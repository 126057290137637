import {Dictionary} from '@ngrx/entity/src/models';
import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {getAudioMetaDataEntitiesOfTrackMix, getTrackMixOfTrackAndMix} from '@spout/any-shared/fns';
import {
  AudioFileMetaData,
  AudioFileMetaDataEntity,
  CombinedTrackAndTrackMix,
  CurrentMixByTrackId,
  CurrentMixSongIdMixes,
  MixEntity,
  TrackEntity,
  TrackMix,
  TrackState,
  UserPermissions
} from '@spout/any-shared/models';
import {createPassThroughFeatureSelector, createPassThroughSelector} from '@spout/web-global/fns';
import {StudioAppState, TrackEntityAndAudioFileMetaDataEntity, trackMixesFeatureKey} from '@spout/web-global/models';
import {getIn, hasValue} from '@uiux/fn';
import {selectAllAudioMetaData, selectAudioMetaDataEntities} from './audio-metadata-storage.selectors';
import {selectCurrentMixEntity, selectCurrentMixEntity_passThrough, selectMixEntities} from './mix-storage.selectors';
import {selectCurrentSongId} from './song-storage.selectors';
import {selectCurrentTrackEntity_passThrough, selectCurrentTracks} from './track-storage.selectors';

export const trackMixState = createFeatureSelector<TrackState>(trackMixesFeatureKey);
export const trackMixStatePassThrough = createPassThroughFeatureSelector<StudioAppState, TrackState>(
  trackMixesFeatureKey
);

export const selectTrackMixEntities = (state: StudioAppState): Dictionary<TrackMix> => {
  if (state.trackMixes && state.trackMixes.entities) {
    return state.trackMixes.entities;
  }
  return {};
};

export const selectAllTrackMixes = createSelector(
  selectTrackMixEntities,
  (entities: Dictionary<TrackMix>): TrackMix[] => {
    if (hasValue(entities)) {
      return <TrackMix[]>Object.values(entities);
    }

    return [];
  }
);

export const selectAllTrackMixesPassThrough = createPassThroughSelector(
  selectTrackMixEntities,
  (entities: Dictionary<TrackMix>): TrackMix[] => {
    if (hasValue(entities)) {
      return <TrackMix[]>Object.values(entities);
    }

    return [];
  }
);

export const getTrackMixesByMixId = createSelector(
  selectAllTrackMixes,
  (mixes: TrackMix[], props: {mixId: string}): TrackMix[] => {
    return mixes.filter((trackMix: TrackMix) => trackMix.mixId === props.mixId);
  }
);

export const getTrackMixByMixId = createSelector(
  selectAllTrackMixes,
  (mixes: TrackMix[], props: {id: string}): TrackMix | undefined => {
    return mixes.find((trackMix: TrackMix) => trackMix.id === props.id);
  }
);

export const selectCurrentTrackMixes = createSelector(
  selectCurrentMixEntity,
  selectAllTrackMixes,
  (mix: MixEntity | null, trackMixes: TrackMix[]) => {
    if (hasValue(mix) && hasValue(trackMixes)) {
      return trackMixes.filter((trackMix: TrackMix) => mix && trackMix.mixId === mix.id);
    }

    return [];
  }
);

export const selectCurrentTrackMixDict = createSelector(
  selectCurrentMixEntity,
  selectAllTrackMixes,
  (mix: MixEntity | null, trackMixes: TrackMix[]): {[trackId: string]: TrackMix} => {
    if (hasValue(mix) && hasValue(trackMixes)) {
      return trackMixes.reduce((a: {[trackId: string]: TrackMix}, trackMix: TrackMix) => {
        a[trackMix.trackId] = trackMix;
        return a;
      }, {});
    }

    return {};
  }
);

export const selectCurrentAudioFileMetaDataForRecord = createSelector(
  selectCurrentMixEntity,
  selectCurrentTrackEntity_passThrough,
  selectAudioMetaDataEntities,
  selectCurrentTrackMixDict,
  (
    currentMix: MixEntity | null,
    currentTrack: TrackEntity | undefined,
    e: Dictionary<AudioFileMetaDataEntity>,
    trackMixesDict: {[trackId: string]: TrackMix}
  ): AudioFileMetaDataEntity[] => {
    if (currentTrack && currentMix && hasValue(e)) {
      // console.log('Current TrackEntity', currentTrack);
      // console.log('Current MixEntity', currentMix);
      // console.log('AudioMetaData Entities', e);

      // const audioSnippets = currentMix.trackMixes[currentTrack.id].audioSnippets;
      const audioSnippets = getIn(trackMixesDict, `${currentTrack.id}.audioSnippets`, null);

      if (hasValue(audioSnippets)) {
        // console.log('Audio Snippets', audioSnippets);
        const mixFileIds: string[] = Object.keys(audioSnippets);

        // console.log('Mix File Ids', mixFileIds);

        return <AudioFileMetaDataEntity[]>(
          mixFileIds.map((audioFileMetaDataEntityId: string) => e[audioFileMetaDataEntityId])
        );
      }

      return [];
    }

    return [];
  }
);

export const selectCurrentTrackEntityAndAudioMetaDataEntity = createSelector(
  selectCurrentMixEntity,
  selectCurrentTrackEntity_passThrough,
  selectAudioMetaDataEntities,
  selectCurrentTrackMixDict,
  (
    currentMix: MixEntity | null,
    currentTrack: TrackEntity | undefined,
    e: Dictionary<AudioFileMetaDataEntity>,
    trackMixesDict: {[trackId: string]: TrackMix}
  ): {
    trackEntity: TrackEntity | null;
    audioFileMetaDataEntity: AudioFileMetaDataEntity | null;
  } => {
    if (currentTrack && currentMix && hasValue(e)) {
      // console.log('Current TrackEntity', currentTrack);
      // console.log('Current MixEntity', currentMix);
      // console.log('AudioMetaData Entities', e);

      // const audioSnippets = currentMix.trackMixes[currentTrack.id].audioSnippets;
      const audioSnippets = getIn(trackMixesDict, `${currentTrack.id}.audioSnippets`, null);

      if (hasValue(audioSnippets)) {
        // console.log('Audio Snippets', audioSnippets);
        const mixFileIds: string[] = Object.keys(audioSnippets);

        // console.log('Mix File Ids', mixFileIds);

        const audioFileMetaDataEntities: ((AudioFileMetaData & UserPermissions) | undefined)[] = mixFileIds.map(
          (audioFileMetaDataEntityId: string) => e[audioFileMetaDataEntityId]
        );

        if (audioFileMetaDataEntities.length) {
          return <TrackEntityAndAudioFileMetaDataEntity>{
            trackEntity: currentTrack,
            audioFileMetaDataEntity: audioFileMetaDataEntities[audioFileMetaDataEntities.length - 1]
          };
        }

        return {
          trackEntity: currentTrack,
          audioFileMetaDataEntity: null
        };
      }

      return {
        trackEntity: currentTrack,
        audioFileMetaDataEntity: null
      };
    }

    return {
      trackEntity: null,
      audioFileMetaDataEntity: null
    };
  }
);

export const selectTrackMixSourcesByCurrentMix = createSelector(
  selectCurrentMixEntity,
  selectCurrentTracks,
  selectAllAudioMetaData,
  selectAllTrackMixes,
  (
    mix: MixEntity | null,
    currentTracks: TrackEntity[],
    audioFileMetaData: AudioFileMetaDataEntity[],
    trackMixes: TrackMix[]
  ): CombinedTrackAndTrackMix[] => {
    if (hasValue(mix) && hasValue(currentTracks) && hasValue(audioFileMetaData) && hasValue(trackMixes)) {
      const combined: (CombinedTrackAndTrackMix | null)[] = (<TrackEntity[]>currentTracks).map((track: TrackEntity) => {
        if (mix) {
          const trackMix: TrackMix | null = getTrackMixOfTrackAndMix(mix, track, trackMixes);

          if (!trackMix) {
            return null;
          }

          return <CombinedTrackAndTrackMix>{
            track,
            trackMix,
            audioFileMetaDataEntities: getAudioMetaDataEntitiesOfTrackMix(trackMix, audioFileMetaData)
          };
        }

        return null;
      });

      if (hasValue(combined)) {
        return <CombinedTrackAndTrackMix[]>combined.filter((com: CombinedTrackAndTrackMix | null) => {
          return hasValue(com);
        });
      }

      return [];
    }

    return [];
  }
);

export const getCurrentTrackMixByTrackIdFn: (props: {
  trackId: string;
}) => MemoizedSelector<StudioAppState, TrackMix | null> = (props: {trackId: string}) =>
  createPassThroughSelector(
    selectCurrentMixEntity_passThrough,
    selectAllTrackMixesPassThrough,
    (mix: MixEntity | null, trackMixes: TrackMix[]): TrackMix | null => {
      if (mix && trackMixes) {
        const found = trackMixes.find((trackMix: TrackMix) => {
          return trackMix.trackId === props.trackId && trackMix.mixId === mix.id;
        });

        if (found) {
          return found;
        }
      }

      return null;
    }
  );

export const getTrackMixesByTrackId = createSelector(
  selectAllTrackMixes,
  (trackMixes: TrackMix[], props: {trackId: string}): TrackMix[] => {
    if (hasValue(trackMixes)) {
      return trackMixes.filter((trackMix: TrackMix) => {
        return trackMix.trackId === props.trackId;
      });
    }
    return [];
  }
);

export const getTrackMixByTrackIdMemoized = () =>
  createSelector(
    selectCurrentMixEntity,
    selectAllTrackMixes,
    (mix: MixEntity | null, trackMixes: TrackMix[], props: {trackId: string}): TrackMix | null => {
      if (mix && trackMixes) {
        const found = trackMixes.find((trackMix: TrackMix) => {
          return trackMix.trackId === props.trackId && trackMix.mixId === mix.id;
        });

        if (found) {
          return found;
        }
      }

      return null;
    }
  );

export const getTrackMixByTrackIdFn: (props: {trackId: string}) => MemoizedSelector<StudioAppState, TrackMix | null> =
  (props: {trackId: string}) =>
    createPassThroughSelector(
      selectCurrentMixEntity_passThrough,
      selectAllTrackMixesPassThrough,
      (mix: MixEntity | null, trackMixes: TrackMix[]): TrackMix | null => {
        if (mix && trackMixes) {
          const found = trackMixes.find((trackMix: TrackMix) => {
            return trackMix.trackId === props.trackId && trackMix.mixId === mix.id;
          });

          if (found) {
            return found;
          }
        }

        return null;
      }
    );

export const selectMixByMixId = createSelector(
  selectCurrentTrackEntity_passThrough,
  selectMixEntities,
  (
    track: TrackEntity | undefined,
    entities: Dictionary<MixEntity>,
    props: {mixId: string}
  ): CurrentMixByTrackId | null => {
    const mixConfig = entities[props.mixId];

    if (track && Object.values(entities) && mixConfig !== undefined) {
      return {
        track,
        mix: mixConfig
      };
    }

    return null;
  }
);

export const sidenavSelectMixComponent = createSelector(
  selectCurrentSongId,
  selectCurrentMixEntity,
  selectMixEntities,
  (songId: string | null, currentMix: MixEntity | null, entities: Dictionary<MixEntity>): CurrentMixSongIdMixes => {
    if (songId && (<MixEntity[]>Object.values(entities)).length) {
      return (<MixEntity[]>Object.values(entities))
        .filter((mix: MixEntity | undefined) => mix !== undefined && mix.songId === songId)
        .reduce(
          (a: CurrentMixSongIdMixes, i: MixEntity | undefined) => {
            if (i) {
              a.mixes.push(i);
            }

            return a;
          },
          <CurrentMixSongIdMixes>{
            songId,
            mixes: [],
            currentMix: null
          }
        );
    }
    return {
      songId,
      mixes: [],
      currentMix: null
    };
  }
);
