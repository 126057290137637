import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {EmailPassword} from '@spout/web-global/models';
import {BehaviorSubject} from 'rxjs';
import {passwordsNotMatch} from '../logn.validators';

@Component({
  selector: 'spt-signup-email',
  templateUrl: './signup-email.component.html',
  styleUrls: ['./signup-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupEmailComponent {
  signUpForm: FormGroup;
  showPassword$: BehaviorSubject<string>;

  @Output() emailPassword: EventEmitter<EmailPassword> = new EventEmitter<EmailPassword>();
  @Output() dirtyAndValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder) {
    this.showPassword$ = new BehaviorSubject<string>('password');

    this.signUpForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]]
      },
      {validators: passwordsNotMatch}
    );

    this.signUpForm.valueChanges.subscribe(() => {
      this.dirtyAndValid.next(this.signUpForm.dirty && this.signUpForm.valid);
      // this.cd.markForCheck();
    });
  }

  showPassword(event: MatCheckboxChange) {
    if (this.showPassword$.value === 'password') {
      this.showPassword$.next('text');
    } else {
      this.showPassword$.next('password');
    }
  }

  submitEmailPassword() {
    if (this.signUpForm.valid) {
      this.emailPassword.next(this.signUpForm.value);
    }
  }
}
