import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {MixEntity, TrackMixAudioSync} from '@spout/any-shared/models';
import {addDurationOffset, calculateAudioLength, getDefaultSongWaveformValues} from '@spout/web-global/fns';
import {
  MixMetrics,
  StudioAppState,
  TrackReporting,
  WaveformSongMetrics,
  WaveformTrackMetrics,
  WaveformValues
} from '@spout/web-global/models';
import {selectCurrentMixEntity} from '@spout/web-global/selectors';
import {allValuesTruthy, hasValue} from '@uiux/fn';
import {hasValuePipe} from '@uiux/rxjs';
import {BehaviorSubject, combineLatest, OperatorFunction, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap} from 'rxjs/operators';
import {parseAudioTrackMetrics, parseSongWaveformMetrics} from '../helpers/mix-metrics.helpers';
import {SptTransportService} from '../spt-transport.service';

interface ReportingTrackIds {
  [trackId: string]: boolean;
}

/**
 * Calculate track-audio metrics as they relate to each other
 * in the current song play list.
 */
@Injectable({
  providedIn: 'root'
})
export class SptCurrentMixMetricsCalculatorService {
  mixMetrics$: ReplaySubject<MixMetrics> = new ReplaySubject<MixMetrics>(1);
  songWaveformMetrics$: ReplaySubject<WaveformSongMetrics> = new ReplaySubject<WaveformSongMetrics>(1);

  currentMixBPM$: ReplaySubject<number> = new ReplaySubject<number>(1);
  currentDuration$ = this.mixMetrics$.pipe(map((metrics: MixMetrics) => (metrics ? metrics.duration : 0)));
  currentSampleRate$ = this.mixMetrics$.pipe(map((metrics: MixMetrics) => (metrics ? metrics.maxSampleRate : 0)));
  timeLeft$ = combineLatest([this.transport.seconds$, this.currentDuration$]).pipe(
    map(([currentTimeSeconds, duration]: [number, number]) => {
      return duration - currentTimeSeconds;
    })
  );

  mixHasAudioToPlay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  mixNotHasAudioToPlay$ = this.mixHasAudioToPlay$.pipe(map((hasAudioToPlay: boolean) => hasAudioToPlay === false));

  // playerChannelMergerIds: { [key: string]: { 0: number; 1: number; }} = {};

  private numberTracksActiveInSong$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private reporting: ReportingTrackIds = {};
  private tracksReporting: {[trackId: string]: TrackReporting} = {};
  private trackWaveforms: {[trackId: string]: WaveformTrackMetrics} = {};
  private allTracksReporting$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private store: Store<StudioAppState>, private transport: SptTransportService) {
    this.calculateAudioMetrics();
  }

  setTrackIdsFromSong(trackIds: string[]): void {
    const that = this;

    this.clear();

    if (trackIds && trackIds.length) {
      trackIds.forEach((trackId: string) => {
        that.reporting[trackId] = false;
      });

      this.numberTracksActiveInSong$.next(trackIds.length);
    }
  }

  resetTrackMixMetrics(trackId: string) {
    this.reporting[trackId] = true;
    this.tracksReporting[trackId] = {
      trackId,
      playerIds: [],
      trackDuration: 0,
      // offsetMs: 0,
      maxSampleRate: 0,
      minSampleRate: 0
    };
  }

  // TODO: Calculate offset in caller of this method
  addTrackMixMetrics(t: TrackReporting) {
    // console.log('TrackReporting', t);
    // const defaultWaveformValues = getDefaultWaveformValues(t.maxSampleRate);

    this.reporting[t.trackId] = true;
    this.tracksReporting[t.trackId] = {
      ...t
      // duration: max([t.duration, defaultWaveformValues.duration]),
    };
    // console.log('reporting', this.reporting);
    if (hasValue(this.reporting)) {
      this.allTracksReporting$.next(allValuesTruthy(this.reporting));
    } else {
      this.allTracksReporting$.next(false);
    }
  }

  // TODO: Calculate offset in caller of this method
  addTrackWaveformValues(w: WaveformValues, trackMixAudioSync: TrackMixAudioSync, trackId: string): void {
    /**
     * Add duration + offset
     */
    const totalTrackDuration = addDurationOffset(trackMixAudioSync.audioDuration, trackMixAudioSync.offsetMs);

    this.trackWaveforms[trackId] = {
      // length: max([w.length, defaultWaveformValues.length]),
      // duration: max([w.duration, defaultWaveformValues.duration]),
      length: calculateAudioLength(totalTrackDuration, w.pixels_per_second),
      // trackDuration: trackMixAudioSync.audioDuration + (trackMixAudioSync.offsetMs * 0.001),
      trackDuration: totalTrackDuration,
      // trackDuration: trackMixAudioSync.audioDuration,
      channels: w.channels,
      hasData: w.hasData,
      sample_rate: w.sample_rate,
      // scale: w.scale ? w.scale : defaultWaveformValues.scale,
      scale: w.scale,
      seconds_per_pixel: w.seconds_per_pixel,
      pixels_per_second: w.pixels_per_second,
      bits: w.bits
    };

    this.songWaveformMetrics$.next(parseSongWaveformMetrics(Object.values(this.trackWaveforms)));
  }

  deleteTrack(t: TrackReporting) {
    delete this.reporting[t.trackId];
    delete this.tracksReporting[t.trackId];
    delete this.trackWaveforms[t.trackId];

    if (hasValue(this.reporting)) {
      this.allTracksReporting$.next(allValuesTruthy(this.reporting));
    } else {
      this.allTracksReporting$.next(false);
    }
  }

  clear() {
    this.reporting = {};
    this.tracksReporting = {};
    this.trackWaveforms = {};
    this.allTracksReporting$.next(false);
  }

  private calculateAudioMetrics() {
    const that = this;

    this.allTracksReporting$
      .pipe(
        filter((allTracksReporting: boolean) => allTracksReporting),
        distinctUntilChanged(),
        map(() => {
          return parseAudioTrackMetrics(Object.values(that.tracksReporting));
        }),
        mergeMap((metrics: MixMetrics) => {
          return this.transport.audioContext$.pipe(
            map((audioContext: AudioContext) => {
              metrics.contextSampleRate = audioContext.sampleRate;

              return {
                metrics,
                audioContext
              };
            })
          );
        })
      )
      .subscribe(
        ({metrics, audioContext}: {metrics: MixMetrics; audioContext: AudioContext}) => {
          // console.log('MixMetrics', metrics);

          this.mixHasAudioToPlay$.next(metrics.duration > 0);
          this.mixMetrics$.next(metrics);
          this.transport.setRenderMap(metrics.playerIds);

          if (!metrics.hasAudio) {
            this.songWaveformMetrics$.next(
              getDefaultSongWaveformValues(audioContext.sampleRate, metrics.defaultDuration)
            );
          }

          // this.calculateWaveformMetrics.call(this);
        },
        () => {
          /* noop */
        },
        () => {
          /* noop */
        }
      );

    this.store
      .pipe(select(selectCurrentMixEntity), hasValuePipe<MixEntity | null, MixEntity>())
      .subscribe((mixEntity: MixEntity) => {
        if (mixEntity) {
          that.currentMixBPM$.next(mixEntity.bpm);
        }
      });
  }
}
