import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProjectEntity, TrackEntity, TrackMix, TrackMixAudioSnippet} from '@spout/any-shared/models';
import {createDefaultConfigsToFirestore} from '@spout/web-global/actions';
import {
  assignTrackMixAudioFileMetaMethod,
  createInitialAudioFileMetaDataEntity,
  createInitialMixEntityWithSong,
  createInitialProjectEntity,
  createInitialSongEntity,
  createInitialTrackEntity,
  createInitialTrackMixConfig,
  setAudioFileMetaDataEntityProps,
  setMixEntityProps,
  setProjectEntityProps,
  setSongEntityProps,
  setTrackEntityProps,
  setTrackMixProps
} from '@spout/web-global/fns';
import {AccountState, StudioAppState} from '@spout/web-global/models';
import {assignMethod, hasValue, objectMethodAssign} from '@uiux/fn';

@Injectable({
  providedIn: 'root'
})
export class CreateDefaultEntitiesService {
  constructor(private store: Store<StudioAppState>, private zone: NgZone) {}

  createDefaultEntities(account: AccountState): void {
    const that = this;
    if (account.email && !hasValue(account.defaultProjectId)) {
      let projectEntity: ProjectEntity = createInitialProjectEntity(account);

      projectEntity = setProjectEntityProps(projectEntity, {
        name: `My Personal Project`,
        description: `${account.stageName}'s Project`,
        isDefault: true
      });

      let songEntity = createInitialSongEntity(account, projectEntity);

      songEntity = setSongEntityProps(songEntity, {
        name: `My Personal Song`,
        description: `${account.stageName}'s Song`,
        isDefault: true
      });

      let trackEntity: TrackEntity = createInitialTrackEntity(account, songEntity);

      trackEntity = setTrackEntityProps(trackEntity, {
        name: `Reference`,
        description: `for ${account.stageName}'s Song`,
        isDefault: true,
        shared: true
      });

      let audioFileEntity = createInitialAudioFileMetaDataEntity(account, trackEntity);

      audioFileEntity = setAudioFileMetaDataEntityProps(audioFileEntity, {
        name: 'Audio'
      });

      let mixEntity = createInitialMixEntityWithSong(account, songEntity);

      mixEntity = setMixEntityProps(mixEntity, {
        name: `${account.stageName}'s Mix`,
        description: `for ${account.stageName}'s Mix`,
        isDefault: true
      });

      const trackMix = objectMethodAssign<TrackMix>(
        createInitialTrackMixConfig(account, trackEntity, mixEntity.id, audioFileEntity)
      ).pipe(
        assignMethod<TrackMix>(setTrackMixProps, {isReference: true}),
        assignMethod<TrackMix, TrackMixAudioSnippet>(assignTrackMixAudioFileMetaMethod(audioFileEntity), {
          isDefault: true
        })
      );

      // trackMix = setTrackMixProps(trackMix, { isMaster: true });
      // trackMix = setTrackMixAudioFileSnippetPropsByAudioFileMetaDataEntity(trackMix, audioFileEntity, { isDefault: true });

      // Saved to Firestore
      that.zone.run(() => {
        that.store.dispatch(
          createDefaultConfigsToFirestore({
            project: projectEntity,
            song: songEntity,
            mix: mixEntity,
            track: trackEntity,
            file: audioFileEntity,
            trackMix
          })
        );
      });

      // this.store.dispatch(
      //   accountSaveFirebase({
      //                         payload: {
      //                           defaultProjectId: projectEntity.id,
      //                           defaultSongId: songEntity.id,
      //                           defaultMixId: mixEntity.id,
      //                           defaultTrackId: trackEntity.id,
      //                         },
      //                       })
      // );

      // this.store.dispatch(
      //   setDeviceStoreCurrentIdsFromTrackAndMixEntity({
      //                                                   track: trackEntity,
      //                                                   mix: mixEntity,
      //                                                 })
      // );
    }
  }
}
