import {createFeatureSelector, createSelector} from '@ngrx/store';
import {TreeConfig} from '@spout/any-shared/models';
import {GenresMusician, genresMusicianFeatureKey, GenresMusicianState} from '@spout/web-global/models';

export const genresMusicianState = createFeatureSelector<GenresMusicianState>(genresMusicianFeatureKey);

export const selectAllGenresMusician = createSelector(
  genresMusicianState,
  (state: GenresMusicianState): TreeConfig[] => {
    if (state && state.entities && Object.values(state.entities).length) {
      return <GenresMusician[]>Object.values(state.entities);
    }
    return [];
  }
);
