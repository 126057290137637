<mat-tab-group
  mat-align-tabs="center"
  (selectedIndexChange)="setTabIndex($event)"
  [selectedIndex]="selected.value"
  dynamicHeight
>
  <mat-tab label="SIGN IN / SIGN UP" [ngSwitch]="isLoggedIn$ | ngrxPush">
    <ng-container *ngSwitchCase="true">
      <div class="logged-in" *ngrxLet="account$; let a">
        <div class="mat-h3">
          You are currently logged in as
          <span class="p-primary">{{ a.email }}</span> with <span class="p-primary">{{ a.providerId }}</span
          >.
        </div>
        <div class="mat-h3">
          If you DON'T want to use this account, you may log out and log in with a different account.
        </div>
      </div>
      <div class="logout-cta">
        <button color="primary" mat-stroked-button (click)="logout()">LOG OUT</button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="false">
      <auth-signin-oath
        [signInOrSignUp]="'signin'"
        (facebook)="signInWithFacebook()"
        (google)="signInWithGoogle()"
        (twitter)="signInWithTwitter()"
        (apple)="signInWithApple()"
      ></auth-signin-oath>

      <div class="or-divider">
        <div class="divider-line">
          <mat-divider></mat-divider>
        </div>
        <div class="or">OR</div>
        <div class="divider-line">
          <mat-divider></mat-divider>
        </div>
      </div>

      <spt-alert-message-container #alertMessageContainerComponent></spt-alert-message-container>
      <ui-signin-email
        (forgotPassword)="enableForgotPassword()"
        (signInEmail)="signInWithEmail($event)"
        (dirtyAndValid)="alertMessageContainerComponent.deleteMessage(ALERT_MESSAGE_ID.PASSWORD_AUTH)"
      ></ui-signin-email>
    </ng-container>
  </mat-tab>

  <mat-tab label="SIGN UP">
    <spt-alert-message-container #alertMessageContainerComponentSignUp></spt-alert-message-container>

    <auth-signin-oath
      [signInOrSignUp]="'signup'"
      (facebook)="signInWithFacebook()"
      (google)="signInWithGoogle()"
      (twitter)="signInWithTwitter()"
      (apple)="signInWithApple()"
    ></auth-signin-oath>

    <div class="or-divider">
      <div class="divider-line">
        <mat-divider></mat-divider>
      </div>
      <div class="or">OR</div>
      <div class="divider-line">
        <mat-divider></mat-divider>
      </div>
    </div>

    <ui-signup-email
      (emailPassword)="createUserWithEmailAndPassword($event)"
      (dirtyAndValid)="alertMessageContainerComponentSignUp.deleteMessage(ALERT_MESSAGE_ID.CREATE_PASSWORD)"
    ></ui-signup-email>
  </mat-tab>

  <mat-tab label="FORGOT PASSWORD" *ngIf="forgotPassword">
    <spt-alert-message-container #alertMessageContainerComponentForgotPassword></spt-alert-message-container>
    <ui-forgot-password
      (dirtyAndValid)="
        alertMessageContainerComponentForgotPassword.deleteMessage(ALERT_MESSAGE_ID.EMAIL_RESET_PASSWORD)
      "
      (email)="sendEmailPasswordReset($event)"
    ></ui-forgot-password>
  </mat-tab>
</mat-tab-group>

<div class="legal push-top-40 push-bottom-20">
  By continuing, you are indicating that you accept our<br />
  <spt-a-marketing [href]="'policies/terms-and-conditions'">Terms of Service</spt-a-marketing>
  and
  <spt-a-marketing [href]="'policies/privacy-policy'">Privacy Policy</spt-a-marketing>
</div>
