import firebase from 'firebase/compat';
import {
  DocumentReference,
  onSnapshot,
  query,
  QuerySnapshot,
  QueryDocumentSnapshot,
  where,
  getDocs,
  getDoc,
  DocumentData
} from 'firebase/firestore';

export interface Customer {
  stripeId: string;
  stripeLink: string;
}

export declare type PriceInterval = 'month' | 'year';

// https://stripe.com/docs/api/prices/object
export interface Price {
  // local only
  id: string;
  name: string;
  productId: string;
  role: string | null;

  // the product this price belongs to has a trial options
  productHasTrial: boolean;
  isTrial: boolean;

  // in database
  trial_period_days: number;
  interval_count: number; // 1
  recurring: {
    interval: string; // 'month'
    usage_type: string; // 'licensed'
    trial_period_days: number;
    interval_count: number; // 1
    aggregate_usage: number;
  };
  transform_quantity: number;
  unit_amount: number; // 3numbernumber
  currency: string; // 'usd'
  tiers_mode: number;
  tiers: number;
  description: number;
  type: string; // 'recurring'
  interval: PriceInterval; // 'month'
  billing_scheme: string; // 'per_unit'
  active: boolean; // boolean
}

export interface PriceEntities {
  [id: string]: Price;
}

export interface CurrentSubscriptionEntities {
  [id: string]: SubscriptionItem;
}

export interface Product {
  // local only
  id: string;

  // database
  active: boolean;
  description: string;
  images: string[];
  name: string;
  role: string | null;
  stripe_metadata_trial: string | null;
  stripe_metadata_description2: string | null;
  stripe_metadata_baseStorageGB: number | string | null;
  stripe_metadata_addon: 'storage' | null;
}

export interface PriceOptions {
  product: Product;
  prices: Price[];
}

export interface PriceOptionsFlat {
  product: Product;
  price: Price;
}

export interface SubscriptionItem {
  id: string;
  subscription: string; // sub_JZzEjobL5YAKst
  tax_rates: string[];
  object: string;
  plan: {
    tiers_mode: string;
    interval_count: number;
    product: string; // prod_JDSotpdfR9D36z
    object: string;
    metadata: any;
    amount: number;
    usage_type: string; // 'licensed'
    active: boolean; // active
    id: string; // price_1Ib1siFr7h7OltJtLEycvZxZ
    interval: string; // month
    transform_usage: string;
    currency: string; // usd
    aggregate_usage: string;
    created: number;
    amount_decimal: string; // 3numbernumber
    trial_period_days: number;
    nickname: string;
    billing_scheme: string; // per_unit
    livemode: boolean;
  };
  billing_thresholds: string;
  quantity: number;
  price: {
    transform_quantity: number;
    type: string; // recurring
    lookup_key: string;
    recurring: {
      trial_period_days: number;
      interval: string; // month
      interval_count: number;
      usage_type: string; // licensed
      aggregate_usage: string;
    };
    currency: string; // usd
    unit_amount: number;
    unit_amount_decimal: string; // 3numbernumber
    created: number;
    nickname: string;
    object: string; // price
    livemode: boolean;
    active: boolean;
    id: string; // price_1Ib1siFr7h7OltJtLEycvZxZ
    billing_scheme: string; // per_unit
    product: {
      attributes: any[];
      livemode: boolean;
      images: string[];
      name: string;
      updated: number;
      url: null;
      created: number;
      description: string;
      statement_descriptor: string;
      metadata: {
        description2: string;
        addon: string;
        trial: string;
        baseStorageGB: string;
        accountRole: string;
      };
      package_dimensions: null;
      object: string;
      active: boolean;
      type: string;
      unit_label: string; // GB
      shippable: null;
      id: string; // prod_JDSotpdfR9D36z
    };
    tiers_mode: string;
    metadata: any;
  };
  created: number;
  metadata: any;
}

export interface SubscriptionItemDict {
  subscriptionItemEntities: {[id: string]: SubscriptionItem};
  subscriptionId: string | null;
}

// firestore object created by stripe payments
export interface ProductFirestoreSubscription {
  product: DocumentReference<DocumentData>;
  prices: DocumentReference<DocumentData>[];
  items: SubscriptionItem[];
  price: DocumentReference<DocumentData>;
}

/**
 * incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid.
 */
export enum SubscriptionStatus {
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  trialing = 'trialing',
  active = 'active',
  past_due = 'past_due',
  canceled = 'canceled',
  unpaid = 'unpaid'
}

/**
 * https://stripe.com/docs/api/subscriptions/object
 */
export interface CurrentSubscription {
  items: SubscriptionItem[];
  created: {
    seconds: number;
    nanoseconds: number;
  };
  current_period_end: {
    seconds: number;
    nanoseconds: number;
  };
  ended_at: null;
  status: SubscriptionStatus;
  cancel_at_period_end: boolean;
  stripeLink: string;
  price: Price;
  role: string;
  trial_end: null;
  cancel_at: {
    seconds: number;
    nanoseconds: number;
  };
  product: Product | undefined;
  current_period_start: {
    seconds: number;
    nanoseconds: number;
  };
  metadata: any;
  quantity: 1;
  canceled_at: {
    seconds: number;
    nanoseconds: number;
  };
  prices: Price[];
  trial_start: null;
}

export interface TrialModel {
  days: number;
}
