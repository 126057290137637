export interface EnvPlatform {
  isM1Mac: boolean;
  isIntelMac: boolean;
  isPc: boolean;
}
export interface BuildVersionElectron {
  year: number;
  month: string;
  electronMajorVersion: number;
  electronVersion: string;
  buildNumber: number;
}

export interface BuildVersionUi {
  year: number;
  month: string;
  angularVersion: number;
  buildNumber: number;
}

export interface AppVersions {
  releaseType: string;
  macString: string;
  pcString: string;
  ui: string;
  build: BuildVersionElectron;
  platform: EnvPlatform;
}

export interface StudioVersion {
  electron: BuildVersionElectron;
  ui: BuildVersionUi;
  releaseType: string;
  changeLog: string;
}
