import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {AuthError} from '../../services/login.model';

@Component({
  selector: 'auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.scss', '../auth.common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthErrorComponent {
  authError$: ReplaySubject<AuthError> = new ReplaySubject<AuthError>(1);

  @Input()
  set message(m: AuthError) {
    this.authError$.next(m);
  }
}
