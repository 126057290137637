import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadComponentDirective} from './load-component.directive';
import {ComponentContainerComponent} from './component-container.component';

@NgModule({
  declarations: [LoadComponentDirective, ComponentContainerComponent],
  exports: [LoadComponentDirective, ComponentContainerComponent],
  imports: [CommonModule]
})
export class LoadComponentModule {}
