import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'spt-terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.component.html',
  styleUrls: ['./terms-and-conditions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsAndConditionsDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
