import {isNumber, isPlainObject, isString} from '@uiux/fn';
import {map} from 'rxjs/operators';

type RecurseUnfreezeFn = (...args: any[]) => any;

function createNewObject(o: any | undefined, unfreezeFn: RecurseUnfreezeFn): any {
  let oo = undefined;
  if (Array.isArray(o)) {
    oo = [];
    let clone = function (v: any) {
      oo.push(unfreezeFn(v, unfreezeFn));
    };
    o.forEach(clone);
  } else if (isString(o) || isNumber(o)) {
    oo = o;
  } else if (isPlainObject(o)) {
    oo = {};

    const keys = Object.keys(o);

    for (let i = 0; i < keys.length; i++) {
      (<any>oo)[keys[i]] = unfreezeFn(o[keys[i]], unfreezeFn);
    }
  } else {
    oo = o;
  }
  return oo;
}

export function unfreeze(o: any) {
  return createNewObject(o, createNewObject);
}

export function unfreezePipe() {
  return map((o: any) => unfreeze(o));
}
