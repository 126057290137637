import {Inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  firestoreTrial,
  firestoreUserSubscriptionsPath,
  getCustomer,
  getPricesPath,
  getProductsPath
} from '@spout/web-global/fns';

import {
  AuthAccountStates,
  CurrentSubscription,
  Customer,
  ENVIRONMENT,
  IEnvironmentState,
  Price,
  Product,
  ProductFirestoreSubscription,
  SubscriptionItem,
  TrialModel
} from '@spout/web-global/models';
import {selectUid} from '@spout/web-global/selectors';
import {hasValuePipe} from '@uiux/rxjs';
import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  where
} from 'firebase/firestore';
import {BehaviorSubject, Observable, Observer, Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {removeTimestampCTorFromDocumentSnapshot, SptFirestoreService} from '../../firestore';
import {getPriceData, getProductData} from './payment-wizard.fns';
import {StripePaymentSelectionService} from './stripe-payment-selection.service';

@Injectable({
  providedIn: 'root'
})
export class StripePaymentFirestoreService {
  private productsSub: (() => void) | undefined;
  private customerSub: (() => void) | undefined;
  private usersSubscriptionsSub: (() => void) | undefined;
  private trialSub: (() => void) | undefined;
  private _onDestroy$: Subject<boolean> = new Subject();
  private _init$: BehaviorSubject<boolean>;

  constructor(
    private payment: StripePaymentSelectionService,
    private sptFirestore: SptFirestoreService,
    private store: Store<AuthAccountStates>,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState
  ) {
    this._init$ = new BehaviorSubject<boolean>(false);
    this._onInit();
  }

  private _onInit() {
    const that = this;
    this.loadProductsPrices().subscribe(() => {
      that.store
        .pipe(
          select(selectUid),
          distinctUntilChanged(),
          hasValuePipe<string | null, string>(),
          takeUntil(that._onDestroy$)
        )
        .subscribe((uid: string) => {
          that.loadCustomerSubscriptions(uid);
          that.loadCustomerInfo(uid);
          that.loadTrial();
        });
    });
  }

  private loadProductsPrices(): Observable<boolean> {
    const that = this;

    return new Observable((observer: Observer<any>) => {
      this.productsSub = onSnapshot<DocumentData>(
        query(that.sptFirestore.collectionRef(getProductsPath())),
        (querySnapshot: QuerySnapshot<DocumentData>) => {
          const size = querySnapshot.size;
          let count = 0;

          querySnapshot.forEach((productRef: QueryDocumentSnapshot<DocumentData>) => {
            const product: Product = getProductData(productRef);

            that.payment.addProduct(product);

            const q = query(that.sptFirestore.collectionRef(getPricesPath(product.id)), where('active', '==', true));

            const priceSnap = getDocs(q);

            priceSnap.then(priceSnap => {
              const length = priceSnap.size;
              let priceCount = 0;

              priceSnap.docs.forEach((doc, index) => {
                const price = getPriceData(doc, doc.id, productRef.id, product);

                that.payment.addPrice(price);

                if (product.stripe_metadata_addon === 'storage') {
                  that.payment.addSelectedStoragePriceEntity(price);
                }

                priceCount++;
              });

              count++;

              if (size === count && priceCount === length) {
                observer.next(true);
                observer.complete();
              }
            });
          });
        }
      );
    });
  }

  private loadTrial() {
    const that = this;

    this.trialSub = onSnapshot(
      this.sptFirestore.docRef(firestoreTrial()),
      (snapshot: DocumentSnapshot<DocumentData>) => {
        if (!snapshot.exists()) {
          // Show subscriptionItemEntities
          return;
        }

        const trial = removeTimestampCTorFromDocumentSnapshot(snapshot) as TrialModel;
        that.payment.addTrialDays(trial.days);
      }
    );
  }

  private loadCustomerSubscriptions(uid: string) {
    const that = this;

    // Get all subscriptionItemEntities for the customer
    this.usersSubscriptionsSub = onSnapshot(
      this.sptFirestore.collectionRef(firestoreUserSubscriptionsPath(uid)),
      (snapshot: QuerySnapshot<DocumentData>) => {
        if (snapshot.empty) {
          // Show subscriptionItemEntities
          return;
        }
        // In this implementation we only expect one Subscription to exist
        snapshot.docs.forEach(async (doc: QueryDocumentSnapshot<DocumentData>) => {
          that.payment.addCurrentSubscriptionId(doc.id);

          const firestoreSubscription: ProductFirestoreSubscription = <ProductFirestoreSubscription>doc.data();

          const subscription: CurrentSubscription = {
            ...(<any>firestoreSubscription)
          };

          subscription.product = <Product>(await getDoc(firestoreSubscription.product)).data();
          subscription.price = <Price>(await getDoc(firestoreSubscription.price)).data();

          await new Promise(resolve => {
            let count = 0;

            firestoreSubscription.prices.forEach(async (item: DocumentReference<DocumentData>, index: number) => {
              count++;
              subscription.prices[index] = removeTimestampCTorFromDocumentSnapshot<Price>(await getDoc(item));
              if (count === firestoreSubscription.prices.length) {
                resolve(true);
              }
            });
          });

          // const subscriptionPriceData = removeTimestampCTorFromDocumentSnapshot(await subscription.price.get());
          //
          firestoreSubscription.items.forEach((item: SubscriptionItem, index: number) => {
            subscription.items[index] = item;

            if (item.price.product.metadata.addon === 'storage') {
              that.payment.addCurrentSubscriptionStorage(item);
            } else {
              that.payment.addCurrentSubscriptionPlan(item);
            }
          });

          this.payment.addSubscription(subscription);
        });
      }
    );
  }

  private loadCustomerInfo(uid: string) {
    // Get all subscriptionItemEntities for the customer
    this.customerSub = onSnapshot(
      this.sptFirestore.docRef(getCustomer(uid)),
      (snapshot: DocumentSnapshot<DocumentData>) => {
        if (snapshot.exists()) {
          // Show subscriptionItemEntities
          // In this implementation we only expect one Subscription to exist
          const subscription: Customer = removeTimestampCTorFromDocumentSnapshot<Customer>(snapshot);

          this.payment.addCustomer({
            ...subscription
          });
        }
      }
    );
  }

  onDestroy() {
    if (this.productsSub) {
      this.productsSub();
    }
    if (this.customerSub) {
      this.customerSub();
    }
    if (this.usersSubscriptionsSub) {
      this.usersSubscriptionsSub();
    }
    if (this.trialSub) {
      this.trialSub();
    }
  }
}
