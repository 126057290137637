<mat-sidenav-container>
  <mat-sidenav-content>
    <auth-app-header></auth-app-header>
    <div class="app-content" *ngrxLet="login.loginScreen$; let loginScreen">
      <mat-progress-bar *ngIf="login.showProgress$ | ngrxPush" mode="indeterminate" color="accent"></mat-progress-bar>
      <auth-firebase-ui
        (openPrivacyPolicy)="openPrivacyPolicy()"
        (openTermsOfUse)="openTermsOfService()"
        *ngIf="loginScreen === screen.SHOW_LOGIN"
      ></auth-firebase-ui>
      <auth-logging-in *ngIf="loginScreen === screen.SHOW_LOGGING_IN"></auth-logging-in>
      <auth-error *ngIf="loginScreen === screen.SHOW_ERROR" [message]="login.errorMessage$ | ngrxPush"></auth-error>
      <auth-close-browser *ngIf="loginScreen === screen.SHOW_CLOSE_BROWSER"></auth-close-browser>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
