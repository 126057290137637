import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {Inject, Injectable, NgZone} from '@angular/core';
import {Update} from '@ngrx/entity';
import {Store} from '@ngrx/store';
import {createUint8ArrayExtensionFromBlob$} from '@spout/any-shared/fns';
import {AudioFileMetaDataEntity, FileUint8ArrayType} from '@spout/any-shared/models';
import {
  openNotificationRightDrawer,
  updateAudioFileMetaDataEntityEffect,
  upsertNotification
} from '@spout/web-global/actions';
import {EndpointModels, ENDPOINTS} from '@spout/web-global/models';
import {forkJoin, Observable, Observer, of, Subject} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {createUploadErrorNotification} from '../../+notifications/helpers/notification.creators';
import {
  createSpoutProxyServerServiceB2DeleteJson,
  createSpoutProxyServerServiceB2DownloadJson,
  createSpoutProxyServerServiceB2PayloadFormData
} from './proxy-form-data';
import {
  SpoutProxyServerServiceB2UploadParams,
  SpoutProxyServerServiceB2UploadResponse,
  UploadStatusSpoutProxyServerServiceB2UploadResponse
} from './proxy.models';

@Injectable({
  providedIn: 'root'
})
export class SpoutProxyServerService {
  constructor(
    private client: HttpClient,
    private store: Store,
    private zone: NgZone,
    @Inject(ENDPOINTS) public endpoints: EndpointModels
  ) {}

  uploadAPI(
    files: Set<SpoutProxyServerServiceB2UploadParams>,
    trackName: string | undefined
  ): {
    [key: string]: Observable<UploadStatusSpoutProxyServerServiceB2UploadResponse>;
  } {
    const that = this;
    // this will be the resulting map
    const status: {
      [key: string]: Observable<UploadStatusSpoutProxyServerServiceB2UploadResponse>;
    } = {};

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = createSpoutProxyServerServiceB2PayloadFormData(file);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', this.endpoints.UPLOAD_BACKBLAZE, formData, {
        reportProgress: true
        // headers: new HttpHeaders({'ngsw-bypass': 'true'})
      });

      // create a new progress-subject for every file
      const progress = new Subject<UploadStatusSpoutProxyServerServiceB2UploadResponse>();

      // send the http-request and subscribe for progress-updates
      this.client
        .request(req)
        .pipe(retry(3))
        .subscribe(
          (event: HttpEvent<any>) => {
            if (event.type === HttpEventType.UploadProgress) {
              // calculate the progress percentage
              const total = event.total ? event.total : 100;
              const percentProgress = Math.round((100 * event.loaded) / total);

              // pass the percentage into the progress-stream
              progress.next({
                progress: percentProgress,
                result: null
              });
            } else if (event instanceof HttpResponse) {
              if (event.status === 200 && event.body) {
                progress.next({
                  progress: 100,
                  result: <SpoutProxyServerServiceB2UploadResponse>event.body
                });

                // Close the progress-stream if we get an answer form the API
                // The upload is complete
                setTimeout(() => {
                  progress.complete();
                }, 250);
              }
            }
          },
          error => {
            that.zone.run(() => {
              that.store.dispatch(
                upsertNotification({
                  notification: createUploadErrorNotification(
                    `The ${trackName ? trackName : ''} track failed to upload.`
                  )
                })
              );

              that.store.dispatch(openNotificationRightDrawer());
            });

            progress.error(error);
          }
        );

      // Save every progress-observable in a map of all observables
      status[file.id] = progress;
    });

    // return the map of progress.observables
    return status;
  }

  deleteAPI(files: AudioFileMetaDataEntity[]): Observable<any> {
    // console.log(files);
    const requests$ = files.map((file: AudioFileMetaDataEntity) => {
      // If file is undefined or null, return true
      if (!file) {
        return of(true);
      }

      const payloadJson = createSpoutProxyServerServiceB2DeleteJson(file);

      const req = new HttpRequest('POST', this.endpoints.DELETE_BACKBLAZE, payloadJson);
      return this.client.request(req).pipe(
        retry(3),
        catchError(() => {
          return of(true);
        })
      );
    });

    return forkJoin(requests$);
  }

  downloadAPI(file: AudioFileMetaDataEntity): Observable<{progress: number; result: FileUint8ArrayType}> {
    const that = this;
    // console.log(files);
    return new Observable((observer: Observer<any>) => {
      const payloadJson = createSpoutProxyServerServiceB2DownloadJson(file);

      const req = new HttpRequest('POST', this.endpoints.DOWNLOAD_BACKBLAZE, payloadJson, {
        headers: new HttpHeaders({
          Accept: 'application/octet-stream'
          // 'ngsw-bypass': 'true'
        }),
        reportProgress: true,
        responseType: 'blob'
      });
      this.client
        .request(req)
        .pipe(retry(3))
        .subscribe(event => {
          if (event.type === HttpEventType.DownloadProgress) {
            // calculate the progress percentage
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const progress = Math.round((100 * event.loaded) / event.total);

            observer.next({
              progress,
              result: null
            });
          } else if (event instanceof HttpResponse) {
            // event.body is a blob
            if (file.fileSize === undefined || file.fileSize === null) {
              that.store.dispatch(
                updateAudioFileMetaDataEntityEffect({
                  projectId: file.projectId,
                  file: {
                    id: file.id,
                    changes: {
                      fileSize: (<Blob>event.body).size
                    }
                  }
                })
              );
            }

            createUint8ArrayExtensionFromBlob$(<Blob>event.body).subscribe((result: FileUint8ArrayType) => {
              observer.next({
                progress: 100,
                result
              });
            });
          }
        });
    });
  }
}
