import {Type} from '@angular/core';
import {HelpModel} from '@spout/web-global/models';
import {ComponentDictItem} from './content.model';
import {HelpCompressorComponent} from './help-compressor/help-compressor.component';
import {HelpGainComponent} from './help-gain/help-gain.component';
import {HelpGettingStartedCollaborationComponent} from './help-getting-started-collaboration/help-getting-started-collaboration.component';
import {HelpGettingStartedCreatingComponent} from './help-getting-started-creating/help-getting-started-creating.component';
import {HelpGettingStartedReviewingComponent} from './help-getting-started-reviewing/help-getting-started-reviewing.component';
import {HelpLatencyVsOffsetComponent} from './help-latency-vs-offset/help-latency-vs-offset.component';
import {HelpRecordLatencyComponent} from './help-record-latency/help-record-latency.component';
import {HelpSelectMicrophoneComponent} from './help-select-microphone/help-select-microphone.component';
import {HelpTocComponent} from './help-toc.component';

const componentRefDict: Map<HelpModel, ComponentDictItem> = new Map();

export class SptComponentRef {
  constructor(public component: Type<any>, public data: any = null) {}
}

const helpComponents: ComponentDictItem[] = [
  {
    title: 'How to create a song',
    id: HelpModel.GETTING_STARTED_CREATING,
    component: new SptComponentRef(HelpGettingStartedCreatingComponent)
  },
  {
    title: 'How to collaborate',
    id: HelpModel.GETTING_STARTED_COLLABORATION,
    component: new SptComponentRef(HelpGettingStartedCollaborationComponent)
  },
  {
    title: 'How to review',
    id: HelpModel.GETTING_STARTED_REVIEWING,
    component: new SptComponentRef(HelpGettingStartedReviewingComponent)
  },
  {
    title: 'Help',
    id: HelpModel.TOC,
    component: new SptComponentRef(HelpTocComponent)
  },

  {
    title: 'Setup Microphone',
    id: HelpModel.MICROPHONE,
    component: new SptComponentRef(HelpSelectMicrophoneComponent)
  },

  {
    title: 'Gain Settings',
    id: HelpModel.GAIN,
    component: new SptComponentRef(HelpGainComponent)
  },

  {
    title: 'Compressor Settings',
    id: HelpModel.COMPRESSOR,
    component: new SptComponentRef(HelpCompressorComponent)
  },

  {
    title: 'Record Latency Test',
    id: HelpModel.LATENCY_TEST,
    component: new SptComponentRef(HelpRecordLatencyComponent)
  },

  {
    title: 'Latency Vs. Offset',
    id: HelpModel.LATENCY_VS_OFFSET,
    component: new SptComponentRef(HelpLatencyVsOffsetComponent)
  }
];

helpComponents.forEach((c: ComponentDictItem) => {
  componentRefDict.set(c.id, c);
});

export default componentRefDict;
