import {Action, createReducer, on} from '@ngrx/store';
import {concatElectronBuildVersion} from '@spout/any-shared/models';
import {loadInstalledElectron, StudioActions} from '@spout/web-global/actions';
import {initialStudioState, StudioInstalled, StudioVersionState} from '@spout/web-global/models';

const reducer = createReducer(
  initialStudioState,

  on(StudioActions.loadStudios, (state: StudioVersionState) => state),

  on(StudioActions.hasUiUpdate, (state: StudioVersionState, action) => {
    return {
      ...state,
      serviceWorker: {
        ...state.serviceWorker,
        checkingForUpdate: false,
        hasUiUpdate: action.hasUiUpdate
      }
    };
  }),

  on(loadInstalledElectron, (state: StudioVersionState, action) => {
    return {
      ...state,
      installed: <StudioInstalled>{
        ...state.installed,
        electron: action.installed.build,
        electronAppBuild: concatElectronBuildVersion(action.installed.build),
        platform: action.installed.platform
      }
    };
  })
);

export function studioReducer(state: StudioVersionState | undefined, action: Action): StudioVersionState {
  return reducer(state, action);
}
