import {Injectable, NgZone} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {genreAggregate, upsertWebsocketRegistry, websocketIsDisconnectedAction} from '@spout/web-global/actions';
import {dispatchNgrxFirestoreAggregate, firestoreGenresPath} from '@spout/web-global/fns';
import {AuthAccountStates, AuthAccountStatesConnect, GENRE_FEATURE_KEY} from '@spout/web-global/models';
import {selectAuthAccountConnect$} from '@spout/web-global/selectors';
import {onSnapshot, QuerySnapshot} from 'firebase/firestore';
import {SptFirestoreService} from '../firestore';
import {OnWebsocketMonitor} from '../websocket-monitor/websocket-monitor';

@Injectable()
export class GenreEffects implements OnWebsocketMonitor {
  private _databaseSub: (() => void) | undefined;

  constructor(
    private sptFirestore: SptFirestoreService,
    private actions$: Actions,
    private store: Store<AuthAccountStatesConnect>,
    private zone: NgZone
  ) {
    const that = this;
    this.zone.run(() => {
      this.store.dispatch(
        upsertWebsocketRegistry({
          id: GENRE_FEATURE_KEY
        })
      );
    });

    this.store.pipe(selectAuthAccountConnect$).subscribe((s: AuthAccountStatesConnect) => {
      if (s.doConnect) {
        this.onConnect.call(this, s.user);
      } else {
        this.onDisconnect.call(this, s.user);
      }
    });
  }

  onConnect(user: AuthAccountStates): void {
    const that = this;
    if (!this._databaseSub) {
      this.zone.run(() => {
        that.store.dispatch(
          websocketIsDisconnectedAction({
            id: GENRE_FEATURE_KEY
          })
        );
      });

      this._databaseSub = onSnapshot(
        this.sptFirestore.collectionRef(firestoreGenresPath()),
        (snapshot: QuerySnapshot) => {
          dispatchNgrxFirestoreAggregate<AuthAccountStatesConnect>(this.store, snapshot, genreAggregate, this.zone);
        },
        (err: Error) => {
          console.error(err);
        },
        () => {
          /* noop */
        }
      );
    }
  }

  onDisconnect(user: AuthAccountStates): void {
    const that = this;
    if (this._databaseSub) {
      this._databaseSub();

      that.zone.run(() => {
        that.store.dispatch(
          websocketIsDisconnectedAction({
            id: GENRE_FEATURE_KEY
          })
        );
      });
    }
  }
}
