import {baseElectronVersion} from './spout-build';
import {BuildVersionElectron, BuildVersionUi} from './spout-version.model';

export function concatBuildVersion(u: BuildVersionUi | BuildVersionElectron): string {
  return `${u.year}.${u.month}.${u.buildNumber}`;
}

export function concatUiBuildVersion(u: BuildVersionUi): string {
  return `${u.year}.${u.month}.${u.angularVersion}.${u.buildNumber}`;
}

export function concatElectronBuildVersion(u: BuildVersionElectron): string {
  return `${u.year}.${u.month}.${u.electronMajorVersion}.${u.buildNumber}`;
}

export function getInstalledElectronVersion(): string {
  return baseElectronVersion.electronVersion;
}

export function getInstalledElectronAppBuildVersion(): string {
  return concatElectronAppBuildVersionFromString(baseElectronVersion);
}

export function concatElectronAppBuildVersionFromString(e: BuildVersionElectron): string {
  return `${e.year}.${e.month}.${e.buildNumber}`;
}

export function firestoreUIVersionIsGreaterThanLocal(f: BuildVersionUi | null, l: BuildVersionUi | null): boolean {
  if (f === null || f === undefined || l === null || l === undefined) {
    return false;
  }

  if (f && l) {
    if (f.year > l.year) {
      return true;
    } else if (parseInt(f.month, 10) > parseInt(l.month, 10)) {
      return true;
    } else if (f.angularVersion > l.angularVersion) {
      return true;
    } else if (f.buildNumber > l.buildNumber) {
      return true;
    }
  }

  return false;
}

export function firestoreElectronVersionIsGreaterThanLocal(
  f: BuildVersionElectron | null,
  l: BuildVersionElectron | null
): boolean {
  return f !== null && l !== null && (f.year > l.year || f.month > l.month || f.buildNumber > l.buildNumber);
}
