import {FileType} from '../file-type';
import {SptSystemInformation} from '../system-information';
import {EntityType} from './tree.model';
import {UserPermissions} from './user-permissions.model';

export interface FileArrayBufferType {
  type: FileType;
  arraybuffer: ArrayBuffer;
}

// export interface FileAudioBufferType {
//   type: FileType;
//   audiobuffer: AudioBuffer;
// }

export interface FileUint8ArrayType {
  type: FileType;
  uint8Array: Uint8Array;
}

export interface URLType {
  type: FileType;
  url: string;
}

export interface AudioBufferMetaData {
  type: FileType;
  length?: number;
  duration?: number;
  bufferSize?: number;
  numberOfChannels: number;
  sampleRate?: number;
  /**
   * Returns the number of audio samples per pixel of the waveform data
   */
  scale: number;
  /**
   * Returns the number of bits per sample, either 8 or 16
   */
  bits: number;
  maxY: number;
  minY: number;
  fileSize: number;
}

// AudioMetaDataEntity is in web-shared lib because of AudioBuffer interface
export interface AudioFileMetaData extends AudioBufferMetaData {
  //  fileId
  // Matches AudioBufferData.id
  id: string;
  name: string;
  entityType: EntityType;
  filename?: string;

  // BackBlaze
  fileUploaded: boolean;

  projectId: string;

  savedTimestamp?: number;

  desktopInformation?: SptSystemInformation | null;

  storageLocation: 'google' | 'backblazeb2';
}

export type AudioFileMetaDataEntity = AudioFileMetaData & UserPermissions;

export interface ExtensionBlob {
  extension: string;
  blob: any;
}
