<p>
  A compression effect which lowers the volume of the loudest parts of the signal in order to help prevent clipping and
  distortion that can occur when multiple sounds are played and multiplexed together at once.
</p>

<div class="mat-h4 help-content-subtitle">threshold</div>
<div class="caption help-content-params">min: -100, max: 0, default: -24</div>
<p>The decibel value above which the compression will start taking effect.</p>

<div class="mat-h4 help-content-subtitle">knee</div>
<div class="caption help-content-params">min: 0, max: 40, default: 30</div>
<p>
  A decibel value representing the range above the threshold where the curve smoothly transitions to the compressed
  portion.
</p>

<div class="mat-h4 help-content-subtitle">ratio</div>
<div class="caption help-content-params">min: 1, max: 20, default: 12</div>
<p>The amount of change, in dB, needed in the input for a 1 dB change in the output.</p>

<!--<div class="mat-h4">reduction</div>-->
<!--<div class="caption">min: -20, max: 0</div>-->
<!--<p>The amount of gain reduction currently applied by the compressor to the signal.</p>-->

<div class="mat-h4 help-content-subtitle">attack</div>
<div class="caption help-content-params">min: 0, max: 1, default: .003</div>
<p>
  The amount of time, in seconds, required to reduce the gain by 10 dB. It defines how quickly the signal is adapted
  when its volume is increased.
</p>

<div class="mat-h4 help-content-subtitle">release</div>
<div class="caption help-content-params">min: 0, max: 1, default: .25</div>
<p>
  The amount of time, in seconds, required to increase the gain by 10 dB. It defines how quick the signal is adapted
  when its volume is reduced.
</p>
