import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {SptAudioService} from '@spout/web-global/data-access';

@Component({
  selector: 'spt-help-record-latency-content',
  templateUrl: './help-record-latency-content.component.html',
  styleUrls: ['./help-record-latency-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpRecordLatencyContentComponent {
  constructor(private sptAudioService: SptAudioService) {}
  refresh(): void {
    this.sptAudioService.refreshAudioDeviceList().subscribe(() => {});
  }
}
