import {Dictionary} from '@ngrx/entity/src/models';
import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {
  CurrentIds,
  MixEntity,
  Musician,
  PartialProjectState,
  ProjectEntity,
  ProjectState,
  SongEntity,
  TrackEntity
} from '@spout/any-shared/models';
import {
  createPassThroughFeatureSelector,
  createPassThroughSelector,
  getDefaultEntityConfig,
  isCreatedBy
} from '@spout/web-global/fns';
import {
  AccountState,
  getProjectRoleTitle,
  getProjectRoleType,
  PROJECT_FEATURE_KEY,
  StudioAppState
} from '@spout/web-global/models';
import {hasValue} from '@uiux/fn';
import {selectAccountState, selectUid} from '../account.selectors';
import {selectCurrentIDsFromStore, selectCurrentIDsFromStore_passThrough} from './device-storage.selectors';
import {selectCurrentMixEntity} from './mix-storage.selectors';

// PROJECT PROJECT PROJECT PROJECT
// PROJECT PROJECT PROJECT PROJECT
// PROJECT PROJECT PROJECT PROJECT
// PROJECT PROJECT PROJECT PROJECT

export const selectProjectState = createFeatureSelector<PartialProjectState>(PROJECT_FEATURE_KEY);

export const selectProjectState_passThrough = createPassThroughFeatureSelector<StudioAppState, PartialProjectState>(
  PROJECT_FEATURE_KEY
);

export const selectProjectEntities = (state: StudioAppState): Dictionary<ProjectEntity> => {
  if (state.project) {
    return state.project.entities;
  }

  return {};
};

export const selectAllProjects = createSelector(
  selectProjectEntities,
  (entities: Dictionary<ProjectEntity>): ProjectEntity[] => {
    if (Object.keys(entities).length) {
      return <ProjectEntity[]>Object.values(entities);
    }

    return <ProjectEntity[]>[];
  }
);

export const selectAllProjects_passThrough = createPassThroughSelector(
  selectProjectEntities,
  (entities: Dictionary<ProjectEntity>): ProjectEntity[] => {
    if (Object.keys(entities).length) {
      return <ProjectEntity[]>Object.values(entities);
    }

    return <ProjectEntity[]>[];
  }
);

export const selectDefaultProject = createSelector(
  selectAllProjects,
  selectAccountState,
  (entities: ProjectEntity[], account: AccountState): ProjectEntity | null => {
    return <ProjectEntity>getDefaultEntityConfig(<{isDefault: boolean; createdByUID: string}[]>entities, account);
  }
);

export const getProjectById = createSelector(
  selectProjectEntities,
  (entities: Dictionary<ProjectEntity>, props: {id: string}): ProjectEntity | undefined => entities[props.id]
);

export const selectCurrentProjectEntity: MemoizedSelector<any, ProjectEntity | undefined> = createPassThroughSelector(
  selectAllProjects_passThrough,
  selectCurrentIDsFromStore_passThrough,
  (projects: ProjectEntity[], ids: CurrentIds | null): ProjectEntity | undefined => {
    return projects.find((project: ProjectEntity) => ids && project.id === ids.currentProjectId);
  }
);

export const selectCurrentProjectEntity_passThrough: MemoizedSelector<any, ProjectEntity | undefined> =
  createPassThroughSelector(
    selectAllProjects_passThrough,
    selectCurrentIDsFromStore_passThrough,
    (projects: ProjectEntity[], ids: CurrentIds | null): ProjectEntity | undefined => {
      return projects.find((project: ProjectEntity) => ids && project.id === ids.currentProjectId);
    }
  );

export const getProjectEntityById = (projectId: string | undefined) =>
  createSelector(selectAllProjects, (projects: ProjectEntity[]) =>
    projects.find((project: ProjectEntity) => projectId && project.id === projectId)
  );

export const selectedCurrentProjectEntityIsCreatedByLoggedInMusician = createSelector(
  selectCurrentProjectEntity,
  selectUid,
  (trackEntity: ProjectEntity | undefined, uid: string | null): boolean => {
    return trackEntity && uid ? isCreatedBy(trackEntity, uid) : false;
  }
);

export const notSelectedCurrentProjectEntityIsCreatedByLoggedInMusician = createSelector(
  selectCurrentProjectEntity,
  selectUid,
  (trackEntity: ProjectEntity | undefined, uid: string | null): boolean => {
    return trackEntity && uid ? !isCreatedBy(trackEntity, uid) : false;
  }
);

// export const selectedIsProjectOwner = createSelector(
//   selectCurrentProjectEntity,
//   selectUid,
//   (project: ProjectEntity | undefined, uid: string | null) => {
//     if (project && uid) {
//       if (project.members[uid]) {
//         return project.members[uid].role.owner;
//       } else {
//         return false;
//       }
//     }
//
//     return false;
//   }
// );

// export const selectIsMixOwner = createSelector(
//   selectCurrentMixEntity,
//   selectUid,
//   (song: MixEntity | null, uid: string | null) => {
//     if (song && uid) {
//       if (song.members[uid]) {
//         return song.members[uid].role.owner;
//       } else {
//         return false;
//       }
//     }
//
//     return false;
//   }
// );

export const selectCurrentProjectEntityMemberList = createSelector(
  selectCurrentProjectEntity,
  (projectEntity: ProjectEntity | undefined) => {
    if (projectEntity && !!Object.keys(projectEntity.members).length) {
      return Object.values(projectEntity.members).map((musician: Musician) => {
        return {
          ...musician,
          roleTitle: getProjectRoleTitle(musician.role),
          roleType: getProjectRoleType(musician.role)
        };
      });
    }

    return [];
  }
);

export const selectCurrentProjectMembersNotOwners = createSelector(
  selectCurrentProjectEntityMemberList,
  (m: Musician[]) => {
    return m.filter((m: Musician) => !m.role.owner);
  }
);

export const selectCurrentProjectEntityOwnerList = createSelector(
  selectCurrentProjectEntity,
  (projectEntity: ProjectEntity | undefined) => {
    if (projectEntity && !!Object.keys(projectEntity.owners).length) {
      return Object.values(projectEntity.owners).map((musician: Musician) => {
        return {
          ...musician,
          roleTitle: getProjectRoleTitle(musician.role),
          roleType: getProjectRoleType(musician.role)
        };
      });
    }

    return [];
  }
);

export const selectCurrentProjectMembersAndOwners = createSelector(
  selectCurrentProjectEntityMemberList,
  selectCurrentProjectEntityOwnerList,
  (members: Musician[], owners: Musician[]) => {
    const entities: {[key: string]: Musician} = [...members, ...owners].reduce(
      (a: {[key: string]: Musician}, m: Musician) => {
        a[m.uid] = m;

        return a;
      },
      <{[key: string]: Musician}>{}
    );

    return Object.values(entities);
  }
);

export const selectAllProjectsIOwn = createSelector(
  selectAllProjects,
  selectUid,
  (projects: ProjectEntity[], uid: string | null) => {
    return projects.filter((project: ProjectEntity) => (uid ? isCreatedBy(project, uid) : false));
  }
);

export const selectCurrentProjectIsDefault = createSelector(
  selectCurrentProjectEntity,
  (project: ProjectEntity | undefined) => (hasValue(project) && project ? project.isDefault : false)
);

export const selectCurrentProjectId = createSelector(
  selectCurrentProjectEntity,
  (project: ProjectEntity | undefined): string | null => (project ? project.id : null)
);

export const selectCurrentProjectNamePassThrough = createPassThroughSelector(
  selectCurrentProjectEntity_passThrough,
  (project: ProjectEntity | undefined) => (project ? project.name : null)
);

export const projectIsCreatedByLoggedInMusician = createSelector(
  selectUid,
  selectCurrentProjectEntity,
  (uid: string | null, project: ProjectEntity | undefined) => {
    return project && uid ? isCreatedBy(project, uid) : false;
  }
);

export const selectProjectNotCreatedByLoggedInMusician = createSelector(
  selectUid,
  selectCurrentProjectEntity,
  (uid: string | null, project: ProjectEntity | undefined) => {
    return project && uid ? !isCreatedBy(project, uid) : false;
  }
);

export const selectShareProjectTooltip = createSelector(
  selectCurrentProjectEntity,
  (project: ProjectEntity | undefined) => {
    if (project && project.isDefault) {
      return 'A personal project is not sharable. Create a new project to share.';
    } else {
      return 'Share project with collaborators';
    }
  }
);

export const selectShareProjectTooltipCSSClass = createSelector(
  selectCurrentProjectEntity,
  (project: ProjectEntity | undefined) => {
    if (project && project.isDefault) {
      return 'spt-tooltip-warn';
    } else {
      return '';
    }
  }
);
