<div class="row">
  <ng-container *ngrxLet="subs.hasSubscription$; let hasSubscription">
    <div *ngIf="hasSubscription" class="column">
      <div class="mat-h4 primary">My Subscription Plan</div>

      <table mat-table [dataSource]="subs.currentSubscriptions$ | ngrxPush" class="mat-elevation-z1">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Product Column -->
        <ng-container matColumnDef="productDescription">
          <!--        <th mat-header-cell *matHeaderCellDef>Product</th>-->
          <td mat-cell *matCellDef="let element">
            <div class="mat-h3">{{ element.productDescription.title }}</div>
            <div class="product-description">{{ element.productDescription.description }}</div>
          </td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
          <!--        <th mat-header-cell *matHeaderCellDef>Price</th>-->
          <td mat-cell *matCellDef="let element">
            <span [ngClass]="{primary: element.type === 'total'}">
              {{ element.price }}
            </span>
          </td>
        </ng-container>

        <!--      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div *ngIf="hasSubscription" class="column">
      <mat-card>
        <mat-progress-bar *ngIf="showCancelProgress | ngrxPush" color="accent" mode="indeterminate"></mat-progress-bar>
        <mat-card-content>
          <ng-container *ngIf="payment.cancelAtPeriodEnd | ngrxPush">
            <spt-subscription-end [subscription]="payment.selectCurrentSubscription$ | ngrxPush"></spt-subscription-end>
          </ng-container>

          <div class="mat-h4 primary"></div>
          <div class="change-plan">
            <button mat-flat-button color="primary" (click)="onUpdateSubscription()">Update Subscription</button>
            <button
              mat-flat-button
              color="primary"
              *ngIf="!(payment.cancelAtPeriodEnd | ngrxPush)"
              (click)="onCancelSubscription()"
            >
              Cancel Subscription
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="!hasSubscription" class="column">
      <button class="subscribe-btn" mat-flat-button color="primary" (click)="onUpdateSubscription()">
        Subscribe To A Plan
      </button>
    </div>
  </ng-container>
</div>
