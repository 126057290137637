import {Update} from '@ngrx/entity';
import {Action, createAction, props} from '@ngrx/store';
import {MusicianNotification, NotificationModel} from '@spout/web-global/models';

export const loadNotifications = createAction(
  '[Notifications] Load Notificationss',
  props<{notifications: NotificationModel[]}>()
);

export const addNotification = createAction(
  '[Notifications] Add Notifications',
  props<{notification: NotificationModel}>()
);

export const upsertNotification = createAction(
  '[Notifications] Upsert Notifications',
  props<{notification: NotificationModel}>()
);

export const addNotifications = createAction(
  '[Notifications] Add Notificationss',
  props<{notifications: NotificationModel[]}>()
);

export const upsertNotifications = createAction(
  '[Notifications] Upsert Notificationss',
  props<{notifications: NotificationModel[]}>()
);

export const updateNotification = createAction(
  '[Notifications] Update Notifications',
  props<{notification: Update<NotificationModel>}>()
);

export const updateNotifications = createAction(
  '[Notifications] Update Notificationss',
  props<{notifications: Update<NotificationModel>[]}>()
);

export const deleteNotification = createAction('[Notifications] Delete Notifications', props<{id: string}>());

export const deleteNotifications = createAction('[Notifications] Delete Notificationss', props<{ids: string[]}>());

export const clearNotifications = createAction('[Notifications] Clear Notificationss');

export const sendNotificationsEffect = createAction(
  '[Notifications] Send Notifications',
  props<{payload: MusicianNotification}>()
);

export const openSnackbarEffect = createAction(
  '[Notifications] Snackbar String',
  props<{message: string; level: string; duration: number}>()
);

export const snackBarDismiss = createAction('[Notifications] Snackbar Close');

export const openDialogEffect = createAction(
  '[Notifications] Open Dialog',
  props<{view: string; message?: string; dismissAction?: Action}>()
);
