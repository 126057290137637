import {FileType} from '@spout/any-shared/models';

export interface Endpoints {
  // bucket actions
  createBucketUrl: string;
  deleteBucketUrl: string;
  listBucketUrl: string;
  updateBucketUrl: string;
  getBucketUploadUrl: string;

  // file actions
  listFilesUrl: string;
  listFileVersionsUrl: string;
  listPartsUrl: string;
  hideFileUrl: string;
  fileInfoUrl: string;
  downloadAuthorizationUrl: string;
  downloadFileByNameUrl: (bucketName: string, filename: string) => string;
  downloadFileByIdUrl: (fileId: string) => string;
  deleteFileVersionUrl: string;
  startLargeFileUrl: string;
  getUploadPartUrl: string;
  finishLargeFileUrl: string;
  cancelLargeFileUrl: string;

  // key actions
  createKeyUrl: string;
  deleteKeyUrl: string;
  listKeysUrl: string;
}

export interface BackblazeB2AuthorizedResponse {
  absoluteMinimumPartSize: number;
  accountId: string;
  allowed: {
    bucketId: string;
    bucketName: string;
    capabilities: string[];
    namePrefix: string;
  };
  apiUrl: string;
  authorizationToken: string;
  downloadUrl: string;
  recommendedPartSize: number;
}

export interface BackBlazeB2Options {
  applicationKeyId: string;
  keyName: string;
  applicationKey: string;
  bucketId: string;
}

export interface BackBlazeB2UploadAuthorizedResponse {
  authorizationToken: string;
  bucketId: string;
  uploadUrl: string;
}

export interface BackblazeB2DownloadAuthorizedResponse {
  authorizationToken: string;
  bucketId: string;
  fileNamePrefix: string;
}

export interface BackBlazeB2UploadFile {
  // authorizationToken: string;
  bucketId: string;
  uploadUrl: string;
  uploadAuthToken: string;
  fileName: string;
  data: any;
  hash?: string;
  info?: string;
  contentLength?: number;
  mime: FileType;
  onUploadProgress: (progressEvent: any) => void;
}

export interface GetBucket {
  bucketName: string;
  bucketId: string;
}

export interface ListFilenames {
  bucketId: string;
  startFileName: string;
  maxFileCount: string;
}

export interface ListFileVersions {
  bucketId: string;
  startFileName?: string;
  maxFileCount?: number;
}

export interface HideFile {
  bucketId: string;
  fileName: string;
}

export interface GetDownloadAuthorization {
  bucketId: string;
  fileNamePrefix: string;
  validDurationInSeconds: number;
  b2ContentDisposition: string;
}

export interface DownloadFileByName {
  bucketName: string;
  fileName: string;
}

export interface DeleteFileVersion {
  fileId: string;
  fileName: string;
}

export interface ListParts {
  fileId: string;
  startPartNumber: number;
  maxPartCount: number;
}

export interface DeleteKey {
  applicationKeyId: string;
}

export interface ListKeys {
  maxKeyCount: number;
  startApplicationKeyId: number;
}

export interface CreateKey {
  capabilities: string[];
  keyName: string;
  validDurationInSeconds: number;
  bucketId: string;
  namePrefix: string;
}

export interface B2FileVersion {
  accountId: string;
  action: string;
  bucketId: string;
  contentLength: number;
  contentSha1: string;
  contentType: string;
  fileId: string;
  fileInfo: any;
  fileName: string;
  uploadTimestamp: number;
}

export interface B2ListFileVersionsResponse {
  files: B2FileVersion[];
}
