import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WINDOW_PROVIDERS} from '@web-global/utils';
import {FirebaseUiComponent} from './firebase-ui.component';

@NgModule({
  declarations: [FirebaseUiComponent],
  imports: [CommonModule],
  exports: [FirebaseUiComponent],
  providers: [WINDOW_PROVIDERS]
})
export class FirebaseUiModule {}
