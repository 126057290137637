import {Observable, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';

/**
 * https://stackoverflow.com/questions/47242559/rxjs-buffer-events-when-condition-is-true-pass-events-through-when-condition-i
 *
 * @param condition
 * @param source
 */
export const bufferIf = <T>(condition: Observable<boolean>) => {
  return (source: Observable<T>): Observable<T> => {
    return new Observable<T>(subscriber => {
      const subscriptions: Subscription[] = [];
      const buffer: T[] = [];
      let isBufferOpen = false;

      subscriptions.push(
        // handle source events
        source.subscribe((value: T) => {
          // if buffer is open, or closed but buffer is still being
          // emptied from previously being closed.
          if (isBufferOpen || (!isBufferOpen && buffer.length > 0)) {
            buffer.push(value);
          } else {
            subscriber.next(value);
          }
        }),

        // handle condition events
        condition
          .pipe(
            tap(value => (isBufferOpen = value)),
            filter(value => !value)
          )
          .subscribe(() => {
            while (buffer.length > 0 && !isBufferOpen) {
              subscriber.next(buffer.shift());
            }
          })
      );

      // on unsubscribe
      return () => {
        subscriptions.forEach(sub => sub.unsubscribe());
      };
    });
  };
};
