import {PROJECT_ROLE, TimeStamp} from '@spout/any-shared/models';
import {ACCOUNT_ROLE, FEATURE_ID, SptPermissionState} from '../payment.model';
import {ACCOUNT_FEATURE_KEY} from '../studio-app-keys';
import {authInitialState, AuthState} from './auth.model';
import {CurrentSubscription, SubscriptionItem, TrialModel} from './product.model';

export interface AccountAlgolia {
  email: string | null;
  stageName: string | null;
  displayName: string | null;
  hasSubscription?: boolean;
  uid: string | null;
  active: boolean;
  trialOverAt?: number | null;
}

export interface AccountDefaultIds extends AccountAlgolia {
  defaultProjectId: string | null;
  defaultSongId: string | null;
  defaultTrackId: string | null;
  defaultMixId: string | null;
  // defaultFileId?: string;
}

export interface AccountState extends AccountDefaultIds {
  production: boolean;
  active: boolean;
  displayName: string | null;
  email: string | null;
  emailVerified: boolean | null;
  phoneNumber: string | null;
  photoURL: string | null;
  providerId: string | null;
  stageName: string | null;
  uid: string | null;
  createdAt: TimeStamp | null;
  updatedAt: TimeStamp | null;

  hasGenres: boolean | null;
  hasInstruments: boolean | null;

  monitorStore: boolean;
  monitorMaxAge: number;

  // Set by cloud function triggered by account creation
  hasSubscription?: boolean;
  promoCode?: string | null;

  stripeId?: string | null;
  stripeLink?: string | null;
}

export interface PublicProfile {
  production?: boolean;
  stageName?: string;
  displayName?: string;
  photoURL?: string;
  uid?: string;
}

export interface PromoAddon {
  type: string;
  quantity: number;
}

export declare type PromoCodeType = 'beta' | 'qa' | 'none';

export interface PromoCodeDict {
  code: string | null;
  type: PromoCodeType;
  description: string;
  accountRole: string;
  baseStorageGB: number;
  active: boolean;
  addons: PromoAddon[];
}

export const initialPromoCode: PromoCodeDict = {
  code: null,
  type: 'none',
  description: 'no promo code',
  accountRole: 'null',
  baseStorageGB: -1,
  active: false,
  addons: []
};

export interface AuthAccountStates {
  auth: AuthState;
  account: AccountState;
  isLoaded?: boolean;
}

export interface AuthAccountProductStates extends SptPermissionState {
  auth: AuthState;
  account: AccountState;
  isLoaded: boolean;
  isOnboarded: boolean;
  trial: TrialModel | null;
  subscription: CurrentSubscription | null;
  subscriptionItemEntities: {[id: string]: SubscriptionItem};
  subscriptionId: string | null;
  totalCloudStorageUsed: number;
  promoCode: PromoCodeDict;
}

export interface OnboardingState {
  isLoaded: boolean | undefined;
  isOnboarded: boolean;
  isLoggedIn: boolean;
  uid: string | null;
}

export interface PartialAuthAccountStates {
  [ACCOUNT_FEATURE_KEY]: AuthAccountStates;
}

export interface AuthAccountStatesConnect {
  user: AuthAccountStates;
  doConnect: boolean;
}

export const accountInitialState: AccountState = {
  production: false,
  active: false,
  uid: null,
  defaultProjectId: null,
  defaultSongId: null,
  defaultMixId: null,
  defaultTrackId: null,
  displayName: null,
  email: null,
  phoneNumber: null,
  photoURL: null,
  providerId: null,
  emailVerified: null,
  stageName: null,

  hasGenres: false,
  hasInstruments: false,

  hasSubscription: false,
  promoCode: null,

  // Log store to monitor database
  monitorStore: false,
  monitorMaxAge: 50,

  createdAt: {
    nanoseconds: 0,
    seconds: 0
  },
  updatedAt: {
    nanoseconds: 0,
    seconds: 0
  },

  stripeId: null,
  stripeLink: null
};

export const permissionInitialState: SptPermissionState = {
  accountRole: ACCOUNT_ROLE.PERSONAL,
  projectRole: ACCOUNT_ROLE.PERSONAL,
  permissionRole: ACCOUNT_ROLE.PERSONAL,
  permissions: {
    [FEATURE_ID.ADD_ANNOTATIONS]: false,
    [FEATURE_ID.ADD_COLLABORATORS]: false,
    [FEATURE_ID.CHAT]: false,
    [FEATURE_ID.CLEAR_TRACKS]: false,
    [FEATURE_ID.CREATE_MIXES]: false,
    [FEATURE_ID.CREATE_PROJECTS]: false,
    [FEATURE_ID.CREATE_SONGS]: false,
    [FEATURE_ID.CREATE_TRACKS]: false,
    [FEATURE_ID.COPY_TRACKS]: false,
    [FEATURE_ID.DELETE_TRACKS]: false,
    [FEATURE_ID.EXPORT_SONG]: false,
    [FEATURE_ID.IMPORT_AUDIO_FILES]: false,
    [FEATURE_ID.RECORD]: false,
    // [FEATURE_ID.RENDER_MIX]: false,
    [FEATURE_ID.SHARE_TRACKS]: false
  },
  featurePermissions: []
};

export const authAccountInitialState: AuthAccountProductStates = {
  auth: authInitialState,
  account: accountInitialState,
  subscription: null,
  subscriptionItemEntities: {},
  subscriptionId: null,
  isLoaded: false,
  isOnboarded: true,
  totalCloudStorageUsed: 0,
  trial: null,
  promoCode: {
    ...initialPromoCode
  },
  ...permissionInitialState
};
