import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSliderChange} from '@angular/material/slider';
import {ReplaySubject} from 'rxjs';

@Component({
  selector: 'spt-dynamics-compressor',
  templateUrl: './spt-dynamics-compressor.component.html',
  styleUrls: ['./spt-dynamics-compressor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SptDynamicsCompressorComponent {
  @Input() labelWidth = 70;
  @Input() valueWidth = 45;

  threshold$: ReplaySubject<number> = new ReplaySubject<number>(1);

  @Input()
  set threshold(v: number) {
    this.threshold$.next(v);
  }

  @Output() thresholdChange: EventEmitter<number> = new EventEmitter<number>();

  knee$: ReplaySubject<number> = new ReplaySubject<number>(1);

  @Input()
  set knee(v: number) {
    this.knee$.next(v);
  }

  @Output() kneeChange: EventEmitter<number> = new EventEmitter<number>();

  ratio$: ReplaySubject<number> = new ReplaySubject<number>(1);

  @Input()
  set ratio(v: number) {
    this.ratio$.next(v);
  }

  @Output() ratioChange: EventEmitter<number> = new EventEmitter<number>();

  attack$: ReplaySubject<number> = new ReplaySubject<number>(1);

  @Input()
  set attack(v: number) {
    this.attack$.next(v);
  }

  @Output() attackChange: EventEmitter<number> = new EventEmitter<number>();

  release$: ReplaySubject<number> = new ReplaySubject<number>(1);

  @Input()
  set release(v: number) {
    this.release$.next(v);
  }

  @Output() releaseChange: EventEmitter<number> = new EventEmitter<number>();

  resetDefault() {
    this.thresholdChange.emit(-24);
    this.kneeChange.emit(30);
    this.ratioChange.emit(12);
    this.attackChange.emit(0.003);
    this.releaseChange.emit(0.25);
  }

  onThresholdChange(c: MatSliderChange) {
    if (c.value !== null && c.value !== undefined) {
      this.thresholdChange.emit(c.value);
    }
  }

  onKneeChange(c: MatSliderChange) {
    if (c.value !== null && c.value !== undefined) {
      this.kneeChange.emit(c.value);
    }
  }

  onRatioChange(c: MatSliderChange) {
    if (c.value !== null && c.value !== undefined) {
      this.ratioChange.emit(c.value);
    }
  }

  onAttachChange(c: MatSliderChange) {
    if (c.value !== null && c.value !== undefined) {
      this.attackChange.emit(c.value);
    }
  }

  onReleaseChange(c: MatSliderChange) {
    if (c.value !== null && c.value !== undefined) {
      this.releaseChange.emit(c.value);
    }
  }
}
