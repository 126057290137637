// This is a copy of lib
// https://www.w3.org/TR/mediacapture-streams/#media-track-constraints
// https://github.com/microsoft/TypeScript/blob/master/lib/lib.dom.d.ts#L770
import {
  recordInputCompressorAttack,
  recordInputCompressorKnee,
  recordInputCompressorRatio,
  recordInputCompressorRelease,
  recordInputCompressorThreshold
} from '@spout/web-global/actions';

export interface SptMediaTrackConstraintSet {
  // GoogleMediaTrackConstraints
  autoGainControl?: boolean;
  autoGainControl2?: boolean;
  echoCancellation?: boolean;
  noiseSuppression?: boolean;
  highpassFilter?: boolean;
  echoCancellation2?: boolean;
  noiseSuppression2?: boolean;
  DAEchoCancellation?: boolean;
  noiseReduction?: boolean;
  audioMirroring?: boolean;

  arrayGeometry?: boolean;
  beamforming?: boolean;
  typingNoiseDetection?: boolean;

  // Extra
  aspectRatio?: number;
  channelCount?: number;
  deviceId?: string;
  facingMode?: string;
  frameRate?: number;
  groupId?: string;
  height?: number;
  // latency?: number;
  resizeMode?: string;
  sampleRate?: number;
  sampleSize?: number;

  width?: number;

  microphoneGain: number;
  recordInputCompressorThreshold: number;
  recordInputCompressorKnee: number;
  recordInputCompressorRatio: number;
  recordInputCompressorAttack: number;
  recordInputCompressorRelease: number;
  exportMergeCompressorThreshold: number;
  exportMergeCompressorKnee: number;
  exportMergeCompressorRatio: number;
  exportMergeCompressorAttack: number;
  exportMergeCompressorRelease: number;
}

export interface GoogleMediaTrackConstraints {
  googAutoGainControl: boolean;
  googAutoGainControl2: boolean;
  googEchoCancellation: boolean;
  googNoiseSuppression: boolean;
  googHighpassFilter: boolean;
  googEchoCancellation2: boolean;
  googNoiseSuppression2: boolean;
  googDAEchoCancellation: boolean;
  googNoiseReduction: boolean;
  googAudioMirroring: boolean;

  // googTypingNoiseDetection: boolean;

  // googBeamforming: boolean;

  // googArrayGeometry: boolean;
}
