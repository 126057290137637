import {Observable, Observer} from 'rxjs';

/**
 * Usage:
 *
 * this.store.pipe(
 *   select(selectSomeData),
 *   memoize()
 * .subscribe((result: any) => { ...  });
 *
 */
export const delayDebounce = <T>(
  delayMs: number = 0,
  debounceTime: number = 0
): ((source: Observable<T>) => Observable<T>) => {
  let delay: any | undefined | null;
  let timer: any | undefined | null;
  let delayFinished: boolean = false;

  return (source: Observable<T>) => {
    return new Observable((observer: Observer<T>) => {
      return source.subscribe({
        next(x: T) {
          console.log('delay value', x);
          if (!delay) {
            console.log('delay start');
            delay = setTimeout(() => {
              delayFinished = true;
              observer.next(x);
              console.log(`delay finished ${x}`);
            }, delayMs);
          }

          if (delayFinished) {
            console.log('delay debounce');
            if (timer) {
              clearTimeout(timer);
              timer = null;
              console.log('delay clear timer');
            }

            timer = setTimeout(() => {
              observer.next(x);
              console.log(`delay debounce ${x}`);
            }, debounceTime);
          }
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        }
      });
    });
  };
};
