/**
 * Not used for recording,
 * See code here libs/web-external/audio-worklet/src/lib/audio-worklet.ts:173
 * @param latencyMilliseconds
 * @param sampleRate
 */
export function recordLatency(latencyMilliseconds: number, sampleRate: number): number {
  const samplesPerMillisecond = sampleRate / 1000;
  const recordStartLatencyMS = Math.ceil(latencyMilliseconds * samplesPerMillisecond);

  console.log('this.audioCtx.sampleRate', sampleRate);
  console.log('samplesPerMillisecond', samplesPerMillisecond);

  return recordStartLatencyMS;
}
