import {NavigationExtras, Params} from '@angular/router';
import {APP_ROUTES, MIXER_ROUTES} from './APP_ROUTES';

export interface RouterOutlet {
  [key: string]: string[] | null;
}

export interface RouterAction {
  payload: {
    state: RouterStateUrl;
    config: IxRouteConfig;
  };
}

export interface SptNavigationExtras extends NavigationExtras {
  relativeToActivatedRoute?: boolean;
}

export interface CloseAndNavigateTo {
  commands: any[];
  extras?: SptNavigationExtras;
  routeConfig: IxRouteConfig;
}

export interface RouterError {
  error: any;
}

export interface IxRouteConfig {
  id: string;
  name: string;
  icon: string;
  svgIcon?: string;
  routerLink: string;
  resetOutlet?: string;
  outlets?: RouterOutlet;
  back: string | null;
  relativeToBase?: boolean;
  // url: string;
  // params: any;
  // queryParams: any;
}

export interface IxCurrentRoute {
  url: string;
  params: any;
  queryParams: any;
}

export interface IxRouteStore {
  [key: string]: IxRouteConfig;
}

export interface IxRouteStorage {
  /**
   * true or false based on if user is authenticated
   * 'home' if unauthenticated
   * 'mixer/console' if use is authenticated
   * If false, UI looks for redirect value
   */
  isStandard: boolean;
  redirect: string;
}

/* tslint:disable:no-empty-interface */

export interface SpoutRouterState {
  isInitialLoad: boolean;
  routes: AppRouteStore;
  currentRouteConfig: IxRouteConfig;
  error: any;
  url: string;
  params: Params | null;
  queryParams: Params | null;

  auxRouteIsOpen: boolean;
}

export interface SpoutRouteOnboarding {
  isLoggedIn: boolean;
  isInitialLoad: boolean;
  isBrowser: boolean;
  isElectron: boolean;
  url: string;
}

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface AppRouteStore extends IxRouteStore {
  [key: string]: IxRouteConfig;

  // Not Used
}

/**
 * Supplied to router
 * Note: AOT compilation requires keys to be strings, can't use enums
 */
export const appRoutingConfig: AppRouteStore = {
  account: {
    id: 'account',
    name: 'Account',
    icon: 'account_circle',
    routerLink: APP_ROUTES.ACCOUNT_OVERVIEW,
    back: null
  },

  'account/overview': {
    id: 'account/overview',
    name: 'Stage Name',
    icon: 'account_circle',
    routerLink: APP_ROUTES.ACCOUNT_OVERVIEW,
    back: 'account'
  },
  login: {
    id: 'login',
    name: 'Login',
    icon: 'account_circle',
    routerLink: APP_ROUTES.LOGIN_ELECTRON,
    back: null
  },
  logout: {
    id: 'logout',
    name: 'Logout',
    icon: 'exit_to_app',
    routerLink: APP_ROUTES.LOGOUT,
    back: null
  },

  mixer: {
    id: 'mixer',
    name: 'Mixer',
    icon: 'icon:spoutIcon',
    routerLink: APP_ROUTES.MIXER,
    back: null
  },
  settings: {
    id: 'settings',
    name: 'Settings',
    icon: 'settings',
    routerLink: APP_ROUTES.SETTINGS,
    back: null
  },
  'settings/settings-home': {
    id: 'settings/settings-home',
    name: 'Settings',
    icon: 'settings',
    routerLink: APP_ROUTES.SETTINGS_HOME,
    back: null
  },

  'mixer/mixer-pages/console': {
    id: 'mixer/mixer-pages/console',
    name: 'Console',
    icon: 'icon:spoutIcon',
    resetOutlet: APP_ROUTES.MIXER_CONSOLE,
    outlets: {
      forms: null
      // primary: APP_ROUTES.MIXER_CONSOLE,
    },
    relativeToBase: false,
    routerLink: APP_ROUTES.MIXER_CONSOLE,
    back: null
  },

  console: {
    id: 'console',
    name: 'Console',
    icon: 'icon:spoutIcon',
    resetOutlet: APP_ROUTES.MIXER_CONSOLE,
    outlets: {
      forms: null
      // primary: MIXER_ROUTES.CONSOLE,
    },
    routerLink: MIXER_ROUTES.CONSOLE,
    back: null
  },
  'select-project-song': {
    id: 'select-project-song',
    name: 'Select Project And Song',
    icon: 'icon:checkbox-multiple',
    outlets: {
      forms: [APP_ROUTES.MIXER_SELECT_PROJECT_SONG]
    },
    routerLink: APP_ROUTES.MIXER_SELECT_PROJECT_SONG,
    back: null
  },
  'add-project': {
    id: 'add-project',
    name: 'Add Project',
    icon: 'create_new_folder',
    outlets: {
      forms: [APP_ROUTES.MIXER_ADD_PROJECT]
    },
    routerLink: APP_ROUTES.MIXER_ADD_PROJECT,
    back: null
  },
  'edit-project': {
    id: 'edit-project',
    name: 'Edit Project',
    icon: 'icon:project-settings',
    outlets: {
      forms: [APP_ROUTES.MIXER_EDIT_PROJECT]
    },
    routerLink: APP_ROUTES.MIXER_EDIT_PROJECT,
    back: null
  },
  'add-song': {
    id: 'add-song',
    name: 'Add Song',
    icon: 'icon:song-add',
    outlets: {
      forms: [APP_ROUTES.MIXER_ADD_SONG]
    },
    routerLink: APP_ROUTES.MIXER_ADD_SONG,
    back: null
  },
  'edit-song': {
    id: 'edit-song',
    name: 'Edit Song',
    icon: 'icon:playlist-edit',
    outlets: {
      forms: [APP_ROUTES.MIXER_EDIT_SONG]
    },
    routerLink: APP_ROUTES.MIXER_EDIT_SONG,
    back: null
  },
  'add-track': {
    id: 'add-track',
    name: 'New Track',
    icon: 'icon:add-track-audio',
    outlets: {
      forms: [APP_ROUTES.MIXER_ADD_TRACK]
    },
    // relativeToBase: false,
    routerLink: APP_ROUTES.MIXER_ADD_TRACK,
    back: null
  },
  'edit-track': {
    id: 'edit-track',
    name: 'Edit Track',
    icon: 'icon:edit-track-audio',
    outlets: {
      forms: [APP_ROUTES.MIXER_EDIT_TRACK]
    },
    routerLink: APP_ROUTES.MIXER_EDIT_TRACK,
    back: null
  },
  'add-mix': {
    id: 'add-mix',
    name: 'Add Mix',
    icon: 'icon:mix-add',
    outlets: {
      forms: [APP_ROUTES.MIXER_ADD_MIX]
    },
    // relativeToBase: false,
    routerLink: APP_ROUTES.MIXER_ADD_MIX,
    back: null
  },
  'edit-mix': {
    id: 'edit-mix',
    name: 'Edit Mix',
    icon: 'icon:mix-add',
    outlets: {
      forms: [APP_ROUTES.MIXER_EDIT_MIX]
    },
    routerLink: APP_ROUTES.MIXER_EDIT_MIX,
    back: null
  },
  'copy-mix': {
    id: 'copy-mix',
    name: 'Copy Mix',
    icon: 'file_copy',
    outlets: {
      forms: [APP_ROUTES.MIXER_COPY_MIX]
    },
    routerLink: APP_ROUTES.MIXER_COPY_MIX,
    back: null
  },
  'latency-test': {
    id: 'latency-test',
    name: 'Latency Test',
    icon: 'speaker_phone',
    outlets: {
      forms: [APP_ROUTES.MIXER_LATENCY_TEST]
    },
    routerLink: APP_ROUTES.MIXER_LATENCY_TEST,
    back: null
  }
};

export const mixerRouteUrlConfigs = Object.keys(appRoutingConfig).reduce((acc: any, key: string) => {
  const routeStoreItem: IxRouteConfig = appRoutingConfig[key];

  if (routeStoreItem.routerLink === '') {
    acc['/'] = appRoutingConfig[key];
  } else if (key === 'default') {
    acc['/'] = appRoutingConfig['default'];
  } else {
    acc[`/${routeStoreItem.routerLink}`] = appRoutingConfig[key];
  }

  // if (key === '/') {
  //   acc[ '/' ] = appRoutingConfig[key];
  // } else if (key === 'default') {
  //   acc[ '/' ] = appRoutingConfig['default'];
  // } else {
  //   acc[ `/${key}` ] = appRoutingConfig[key];
  // }

  return acc;
}, {});

export const routerInitialState: SpoutRouterState = {
  isInitialLoad: true,
  routes: appRoutingConfig,
  currentRouteConfig: appRoutingConfig['login'],
  error: null,

  url: '',
  params: null,
  queryParams: null,

  auxRouteIsOpen: false
};
