import {Component, Inject, Input} from '@angular/core';
import {marketingPaths, stopEvent} from '@spout/web-global/fns';
import {WINDOW} from '@spout/web-global/utils';

@Component({
  selector: 'spt-a-marketing',
  templateUrl: './spt-a-external.component.html',
  styleUrls: ['./spt-a-external.component.scss']
})
export class SptAExternalComponent {
  @Input() href = '';

  constructor(@Inject(WINDOW) private window: Window) {}

  onClick(event: MouseEvent) {
    stopEvent(event);
    if (this.href && this.href.length) {
      this.window.open(marketingPaths(this.href), '_blank');
    }
  }
}
