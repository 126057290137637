import {atLeast} from '@uiux/fn';

export interface D3CanvasMargins {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface D3CanvasDimension {
  width: number;
  height: number;
  margin: D3CanvasMargins;
}

export class D3LayoutV2 {
  _dimensions: D3CanvasDimension = {
    width: 0,
    height: 0,
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  };

  wrapper = null;
  bounds = null;

  atLeast: (val: number) => number = atLeast(0);

  constructor(dim?: D3CanvasDimension) {
    if (dim) {
      this._dimensions.width = this.atLeast(dim.width);
      this._dimensions.height = this.atLeast(dim.height);
      this._dimensions.margin.top = this.atLeast(dim.margin.top);
      this._dimensions.margin.right = this.atLeast(dim.margin.right);
      this._dimensions.margin.bottom = this.atLeast(dim.margin.bottom);
      this._dimensions.margin.left = this.atLeast(dim.margin.left);
    }
  }

  resize(dim: D3CanvasDimension) {
    this._dimensions.width = this.atLeast(dim.width);
    this._dimensions.height = this.atLeast(dim.height);
    this._dimensions.margin.top = this.atLeast(dim.margin.top);
    this._dimensions.margin.right = this.atLeast(dim.margin.right);
    this._dimensions.margin.bottom = this.atLeast(dim.margin.bottom);
    this._dimensions.margin.left = this.atLeast(dim.margin.left);
  }

  get width(): number {
    return this._dimensions.width;
  }

  set width(val) {
    this._dimensions.width = val;
  }

  get height(): number {
    return this._dimensions.height;
  }

  set height(val) {
    this._dimensions.height = val;
  }

  get top(): number {
    return this._dimensions.margin.top;
  }

  set top(val) {
    this._dimensions.margin.top = val;
  }

  get right(): number {
    return this._dimensions.margin.right;
  }

  set right(val) {
    this._dimensions.margin.right = val;
  }

  get bottom(): number {
    return this._dimensions.margin.bottom;
  }

  set bottom(val) {
    this._dimensions.margin.bottom = val;
  }

  get left(): number {
    return this._dimensions.margin.left;
  }

  set left(val) {
    this._dimensions.margin.left = val;
  }

  get boundedWidth(): number {
    return this.atLeast(this._dimensions.width - this._dimensions.margin.left - this._dimensions.margin.right);
  }

  get centerBoundedWidth(): number {
    return this.boundedWidth / 2;
  }

  get boundedHeight(): number {
    return this.atLeast(this._dimensions.height - this._dimensions.margin.top - this._dimensions.margin.bottom);
  }

  set boundedHeight(boundedHeight) {
    this._dimensions.height = this.atLeast(
      boundedHeight + this._dimensions.margin.top + this._dimensions.margin.bottom
    );
  }

  get centerBoundedHeight(): number {
    return this.boundedHeight / 2;
  }

  get translateBounds(): string {
    return `translate(${this.left}px, ${this.top}px)`;
  }

  get translateXAxisBottom(): string {
    return `translateY(${this.boundedHeight}px`;
  }

  translateToolTip(x: number, y: number) {
    x = x + this._dimensions.margin.left;
    y = y + this._dimensions.margin.right;
    return `translate(
            calc( -50% + ${x}px),
            calc(-100% + ${y}px)
        )`;
  }
}
