import {removeTimestampCTorFromDocumentSnapshot} from '../../firestore';
import {initialProduct, ProductEntities, ProductWizardStore} from './payment-wizard.service.model';
import {Price, PriceEntities, PriceInterval, Product, ACCOUNT_ROLE} from '@spout/web-global/models';
import {DocumentChange, DocumentData, QueryDocumentSnapshot} from 'firebase/firestore';

export function getProductData(productRef: QueryDocumentSnapshot<DocumentData>): Product {
  const p: Product = removeTimestampCTorFromDocumentSnapshot<Product>(productRef);
  return {
    ...p,
    id: productRef.id,
    stripe_metadata_baseStorageGB:
      p.stripe_metadata_baseStorageGB === 'null' ? null : parseInt(<any>p.stripe_metadata_baseStorageGB, 10),
    stripe_metadata_description2: p.stripe_metadata_description2 === 'null' ? null : p.stripe_metadata_description2,
    role: p.role === 'null' ? null : p.role
  };
}

export function getPriceData(
  doc: QueryDocumentSnapshot<DocumentData>,
  id: string,
  productId: string,
  product: Product
): Price {
  const d: Price = removeTimestampCTorFromDocumentSnapshot<Price>(doc);
  return {
    ...d,
    id,
    productId,
    role: product.role
    // productHasTrial: product.stripe_metadata_trial === 'month',
    // isTrial: false
  };
}

function getProductByRole(role: string, productEntities: ProductEntities): Product {
  return Object.values(productEntities).reduce(
    (a: Product, p: Product) => {
      if (p.role === role) {
        return p;
      }

      return a;
    },
    <Product>{...initialProduct}
  );
}

export function productEntityByRole(productEntities: ProductEntities) {
  return {
    [ACCOUNT_ROLE.EARLY_ADOPTER]: getProductByRole(ACCOUNT_ROLE.EARLY_ADOPTER, productEntities),
    [ACCOUNT_ROLE.COLLABORATOR]: getProductByRole(ACCOUNT_ROLE.COLLABORATOR, productEntities),
    [ACCOUNT_ROLE.PERSONAL]: getProductByRole(ACCOUNT_ROLE.PERSONAL, productEntities),
    [ACCOUNT_ROLE.BAND]: getProductByRole(ACCOUNT_ROLE.BAND, productEntities)
  };
}

function getPriceByProductAndInterval(priceEntities: PriceEntities, productId: string, interval: string): Price {
  return Object.values(priceEntities).reduce((a: Price, p: Price) => {
    if (p.productId === productId && p.interval === interval) {
      return p;
    }

    return a;
  }, <Price>{});
}

export function monthlyPriceEntityByRole(state: ProductWizardStore, interval: string) {
  return {
    [ACCOUNT_ROLE.EARLY_ADOPTER]: getPriceByProductAndInterval(
      state.priceEntities,
      getProductByRole(ACCOUNT_ROLE.EARLY_ADOPTER, state.productEntities)?.id,
      interval
    ),
    [ACCOUNT_ROLE.COLLABORATOR]: getPriceByProductAndInterval(
      state.priceEntities,
      getProductByRole(ACCOUNT_ROLE.COLLABORATOR, state.productEntities).id,
      interval
    ),
    [ACCOUNT_ROLE.PERSONAL]: getPriceByProductAndInterval(
      state.priceEntities,
      getProductByRole(ACCOUNT_ROLE.PERSONAL, state.productEntities).id,
      interval
    ),
    [ACCOUNT_ROLE.BAND]: getPriceByProductAndInterval(
      state.priceEntities,
      getProductByRole(ACCOUNT_ROLE.BAND, state.productEntities).id,
      interval
    )
  };
}

function findPriceByInterval(state: ProductWizardStore, product: Product, interval: PriceInterval): Price | null {
  return Object.values(state.priceEntities).reduce((found: Price | null, price: Price) => {
    if (price.productId === product.id && price.interval === interval) {
      return price;
    }
    return found;
  }, null);
}

export function selectStorageAddonPriceMonthly(state: ProductWizardStore): Price | null {
  return Object.values(state.productEntities).reduce((a: Price | null, product: Product) => {
    if (product.stripe_metadata_addon === 'storage') {
      return findPriceByInterval(state, product, 'month');
    }
    return a;
  }, null);
}
