import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {ProjectEntity, ProjectState} from '@spout/any-shared/models';
import {
  addProject,
  addProjects,
  authLoggedOut,
  clearProjects,
  createProject,
  createProjectAction,
  deleteProject,
  deleteProjects,
  updateProject,
  updateProjects,
  upsertProject,
  upsertProjects
} from '@spout/web-global/actions';

export const projectAdapter: EntityAdapter<ProjectEntity> = createEntityAdapter<ProjectEntity>();

export const initialProjectsState: ProjectState = projectAdapter.getInitialState({
  // additional entity state properties
  selectedId: null,
  defaultProjectId: null
});

const reducer = createReducer(
  initialProjectsState,
  on(addProject, (state, action) => projectAdapter.addOne(action.project, state)),
  on(createProjectAction, (state, action) => projectAdapter.addOne(action.project, state)),
  on(upsertProject, (state, action) => projectAdapter.upsertOne(action.project, state)),
  on(addProjects, (state, action) => projectAdapter.addMany(action.projects, state)),
  on(upsertProjects, (state, action) => projectAdapter.upsertMany(action.projects, state)),
  on(updateProject, (state, action) => projectAdapter.updateOne(action.project, state)),
  on(updateProjects, (state, action) => projectAdapter.updateMany(action.projects, state)),
  on(deleteProject, (state, action) => projectAdapter.removeOne(action.id, state)),
  on(deleteProjects, (state, action) => projectAdapter.removeMany(action.ids, state)),
  on(clearProjects, state => projectAdapter.removeAll(state)),

  on(createProject, (state, action) => {
    return projectAdapter.addOne(action.project, state);
  }),
  on(authLoggedOut, (state, action) => {
    return projectAdapter.removeAll(state);
  })
);

export function projectReducer(state: ProjectState | undefined, action: Action) {
  return reducer(state, action);
}
