import {ElectronFileError} from '../electron';
import {FileUint8ArrayType} from './audio-file-meta-data';
import {BaseEntity, EntityType} from './tree.model';
import {UserPermissions} from './user-permissions.model';

export interface TrackMixAudioMeta {
  isDefault: boolean;
  updatedAtSeconds?: number;
}

/**
 * Do not change, matches database
 * Keep in sync with libs/web-external/audio-worklet/src/lib/shared-buffer-player-worklet-processor.ts:7
 */
export interface TrackMixAudioSync {
  audioFileMetaDataEntityId: string;
  start: number;
  offsetMs: number;
  stop: number;

  /* seconds */
  audioDuration: number;
  sampleRate: number;
}

export type TrackMixAudioSnippet = TrackMixAudioMeta & TrackMixAudioSync;

export interface TrackMixOptions {
  orderNum: number;
  volume: number;
  isReference: boolean;
  mute: boolean;
}

export interface TrackMix extends TrackMixOptions {
  id: string;
  entityType: EntityType;
  trackId: string;
  mixId: string;
  songId: string;
  projectId: string;
  name: string;
  // Sort Order of Track in mix
  // updatedAt: firebase.firestore.FieldValue;
  updatedAtSeconds?: number;

  /* audioFileEntity.id as key for TrackMixAudioSnippet */
  audioSnippets: {[audioFileEntityId: string]: TrackMixAudioSnippet};
}

export type TrackMixEntity = TrackMix & UserPermissions;

export interface Mix {
  id: string;
  entityType: EntityType;
  name: string;
  description: string;
  songId: string;
  projectId: string;
  isDefault: boolean;

  bpm?: number;
  hasReferenceTrack?: boolean;

  // metrics

  // trackMixes: { [trackId: string]: TrackMix };
}

export type MixEntity = Mix & UserPermissions & BaseEntity;

export interface RenderMixData {
  name: string;
  path: string;
}

export interface SaveExportedMixAsFile extends RenderMixData {
  uint8ArrayType: FileUint8ArrayType;
  error?: ElectronFileError;
}

export interface SaveExportedMixAsFileAccount extends SaveExportedMixAsFile {
  account: string;
}

export interface SelectMixComponent {
  songId: string | null;
  mixes: MixEntity[];
  currentMix: MixEntity | null;
}
