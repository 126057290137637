<p>
  The latency correction tool has been designed to help minimize the impact of latency (the inherent delay in the
  recording process). Please note that latency can change frequently throughout the day. Keep this in mind when
  recording and syncing your tracks. The offset tool helps refine synchronization of multiple tracks during playback.
</p>

<p>
  <strong>Latency</strong> occurs only during the recording process. Latency correction trims off the processing time at
  the beginning of the track that occurs for audio input to travel from the microphone, through controllers ( Focusrite
  for example ), gain processor, and compressor processor. The audio time trimmed is silent input until the audio meets
  the recorder. Once the audio is trimmed, latency correction is finished with the track and has no more impact on
  playback or synchronization of tracks. A track is processed live during the recording session ( when recording is
  active ). Once a track is finished recording, no more processing occurs, the latency time is trimmed from the
  beginning. The track becomes a normal wav file like any other audio file.
</p>

<p>
  The <strong>Offset</strong> tool changes the start time of a single track during playback of a song compared to other
  tracks. An offset of -50ms will start the play of the track 50ms BEFORE the other tracks start to play. An offset of
  50ms will delay the play of the track 50ms AFTER the other tracks start to play. Offset is used to precisely
  synchronize tracks against each other during the playback of a song.
</p>
