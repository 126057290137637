export const authInitialState: AuthState = {
  // Custom
  isLoggedIn: false,
  loginFail: false,
  loginError: {
    code: null,
    message: null
  },
  alertMessage: {
    code: null,
    message: null
  },
  // AngularFire defined
  displayName: 'Guest',
  email: null,
  phoneNumber: null,
  photoURL: null,
  uid: null,
  uidHash: null,
  providerId: null,

  emailVerified: false,
  isAnonymous: true,
  // stsTokenManager: {
  //   apiKey: null,
  //   refreshToken: null,
  //   accessToken: null,
  //   expirationTime: null,
  // },

  isOnline: false
};

export interface AlertMesssage {
  code: number | null;
  message: string | null;
}

export interface LoginError {
  code: string | null;
  message: string | null;
}

export interface AuthEmail {
  email: string;
  password: string;
}

/**
 * stsTokenManager
 */
// export interface StsTokenManager {
//   apiKey: string;
//   refreshToken: string;
//   accessToken: string;
//   expirationTime: number;
// }

/**
 * Provider Data
 */
export interface Provider {
  uid: string;
  displayName: string;
  photoURL: string;
  email: string;
  providerId: string;
}

export interface AuthState {
  // Custom
  isLoggedIn: boolean;
  loginFail: boolean;
  loginError: LoginError;
  alertMessage: AlertMesssage;
  uidHash: string | null;
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  uid: string | null;
  providerId: string | null;

  emailVerified: boolean;
  isAnonymous: boolean;
  // stsTokenManager: StsTokenManager;

  isOnline: boolean;
}
