import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {createPassThroughFeatureSelector} from '@spout/web-global/fns';
import {StudioAppState, TRANSPORT_FEATURE_KEY, TransportState} from '@spout/web-global/models';
import {pipe} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {selectedNotCurrentTrackEntityIsCreatedByLoggedInMusician} from '../entities/track-storage.selectors';

export const selectMasterControlState = createFeatureSelector<TransportState>(TRANSPORT_FEATURE_KEY);

export const selectMasterControlStatePassThrough = createPassThroughFeatureSelector<StudioAppState, TransportState>(
  TRANSPORT_FEATURE_KEY
);

export const selectMasterControlIsPlaying = createSelector(
  selectMasterControlState,
  (state: TransportState) => state.isPlayingMix
);

export const selectPlayMixOnRecord = createSelector(selectMasterControlState, state => state.playMixOnRecord);

export const selectPlayMetronomeOnRecord = createSelector(
  selectMasterControlState,
  state => state.isRecording && state.playMetronomeOnRecord
);

export const selectRecordOptions = createSelector(selectMasterControlState, state => {
  return {
    playMixOnRecord: state.playMixOnRecord,
    playMetronomeOnRecord: state.playMetronomeOnRecord
  };
});

export const selectMasterControlIsPlaying$ = pipe(select(selectMasterControlIsPlaying), distinctUntilChanged());

export const selectMasterControlIsPlayingOnly = createSelector(selectMasterControlState, state => {
  return state.isPlayingMix;
});

export const selectMasterControlIsRecording = createSelector(
  selectMasterControlState,
  (state: TransportState): boolean => state.isRecording
);

export const selectMasterControlIsRecording$ = pipe(select(selectMasterControlIsRecording), distinctUntilChanged());

export const selectMasterControlStopRecording = createSelector(
  selectMasterControlState,
  (state: TransportState) => state.isRecording === false
);

export const getIsPlayingOrRecording = createSelector(
  selectMasterControlIsPlaying,
  selectMasterControlIsRecording,
  (isPlaying: boolean, isRecording: boolean) => isPlaying || isRecording
);

export const getStopPlaying = createSelector(selectMasterControlState, (state: TransportState) => !state.isPlayingMix);

export const selectIsSavingRecordedFileTrackId = createSelector(
  selectMasterControlState,
  (state: TransportState) => state.isSavingRecordedFileTrackId
);

export const selectDisableRecord = createSelector(
  selectedNotCurrentTrackEntityIsCreatedByLoggedInMusician,
  selectMasterControlIsPlaying,
  (notCreatedByLoggedInMusician, isPlaying): boolean => {
    return notCreatedByLoggedInMusician || isPlaying;
  }
);

export const masterControlQuery = {
  getMasterControlState: selectMasterControlState,
  getIsPlaying: selectMasterControlIsPlaying,
  getIsRecording: selectMasterControlIsRecording,
  getIsPlayingOrRecording,
  getStopPlaying,
  selectIsSavingRecordedFileTrackId
};
