import {Inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {websocketIsConnectedAction, websocketIsDisconnectedAction} from '@spout/web-global/actions';
import {AppEventName, ENVIRONMENT, FirebaseAnalyticEventParams, IEnvironmentState} from '@spout/web-global/models';
import {getWebSocketIdConnected} from '@spout/web-global/selectors';
import {hasValue} from '@uiux/fn';

import {AnalyticsCallOptions, getAnalytics, logEvent} from 'firebase/analytics';
import {FirebaseApp, initializeApp} from 'firebase/app';
import {
  ActionCodeSettings,
  Auth,
  AuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  Persistence,
  PopupRedirectResolver,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signInWithPopup,
  signInWithRedirect,
  UserCredential
} from 'firebase/auth';
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  DocumentSnapshot,
  FieldValue,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc,
  writeBatch,
  DocumentData,
  GeoPoint,
  clearIndexedDbPersistence
} from 'firebase/firestore';
import {Functions, getFunctions, HttpsCallable, httpsCallable} from 'firebase/functions';
import {
  FirebaseStorage,
  ref,
  getStorage,
  getDownloadURL,
  uploadBytesResumable,
  UploadTask,
  deleteObject
} from 'firebase/storage';
import {Observable, Observer} from 'rxjs';
import {take} from 'rxjs/operators';
import {removeTimeStampCTorFromData, removeTimestampCTorFromDocumentSnapshot} from './spt-firestore.fns';
import {Exists} from './spt-firestore.model';

@Injectable({
  providedIn: 'root'
})
export class SptFirestoreService {
  readonly _app: FirebaseApp;
  readonly _db: Firestore;
  readonly _auth: Auth;
  readonly _storage: FirebaseStorage;
  readonly _functions: Functions;
  readonly _analytics: any;

  get db() {
    return this._db;
  }

  get functions() {
    return this._functions;
  }

  get auth() {
    return this._auth;
  }

  get storage() {
    return this._storage;
  }

  constructor(private store: Store, @Inject(ENVIRONMENT) private environment: IEnvironmentState) {
    const name = this.environment.emulator ? '[spoutEmulator]' : '[spoutProd]';

    this._app = initializeApp(this.environment.firebase, name);
    this._analytics = getAnalytics(this._app);
    this._db = getFirestore(this._app);
    this._auth = getAuth(this._app);
    this._storage = getStorage(this._app);
    this._functions = getFunctions(this._app);

    this.logEvent('notification_received');
    // if (this.environment.emulator) {
    //   this._db.useEmulator('localhost', EMULATOR_PORTS.FIRESTORE);
    //   this._auth.useEmulator(`http://localhost:${EMULATOR_PORTS.AUTH}`);
    //   this._storage.useEmulator('localhost', EMULATOR_PORTS.STORAGE);
    //   this._functions.useEmulator('localhost', EMULATOR_PORTS.FUNCTIONS);
    // }

    // const messaging = firebase.messaging();

    // https://youtu.be/ciu62KLlwGQ?t=318
    // firestore()
    //   .enablePersistence({
    //     synchronizeTabs: true
    //   })
    //   .then(() => {
    //     console.log('FIRESTORE PERSISTENCE ENABLED');
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });

    // console.log('SptFirestoreService INIT');
  }

  /// Firebase Server Timestamp
  get timestamp(): FieldValue {
    // return firebase.database.ServerValue.TIMESTAMP;
    return serverTimestamp();
  }

  logEvent(
    eventName: AppEventName<string>,
    eventParams?: FirebaseAnalyticEventParams,
    options?: AnalyticsCallOptions
  ): void {
    logEvent(this._analytics, eventName, eventParams, options);
  }

  /// **************
  collectionRef(path: string): CollectionReference<DocumentData> {
    return collection(this._db, path);
  }

  /// **************
  /// Get Data

  docRef(path: string): DocumentReference<DocumentData> {
    return doc(this._db, path);
  }

  doc$(path: string): Observable<DocumentSnapshot> {
    const that = this;
    return new Observable((observer: Observer<DocumentSnapshot>) => {
      const doc = getDoc(that.docRef(path));
      doc.then((snap: DocumentSnapshot) => {
        observer.next(removeTimestampCTorFromDocumentSnapshot(snap));
      });
    });
  }

  docData$<T>(path: string): Observable<T> {
    const that = this;

    return new Observable((observer: Observer<T>) => {
      const doc = getDoc(that.docRef(path));
      doc.then((snap: DocumentSnapshot) => {
        observer.next(<T>removeTimestampCTorFromDocumentSnapshot(snap));
        observer.complete();
      });
    });
  }

  docData<T>(path: string): Promise<T> {
    return getDoc(this.docRef(path)).then((d: DocumentSnapshot) => {
      return removeTimestampCTorFromDocumentSnapshot(d);
    });
  }

  /**
   * adds createdAt field
   *
   * Usage:
   * db.set('items/ID', data) })
   *
   * @param {DocPredicate<T>} ref
   * @param data
   * @returns {Promise<void>}
   */
  setDoc(path: string, data: any): Promise<void> {
    return setDoc(this.docRef(path), this.payloadForSet(data));
  }

  set$<T>(path: string, data: any, options?: any): Observable<T> {
    return new Observable((observer: Observer<any>) => {
      const payload = this.payloadForSet(data);

      // console.log(path, data, options);
      const p: Promise<void> = options
        ? setDoc(this.docRef(path), payload, options)
        : setDoc(this.docRef(path), payload);
      p.then(() => {
        observer.next(removeTimeStampCTorFromData(payload));
        observer.complete();
      }).catch((e: any) => {
        console.log('error');
        console.log(e);
        observer.error(e);
      });
    });
  }

  setWithoutTimestamp<T>(path: string, data: any): Promise<void> {
    return setDoc(
      this.docRef(path),
      {
        ...data
      },
      {merge: true}
    );
  }

  /**
   * adds updatedAt field do document
   * @param path
   * @param data
   */
  update<T>(path: string, data: any): Observable<Exists<T>> {
    const that = this;

    return new Observable((observer: Observer<any>) => {
      updateDoc(that.docRef(path), that.payloadForUpdate(data))
        .then(() => {
          // console.log('result', result);
          // Get data after it is set

          getDoc(that.docRef(path))
            .then((setSnap: DocumentSnapshot) => {
              observer.next(<Exists<T>>{
                data: removeTimeStampCTorFromData(setSnap.data()),
                exists: true
              });
              observer.complete();
            })
            .catch(error => {
              observer.error(error);
            });
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }

  updateWithoutTimestamp<T>(path: string, data: any): Promise<void> {
    return setDoc(this.docRef(path), data, {merge: true});
  }

  deleteDoc<T>(path: string): Promise<void> {
    return deleteDoc(this.docRef(path));
  }

  deleteDoc$<T>(path: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      deleteDoc(this.docRef(path)).then(
        () => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  /**
   * adds createdAt field
   *
   * Usage:
   * db.add('items', data) })
   *
   * @param {CollectionPredicate<T>} ref
   * @param data
   * @returns {Promise<firebase.firestore.DocumentReference>}
   */
  addDoc<T>(path: string, data: any): Promise<DocumentReference> {
    return addDoc(this.collectionRef(path), this.payloadForSet(data));
  }

  /**
   * Usage:
   * const geopoint = this.db.geopoint(38, -119)
   * return this.db.add('items', { location: geopoint })
   *
   * @param {number} lat
   * @param {number} lng
   * @returns {firebase.firestore.GeoPoint}
   */
  geopoint(lat: number, lng: number): GeoPoint {
    return new GeoPoint(lat, lng);
  }

  /**
   * If doc exists update, otherwise set
   *
   * Usage:
   * this.db.upsert('notes/xyz', { content: 'hello dude'})
   *
   * @param {DocPredicate<T>} ref
   * @param data
   * @returns {Promise<any>}
   */
  upsertDoc<T>(path: string, data: any): Observable<Exists<T>> {
    const that = this;

    return new Observable((observer: Observer<any>) => {
      getDoc(this.docRef(path)).then((snap: DocumentSnapshot): any => {
        if (!snap.exists()) {
          that
            .setDoc(path, data)
            .then((result: any) => {
              // console.log('result', result);
              // Get data after it is set
              getDoc(that.docRef(path))
                .then((setSnap: DocumentSnapshot) => {
                  observer.next(<Exists<T>>{
                    data: removeTimestampCTorFromDocumentSnapshot(setSnap),
                    exists: true
                  });
                  observer.complete();
                })
                .catch(error => {
                  observer.error(error);
                });
            })
            .catch(error => {
              observer.error(error);
            });
        } else {
          that.update<T>(path, data).subscribe((r: Exists<T>) => {
            observer.next(r);
            observer.complete();
          });
        }
      });
    });
  }

  /**
   * Returns true if doc existed, false if not
   * Always returns data if not exists since it's created
   * @param path
   * @param data
   */
  setDocIfNotExist<T>(path: string, data: any): Observable<Exists<T>> {
    const that = this;

    return new Observable((observer: Observer<any>) => {
      getDoc(this.docRef(path)).then((snap: DocumentSnapshot): any => {
        // console.log(snap);
        // console.log(snap.exists());
        // console.log(snap.data());

        if (!snap.exists()) {
          // console.log('NOT EXIST');
          // console.log(path);
          // console.log(data);
          // console.log(that.payloadForSet(data));

          // console.log(that._db);

          setDoc(this.docRef(path), that.payloadForSet(data))
            .then(() => {
              // Get data after it is set
              getDoc(that.docRef(path))
                .then((setSnap: DocumentSnapshot) => {
                  observer.next(<Exists<T>>{
                    data: removeTimeStampCTorFromData(setSnap.data()),
                    exists: true
                  });
                  observer.complete();
                })
                .catch(error => {
                  console.log(error);
                  observer.error(error);
                });
            })
            .catch(error => {
              console.log(error);
              observer.error(error);
            });
        } else {
          observer.next(<Exists<T>>{
            data: removeTimestampCTorFromDocumentSnapshot(snap),
            exists: true
          });
          observer.complete();
        }
      });
    });
  }

  /**
   * If doc exists update, otherwise set
   *
   * Usage:
   * this.db.upsert('notes/xyz', { content: 'hello dude'})
   *
   * @param {DocPredicate<T>} path
   * @param data
   * @returns {Promise<any>}
   */
  setDocMerge<T>(path: string, data: any): Observable<Exists<T>> {
    // console.log(path, data);
    return new Observable((observer: Observer<Exists<T>>) => {
      getDoc(this.docRef(path)).then((snap: DocumentSnapshot): any => {
        // console.log(snap);

        if (!snap.exists) {
          const payload = this.payloadForSet(data);

          // use .set with merge true
          setDoc(this.docRef(path), payload, {merge: true})
            .then(() => {
              // console.log(path, payload);
              observer.next(<Exists<T>>{
                data: <T>removeTimeStampCTorFromData(payload),
                exists: true
              });
              observer.complete();
            })
            .catch((e: any) => {
              console.log(e);
              observer.error(e);
            });
        } else {
          // Get the entire document
          const rootData: any = snap.data();

          if (hasValue(rootData)) {
            const payload = this.payloadForUpdate(data);

            setDoc(this.docRef(path), payload, {merge: true})
              .then(() => {
                observer.next(<Exists<T>>{
                  data: <T>removeTimeStampCTorFromData(payload),
                  exists: true
                });
                observer.complete();
              })
              .catch((e: any) => {
                observer.error(e);
              });
          } else {
            // use .set with merge true
            const payload = this.payloadForSet(data);
            setDoc(this.docRef(path), payload, {merge: true})
              .then(() => {
                observer.next(<Exists<T>>{
                  data: <T>removeTimeStampCTorFromData(payload),
                  exists: true
                });
                observer.complete();
              })
              .catch((e: any) => {
                observer.error(e);
              });
          }
        }
      });
    });
  }

  merge<T>(path: string, data: any): Observable<Exists<T>> {
    return new Observable((observer: Observer<any>) => {
      const payload = this.payloadForSet(data);

      setDoc(this.docRef(path), payload, {merge: true})
        .then(() => {
          observer.next(<Exists<T>>{
            data: <T>removeTimeStampCTorFromData(payload),
            exists: true
          });
          observer.complete();
        })
        .catch((e: any) => {
          observer.error(e);
        });
    });
  }

  payloadForSet(_data: any): any {
    const timestamp: FieldValue = this.timestamp;

    const data = JSON.parse(JSON.stringify(_data));

    const payload: any = {
      ...data,
      createdAt: timestamp,
      updatedAt: timestamp
    };

    return payload;
  }

  payloadForUpdate(_data: any): any {
    const timestamp: FieldValue = this.timestamp;

    const data = JSON.parse(JSON.stringify(_data));

    delete data.createdAt;
    delete data.updatedAtSeconds;

    const payload: any = {
      ...data,
      updatedAt: timestamp
    };

    return payload;
  }

  /// **************
  /// Inspect Data
  /// **************

  /**
   * Usage:
   * this.db.inspectDoc('notes/xyz')
   *
   * @param {DocPredicate<any>} ref
   */
  inspectDoc(path: string): void {
    const tick: number = new Date().getTime();

    getDoc(this.docRef(path)).then((r: any) => {
      const tock: number = new Date().getTime() - tick;
      console.log(`Loaded Document in ${tock}ms`, r);
    });
  }

  /**
   * Usage:
   * this.db.inspectCol('notes')
   *
   * @param {CollectionPredicate<any>} path
   */
  inspectCol(path: string): void {
    const tick: number = new Date().getTime();

    getDocs(this.collectionRef(path)).then((r: any) => {
      const tock: number = new Date().getTime() - tick;
      console.log(`Loaded Collection in ${tock}ms`, r);
    });
  }

  /// **************
  /// Create and read doc references
  /// **************
  /// create a reference between two documents
  connect<T>(host: string, key: string, doc: string): Promise<void | T> {
    return this.docData(host).then((d: unknown | T) => {
      if (d) {
        updateDoc(this.docRef(host), {[key]: <T>d});
      }
      // this.doc(host).update({[key]: d});
    });

    return updateDoc(this.docRef(host), {[key]: this.docRef(doc)});
    // return this.doc(host).update({[key]: this.doc(doc)});
  }

  writeBatch() {
    return writeBatch(this._db);
  }

  httpsCallable<R, T>(functionName: string): HttpsCallable<R, T> {
    return httpsCallable(this.functions, functionName);
  }

  getDownloadURL(url: string): Promise<string> {
    return getDownloadURL(ref(this.storage, url));
  }

  getStorageRef(path: string) {
    return ref(this.storage, path);
  }

  uploadBytesResumable(path: string, blob: Blob): UploadTask {
    return uploadBytesResumable(this.getStorageRef(path), blob);
  }

  deleteStorageFile(path: string) {
    return new Observable((observer: Observer<boolean>) => {
      deleteObject(this.getStorageRef(path))
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch(error => {
          // If file does not exist, continue with deletion process
          observer.next(true);
          observer.complete();
        });
    });
  }

  /// **************
  /// Atomic batch example
  /// **************
  /// Just an example, you will need to customize this method.
  atomic(): Promise<void> {
    // Get a new write batch
    const batch = writeBatch(this._db);

    /// add your operations here
    const itemDoc: DocumentReference = this.docRef('items/myCoolItem');
    const userDoc: DocumentReference = this.docRef('users/userId');
    const currentTime: FieldValue = this.timestamp;
    batch.update(itemDoc, {timestamp: currentTime});
    batch.update(userDoc, {timestamp: currentTime});

    /// commit operations
    return batch.commit();
  }

  /**
   * TODO Move to it's own service
   * @param key
   */
  setWebSocketConnected(key: string): void {
    this.store.pipe(select(getWebSocketIdConnected(key)), take(1)).subscribe((connected: boolean) => {
      // Only dispatch action if websocket is not connected
      if (!connected) {
        this.store.dispatch(
          websocketIsConnectedAction({
            id: key
          })
        );
      }
    });
  }

  setWebSocketDisconnected(key: string): void {
    this.store.dispatch(
      websocketIsDisconnectedAction({
        id: key
      })
    );
  }

  getSocketIsConnected(key: string): Observable<boolean> {
    return this.store.pipe(select(getWebSocketIdConnected(key)), take(1));
  }

  removeTimeStampCTorFromData<T>(data: {createdAt: any; updatedAt: any}): T {
    return removeTimeStampCTorFromData(data);
  }

  removeTimeStampCTorFromDocumentData<T>(snap: DocumentSnapshot): T {
    return removeTimestampCTorFromDocumentSnapshot(snap);
  }

  signInWithPopup(provider: AuthProvider, resolver?: PopupRedirectResolver): Promise<UserCredential> {
    return signInWithPopup(this.auth, provider, resolver);
  }

  signInWithRedirect(provider: AuthProvider, resolver?: PopupRedirectResolver): Promise<never> {
    return signInWithRedirect(this.auth, provider, resolver);
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  signInWithCustomToken(customToken: string): Promise<UserCredential> {
    return signInWithCustomToken(this.auth, customToken);
  }

  createUserWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  sendPasswordResetEmail(email: string, actionCodeSettings?: ActionCodeSettings): Promise<void> {
    return sendPasswordResetEmail(this.auth, email, actionCodeSettings);
  }

  setPersistence(persistence: Persistence): Promise<void> {
    return setPersistence(this.auth, persistence);
  }

  clearIndexedDbPersistence() {
    return clearIndexedDbPersistence(this.db);
  }

  initialize(): void {
    /* noop */
  }
}
