import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthLogginInComponent} from './auth-loggin-in.component';
import {MatCardModule} from '@angular/material/card';

@NgModule({
  declarations: [AuthLogginInComponent],
  imports: [CommonModule, MatCardModule],
  exports: [AuthLogginInComponent]
})
export class AuthLogginInModule {}
