import {Action, createReducer, on} from '@ngrx/store';
import {
  addInstrument,
  addInstruments,
  authLoggedOut,
  clearInstruments,
  deleteInstrument,
  deleteInstruments,
  instrumentAggregate,
  instrumentLoaded,
  loadInstruments,
  updateInstrument,
  updateInstruments,
  upsertInstrument,
  upsertInstruments
} from '@spout/web-global/actions';
import {instrumentAdapter, instrumentInitialState, InstrumentState} from '@spout/web-global/models';

const reducer = createReducer(
  instrumentInitialState,
  on(addInstrument, (state, action) => instrumentAdapter.addOne(action.instrument, state)),
  on(upsertInstrument, (state, action) => instrumentAdapter.upsertOne(action.instrument, state)),
  on(addInstruments, (state, action) => instrumentAdapter.addMany(action.instruments, state)),
  on(upsertInstruments, (state, action) => instrumentAdapter.upsertMany(action.instruments, state)),
  on(updateInstrument, (state, action) => instrumentAdapter.updateOne(action.instrument, state)),
  on(updateInstruments, (state, action) => instrumentAdapter.updateMany(action.instruments, state)),
  on(deleteInstrument, (state, action) => instrumentAdapter.removeOne(action.id, state)),
  on(deleteInstruments, (state, action) => instrumentAdapter.removeMany(action.ids, state)),
  on(loadInstruments, (state, action) => instrumentAdapter.setAll(action.instruments, state)),
  on(clearInstruments, state => instrumentAdapter.removeAll(state)),
  on(instrumentAggregate, (state, action) => {
    let _state = instrumentAdapter.upsertMany(action.aggregate.added, state);
    _state = instrumentAdapter.upsertMany(action.aggregate.modified, _state);
    _state = instrumentAdapter.removeMany(action.aggregate.removed, _state);

    if (action.aggregate.root) {
      _state.rootNode = action.aggregate.root;
    }

    return _state;
  }),
  on(instrumentLoaded, (state, action) => {
    state = {
      ...state,
      ...action.payload,
      loaded: true
    };
    return state;
  }),
  on(authLoggedOut, (state, action) => {
    state = {
      ...instrumentInitialState
    };
    return state;
  })
);

export function instrumentReducer(state: InstrumentState | undefined, action: Action) {
  return reducer(state, action);
}
