import {NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProjectEntity} from '@spout/any-shared/models';
import {deleteChats, upsertChats} from '@spout/web-global/actions';
import {aggregateDocChanges, firestoreChatsPath} from '@spout/web-global/fns';
import {AggregateFirebaseSnapshotChangesEntities, AuthAccountStates, ChatEntity} from '@spout/web-global/models';
import {DocumentChange, DocumentData, onSnapshot, QuerySnapshot} from 'firebase/firestore';
import {SptFirestoreService} from '../../firestore';
import {QueryModel} from './query.model';

export class QueryChatsService implements QueryModel<ChatEntity> {
  // TRACK
  private myChatsSub: (() => void) | undefined;
  constructor(
    private sptFirebase: SptFirestoreService,
    private store: Store,
    private config: ProjectEntity,
    private zone: NgZone
  ) {}

  onConnect(user: AuthAccountStates) {
    const that = this;

    // BEGIN TRACKS
    if (this.myChatsSub) {
      this.myChatsSub();
    }

    this.myChatsSub = onSnapshot(
      this.sptFirebase.collectionRef(firestoreChatsPath(this.config)),
      (snapshot: QuerySnapshot) => {
        // that.processTracks(snapshot, user.auth.uid);
        that.process.apply(that, [snapshot.docChanges()]);
      },
      () => {
        /* noop */
      },
      () => {
        /* noop */
      }
    );
  }

  onDisconnect(user?: AuthAccountStates) {
    // TRACKS
    if (this.myChatsSub) {
      this.myChatsSub();
    }
  }

  process(snapshot: DocumentChange<DocumentData>[]) {
    const that = this;
    const aggregate: AggregateFirebaseSnapshotChangesEntities<DocumentData> = aggregateDocChanges<DocumentData>(
      snapshot
      // createdByUID
    );

    this.zone.run(() => {
      if (aggregate.added.length) {
        // aggregate.added.forEach((added) => {
        //   console.log('TRACKS ADDED', added.id);
        // });

        that.store.dispatch(
          upsertChats({
            chats: <ChatEntity[]>aggregate.added
          })
        );
      }

      if (aggregate.modified.length) {
        // aggregate.modified.forEach((modified) => {
        //   console.log('TRACKS MODIFIED', modified.id);
        // });

        that.store.dispatch(
          upsertChats({
            chats: <ChatEntity[]>aggregate.modified
          })
        );
      }

      if (aggregate.removed.length) {
        // aggregate.removed.forEach((removed) => {
        //   console.log('TRACKS REMOVED', removed);
        // });

        that.store.dispatch(
          deleteChats({
            ids: aggregate.removed
          })
        );
      }
    });
  }
}
