import {Action, createReducer, on} from '@ngrx/store';
import {
  addChat,
  addChats,
  authLoggedOut,
  clearChats,
  deleteChat,
  deleteChats,
  deleteConversation,
  selectTrack,
  showArchivedChats,
  updateChat,
  updateChats,
  upsertChat,
  upsertChats
} from '@spout/web-global/actions';
import {chatAdapter, ChatEntity, chatInitialState, ChatState} from '@spout/web-global/models';
import {hasValueIn} from '@uiux/fn';

const reducer = createReducer(
  chatInitialState,
  on(addChat, (state, action) => chatAdapter.addOne(action.chat, state)),
  on(upsertChat, (state, action) => chatAdapter.upsertOne(action.chat, state)),
  on(addChats, (state, action) => chatAdapter.addMany(action.chats, state)),
  on(upsertChats, (state, action) => chatAdapter.upsertMany(action.chats, state)),
  on(updateChat, (state, action) => chatAdapter.updateOne(action.chat, state)),
  on(updateChats, (state, action) => chatAdapter.updateMany(action.chats, state)),
  on(deleteChat, (state, action) => chatAdapter.removeOne(action.id, state)),
  on(deleteChats, (state, action) => chatAdapter.removeMany(action.ids, state)),
  on(clearChats, state => chatAdapter.removeAll(state)),

  on(selectTrack, (state, action) => {
    if (hasValueIn(action, 'track-audio.id') && action.track.id !== state.selectedId) {
      return {
        ...state,
        selectedProjectId: action.track.projectId,
        selectedSongId: action.track.songId,
        selectedTrackId: action.track.id
      };
    }
    return state;
  }),
  on(authLoggedOut, (state, action) => {
    return chatAdapter.removeAll(state);
  }),
  on(showArchivedChats, (state, action) => {
    return {
      ...state,
      showArchived: action.showArchived
    };
  }),
  on(deleteConversation, (state, action) => {
    const ids = action.chats.map((chat: ChatEntity) => {
      return chat.id;
    });

    return chatAdapter.removeMany(ids, state);
  })
);
export function chatReducer(state: ChatState | undefined, action: Action) {
  return reducer(state, action);
}
