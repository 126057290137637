import {SptBreakpoints} from './breakpoints.model';

export const BREAKPOINTS: SptBreakpoints = {
  SMALL: '(max-width: 970px)',
  LARGE: '(min-width: 971px)'
};

export interface SptBreakpointResult {
  isSmall: boolean;
  isLarge: boolean;
}
