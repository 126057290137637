<button
  mat-icon-button
  cdkOverlayOrigin
  [matTooltip]="tooltipControl.value"
  matTooltipPosition="above"
  type="button"
  (click)="onRefresh()"
>
  <mat-icon svgIcon="refresh"></mat-icon>
</button>
