import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CurrentSubscription} from '@spout/web-global/models';

@Component({
  selector: 'spt-subscription-end',
  templateUrl: './subscription-end.component.html',
  styleUrls: ['./subscription-end.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionEndComponent {
  @Input() subscription!: CurrentSubscription;
}
