import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {MixEntity, Musician, SongEntity, TimeStamp, TrackEntity} from '@spout/any-shared/models';
import {copyExcludeKeys} from '@uiux/fn';
import {v4 as uuidv4} from 'uuid';
import {CHAT_FEATURE_KEY} from '../studio-app-keys';

export interface ChatState extends EntityState<ChatEntity> {
  selectedId?: string | number; // which Chat record has been selected
  loaded: boolean; // has the Chat list been loaded
  error?: string | null; // last none error (if any)
  showArchived: boolean;
}

export interface ChatPartialState {
  readonly [CHAT_FEATURE_KEY]: ChatState;
}

export function selectChatId(a: ChatEntity): string {
  //In this case this would be optional since primary key is id
  return a.id;
}

export function sortBySeconds(a: ChatEntity, b: ChatEntity): number {
  return a.seconds - b.seconds;
}

export const chatAdapter: EntityAdapter<ChatEntity> = createEntityAdapter<ChatEntity>({
  selectId: selectChatId,
  sortComparer: sortBySeconds
});

export const chatInitialState: ChatState = chatAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  showArchived: false
});

// export interface Chat {
//   id: number; // Primary ID, can be string | number
// }

export type ChatType = 'conversation' | 'message';
export enum ChatTypeConstant {
  CONVERSATION = 'conversation',
  MESSAGE = 'message'
}

export interface ChatEntity {
  conversationId: string;
  archived: boolean;
  createdAt: TimeStamp | null; // firebase.firestore.FieldValue;
  createdBy: Musician | null;
  id: string;
  message: string | null;
  openedAt: number | null;
  seconds: number;

  projectId: string;
  songId: string;
  trackId: string;
  mixId: string;

  type: ChatType;
}

export interface ConversationMessage extends ChatEntity {
  messageId: string;
}

export type ConversationPointAction = 'mouseover' | 'click' | 'add';
export interface ConversationPoint extends ChatEntity {
  // Do not use x and y coordinates, may rescale charts in the future
  x: number;
  y: number;
  yPercent: number;
  action?: ConversationPointAction;
}

export type CreateConversationPoint = (seconds: number, x: number, y: number, yPercent: number) => ConversationPoint;

export type CreateMessage = (message: string) => ConversationMessage;

export interface Conversation {
  conversation: ChatEntity;
  messages: ChatEntity[];
}

export interface Conversations {
  track: TrackEntity;
  conversations: Conversation[];
}

export interface TrackConversation {
  mix: MixEntity;
  trackConversations: TrackConversation[];
}

export interface MixConversations {
  song: SongEntity;
  mixConversations: MixConversations[];
}

export function conversationPointFactory(mix: MixEntity, track: TrackEntity): CreateConversationPoint {
  return function (seconds: number, x: number, y: number, yPercent: number): ConversationPoint {
    const id = uuidv4();

    return {
      id,
      conversationId: id,
      seconds,
      x,
      y,
      yPercent,
      archived: false,
      type: 'conversation',

      projectId: mix.projectId,
      songId: mix.songId,
      trackId: track.id,
      mixId: mix.id,

      createdBy: null,
      message: null,

      createdAt: null,
      openedAt: null
    };
  };
}

export function createMessagePoint(con: ChatEntity, message: string): ConversationMessage {
  const messageId = uuidv4();

  return copyExcludeKeys<ConversationPoint, ConversationMessage>(
    {
      ...con,
      type: 'message',
      id: messageId,
      messageId,
      message
    },
    ['x', 'y', 'amplitude', 'action']
  );
}
