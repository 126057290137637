export interface LoginConfig {
  FACEBOOK: boolean;
  TWITTER: boolean;
  GOOGLE: boolean;
  EMAIL: boolean;
  APPLE: boolean;
}

export const LOGIN_CONFIG: LoginConfig = {
  FACEBOOK: false,
  TWITTER: false,
  GOOGLE: true,
  EMAIL: true,
  APPLE: true
};
