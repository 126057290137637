export const PORT = 7777;
export const SPOUT_CACHE_DIRECTORY_NAME = 'SpoutProjects';

export enum SptElectronChannelInvoke {
  // Devide Cache
  GET_CACHE_DIRECTORY = 'getCacheDirectory',
  CREATE_CACHE_DIRETORY = 'createCacheDirectory',
  USE_DEFAULT_CACHE_DIRECTORY = 'useDefaultCacheDirectory',
  GET_DEVICE_STORAGE = 'getDeviceCache',
  GET_SYSTEM_INFORMATION = 'getSystemInformation',
  GET_ELECTRON_BUILD = 'getElectronBuild',
  SAVE_DEVICE_STORAGE = 'saveDeviceCache',

  /**
   * @deprecated
   */
  RESET_DEVICE_STORAGE = 'resetDeviceCache',

  // ProjectEntity
  SAVE_ProjectEntity = 'saveProjectEntity',
  DELETE_ProjectEntity = 'deleteProjectEntity',

  // SongEntity
  SAVE_SongEntity = 'saveSongEntity',
  DELETE_SongEntity = 'deleteSongEntity',

  // MixEntity
  SAVE_MixEntity = 'saveMixEntity',
  DELETE_MixEntity = 'deleteMixEntity',

  // TrackEntity
  SAVE_TrackEntity = 'saveTrackEntity',
  DELETE_TrackEntity = 'deleteTrackEntity',

  // AudioFileMetaData
  SAVE_AudioFileMetaData = 'saveAudioFileMetaData',
  DELETE_AudioFileMetaData = 'deleteAudioFileMetaData',

  // Audio or Midi Files
  GET_FILE = 'getFile',
  SAVE_FILE = 'saveFile',
  SAVE_EXPORTED_FILE = 'saveExportedFile',
  DELETE_FILES = 'deleteFiles',

  // BackblazeB2
  // UPLOAD_BACKBLAZE_B2 = 'uploadBackblazeB2',
  // DELETE_BACKBLAZE_B2 = 'deleteBackblazeB2',
  // DELETE_BACKBLAZE_B2_ONLY = 'deleteBackblazeB2Only',

  // Workspace
  CLEAN_WORKSPACE = 'cleanWorkspace',
  GET_NEW_WORKSPACE_CACHE_DIRECTORY = 'createWorkspaceCacheDirectory',
  GET_DEFAULT_CACHE_DIRECTORY_PATH = 'getDefaultCacheDirectoryPath',
  CREATE_DIRECTORY_PATH = 'createDirectoryPath',
  MOVE_WORKSPACE = 'moveWorkspace',

  GET_NEW_EXPORT_DIRECTORY = 'getNewExportDirectory',

  RELAUNCH_ELECTRON = 'relaunchElectron'
}

export enum SptElectronStoreKeys {
  DEFAULT_DEVICE_STORAGE_KEY = 'defaultDeviceStorage'
}

export enum SptElectronChannelToRenderer {
  ERROR = 'error',
  UPLOAD_FILE_PROGRESS = 'uploadFileProgress'
}

export enum MainMenuToRenderer {
  // App Name
  LOGOUT = 'logout',
  RESET = 'logout',

  // File
  EXPORT_SONG = 'exportSong',

  // EDIT

  // not used
  EDIT_ACTIVE_TRACK = 'editActiveTrack',

  // Create
  CONSOLE_PAGE = 'consolePage',
  ADD_PROJECT = 'addProject',
  ADD_MIX = 'addMix',
  ADD_SONG = 'addSong',
  ADD_TRACK = 'addTrack'
}
