import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {saveLatency} from '@spout/web-global/actions';
import {firestoreLatencyPath} from '@spout/web-global/fns';
import {DeviceDetectionState, StudioAppState} from '@spout/web-global/models';
import {deviceState} from '@spout/web-global/selectors';
import {from} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {SptFirestoreService} from '../firestore';

@Injectable({providedIn: 'root'})
export class LatencyEffects {
  loadLatencys$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(saveLatency),
        switchMap(action => {
          return this.store.pipe(
            select(deviceState),
            switchMap((device: DeviceDetectionState) => {
              return from(
                this.sptFirestore.addDoc(
                  firestoreLatencyPath(),
                  JSON.parse(
                    JSON.stringify({
                      latency: action.latency,
                      electron: device.electronVersion,
                      useragent: device.useragent,
                      desktopInformation: device.desktopInformation
                    })
                  )
                )
              );
            })
          );
        })
      );
    },
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private store: Store<StudioAppState>,
    private sptFirestore: SptFirestoreService
  ) {}
}
