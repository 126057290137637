import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveComponentModule} from '@ngrx/component';
import {DownloadSpoutComponent} from './download-spout.component';

@NgModule({
  declarations: [DownloadSpoutComponent],
  imports: [CommonModule, ReactiveComponentModule, MatButtonModule, MatIconModule],
  exports: [DownloadSpoutComponent]
})
export class DownloadSpoutModule {}
