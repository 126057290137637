import {AudioFileMetaData, DecodedArrayBuffer} from '@spout/any-shared/models';

export function decodeArrayBuffer(src: ArrayBuffer): DecodedArrayBuffer {
  const float32Arrays: Float32Array[] = [];
  const dv = new DataView(src);
  const config = <AudioFileMetaData>{
    sampleRate: dv.getFloat32(0),
    duration: dv.getFloat32(4),
    length: dv.getUint32(8),
    numberOfChannels: dv.getUint32(12)
  };

  if (config.length) {
    for (let c = 0; c < config.numberOfChannels; c++) {
      const f32: Float32Array = new Float32Array(config.length);
      for (let i = 0; i < f32.length; i++) {
        const j = 16 + c * config.length * 4 + i * 4;
        f32[i] = dv.getFloat32(j);
      }
      float32Arrays[c] = f32;
    }
  }

  return {
    float32Arrays,
    config
  };
}
