/**
 * Copyright 2018 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not
 * use this file except in compliance with the License. You may obtain a copy of
 * the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations under
 * the License.
 */
import {AudioWorkerCommand} from '@spout/any-shared/models';
import {Deferred, DeferredPromise} from '@spout/web-global/fns';
import {SharedBufferWorkerOptions} from '@spout/audio-worklets';
import {take} from 'rxjs/operators';
import {SptTransportService} from './spt-transport.service';

// tslint:disable-next-line:no-empty-interface
export interface SharedBufferWorkletOptions extends AudioWorkletNodeOptions {
  isActiveInPlaylist?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OutputData {}

/**
 * The AudioWorkletNode that has a DedicatedWorker as a backend. The
 * communication between Worker and AWP is done via SharedArrayBuffer,
 * which runs like a big ring buffer between two objects. This class is to
 * demonstrate a design of using Worker, SharedArrayBuffer and the AudioWorklet
 * system in one place.
 *
 * In order to use this class, you need 3 files:
 *  - shared-buffer-worklet-node.js (main scope)
 *  - shared-buffer-worklet-processor.js (via `audioWorklet.addModule()` call)
 *  - shared-buffer-worker.js (via `new Worker()` call)
 *
 * @class SharedBufferWorkletNode
 * @extends AudioWorkletNode
 */
class SharedBufferWorkletNode extends AudioWorkletNode {
  private stopPromise: DeferredPromise<any> | null;

  _workerOptions: SharedBufferWorkerOptions | undefined;
  _worker: Worker | undefined;
  output: OutputData;
  onError: (data: any) => void = (data: any) => {
    /* noop */
  };
  // onInitialized: () => {};

  /**
   * @constructor
   * @param transport
   * @param {BaseAudioContext} context The associated BaseAudioContext.
   * @param { () => Worker } workerFactory
   * @param { OutputData } output
   * @param {AudioWorkletNodeOptions} options User-supplied options for
   * AudioWorkletNode.
   * @param {object} options.worker Options for worker processor.
   * @param {number} options.worker.ringBufferLength Ring buffer length of
   * worker processor.
   * @param {number} options.worker.channelCount Channel count of worker
   * processor.
   */
  constructor(
    private transport: SptTransportService,
    context: BaseAudioContext,
    workerFactory: () => Worker,
    output: OutputData,
    options?: SharedBufferWorkletOptions
  ) {
    super(context, 'shared-buffer-record-worklet-processor', options);

    this.output = output;
    this.stopPromise = null;

    this.transport.transportSharedArrayBuffer$
      .pipe(take(1))
      .subscribe((transportSharedArrayBuffer: SharedArrayBuffer) => {
        this._workerOptions = options
          ? Object.assign(
              {
                ringBufferLength: 4096,
                transportSharedArrayBuffer: transportSharedArrayBuffer
              },
              options
            )
          : {
              ringBufferLength: 4096,
              transportSharedArrayBuffer: transportSharedArrayBuffer
            };

        // Worker backend.
        this._worker = workerFactory();

        // This node is a messaging hub for the Worker and AWP. After the initial
        // setup, the message passing between the worker and the process are rarely
        // necessary because of the SharedArrayBuffer.
        this._worker.onmessage = this._onWorkerInitialized.bind(this);

        // output events handled on parent node
        // this.port.onmessage = this._onProcessorInitialized.bind(this);

        // Initialize the worker.
        this._worker.postMessage({
          message: 'INITIALIZE_WORKER',
          options: this._workerOptions
        });
      });
  }

  /**
   * Handles the initial event from the associated worker.
   *
   */
  _onWorkerInitialized(eventFromWorker: MessageEvent) {
    const that = this;
    const data = eventFromWorker.data;

    if (data.message === 'WORKER_READY') {
      // Send SharedArrayBuffers to the processor.

      this.transport.transportSharedArrayBuffer$
        .pipe(take(1))
        .subscribe((transportSharedArrayBuffer: SharedArrayBuffer) => {
          this.port.postMessage({
            message: 'SHARED_BUFFERS',
            SharedBuffers: data.SharedBuffers,
            transportSharedArrayBuffer: transportSharedArrayBuffer
          });
        });

      return;
    }

    if (data.message === 'WORKER_ERROR') {
      console.log('[SharedBufferWorklet] Worker Error:', data.detail);
      if (typeof this.onError === 'function') {
        this.onError(data);
      }
      return;
    }

    if (data.message === 'EXPORT_KERNEL') {
      console.log(data.kernel);
      // const audioBlob = new Blob([data.dataView], { type: data.type });
      //
      // this.stopPromise.resolve({
      //   type: data.type,
      //   message: data.message,
      //   blob: audioBlob,
      // });

      return;
    }

    if (data.message === AudioWorkerCommand.exportWAV) {
      const audioBlob = new Blob([data.dataView], {type: data.type});

      if (this.stopPromise) {
        this.stopPromise.resolve({
          type: data.type,
          message: data.message,
          blob: audioBlob
        });
      }

      return;
    }

    console.log('[SharedBufferWorklet] Unknown message: ', eventFromWorker);
  }

  stop(): Promise<any> {
    this.stopPromise = Deferred<any>();
    this.port.postMessage({message: 'STOP_RECORDING'});
    return this.stopPromise.promise;
  }

  /**
   * Handles the initial event form the associated processor.
   *
   * @param {Event} eventFromProcessor
   */
  // _onProcessorInitialized(eventFromProcessor) {
  //   const data = eventFromProcessor.data;
  //
  //   console.log(data);
  //
  //
  //   console.log('[SharedBufferWorklet] Unknown message: ', eventFromProcessor);
  // }
} // class SharedBufferWorkletNode

export default SharedBufferWorkletNode;
