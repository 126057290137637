import {DeviceStorageState, ProjectState, SongState, TrackState} from '@spout/any-shared/models';
import {DialogQueueState} from './dialog-queue.model';
import {IEnvironmentState} from './environment/models/environment.models';
import {GenresMusicianState} from './genres-musician.model';
import {InstrumentMusicianState} from './instrument-musician.model';
import {SptPermissionState} from './payment.model';
import {SpoutRouterState} from './routes/router.model';
import {AuthAccountProductStates} from './store-models/account.model';
import {AudioFileMetaDataState} from './store-models/audio-meta-data.model';
import {AuthState} from './store-models/auth.model';
import {ChatState} from './store-models/chat.model';
import {DeviceDetectionState} from './store-models/device.model';
import {GenreState} from './store-models/genres.model';
import {InstrumentState} from './store-models/instruments.model';
import {LatencyState} from './store-models/latency.model';
import {MixState} from './store-models/mix.model';
import {MixerRightDrawerState} from './store-models/mixer-right-drawer.model';
import {NotificationsState} from './store-models/notifications.model';
import {TransportState} from './store-models/tone-transport-control.model';
import {TrackMixState} from './store-models/track-mix.models';
import {ConnectionRegistryState} from './store-models/websocket-registry.models';
import {StudioVersionState} from './studio.model';

export interface StudioAppState {
  account: AuthAccountProductStates;
  audioFileMetaData: AudioFileMetaDataState;
  auth: AuthState;
  chat: ChatState;
  deviceDetection: DeviceDetectionState;
  deviceStorage: DeviceStorageState;
  dialogQueues: DialogQueueState;
  environment: IEnvironmentState;
  genres: GenreState;
  genresMusicians: GenresMusicianState;
  instruments: InstrumentState;
  instrumentMusicians: InstrumentMusicianState;
  latencies: LatencyState;
  mix: MixState;
  notifications: NotificationsState;
  permission: SptPermissionState;
  project: ProjectState;
  mixerRightDrawer: MixerRightDrawerState;
  song: SongState;
  studio: StudioVersionState;
  router: SpoutRouterState;
  track: TrackState;
  trackMixes: TrackMixState;
  transport: TransportState;
  webSocketRegistry: ConnectionRegistryState;
}
