import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {StripePaymentFirestoreService, StripePaymentSelectionService} from '@spout/web-global/data-access';
import {SptFirestoreService} from '@spout/web-global/data-access';
import {AuthAccountStates, ENVIRONMENT, IEnvironmentState, PriceInterval} from '@spout/web-global/models';
import {deviceIsElectron, selectSubscriptionId} from '@spout/web-global/selectors';
import {WINDOW} from '@spout/web-global/utils';
import {BehaviorSubject, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {CancelSubscriptionErrorComponent} from './modals/cancel-subscription-error/cancel-subscription-error.component';
import {CancelSubscriptionSuccessComponent} from './modals/cancel-subscription-success/cancel-subscription-success.component';
import {MySubscriptionService} from './services/my-subscription.service';

@Component({
  selector: 'spt-my-subscription',
  templateUrl: './my-subscription.component.html',
  styleUrls: ['./my-subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
  // providers: [MySubscriptionService]
})
export class MySubscriptionComponent implements OnDestroy, OnInit {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _intervalSub$: Subject<boolean> = new Subject<boolean>();

  selectedPlan: string | undefined;
  selectedInterval: PriceInterval = 'month';

  displayedColumns: string[] = ['productDescription', 'price'];
  showCancelProgress: BehaviorSubject<boolean>;

  constructor(
    private sptFirebase: SptFirestoreService,
    public subs: MySubscriptionService,
    public payment: StripePaymentSelectionService,
    private paymentWizardFirestoreService: StripePaymentFirestoreService,
    private router: Router,
    private store: Store<AuthAccountStates>,
    private dialog: MatDialog,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState,
    @Inject(WINDOW) private window: Window
  ) {
    this.showCancelProgress = new BehaviorSubject<boolean>(false);
  }

  ngOnInit() {
    // this.paymentWizardFirestoreService.init();
    this.getPricesByInterval(this.selectedInterval);
  }

  onUpdateSubscription() {
    this.store.pipe(select(deviceIsElectron), take(1)).subscribe((isElectron: boolean) => {
      if (isElectron) {
        if (this.environment.production) {
          this.window.open('https://spoutstudio.io/subscribe?s=2');
        } else {
          this.window.open('https://dev.spoutstudio.io/subscribe?s=2');
        }
      } else {
        this.router.navigate(['subscribe'], {
          queryParams: {
            s: 2
          }
        });
      }
    });
  }

  onCancelSubscription() {
    this.showCancelProgress.next(true);
    this.store.pipe(select(selectSubscriptionId), take(1)).subscribe((subscriptionId: string | null) => {
      if (subscriptionId) {
        const cancelSubscriptionEndOfPeriod = this.sptFirebase.httpsCallable('cancelSubscriptionEndOfPeriod');

        cancelSubscriptionEndOfPeriod(subscriptionId)
          .then(() => {
            this.dialog.open(CancelSubscriptionSuccessComponent, {
              width: '600px'
            });
            this.showCancelProgress.next(false);
          })
          .catch((error: any) => {
            this.dialog.open(CancelSubscriptionErrorComponent, {
              width: '600px',
              data: error
            });
            this.showCancelProgress.next(false);
          });
      } else {
        this.showCancelProgress.next(false);
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
    this._intervalSub$.next(true);
    this.paymentWizardFirestoreService.onDestroy();
    this.subs.onDestroy();
  }

  private getPricesByInterval(interval: PriceInterval) {
    this._intervalSub$.next(true);
  }
}
