import {getIn} from '@uiux/fn';

export interface SptDictionary<T> {
  [p: string]: T;
}

/**
 *
 * @param collection
 * @param path - path to value in each collection object
 * @param id - id of object value
 * @param entities - entities if one is already created
 */
export function createEntityObjectById<T, R>(
  collection: T[],
  path: string,
  id: string = 'id',
  entities?: SptDictionary<R>
): SptDictionary<R> {
  const _entities = entities ? entities : {};

  return collection.reduce((a: SptDictionary<R>, i: T | R) => {
    const e: any = getIn(i, path, i);

    a[e[id]] = e;

    return a;
  }, _entities);
}
