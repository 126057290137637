import {TrackAudioBufferId, TrackEntityAndAudioFileMetaDataEntity} from '@spout/web-global/models';

export function getPlayerInstanceKey(a: TrackAudioBufferId) {
  if (a.trackId && a.audioFileMetaDataId) {
    return `${a.trackId}-${a.audioFileMetaDataId}`;
  }
  return null;
}

export function getPlayerInstanceKeyByEntities(file: TrackEntityAndAudioFileMetaDataEntity) {
  return getPlayerInstanceKey({
    trackId: file.trackEntity.id,
    audioFileMetaDataId: file.audioFileMetaDataEntity.id
  });
}

export function secondsToMilliseconds(s: number): number {
  return s * 1000;
}

export function millisecondsToSeconds(m: number): number {
  return m * 0.001;
}
