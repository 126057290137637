<ng-container *ngrxLet="hasPromoCode$; let hasPromoCode">
  <div [ngClass]="{column: deviceIsSmallScreen$ | ngrxPush, row: deviceIsWideScreen$ | ngrxPush}">
    <div class="column promo-code-form">
      <mat-form-field
        class="spt-width-100-percent push-bottom-20"
        [floatLabel]="formOptions.FLOAT_LABEL"
        [appearance]="formOptions.APPEARANCE"
      >
        <mat-label>Promo Code</mat-label>

        <input autofocus placeholder="code" matInput [formControl]="promoCodeForm" autocomplete="off" />

        <mat-error
          *ngIf="promoCodeForm.invalid && promoCodeForm.errors !== null && promoCodeForm.errors['invalidCode'] !== null"
          >Invalid code</mat-error
        >
      </mat-form-field>

      <div>
        <button mat-button color="primary" (click)="reset()" [disabled]="promoCodeForm.pristine">Reset</button>

        <button mat-button color="primary" class="push-left-10" (click)="onDelete()" [disabled]="!hasPromoCode">
          Delete
        </button>

        <button
          mat-flat-button
          color="primary"
          class="push-left-10"
          (click)="onSubmit()"
          [disabled]="promoCodeForm.invalid || promoCodeForm.pristine"
        >
          Save
        </button>

        <button
          *ngIf="showSkip && !hasPromoCode"
          mat-flat-button
          color="primary"
          class="push-left-10"
          (click)="onNext()"
        >
          Skip
        </button>
        <button
          *ngIf="showSkip && hasPromoCode"
          mat-flat-button
          color="primary"
          class="push-left-10"
          (click)="onNext()"
        >
          Next
        </button>
      </div>
    </div>
    <div class="column">
      <ng-container *ngIf="hasPromoCode">
        <ng-container *ngrxLet="promoDict$; let promoDict">
          <div class="flex-table promo-code-table" [ngClass]="{'push-top-20': deviceIsSmallScreen$ | ngrxPush}">
            <div class="flex-tr">
              <div class="flex-td title">Promo Plan</div>
              <div class="flex-td value">{{ getRoleName(promoDict?.accountRole) }} Plan</div>
            </div>

            <div class="flex-tr">
              <div class="flex-td title">Description</div>
              <div class="flex-td value">{{ promoDict?.description }}</div>
            </div>

            <div class="flex-tr">
              <div class="flex-td title">Plan Storage</div>
              <div class="flex-td value">{{ promoDict?.baseStorageGB }}GB</div>
            </div>

            <ng-container *ngIf="promoDict && promoDict.addons">
              <ng-container *ngFor="let a of promoDict.addons">
                <div class="flex-tr">
                  <div class="flex-td title">{{ a.type }} addon</div>
                  <div class="flex-td value">{{ a.quantity }}</div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
