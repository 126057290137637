<h1 mat-dialog-title>Subscription Cancellation Error</h1>
<div mat-dialog-content>
  <div>{{ data | json }}</div>
  <div>
    Try again, if the issue persists, contact
    <a href="mailto:inquiries@spoutsoftware.com" target="_blank">inquiries@spoutsoftware.com</a>.
  </div>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" mat-dialog-close>Close</button>
</div>
