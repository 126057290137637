import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpGettingStartedCreatingContentComponent} from './help-getting-started-creating-content/help-getting-started-creating-content.component';
import {HelpGettingStartedCreatingComponent} from './help-getting-started-creating.component';

@NgModule({
  declarations: [HelpGettingStartedCreatingComponent, HelpGettingStartedCreatingContentComponent],
  exports: [HelpGettingStartedCreatingComponent, HelpGettingStartedCreatingContentComponent],
  imports: [CommonModule, HelpContentModule, HelpNavBackModule, MatIconModule, MatTooltipModule, MatButtonModule]
})
export class HelpGettingStartedCreatingModule {}
