import {Component, EventEmitter, ChangeDetectionStrategy, Input, Output} from '@angular/core';
import {LOGIN_CONFIG} from '../login.config';
import {SignInType} from './signin.models';

@Component({
  selector: 'auth-signin-oath',
  templateUrl: './signin-oath.component.html',
  styleUrls: ['./signin-oath.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninOathComponent {
  @Input() signInOrSignUp: SignInType = 'signin';

  @Output() facebook: EventEmitter<null> = new EventEmitter<null>();
  @Output() google: EventEmitter<null> = new EventEmitter<null>();
  @Output() twitter: EventEmitter<null> = new EventEmitter<null>();
  @Output() apple: EventEmitter<null> = new EventEmitter<null>();

  config = LOGIN_CONFIG;
}
