import {Injectable, NgZone} from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {openSnackbarEffect, sendNotificationsEffect, snackBarDismiss} from '@spout/web-global/actions';
import {MusicianNotification, NotificationLevel} from '@spout/web-global/models';
import {map} from 'rxjs/operators';
// import { UnshareProjectSongDialogComponent } from './helpers/unshare-project-song/unshare-project-song-dialog.component';
import {SptNotificationsService} from './notifications.service';

@Injectable()
export class NotificationsEffects {
  sendNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendNotificationsEffect),
        map((action: {payload: MusicianNotification}) => {
          this.notificationsService.sendNotification(action.payload);
        })
      ),
    {dispatch: false}
  );

  snackBarClose$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(snackBarDismiss),
        map(() => {
          this.zone.run(() => {
            this.snackBar.dismiss();
          });
        })
      ),
    {dispatch: false}
  );

  snackbarString$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openSnackbarEffect),
        map(({message, level, duration}) => {
          let panelClass = '';

          if (level === NotificationLevel.Critical) {
            panelClass = 'spt-snackbar-panel-simple-message-high';
          }

          if (level === NotificationLevel.Medium) {
            panelClass = 'spt-snackbar-panel-simple-message-medium';
          }

          if (level === NotificationLevel.Low) {
            panelClass = 'spt-snackbar-panel-simple-message-low';
          }

          const config: MatSnackBarConfig = {
            horizontalPosition: <MatSnackBarHorizontalPosition>'center',
            verticalPosition: <MatSnackBarVerticalPosition>'top',
            panelClass
          };

          if (duration > 0) {
            config.duration = duration;
          }

          this.zone.run(() => {
            this.snackBar.open(message, 'Close', {
              horizontalPosition: <MatSnackBarHorizontalPosition>'center',
              verticalPosition: <MatSnackBarVerticalPosition>'top',
              panelClass
            });
          });
        })
      ),
    {dispatch: false}
  );

  // seconds
  snackBarDuration = 3;

  // @Effect({ dispatch: false })
  // openDialog$ = this.actions$.pipe(
  //   ofType(openDialogEffect),
  //   map(({ view, message, dismissAction }: { view: string; message?: string; dismissAction?: Action }) => {
  //     if (view === NotificationDialogView.UNSHARE_PROJECT_SONG) {
  //       // this.dialog.open(UnshareProjectSongDialogComponent);
  //     }
  //   })
  // );

  constructor(
    private actions$: Actions,
    private notificationsService: SptNotificationsService,
    private snackBar: MatSnackBar,
    private zone: NgZone
  ) {}
}
