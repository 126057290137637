import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveComponentModule} from '@ngrx/component';
import {NoEmailWarningComponent} from './no-email-warning.component';

@NgModule({
  declarations: [NoEmailWarningComponent],
  exports: [NoEmailWarningComponent],
  imports: [CommonModule, ReactiveComponentModule, MatButtonModule, MatIconModule, MatDialogModule]
})
export class NoEmailWarningModule {}
