export function removeProperties<T, K>(src: any, exclude: string[]): K | any {
  if (exclude && exclude.length) {
    const tar: any = {};

    return Object.keys(src).reduce((acc: any, key: string) => {
      if (exclude.indexOf(key) < 0) {
        acc[key] = src[key];
      }

      return acc;
    }, tar);
  } else {
    return src;
  }
}
