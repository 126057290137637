import {Observable, Observer} from 'rxjs';

export function fileToArrayBuffer(file: Blob | File): Observable<ArrayBuffer> {
  return new Observable((observer: Observer<any>) => {
    const reader = new FileReader();

    function readerHandler() {
      observer.next(reader.result);
      reader.removeEventListener('loadend', readerHandler);
    }

    reader.addEventListener('loadend', readerHandler);
    reader.readAsArrayBuffer(file);
  });
}
