import {formatCurrency} from '@angular/common';
import {CurrentSubscription, Price, PriceEntities, Product, SubscriptionItem} from '@spout/web-global/models';

// Replace with your publishable key
// https://dashboard.stripe.com/apikeys
export const STRIPE_PUBLISHABLE_KEY =
  'pk_live_51IYqrXFr7h7OltJthyKcAFsVWavqybQ7iViLqZkSGGHosuTI2BE0EahsC1ujIJow7qQ0pPaRFl36KUCHAqKsnRjN009LzCJ0P8';

export const initialPrice: Price = {
  // local only
  id: '',
  name: '',
  productId: '',
  role: null,
  productHasTrial: false,
  isTrial: false,

  // in database
  trial_period_days: -1,
  interval_count: -1, // 1
  recurring: {
    interval: '', // 'month'
    usage_type: '', // 'licensed'
    trial_period_days: -1,
    interval_count: -1, // 1
    aggregate_usage: -1
  },
  transform_quantity: -1,
  unit_amount: -1, // 300
  currency: '', // 'usd'
  tiers_mode: -1,
  tiers: -1,
  description: -1,
  type: '', // 'recurring'
  interval: 'month', // 'month'
  billing_scheme: '', // 'per_unit'
  active: false // true
};

export const initialProduct: Product = {
  id: '',
  active: false,
  description: '',
  images: [],
  name: '',
  role: '-1',
  stripe_metadata_trial: '',
  stripe_metadata_description2: '',
  stripe_metadata_baseStorageGB: null,
  stripe_metadata_addon: null
};

export interface ProductEntities {
  [id: string]: Product;
}

export interface ProductWizardStore {
  stripeId: string | null;
  stripeLink: string | null;

  cancelAtPeriodEnd: boolean;
  currentSubscription: CurrentSubscription | null;
  currentSubscriptionId: string | null;

  productEntities: ProductEntities;
  priceEntities: PriceEntities;

  trialDays: number;

  // currentSubscriptionEntities: CurrentSubscriptionEntities;

  currentSubscriptionPlan: SubscriptionItem | null;
  currentSubscriptionStorage: SubscriptionItem | null;

  selectedProduct: Product | null;
  selectedPlanPrice: Price | null;
  selectedStoragePrice: Price | null;

  earlyAdopterAgreementSigned: boolean;
  additionalStoragePerGB: number;

  totalCost: number;
  isPerMonth: boolean;
}

export interface Checkout {
  stripeId: string | null;
  selectedProduct: Product | null;
  selectedPlanPrice: Price | null;
  selectedStoragePrice: Price | null;
  earlyAdopterAgreementSigned: boolean;
  additionalStoragePerGB: number;
}

export interface UpdateCheckout extends Checkout {
  currentSubscriptionId: string;
  currentSubscriptionPlan: SubscriptionItem | null;
  currentSubscriptionStorage: SubscriptionItem | null;
}

export interface PaymentWizardForm {
  priceId: string;
  additionalStoragePerGB: number;
  earlyAdopterAgreementSigned: boolean;
}

export interface ItemizedCost {
  isTrial: boolean;
  itemName: string;
  itemPrice: number;
  isTotal: boolean;
  isIncludedStorage: boolean;
  baseStorage: string;
}

export interface SubscribePayload {
  dynamic_tax_rates: string[];
  price: string | undefined; // price id
  quantity: number;
  customer?: string;
  trial_end?: number;
}

// functions/src/index.ts:161
export interface APIStripeUpdateSubscrptionItem {
  id: string;
  price: string | undefined;
  quantity?: number;
}

export interface APIStripeSubscriptionPayload {
  subscriptionId: string;
  items: APIStripeUpdateSubscrptionItem[];
}

export function createCurrencyDisplay(p: Price) {
  if (p && p.currency) {
    return formatCurrency(p.unit_amount / 100, 'en-US', '$', p.currency.toUpperCase(), '1.2-2');
  }

  return '';
}
