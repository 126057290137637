import {MediaMatcher} from '@angular/cdk/layout';
import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType, OnInitEffects, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {SptSystemInformation} from '@spout/any-shared/models';
import {detectDevices, deviceLoadDesktopInformation, deviceLoaded} from '@spout/web-global/actions';
import {DeviceDetectionService} from '@spout/web-global/data-access';
import {StudioAppState} from '@spout/web-global/models';
import {WINDOW} from '@spout/web-global/utils';
import {map, switchMap} from 'rxjs/operators';
import {UAParser} from 'ua-parser-js';
import {DeviceStorageService} from '../+device-storage/services/device-storage.service';

@Injectable({providedIn: 'root'})
export class DeviceDetectionEffects implements OnInitEffects {
  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => {
        return this.deviceStorageService.getSystemInformation().pipe(
          map((desktopInformation: SptSystemInformation) => {
            const ua = new UAParser(navigator.userAgent);
            const regex = /Electron\/(\d+\.)?(\d+\.)?(\*|\d+)/gm;
            const electronUserAgent = regex.exec(navigator.userAgent);
            const electronVersion = electronUserAgent && electronUserAgent.length ? electronUserAgent[0] : '';
            return deviceLoadDesktopInformation({
              electron: electronVersion.replace('Electron/', ''),
              useragent: ua.getResult(),
              desktopInformation
            });
          })
        );
      })
    );
  });

  detectDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(detectDevices),
      map((action: Action) => {
        return deviceLoaded({payload: this.deviceDetectionService.getDeviceSpecs()});
      })
    );
  });

  constructor(
    private store: Store<StudioAppState>,
    public deviceStorageService: DeviceStorageService,
    private deviceDetectionService: DeviceDetectionService,
    private actions$: Actions,
    private mediaMatcher: MediaMatcher,
    @Inject(WINDOW) private window: Window
  ) {}

  ngrxOnInitEffects(): Action {
    return detectDevices();
  }
}
