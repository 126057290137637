import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommunityGuidelinesComponent} from './community-guidelines/community-guidelines.component';
import {EulaComponent} from './eula/eula.component';
import {PagePoliciesComponent} from './page-policies.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  {
    path: '',
    component: PagePoliciesComponent,
    children: [
      {
        path: 'eula',
        component: EulaComponent
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'community-guidelines',
        component: CommunityGuidelinesComponent
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagePoliciesRoutingModule {}
