import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  openSnackbarEffect,
  serviceDoConnectAction,
  serviceDoDisconnectAction,
  snackBarDismiss
} from '@spout/web-global/actions';
import {NotificationLevel, StudioAppState} from '@spout/web-global/models';
import {minToMs} from '@uiux/fn';
import {BehaviorSubject, fromEvent, Subject, timer} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {releaseStoreToSelect} from './release-store';

function _window(): any {
  // return the global native browser window object
  return window;
}

enum ACTIVE_STATUS {
  ACTIVE = 'active',
  OFFLINE = 'offline',
  IDLE = 'idle'
}

@Injectable({
  providedIn: 'root'
})
export class PresenceService {
  private _timerInMinutes = 15;
  private _stopTimer: Subject<boolean> = new Subject<boolean>();
  private _windowOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _activeStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(ACTIVE_STATUS.ACTIVE);

  constructor(
    private store: Store<StudioAppState>,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ) {}

  init(): void {
    const that = this;

    this._activeStatus$.pipe(distinctUntilChanged()).subscribe((status: string) => {
      if (status === ACTIVE_STATUS.ACTIVE) {
        // console.log('active');
        this.store.dispatch(snackBarDismiss());
        that.store.dispatch(serviceDoConnectAction());
      }

      if (status === ACTIVE_STATUS.OFFLINE) {
        // console.log('offline');
        that._stopTimer.next(true);
        that.store.dispatch(serviceDoDisconnectAction());
        this.store.dispatch(
          openSnackbarEffect({
            message: 'Your network connection is offline.',
            level: NotificationLevel.Critical,
            duration: 0
          })
        );
      }

      if (status === ACTIVE_STATUS.IDLE) {
        // console.log('idle');
        that._stopTimer.next(true);
        that.store.dispatch(serviceDoDisconnectAction());

        that.store.dispatch(
          openSnackbarEffect({
            message: 'Disconnected from server while you are idle.',
            level: NotificationLevel.Critical,
            duration: 0
          })
        );
      }
    });

    fromEvent(this.document, 'mousemove')
      .pipe(
        debounceTime(30000),
        switchMap(() => {
          return this._windowOnline$.pipe(
            tap((windowOnline: boolean) => {
              if (windowOnline) {
                this._activeStatus$.next(ACTIVE_STATUS.ACTIVE);
              }
            }),
            filter((windowOnline: boolean) => windowOnline),
            switchMap(() => timer(minToMs(this._timerInMinutes)).pipe(takeUntil(this._stopTimer)))
          );
        })
      )
      .subscribe(() => {
        this._activeStatus$.next(ACTIVE_STATUS.IDLE);
      });

    fromEvent(_window(), 'online').subscribe((e: any) => {
      // console.log('online', e);
      this._windowOnline$.next(true);
      this._activeStatus$.next(ACTIVE_STATUS.ACTIVE);
    });

    fromEvent(_window(), 'offline').subscribe((e: any) => {
      // console.log('offline', e);
      this._windowOnline$.next(false);
      this._activeStatus$.next(ACTIVE_STATUS.OFFLINE);
    });

    this.zone.run(() => {
      releaseStoreToSelect();

      this.store.dispatch(snackBarDismiss());
      that.store.dispatch(serviceDoConnectAction());

      // Initialize without mouse event
      this._windowOnline$.next(true);
      this._activeStatus$.next(ACTIVE_STATUS.ACTIVE);

      // document.body.click();
    });
  }

  // TODO
  isProcessing() {
    /* noop */
  }
}
