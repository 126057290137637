import {hasValueIn} from '@uiux/fn';
import {Observable} from 'rxjs';

export const hasEmailFilter =
  () =>
  <T>(source: Observable<T>) =>
    new Observable<T>(observer => {
      return source.subscribe({
        next(x) {
          if (hasValueIn(x, 'account.email')) {
            observer.next(x);
          }
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        }
      });
    });
