import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {MatSliderChange} from '@angular/material/slider';
import {BehaviorSubject, ReplaySubject} from 'rxjs';

@Component({
  selector: 'spt-slider',
  templateUrl: './spt-slider.component.html',
  styleUrls: ['./spt-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'spt-slider',
    '[class.spt-slider-vertical]': 'vertical === true'
  }
})
export class SptSliderComponent {
  showLabel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input()
  set showLabel(v: boolean) {
    this.showLabel$.next(v);
  }

  labelWidth$: ReplaySubject<number> = new ReplaySubject<number>(1);
  @Input()
  set labelWidth(v: number) {
    this.labelWidth$.next(v);
  }

  valueWidth$: ReplaySubject<number> = new ReplaySubject<number>(1);
  @Input()
  set valueWidth(v: number) {
    this.valueWidth$.next(v);
  }

  showUnits$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input()
  set showUnits(v: boolean) {
    this.showUnits$.next(v);
  }

  label$: ReplaySubject<string> = new ReplaySubject<string>(1);
  @Input()
  set label(v: string) {
    this.label$.next(v);
  }

  units$: ReplaySubject<string> = new ReplaySubject<string>(1);
  @Input()
  set units(v: string) {
    this.units$.next(v);
  }

  min$: ReplaySubject<number> = new ReplaySubject<number>(1);
  @Input()
  set min(v: number) {
    this.min$.next(v);
  }

  max$: ReplaySubject<number> = new ReplaySubject<number>(1);
  @Input()
  set max(v: number) {
    this.max$.next(v);
  }

  value$: ReplaySubject<number> = new ReplaySubject<number>(1);
  @Input()
  set value(v: number) {
    this.value$.next(v);
  }

  step$: ReplaySubject<number> = new ReplaySubject<number>(1);
  @Input()
  set step(v: number) {
    this.step$.next(v);
  }

  thumbLabel$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  @Input()
  set thumbLabel(v: boolean) {
    this.thumbLabel$.next(v);
  }

  @Input() vertical = false;

  @Output() change: EventEmitter<MatSliderChange> = new EventEmitter<MatSliderChange>();

  onChange(c: MatSliderChange) {
    this.change.emit(c);
  }
}
