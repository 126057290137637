import {WaveformChannel, WaveformValues} from '@spout/web-global/models';
import {max, min} from 'd3-array';
import {Observable, Observer} from 'rxjs';
import {map} from 'rxjs/operators';
import WaveformData from 'waveform-data';

export function createWaveformDataFromAudioBuffer(audioBuffer: AudioBuffer): Observable<WaveformData> {
  // To create a WaveformData object from audio content in an ArrayBuffer:
  // https://github.com/bbc/waveform-data.js/blob/master/doc/API.md#examples-1
  // https://github.com/bbc/waveform-data.js/blob/master/doc/API.md#waveformdatacreatefromaudiooptions-callback
  const options = {
    audio_buffer: audioBuffer
    // scale: 512
  };

  return new Observable((observer: Observer<any>) => {
    WaveformData.createFromAudio(options, (err, waveformData: WaveformData) => {
      if (err) {
        observer.error(err);
        return;
      }
      observer.next(waveformData);
    });
  });
}

export function getMaxYFromWaveformData(waveformData: WaveformChannel[]): number {
  let maxArray: number[] = [];
  for (let i = 0; i < waveformData.length; i++) {
    maxArray = [...maxArray, ...waveformData[i].max_array];
  }
  return <number>max(maxArray);
}

export function getMinYFromWaveformData(waveformData: WaveformChannel[]): number {
  let minArray: number[] = [];
  for (let i = 0; i < waveformData.length; i++) {
    minArray = [...minArray, ...waveformData[i].min_array];
  }
  return <number>min(minArray);
}

export function getWaveformChannelData(waveformData: WaveformData): WaveformChannel[] {
  const channels: WaveformChannel[] = [];
  for (let i = 0; i < waveformData.channels; i++) {
    channels[i] = <WaveformChannel>{
      max_array: waveformData.channel(i).max_array(),
      min_array: waveformData.channel(i).min_array()
    };
  }

  return channels;
}

export function getWaveformValuesFromToneBuffer(audioBuffer: AudioBuffer): Observable<WaveformValues> {
  return createWaveformDataFromAudioBuffer(audioBuffer).pipe(
    map((w: WaveformData) => {
      const values = <WaveformValues>{
        bits: w.bits,
        channels: w.channels,
        audioDuration: w.duration,
        hasData: w.duration > 0,
        length: w.length,
        pixels_per_second: w.pixels_per_second,
        sample_rate: w.sample_rate,
        scale: w.scale,
        seconds_per_pixel: w.seconds_per_pixel,
        channel: getWaveformChannelData(w)
      };

      values.maxY = getMaxYFromWaveformData(values.channel);
      values.minY = getMinYFromWaveformData(values.channel);

      return values;
    })
  );
}

// export function getDefaultWaveformValues(sample_rate = 48000): WaveformValues {
//   // MINIMAL QUANTITY FOR TRACKS
//   const duration = MIX_METRICS.BASE_TRACK_DURATION; // seconds
//
//   // CONSTANTS
//   const scale = 512;
//   const channels = 2;
//   const bits = 8;
//
//   // CALCULATIONS
//   const pixels_per_second = sample_rate / scale;
//
//   return {
//     bits,
//     channels,
//     audioDuration: duration,
//     hasData: false,
//     length: Math.floor(duration * pixels_per_second),
//     pixels_per_second,
//     sample_rate,
//     scale,
//     seconds_per_pixel: scale / sample_rate,
//     channel: [
//       {
//         min_array: [],
//         max_array: [],
//       },
//       {
//         min_array: [],
//         max_array: [],
//       },
//     ],
//     maxY: 0,
//     minY: 0,
//   };
// }
