import {Component, Input} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {DynamicStoreService} from '@spout/web-global/data-access';
import {DYN_STORE} from '@spout/web-global/models';
import {selectCurrentSongNamePassThrough} from '@spout/web-global/selectors';

@Component({
  selector: 'spt-help-create-track',
  templateUrl: './help-create-track.component.html',
  styleUrls: ['./help-create-track.component.scss']
})
export class HelpCreateTrackComponent {
  @Input() isReference = false;
  @Input() showFileLoad = true;
  @Input() showSelectText = true;

  currentSongName$ = this.store.pipe(select(selectCurrentSongNamePassThrough));

  constructor(private dss: DynamicStoreService, public store: Store) {}

  loadFile() {
    this.dss.dispatch(DYN_STORE.LOAD_FILE_TO_TRACK, true);
  }
}
