import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {StorageMap} from '@ngx-pwa/local-storage';
import {authLogout} from '@spout/web-global/actions';
import {clearIndexedDBCached$} from '@spout/web-global/fns';
import {StudioAppState} from '@spout/web-global/models';
import {getIn} from '@uiux/fn';
import {ReplaySubject} from 'rxjs';
import {User} from 'firebase/auth';

@Component({
  selector: 'spt-no-email-warning',
  templateUrl: './no-email-warning.component.html',
  styleUrls: ['./no-email-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoEmailWarningComponent implements OnInit {
  providerId$: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<NoEmailWarningComponent>,
    private store: Store<StudioAppState>,
    private storage: StorageMap,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {}

  ngOnInit(): void {
    this.providerId$.next(getIn(this.data, 'providerData[0].providerId', 'unknown'));
    this.cd.detectChanges();
  }

  ok() {
    localStorage.clear();
    this.storage.clear();
    this.store.dispatch(authLogout());

    clearIndexedDBCached$().subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
