import {NgZone} from '@angular/core';
import {ActionCreator, Store} from '@ngrx/store';
import {TypedAction} from '@ngrx/store/src/models';
import {AggregateFirebaseSnapshotChangesEntities} from '@spout/web-global/models';
import {aggregateFirestoreSnapshotEntities} from './aggregate-firestore-snapshot-entities';
import {QuerySnapshot} from 'firebase/firestore';

/**
 *
 * @param store - NgRX Store Service
 * @param snapshot - firebase.firestore.QuerySnapshot return from firestore
 * @param aggregateAction - Ngrx Action to Aggregate Entity
 * @param zone NgZone
 * @param id - id key used to map Enitities in reducer / store
 */
export function dispatchNgrxFirestoreAggregate<T>(
  store: Store<T>,
  snapshot: QuerySnapshot,
  aggregateAction: ActionCreator<string, (props: any) => TypedAction<string>>,
  zone: NgZone,
  id: string = 'id'
): AggregateFirebaseSnapshotChangesEntities<T> {
  const aggregate: AggregateFirebaseSnapshotChangesEntities<T> = aggregateFirestoreSnapshotEntities<T>(snapshot, id);

  zone.run(() => {
    store.dispatch(
      aggregateAction({
        aggregate
      })
    );
  });

  return aggregate;
}
