import {MixEntity, TrackMix} from './mix-entity.model';
import {ProjectEntity} from './project-entity.model';
import {SongEntity} from './song-entity.model';
import {TimeStamp} from './time-stamp.model';
import {TrackEntity} from './track-entity.model';
import {UserPermissions} from './user-permissions.model';

export enum EntityType {
  PROJECT = 'project',
  SONG = 'song',
  MIX = 'mix',
  TRACK = 'track',
  AUDIO_FILE_META_DATA = 'audioFileMetaData',
  TRACK_MIX = 'trackMix'
}

export interface TreeConfig {
  createdAt?: TimeStamp; // firebase.firestore.FieldValue;
  description?: string;
  entityType?: EntityType;
  id: string;
  isDefault?: boolean;
  name?: string;
  parentId?: string;
  parentName?: string;
  updatedAt?: TimeStamp; // firebase.firestore.FieldValue;
  updatedAtSeconds?: number;
  children?: TreeConfig[];
}

export type BaseEntity = TreeConfig & UserPermissions;

export interface DefaultEntitiesModel {
  project: Partial<ProjectEntity> | null;
  song: Partial<SongEntity> | null;
  mix: Partial<MixEntity> | null;
  track: Partial<TrackEntity> | null;
}

export interface CreateDefaultEntitiesModel extends DefaultEntitiesModel {
  file: Partial<TreeConfig>;
  trackMix: Partial<TrackMix>;
}
