import {hasValue} from '@uiux/fn';

export function mergeEntities<T>(a: T, b: T): T {
  if (hasValue(a) && hasValue(b)) {
    return {
      ...a,
      ...b
    };
  } else if (hasValue(a) && !hasValue(b)) {
    return a;
  } else if (!hasValue(a) && hasValue(b)) {
    return b;
  } else {
    return <T>{};
  }
}
