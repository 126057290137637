import {AuthAccountProductStates} from '@spout/web-global/models';

/**
 * milliseconds * seconds * minutes * hours
 */
const oneDay = 1000 * 60 * 60 * 24;

const hasPromoCode = (state: AuthAccountProductStates): boolean =>
  state.account.promoCode !== null && state.account.promoCode !== undefined && state.account.promoCode.length > 0;

const hasSubscription = (state: AuthAccountProductStates): boolean =>
  state.account.hasSubscription !== null &&
  state.account.hasSubscription !== undefined &&
  state.account.hasSubscription;

export function isInTrialPeriod(state: AuthAccountProductStates): boolean {
  if (!state.isLoaded) {
    return true;
  } else if (state.trial === null) {
    return true;
  } else if (hasPromoCode(state)) {
    return true;
  } else if (hasSubscription(state)) {
    return true;
  } else if (state.account.createdAt && state.account.createdAt.seconds) {
    const now = Date.now().valueOf();
    const start = new Date(state.account.createdAt.seconds * 1000);
    const diffInDays = (now - start.valueOf()) / oneDay;
    return diffInDays <= state.trial.days;
  }

  return true;
}
