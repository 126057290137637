/**
 * Generated by script tools/bump.version.js
 *
 * See docs/VERSION.md
 *
 * To update version:
 * 1. Make sure repo is clean ( everything committed )
 * 2. Edit the ./spout-version.json file in root
 * 3. Run yarn bump.version
 *
 */
import {BuildVersionElectron, BuildVersionUi} from './spout-version.model';

/**
 * Mac, PC
 */
export const baseElectronVersion: BuildVersionElectron = {
  year: 2022,
  month: '01',
  electronMajorVersion: 0,
  electronVersion: '13.1.7',
  buildNumber: 1
};

/**
 * UI
 */
export const ui: BuildVersionUi = {
  year: 2022,
  month: '01',
  angularVersion: 13,
  buildNumber: 2
};

/**
 * Build Type
 */
export const releaseType = 'alpha';
