import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {
  PrivacyPolicyDialogComponent,
  TermsAndConditionsDialogComponent
} from '@spout/studio-site/feature-page-policies';
import {WINDOW} from '@web-global/utils';
import {LoginService} from './services/login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AUTH_QUERY_PARAMS, LOGIN_SCREEN, OTLID} from './services/login.model';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'auth-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  screen = LOGIN_SCREEN;

  constructor(
    private locationService: Location,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public login: LoginService,
    private _dialog: MatDialog,
    @Inject(WINDOW) private _win: Window
  ) {
    /**
     * Will not work with redirect
     */
    // this._win.addEventListener('beforeunload', function (e) {
    //   localStorage.removeItem(OTLID)
    //   e.preventDefault();
    //   e.returnValue = '';
    // });
  }

  openPrivacyPolicy() {
    this._dialog.open(PrivacyPolicyDialogComponent);
  }

  openTermsOfService() {
    this._dialog.open(TermsAndConditionsDialogComponent);
  }

  ngOnInit(): void {
    const that = this;

    const otlidFromLocalStorage = localStorage.getItem(OTLID);
    const queryStr = this._win.location.search;

    const urlParams = new URLSearchParams(queryStr);
    const otlidFromURL: string | undefined = <string | undefined>urlParams.get(AUTH_QUERY_PARAMS.OTLID);
    const isLoggingIn: boolean | undefined = <boolean | undefined>!!urlParams.get(AUTH_QUERY_PARAMS.IS_LOGGIN_IN);

    if (queryStr) {
      if (otlidFromURL) {
        this.login.checkOtlidInFirestore(otlidFromURL).subscribe(
          () => {
            this._onOtlidQueryParam(isLoggingIn);
          },
          () => {
            this._showIDError();
          }
        );
      } else if (otlidFromLocalStorage) {
        this.login.checkOtlidInFirestore(otlidFromLocalStorage).subscribe(
          () => {
            this._onOtlidQueryParam(isLoggingIn);
          },
          () => {
            this._showIDError();
          }
        );
      } else {
        this._showIDError();
      }
    } else if (otlidFromLocalStorage) {
      this.login.checkOtlidInFirestore(otlidFromLocalStorage).subscribe(
        () => {
          this._onOtlidQueryParam(isLoggingIn);
        },
        () => {
          this._showIDError();
        }
      );
    } else {
      this._showIDError();
    }
  }

  private _onOtlidQueryParam(isLoggingIn: boolean | undefined) {
    if (!isLoggingIn) {
      this.login
        .signout()
        .then(() => {
          this.router.navigate(['/'], {
            queryParams: {
              [AUTH_QUERY_PARAMS.IS_LOGGIN_IN]: true
            }
          });
        })
        .catch(() => {
          this.login.loginScreen$.next(LOGIN_SCREEN.SHOW_ERROR);
          this.login.errorMessage$.next({
            title: 'Auth Error',
            message: 'Please close browser window and try again.'
          });
        });
    }

    this.login.addIsLogginIn(true);
  }

  private _showIDError() {
    this.login.loginScreen$.next(LOGIN_SCREEN.SHOW_ERROR);
    this.login.errorMessage$.next({
      title: 'No Auth ID',
      message: 'Please close browser window and try again.'
    });
  }

  ngOnDestroy() {
    this.login.destroy();
  }
}
