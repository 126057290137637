import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';

export interface GlobalTableConfig {
  id: string;
  allExpanded: boolean;
}

export interface GlobalTableState {
  [tableId: string]: GlobalTableConfig;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalTableStateService extends ComponentStore<GlobalTableState> {
  constructor() {
    super({});
  }

  readonly upsertTableId = this.updater((state: GlobalTableState, config: GlobalTableConfig) => {
    const _state = {
      ...state
      // config,
    };

    if (!_state[config.id]) {
      _state[config.id] = {
        id: config.id,
        allExpanded: config.allExpanded
      };
    }

    return _state;
  });

  readonly expandAll = this.updater((state: GlobalTableState, id: string) => {
    const _state = {
      ...state
    };

    _state[id].allExpanded = true;
    return _state;
  });

  readonly collapseAll = this.updater((state: GlobalTableState, id: string) => {
    const _state = {
      ...state
    };

    _state[id].allExpanded = false;
    return _state;
  });
}
