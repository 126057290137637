import {Action, createReducer, on} from '@ngrx/store';
import {
  addAudioFileMetaDataEntities,
  addAudioFileMetaDataEntity,
  authLoggedOut,
  clearAudioFileMetaDataEntities,
  createProjectAction,
  createSongAction,
  createTrackAction,
  deleteAudioFileMetaDataEntities,
  deleteAudioFileMetaDataEntity,
  updateAudioFileMetaDataEntities,
  updateAudioFileMetaDataEntity,
  upsertAudioFileMetaDataEntities,
  upsertAudioFileMetaDataEntity
} from '@spout/web-global/actions';
import {audioFileMetaDataAdapter, AudioFileMetaDataState, initialMetaDataState} from '@spout/web-global/models';

const reducer = createReducer(
  initialMetaDataState,
  on(addAudioFileMetaDataEntity, (state, action) => audioFileMetaDataAdapter.addOne(action.file, state)),
  on(createProjectAction, (state, action) => audioFileMetaDataAdapter.addOne(action.file, state)),
  on(createSongAction, (state, action) => audioFileMetaDataAdapter.addOne(action.file, state)),
  on(createTrackAction, (state, action) => audioFileMetaDataAdapter.addOne(action.file, state)),
  on(upsertAudioFileMetaDataEntity, (state, action) => audioFileMetaDataAdapter.upsertOne(action.file, state)),
  on(addAudioFileMetaDataEntities, (state, action) => audioFileMetaDataAdapter.addMany(action.files, state)),
  on(upsertAudioFileMetaDataEntities, (state, action) => audioFileMetaDataAdapter.upsertMany(action.files, state)),
  on(updateAudioFileMetaDataEntity, (state, action) => audioFileMetaDataAdapter.updateOne(action.file, state)),
  on(updateAudioFileMetaDataEntities, (state, action) => audioFileMetaDataAdapter.updateMany(action.files, state)),
  on(deleteAudioFileMetaDataEntity, (state, action) => audioFileMetaDataAdapter.removeOne(action.id, state)),
  on(deleteAudioFileMetaDataEntities, (state, action) => audioFileMetaDataAdapter.removeMany(action.ids, state)),
  on(clearAudioFileMetaDataEntities, state => audioFileMetaDataAdapter.removeAll(state)),
  on(authLoggedOut, (state, action) => {
    return audioFileMetaDataAdapter.removeAll(state);
  })
);

export function audioFileMetaDataReducer(state: AudioFileMetaDataState | undefined, action: Action) {
  return reducer(state, action);
}
