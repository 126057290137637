export interface DeferredPromise<T> {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
}

export function Deferred<T>(): DeferredPromise<T> {
  let fResolve: (value: T | PromiseLike<T>) => void = () => {
    /* noop */
  };
  let fReject: (reason?: any) => void = () => {
    /* noop */
  };
  const p: Promise<T> = new Promise(function (resolve, reject) {
    fResolve = resolve;
    fReject = reject;
  });
  return {
    promise: p,
    resolve: fResolve,
    reject: fReject
  };
}
