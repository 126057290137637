import {EntityType, SongEntity, TrackEntity, TreeConfig} from '@spout/any-shared/models';
import {AccountState, applyInitialAuthor, createAuthorMusician} from '@spout/web-global/models';
import {getGlobalDefaultPaletteSelector} from '../color';
import {createInitialConfig} from './create-initial-config';

export function createInitialTrackEntity(
  account: AccountState,
  song: SongEntity,
  id?: string | null,
  name?: string
): TrackEntity {
  const initialConfig: Partial<TreeConfig> = createInitialConfig(id, name);

  const config = {
    ...initialConfig,
    entityType: EntityType.TRACK,
    createdBy: null,
    owners: null,
    recordTime: null,
    uploadTime: null,

    projectId: song.projectId,
    songId: song.id,

    shared: false,
    archived: false,

    color: getGlobalDefaultPaletteSelector()
  };

  return applyInitialAuthor<any, TrackEntity>(config, createAuthorMusician(account));
}

export function setTrackEntityProps(trackEntity: TrackEntity, props: Partial<TrackEntity>): TrackEntity {
  return {
    ...trackEntity,
    ...props
  };
}
