import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {MixEntity, ProjectEntity, TrackEntity, TrackMix} from '@spout/any-shared/models';
import {
  addSongFromSidenav,
  addSongToProjectAction,
  clearGenres,
  closeAndNavigate,
  createDefaultConfigsToFirestore,
  createProjectAction,
  createSongAction,
  masterFullRewindEffect,
  selectedProjectEntity,
  selectSongEffect,
  setDeviceStoreCurrentIdsFromTrackAndMixEntity,
  updateSong
} from '@spout/web-global/actions';
import {
  createInitialAudioFileMetaDataEntity,
  createInitialMixEntityWithSong,
  createInitialSongEntity,
  createInitialTrackEntity,
  createInitialTrackMixConfig,
  setSongEntityProps,
  setTrackMixProps
} from '@spout/web-global/fns';
import {AccountState, getAddSongRoute, StudioAppState} from '@spout/web-global/models';
import {
  getProjectEntityById,
  getProjectTrackAndMixBySongId,
  selectAccountState,
  selectCurrentProjectId
} from '@spout/web-global/selectors';
import {assignMethod, objectMethodAssign} from '@uiux/fn';
import {concat, EMPTY, from, Observable, of} from 'rxjs';
import {concatMap, map, mergeMap, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {SptPlayerCacheService} from '../audio/services/spt-player-cache.service';
import {releaseStoreToSelect} from '../services/release-store';
import {SptFirestoreService} from '../firestore';
import {SongService} from './song.service';

@Injectable({
  providedIn: 'root'
})
export class SongsEffects {
  addSongToProjectAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSongToProjectAction),
      mergeMap(action => {
        return this.store.pipe(
          select(selectAccountState),
          take(1),
          map((account: AccountState) => {
            releaseStoreToSelect();

            let songEntity = createInitialSongEntity(account, action.project);

            songEntity = setSongEntityProps(songEntity, {
              name: action.songName,
              description: action.songDescription
            });

            const trackEntity: TrackEntity = createInitialTrackEntity(account, songEntity);
            trackEntity.name = `Audio Master`;
            trackEntity.description = `for ${action.songName}`;
            trackEntity.isDefault = true;

            const audioFileEntity = createInitialAudioFileMetaDataEntity(account, trackEntity);
            audioFileEntity.name = 'Audio Master';

            const mix = createInitialMixEntityWithSong(account, songEntity);
            mix.name = `Mix`;
            mix.description = `for ${action.songName}`;
            mix.isDefault = true;

            const trackMix = objectMethodAssign<TrackMix>(
              createInitialTrackMixConfig(account, trackEntity, mix.id, audioFileEntity)
            ).pipe(assignMethod<TrackMix>(setTrackMixProps, {isReference: true}));

            return {
              songEntity,
              trackEntity,
              audioFileEntity,
              mixEntity: mix,
              trackMix
            };
          }),
          switchMap(d => {
            return this.playerCache.muteAllPipe$().pipe(map(() => d));
          }),

          switchMap(d => {
            return this.playerCache.disconnectAll$().pipe(map(() => d));
          }),

          take(1),
          switchMap(({songEntity, trackEntity, audioFileEntity, mixEntity, trackMix}) => {
            return concat([
              masterFullRewindEffect(),
              createSongAction({
                song: songEntity,
                track: trackEntity,
                mix: mixEntity,
                file: audioFileEntity,
                trackMix
              }),
              setDeviceStoreCurrentIdsFromTrackAndMixEntity({
                track: trackEntity,
                mix: mixEntity
              })
            ]);
          })
        );
      })
    )
  );

  createDefaultSongEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createDefaultConfigsToFirestore, createSongAction, createProjectAction),
        switchMap(action => {
          if (action.song) {
            return this.songService.createSong(action.song);
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  updateSongEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateSong),
        mergeMap(action => {
          return this.songService.updateSong(action.song.changes);
        })
      ),
    {dispatch: false}
  );

  selectSongEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectSongEffect),
      switchMap(action => {
        return this.playerCache.disconnectAll$().pipe(map(() => action));
      }),
      switchMap(action => {
        return this.store.pipe(
          select(getProjectEntityById(action.song.projectId)),
          take(1),
          switchMap((project: ProjectEntity | undefined) => {
            return this.store.pipe(
              select(getProjectTrackAndMixBySongId({song: action.song})),
              take(1),

              tap(
                (
                  r: {
                    project: ProjectEntity | undefined;
                    track: TrackEntity | undefined;
                    mix: MixEntity | undefined;
                  } | null
                ) => {
                  releaseStoreToSelect();
                }
              ),
              concatMap(
                (
                  r: {
                    project: ProjectEntity | undefined;
                    track: TrackEntity | undefined;
                    mix: MixEntity | undefined;
                  } | null
                ) => {
                  if (r && r.track && r.track.id && r.mix && project) {
                    return from([
                      masterFullRewindEffect(),
                      setDeviceStoreCurrentIdsFromTrackAndMixEntity(r as {track: TrackEntity; mix: MixEntity}),
                      selectedProjectEntity({projectEntity: project})
                    ]);
                  }
                  return EMPTY;
                }
              )
            );
          })
        );

        // end
      })
    )
  );

  addSongFromSidenav$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSongFromSidenav),
      withLatestFrom(this.store.pipe(select(selectCurrentProjectId))),
      switchMap(([_action, projectId]): Observable<Action> => {
        if (projectId) {
          return of(closeAndNavigate(getAddSongRoute(projectId)));
        }
        return EMPTY;
      })
    )
  );

  constructor(
    private actions$: Actions,
    private sptFirestore: SptFirestoreService,
    private songService: SongService,
    private store: Store<StudioAppState>,
    private playerCache: SptPlayerCacheService
  ) {
    // console.log('LOGIN SONG EFFECTS');
  }
}
