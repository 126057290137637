import {SearchResponse} from '@algolia/client-search';
import {Inject, Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {select, Store} from '@ngrx/store';
import {getMusicianPublicPathByUser} from '@spout/web-global/fns';
import {AccountAlgolia, AccountState, ENVIRONMENT, IEnvironmentState, StudioAppState} from '@spout/web-global/models';
import {selectUid} from '@spout/web-global/selectors';
import algoliasearch, {SearchIndex} from 'algoliasearch';
import {EMPTY, from, Observable, Subject} from 'rxjs';
import {catchError, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {SptFirestoreService} from '../firestore';

export interface SearchMusicians {
  musicians: AccountAlgolia[];
}

@Injectable()
export class SptSearchUsersService extends ComponentStore<SearchMusicians> {
  private searchIndex: SearchIndex;
  private _queryConfig: {[key: string]: number} = {
    hitsPerPage: 10
  };
  private _cancelCurrentQuery$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private sptFirestore: SptFirestoreService,
    private store: Store<StudioAppState>,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState
  ) {
    super({
      musicians: []
    });

    const _client = algoliasearch(this.environment.algolia.appId, this.environment.algolia.apiKey);

    this.searchIndex = _client.initIndex('users');
  }

  readonly musicians$: Observable<AccountAlgolia[]> = this.select((state: SearchMusicians) => state.musicians, {
    debounce: true
  });

  readonly musiciansNotLoggedInMusician$: Observable<AccountAlgolia[]> = this.select(
    this.store.pipe(select(selectUid)),
    this.musicians$,
    (uid: string, musicians: AccountAlgolia[]) => {
      return musicians.filter((m: AccountAlgolia) => m.uid !== uid);
    },
    {debounce: true}
  );

  readonly reset = this.updater((state: SearchMusicians) => {
    return {
      ...state,
      musicians: []
    };
  });

  readonly addMusicians = this.updater((state, musicians: AccountAlgolia[]) => {
    return {
      ...state,
      musicians
    };
  });

  readonly searchMusician = this.effect((search$: Observable<string>) => {
    return search$.pipe(
      switchMap((search: string) =>
        this.searchUser(search).pipe(
          tap({
            next: (musicians: AccountAlgolia[]) => this.addMusicians(musicians),
            error: e => {
              console.error(e);
            }
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  private searchUser(search: string): Observable<any> {
    this._cancelCurrentQuery$.next(true);

    return from(this.searchIndex.search(search, this._queryConfig)).pipe(
      takeUntil(this._cancelCurrentQuery$),
      map((results: SearchResponse<unknown>) => {
        return <SearchResponse<AccountAlgolia>>{
          ...results
        };
      }),
      map((results: SearchResponse<AccountAlgolia>) => results.hits)
    );

    // return this.store.pipe(select(selectDoConnect)).pipe(
    //   filter((doConnect: boolean) => doConnect),
    //   take(1),
    //   switchMap((): any => {
    //     return combineLatest([this.searchEmail(search), this.searchStagename(search)]).pipe(
    //       take(1),
    //       map(([emails, usernames]: [AccountState[], AccountState[]]) => {
    //         return [...emails, ...usernames];
    //       })
    //     );
    //   })
    // );
  }

  getMusicianProfile(a: AccountAlgolia): Observable<AccountState> {
    return this.sptFirestore.docData$<AccountState>(getMusicianPublicPathByUser(<string>a.uid));
  }

  // private searchEmail(email: string): Observable<AccountState[]> {
  //   const that = this;
  //   return new Observable((observer: Observer<any>) => {
  //     const emailQuery = that.sptFirestore.collection('users').where('email', '==', email).get();
  //
  //     emailQuery
  //       .then((result: QuerySnapshot) => {
  //         if (result && result.docs && result.docs.length) {
  //           const docs = result.docs.map((doc: QueryDocumentSnapshot) => {
  //             return doc.data();
  //           });
  //
  //           observer.next(docs);
  //         } else {
  //           observer.next([]);
  //         }
  //       })
  //       .catch(error => {
  //         observer.error(error);
  //       });
  //   });
  // }

  // private searchStagename(stageName: string): Observable<AccountState[]> {
  //   const that = this;
  //   return new Observable((observer: Observer<any>) => {
  //     const stageNameQuery = that.sptFirestore.collection('users').where('stageName', '==', stageName).get();
  //
  //     stageNameQuery
  //       .then((result: QuerySnapshot) => {
  //         if (result && result.docs && result.docs.length) {
  //           const docs = result.docs.map((doc: QueryDocumentSnapshot) => {
  //             return doc.data();
  //           });
  //
  //           observer.next(docs);
  //         } else {
  //           observer.next([]);
  //         }
  //       })
  //       .catch(error => {
  //         observer.error(error);
  //       });
  //   });
  // }
}
