import {Inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AudioFileMetaData, DeleteFilesFromSystem} from '@spout/any-shared/models';
import {AuthAccountStates, ENVIRONMENT, IEnvironmentState} from '@spout/web-global/models';
import {selectUidHash$} from '@spout/web-global/selectors';
import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {DeviceStorageService} from './device-storage.service';

@Injectable()
export class AudioFileDeleteService {
  constructor(
    private device: DeviceStorageService,
    private store: Store<AuthAccountStates>,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState
  ) {}

  deleteFilesFromFileSystem(files: AudioFileMetaData[]): Observable<DeleteFilesFromSystem> {
    const that = this;

    return this.selectUidHash$().pipe(
      switchMap((account: string) => {
        return that.device.deleteFilesFromSystem({
          account,
          audioFileMetaData: files
        });
      })
    );
  }

  private selectUidHash$(): Observable<string> {
    return this.store.pipe(selectUidHash$, take(1));
  }
}
