import {algoliaConfig, firebaseProdConfig} from '../firebase/environment.firebas.config';
import {endpointsMain} from './endpoints/endpoints.main';

import {faceBookInit, IEnvironmentState} from './models/environment.models';

/*
K000DWPy6RO5e/R6ZYz9sG3rVh4f8fM
 */

export const prodEnv: IEnvironmentState = {
  production: true,
  mobileTest: false,
  appName: 'studio-app',
  facebookAppId: '2219988261549213',
  firebaseProjectID: 'spout-prod',
  firebase: firebaseProdConfig,
  // firebaseMonitor: firebaseMonitorConfig,
  serviceWorker: true,

  // proxy server
  endpoints: endpointsMain,
  algolia: algoliaConfig,
  emulator: false,
  authSite: 'https://auth.spoutstudio.io'
};

export const prodEnvLocal: IEnvironmentState = {
  ...prodEnv,
  serviceWorker: true
};

faceBookInit(prodEnvLocal.facebookAppId);
