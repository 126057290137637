import {
  AudioFileMetaDataEntity,
  MixEntity,
  SongEntity,
  SptSystemInformation,
  TrackEntity,
  TrackMix
} from '@spout/any-shared/models';
import {createAndAddAudioTrackEntityToSongAndMix} from '@spout/web-global/fns';
import {AccountState} from '@spout/web-global/models';
import {Observable} from 'rxjs';
import {FileWithId, ImportFileMeta} from './import-files.models';

export function createImportFileMeta({
  account,
  song,
  currentSongTrackCount,
  mixes,
  // desktopInformation,
  fileWithId
}: CreateImportFileMetaSource): ImportFileMeta {
  const mergedMixes: MixEntity[] = mixes.map((mix: MixEntity) => {
    return {
      ...mix
    };
  });

  const meta: {
    trackEntity: TrackEntity;
    audioFileMetaDataEntity: AudioFileMetaDataEntity;
    mixes: MixEntity[];
    trackMixes: TrackMix[];
  } = createAndAddAudioTrackEntityToSongAndMix(
    account,
    song,
    mergedMixes, // function relies on mixes for iteration
    currentSongTrackCount,
    fileWithId.id,
    fileWithId.name
  );

  // meta.mixes = meta.mixes.map((metaMix: MixEntity) => {
  //   const mergedMix: MixEntity = mergedMixes.find((_mix: MixEntity) => {
  //     return _mix.id === metaMix.id;
  //   });
  //
  //   return merge(mergedMix, metaMix);
  // });

  return {
    file: fileWithId,
    meta,
    // desktopInformation,
    parsedExport: null
  };
}

export interface CreateImportFileMetaSource {
  account: AccountState;
  song: SongEntity;
  currentSongTrackCount: number;
  mixes: MixEntity[];
  // trackMixes: TrackMix[];
  // desktopInformation: SptSystemInformation;
  fileWithId: FileWithId;
}

export function createImportFileMeta$(source: Observable<CreateImportFileMetaSource>): Observable<ImportFileMeta> {
  return new Observable(subscriber => {
    const subscription = source.subscribe({
      next(data: CreateImportFileMetaSource) {
        subscriber.next(createImportFileMeta(data));
      },
      error(error) {
        subscriber.error(error);
      },
      complete() {
        subscriber.complete();
      }
    });

    return () => subscription.unsubscribe();
  });
}
