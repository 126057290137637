// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  AudioFileMetaDataEntity,
  TrackEntity,
  TrackMix,
  TrackMixAudioSnippet,
  TrackMixAudioSync
} from '@spout/any-shared/models';
import {WaveformSongMetrics} from '../current-mix-metrics.model';

// - AUDIO BUFFER DATA - AUDIO BUFFER DATA - AUDIO BUFFER DATA - AUDIO BUFFER DATA
// - AUDIO BUFFER DATA - AUDIO BUFFER DATA - AUDIO BUFFER DATA - AUDIO BUFFER DATA
// - AUDIO BUFFER DATA - AUDIO BUFFER DATA - AUDIO BUFFER DATA - AUDIO BUFFER DATA
// - AUDIO BUFFER DATA - AUDIO BUFFER DATA - AUDIO BUFFER DATA - AUDIO BUFFER DATA

export interface WaveformChannel {
  max_array: number[];
  min_array: number[];
}

/**
 * See libs/web-shared/models/src/lib/current-mix-metrics.model.ts
 */
export interface WaveformValues {
  /**
   * Returns the length of the waveform data, in pixels
   */
  length: number;

  /**
   * Returns the approximate duration of the audio file, in seconds.
   The duration is approximate because it is calculated based on the waveform length, number of samples per pixel, and audio sample rate
   */
  audioDuration: number;

  /**
   * Returns the number of waveform channels
   */
  channels: number;

  /**
   * Returns a WaveformDataChannel object that provides access to the waveform data for the given channel index
   */
  channel: WaveformChannel[];
  hasData: boolean;

  /**
   * Returns the sample rate of the original audio, in Hz
   */
  sample_rate: number;

  /**
   * Returns the number of audio samples per pixel of the waveform data
   */
  scale: number;

  /**
   * Returns the amount of time (in seconds) represented by a single pixel
   */
  seconds_per_pixel: number;

  /**
   * Returns the number of pixels per second
   */
  pixels_per_second: number;

  /**
   * Returns the number of bits per sample, either 8 or 16
   */
  bits: number;

  // max number in audio buffer
  // Used for d3 y-axis coordinate
  maxY: number;
  minY: number;
}

export const defaultWaveformValues: WaveformValues = {
  length: 0,
  audioDuration: 0,
  channels: 2,
  channel: [
    {max_array: [], min_array: []},
    {max_array: [], min_array: []}
  ],
  hasData: false,
  sample_rate: 44100,
  seconds_per_pixel: 0,
  pixels_per_second: 0,
  scale: 512,
  bits: 0,
  maxY: 0,
  minY: 0
};

export const defaultWaveformSongMetrics: WaveformSongMetrics = {
  length: 0,
  songDuration: 0,
  channels: 2,
  sample_rate: 44100,
  scale: 512,
  seconds_per_pixel: 0,
  pixels_per_second: 0,
  bits: 0
};

export interface AudioBufferDataDEPRECATED {
  // Matches AudioFileMetaD ata.id
  // id: string;
  // hasData: boolean;
  audioBuffer: AudioBuffer;
  // blob?: Blob;
  // waveformValues: WaveformValues;
  // used for tracking updated changes in the store locally.
  // localTimestamp: number;

  // max number in audio buffer
  // Used for d3 y-axis coordinate
  // maxY: number;
  // minY: number;
}

/**
 * TODO Do need
 *  - TrackMix
 */
export interface CompositeAudioBufferDataAndAudioMetaData {
  audioFileMetaData: AudioFileMetaDataEntity;
  snippet: TrackMixAudioSnippet;
}

export interface TrackEntityAndAudioFileMetaDataEntity {
  trackEntity: TrackEntity;
  audioFileMetaDataEntity: AudioFileMetaDataEntity;
}

export interface TrackEntityAndAudioBufferMetaDataEntities {
  trackEntity: TrackEntity;
  audioFileMetaDataEntities: AudioFileMetaDataEntity[];
}

export interface IsCurrentTrackAndTrackEntityAndAudioBufferMetaDataEntities
  extends TrackEntityAndAudioBufferMetaDataEntities {
  isCurrentTrack: boolean;
}

export interface TrackAudioBufferId {
  trackId: string;
  audioFileMetaDataId: string;
}

export interface TrackIdAndAudioBufferIds {
  trackId: string;
  audioFileMetaDataIds: string[];
}

export interface AggregateTrackAudioBufferForTonePlayer {
  trackAudioBufferId: TrackAudioBufferId;
  audioBufferData: AudioFileMetaDataEntity;
}

export interface ApplySyncMix {
  trackEntity: TrackEntity;
  snippetsSync: TrackMixAudioSync[];
}

export interface ApplyVolumeMix {
  trackEntity: TrackEntity;
  masterVolume: number;
  volume: number;
  mute: boolean;
  audioFileMetaDataEntities: AudioFileMetaDataEntity[];
}

export interface TrackComponentModel {
  masterVolume: number;
  allFilesUploaded: boolean;
  isCurrentTrack: boolean;
  trackEntity: TrackEntity;
  trackMix: TrackMix;
  fileData: CompositeAudioBufferDataAndAudioMetaData[];
}

export interface AudioComponentCombined {
  trackId: string;
  trackEntity: TrackEntity;
  trackMix: TrackMix;
  t: TrackEntityAndAudioBufferMetaDataEntities;
}
