import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Musician} from '@spout/any-shared/models';
import {hasEmailFilter} from '@spout/any-shared/utils';
import {
  AccountState,
  createAnonymousMusician,
  createAuthorMusician,
  createCollaboratorMusician,
  createOwnerMusician,
  createViewerMusician,
  StudioAppState
} from '@spout/web-global/models';
import {selectAccountState} from '@spout/web-global/selectors';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MusicianService {
  constructor(private store: Store<StudioAppState>) {}

  createMeAsAuthorMusician(): Observable<Musician> {
    return this.store
      .pipe(select(selectAccountState))
      .pipe(
        // tap((account: AuthAccountStates) => {  console.log(account); }),
        take(1)
      )
      .pipe(map((account: AccountState) => createAuthorMusician(account)));
  }

  createMeAsOwnerMusician(): Observable<Musician> {
    return this.store
      .pipe(select(selectAccountState))
      .pipe(
        // tap((account: AuthAccountStates) => {  console.log(account); }),
        take(1)
      )
      .pipe(map((account: AccountState) => createOwnerMusician(account)));
  }

  createMeAsCollaboratorMusician(): Observable<Musician> {
    return this.store
      .pipe(select(selectAccountState))
      .pipe(
        // tap((account: AuthAccountStates) => {  console.log(account); }),
        take(1)
      )
      .pipe(map((account: AccountState) => createCollaboratorMusician(account)));
  }

  createMeAsViewerMusician(): Observable<Musician> {
    return this.store
      .pipe(select(selectAccountState))
      .pipe(
        // tap((account: AuthAccountStates) => {  console.log(account); }),
        take(1)
      )
      .pipe(map((account: AccountState) => createViewerMusician(account)));
  }

  createMeAsAnonymousMusician(): Observable<Musician> {
    return this.store
      .pipe(select(selectAccountState))
      .pipe(
        // tap((account: AuthAccountStates) => {  console.log(account); }),
        take(1)
      )
      .pipe(map((account: AccountState) => createAnonymousMusician(account)));
  }
}
