import {Inject, Injectable} from '@angular/core';
import {SptFirestoreService} from '@spout/web-global/data-access';
import {uuid} from '@spout/web-global/fns';
import {ENVIRONMENT, IEnvironmentState} from '@spout/web-global/models';
import {WINDOW} from '@web-global/utils';
import {DocumentData, DocumentSnapshot, getDocs, onSnapshot, query, QuerySnapshot, where} from 'firebase/firestore';
import {firestoreOtlidById, firestoreOtlidCollection, OTLIDFirestore} from './auth.models';
import {Observable, Observer} from 'rxjs';
import {browserLocalPersistence} from 'firebase/auth';
import {switchMap, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class OtlidAppService {
  private subscriptionsSub: (() => void) | undefined;

  constructor(
    private _firestore: SptFirestoreService,
    private _store: Store,
    private _winService: Window,
    @Inject(WINDOW) private _win: Window,
    @Inject(ENVIRONMENT) private _env: IEnvironmentState
  ) {}

  launchAuthSite(): Observable<boolean> {
    const that = this;
    const otlid = uuid().replace(/-/gi, '');
    const authUrlWithOtlidQuery = `${this._env.authSite}?otlid=${otlid}`;

    /**
     * Set One Time Login ID in firestore
     */
    return this._firestore
      .set$(firestoreOtlidById(otlid), <OTLIDFirestore>{
        otlid,
        jwtToken: null,
        uid: null
      })
      .pipe(
        tap(_ => {
          this._winService.open(authUrlWithOtlidQuery);
        }),

        /**
         * Listen for new jwtToken to be returned from Firestore
         */
        switchMap(() => this._watchForLogin(otlid))
      );
  }

  private _watchForLogin(otlid: string): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (this.subscriptionsSub) {
        this.subscriptionsSub();
      }

      this.subscriptionsSub = onSnapshot(
        this._firestore.docRef(firestoreOtlidById(otlid)),
        (_doc: DocumentSnapshot<DocumentData>) => {
          if (_doc.exists()) {
            const data: OTLIDFirestore = <OTLIDFirestore>_doc.data();

            if (
              data &&
              data.jwtToken !== undefined &&
              data.jwtToken !== null &&
              data.uid !== undefined &&
              data.uid !== null
            ) {
              /**
               * Delete Auth from database
               */
              this.deleteFirestoreOtlibNodes(data.uid).subscribe(() => {
                if (this.subscriptionsSub) {
                  this.subscriptionsSub();
                }

                this._firestore.setPersistence(browserLocalPersistence).then(() => {
                  this._firestore.signInWithCustomToken(<string>data.jwtToken).then(() => {
                    observer.next(true);
                    observer.complete();
                  });
                });
              });
            }
          }
        }
      );
    });
  }

  deleteFirestoreOtlibNodes(uid: string): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      const _pathRef = this._firestore.collectionRef(firestoreOtlidCollection());

      const q = query(_pathRef, where('uid', '==', uid));

      getDocs(q).then((querySnapshot: QuerySnapshot<unknown>) => {
        const batch = this._firestore.writeBatch();

        for (let q = 0; q < querySnapshot.docs.length; q++) {
          /**
           * QueryDocumentSnapshot id
           */
          const docRef = this._firestore.docRef(firestoreOtlidById(querySnapshot.docs[q].id));
          /**
           * Delete doc
           */
          batch.delete(docRef);
        }

        batch
          .commit()
          .then(() => {
            observer.next();
            observer.complete();
          })
          .catch(() => {
            observer.next();
            observer.complete();
          });
      });
    });
  }
}
