import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HelpCreateSongComponent} from './help-create-song.component';

@NgModule({
  declarations: [HelpCreateSongComponent],
  exports: [HelpCreateSongComponent],
  imports: [CommonModule]
})
export class HelpCreateSongModule {}
