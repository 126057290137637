import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {TrackMix} from '@spout/any-shared/models';
import {
  addTrackMix,
  addTrackMixs,
  clearTrackMixs,
  createMixAction,
  createTrackAction,
  deleteTrackMix,
  deleteTrackMixs,
  loadTrackMixs,
  updateTrackMix,
  updateTrackMixs,
  upsertTrackMix,
  upsertTrackMixs
} from '@spout/web-global/actions';
import {TrackMixState} from '@spout/web-global/models';

export const adapter: EntityAdapter<TrackMix> = createEntityAdapter<TrackMix>();

export const initialTrackMixStateState: TrackMixState = adapter.getInitialState({
  // additional entity state properties
});

const reducer = createReducer(
  initialTrackMixStateState,
  on(addTrackMix, (state, action) => adapter.addOne(action.trackMix, state)),
  on(upsertTrackMix, (state, action) => adapter.upsertOne(action.trackMix, state)),
  on(addTrackMixs, createTrackAction, createMixAction, (state, action) => adapter.addMany(action.trackMixs, state)),
  on(upsertTrackMixs, (state, action) => adapter.upsertMany(action.trackMixs, state)),
  on(updateTrackMix, (state, action) => adapter.updateOne(action.trackMix, state)),
  on(updateTrackMixs, (state, action) => adapter.updateMany(action.trackMixs, state)),
  on(deleteTrackMix, (state, action) => adapter.removeOne(action.id, state)),
  on(deleteTrackMixs, (state, action) => adapter.removeMany(action.ids, state)),
  on(loadTrackMixs, (state, action) => adapter.setAll(action.trackMixs, state)),
  on(clearTrackMixs, state => adapter.removeAll(state))
);

export function trackMixReducer(state: TrackMixState | undefined, action: Action) {
  return reducer(state, action);
}
