<h3>Send reset password email</h3>

<form [formGroup]="forgotPasswordForm">
  <mat-form-field class="full-width" appearance="fill" floatLabel="always">
    <mat-label>Email</mat-label>
    <input type="email" matInput formControlName="email" required placeholder="Ex. pat@example.com" />

    <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <button
    mat-flat-button
    e2e="cta-sign-in"
    color="primary"
    [disabled]="(emailControl.invalid && emailControl.dirty) || emailControl.pristine"
    (click)="sendEmail()"
  >
    SUBMIT EMAIL
  </button>
</form>
