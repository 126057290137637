import {Musician} from '@spout/any-shared/models';

export function sortByStagename(m: Musician[]): Musician[] {
  return m.sort((a: Musician, b: Musician) => {
    if (a.stageName < b.stageName) {
      return -1;
    } else if (a.stageName > b.stageName) {
      return 1;
    } else {
      return 0;
    }
  });
}
