import {Action, createReducer, on} from '@ngrx/store';
import {
  addNotification,
  addNotifications,
  authLoggedOut,
  clearNotifications,
  deleteNotification,
  deleteNotifications,
  loadNotifications,
  updateNotification,
  updateNotifications,
  upsertNotification,
  upsertNotifications
} from '@spout/web-global/actions';
import {notificationsEntityAdapter, notificationsInitialState, NotificationsState} from '@spout/web-global/models';

const reducer = createReducer(
  notificationsInitialState,
  on(addNotification, (state, action) => notificationsEntityAdapter.addOne(action.notification, state)),
  on(upsertNotification, (state, action) => notificationsEntityAdapter.upsertOne(action.notification, state)),
  on(addNotifications, (state, action) => notificationsEntityAdapter.addMany(action.notifications, state)),
  on(upsertNotifications, (state, action) => notificationsEntityAdapter.upsertMany(action.notifications, state)),
  on(updateNotification, (state, action) => notificationsEntityAdapter.updateOne(action.notification, state)),
  on(updateNotifications, (state, action) => notificationsEntityAdapter.updateMany(action.notifications, state)),
  on(deleteNotification, (state, action) => notificationsEntityAdapter.removeOne(action.id, state)),
  on(deleteNotifications, (state, action) => notificationsEntityAdapter.removeMany(action.ids, state)),
  on(loadNotifications, (state, action) => notificationsEntityAdapter.setAll(action.notifications, state)),
  on(clearNotifications, state => notificationsEntityAdapter.removeAll(state)),
  on(authLoggedOut, (state, action) => {
    return notificationsEntityAdapter.removeAll(state);
  })
);

export function notificationsReducer(state: NotificationsState | undefined, action: Action) {
  return reducer(state, action);
}
