import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export enum DIALOG_COMPONENT {
  ONBOARD = 'onboard',
  PRESENCE = 'presence',
  TRIAL_EXPIRED = 'trialExpired',
  ABOUT = 'about'
}

export interface DialogQueue {
  id: DIALOG_COMPONENT;
  isOpen: boolean;
  // The data stream can close the dialog box
  canDestroy: boolean;

  // Unsubscribe from the data stream
  destroy: boolean;
}

export const dialogQueuesFeatureKey = 'dialogQueues';

export interface DialogQueueState extends EntityState<DialogQueue> {
  // additional entities state properties
  isLoaded: boolean;
}

export interface PartialDialogQueueState {
  readonly [dialogQueuesFeatureKey]: DialogQueueState;
}

export const dialogQueueEntityAdapter: EntityAdapter<DialogQueue> = createEntityAdapter<DialogQueue>();

export const initialDialogQueueState: DialogQueueState = dialogQueueEntityAdapter.getInitialState({
  // additional entity state properties
  isLoaded: false
});

export const initialDialogQueue: DialogQueue[] = [
  {
    id: DIALOG_COMPONENT.PRESENCE,
    isOpen: false,
    destroy: false,
    canDestroy: false
  },
  {
    id: DIALOG_COMPONENT.ONBOARD,
    isOpen: false,
    destroy: false,
    canDestroy: true
  },
  {
    id: DIALOG_COMPONENT.TRIAL_EXPIRED,
    isOpen: false,
    destroy: false,
    canDestroy: false
  },
  {
    id: DIALOG_COMPONENT.ABOUT,
    isOpen: false,
    destroy: false,
    canDestroy: false
  }
];
