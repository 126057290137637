import {Injectable, NgZone} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {ProjectEntity} from '@spout/any-shared/models';
import {openHelpViewRightDrawer, selectedProjectEntity} from '@spout/web-global/actions';
import {ACCOUNT_ROLE, getProjectRole, HelpModel} from '@spout/web-global/models';
import {selectCurrentProjectEntity, selectPermissionRole_passThrough, selectUid} from '@spout/web-global/selectors';
import {ReplaySubject} from 'rxjs';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {ProjectEffects} from '../+project/project.effects';

@Injectable({
  providedIn: 'root'
})
export class MixerRightDrawerEffects {
  constructor(private actions$: Actions, private store: Store, private zone: NgZone) {
    const that = this;
    this.store
      .pipe(
        select(selectPermissionRole_passThrough),
        distinctUntilChanged(),
        map((projectRole: ACCOUNT_ROLE) => {
          let helpView: HelpModel;

          if (projectRole === ACCOUNT_ROLE.COLLABORATOR) {
            helpView = HelpModel.GETTING_STARTED_COLLABORATION;
          } else if (projectRole === ACCOUNT_ROLE.PERSONAL) {
            helpView = HelpModel.GETTING_STARTED_CREATING;
          } else {
            helpView = HelpModel.GETTING_STARTED_REVIEWING;
          }

          return openHelpViewRightDrawer({helpView: helpView});
        })
      )
      .subscribe((action: Action) => {
        that.zone.run(() => {
          that.store.dispatch(action);
        });
      });
  }
}
