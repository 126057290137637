import {Directive, ElementRef, EventEmitter, NgModule, NgZone, OnDestroy, Output} from '@angular/core';
import {Subject, Subscription, timer} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {ResizeObserverEntry} from './chart.models';

@Directive({selector: '[uiResizeObserver]'})
export class UiResizeObserverDirective implements OnDestroy {
  @Output() public resize = new EventEmitter<DOMRectReadOnly>();

  // Browser Implementation
  private ro: any;

  // Custom Implementation
  private customSub: Subscription = Subscription.EMPTY;
  private cancel: Subject<boolean> = new Subject<boolean>();

  constructor(private el: ElementRef, private zone: NgZone) {
    // ResizeObserver only supported by Chrome
    // Custom type is not working... so testing
    // if ResizeObserver is on window object
    // TODO Inject Window
    if ((<any>window)['ResizeObserver']) {
      // const ResizeObserver = window['ResizeObserver'];
      const ResizeObserver: any = (<any>window)['ResizeObserver'];

      this.ro = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        this.zone.run(() => {
          this.resize.next(entries[0].contentRect);
        });
      });

      this.ro.observe(this.el.nativeElement);
    } else {
      // Manual ResizeObserver if Native ResizeObserver is not available
      //
      window.addEventListener('resize', this.onCustomResizeListener.bind(this));
      this.onCustomResizeListener.call(this);
    }
  }

  /**
   * For older browsers where window.ResizeObserver does not exist
   */
  private onCustomResizeListener() {
    let previous = 0;
    let count = 0;

    if (!this.customSub.closed) {
      this.customSub.unsubscribe();
    }

    this.customSub = timer(0, 20)
      .pipe(
        map(() => this.el.nativeElement.getBoundingClientRect()),
        takeUntil(this.cancel)
      )
      .subscribe((r: DOMRect) => {
        if (r.width === previous) {
          if (count === 8) {
            this.cancel.next(true);
          }
          count++;
        } else {
          previous = r.width;
          this.zone.run(() => {
            this.resize.next(r);
          });
        }
      });
  }

  ngOnDestroy() {
    if (this.ro) {
      this.ro.unobserve(this.el.nativeElement);
      this.ro.disconnect();
    }
    window.removeEventListener('resize', this.onCustomResizeListener.bind(this));
    this.customSub.unsubscribe();
  }
}
