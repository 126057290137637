import {getFileExtension} from '@uiux/fn';

export interface ElectronDialogFileExtensionFilterItem {
  name: string;
  extensions: string[];
}

export declare type ElectronDialogFileExtensionFilter = ElectronDialogFileExtensionFilterItem[];

export type FileType =
  | 'arraybuffer'
  | 'dat'
  | 'mid'
  | 'audio/mp3'
  | 'audio/mp4'
  | 'audio/mpeg'
  | 'audio/ogg'
  | 'audio/wav'
  | 'audio/webm'
  | 'Float32Array'
  | string;

export enum FILE_TYPE {
  ARRAY_BUFFER = 'arraybuffer',
  DAT = 'dat',
  MIDI = 'mid',
  AUDIO_MP3 = 'audio/mp3',
  AUDIO_MP4 = 'audio/mp4',
  AUDIO_MPEG = 'audio/mpeg',
  AUDIO_OGG = 'audio/ogg',
  AUDIO_WAV = 'audio/wav',
  AUDIO_WEBM = 'audio/webm',
  FLOAT_32_ARRAY = 'Float32Array'
}

export enum AudioWorkerCommand {
  latencyStartRecording = 'latencyStartRecording',
  getRawAudio = 'getFloat32ArrayBufferData',
  exportWAV = 'exportWAV',
  exportDAT = 'exportDAT',
  getHasData = 'getHasData',
  audioLoaded = 'audioLoaded',
  init = 'init',
  record = 'record',
  stopRecording = 'stopRecording',
  saveRecording = 'saveRecording',
  loadFloat32Array = 'loadFloat32Array',
  clear = 'clear',
  startRecording = 'startRecording',
  destroy = 'destroy',
  audioBuffer = 'audioBuffer',
  gain = 'gain',
  isRecording = 'isRecording',
  volumeDisplay = 'VOLUME_DISPLAY'
}

export interface SptWebWorkerMessage {
  message: string;
  type: FileType;
  blob?: any; // Blob
  bufferData?: Float32Array[];
  volume?: number;
}

export interface AudioData {
  length: number;
  numberOfChannels: number;
  sampleRate: number;
  buffer: Float32Array[];
}

export const ExtensionToType: {[key: string]: FILE_TYPE} = {
  dat: FILE_TYPE.DAT,
  mid: FILE_TYPE.MIDI,
  mpeg: FILE_TYPE.AUDIO_MPEG,
  mp3: FILE_TYPE.AUDIO_MP3,
  mp4: FILE_TYPE.AUDIO_MP4,
  ogg: FILE_TYPE.AUDIO_OGG,
  wav: FILE_TYPE.AUDIO_WAV,
  webm: FILE_TYPE.AUDIO_WEBM
};

export function getTypeByExtension(extension: string): FILE_TYPE {
  return ExtensionToType[extension];
}

export const TypeToExtention: {[key: string]: string} = {
  [FILE_TYPE.DAT]: 'dat',
  [FILE_TYPE.MIDI]: 'mid',
  [FILE_TYPE.AUDIO_MP3]: 'mp3',
  [FILE_TYPE.AUDIO_MP4]: 'mp4',
  [FILE_TYPE.AUDIO_MPEG]: 'mpeg',
  [FILE_TYPE.AUDIO_OGG]: 'ogg',
  [FILE_TYPE.AUDIO_WAV]: 'wav',
  [FILE_TYPE.AUDIO_WEBM]: 'webm'
};

export function getExtensionByType(type: string | FileType) {
  return TypeToExtention[type];
}

export function isDatType(type: string): boolean {
  return type === FILE_TYPE.DAT;
}

export function isAudioBuffer(audioBuffer: {sampleRate: number}): boolean {
  return audioBuffer && audioBuffer.sampleRate !== undefined;
}

export function isURLType(response: {url: string}): boolean {
  return response && response.url !== undefined && response.url.length > 0;
}

// Extensions
export const mp3Extension = TypeToExtention[FILE_TYPE.AUDIO_MP3];

export function pathIsMp3(path: string): boolean {
  return getFileExtension(path) === getExtensionByType(FILE_TYPE.AUDIO_MP3);
}

export const oggExtension = TypeToExtention[FILE_TYPE.AUDIO_OGG];

export function pathIsOgg(path: string): boolean {
  return getFileExtension(path) === getExtensionByType(FILE_TYPE.AUDIO_OGG);
}

export const wavExtension = TypeToExtention[FILE_TYPE.AUDIO_WAV];

export function pathIsWav(path: string): boolean {
  return getFileExtension(path) === getExtensionByType(FILE_TYPE.AUDIO_WAV);
}

/**
 * use File type
 * @param fileList
 */
export function filterFileListByApprovedExtension(fileList: any[]): any[] {
  // File
  return fileList.filter((file: any) => {
    return pathIsMp3(file.name) || pathIsOgg(file.name) || pathIsWav(file.name);
  });
}
