import {Component, EventEmitter, Input, Output} from '@angular/core';
import {getTrackColorCSS} from '@spout/web-global/fns';
import {BehaviorSubject} from 'rxjs';
import {WarnDeleteSelectedTrack} from '../track-item-dialog.model';

@Component({
  selector: 'spt-warn-delete-selected',
  templateUrl: './warn-delete-selected.component.html',
  styleUrls: ['./warn-delete-selected.component.scss']
})
export class WarnDeleteSelectedComponent {
  selectedColor$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  @Input()
  set data(data: WarnDeleteSelectedTrack) {
    if (data && data.track !== null) {
      this.selectedColor$.next(getTrackColorCSS(data.track));
    }
  }

  @Output()
  ok: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
}
