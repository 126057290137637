import {Injectable, NgZone} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {authLoggedIn} from '@spout/web-global/actions';
import {AccountState, PartialAuthAccountStates} from '@spout/web-global/models';
import {getIsLoggedIn, selectAccountState} from '@spout/web-global/selectors';
import {Observable, Subject} from 'rxjs';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, take, takeUntil} from 'rxjs/operators';
import {SptFirestoreService} from '../firestore';

import {processRedirectUser} from './helpers/get-user-detials';
import {User} from 'firebase/auth';

/**
 * Authentication is watched depending on context and scenario.
 */
@Injectable()
export class AuthWatchService {
  private _onDestroy$: Subject<boolean> = new Subject();

  onUser$: Subject<AccountState> = new Subject<AccountState>();
  onUserOnce$: Observable<AccountState> = this.onUser$.pipe(take(1));
  onLogout$: Subject<boolean>;

  constructor(
    private sptFirebase: SptFirestoreService,
    private store: Store<PartialAuthAccountStates>,
    private zone: NgZone
  ) {
    this.onLogout$ = new Subject<boolean>();

    this.store
      .pipe(select(getIsLoggedIn), distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.onLogout$.next(true);
        }
      });

    this.store
      .pipe(
        select(selectAccountState),
        filter((account: AccountState) => account && account.uid !== null && account.uid?.length > 0),
        distinctUntilKeyChanged('uid')
      )
      .subscribe((account: AccountState) => {
        this.onUser$.next(account);
      });
  }

  private _unsubscribe: () => void | null | undefined = () => {
    /* noop */
  };

  watchLogin(cb?: (user: User | null) => void) {
    const that = this;

    if (this._unsubscribe) {
      this._unsubscribe();
    }

    this._unsubscribe = this.sptFirebase.auth.onAuthStateChanged(function (user: User | null) {
      if (user) {
        if (cb) {
          cb(user);
        }

        that.zone.run(() => {
          that.store.dispatch(
            authLoggedIn({
              payload: processRedirectUser(user)
            })
          );
        });
      }
    });
  }

  unsubscribe() {
    if (this._unsubscribe) {
      this._unsubscribe();
    }
    this._onDestroy$.next(true);
  }
}
