// https://developer.mozilla.org/en-US/docs/Web/HTML/Supported_media_formats
import {
  ExtensionBlob,
  FileArrayBufferType,
  FileUint8ArrayType,
  getExtensionByType,
  URLType
} from '@spout/any-shared/models';
import {from, Observable, Observer} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Create an url from a Audio Blog
 * @param blob
 */
export function createURLFromBlob(blob: Blob): string {
  return URL.createObjectURL(blob);
}

export function createUrlFromArrayBuffer(response: FileUint8ArrayType): URLType {
  const blob = new Blob([response.uint8Array], {type: response.type});
  return {
    type: response.type,
    url: createURLFromBlob(blob)
  };
}

export function createBlobFromUint8Array(uint8Array: Uint8Array, type: string): Blob {
  return new Blob([uint8Array], {type});
}

export function createUrlFromFile(file: File): string {
  return URL.createObjectURL(file);
}

export function createBlobOctateStream(array: ArrayBuffer): Blob {
  return new Blob([array], {type: 'application/octet-stream'});
}

export function createBlobFromFileUint8ArrayType(uint8ArrayType: FileUint8ArrayType): ExtensionBlob {
  const extension = getExtensionByType(uint8ArrayType.type);

  return {
    extension,
    blob: new Blob([uint8ArrayType.uint8Array], {type: uint8ArrayType.type})
  };
}

export function createArrayBufferExtensionFromFile(file: File): Observable<FileArrayBufferType> {
  return from(file.arrayBuffer()).pipe(
    map((arrayBuffer: ArrayBuffer) => {
      return {
        type: file.type,
        arraybuffer: arrayBuffer
      };
    })
  );
}

export function createArrayBufferExtensionFromBlob(file: Blob): Observable<FileArrayBufferType> {
  return from(file.arrayBuffer()).pipe(
    map((arrayBuffer: ArrayBuffer) => {
      return {
        type: file.type,
        arraybuffer: arrayBuffer
      };
    })
  );
}

export function createUint8ArrayExtensionFromFile$(file: File): Observable<FileUint8ArrayType> {
  return from(file.arrayBuffer()).pipe(
    map((arrayBuffer: ArrayBuffer) => {
      return createUint8ArrayFromArrayBuffer(arrayBuffer, file.type);
    })
  );
}

export function createUint8ArrayExtensionFromBlob$(blob: Blob): Observable<FileUint8ArrayType> {
  return from(blob.arrayBuffer()).pipe(
    map((arrayBuffer: ArrayBuffer) => {
      return createUint8ArrayFromArrayBuffer(arrayBuffer, blob.type);
    })
  );
}

export function createUint8ArrayFromArrayBuffer(arrayBuffer: ArrayBuffer, type: string): FileUint8ArrayType {
  return {
    type,
    uint8Array: new Uint8Array(arrayBuffer)
  };
}

export function createUint8ArrayFromArrayBuffer$(
  arrayBuffer: ArrayBuffer,
  type: string
): Observable<FileUint8ArrayType> {
  return new Observable((observer: Observer<any>) => {
    observer.next(createUint8ArrayFromArrayBuffer(arrayBuffer, type));
  });
}

export function createBlobFromFile(file: File): Observable<ExtensionBlob> {
  const extension = getExtensionByType(file.type);

  return from(file.arrayBuffer()).pipe(
    map((arrayBuffer: ArrayBuffer) => {
      return {
        extension,
        blob: new Blob([arrayBuffer], {type: file.type})
      };
    })
  );
}

/**
 * To convert a url ( created from a File object ) to
 * an AudioBuffer
 * @param url
 * @param tone: any
 */
// export function getAudioBufferFromFileUrl(url: string, tone: any): Observable<AudioBuffer> {
//   return new Observable((observer: Observer<any>) => {
//     const toneBuffer: any = new tone.Buffer(
//       url,
//       () => {
//         observer.next(toneBuffer.get());
//         toneBuffer.dispose();
//         observer.complete();
//       },
//       () => {
//         observer.error('Can not load Audio URL');
//       }
//     );
//   });
// }

// export function convertFileToAudioBuffer(file: File): Observable<AudioBuffer> {
//   return getAudioBufferFromFileUrl(createUrlFromFile(file));
// }
