import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ui-bullet-chart-tooltip, [ui-bullet-chart-tooltip], [uiBulletChartTooltip]',
  templateUrl: './bullet-chart-tooltip.component.html',
  host: {
    class: 't-bullet-chart-tooltip ui-bullet-chart-tooltip'
  }
})
export class BulletChartTooltipComponent {}
