import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'spt-help-nav-header',
  templateUrl: './help-nav-container.component.html',
  styleUrls: ['./help-nav-container.component.scss']
})
export class HelpNavContainerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
