<div [ngSwitch]="isLoggedIn$ | ngrxPush">
  <ng-container *ngSwitchCase="true">
    <div class="logged-in" *ngrxLet="account$; let a">
      <div class="logged-in-as">
        You are currently logged in as
        <span class="p-primary">{{ a.email }}</span> with <span class="p-primary">{{ a.providerId }}</span
        >.
      </div>
      <div>If you DON'T want to use this account, you may log out and log in with a different account.</div>
    </div>
    <div class="logout-cta">
      <button color="primary" mat-flat-button (click)="logout()">LOG OUT</button>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="false">
    <button mat-flat-button color="primary" (click)="onLoginBrowser()">
      {{ loginBtnText }}
    </button>
  </ng-container>
</div>
