import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {TreeConfig} from '@spout/any-shared/models';
import {AggregateFirebaseSnapshotChangesEntities, GenresMusician} from '@spout/web-global/models';

export const loadGenresMusicians = createAction(
  '[GenresMusician/API] Load GenresMusicians',
  props<{genresMusicians: GenresMusician[]}>()
);

export const addGenresMusician = createAction(
  '[GenresMusician/API] Add GenresMusician',
  props<{genresMusician: GenresMusician}>()
);

export const upsertGenresMusician = createAction(
  '[GenresMusician/API] Upsert GenresMusician',
  props<{genresMusician: GenresMusician}>()
);

export const addGenresMusicians = createAction(
  '[GenresMusician/API] Add GenresMusicians',
  props<{genresMusicians: GenresMusician[]}>()
);

export const upsertGenresMusicians = createAction(
  '[GenresMusician/API] Upsert GenresMusicians',
  props<{genresMusicians: GenresMusician[]}>()
);

export const updateGenresMusician = createAction(
  '[GenresMusician/API] Update GenresMusician',
  props<{genresMusician: Update<GenresMusician>}>()
);

export const updateGenresMusicians = createAction(
  '[GenresMusician/API] Update GenresMusicians',
  props<{genresMusicians: Update<GenresMusician>[]}>()
);

export const deleteGenresMusician = createAction('[GenresMusician/API] Delete GenresMusician', props<{id: string}>());

export const deleteGenresMusicians = createAction(
  '[GenresMusician/API] Delete GenresMusicians',
  props<{ids: string[]}>()
);

export const clearGenresMusicians = createAction('[GenresMusician/API] Clear GenresMusicians');

export const genreMusicianAggregateEffect = createAction(
  '[GenresMusician/API] Update Aggregate Effect',
  props<{genres: TreeConfig[]}>()
);

export const genreMusicianAggregate = createAction(
  '[GenresMusician/API] Update Aggregate',
  props<{aggregate: AggregateFirebaseSnapshotChangesEntities<TreeConfig>}>()
);
