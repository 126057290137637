import {Dictionary} from '@ngrx/entity/src/models';
import {hasValue} from '@uiux/fn';

export function getAllEntities<T>(entities: Dictionary<T>): T[] {
  if (hasValue(entities)) {
    return <T[]>Object.values(entities);
  }

  return [];
}
