import {Inject, Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Actions, OnInitEffects} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {delayDebounce} from '@spout/any-shared/utils';
import {closeDialog, loadDialogQueues, openDialog} from '@spout/web-global/actions';
import {accountIsLoadedForOnboarding} from '@spout/web-global/fns';
import {DIALOG_COMPONENT, initialDialogQueue, OnboardingState, PartialDialogQueueState} from '@spout/web-global/models';
import {destroyDialogStream, selectAccountIsExpired, selectOnboardingState} from '@spout/web-global/selectors';
import {WINDOW} from '@spout/web-global/utils';
import {debounceTime, delay, distinctUntilChanged, distinctUntilKeyChanged, filter, takeUntil} from 'rxjs/operators';

@Injectable()
export class DialogQueueEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private store: Store<PartialDialogQueueState>,
    private dialog: MatDialog,
    private zone: NgZone,
    @Inject(WINDOW) private window: Window
  ) {
    const that = this;
    this.store
      .pipe(
        select(selectOnboardingState),
        filter(accountIsLoadedForOnboarding),
        distinctUntilKeyChanged('isOnboarded'),
        takeUntil(
          this.store.pipe(
            select(destroyDialogStream(DIALOG_COMPONENT.ONBOARD)),
            distinctUntilChanged(),
            filter((destroy: boolean) => destroy)
          )
        )
      )
      .subscribe((s: OnboardingState) => {
        that.zone.run(() => {
          that.store.dispatch(openDialog({id: DIALOG_COMPONENT.ONBOARD}));
        });
      });

    this.store
      .pipe(select(selectAccountIsExpired), debounceTime(500), distinctUntilChanged())
      .subscribe((isExpired: boolean) => {
        // console.log('isInTrialPeriod -> ', isExpired);
        if (isExpired) {
          this.store.dispatch(openDialog({id: DIALOG_COMPONENT.TRIAL_EXPIRED}));
        } else {
          this.store.dispatch(closeDialog({id: DIALOG_COMPONENT.TRIAL_EXPIRED}));
        }
      });
  }

  ngrxOnInitEffects(): Action {
    return loadDialogQueues({
      dialogQueues: initialDialogQueue
    });
  }
}
