import {AudioFileMetaDataEntity} from './audio-file-meta-data';
import {MixEntity, TrackMix, TrackMixEntity} from './mix-entity.model';
import {ProjectEntity} from './project-entity.model';
import {SongEntity} from './song-entity.model';
import {TrackEntity} from './track-entity.model';

export interface CurrentEntities {
  project: ProjectEntity | undefined;
  song: SongEntity | undefined;
  mix: MixEntity | undefined;
  track: TrackEntity | undefined;
}

export interface CombinedTrackEntityMix {
  track: TrackEntity;
  mix: MixEntity;
  trackTextColor: string;
}

export interface CombinedSongMixes {
  song: SongEntity;
  tracksByMix: CombinedTrackEntityMix[];
}

export interface CombinedTrackIdTrackMix {
  trackId: string;
  trackMix: TrackMix;
}

export interface CombinedTrackAndTrackMix {
  track: TrackEntity;
  trackMix: TrackMix;
  audioFileMetaDataEntities: AudioFileMetaDataEntity[];
}

export interface BatchCreateNewMix {
  mix: MixEntity;
  trackMixes: TrackMix[];
}

export interface CurrentMixByTrackId {
  track: TrackEntity;
  mix: MixEntity;
}

export interface MixAndTrackMixEntity {
  mix: MixEntity;
  trackMix: TrackMix;
}

export interface CurrentMixSongIdMixes {
  songId: string | null;
  mixes: MixEntity[];
  currentMix: MixEntity | null;
}

export interface CurrentMixSongMixes {
  currentMix: MixEntity | null | undefined;
  songMixes: MixEntity[];
}

export interface SelectProjectMixSong {
  project: Partial<ProjectEntity>;
  song: Partial<SongEntity>;
  mix: Partial<MixEntity>;
}
