import {Inject, Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {SHARED_BUFFER_RECORD_WORKER_FACTORY, StudioAppState} from '@spout/web-global/models';
import {hasValue, ternary} from '@uiux/fn';
import {BehaviorSubject, from, Observable, Observer} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {AudioFileSaveService} from '../+device-storage/services/audio-file-save.service';
import {DeviceStorageService} from '../+device-storage/services/device-storage.service';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {SptCurrentMixMetricsCalculatorService} from './services/spt-current-mix-metrics-calculator.service';
import {SptRecorderController} from './spt-recorder.controller';
import {SptTransportService} from './spt-transport.service';

@Injectable({
  providedIn: 'root'
})
export class SptAudioService {
  devices$: BehaviorSubject<MediaDeviceInfo[]> = new BehaviorSubject<MediaDeviceInfo[]>([]);
  private workerCache = new Map<string, SptRecorderController>();

  private CONFIG = {
    // RECORDER_FACTORY_ID: SCRIPT_PROCESSOR_WORKER_FACTORY,
    RECORDER_FACTORY_ID: SHARED_BUFFER_RECORD_WORKER_FACTORY
  };

  constructor(
    private dss: DynamicStoreService,
    private store: Store<StudioAppState>,
    private zone: NgZone,
    private transport: SptTransportService,
    private deviceStorageService: DeviceStorageService,
    private metricsService: SptCurrentMixMetricsCalculatorService,
    private fileSaveService: AudioFileSaveService,
    @Inject(SHARED_BUFFER_RECORD_WORKER_FACTORY)
    private sharedBufferFactory: () => Worker
  ) {}

  getAudioDeviceList(): Observable<MediaDeviceInfo[]> {
    return from(navigator.mediaDevices.enumerateDevices()).pipe(
      map((devices: MediaDeviceInfo[]) => {
        return devices.filter((d: MediaDeviceInfo) => d.kind === 'audioinput');
      })
    );
  }

  /**
   * This Observable completes
   */
  getDefaultAudioDevice(): Observable<MediaDeviceInfo | null> {
    return this.getAudioDeviceList().pipe(
      filter((devices: MediaDeviceInfo[]) => hasValue(devices)),
      map((devices: MediaDeviceInfo[]) =>
        devices
          .filter((device: MediaDeviceInfo) => device.deviceId === 'default')
          .reduce((acc: MediaDeviceInfo | null, device: MediaDeviceInfo) => {
            return device;
          }, null)
      )
    );
  }

  getAudioDeviceById(deviceId: string = 'default'): Observable<MediaDeviceInfo | null> {
    deviceId = ternary(deviceId, 'default');
    return this.getAudioDeviceList().pipe(
      filter((devices: MediaDeviceInfo[]) => hasValue(devices)),
      map((devices: MediaDeviceInfo[]) =>
        devices
          .filter((device: MediaDeviceInfo) => device.deviceId === deviceId)
          .reduce((acc: MediaDeviceInfo | null, device: MediaDeviceInfo) => {
            return device;
          }, null)
      )
      // tap((device: MediaDeviceInfo) => { console.log('getAudioDeviceById -> device >>>>', device);  })
    );
  }

  refreshAudioDeviceList(): Observable<MediaDeviceInfo[]> {
    return this.getAudioDeviceList().pipe(
      tap((devices: MediaDeviceInfo[]) => {
        this.devices$.next(devices);
      })
    );
  }

  getRecorder() {
    // Just to change in one place
    let recorderWorkerFactory: () => Worker;

    if (this.CONFIG.RECORDER_FACTORY_ID === SHARED_BUFFER_RECORD_WORKER_FACTORY) {
      recorderWorkerFactory = this.sharedBufferFactory;
    }

    return new Observable((observer: Observer<any>) => {
      if (!this.workerCache.has(this.CONFIG.RECORDER_FACTORY_ID)) {
        this.workerCache.set(
          this.CONFIG.RECORDER_FACTORY_ID,
          new SptRecorderController(
            this.CONFIG.RECORDER_FACTORY_ID,
            recorderWorkerFactory,
            this.store,
            this.dss,
            this.transport,
            this.fileSaveService,
            this.deviceStorageService,
            this.zone
          )
        );
      }

      observer.next(this.workerCache.get(this.CONFIG.RECORDER_FACTORY_ID));
    });
  }
}
