import {Location} from '@angular/common';
import {Inject, Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {SptSystemInformation} from '@spout/any-shared/models';
import {
  accountLoaded,
  accountSaveFirebase,
  authError,
  authLoadUser,
  authLoggedIn,
  authLoggedOut,
  authLogout,
  createDefaultConfigsToFirestore,
  loadPermissionsData,
  serviceDoDisconnectAction
} from '@spout/web-global/actions';
import {firestorePermissions, getDesktopSerialNumber, localCacheKey_sha256L40} from '@spout/web-global/fns';
import {
  AccountState,
  AuthAccountProductStates,
  AuthState,
  ENVIRONMENT,
  FeaturePermission,
  FirebaseAnalyticEventParams,
  IEnvironmentState,
  SptEventName,
  StudioAppState
} from '@spout/web-global/models';
import {
  getDesktopInformation,
  selectAllDisconnectedFn,
  selectAuthAccountState,
  selectDoConnect
} from '@spout/web-global/selectors';
import {onSnapshot, query} from 'firebase/firestore';
import {EMPTY, of} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {ConsoleStore} from '../+console/console.store';
import {Exists, removeTimestampCTorFromDocumentSnapshot, SptFirestoreService} from '../firestore';
import {AccountService} from './account.service';
import {AuthLogoutWatchService} from './auth-logout-watch.service';

@Injectable({providedIn: 'root'})
export class AccountEffects {
  saveAccountToFirebase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(accountSaveFirebase),
        map(action => {
          this.accountService.saveToFirebase(action.payload);
        })
      ),
    {dispatch: false}
  );

  createDefaultConfigsToFirestore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createDefaultConfigsToFirestore),
        switchMap(action => {
          if (action.project && action.song && action.mix && action.track) {
            const payload: Partial<AccountState> = {
              defaultProjectId: action.project.id,
              defaultSongId: action.song.id,
              defaultMixId: action.mix.id,
              defaultTrackId: action.track.id
            };

            this.accountService.saveToFirebase(payload);
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  createAccountFromAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authLoadUser),
      switchMap(action => {
        return this.accountService.createAccountIfNotExist(action.payload).pipe(
          tap((response: Exists<AccountState>) => {
            // console.log(response);
            return this.store.pipe(
              select(getDesktopInformation),
              map((desktopInformation: SptSystemInformation) => {
                return {
                  desktopInformation,
                  serial: getDesktopSerialNumber(desktopInformation)
                };
              }),
              filter(({serial}) => serial !== null),
              take(1),
              switchMap(({desktopInformation, serial}: {desktopInformation: SptSystemInformation; serial: string}) => {
                return this.accountService.saveMusicianDeviceSystemInformation(
                  action.payload,
                  desktopInformation,
                  serial
                );
              })
            );
          }),
          map((response: Exists<AccountState>) => {
            // console.log(response);
            return accountLoaded({
              payload: {
                auth: action.payload,
                account: response.data,
                isLoaded: true
              }
            });
          })
        );
      })
    )
  );

  $login = createEffect(() => {
    const that = this;
    return this.actions$.pipe(
      ofType(authLoggedIn),
      switchMap(action => {
        if (action.payload.uid) {
          return localCacheKey_sha256L40(action.payload.uid).pipe(
            map(uidHash => {
              if (!this.environment.production) {
                uidHash = `dev-${uidHash}`;
              }

              return authLoadUser({
                payload: {
                  ...action.payload,
                  uidHash
                }
              });
            })
          );
        }

        return EMPTY;
      })
    );
  });

  // Use Case: Only to indicate when logging out
  $logout = createEffect(() => {
    return this.actions$.pipe(
      ofType(authLogout),

      // Tell all WebSockets to disconnect
      tap(() => {
        this.zone.run(() => {
          this.store.dispatch(serviceDoDisconnectAction());
        });

        this.localStorage.removeItem('redirect').subscribe(() => {
          /* noop */
        });
      }),

      // Listen for when all WebSockets are disconnected
      mergeMap(() =>
        this.store.pipe(
          select(selectAllDisconnectedFn()),
          // tap(allDisconnected => {
          //   console.log(`selectAllDisconnected -> ${allDisconnected} `);
          // }),
          filter((allDisconnected: boolean) => allDisconnected),
          distinctUntilChanged(),
          take(1),
          // Sign out of app
          switchMap(() => {
            return this.authLogoutWatch.logoutFirebase$();
          }),

          tap(() => {
            if ((<any>window).FB && (<any>window).FB.logout) {
              try {
                // (<any>window).FB.logout(function (response: any) {
                // });
              } catch (e) {
                console.error(e);
              }
            }
          }),

          // Update Store when signed out
          map((r: any) => {
            this.consoleStore.authLoggedOut();
            return authLoggedOut();
          }),

          catchError((r: any) => {
            return of(authError({payload: {code: r.code, message: r.message}}));
          })
        )
      )
    );
  });

  private firestorePermissionsSub: (() => void) | undefined;

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private locationService: Location,
    private store: Store<StudioAppState>,
    private localStorage: LocalStorage,
    public consoleStore: ConsoleStore,
    private authLogoutWatch: AuthLogoutWatchService,
    // @Inject(ApiOptionsToken) private options: ApiOptions,
    // libs/mixer-browser-desktop/feature-app/src/lib/api-options.ts
    // @Inject(LOGOUT_REDIRECT) private logoutRedirect: string,
    private sptFirestore: SptFirestoreService,
    private zone: NgZone,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState
  ) {
    const that = this;
    this.store.pipe(select(selectDoConnect), distinctUntilChanged()).subscribe((doConnect: boolean) => {
      if (doConnect) {
        if (!this.firestorePermissionsSub) {
          this.firestorePermissionsSub = onSnapshot(
            query(this.sptFirestore.collectionRef(firestorePermissions())),
            querySnapshot => {
              const featurePermissions: FeaturePermission[] = [];

              querySnapshot.forEach(function (productRef) {
                featurePermissions.push(<FeaturePermission>removeTimestampCTorFromDocumentSnapshot(productRef));
              });

              that.zone.run(() => {
                this.store.dispatch(loadPermissionsData({featurePermissions}));
              });
            }
          );
        }
      } else {
        if (this.firestorePermissionsSub) {
          this.firestorePermissionsSub();
        }
      }
    });

    // analytics
    this.store
      .pipe(
        select(selectAuthAccountState),
        filter((account: AuthAccountProductStates) => account.isLoaded),
        distinctUntilChanged((a: AuthAccountProductStates, b: AuthAccountProductStates) => {
          return (
            a.auth.uid === b.auth.uid && a.auth.email === b.auth.email && a.account.stageName === b.account.stageName
          );
        }),
        mergeMap((account: AuthAccountProductStates) =>
          this.store.pipe(
            select(selectDoConnect),
            distinctUntilChanged(),
            map((doConnect: boolean) => {
              return {
                doConnect,
                account
              };
            })
          )
        )
      )
      .subscribe(({doConnect, account}: {doConnect: boolean; account: AuthAccountProductStates}) => {
        let eventName = '';

        if (this.environment.appName === 'studio-app') {
          if (doConnect) {
            eventName = 'user_studio_app_active';
          } else {
            eventName = 'user_studio_app_inactive';
          }
        } else {
          if (doConnect) {
            eventName = 'user_studio_app_marketing_site_active';
          } else {
            eventName = 'user_studio_app_marketing_site_inactive';
          }
        }

        const params: FirebaseAnalyticEventParams = {
          uid: account.account.uid,
          email: account.account.email,
          stage_name: account.account.stageName
        };

        if (account.promoCode.code) {
          params['promo_code'] = account.promoCode.code;
        }

        this.sptFirestore.logEvent(eventName, params);
      });
  }
}
