import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  AUDIO_WORKLET_MAP,
  DYN_STORE,
  StudioAppState,
  TrackEntityAndAudioFileMetaDataEntity
} from '@spout/web-global/models';
import {isDefinedPipe} from '@uiux/rxjs';
import {from, Observable, of, ReplaySubject} from 'rxjs';
import {concatAll, filter, map, mergeMap, take} from 'rxjs/operators';
import {AudioFileLoadService} from '../../+device-storage/services/audio-file-load.service';
import {AudioFileSaveService} from '../../+device-storage/services/audio-file-save.service';
import {DeviceStorageService} from '../../+device-storage/services/device-storage.service';
import {DynamicStoreService} from '../../services/dynamic-store.service';
import {FirebaseStorageService} from '../../services/firebase-storage.service';
import {getPlayerInstanceKeyByEntities} from '../helpers/audio.helpers';
import {SharedBufferWorkletOptions} from '../shared-buffer-worklet-node';
import {SptTransportService} from '../spt-transport.service';
import {SptWorkletAudioPlayer} from '../spt-worklet-audio-player';
import {SptCurrentMixMetricsCalculatorService} from './spt-current-mix-metrics-calculator.service';
import {SptMapSubject} from './spt-map.subject';
import {SptVolumeTranslateService} from './spt-volume-translate.service';

@Injectable({
  providedIn: 'root'
})
export class SptPlayerCache {
  private workletProcessorLoaded: ReplaySubject<boolean>;
  private cache: SptMapSubject<SptWorkletAudioPlayer>;

  constructor(
    private volumeService: SptVolumeTranslateService,
    private dss: DynamicStoreService,
    private device: DeviceStorageService,
    private store: Store<StudioAppState>,
    private audioFileLoadService: AudioFileLoadService,
    private audioFileSaveService: AudioFileSaveService,
    private metrics: SptCurrentMixMetricsCalculatorService,
    private sptTransportService: SptTransportService, // @Inject(SPT_TRANSPORT_TOKEN) private sptTransportService: SptTransportService
    private _storage: FirebaseStorageService
  ) {
    const that = this;
    this.cache = new SptMapSubject();
    this.workletProcessorLoaded = new ReplaySubject(1);

    this.sptTransportService.audioContext$
      .pipe(isDefinedPipe<AudioContext, AudioContext>())
      .subscribe((audioContext: AudioContext) => {
        audioContext.audioWorklet.addModule(AUDIO_WORKLET_MAP.SHARED_BUFFER_PLAYER_WORKLET_PROCESSOR).then(() => {
          this.workletProcessorLoaded.next(true);
        });
      });
  }

  init() {}

  getSptPlayer(
    a: TrackEntityAndAudioFileMetaDataEntity,
    options?: SharedBufferWorkletOptions
  ): Observable<SptWorkletAudioPlayer | undefined> {
    const that = this;

    return this.workletProcessorLoaded.pipe(
      filter((isLoaded: boolean) => isLoaded),
      take(1),
      mergeMap(() => {
        return this.sptTransportService.audioContext$.pipe(
          map((audioContext: AudioContext) => {
            const playerKey: string | null = getPlayerInstanceKeyByEntities(a);
            const audioContextId: number = (<any>audioContext)['id'];

            // https://developer.mozilla.org/en-US/docs/Web/API/AudioWorkletNodeOptions
            let _options: SharedBufferWorkletOptions = {
              isActiveInPlaylist:
                options && options.isActiveInPlaylist !== undefined ? options.isActiveInPlaylist : true
            };

            if (options && !options.numberOfInputs) {
              _options = Object.assign(_options, <AudioWorkletNodeOptions>{
                numberOfOutputs: 1,
                outputChannelCount: [2]
              });
            } else if (options) {
              _options = Object.assign(_options, options);
            }

            if (playerKey && that.cache.has(playerKey)) {
              const player: SptWorkletAudioPlayer | undefined = that.cache.get(playerKey);

              if (player && !player.audioContextIdMatches(audioContextId)) {
                player.destroy();
                that.cache.delete(playerKey);
              }
            }

            if (playerKey && !that.cache.has(playerKey)) {
              that.addPlayer(
                new SptWorkletAudioPlayer(
                  audioContext,
                  playerKey,
                  a,
                  this.dss,
                  this.store,
                  this.sptTransportService,
                  this.audioFileLoadService,
                  this.audioFileSaveService,
                  this.volumeService,
                  this.metrics,
                  this._storage,
                  true, // isActiveInPlaylist
                  this.device,
                  _options
                )
              );
            }

            return playerKey ? that.cache.get(playerKey) : undefined;
          })
        );
      })
    );
  }

  muteAll() {
    if (this.cache.size) {
      from(Array.from(this.cache.keys()))
        .pipe(
          map((key: string) => {
            const pi = this.cache.get(key);
            if (pi) {
              pi.mute = true;
            }
          })
        )
        .subscribe(() => {
          /* noop */
        });
    }
  }

  muteAll$(): Observable<boolean> {
    return from(<SptWorkletAudioPlayer[]>[...this.cache.values()]).pipe(
      map((p: SptWorkletAudioPlayer) => p.mute$(true)),
      concatAll()
    );
  }

  disconnectAll$(): Observable<boolean> {
    return from(<SptWorkletAudioPlayer[]>[...this.cache.values()]).pipe(
      map((p: SptWorkletAudioPlayer) => p.disconnectOutput$()),
      concatAll()
    );
  }

  unmuteActiveInPlaylist$() {
    if (this.cache.size) {
      return from(<SptWorkletAudioPlayer[]>[...this.cache.values()]).pipe(
        map((pi: SptWorkletAudioPlayer) => {
          if (pi && pi.isActiveInPlaylist) {
            return pi.unmute$();
          }

          return of(true);
        }),
        concatAll()
      );
    }

    return of(true);
  }

  getSptPlayersByTrackId(trackId: string): SptWorkletAudioPlayer[] {
    if (this.cache.size) {
      return Array.from(this.cache.values()).filter((pi: SptWorkletAudioPlayer) => pi.trackId === trackId);
    }

    return [];
  }

  /**
   * Return only first track found
   * @param trackId
   */
  getSptHeadPlayerByTrackId(trackId: string): Observable<SptWorkletAudioPlayer | null> {
    return this.cache.pipe(
      map((cache: Map<string, SptWorkletAudioPlayer>) => {
        return Array.from(cache.values()).reduce((p: SptWorkletAudioPlayer | null, i: SptWorkletAudioPlayer) => {
          if (!p && i.trackId === trackId) {
            return i;
          }
          return p;
        }, null);
      })
    );
  }

  deleteSptPlayerByTrackId(trackId: string): void {
    this.getSptPlayersByTrackId(trackId).forEach((pi: SptWorkletAudioPlayer) => {
      pi.disconnect();
      this.cache.delete(pi.id);
    });
  }

  clearSptPlayersByTrackId(trackId: string): void {
    this.getSptPlayersByTrackId(trackId).forEach((pi: SptWorkletAudioPlayer) => {
      // console.log(pi);
      if (pi) {
        // pi.disconnect();
        pi.clear();
      }
    });

    this.dss.emit(DYN_STORE.TRACK_CLEARED, trackId);
  }

  addPlayer(value: SptWorkletAudioPlayer) {
    this.cache.set(value.id, value);
  }
}
