import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'spt-help-create-project',
  templateUrl: './help-create-project.component.html',
  styleUrls: ['./help-create-project.component.scss']
})
export class HelpCreateProjectComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
