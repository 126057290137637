// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  AudioFileMetaData,
  AudioFileMetaDataEntity,
  EntityType,
  FILE_TYPE,
  FileType,
  TrackEntity
} from '@spout/any-shared/models';
import {AccountState, applyInitialAuthor, createAuthorMusician} from '@spout/web-global/models';
import {uuid} from '../uuid';

export function createInitialAudioFileMetaDataEntity(
  account: AccountState,
  track: Partial<TrackEntity>,
  type: FileType = FILE_TYPE.AUDIO_WAV,
  id?: string,
  name?: string
): AudioFileMetaDataEntity {
  const config = {
    id: id ? id : uuid(),
    entityType: EntityType.AUDIO_FILE_META_DATA,
    type: type,
    name: name ? name : '',

    fileUploaded: false,
    projectId: track.projectId,
    // songIds: [],

    // trackIds: [],
    bufferSize: 4096,
    length: 0,

    sampleRate: 44100,
    duration: null,
    createdBy: null,

    createdByUID: null,
    numberOfChannels: 2,
    maxY: 0,
    minY: 0
  };

  return applyInitialAuthor<AudioFileMetaData, AudioFileMetaDataEntity>(
    <AudioFileMetaData>(<unknown>config),
    createAuthorMusician(account)
  );
}

export function setAudioFileMetaDataEntityProps(
  audioFileMetaDataEntity: AudioFileMetaDataEntity,
  props: Partial<AudioFileMetaDataEntity>
): AudioFileMetaDataEntity {
  return {
    ...audioFileMetaDataEntity,
    ...props
  };
}
