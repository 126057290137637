import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Musician, ProjectEntity, UserPermissions} from '@spout/any-shared/models';
import {getMusicianPublicPathByUser} from '@spout/web-global/fns';
import {PublicProfile} from '@spout/web-global/models';
import {difference, hasValue, uniq} from '@uiux/fn';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {SptFirestoreService} from '../firestore';

function selectedProfileId(a: PublicProfile): string {
  return a && a.uid ? a.uid : '';
}

const profileAdapter: EntityAdapter<PublicProfile> = createEntityAdapter({
  selectId: selectedProfileId
});

type ProfilesState = EntityState<PublicProfile>;

const initialProfileState: ProfilesState = profileAdapter.getInitialState();

@Injectable({
  providedIn: 'root'
})
export class ProfilesService extends ComponentStore<ProfilesState> {
  readonly ids$: Observable<string[] | number[]> = this.select(state => state.ids);
  readonly addProfiles = this.updater((state, profile: PublicProfile) => {
    return profileAdapter.addOne(profile, state);
  });

  constructor(private sptFirestoreService: SptFirestoreService) {
    super(initialProfileState);
  }

  addProfileByProjects(p: ProjectEntity[]) {
    this.ids$.pipe(take(1)).subscribe((storedIds: string[] | number[]) => {
      let newIds: string[] = p.reduce((acc: string[], _p: ProjectEntity) => {
        Object.values(_p.members).forEach((m: Musician) => {
          acc.push(m.uid);
        });

        return acc;
      }, []);

      newIds = difference(uniq(newIds), storedIds);

      if (hasValue(newIds)) {
        this.getProfiles(newIds);
      }
    });
  }

  getProfileById$(p: UserPermissions): Observable<PublicProfile | undefined> {
    return this.select((state: ProfilesState) => state.entities[p.createdBy.uid]);
  }

  private getProfiles(ids: string[]) {
    const that = this;
    ids.forEach((id: string) => {
      this.sptFirestoreService
        .docData<PublicProfile>(getMusicianPublicPathByUser(id))
        .then((profile: PublicProfile) => {
          if (hasValue(profile)) {
            that.addProfiles(<PublicProfile>{
              stageName: profile.stageName,
              displayName: profile.displayName,
              photoURL: profile.photoURL,
              uid: profile.uid
            });
          }
        });
    });
  }
}
