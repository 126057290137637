// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {initialDesktopInformation, SptSystemInformation} from '@spout/any-shared/models';
import * as UAParser from 'ua-parser-js';

export interface DeviceDection {
  available: boolean;
  platform: string;
  version: string;
  uuid: string;
  cordova: string; // version
  model: string;
  manufacturer: string;
  isVirtual: boolean;
  serial: string;
}

// export interface SpoutCordova extends Cordova {
//   version: string;
//   platformVersion: string;
//   platformId: string;
//   callbackId: number;
//   callbacks: {
//     NetworkStatus848829231: any;
//     StatusBar848829232: {
//       fail: null;
//     };
//   };
//   callbackStatus: {
//     NO_RESULT: number;
//     OK: number;
//     CLASS_NOT_FOUND_EXCEPTION: number;
//     ILLEGAL_ACCESS_EXCEPTION: number;
//     INSTANTIATION_EXCEPTION: number;
//     MALFORMED_URL_EXCEPTION: number;
//     IO_EXCEPTION: number;
//     INVALID_ACTION: number;
//     JSON_EXCEPTION: number;
//     ERROR: number;
//   };
//   file: {
//     applicationDirectory: string;
//     applicationStorageDirectory: string;
//     dataDirectory: string;
//     cacheDirectory: string;
//     externalApplicationStorageDirectory: string;
//     externalDataDirectory: string;
//     externalCacheDirectory: string;
//     externalRootDirectory: string;
//     tempDirectory: string;
//     syncedDataDirectory: string;
//     documentsDirectory: string;
//     sharedDirectory: string;
//   };
//   InAppBrowser: any;
// }

export interface CurrentDevice {
  isCordova?: boolean;
  isDesktop?: boolean;
  isBrowser?: boolean;
  cordovaDeviceData: DeviceDection;
  browserData?: any;
  cordovaElectronData?: any;
}

export interface DeviceDetectionState {
  isLoaded: boolean;
  isActive: boolean;
  // isIphoneNotch: boolean;
  // isCordova: boolean;
  isElectron: boolean;
  isBrowser: boolean;
  isLandscape: boolean;
  isPortrait: boolean;
  isSmall: boolean;
  electronVersion: string;
  useragent: UAParser.IResult;
  // cordova: SpoutCordova;
  // device: DeviceDection;
  desktopInformation: SptSystemInformation;
  currentDevice: CurrentDevice;
}

export const deviceDetectionInitialState: DeviceDetectionState = {
  isLoaded: false,
  isActive: true,
  // isIphoneNotch: false,
  // isCordova: false,
  isElectron: false,
  isBrowser: false,
  isLandscape: false,
  isPortrait: true,
  isSmall: false,
  // cordova: {
  //   version: '',
  //   platformVersion: '',
  //   platformId: 'browser',
  //   callbackId: 0,
  //   callbacks: {
  //     NetworkStatus848829231: {},
  //     StatusBar848829232: {
  //       fail: null
  //     }
  //   },
  //   callbackStatus: {
  //     NO_RESULT: 0,
  //     OK: 1,
  //     CLASS_NOT_FOUND_EXCEPTION: 2,
  //     ILLEGAL_ACCESS_EXCEPTION: 3,
  //     INSTANTIATION_EXCEPTION: 4,
  //     MALFORMED_URL_EXCEPTION: 5,
  //     IO_EXCEPTION: 6,
  //     INVALID_ACTION: 7,
  //     JSON_EXCEPTION: 8,
  //     ERROR: 9
  //   },
  //   file: {
  //     applicationDirectory: '',
  //     applicationStorageDirectory: '',
  //     dataDirectory: '',
  //     cacheDirectory: '',
  //     externalApplicationStorageDirectory: '',
  //     externalDataDirectory: '',
  //     externalCacheDirectory: '',
  //     externalRootDirectory: '',
  //     tempDirectory: '',
  //     syncedDataDirectory: '',
  //     documentsDirectory: '',
  //     sharedDirectory: ''
  //   },
  //   InAppBrowser: {}
  // },
  electronVersion: '',
  useragent: {
    ua: '',
    browser: {
      name: '',
      version: '',
      major: ''
    },
    device: {
      model: '',
      type: '',
      vendor: ''
    },
    engine: {
      name: '',
      version: ''
    },
    os: {
      name: '',
      version: ''
    },
    cpu: {
      architecture: ''
    }
  },
  // device: {
  //   available: false,
  //   platform: '',
  //   version: '',
  //   uuid: '',
  //   cordova: '',
  //   model: '',
  //   manufacturer: '',
  //   isVirtual: false,
  //   serial: ''
  // },
  currentDevice: {
    isCordova: false,
    isDesktop: false,
    isBrowser: false,
    cordovaDeviceData: {
      available: false,
      platform: '',
      version: '',
      uuid: '',
      cordova: '', // version
      model: '',
      manufacturer: '',
      isVirtual: false,
      serial: ''
    },
    browserData: null,
    cordovaElectronData: null
  },
  desktopInformation: initialDesktopInformation
};
