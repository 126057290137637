<h1 mat-dialog-title class="warn">Cannot Delete Track</h1>
<mat-dialog-content>
  <div class="pad-top-2 push-buttom-4">
    This track is selected to do these operations:
    <ul>
      <li>
        <mat-icon [ngClass]="selectedColor$ | ngrxPush">mic</mat-icon>
        <span class="push-left-20">record</span>
      </li>
      <li>
        <mat-icon [ngClass]="selectedColor$ | ngrxPush">chat</mat-icon>
        <span class="push-left-20">chat</span>
      </li>
    </ul>

    Select another track to do these operations before deleting this track.
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="ok.emit(true)">OK</button>
</mat-dialog-actions>
