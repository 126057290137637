<!--  SIGN UP WITH EMAIL  -->
<!--<h3>Sign up with email</h3>-->

<form class="example-form" [formGroup]="signUpForm">
  <mat-form-field class="full-width" appearance="fill" floatLabel="always">
    <mat-label>Email</mat-label>
    <input type="email" matInput formControlName="email" required placeholder="Ex. pat@example.com" />

    <mat-error *ngIf="signUpForm.controls['email'].hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field class="full-width password" appearance="fill" floatLabel="always">
    <mat-label>Password</mat-label>
    <input
      [type]="showPassword$ | ngrxPush"
      autocomplete="current-password"
      matInput
      formControlName="password"
      required
    />

    <mat-error *ngIf="signUpForm.controls['password'].hasError('required')">
      Password is <strong>required</strong>
    </mat-error>

    <mat-error *ngIf="signUpForm.controls['confirmPassword'].hasError('passwordsNotMatch')">
      Passwords do not match
    </mat-error>
  </mat-form-field>

  <mat-form-field class="full-width password" appearance="fill" floatLabel="always">
    <mat-label>Confirmed Password</mat-label>
    <input
      [type]="showPassword$ | ngrxPush"
      autocomplete="current-password"
      matInput
      formControlName="confirmPassword"
      required
    />

    <mat-error *ngIf="signUpForm.controls['password'].hasError('required')">
      Confirm Password is <strong>required</strong>
    </mat-error>

    <mat-error *ngIf="signUpForm.controls['confirmPassword'].hasError('passwordsNotMatch')">
      Passwords do not match
    </mat-error>
  </mat-form-field>

  <mat-checkbox (change)="showPassword($event)" [checked]="(showPassword$ | ngrxPush) === 'text'">
    Show Password
  </mat-checkbox>

  <button
    mat-flat-button
    e2e="cta-sign-in"
    color="primary"
    (click)="submitEmailPassword()"
    [disabled]="signUpForm.invalid && signUpForm.dirty"
  >
    SIGN UP
  </button>
</form>
