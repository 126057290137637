<div class="getting-started-title">HOW TO COLLABORATE</div>

<div class="steps-row">
  <div>
    <div class="step-number">1</div>
  </div>

  <div class="step-task">
    <strong>Select the song to collaborate.</strong>
  </div>
</div>

<div class="steps-row">
  <div>
    <div class="step-number">2</div>
  </div>
  <div class="step-task"><strong>Add a mix. ( REQUIRED )</strong>. Add your own mix to customize mix settings.</div>
</div>

<div class="steps-row">
  <div>
    <div class="step-number">3</div>
  </div>
  <div class="step-task"><strong>Add annotations and chat</strong>. Request and give feedback on tracks.</div>
</div>

<div class="steps-row">
  <div>
    <div class="step-number">4</div>
  </div>
  <div class="step-task"><strong>Record / import tracks.</strong> Add to the song with your own music.</div>
</div>

<div class="steps-row">
  <div>
    <div class="step-number">5</div>
  </div>
  <div class="step-task">Make great music together.</div>
</div>
