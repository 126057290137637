import {createBlobFromUint8Array} from '@spout/any-shared/fns';
import {AudioFileMetaDataEntity, getExtensionByType} from '@spout/any-shared/models';
import {ParsedRecordExport} from '@spout/web-global/models';
import {
  SpoutProxyServerDownloadParams,
  SpoutProxyServerFileParams,
  SpoutProxyServerServiceB2UploadParams
} from './proxy.models';

export function getAudioStorageFilePath(file: {projectId: string; id: string; type: string}): string {
  return `projects/${file.projectId}/audio/${file.id}.${getExtensionByType(file.type)}`;
}

export function createSpoutProxyServerServiceB2PayloadFormData(file: SpoutProxyServerServiceB2UploadParams): FormData {
  const formData: FormData = new FormData();
  // formData.append('file', createBlobFromUint8Array(file.file, file.type), file.id);
  formData.append('blob', createBlobFromUint8Array(file.file, file.type));
  formData.append('type', file.type);
  formData.append('projectId', file.projectId);
  formData.append('fileUploaded', `${file.fileUploaded}`);
  formData.append('id', file.id);

  return formData;
}

export function createSpoutProxyServerServiceB2DeleteJson(file: AudioFileMetaDataEntity): SpoutProxyServerFileParams {
  return {
    type: file.type,
    projectId: file.projectId,
    id: file.id,
    fileUploaded: file.fileUploaded
  };
}

export function createSpoutProxyServerServiceB2DownloadJson(
  file: AudioFileMetaDataEntity
): SpoutProxyServerDownloadParams {
  return {
    type: file.type,
    projectId: file.projectId,
    id: file.id
  };
}

export function transformParsedRecordExportToB2Payload(data: ParsedRecordExport) {
  return <SpoutProxyServerServiceB2UploadParams>{
    file: data.uint8ArrayType.uint8Array,
    type: data.uint8ArrayType.type,
    projectId: data.trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity.projectId,
    fileUploaded: false,
    id: data.trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity.id
  };
}
