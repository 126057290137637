<div>
  <div class="push-top-20">
    Your project is saved in
    <span class="help-bold">Project Workspace</span> where all your song files are stored locally.
  </div>

  <div class="push-top-20">
    The <span class="help-bold">Project Workspace</span> does not have to be the same as your
    <span class="help-bold">Spout Workspace</span> but it's strongly recommended you use the
    <span class="help-bold">Spout Workspace</span> to keep all your projects in one location.
  </div>

  <div class="push-top-20">
    You may choose a custom workspace for your project that is not in the
    <span class="help-bold">Spout Workspace</span>.
  </div>

  <div class="push-top-20">Learn more about <span class="help-bold">Spout Workspaces</span> in the Spout Settings.</div>
</div>
