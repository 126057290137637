import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AudioFileMetaDataEntity, TrackMixAudioSync} from '@spout/any-shared/models';
import {
  ApplySyncMix,
  ApplyVolumeMix,
  AudioComponentCombined,
  RecordType,
  StudioAppState,
  TrackEntityAndAudioBufferMetaDataEntities,
  TrackEntityAndAudioFileMetaDataEntity
} from '@spout/web-global/models';
import {isDefinedPipe} from '@uiux/rxjs';
import {max, min} from 'd3-array';
import {from, Observable, Observer, of, OperatorFunction} from 'rxjs';
import {filter, map, mergeMap, scan, switchMap, take} from 'rxjs/operators';
import {getSnippetStopTime} from '../helpers/track-mix-audio-snippet.helpers';
import {SptMergerWorklet} from '../spt-merger-worklet';
import {InputMergeMap, OutputNodes, SptTransportService} from '../spt-transport.service';
import {SptWorkletAudioPlayer} from '../spt-worklet-audio-player';
import {SptCurrentMixMetricsCalculatorService} from './spt-current-mix-metrics-calculator.service';
import {SptPlayerCache} from './spt-player-cache';
import {SptVolumeTranslateService} from './spt-volume-translate.service';

@Injectable({
  providedIn: 'root'
})
export class SptPlayerCacheService {
  constructor(
    private volumeService: SptVolumeTranslateService,
    private store: Store<StudioAppState>,
    private metrics: SptCurrentMixMetricsCalculatorService,
    public cache: SptPlayerCache,
    private transportService: SptTransportService
  ) {}

  init() {
    this.cache.init();
  }

  upsertSpoutAudioWorkletPlayerPipe() {
    const that = this;

    return (source: Observable<AudioComponentCombined>) => {
      return source.pipe(
        mergeMap((combined: AudioComponentCombined) => {
          return from(combined.t.audioFileMetaDataEntities).pipe(
            mergeMap((t: AudioFileMetaDataEntity) => {
              const trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity = {
                trackEntity: combined.trackEntity,
                audioFileMetaDataEntity: t
              };

              return that.cache
                .getSptPlayer(trackEntityAndAudioFileMetaDataEntity, {
                  isActiveInPlaylist: true
                })
                .pipe(
                  mergeMap((p: SptWorkletAudioPlayer | undefined) => {
                    if (p) {
                      return that.transportService.outputNodes$.pipe(
                        switchMap((o: OutputNodes) => {
                          return p.disconnectOutput$().pipe(map(() => o));
                        }),
                        map((o: OutputNodes) => {
                          if (o.recordType === RecordType.TRACK) {
                            p.connectOutput(o.audioContext.destination);
                          } else if (o.recordType === RecordType.MIX_MASTER) {
                            this.transportService.connectMerger$
                              .pipe(take(1))
                              .subscribe(([sptMergerWorklet, inputMergerMap]: [SptMergerWorklet, InputMergeMap]) => {
                                p.connectMerger(sptMergerWorklet, inputMergerMap);
                              });

                            // const connector = new SptConnectorWorklet(o.audioContext, { id: p.id});
                            // // connector.connect(o.audioContext.destination);
                            //
                            // const gainConnector = o.audioContext.createGain();
                            // gainConnector.gain.value = 1;
                            // p.connectMerger(gainConnector);
                            //
                            // that.transportService.addConnector(connector);
                          }

                          return p;
                        }),
                        scan((acc, curr) => ++acc, 0),
                        filter(total => {
                          return total === combined.t.audioFileMetaDataEntities.length;
                        }),
                        map(() => combined)
                      );
                    }

                    return of(combined);
                  })
                );
            })
          );
        })
      );
    };
  }

  applyWorkletSyncMix(t: ApplySyncMix): void {
    const that = this;
    // console.log('applyTrackMixesPipe');

    // const durations: number[] = [];
    // const sampleRates: number[] = [];

    from(t.snippetsSync)
      .pipe(
        switchMap((snippet: TrackMixAudioSync) => {
          const trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity = {
            trackEntity: t.trackEntity,
            audioFileMetaDataEntity: <AudioFileMetaDataEntity>{
              id: snippet.audioFileMetaDataEntityId
            }
          };

          return that.cache.getSptPlayer(trackEntityAndAudioFileMetaDataEntity).pipe(
            isDefinedPipe<SptWorkletAudioPlayer | undefined, SptWorkletAudioPlayer>(),
            map((p: SptWorkletAudioPlayer) => {
              p.setSnippet(snippet);
              // durations.push(parseFloat(<string>getSnippetStopTime(snippet)));
              // sampleRates.push(snippet.sampleRate);

              return {
                playerId: p.id,
                duration: parseFloat(<string>getSnippetStopTime(snippet)),
                sampleRate: snippet.sampleRate
              };
            })
          );
        }),
        scan(
          (acc, curr) => {
            acc.count = ++acc.count;
            acc.durations.push(curr.duration);
            acc.sampleRates.push(curr.sampleRate);
            acc.playerIds.push(curr.playerId);
            return acc;
          },
          {
            count: 0,
            durations: <number[]>[],
            sampleRates: <number[]>[],
            playerIds: <string[]>[]
          }
        ),
        filter(total => {
          return total.count === t.snippetsSync.length;
        })
      )
      .subscribe(({playerIds, sampleRates, durations, count}) => {
        // Set Mix
        const duration = max(durations) || 0;

        that.metrics.addTrackMixMetrics({
          trackId: t.trackEntity.id,
          playerIds,
          trackDuration: duration,
          maxSampleRate: max(sampleRates) || 0,
          minSampleRate: min(sampleRates) || 0
        });
      });
  }

  applyWorkletVolumeMixPipe(): (source: Observable<ApplyVolumeMix>) => Observable<ApplyVolumeMix> {
    const that = this;

    return mergeMap((v: ApplyVolumeMix) => {
      // console.log('applyVolumeMixPipe', v);

      return from(v.audioFileMetaDataEntities).pipe(
        mergeMap((s: AudioFileMetaDataEntity) => {
          const e: TrackEntityAndAudioFileMetaDataEntity = {
            trackEntity: v.trackEntity,
            audioFileMetaDataEntity: s
          };

          return that.cache.getSptPlayer(e).pipe(
            map((pi: SptWorkletAudioPlayer | undefined) => {
              if (pi) {
                pi.setVolume(v);
              }

              return s;
            })
          );
        }),

        scan((acc, curr) => ++acc, 0),
        filter(total => {
          return total === v.audioFileMetaDataEntities.length;
        }),
        map(() => {
          return v;
        })
      );
    });
  }

  muteTrackPipe(): (
    source: Observable<TrackEntityAndAudioBufferMetaDataEntities>
  ) => Observable<TrackEntityAndAudioBufferMetaDataEntities> {
    const that = this;

    return mergeMap((t: TrackEntityAndAudioBufferMetaDataEntities) => {
      return from(t.audioFileMetaDataEntities).pipe(
        map((audioFileMetaDataEntity: AudioFileMetaDataEntity) => {
          const e: TrackEntityAndAudioFileMetaDataEntity = {
            trackEntity: t.trackEntity,
            audioFileMetaDataEntity
          };

          return that.cache.getSptPlayer(e).pipe(
            map((pi: SptWorkletAudioPlayer | undefined) => {
              if (pi) {
                pi.mute = true;
              }

              return e;
            })
          );
        }),
        scan((acc, curr) => ++acc, 0),
        filter(total => {
          return total === t.audioFileMetaDataEntities.length;
        }),
        map(() => {
          return t;
        })
      );
    });
  }

  unmuteTrackPipe(): (
    source: Observable<TrackEntityAndAudioBufferMetaDataEntities>
  ) => Observable<TrackEntityAndAudioBufferMetaDataEntities> {
    const that = this;

    return mergeMap((t: TrackEntityAndAudioBufferMetaDataEntities) => {
      return from(t.audioFileMetaDataEntities).pipe(
        map((audioFileMetaDataEntity: AudioFileMetaDataEntity) => {
          const e: TrackEntityAndAudioFileMetaDataEntity = {
            trackEntity: t.trackEntity,
            audioFileMetaDataEntity
          };

          return that.cache.getSptPlayer(e).pipe(
            map((pi: SptWorkletAudioPlayer | undefined) => {
              if (pi) {
                pi.mute = false;
              }

              return e;
            })
          );
        }),
        scan((acc, curr) => ++acc, 0),
        filter(total => {
          return total === t.audioFileMetaDataEntities.length;
        }),
        map(() => {
          return t;
        })
      );
    });
  }

  muteAllPipe$() {
    return this.cache.muteAll$();
  }

  disconnectAll$() {
    return this.cache.disconnectAll$();
  }

  unmuteTracksActiveInPlaylistPipe$() {
    return this.cache.unmuteActiveInPlaylist$();
  }

  deleteTrack(trackId: string): void {
    this.cache.deleteSptPlayerByTrackId(trackId);
  }

  clearTrack(trackId: string): void {
    this.cache.clearSptPlayersByTrackId(trackId);
  }
}
