import {createBlobOctateStream, createUint8ArrayFromArrayBuffer$, encodeToArrayBuffer} from '@spout/any-shared/fns';
import {AudioFileMetaDataEntity, FileUint8ArrayType, SptSystemInformation} from '@spout/any-shared/models';
import {ParsedRecordExport, TrackEntityAndAudioFileMetaDataEntity} from '@spout/web-global/models';
import {Observable, Observer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {parseRawAudioToEntityData} from './parse-exported-wav-audio';

export function parseExportedDatAudio(
  file: TrackEntityAndAudioFileMetaDataEntity,
  context: BaseAudioContext,
  bufferData: Float32Array[]
  // desktopInformation: SptSystemInformation
): Observable<ParsedRecordExport> {
  return convertArrayToAudioBuffer(file.audioFileMetaDataEntity, context, bufferData).pipe(
    switchMap((audioBuffer: AudioBuffer) => {
      return encodeToArrayBuffer(bufferData, file.audioFileMetaDataEntity).pipe(
        switchMap((arrayBuffer: ArrayBuffer) => {
          return createUint8ArrayFromArrayBuffer$(arrayBuffer, file.audioFileMetaDataEntity.type).pipe(
            switchMap((uint8ArrayType: FileUint8ArrayType) =>
              parseRawAudioToEntityData(
                audioBuffer,
                uint8ArrayType,
                file,
                file.audioFileMetaDataEntity.type,
                // desktopInformation,
                createBlobOctateStream(arrayBuffer)
              )
            )
          );
        })
      );
    })
  );
}

export function convertArrayToAudioBuffer(
  audioMetaDataEntity: AudioFileMetaDataEntity,
  context: BaseAudioContext,
  data: Float32Array[]
): Observable<AudioBuffer> {
  return new Observable((observer: Observer<any>) => {
    // console.log('convertArrayToAudioBuffer', config, context, data);
    const channels = audioMetaDataEntity.numberOfChannels;
    const frameCount = audioMetaDataEntity.length ? audioMetaDataEntity.length : 0;
    const audioBuffer: AudioBuffer = context.createBuffer(channels, frameCount, context.sampleRate);

    for (let i = 0; i < audioMetaDataEntity.numberOfChannels; i++) {
      audioBuffer.copyToChannel(data[i], i);
    }

    observer.next(audioBuffer);
  });
}
