import {TrackMix, TrackMixAudioSnippet} from '@spout/any-shared/models';
import {ParsedRecordExport} from '@spout/web-global/models';
import {getDefaultSnippet} from '../entities/get-snippets';

export function updateTrackMixAudio(trackMix: TrackMix, parsedRecordExport: ParsedRecordExport): TrackMix {
  const defaultSnippet: TrackMixAudioSnippet | null = getDefaultSnippet(trackMix.audioSnippets);

  if (defaultSnippet) {
    const _trackMix = {
      ...trackMix,
      audioSnippets: {
        ...trackMix.audioSnippets
      }
    };

    // Save Audio Snippets
    // TODO AUDIO SNIPPET
    _trackMix.audioSnippets[defaultSnippet.audioFileMetaDataEntityId] = <TrackMixAudioSnippet>{
      ...trackMix.audioSnippets[defaultSnippet.audioFileMetaDataEntityId],
      ...defaultSnippet,
      audioDuration: parsedRecordExport.audioBuffer.duration,
      // audioFileMetaDataEntity: action.trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity,
      start: 0,
      offsetMs: parsedRecordExport.recordOffsetMs,
      sampleRate: parsedRecordExport.trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity.sampleRate,
      stop: parsedRecordExport.audioBuffer.duration
    };

    return _trackMix;
  }

  return trackMix;
}
