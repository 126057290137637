import {Dictionary, EntityState} from '@ngrx/entity/src/models';
import {createFeatureSelector, MemoizedSelector} from '@ngrx/store';
import {CurrentIds, TrackConfig, TrackEntity, TrackState} from '@spout/any-shared/models';

import {
  colorBackground,
  colorBorder,
  colorD3Path,
  colorFormFieldInput,
  colorIconText,
  createPassThroughFeatureSelector,
  createPassThroughSelector,
  getDefaultEntityConfig,
  isCreatedBy
} from '@spout/web-global/fns';
import {AccountState, StudioAppState, TRACK_FEATURE_KEY} from '@spout/web-global/models';
import {hasValue} from '@uiux/fn';
import {selectAccountState, selectUid} from '../account.selectors';
import {selectCurrentIDsFromStore, selectCurrentIDsFromStore_passThrough} from './device-storage.selectors';
import {selectCurrentSongId} from './song-storage.selectors';

// TRACK TRACK TRACK TRACK
// TRACK TRACK TRACK TRACK
// TRACK TRACK TRACK TRACK
// TRACK TRACK TRACK TRACK

export const selectTrackState = createFeatureSelector<TrackState>(TRACK_FEATURE_KEY);
export const selectTrackState_passThrough = createPassThroughFeatureSelector<StudioAppState, TrackState>(
  TRACK_FEATURE_KEY
);

/**
 * @deprecated use selectTrackEntities_passThrough
 * @param state
 */
export const selectTrackEntities = (state: StudioAppState): Dictionary<TrackEntity> => {
  if (state.track) {
    return state.track.entities;
  }

  return {};
};

export const selectTrackEntities_passThrough = createPassThroughSelector(
  selectTrackState_passThrough,
  (state: EntityState<TrackEntity>): Dictionary<TrackEntity> => state.entities
);

export const selectAllTracks_passThrough = createPassThroughSelector(
  selectTrackEntities_passThrough,
  (entities: Dictionary<TrackEntity>): TrackEntity[] => {
    if (Object.keys(entities).length) {
      return <TrackEntity[]>Object.values(entities);
    }

    return [];
  }
);

export const getAllTracksBySongIdFn: (props: {songId: string}) => MemoizedSelector<StudioAppState, TrackEntity[]> =
  (props: {songId: string}) => {
    return createPassThroughSelector(selectAllTracks_passThrough, (tracks: TrackEntity[]): TrackEntity[] => {
      return tracks.filter((track: TrackEntity) => track.songId === props.songId);
    });
  };

export const getAllTracksByProjectId = createPassThroughSelector(
  selectAllTracks_passThrough,
  (tracks: TrackEntity[], props: {projectId: string}): TrackEntity[] => {
    return tracks.filter((track: TrackEntity) => track.projectId === props.projectId);
  }
);

export const selectDefaultTrack = createPassThroughSelector(
  selectAllTracks_passThrough,
  selectAccountState,
  (entities: TrackEntity[], account: AccountState): TrackEntity | null => {
    return <TrackEntity>getDefaultEntityConfig(<{isDefault: boolean; createdByUID: string}[]>entities, account);
  }
);

export const selectCurrentTrackEntity_passThrough: MemoizedSelector<StudioAppState | any, TrackEntity | undefined> =
  createPassThroughSelector(
    selectAllTracks_passThrough,
    selectCurrentIDsFromStore_passThrough,
    (tracks: TrackEntity[], ids: CurrentIds | null): TrackEntity | undefined => {
      return (<TrackEntity[]>tracks).find((track: TrackEntity) => track && ids && track.id === ids.currentTrackId);
    }
  );

// export const selectIsTrackOwner = createPassThroughSelector(
//   selectCurrentTrackEntity,
//   selectUid,
//   (song: TrackEntity | undefined, uid: string | null) => {
//     if (song && uid) {
//       if (song.members[uid]) {
//         return song.members[uid].role.owner;
//       } else {
//         return false;
//       }
//     }
//
//     return false;
//   }
// );

export const getIsCurrentTrackEntityByIdFn: (props: {trackId: string}) => MemoizedSelector<StudioAppState, boolean> =
  (props: {trackId: string}) =>
    createPassThroughSelector(
      selectCurrentTrackEntity_passThrough,
      (currentTrack: TrackEntity | undefined): boolean =>
        currentTrack !== undefined && currentTrack.id === props.trackId
    );

export const selectedCurrentTrackEntityIsCreatedByLoggedInMusician = createPassThroughSelector(
  selectCurrentTrackEntity_passThrough,
  selectUid,
  (trackEntity: TrackEntity | undefined, email: string | undefined): boolean => {
    if (trackEntity && email) {
      return isCreatedBy(trackEntity, email);
    }

    return false;
  }
);

export const selectedNotCurrentTrackEntityIsCreatedByLoggedInMusician: MemoizedSelector<StudioAppState, boolean> =
  createPassThroughSelector(
    selectCurrentTrackEntity_passThrough,
    selectUid,
    (trackEntity: TrackEntity | undefined, uid: string | undefined): boolean => {
      if (trackEntity && uid) {
        return !isCreatedBy(trackEntity, uid);
      }
      return false;
    }
  );

export const selectCurrentTracks: MemoizedSelector<StudioAppState, TrackEntity[]> = createPassThroughSelector(
  selectCurrentSongId,
  selectAllTracks_passThrough,
  (_currentSongId: string | null, _allTracks: TrackEntity[]): TrackEntity[] => {
    return _allTracks.filter((track: TrackEntity) => track.songId === _currentSongId);
  }
);

export const selectCurrentSongTrackCount: MemoizedSelector<StudioAppState, number> = createPassThroughSelector(
  selectCurrentTracks,
  (tracks: TrackEntity[]): number => (tracks ? tracks.length : 0)
);

export const getNotHasSelectedTrack: MemoizedSelector<StudioAppState, boolean> = createPassThroughSelector(
  selectCurrentTrackEntity_passThrough,
  (currentTrack: TrackConfig | undefined): boolean => !hasValue(currentTrack)
);

export const selectCurrentTrackName = createPassThroughSelector(
  selectCurrentTrackEntity_passThrough,
  (trackConfig: TrackEntity | undefined) => (trackConfig ? trackConfig.name : '')
);

export const selectCurrentTrackId = createPassThroughSelector(
  selectCurrentTrackEntity_passThrough,
  (trackConfig: TrackEntity | undefined) => (trackConfig ? trackConfig.id : '')
);

export const selectedTrackIconTextColor = createPassThroughSelector(
  selectCurrentTrackEntity_passThrough,
  (currentTrack: TrackEntity | undefined) =>
    currentTrack && currentTrack.color ? colorIconText(currentTrack.color) : null
);

export const selectedTrackD3Path = createPassThroughSelector(
  selectCurrentTrackEntity_passThrough,
  (currentTrack: TrackEntity | undefined) =>
    currentTrack && currentTrack.color ? colorD3Path(currentTrack.color) : null
);

// export const selectedTrackColorConversation = createPassThroughSelector(selectCurrentTrackEntity, (currentTrack: TrackEntity) =>
//   currentTrack && currentTrack.color ? colorConversation(currentTrack.color) : null
// );

export const selectedTrackColorBackground = createPassThroughSelector(
  selectCurrentTrackEntity_passThrough,
  (currentTrack: TrackEntity | undefined) =>
    currentTrack && currentTrack.color ? colorBackground(currentTrack.color) : null
);

export const selectedTrackColorFormFieldInput = createPassThroughSelector(
  selectCurrentTrackEntity_passThrough,
  (currentTrack: TrackEntity | undefined) =>
    currentTrack && currentTrack.color ? colorFormFieldInput(currentTrack.color) : null
);

export const selectedTrackColorBorder = createPassThroughSelector(
  selectCurrentTrackEntity_passThrough,
  (currentTrack: TrackEntity | undefined) =>
    currentTrack && currentTrack.color ? colorBorder(currentTrack.color) : null
);

export const getTrackEntityByIdFn: (props: {
  trackId: string;
}) => MemoizedSelector<StudioAppState, TrackEntity | null | undefined> = (props: {trackId: string}) =>
  createPassThroughSelector(
    selectTrackEntities_passThrough,
    (entities: Dictionary<TrackEntity>): TrackEntity | null | undefined => {
      if (entities[props.trackId]) {
        return entities[props.trackId];
      }
      return null;
    }
  );

export const selectedColorIconTextCSS = createPassThroughSelector(
  selectCurrentTrackEntity_passThrough,
  (track: TrackEntity | undefined): string | null | undefined => {
    if (track && track.color) {
      return colorIconText(track.color);
    }
    return null;
  }
);
