import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FirebaseConfig, IEnvironmentState, StudioAppState} from '@spout/web-global/models';

export const environmentFeatureKey = 'environment';

export const getEnvironmentState = createFeatureSelector<IEnvironmentState>(environmentFeatureKey);

export const getFirebaseProject = createSelector(getEnvironmentState, (state: IEnvironmentState) => state.firebase);

export const getFirebaseProjectId = createSelector(getFirebaseProject, (firebase: FirebaseConfig | null) => {
  if (firebase) {
    return firebase.projectId;
  }

  return '';
});
