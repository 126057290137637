import {Musician} from '@spout/any-shared/models';
import {
  MusicianNotification,
  NotificationModel,
  NotificationActionTypes,
  NotificationLevel,
  NotificationTypes,
  StaticNotificationIds,
  GenericNotification
} from '@spout/web-global/models';
import {v4 as uuidv4} from 'uuid';
import {ProjectNode} from '../../+project/project.node';

/**
 * NotificationTypes.UserInviteToProject
 * @param sourceMusician
 * @param targetMusician
 * @param project
 */
export function createNotificationInviteMusicianToProject(
  sourceMusician: Musician,
  targetMusician: Musician,
  project: ProjectNode
): MusicianNotification {
  const message = `${sourceMusician.stageName} has invited you
  to collaborate on the project "${project.name}".`;

  return {
    id: uuidv4(),
    header: 'Invite',
    type: NotificationTypes.UserInviteToProject,
    level: NotificationLevel.Low,
    message,
    sourceMusician,
    targetMusician
  };
}

export function createUiUpdateNotification(version: string): NotificationModel {
  return {
    id: StaticNotificationIds.UpdateUI,
    type: NotificationTypes.UpdateUI,
    header: 'Update Spout UI',
    message: `Spout UI version ${version} is available.`,
    level: NotificationLevel.Critical,
    cta: 'Update',
    action: NotificationActionTypes.UpdateUi,
    canDismiss: false
  };
}

export function createElectronUpdateNotification(version: string): NotificationModel {
  return {
    id: StaticNotificationIds.UpdateElectron,
    type: NotificationTypes.UpdateElectron,
    header: 'Update Spout App',
    message: `Spout App version ${version} is available for download.`,
    level: NotificationLevel.Critical,
    cta: 'Get Download',
    action: NotificationActionTypes.UpdateElectron,
    canDismiss: false
  };
}

export function createUploadErrorNotification(message: string): NotificationModel {
  return {
    id: uuidv4(),
    type: NotificationTypes.UploadError,
    header: 'File Upload Error',
    message,
    level: NotificationLevel.Critical,
    cta: null,
    action: NotificationActionTypes.UploadError,
    canDismiss: true
  };
}

export function createGenericNotification(n: GenericNotification): NotificationModel {
  return {
    id: uuidv4(),
    type: n.type,
    header: n.header,
    message: n.message,
    level: n.level,
    // cta: null,
    // action: null,
    canDismiss: n.canDismiss
  };
}
