import {FileType} from '@spout/any-shared/models';

export interface SpoutProxyServerFileParams {
  type: FileType;
  projectId: string;
  id: string;
  fileUploaded: boolean;
}

export interface SpoutProxyServerDownloadParams {
  type: FileType;
  projectId: string;
  id: string;
}

export interface SpoutProxyServerServiceB2UploadParams {
  file: Uint8Array;
  type: FileType;
  projectId: string;
  id: string;
  fileUploaded: boolean;
}

export interface SpoutProxyServerServiceB2UploadPayload {
  blob: Blob;
  type: FileType;
  projectId: string;
  id: string;
  fileUploaded: string;
}

export interface SpoutProxyServerServiceB2UploadResponse {
  type: FileType;
  projectId: string;
  id: string;
  fileUploaded: boolean;
  storageLocation: 'google' | 'backblazeb2';
}

export interface UploadStatusSpoutProxyServerServiceB2UploadResponse {
  progress: number;
  result: SpoutProxyServerServiceB2UploadResponse | null;
}
