import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {
  AudioFileMetaDataEntity,
  MixEntity,
  ProjectEntity,
  SongEntity,
  TrackEntity,
  TrackMix
} from '@spout/any-shared/models';

export const addAudioTrackEntityToSongAndMixAction = createAction('[ADD/API] Add Track To Song/Mix');
export const addSongToProjectAction = createAction(
  '[ADD/API] Add Song To Project',
  props<{
    project: ProjectEntity;
    songName: string;
    songDescription: string;
  }>()
);
export const addMixToSongAction = createAction(
  '[ADD/API] Add Mix To Song',
  props<{
    song: SongEntity;
    mix: MixEntity;
    mixName: string;
    mixDescription: string;
  }>()
);
export const copyMixToSongAction = createAction(
  '[ADD/API] Copy Mix',
  props<{
    song: SongEntity;
    mix: MixEntity;
    mixName: string;
    mixDescription: string;
  }>()
);
export const addProjectAction = createAction(
  '[ADD/API] Add Project',
  props<{
    projectName: string;
    projectDescription: string;
  }>()
);
export const createProjectAction = createAction(
  '[ADD/API] Create Project',
  props<{
    project: ProjectEntity;
    song: SongEntity;
    mix: MixEntity;
    track: TrackEntity;
    file: AudioFileMetaDataEntity;
    trackMix: TrackMix;
  }>()
);
export const createSongAction = createAction(
  '[ADD/API] Create Song',
  props<{
    song: SongEntity;
    mix: MixEntity;
    track: TrackEntity;
    file: AudioFileMetaDataEntity;
    trackMix: TrackMix;
  }>()
);

export const createMixAction = createAction(
  '[ADD/API] Create Mix',
  props<{
    mix: MixEntity;
    trackMixs: TrackMix[];
  }>()
);

export const createTrackAction = createAction(
  '[ADD/API] Create Track',
  props<{
    mixConfigs: Update<MixEntity>[];
    track: TrackEntity;
    file: AudioFileMetaDataEntity;
    trackMixs: TrackMix[];
  }>()
);
