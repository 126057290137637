<div [@marginTop]="hasMessages$ | ngrxPush">
  <spt-warn-message
    @showMessage
    *ngFor="let message of warnMessages$ | ngrxPush"
    (dismiss)="deleteMessage(message.id)"
    >{{ message.message }}</spt-warn-message
  >
  <spt-success-message
    @showMessage
    *ngFor="let message of successMessages$ | ngrxPush"
    (dismiss)="deleteMessage(message.id)"
    >{{ message.message }}</spt-success-message
  >
</div>
