import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveComponentModule} from '@ngrx/component';
import {TrackItemDialogComponent} from './track-item-dialog.component';
import {WarnClearSelectedComponent} from './warn-clear-seletected/warn-clear-selected.component';
import {WarnDeleteSelectedComponent} from './warn-delete-seletected/warn-delete-selected.component';
import {WarnTrackRecordedComponent} from './warn-track-recorded/warn-track-recorded.component';
import {WarnIsMasterComponent} from './warn-is-master/warn-is-master.component';

@NgModule({
  declarations: [
    TrackItemDialogComponent,
    WarnClearSelectedComponent,
    WarnDeleteSelectedComponent,
    WarnTrackRecordedComponent,
    WarnIsMasterComponent
  ],
  exports: [TrackItemDialogComponent],
  imports: [CommonModule, ReactiveComponentModule, MatDialogModule, MatButtonModule, MatIconModule]
})
export class TrackItemDialogModule {}
