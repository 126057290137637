import {
  AudioFileMetaDataEntity,
  EntityType,
  TrackEntity,
  TrackMix,
  TrackMixAudioSnippet,
  TrackMixEntity
} from '@spout/any-shared/models';
import {AccountState, applyInitialAuthor, createAuthorMusician} from '@spout/web-global/models';
import {uuid} from '../uuid';
import {createInitialTrackMixAudioSnippet} from './create-initial-track-mix-audio-snippet';

export function createInitialTrackMixConfig(
  account: AccountState,
  track: Partial<TrackEntity>,
  mixId: string,
  audioFileMetaDataEntity: AudioFileMetaDataEntity,
  currentSongTrackCount = 0
): TrackMix {
  const trackMix = <TrackMix>{
    id: uuid(),
    entityType: EntityType.TRACK_MIX,
    volume: 100,
    orderNum: currentSongTrackCount + 1,
    isReference: false,
    mute: false,
    // same as track-audio
    trackId: track.id,
    songId: track.songId,
    projectId: track.projectId,
    name: track.name,
    mixId,
    audioSnippets: {
      [audioFileMetaDataEntity.id]: createInitialTrackMixAudioSnippet(audioFileMetaDataEntity)
    }
  };

  return applyInitialAuthor<TrackMix, TrackMixEntity>(trackMix, createAuthorMusician(account));
}

/**
 * Resets some values of TrackMix
 * @param trackMix
 * @param account
 */
export function createNewTrackMixFromTrackMix(trackMix: TrackMix, mixId: string, account: AccountState): TrackMix {
  const newTrackMix = {
    ...trackMix,
    id: uuid(),
    mixId,
    volume: 100,
    mute: false
    // updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    // createdAt: firebase.firestore.FieldValue.serverTimestamp()
  };

  return applyInitialAuthor<TrackMix, TrackMixEntity>(newTrackMix, createAuthorMusician(account));
}

export function setTrackMixProps(trackMix: TrackMix, props: Partial<TrackMix>): TrackMix {
  return {
    ...trackMix,
    ...props
  };
}

export function setTrackMixAudioFileSnippetPropsByAudioFileMetaDataEntityId(
  trackMix: TrackMix,
  audioFileEntityId: string,
  props: Partial<TrackMixAudioSnippet>
): TrackMix {
  trackMix.audioSnippets[audioFileEntityId] = {
    ...trackMix.audioSnippets[audioFileEntityId],
    ...props
  };

  return trackMix;
}

export function setTrackMixAudioFileSnippetPropsByAudioFileMetaDataEntity(
  trackMix: TrackMix,
  audioFileEntity: AudioFileMetaDataEntity,
  props: Partial<TrackMixAudioSnippet>
): TrackMix {
  return setTrackMixAudioFileSnippetPropsByAudioFileMetaDataEntityId(trackMix, audioFileEntity.id, props);
}

export function assignTrackMixAudioFileMetaMethod(audioFileEntity: AudioFileMetaDataEntity) {
  return function (trackMix: TrackMix, update: Partial<TrackMix>) {
    return setTrackMixAudioFileSnippetPropsByAudioFileMetaDataEntity(trackMix, audioFileEntity, update);
  };
}
