import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {DYN_STORE, StudioAppState} from '@spout/web-global/models';
import {selectMasterControlIsPlaying$, selectMasterControlIsRecording$} from '@spout/web-global/selectors';
import {combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {SptCurrentMixMetricsCalculatorService} from '../audio/services/spt-current-mix-metrics-calculator.service';
import {DynamicStoreService} from './dynamic-store.service';

@Injectable({
  providedIn: 'root'
})
export class AggregateService {
  constructor(
    private dss: DynamicStoreService,
    private store: Store<StudioAppState>,
    private metrics: SptCurrentMixMetricsCalculatorService
  ) {}

  startPlayButtonDisabled$ = combineLatest([
    this.metrics.mixHasAudioToPlay$.pipe(distinctUntilChanged()),
    this.dss.store(DYN_STORE.IS_END_OF_SONG).pipe(distinctUntilChanged()),
    this.store.pipe(selectMasterControlIsRecording$).pipe(distinctUntilChanged())
  ]).pipe(
    map(([hasAudioToPlay, isEndOfSong, isRecording]) => {
      // console.log(`\n`);
      // console.log(`hasAudioToPlay ${hasAudioToPlay}`);
      // console.log(`isEndOfSong ${isEndOfSong}`);
      // console.log(`isRecording ${isRecording}`);
      // console.log(`startPlayButtonDisabled$ ${!(hasAudioToPlay && !isEndOfSong && !isRecording)}`);
      // console.log(`\n`);

      return !(hasAudioToPlay && !isEndOfSong && !isRecording);
    }),
    distinctUntilChanged()
  );

  stopPlayButtonDisabled$: Observable<boolean> = combineLatest([
    this.dss.store<boolean>(DYN_STORE.IS_END_OF_SONG).pipe(distinctUntilChanged()),
    this.store.pipe(selectMasterControlIsPlaying$)
  ]).pipe(
    map(([isEndOfSong, isPlaying]: [boolean, boolean]) => {
      return isEndOfSong && isPlaying;
    }),
    distinctUntilChanged<boolean>()
  );

  rewindButtonsDisabled$ = combineLatest([
    this.dss.store(DYN_STORE.IS_BEGINNING_OF_SONG).pipe(distinctUntilChanged()),
    this.store.pipe(selectMasterControlIsPlaying$).pipe(distinctUntilChanged()),
    this.store.pipe(selectMasterControlIsRecording$)
  ]).pipe(
    map(([isBeginningOfSong, isPlaying, isRecording]) => {
      return (isBeginningOfSong && !isPlaying) || isRecording;
    })
  );

  notHasAudioOrIsRecording$ = combineLatest([
    this.store.pipe(selectMasterControlIsRecording$),
    this.metrics.mixHasAudioToPlay$
  ]).pipe(
    map(([isRecording, hasAudioToPlay]) => {
      return !hasAudioToPlay || isRecording;
    }),
    distinctUntilChanged()
  );
}
