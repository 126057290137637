import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {deleteConversation, deleteTrackEffect, sendMessage, updateConversation} from '@spout/web-global/actions';
import {firestoreMessageByIdPath} from '@spout/web-global/fns';
import {AccountState, ChatEntity, createAuthorMusician, StudioAppState} from '@spout/web-global/models';
import {getAllChatsByTrackId, selectAccountState} from '@spout/web-global/selectors';
import {DocumentReference, WriteBatch} from 'firebase/firestore';
import {from} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {SptFirestoreService} from '../firestore';

@Injectable({
  providedIn: 'root'
})
export class ChatEffects {
  sendConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendMessage),
        switchMap(({message}) => {
          return this.store.pipe(
            select(selectAccountState),
            take(1),
            switchMap((account: AccountState) => {
              message.createdBy = createAuthorMusician(account);

              return this.sptFirestore.upsertDoc(firestoreMessageByIdPath(message), message);
            })
          );
        })
      ),
    {dispatch: false}
  );

  updateConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateConversation),
        switchMap(({conversation}) => {
          return this.sptFirestore.upsertDoc(firestoreMessageByIdPath(conversation), conversation);
        })
      ),
    {dispatch: false}
  );

  deleteConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteConversation),
        switchMap(({chats}) => {
          const batch: WriteBatch = this.sptFirestore.writeBatch();

          chats.forEach((_chat: ChatEntity) => {
            const doc: DocumentReference = this.sptFirestore
              // .collection(fileCollection(file))
              .docRef(firestoreMessageByIdPath(_chat));

            batch.delete(doc);
          });

          return from(batch.commit());
        })
      ),
    {dispatch: false}
  );

  deleteTrack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteTrackEffect),
        switchMap(action => {
          const batch: WriteBatch = this.sptFirestore.writeBatch();

          return this.store.pipe(
            select(getAllChatsByTrackId, {
              trackId: action.trackModel.trackEntity.id
            }),
            take<ChatEntity[]>(1),
            switchMap((chats: ChatEntity[]) => {
              chats.forEach((_chat: ChatEntity) => {
                const doc: DocumentReference = this.sptFirestore
                  // .collection(fileCollection(file))
                  .docRef(firestoreMessageByIdPath(_chat));

                batch.delete(doc);
              });

              return from(batch.commit());
            })
          );
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    public sptFirestore: SptFirestoreService,
    public store: Store<StudioAppState>
  ) {}
}
