import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';

export interface ButtonSaveState {
  saved: boolean;
  pristine: boolean;
  dirty: boolean;
  saving: boolean;
}

export const initialButtonState: ButtonSaveState = {
  saved: false,
  pristine: false,
  dirty: false,
  saving: false
};

export function getInitialButtonState(): ButtonSaveState {
  return JSON.parse(JSON.stringify(initialButtonState));
}

export class ButtonStateModel {
  state$ = new BehaviorSubject(getInitialButtonState());
  pristine$ = this.state$.pipe(map((state: ButtonSaveState) => state.pristine));
  dirty$ = this.state$.pipe(map((state: ButtonSaveState) => state.dirty));
  saving$ = this.state$.pipe(map((state: ButtonSaveState) => state.saving));
  saved$ = this.state$.pipe(map((state: ButtonSaveState) => state.saved));
  disabled$ = this.state$.pipe(map((state: ButtonSaveState) => state.pristine || state.saved));

  pristine() {
    this.state$.next({
      saved: false,
      pristine: true,
      dirty: false,
      saving: false
    });
  }

  saved() {
    this.state$.next({
      saved: true,
      pristine: false,
      dirty: false,
      saving: false
    });
  }

  dirty() {
    this.state$.next({
      saved: false,
      pristine: false,
      dirty: true,
      saving: false
    });
  }

  saving() {
    this.state$.next({
      saved: false,
      pristine: false,
      dirty: false,
      saving: true
    });
  }
}
