<div>
  <div class="push-bottom-20" *ngIf="showFileLoad">
    You can create a track by loading a
    <span class="help-bold-accent">mp3</span>, <span class="help-bold-accent">ogg</span>, or
    <span class="help-bold-accent">wav</span> file.
    <span *ngIf="showSelectText"
      >Select the
      <button mat-flat-button (click)="loadFile()">
        <mat-icon>attach_file</mat-icon>
      </button>
      tab to load a file.</span
    >
  </div>

  <div class="push-bottom-20" *ngIf="isReference">
    A <span class="help-bold-accent">Master Track</span> is required to be created first as a guide and reference to
    record other tracks.
  </div>

  <div class="push-bottom-20">
    Select <span class="help-bold-accent">Share Track</span> to share your track with other musicians in your project.
  </div>

  <div class="push-top-20">
    Selecting <span class="help-bold">Save</span> will save your track in your song "<span class="help-bold-accent">{{
      currentSongName$ | async
    }}</span
    >".
  </div>
</div>
