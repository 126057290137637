import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {authLogout} from '@spout/web-global/actions';
import {SptFirestoreService} from '@spout/web-global/data-access';
import {AccountState, ENVIRONMENT, IEnvironmentState} from '@spout/web-global/models';
import {getIsLoggedIn, selectAccountState} from '@spout/web-global/selectors';
import {animationDelay, animationDuration} from '@spout/web-global/ui';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AlertMessageContainerComponent} from '@spout/web-global/ui';
import {ALERT_MESSAGE_ID} from '@spout/web-global/ui';

import {LOGIN_CONFIG} from './login.config';
import {EmailPassword, LOGIN_KEY} from './login.model';
import {MyErrorStateMatcher} from './logn.validators';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  User,
  UserCredential,
  OAuthProvider
} from 'firebase/auth';

@Component({
  selector: 'auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showMessage', [
      state('false', style({opacity: 0})),
      state('true', style({opacity: 1})),
      transition('false => true', [animate(`${animationDuration} ${animationDelay} ease-out`)]),
      transition('true => false', [animate(`${animationDuration} ease-in`)])
    ])
  ]
})
export class LoginComponent {
  private loginStorageState: string | null;
  showProgress$: BehaviorSubject<boolean>;
  showUi$: BehaviorSubject<boolean>;
  forgotPassword = false;
  selected = new FormControl(0);
  matcher = new MyErrorStateMatcher();
  config = LOGIN_CONFIG;
  ALERT_MESSAGE_ID = ALERT_MESSAGE_ID;
  isLoggedIn$: Observable<boolean>;
  notLoggedIn$: Observable<boolean>;
  account$: Observable<AccountState>;

  @Input() signInWithPopup = false;
  @Output() authEmailUser: EventEmitter<User | null> = new EventEmitter();

  @HostBinding('class.login-component') bind = true;

  @ViewChild('alertMessageContainerComponent')
  alertMessageContainerComponent!: AlertMessageContainerComponent;
  @ViewChild('alertMessageContainerComponentForgotPassword')
  alertMessageContainerComponentForgotPassword!: AlertMessageContainerComponent;
  @ViewChild('alertMessageContainerComponentSignUp')
  alertMessageContainerComponentSignUp!: AlertMessageContainerComponent;

  constructor(
    private _customFirebase: SptFirestoreService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private store: Store,
    @Inject(ENVIRONMENT) private _env: IEnvironmentState
  ) {
    this.showProgress$ = new BehaviorSubject<boolean>(false);
    this.loginStorageState = localStorage.getItem(LOGIN_KEY);
    this.showUi$ = new BehaviorSubject<boolean>(false);
    this.isLoggedIn$ = this.store.pipe(select(getIsLoggedIn));
    this.notLoggedIn$ = this.isLoggedIn$.pipe(map((isLoggedIn: boolean) => !isLoggedIn));
    this.account$ = this.store.pipe(select(selectAccountState));
  }

  setTabIndex(index: number) {
    this.selected.setValue(index, {emitEvent: false});
  }

  enableForgotPassword() {
    this.forgotPassword = true;
    this.selected.setValue(2);
    this.cd.detectChanges();
  }

  signInWithGoogle() {
    // https://firebase.google.com/docs/auth/web/google-signin
    const googleAuthProvider = new GoogleAuthProvider();
    // googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');

    // https://firebase.google.com/docs/auth/web/google-signin
    //https://firebase.google.com/docs/reference/js/v8/firebase.auth.GoogleAuthProvider#setcustomparameters
    // https://developers.google.com/identity/protocols/oauth2/openid-connect#authenticationuriparameters
    // https://developers.google.com/identity/protocols/oauth2/openid-connect#authenticationuriparameters

    const params: any = {
      prompt: 'select_account'
      // hd: this._env.deviceBuild.authHD
      // display: this._env.deviceBuild.authDisplay
    };

    googleAuthProvider.setCustomParameters(params);

    if (this.signInWithPopup) {
      this._customFirebase.signInWithPopup(googleAuthProvider).then((userCredential: UserCredential) => {
        // console.log(userCredential);
        this.authEmailUser.emit(userCredential.user);
      });
    } else {
      this._customFirebase.signInWithRedirect(googleAuthProvider).then(() => {
        /* noop */
      });
    }
  }

  signInWithFacebook() {
    const facebookAuthProvider = new FacebookAuthProvider();

    if (this.signInWithPopup) {
      this._customFirebase.signInWithPopup(facebookAuthProvider).then((userCredential: UserCredential) => {
        // console.log(userCredential);
        this.authEmailUser.emit(userCredential.user);
      });
    } else {
      this._customFirebase.signInWithRedirect(facebookAuthProvider).then(() => {
        /* noop */
      });
    }
  }

  /**
   * https://firebase.google.com/docs/auth/web/apple?authuser=0
   */
  signInWithApple() {
    const provider = new OAuthProvider('apple.com');

    if (this.signInWithPopup) {
      this._customFirebase.signInWithPopup(provider).then((userCredential: UserCredential) => {
        // console.log(userCredential);
        this.authEmailUser.emit(userCredential.user);
      });
    } else {
      this._customFirebase.signInWithRedirect(provider).then(() => {
        /* noop */
      });
    }
  }

  signInWithTwitter() {
    const twitterAuthProvider = new TwitterAuthProvider();

    if (this.signInWithPopup) {
      this._customFirebase.signInWithPopup(twitterAuthProvider).then((userCredential: UserCredential) => {
        // console.log(userCredential);
        this.authEmailUser.emit(userCredential.user);
      });
    } else {
      this._customFirebase.signInWithRedirect(twitterAuthProvider).then(() => {
        /* noop */
      });
    }
  }

  signInWithEmail(c: EmailPassword) {
    this._customFirebase
      .signInWithEmailAndPassword(c.email, c.password)
      .then((userCredential: UserCredential) => {
        // console.log(userCredential);
        this.authEmailUser.emit(userCredential.user);
      })
      .catch((error: any) => {
        if (error.message && error.message.length) {
          this.alertMessageContainerComponent.addWarnMessage({
            id: ALERT_MESSAGE_ID.PASSWORD_AUTH,
            message: error.message
          });
          // this.cd.detectChanges();
        }
      });
  }

  createUserWithEmailAndPassword(c: EmailPassword) {
    this._customFirebase
      .createUserWithEmailAndPassword(c.email, c.password)
      .then((userCredential: UserCredential) => {
        // console.log(userCredential);
        this.authEmailUser.emit(userCredential.user);
      })
      .catch((error: any) => {
        this.alertMessageContainerComponentSignUp.addWarnMessage({
          id: ALERT_MESSAGE_ID.CREATE_PASSWORD,
          message: error.message
        });
      });
  }

  sendEmailPasswordReset(email: string) {
    this._customFirebase
      .sendPasswordResetEmail(email)
      .then(() => {
        // Email sent.
        this.alertMessageContainerComponentForgotPassword.addSuccessMessage({
          id: ALERT_MESSAGE_ID.RESET_EMAIL_SENT,
          message: 'Check your email for reset instructions.'
        });
      })
      .catch((error: any) => {
        this.alertMessageContainerComponentForgotPassword.addWarnMessage({
          id: ALERT_MESSAGE_ID.EMAIL_RESET_PASSWORD,
          message: error.message
        });
      });
  }

  logout() {
    this.store.dispatch(authLogout());
  }
}
