import {firebaseDevConfig, algoliaConfigDev} from '../firebase/environment.firebas.config';
import {endpointsStaging} from './endpoints/endpoints.staging';

import {faceBookInit, IEnvironmentState} from './models/environment.models';

/*
K000DWPy6RO5e/R6ZYz9sG3rVh4f8fM
 */

export const devEnv: IEnvironmentState = {
  production: false,
  mobileTest: false,
  appName: 'studio-app',
  facebookAppId: '2219988261549213',
  firebaseProjectID: 'spout-dev-d0c97',
  firebase: firebaseDevConfig,
  // firebaseMonitor: firebaseMonitorConfig,
  serviceWorker: true,

  // proxy server
  endpoints: endpointsStaging,
  algolia: algoliaConfigDev,
  emulator: false,
  authSite: 'https://dev.auth.spoutstudio.io'
};

export const devEnvLocal: IEnvironmentState = {
  ...devEnv,
  serviceWorker: true
};

faceBookInit(devEnvLocal.facebookAppId);
