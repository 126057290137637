import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {
  CurrentSubscription,
  Customer,
  Price,
  PriceEntities,
  PriceInterval,
  PriceOptions,
  PriceOptionsFlat,
  Product,
  ACCOUNT_ROLE,
  SubscriptionItem
} from '@spout/web-global/models';
import {hasValue} from '@uiux/fn';
import {combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map, take} from 'rxjs/operators';
import {monthlyPriceEntityByRole, productEntityByRole, selectStorageAddonPriceMonthly} from './payment-wizard.fns';

import {
  Checkout,
  ItemizedCost,
  PaymentWizardForm,
  ProductEntities,
  ProductWizardStore,
  UpdateCheckout
} from './payment-wizard.service.model';
import {
  getCurrentPlanId,
  getPlanPriceByInterval,
  getPlanPricesByInterval,
  getStoragePriceByInterval,
  getStoragePricesByInterval,
  isUpdatedCheckoutState,
  planPriceOptions,
  totalCost
} from './stripe-payment.fns';

@Injectable({
  providedIn: 'root'
})
export class StripePaymentSelectionService extends ComponentStore<ProductWizardStore> {
  constructor() {
    super({
      stripeId: null,
      stripeLink: null,

      productEntities: {},
      priceEntities: {},

      trialDays: 0,

      // Current Musican's Subscription Prices
      // currentSubscriptionEntities: {},
      cancelAtPeriodEnd: false,
      currentSubscription: null,
      currentSubscriptionId: null,
      currentSubscriptionPlan: null,
      currentSubscriptionStorage: null,

      selectedProduct: null,
      selectedPlanPrice: null,
      selectedStoragePrice: null,

      additionalStoragePerGB: 0,
      earlyAdopterAgreementSigned: false,

      totalCost: 0,
      isPerMonth: false
    });
  }

  readonly addSubscription = this.updater((state: ProductWizardStore, subscription: CurrentSubscription) => {
    return {
      ...state,
      currentSubscription: subscription,
      cancelAtPeriodEnd: subscription.cancel_at_period_end
    };
  });

  readonly selectCurrentSubscription$ = this.select((state: ProductWizardStore) => {
    return state.currentSubscription;
  });

  readonly cancelAtPeriodEnd = this.select((state: ProductWizardStore) => {
    return state.cancelAtPeriodEnd;
  });

  readonly addCurrentSubscriptionId = this.updater((state: ProductWizardStore, subscriptionId: string) => {
    return {
      ...state,
      currentSubscriptionId: subscriptionId
    };
  });

  readonly addTrialDays = this.updater((state: ProductWizardStore, trialDays: number) => {
    return {
      ...state,
      trialDays
    };
  });

  readonly addCurrentSubscriptionPlan = this.updater((state: ProductWizardStore, plan: SubscriptionItem) => {
    const _state = {
      ...state,
      currentSubscriptionPlan: plan
    };

    const selectedProduct: Product = state.productEntities[plan.price.product.id];
    _state.selectedProduct = selectedProduct;
    _state.selectedPlanPrice = state.priceEntities[plan.price.id];

    return _state;
  });

  readonly addCurrentSubscriptionStorage = this.updater((state: ProductWizardStore, storage: SubscriptionItem) => {
    const _state = {
      ...state,
      currentSubscriptionStorage: storage
    };

    const selectedStoragePrice = getStoragePriceByInterval(state, state.priceEntities[storage.price.id].interval);
    _state.selectedStoragePrice = selectedStoragePrice?.price ? selectedStoragePrice?.price : null;
    _state.additionalStoragePerGB = storage.quantity;

    return _state;
  });

  readonly addCustomer = this.updater((state: ProductWizardStore, customer: Customer) => {
    return {
      ...state,
      ...customer
    };
  });

  readonly addProduct = this.updater((state: ProductWizardStore, product: Product) => {
    return {
      ...state,
      productEntities: {
        ...state.productEntities,
        [product.id]: product
      }
    };
  });

  readonly addPrice = this.updater((state: ProductWizardStore, price: Price) => {
    return {
      ...state,
      priceEntities: {
        ...state.priceEntities,
        [price.id]: price
      }
    };
  });

  readonly signEarlyAdopterAgreement = this.updater((state: ProductWizardStore, signed: boolean) => {
    return {
      ...state,
      earlyAdopterAgreementSigned: signed
    };
  });

  readonly addSelectedStoragePriceEntity = this.updater((state: ProductWizardStore, price: Price) => {
    return {
      ...state,
      selectedStoragePrice: price
    };
  });

  readonly updatePayment = this.updater((state, payment: PaymentWizardForm) => {
    if (hasValue(state.productEntities) && hasValue(state.priceEntities) && payment) {
      let s = {
        ...state
      };

      if (payment.priceId && state.priceEntities[payment.priceId]) {
        const selectedProduct: Product = state.productEntities[state.priceEntities[payment.priceId].productId];
        const selectedPlanPrice: Price = state.priceEntities[payment.priceId];
        const earlyAdopterAgreementSigned =
          state.selectedProduct?.role === ACCOUNT_ROLE.EARLY_ADOPTER ? state.earlyAdopterAgreementSigned : false;

        const selectedStoragePrice = getStoragePriceByInterval(state, selectedPlanPrice.interval);

        s = {
          ...s,
          selectedProduct,
          selectedStoragePrice: selectedStoragePrice?.price ? selectedStoragePrice?.price : null,
          selectedPlanPrice: selectedPlanPrice,
          isPerMonth: state.priceEntities[payment.priceId].interval === 'month',
          earlyAdopterAgreementSigned: earlyAdopterAgreementSigned,
          additionalStoragePerGB: payment.additionalStoragePerGB
        };
      }

      return s;
    }

    return {
      ...state
    };
  });

  readonly selectTrialDays$: Observable<number> = this.select(state => {
    return state.trialDays;
  });

  readonly selectProductByRoleDict$: Observable<ProductEntities> = this.select(state => {
    return productEntityByRole(state.productEntities);
  });

  readonly selectPriceByMonthDict$: Observable<PriceEntities> = this.select(state => {
    return monthlyPriceEntityByRole(state, 'month');
  });

  readonly selectPriceByYearDict$: Observable<PriceEntities> = this.select(state => {
    return monthlyPriceEntityByRole(state, 'year');
  });

  readonly selectStorageAddonMonthly$: Observable<Price | null> = this.select(state => {
    return selectStorageAddonPriceMonthly(state);
  });

  readonly getEarlyAdopterSelected$: Observable<boolean> = this.select(state => {
    return state.selectedProduct?.role === ACCOUNT_ROLE.EARLY_ADOPTER;
  });

  readonly checkoutDisabled$: Observable<boolean> = this.select(state => {
    const isEarlyAdopterAndNotSigned =
      state.selectedProduct?.role === ACCOUNT_ROLE.EARLY_ADOPTER && !state.earlyAdopterAgreementSigned;

    if (state.currentSubscriptionPlan !== null) {
      if (state.cancelAtPeriodEnd) {
        return isEarlyAdopterAndNotSigned;
      }

      return !isUpdatedCheckoutState(state) || isEarlyAdopterAndNotSigned;
    }

    return state.selectedPlanPrice === null || isEarlyAdopterAndNotSigned;
  });

  readonly selectCheckout$: Observable<Checkout> = this.select(state => {
    return {
      subscriptionId: state.currentSubscriptionId,
      stripeId: state.stripeId,
      selectedProduct: state.selectedProduct,
      selectedPlanPrice: state.selectedPlanPrice,
      selectedStoragePrice: state.selectedStoragePrice,
      additionalStoragePerGB: state.additionalStoragePerGB,
      earlyAdopterAgreementSigned: state.earlyAdopterAgreementSigned
    };
  });

  readonly updateCheckout$: Observable<UpdateCheckout> = this.select(state => {
    return {
      currentSubscriptionId: <string>state.currentSubscriptionId,
      currentSubscriptionPlan: state.currentSubscriptionPlan,
      currentSubscriptionStorage: state.currentSubscriptionStorage,

      subscriptionId: state.currentSubscriptionId,
      stripeId: state.stripeId,
      selectedProduct: state.selectedProduct,
      selectedPlanPrice: state.selectedPlanPrice,
      selectedStoragePrice: state.selectedStoragePrice,
      additionalStoragePerGB: state.additionalStoragePerGB,
      earlyAdopterAgreementSigned: state.earlyAdopterAgreementSigned
    };
  });

  readonly itemizedCheckoutList$: Observable<ItemizedCost[]> = this.select(state => {
    const items: ItemizedCost[] = [];

    if (state.selectedPlanPrice) {
      items.push({
        isTrial: false,
        itemName: state.selectedProduct?.name !== undefined ? state.selectedProduct.name : '',
        itemPrice: state.selectedPlanPrice.unit_amount / 100,
        isTotal: false,
        isIncludedStorage: false,
        baseStorage: <string>state.selectedProduct?.stripe_metadata_baseStorageGB
      });

      items.push({
        isTrial: false,
        itemName: `${state.selectedProduct?.stripe_metadata_baseStorageGB} GB Storage`,
        itemPrice: 0,
        isIncludedStorage: true,
        isTotal: false,
        baseStorage: <string>state.selectedProduct?.stripe_metadata_baseStorageGB
      });
    }

    if (state.selectedStoragePrice && state.additionalStoragePerGB > 0) {
      const storagePrice: number = (state.selectedStoragePrice.unit_amount / 100) * state.additionalStoragePerGB;

      items.push({
        isTrial: false,
        itemName: `${state.additionalStoragePerGB} GB Additional Storage`,
        itemPrice: storagePrice,
        isTotal: false,
        isIncludedStorage: false,
        baseStorage: <string>state.selectedProduct?.stripe_metadata_baseStorageGB
      });
    }

    return items;
  });

  readonly planPriceOptions$: Observable<PriceOptions[]> = this.select(
    state => {
      return planPriceOptions(state);
    },
    {debounce: true}
  );

  readonly totalCost$: Observable<number> = this.itemizedCheckoutList$.pipe(
    map((items: ItemizedCost[]) => {
      return totalCost(items);
    })
  );

  readonly isPerMonth$: Observable<boolean> = this.select(state => state.isPerMonth);

  readonly additionalStoragePerGB$: Observable<number | null> = this.select((state: ProductWizardStore) => {
    return state.additionalStoragePerGB !== null && state.additionalStoragePerGB !== undefined
      ? state.additionalStoragePerGB
      : null;
  });

  readonly disableAdditionalStoragePerGBInput$: Observable<boolean> = this.select(state => {
    return (
      (state.currentSubscriptionPlan !== null &&
        state.currentSubscriptionPlan.price.product.metadata.accountRole === ACCOUNT_ROLE.COLLABORATOR) ||
      (state.selectedProduct !== null && state.selectedProduct.role === ACCOUNT_ROLE.COLLABORATOR)
    );
  });

  readonly getCurrentSubscriptionStorageQuantity: Observable<number | null> = this.select(state => {
    return state.currentSubscriptionStorage && state.currentSubscriptionStorage.quantity !== undefined
      ? state.currentSubscriptionStorage.quantity
      : null;
  });

  readonly combinedStorageDisableAndQuantity$: Observable<[boolean, number]> = combineLatest([
    this.disableAdditionalStoragePerGBInput$.pipe(distinctUntilChanged()),
    this.getCurrentSubscriptionStorageQuantity.pipe(distinctUntilChanged()),
    this.additionalStoragePerGB$.pipe(distinctUntilChanged())
  ]).pipe(
    map(([isStorageDisabled, currentStorageQuantity, additionalStorage]: [boolean, number | null, number | null]) => {
      const storageQuantity =
        additionalStorage !== null ? additionalStorage : currentStorageQuantity !== null ? currentStorageQuantity : 0;

      return [isStorageDisabled, storageQuantity];
    })
  );

  readonly getCurrentPlanId$ = this.select((state: ProductWizardStore) => {
    return getCurrentPlanId(state);
  });

  readonly isCurrentSubscriptionPlan = (id: string) => {
    return this.select(state => {
      return state.currentSubscriptionPlan && state.currentSubscriptionPlan.price.id === id;
    }).pipe(take(1));
  };

  readonly getPlanPricesByInterval = (interval: PriceInterval) =>
    this.select(state => {
      return getPlanPricesByInterval(state, interval);
    });

  readonly getPlanPriceByInterval = (interval: PriceInterval): Observable<PriceOptionsFlat | null> =>
    this.select(state => {
      return getPlanPriceByInterval(state, interval);
    });

  readonly getStoragePricesByInterval = (interval: PriceInterval) =>
    this.select(state => {
      return getStoragePricesByInterval(state, interval);
    });

  readonly getStoragePriceByInterval = (interval: PriceInterval): Observable<PriceOptionsFlat | null> =>
    this.select(state => {
      return getStoragePriceByInterval(state, interval);
    });
}
