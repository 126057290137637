import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ReactiveComponentModule} from '@ngrx/component';
import {PromoCodeFormComponent} from './promo-code-form.component';

@NgModule({
  declarations: [PromoCodeFormComponent],
  exports: [PromoCodeFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveComponentModule
  ]
})
export class PromoCodeFormModule {}
