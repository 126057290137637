import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppOtlidLoginComponent} from './app-otlid-login.component';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveComponentModule} from '@ngrx/component';

@NgModule({
  declarations: [AppOtlidLoginComponent],
  imports: [CommonModule, MatButtonModule, ReactiveComponentModule],
  exports: [AppOtlidLoginComponent]
})
export class AppOtlidLoginModule {}
