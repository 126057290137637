import {Observable, Subject, Subscriber, Subscription, SubscriptionLike} from 'rxjs';
import {map} from 'rxjs/operators';

function createErrorClass<T>(createImpl: (_super: any) => any): T {
  const _super = (instance: any) => {
    Error.call(instance);
    instance.name = instance.constructor.name;
    instance.stack = new Error().stack;
  };

  const ctorFunc = createImpl(_super);
  ctorFunc.prototype = Object.create(Error.prototype);
  ctorFunc.prototype.constructor = ctorFunc;
  return ctorFunc;
}

// tslint:disable-next-line:no-empty-interface
type ObjectUnsubscribedError = Error;

interface ObjectUnsubscribedErrorCtor {
  // tslint:disable-next-line
  new (): ObjectUnsubscribedError;
}

/**
 * An error thrown when an action is invalid because the object has been
 * unsubscribed.
 *
 * @see {@link Subject}
 * @see {@link BehaviorSubject}
 *
 * @class ObjectUnsubscribedError
 */
const ObjectUnsubscribedError: ObjectUnsubscribedErrorCtor = createErrorClass(
  _super =>
    // tslint:disable-next-line:no-shadowed-variable
    function ObjectUnsubscribedError(this: any) {
      _super(this);
      this.message = 'object unsubscribed';
    }
);

export class SptMapSubject<T> extends Subject<Map<string, T>> {
  private readonly _map: Map<string, T>;

  constructor() {
    super();
    this._map = new Map<string, T>();
  }

  get value(): Map<string, T> {
    return this.getValue();
  }

  get map(): Map<string, T> {
    return this._map;
  }

  get size() {
    return this._map.size;
  }

  get size$() {
    return this.pipe(
      map((cache: Map<string, T>) => {
        return cache.size;
      })
    );
  }

  has(key: string) {
    return this._map.has(key);
  }

  has$(key: string): Observable<boolean> {
    return this.pipe(
      map((cache: Map<string, T>) => {
        return cache.has(key);
      })
    );
  }

  keys() {
    return this._map.keys();
  }

  keys$(): Observable<IterableIterator<string>> {
    return this.pipe(
      map((cache: Map<string, T>) => {
        return cache.keys();
      })
    );
  }

  values() {
    return this._map.values();
  }

  values$(): Observable<IterableIterator<T>> {
    return this.pipe(
      map((cache: Map<string, T>) => {
        return cache.values();
      })
    );
  }

  get(key: string): T | undefined {
    return this._map.get(key);
  }

  set(key: string, value: T) {
    if (!this._map.has(key)) {
      this._map.set(key, value);
      this.next(this._map);
    }
  }

  delete(key: string) {
    this._map.delete(key);
  }

  entries() {
    return this._map.entries();
  }

  entries$(): Observable<IterableIterator<[string, T]>> {
    return this.pipe(
      map((cache: Map<string, T>) => {
        return cache.entries();
      })
    );
  }

  /** @deprecated This is an internal implementation detail, do not use. */
  override _subscribe(subscriber: Subscriber<Map<string, T>>): Subscription {
    const subscription = super._subscribe(subscriber);
    if (subscription && !(<SubscriptionLike>subscription).closed) {
      subscriber.next(this._map);
    }
    return subscription;
  }

  getValue(): Map<string, T> {
    if (this.hasError) {
      throw this.thrownError;
    } else if (this.closed) {
      throw new ObjectUnsubscribedError();
    } else {
      return this._map;
    }
  }

  override next(value: Map<string, T>): void {
    super.next(value);
  }
}
