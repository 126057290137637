import {AuthState} from '@spout/web-global/models';
import {getIn, hasValue, hasValueIn} from '@uiux/fn';
import {User, UserInfo} from 'firebase/auth';

export function getUserValue(user: User, key: string) {
  if (hasValueIn(user, key)) {
    return getIn(user, key);
  } else if (hasValueIn(user, `providerData[0].${key}`)) {
    return getIn(user, `providerData[0].${key}`);
  } else {
    return null;
  }
}

function getProviderData(user: User, key: string) {
  if (hasValueIn(user, `providerData[0].${key}`)) {
    return getIn(user, `providerData[0].${key}`);
  } else if (hasValueIn(user, key)) {
    return getIn(user, key);
  } else {
    return null;
  }
}

export function processRedirectUser(user: User): AuthState {
  // let providerId: string = null;
  //
  // if (hasValueIn(result, 'credential.providerId')) {
  //   providerId = getIn(result, 'credential.providerId');
  // } else if (hasValueIn(result, 'providerData[0].providerId')) {
  //   providerId = getIn(result, 'providerData[0].providerId');
  // } else {
  //   providerId = null;
  // }

  return <AuthState>{
    isLoggedIn: true,
    loginFail: false,
    uid: user.uid,
    // displayName: user.displayName,
    displayName: getUserValue(user, 'displayName'),
    // photoURL: user.photoURL,
    photoURL: getUserValue(user, 'photoURL'),
    // email: user.email,
    email: getUserValue(user, 'email'),
    emailVerified: user.emailVerified,
    // phoneNumber: user.phoneNumber,
    phoneNumber: getUserValue(user, 'phoneNumber'),
    // providerId: providerId,
    providerId: getProviderData(user, 'providerId'),
    isAnonymous: false,
    isOnline: true

    // not exposed by firebase User interface
    // stsTokenManager: result.user.stsTokenManager,
  };
}

export function processAuthStateUser(result: User): AuthState | null {
  if (result.providerData[0]) {
    const providerData: UserInfo | null = result.providerData[0];

    // if (providerData) {
    //   delete providerData.uid
    // }

    return <AuthState>{
      ...providerData,

      isLoggedIn: true,
      loginFail: false,
      uid: result.uid,
      // displayName: result.displayName,
      // photoURL: result.photoURL,
      // email: result.email,
      emailVerified: result.emailVerified,
      // phoneNumber: result.phoneNumber,
      // providerId: providerId,
      isAnonymous: false,
      isOnline: true

      // not exposed by firebase User interface
      // stsTokenManager: result.user.stsTokenManager,

      // currentProjectId: getIn(result, 'currentProjectId'),
      // currentSongId: getIn(result, 'currentSongId'),
      // currentTrackId: getIn(result, 'currentTrackId'),
    };
  } else {
    return null;
  }
}
