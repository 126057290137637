import {NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProjectEntity, TrackEntity} from '@spout/any-shared/models';
import {deleteTracks, upsertTracks} from '@spout/web-global/actions';
import {aggregateDocChanges, firestoreTracksPath} from '@spout/web-global/fns';
import {AggregateFirebaseSnapshotChangesEntities, AuthAccountStates} from '@spout/web-global/models';
import {onSnapshot, QuerySnapshot, DocumentData, DocumentChange} from 'firebase/firestore';
import {Subscription} from 'rxjs';
import {SptFirestoreService} from '../../firestore';
import {QueryModel} from './query.model';

export class QueryTracksService implements QueryModel<TrackEntity> {
  // TRACK
  private tracksCombinedSub: Subscription = Subscription.EMPTY;
  private otherTracksSub: (() => void) | undefined;
  private myTracksSub: (() => void) | undefined;

  constructor(
    private sptFirebase: SptFirestoreService,
    private store: Store,
    private config: ProjectEntity,
    private zone: NgZone
  ) {}

  onConnect(user: AuthAccountStates) {
    const that = this;

    // BEGIN TRACKS
    // const trackOwner$: Observable<DocumentChange[]> = new Observable<DocumentChange[]>(
    //   (observer: Observer<DocumentChange[]>) => {
    //     this.myTracksSub = firebase
    //       .firestore()
    //       .collection(firestoreTracksPath(this.config))
    //       .where('ownerUIDs', 'array-contains', user.auth.uid)
    //       .onSnapshot(
    //         (snapshot: firebase.firestore.QuerySnapshot) => {
    //           // that.processTracks(snapshot, user.auth.uid);
    //           observer.next(snapshot.docChanges());
    //         },
    //         () => {
    //           /* noop */
    //         },
    //         () => {
    //           /* noop */
    //         }
    //       );
    //   }
    // );
    //
    // const trackMember$: Observable<DocumentChange[]> = new Observable<DocumentChange[]>(
    //   (observer: Observer<DocumentChange[]>) => {
    //     this.otherTracksSub = firebase
    //       .firestore()
    //       .collection(firestoreTracksPath(this.config))
    //       .where('shared', '==', true)
    //       .where('memberUIDs', 'array-contains', user.auth.uid)
    //       .onSnapshot(
    //         (snapshot: firebase.firestore.QuerySnapshot) => {
    //           // that.processTracks(snapshot, user.auth.uid);
    //           observer.next(snapshot.docChanges());
    //         },
    //         () => {
    //           /* noop */
    //         },
    //         () => {
    //           /* noop */
    //         }
    //       );
    //   }
    // );
    //
    // this.tracksCombinedSub.unsubscribe();
    // this.tracksCombinedSub = combineLatest([trackOwner$, trackMember$])
    //   .pipe(
    //     map(([owner, member]: [DocumentChange[], DocumentChange[]]) => {
    //       // console.log(owner, member);
    //       return [...owner, ...member];
    //     })
    //   )
    //   .subscribe((r: DocumentChange[]) => {
    //     that.process.apply(that, [r, <string>user.auth.uid]);
    //   });

    this.otherTracksSub = onSnapshot(
      this.sptFirebase.collectionRef(firestoreTracksPath(this.config)),
      (snapshot: QuerySnapshot) => {
        if (user.auth.uid) {
          that.process.apply(that, [snapshot.docChanges(), user.auth.uid]);
        }
      },
      () => {
        /* noop */
      },
      () => {
        /* noop */
      }
    );
  }

  onDisconnect(user?: AuthAccountStates) {
    // TRACKS
    this.tracksCombinedSub.unsubscribe();

    if (this.otherTracksSub) {
      this.otherTracksSub();
    }
    if (this.myTracksSub) {
      this.myTracksSub();
    }
  }

  process(snapshot: DocumentChange<DocumentData>[], createdByUID: string) {
    const that = this;
    const aggregate: AggregateFirebaseSnapshotChangesEntities<DocumentData> = aggregateDocChanges<DocumentData>(
      snapshot
      // createdByUID
    );

    that.zone.run(() => {
      if (aggregate.added.length) {
        // aggregate.added.forEach((added) => {
        //   console.log('TRACKS ADDED', added.id);
        // });

        that.store.dispatch(
          upsertTracks({
            tracks: <TrackEntity[]>aggregate.added
          })
        );
      }

      if (aggregate.modified.length) {
        // aggregate.modified.forEach((modified) => {
        //   console.log('TRACKS MODIFIED', modified.id);
        // });

        that.store.dispatch(
          upsertTracks({
            tracks: <TrackEntity[]>aggregate.modified
          })
        );
      }

      if (aggregate.removed.length) {
        // aggregate.removed.forEach((removed) => {
        //   console.log('TRACKS REMOVED', removed);
        // });

        that.store.dispatch(
          deleteTracks({
            ids: aggregate.removed
          })
        );
      }
    });
  }
}
