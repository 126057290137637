import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthCloseBrowserComponent} from './auth-close-browser.component';
import {MatCardModule} from '@angular/material/card';

@NgModule({
  declarations: [AuthCloseBrowserComponent],
  imports: [CommonModule, MatCardModule],
  exports: [AuthCloseBrowserComponent]
})
export class AuthCloseBrowserModule {}
