import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {PagePoliciesModule} from '@spout/studio-site/feature-page-policies';
import {AccountEffects, SptFirestoreService} from '@spout/web-global/data-access';
import {ENVIRONMENT, initialWebsocketRegistryState, LOGOUT_REDIRECT} from '@spout/web-global/models';
import {WINDOW_PROVIDERS} from '@web-global/utils';
import {FirebaseUiModule} from '@spout-app-v2/web-global/auth';
import {UiLoginModule} from '@spout-app-v2/web-global/auth';
import {WebPlatformLoginDataAccessModule} from '@spout/web-global/data-access';

import {AppComponent} from './app.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {AuthAppHeaderModule} from './components/app-header/app-header.module';

import {environment} from '../environments/environment';

import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveComponentModule} from '@ngrx/component';
import {AuthErrorModule} from './components/auth-error/auth-error.module';
import {AuthCloseBrowserModule} from './components/auth-close-browser/auth-close-browser.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AuthLogginInModule} from './components/auth-loggin-in/auth-loggin-in.module';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthAppHeaderModule,
    UiLoginModule,
    RouterModule.forRoot([]),
    ReactiveComponentModule,
    AuthErrorModule,
    AuthCloseBrowserModule,
    AuthLogginInModule,
    FirebaseUiModule,
    PagePoliciesModule,

    MatSidenavModule,
    MatProgressBarModule,
    MatDialogModule,

    WebPlatformLoginDataAccessModule,
    EffectsModule.forRoot([AccountEffects])
  ],
  providers: [
    WINDOW_PROVIDERS,
    SptFirestoreService,
    {provide: LOGOUT_REDIRECT, useValue: ''},
    {
      provide: ENVIRONMENT,
      useValue: environment
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
