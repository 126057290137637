import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {TreeConfig} from '@spout/any-shared/models';
import {INSTRUMENT_FEATURE_KEY} from '../studio-app-keys';

export enum InstrumentActionTypes {
  InstrumentLoaded = '[Instrument] Instrument Loaded',
  InstrumentSaveSaved = '[InstrumentSave] InstrumentSave Saved',
  InstrumentsSelected = '[Account] Instruments Selected',
  SaveInstrumentsToFirebase = '[Account] Save Instruments to Firebase'
}

export const instrumentAdapter: EntityAdapter<TreeConfig> = createEntityAdapter<TreeConfig>();

export interface InstrumentState extends EntityState<TreeConfig> {
  rootNode: TreeConfig | null; // Instrument Tree
  loaded?: boolean; // has the Instrument list been loaded
  error?: any; // last none error (if any)
}

export interface InstrumentPartialState {
  readonly [INSTRUMENT_FEATURE_KEY]: InstrumentState;
}

export const instrumentInitialState: InstrumentState = instrumentAdapter.getInitialState({
  rootNode: null,
  loaded: false,
  error: null
});
