import {createFeatureSelector, createSelector} from '@ngrx/store';
import {createPassThroughFeatureSelector, createPassThroughSelector} from '@spout/web-global/fns';
import {DIALOG_COMPONENT, DialogQueue, dialogQueuesFeatureKey, DialogQueueState} from '@spout/web-global/models';

export const selectDialogQueueFeatureState_PassThrough =
  createPassThroughFeatureSelector<DialogQueueState>(dialogQueuesFeatureKey);

export const selectAllDialogs_PassThrough = createPassThroughSelector(
  selectDialogQueueFeatureState_PassThrough,
  (state: DialogQueueState): DialogQueue[] => {
    if (state && state.entities) {
      return Object.values(state.entities) as DialogQueue[];
    }

    return [];
  }
);

export const openNextDialog_PassThrough = createPassThroughSelector(
  selectAllDialogs_PassThrough,
  (dialogs: DialogQueue[]): DialogQueue | undefined => {
    return dialogs.find((d: DialogQueue) => d.isOpen);
  }
);

export const dialogsStoreIsLoaded_PassThrough = createPassThroughSelector(
  selectDialogQueueFeatureState_PassThrough,
  (state: DialogQueueState) => state.isLoaded
);

export const destroyDialogStream = (dialogId: DIALOG_COMPONENT) => {
  return createPassThroughSelector(selectAllDialogs_PassThrough, (dialogs: DialogQueue[]): boolean => {
    const dialog = dialogs.find((d: DialogQueue) => d.id === dialogId);

    if (dialog === undefined || dialog === null) {
      return false;
    }

    return dialog.destroy;
  });
};

export const getDialogIsOpenById = (dialogId: DIALOG_COMPONENT) => {
  return createPassThroughSelector(selectAllDialogs_PassThrough, (dialogs: DialogQueue[]): boolean => {
    const dialog = dialogs.find((d: DialogQueue) => d.id === dialogId);

    return dialog !== undefined && dialog.isOpen;
  });
};
