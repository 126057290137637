import {BehaviorSubject, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {ChangeDetectorRef, ElementRef} from '@angular/core';
import {CommonChartConfig, ElSizeConfigDimensionsData, JSONDOMRect, SizeConfigDimensions} from './chart.models';
import {calculateDimensions, processConfig, processResize} from './fns/chart.fns';
import {filter, mergeMap, takeUntil} from 'rxjs/operators';
import {AbstractChartLayout} from './abstract-chart-layout';

export abstract class AbstractChartComponent<ChartConfig, ChartData, TooltipData> {
  protected _onDestroy$: Subject<boolean> = new Subject<boolean>();
  protected _data$: ReplaySubject<ChartData[]> = new ReplaySubject<ChartData[]>(1);
  protected _config$: ReplaySubject<ChartConfig> = new ReplaySubject<ChartConfig>(1);
  protected onResize: ReplaySubject<DOMRectReadOnly> = new ReplaySubject<DOMRectReadOnly>(1);
  tooltipData$: ReplaySubject<TooltipData>;
  showNoData: BehaviorSubject<boolean>;
  onResize$: ReplaySubject<DOMRectReadOnly>;

  protected chartContainer: ElementRef | null = null;

  constructor(
    protected _cd: ChangeDetectorRef,
    protected _chart: AbstractChartLayout<ChartConfig, ChartData, TooltipData>
  ) {
    // Detach change detection
    // D3 will handle changes in DOM
    this._cd.detach();
    this._onDestroy$ = new Subject<boolean>();

    this.tooltipData$ = this._chart.toolTipData$;
    this.showNoData = new BehaviorSubject<boolean>(false);
    this.onResize$ = new ReplaySubject<DOMRectReadOnly>(1);
  }

  protected init() {
    const that = this;

    if (this.chartContainer?.nativeElement) {
      const config$: Observable<CommonChartConfig> = this._config$.pipe(
        filter((c: ChartConfig) => c !== undefined && c !== null),
        processConfig
      );
      const resize$: Observable<JSONDOMRect> = this.onResize$.pipe(processResize);
      const data$ = this._data$;

      of(this.chartContainer?.nativeElement)
        .pipe(
          AbstractChartLayout.CreateBaseLayout(),
          this._chart.appendLayout(),
          mergeMap((el: HTMLElement) =>
            config$.pipe(
              mergeMap((config: CommonChartConfig) =>
                resize$.pipe(
                  calculateDimensions(config),
                  AbstractChartLayout.ResizeBaseLayout(el),
                  mergeMap((scd: SizeConfigDimensions) => data$.pipe(that._chart.resizeDataLayout({el, ...scd})))
                )
              )
            )
          ),
          takeUntil(this._onDestroy$)
        )
        .subscribe((d: any) => {
          that._chart.applyData(<ElSizeConfigDimensionsData<ChartData>>d);
        });
    } else {
      console.error('Chart Native Element Not Available');
    }
  }
}
