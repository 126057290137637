import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  ViewEncapsulation
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

export const SVG_REFRESH =
  '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/></svg>';

export type RefreshBtnType = 'small' | 'none';

@Component({
  selector: 'spt-reset-btn',
  templateUrl: './spt-reset-btn.component.html',
  styleUrls: ['./spt-reset-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'design-refresh-btn',
    '[class.design-refresh-btn-small]': 'design === "small"'
  }
})
export class SptResetBtnComponent implements OnInit {
  @Output() reset: EventEmitter<any> = new EventEmitter<any>();

  tooltipControl = new FormControl('');

  @Input()
  set tooltip(v: string) {
    this.tooltipControl.setValue(v);
  }

  @Input() design: RefreshBtnType = 'none';

  constructor(private _iconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {
    this._iconRegistry.addSvgIconLiteral('refresh', this._domSanitizer.bypassSecurityTrustHtml(SVG_REFRESH));
  }

  onRefresh() {
    this.reset.emit(true);
  }

  ngOnInit(): void {}
}
