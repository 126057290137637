import {AudioFileMetaData} from './entities/audio-file-meta-data';

export interface RecorderState {
  recording: boolean;
  pause: boolean;
  stop: boolean;
  hasData: boolean;
}

export const initialRecorderState: RecorderState = {
  recording: false,
  pause: false,
  stop: false,
  hasData: false
};

export interface DecodedArrayBuffer {
  float32Arrays: Float32Array[];
  config: AudioFileMetaData;
}
