import {Action, createReducer, on} from '@ngrx/store';
import {
  accountLoaded,
  accountUpdated,
  addSubscriptionIdToAccount,
  addSubscriptionItemToAccount,
  addSubscriptionToAccount,
  addTrialToAccount,
  authAlert,
  authError,
  authIsOffline,
  authIsOnline,
  authLoadUser,
  authLoggedOut,
  clearSubscriptions,
  deletePromoCode,
  loadPermissionsData,
  loadPromoCode,
  selectedProjectEntity,
  updateTotalCloudStorageUsed
} from '@spout/web-global/actions';
import {parseAlert, parseError, validateOnboardingValues} from '@spout/web-global/fns';
import {
  accountInitialState,
  authAccountInitialState,
  AuthAccountProductStates,
  authInitialState,
  getPermissionsRole,
  getPermissionState,
  getProjectRole,
  initialPromoCode,
  permissionInitialState
} from '@spout/web-global/models';
import {allValuesTruthy} from '@uiux/fn';

const reducer = createReducer(
  authAccountInitialState,
  on(accountLoaded, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    const _state: AuthAccountProductStates = {
      ...state,
      auth: {
        ...state.auth,
        ...action.payload.auth
      },
      account: {
        ...state.account,
        ...action.payload.account,
        uid: action.payload.auth.uid
      },
      isLoaded: action?.payload.auth?.uid ? action.payload.auth.uid.length > 0 : false,
      isOnboarded: allValuesTruthy(validateOnboardingValues(action.payload.account))
    };

    return {
      ..._state
    };
  }),
  // on(addSubscriptionsToAccount, (state: AuthAccountProductStates, action) => {
  //   if (action.subscriptions) {
  //     return <AuthAccountProductStates>{
  //       ...state,
  //       subscriptionItemEntities: [...action.subscriptions],
  //       isLoaded: true
  //     };
  //   }
  //
  //   return {
  //     ...state
  //   };
  // }),
  on(clearSubscriptions, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    return <AuthAccountProductStates>{
      ...state,
      subscriptionItemEntities: {}
    };
  }),
  on(accountUpdated, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    return {
      ...state,
      account: {
        ...state.account,
        ...action.account
      },
      isOnboarded: allValuesTruthy(validateOnboardingValues(action.account))
    };
  }),
  on(addTrialToAccount, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    return {
      ...state,
      trial: {
        ...state.trial,
        days: action.days
      }
    };
  }),
  on(authLoggedOut, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    state = <AuthAccountProductStates>{
      isLoaded: false,
      auth: {
        ...authInitialState
      },
      account: {
        ...accountInitialState
      },
      subscriptionItemEntities: {},
      ...permissionInitialState
    };
    return state;
  }),

  // AUTH
  on(authLoadUser, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    return <AuthAccountProductStates>{
      ...state,
      auth: {
        ...state.auth,
        ...action.payload
      }
    };
  }),
  on(authAlert, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    const newState: AuthAccountProductStates = {
      ...state
    };
    newState.auth.alertMessage = parseAlert(action.payload);
    newState.auth.isLoggedIn = false;
    return newState;
  }),
  on(authError, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    const newState: AuthAccountProductStates = {
      ...state
    };
    newState.auth.loginError = parseError(action.payload);
    newState.auth.isLoggedIn = false;
    return newState;
  }),
  on(authIsOnline, (state: AuthAccountProductStates): AuthAccountProductStates => {
    return <AuthAccountProductStates>{
      ...state,
      auth: {
        ...state.auth,
        isOnline: true
      }
    };
  }),
  on(authIsOffline, (state: AuthAccountProductStates): AuthAccountProductStates => {
    return <AuthAccountProductStates>{
      ...state,
      auth: {
        ...state.auth,
        isOnline: false
      }
    };
  }),

  on(addSubscriptionToAccount, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    return <AuthAccountProductStates>{
      ...state,
      subscription: action.subscription
    };
  }),

  on(addSubscriptionItemToAccount, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    let accountRole = action.subscription?.price?.metadata?.accountRole;

    accountRole = accountRole !== null && accountRole !== 'null' ? accountRole : null;

    if (accountRole && !(state.promoCode && state.promoCode.accountRole && state.promoCode.accountRole.length)) {
      const permissionRole = getPermissionsRole(state);

      const _state: AuthAccountProductStates = {
        ...state,
        accountRole,
        permissionRole,
        permissions: getPermissionState(permissionRole, state.featurePermissions, state.permissions)
      };

      return {
        ..._state
      };
    }

    return {
      ...state,
      subscriptionItemEntities: {
        ...state.subscriptionItemEntities,
        [action.subscription.id]: action.subscription
      }
    };
  }),

  on(selectedProjectEntity, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    const _state: AuthAccountProductStates = {
      ...state,
      projectRole: getProjectRole(action.projectEntity, state.account.uid)
    };

    const permissionRole = getPermissionsRole(_state);

    return {
      ..._state,
      permissionRole,
      permissions: getPermissionState(permissionRole, state.featurePermissions, state.permissions)
    };
  }),

  on(addSubscriptionIdToAccount, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    return {
      ...state,
      subscriptionId: action.subscriptionId
    };
  }),

  on(loadPermissionsData, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    const permissionRole = getPermissionsRole(state);

    const _state: AuthAccountProductStates = {
      ...state,
      featurePermissions: action.featurePermissions,
      permissionRole,
      permissions: getPermissionState(permissionRole, action.featurePermissions, state.permissions)
    };

    return {
      ..._state
      // isInTrialPeriod: isInTrialPeriod(_state)
    };
  }),

  on(loadPromoCode, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    const permissionRole = getPermissionsRole(state);

    const _state: AuthAccountProductStates = {
      ...state,
      permissionRole,
      promoCode: action.promoCode.active ? {...action.promoCode} : {...initialPromoCode}
    };

    return {
      ..._state,
      permissions: getPermissionState(permissionRole, _state.featurePermissions, _state.permissions)
    };
  }),

  on(deletePromoCode, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    const permissionRole = getPermissionsRole(state);

    const _state: AuthAccountProductStates = {
      ...state,
      permissionRole,
      promoCode: {...initialPromoCode}
    };

    return {
      ..._state,
      permissions: getPermissionState(permissionRole, _state.featurePermissions, _state.permissions)
    };
  }),
  on(updateTotalCloudStorageUsed, (state: AuthAccountProductStates, action): AuthAccountProductStates => {
    return {
      ...state,
      totalCloudStorageUsed: action.totalCloudStorageUsed
    };
  })
);

export function accountReducer(state: AuthAccountProductStates | undefined, action: Action): AuthAccountProductStates {
  return reducer(state, action);
}
