/**
 * @deprecated
 * @param config
 */
import {AudioFileMetaData} from './entities/audio-file-meta-data';
import {TrackEntity} from './entities/track-entity.model';
import {TreeConfig} from './entities/tree.model';

export function workspaceKey(config: Partial<TreeConfig>): string {
  return `workspace-${config.id}`;
}

/**
 * @deprecated
 * @param config
 */
export function projectKey(config: TreeConfig): string {
  return `project-${config.id}`;
}

// export function projectKeyFromTrack(config: FileConfig): string {
//   return `audio-file-${config.id}`;
// }

export function trackKey(config: TrackEntity): string {
  return `track-${config.id}`;
}

export function audioFileKey(config: AudioFileMetaData): string {
  return `audio-${config.id}`;
}

export function songFileKey(config: AudioFileMetaData): string {
  return `song-${config.id}`;
}
