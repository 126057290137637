export const ACCOUNT_FEATURE_KEY = 'account';
export const AUDIO_FILE_META_DATA_FEATURE_KEY = 'audioFileMetaData';
export const AUTH_FEATURE_KEY = 'auth';
export const CHAT_FEATURE_KEY = 'chat';
export const DEVICE_DETECTION_FEATURE_KEY = 'deviceDetection';
export const deviceStorageFeatureKey = 'deviceStorage';
export const ENVIRONMENT_FEATURE_KEY = 'environment';
export const GENRE_FEATURE_KEY = 'genres';
export const genresMusicianFeatureKey = 'genresMusicians';
export const INSTRUMENT_FEATURE_KEY = 'instruments';
export const instrumentMusicianFeatureKey = 'instrumentMusicians';
export const mixesFeatureKey = 'mix';
export const notificationsesFeatureKey = 'notifications';
export const PROJECT_FEATURE_KEY = 'project';
export const MIXERRIGHTDRAWER_FEATURE_KEY = 'mixerRightDrawer';
export const SONG_FEATURE_KEY = 'song';
export const studioFeatureKey = 'studio';
export const ROUTER_FEATURE_KEY = 'router';
export const TRANSPORT_FEATURE_KEY = 'transport';
export const TRACK_FEATURE_KEY = 'track';
export const trackMixesFeatureKey = 'trackMixes';
export const WEBSOCKET_REGISTRY_FEATURE_KEY = 'webSocketRegistry';
