import {CurrentIds, MixEntity, TrackEntity} from '@spout/any-shared/models';

export function currentIdSet(track?: Partial<TrackEntity>, mix?: Partial<MixEntity>): CurrentIds {
  if (track && track.projectId && track.songId && track.id && mix && mix.id) {
    return {
      currentProjectId: track.projectId,
      currentSongId: track.songId,
      currentTrackId: track.id,
      currentMixId: mix.id
    };
  }

  return {
    currentProjectId: null,
    currentSongId: null,
    currentTrackId: null,
    currentMixId: null
  };
}
