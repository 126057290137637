<div class="no-email-warning-dialog">
  <!--  <button class="close-icon-button" mat-icon-button mat-dialog-close  (click)="ok()">-->
  <!--    <mat-icon>close</mat-icon>-->
  <!--  </button>-->

  <h1 mat-dialog-title class="warn">Account Must Include Email</h1>
  <mat-dialog-content>
    <div class="push-top-20">
      You not not have an email associated with your
      <span class="accent">{{ providerId$ | async }}</span> account. You must have an email associated with your account
      to log into Spout.
    </div>

    <div class="push-top-20 push-bottom-20">We do not share your private information</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="ok()">OK</button>
  </mat-dialog-actions>
</div>
