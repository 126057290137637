<div class="label" [style.width.px]="labelWidth$ | ngrxPush" *ngIf="showLabel$ | ngrxPush">{{ label$ | ngrxPush }}</div>
<div class="slider">
  <mat-slider
    [step]="step$ | ngrxPush"
    [value]="value$ | ngrxPush"
    [min]="min$ | ngrxPush"
    [max]="max$ | ngrxPush"
    [thumbLabel]="thumbLabel$ | ngrxPush"
    (change)="onChange($event)"
  ></mat-slider>
  <div class="min">{{ min$ | ngrxPush }}</div>
  <div class="max">{{ max$ | ngrxPush }}</div>
</div>
<div class="value" *ngIf="showUnits$ | ngrxPush" [style.width.px]="valueWidth$ | ngrxPush">
  {{ value$ | ngrxPush }} {{ units$ | ngrxPush }}
</div>
