import {Injectable} from '@angular/core';
import {SongEntity} from '@spout/any-shared/models';
import {firestoreSongByIdPath} from '@spout/web-global/fns';
import {Observable, Observer} from 'rxjs';
import {Exists, SptFirestoreService} from '../firestore';

@Injectable({
  providedIn: 'root'
})
export class SongService {
  constructor(private sptFirestore: SptFirestoreService) {}

  createSong(song: Partial<SongEntity>): Observable<SongEntity> {
    return new Observable((observer: Observer<any>) => {
      this.sptFirestore.setDocIfNotExist<SongEntity>(firestoreSongByIdPath(song), song).subscribe(
        (r: Exists<SongEntity>) => {
          observer.next(r.data);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  updateSong(song: Partial<SongEntity>): Observable<SongEntity> {
    return new Observable((observer: Observer<any>) => {
      this.sptFirestore.update<SongEntity>(firestoreSongByIdPath(song), song).subscribe(
        (r: Exists<SongEntity>) => {
          observer.next(r.data);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }
}
