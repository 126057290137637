import {ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {concatElectronBuildVersion, StudioVersion} from '@spout/any-shared/models';
import {removeTimestampCTorFromDocumentSnapshot, SptFirestoreService} from '@spout/web-global/data-access';
import {studioVersion} from '@spout/web-global/fns';
import {ENVIRONMENT, IEnvironmentState} from '@spout/web-global/models';
import {combineLatest, Observable, Observer, of, ReplaySubject, Subject} from 'rxjs';
import {catchError, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {UAParser} from 'ua-parser-js';

import {onSnapshot, DocumentSnapshot, FirestoreError} from 'firebase/firestore';

@Component({
  selector: 'spt-download-spout',
  templateUrl: './download-spout.component.html',
  styleUrls: ['./download-spout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadSpoutComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();
  private ua: UAParser | undefined;
  private fbSub: (() => void) | undefined;

  private downloadPcUrl = 'installers/Spout-x64.msix';
  private downloadMacIntelUrl = 'installers/spout-installer-intel.dmg';
  private downloadMacM1Url = 'installers/spout-installer-m1.dmg';

  private downloadPcUrl_Dev = 'installers/Spout-Dev-x64.msix';
  private downloadMacIntelUrl_Dev = 'installers/spout-installer-intel-dev.dmg';
  private downloadMacM1Url_Dev = 'installers/spout-installer-m1-dev.dmg';

  downloadMacIntelUrl$: ReplaySubject<string>;
  downloadMacM1Url$: ReplaySubject<string>;
  downloadPcUrl$: ReplaySubject<string>;
  firebaseStudioVersion$: ReplaySubject<string>;

  @Input() showDownload = true;
  @Input() showTrial = true;

  constructor(private sptFirestore: SptFirestoreService, @Inject(ENVIRONMENT) public environment: IEnvironmentState) {
    this.ua = new UAParser(navigator.userAgent);

    this.downloadMacIntelUrl$ = new ReplaySubject<string>(1);
    this.downloadMacM1Url$ = new ReplaySubject<string>(1);
    this.downloadPcUrl$ = new ReplaySubject<string>(1);

    this.firebaseStudioVersion$ = new ReplaySubject<string>(1);
  }

  ngOnInit(): void {
    const _ref = this.sptFirestore.docRef(studioVersion());

    this.fbSub = onSnapshot(
      _ref,
      (doc: DocumentSnapshot) => {
        const v: StudioVersion = removeTimestampCTorFromDocumentSnapshot<StudioVersion>(doc);

        if (v && v.electron) {
          this.firebaseStudioVersion$.next(concatElectronBuildVersion(v.electron));
        }
      },
      (error: FirestoreError) => {
        console.error(error);
      }
    );

    if (this.showDownload) {
      this.getDownloadUrl(this.environment.production ? this.downloadPcUrl : this.downloadPcUrl_Dev)
        .pipe(
          takeUntil(this._onDestroy$),
          catchError((error: any) => {
            return of('');
          }),
          distinctUntilChanged()
        )
        .subscribe(pc => {
          this.downloadPcUrl$.next(pc);
        });

      this.getDownloadUrl(this.environment.production ? this.downloadMacIntelUrl : this.downloadMacIntelUrl_Dev)
        .pipe(
          takeUntil(this._onDestroy$),
          catchError((error: any) => {
            return of('');
          }),
          distinctUntilChanged()
        )
        .subscribe(macIntel => {
          this.downloadMacIntelUrl$.next(macIntel);
        });

      this.getDownloadUrl(this.environment.production ? this.downloadMacM1Url : this.downloadMacM1Url_Dev)
        .pipe(
          takeUntil(this._onDestroy$),
          catchError((error: any) => {
            return of('');
          }),
          distinctUntilChanged()
        )
        .subscribe(macM1 => {
          this.downloadMacM1Url$.next(macM1);
        });
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
    if (this.fbSub) {
      this.fbSub();
    }
  }

  getDownloadUrl(url: string): Observable<string> {
    const that = this;

    return new Observable((observer: Observer<any>) => {
      // Create a reference to the file we want to download
      const installerRef = this.sptFirestore.getDownloadURL(url);

      // Get the download URL
      installerRef
        .then(function (url: string) {
          // Insert url into an <img> tag to 'download'
          observer.next(url);
        })
        .catch(function (error: any) {
          console.log(error);

          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;

            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;

            case 'storage/canceled':
              // User canceled the upload
              break;

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
          }
        });
    });
  }
}
