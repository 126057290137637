import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

export class BasicHttpInterceptor implements HttpInterceptor {
  constructor() {
    console.log('FirestoreHttpInterceptor');
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(req);
    // console.log(req.url);
    return next.handle(req);
  }
}
