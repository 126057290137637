import {createSelector} from '@ngrx/store';
import {CombinedTrackEntityMix, MixEntity, SongEntity, TrackEntity} from '@spout/any-shared/models';
import {colorIconText} from '@spout/web-global/fns';
import {selectAllMixes} from './entities/mix-storage.selectors';
import {selectCurrentSongEntity} from './entities/song-storage.selectors';
import {selectAllTracks_passThrough} from './entities/track-storage.selectors';

export const selectAllMixesByCurrentSong = createSelector(
  selectCurrentSongEntity,
  selectAllMixes,
  (song: SongEntity | null, mixes: MixEntity[]): MixEntity[] => {
    if (song && mixes) {
      return mixes.filter((mix: MixEntity) => mix.songId === song.id);
    }

    return [];
  }
);

export const gettracksAndMixBySongId = createSelector(
  selectAllTracks_passThrough,
  (tracks: TrackEntity[], props: {mix: MixEntity}): CombinedTrackEntityMix[] => {
    return tracks
      .filter((track: TrackEntity) => track.songId === props.mix.songId)
      .map((track: TrackEntity) => {
        return {
          track,
          mix: props.mix,
          trackTextColor: colorIconText(track.color)
        };
      });
  }
);

// export const selectCurrentSongAndMixes = createSelector(
//   selectCurrentSongEntity,
//   selectAllMixes,
//   selectAllTracks,
//   (song: SongEntity, mixes: MixEntity[], tracks: TrackEntity[]): CombinedSongMixes => {
//
//     const songMixes: MixEntity[] =  mixes.filter((mix: MixEntity) => mix.songId === song.id );
//     const songTracks: TrackEntity[] = tracks.filter((track: TrackEntity) => track.songId === song.id );
//
//     const combinedTrackMixes: CombinedTrackEntityMix[] = songMixes.map((mix: MixEntity) => {
//       return {
//         mix,
//         tracks: songTracks,
//       }
//     })
//
//     return {
//       song,
//       tracksByMix: combinedTrackMixes,
//     }
//   }
// );

// export const selectAllMixIdsByCurrentSong = createSelector(
//   selectCurrentSongEntity,
//   selectAllMixes,
//   (song: SongEntity, mixes: MixEntity[]): string[] => {
//     return mixes.filter((mix: MixEntity) => mix.songId === song.id )
//       .map((mix: MixEntity) => mix.id );
//
//   }
// );
