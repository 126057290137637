import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {getTrackColorCSS} from '@spout/web-global/fns';
import {ReplaySubject, Subject} from 'rxjs';
import {WarnTrackRecorded} from '../track-item-dialog.model';

@Component({
  selector: 'spt-warn-track-recorded',
  templateUrl: './warn-track-recorded.component.html',
  styleUrls: ['./warn-track-recorded.component.scss']
})
export class WarnTrackRecordedComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  selectedColor$: ReplaySubject<string> = new ReplaySubject<any>(1);
  private _data: WarnTrackRecorded | undefined;

  @Input()
  set data(data: WarnTrackRecorded) {
    if (data && data.track) {
      this._data = data;
      this.selectedColor$.next(getTrackColorCSS(data.track));
    }
  }

  @Output()
  ok: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this._onDestroy$.next(true);
  }

  /**
   * To record without clearing track first.
   */
  okWithoutClearAudio() {
    this.ok.emit(true);
    this.cd.detectChanges();
  }

  // clearTrackAudio() {
  //   of(this._data.track.id)
  //     .pipe(
  //       switchMap((trackId: string) => {
  //         return this.store.pipe(
  //           select(getTrackModel, { trackId }),
  //           take(1),
  //           map((trackModel: TrackComponentModel) => {
  //             return {
  //               trackModel,
  //             };
  //           })
  //         );
  //       }),
  //       take(1)
  //     )
  //     .subscribe(({ trackModel }) => {
  //       this.dss
  //         .event(DYN_STORE.TRACK_CLEARED)
  //         .pipe(
  //           filter((trackId: string) => trackId === trackModel.trackEntity.id),
  //           delay(300),
  //           takeUntil(this._onDestroy$)
  //         )
  //         .subscribe(() => {
  //           this.ok.emit(true);
  //           this.cd.detectChanges();
  //         });
  //
  //       this.metrics.resetTrackMixMetrics(trackModel.trackEntity.id);
  //
  //       this.playerCache.clearTrack(trackModel.trackEntity.id);
  //
  //       this.dss.emit(DYN_STORE.CLEAR_TRACK, trackModel.trackEntity.id);
  //
  //       this.store.dispatch(
  //         clearTrackEffect({
  //           trackModel,
  //         })
  //       );
  //
  //       // this.cd.detectChanges();
  //       //
  //       // this.ok.emit(true);
  //     });
  // }
}
