export interface SptConnectorWorkletOptions extends AudioWorkletNodeOptions {
  id: string;
}

export class SptMergerWorklet extends AudioWorkletNode {
  id = '';

  constructor(context: BaseAudioContext, options: SptConnectorWorkletOptions) {
    super(context, 'merger-worklet-processor', options);

    this.id = options.id;
  }
}
