// - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA
// - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA
// - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA
// - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {AudioFileMetaDataEntity} from '@spout/any-shared/models';

export type AudioFileMetaDataState = EntityState<AudioFileMetaDataEntity>;

export function selectFileId(a: AudioFileMetaDataEntity): string {
  //In this case this would be optional since primary key is id
  return a.id;
}

export function sortByName(a: AudioFileMetaDataEntity, b: AudioFileMetaDataEntity): number {
  return a.name.localeCompare(b.name);
}

export const audioFileMetaDataAdapter: EntityAdapter<AudioFileMetaDataEntity> =
  createEntityAdapter<AudioFileMetaDataEntity>({
    // selectId: selectFileId,
    // sortComparer: sortByName,
  });

export const initialMetaDataState: AudioFileMetaDataState = audioFileMetaDataAdapter.getInitialState({
  // set initial required properties
  // loaded: false,
});
