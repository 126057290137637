import {DeviceStorageState, MergeEntityConfig} from '@spout/any-shared/models';
import {Observable, Observer} from 'rxjs';

export function mergeEntityWithDeviceStore(
  s: DeviceStorageState | any,
  a: MergeEntityConfig | any,
  key: string
): Observable<DeviceStorageState> {
  return new Observable((observer: Observer<any>) => {
    if (s && s[key] && s[key][a.id]) {
      s[key][a.id] = {
        ...s[key][a.id],
        ...a
      };
    } else {
      s[key][a.id] = a;
    }

    observer.next(s);
  });
}
