import {Injectable, NgZone} from '@angular/core';
import {select, Store} from '@ngrx/store';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  AppVersions,
  DeleteFilesFromSystem,
  DeviceStoragePayload,
  DeviceStorageState,
  ElectronDialogText,
  ElectronNativeAudio,
  FileBackblazeB2LoadPayload,
  GetDeviceStorage,
  MainMenuToRenderer,
  SaveExportedMixAsFileAccount,
  SptElectronChannelInvoke,
  SptElectronChannelToRenderer,
  SptSystemInformation,
  SptSystemInformationRequest,
  systemInformationRequest,
  TreeConfig,
  UploadFileProgress
} from '@spout/any-shared/models';
import {addAudioTrackEntityToSongAndMixAction, authLogout, closeAndNavigate} from '@spout/web-global/actions';
import {
  DYN_STORE,
  getAddMixRoute,
  getAddProjectRoute,
  getAddSongRoute,
  getConsoleRoute,
  StudioAppState
} from '@spout/web-global/models';
import {selectCurrentMixEntityId, selectCurrentProjectId, selectCurrentSongId} from '@spout/web-global/selectors';
import {IpcRenderer} from 'electron';
import {combineLatest, Observable, Observer} from 'rxjs';
import {take} from 'rxjs/operators';
import {DynamicStoreService} from '../../services/dynamic-store.service';
import IpcRendererEvent = Electron.IpcRendererEvent;

@Injectable({
  providedIn: 'root'
})
export class DeviceStorageService {
  // https://github.com/ccnokes/electron-tutorials/tree/master/ipc-demo
  // https://dev.to/michaeljota/integrating-an-angular-cli-application-with-electron---the-ipc-4m18
  private _ipc: IpcRenderer | undefined;

  constructor(private store: Store<StudioAppState>, private dss: DynamicStoreService, private zone: NgZone) {
    this.initIpc();
    this.addListeners();
  }

  /**
   * Initializes Device Storage if not exists
   * Create Directory to store audio files.
   * Creates App Cache
   */
  loadDeviceStorageOnInit(payload: DeviceStoragePayload): Observable<DeviceStorageState> {
    return this.invoke$(SptElectronChannelInvoke.GET_DEVICE_STORAGE, payload);
  }

  relaunchElectron(): Observable<null> {
    return this.invoke$(SptElectronChannelInvoke.RELAUNCH_ELECTRON);
  }

  /**
   *
   * @param systemParams = systemParamsToGet
   */
  getSystemInformation(): Observable<SptSystemInformation> {
    return this.invoke$(SptElectronChannelInvoke.GET_SYSTEM_INFORMATION, systemInformationRequest);
  }

  getElectronBuild(): Observable<AppVersions> {
    return this.invoke$(SptElectronChannelInvoke.GET_ELECTRON_BUILD);
  }

  saveDeviceStorage(payload: Partial<DeviceStoragePayload>): Observable<DeviceStorageState> {
    return this.invoke$(SptElectronChannelInvoke.SAVE_DEVICE_STORAGE, payload);
  }

  loadAudioFile(request: FileBackblazeB2LoadPayload): Observable<ElectronNativeAudio> {
    return this.invoke$(SptElectronChannelInvoke.GET_FILE, request);
  }

  saveNativeAudioFile(payload: ElectronNativeAudio): Observable<ElectronNativeAudio> {
    return this.invoke$(SptElectronChannelInvoke.SAVE_FILE, payload);
  }

  saveExportedFile(payload: SaveExportedMixAsFileAccount): Observable<SaveExportedMixAsFileAccount> {
    return this.invoke$(SptElectronChannelInvoke.SAVE_EXPORTED_FILE, payload);
  }

  deleteFilesFromSystem(payload: DeleteFilesFromSystem): Observable<DeleteFilesFromSystem> {
    return this.invoke$(SptElectronChannelInvoke.DELETE_FILES, payload);
  }

  clearMicrophoneCache() {
    /* stub */
  }

  // BEGIN WORKSPACE BEGIN WORKSPACE BEGIN WORKSPACE
  // BEGIN WORKSPACE BEGIN WORKSPACE BEGIN WORKSPACE
  // BEGIN WORKSPACE BEGIN WORKSPACE BEGIN WORKSPACE

  setProjectWorkspaceToDefaultCacheDirectory(config: Partial<TreeConfig>) {
    /* stub */
  }

  cleanWorkspace(payload: GetDeviceStorage): Observable<boolean> {
    return this.invoke$(SptElectronChannelInvoke.CLEAN_WORKSPACE, payload);
  }

  getNewWorkspaceCacheDirectory(): Observable<string> {
    return this.invoke$(SptElectronChannelInvoke.GET_NEW_WORKSPACE_CACHE_DIRECTORY);
  }

  getNewExportDirectory(payload: ElectronDialogText): Observable<string> {
    return this.invoke$(SptElectronChannelInvoke.GET_NEW_EXPORT_DIRECTORY, payload);
  }

  getDefaultCacheDirectoryPath(): Observable<string> {
    return this.invoke$(SptElectronChannelInvoke.GET_DEFAULT_CACHE_DIRECTORY_PATH);
  }

  createWorkspaceDirectory(newDirectoryPath: string): Observable<string> {
    return this.invoke$(SptElectronChannelInvoke.CREATE_DIRECTORY_PATH, newDirectoryPath);
  }

  /**
   * returns new workspace path
   * @param account
   */
  moveWorkspace(account: string): Observable<string> {
    return this.invoke$(SptElectronChannelInvoke.MOVE_WORKSPACE, account);
  }

  // END WORKSPACE END WORKSPACE END WORKSPACE
  // END WORKSPACE END WORKSPACE END WORKSPACE
  // END WORKSPACE END WORKSPACE END WORKSPACE

  private addListeners() {
    const that = this;
    this.on(
      SptElectronChannelToRenderer.UPLOAD_FILE_PROGRESS,
      (event: IpcRendererEvent, response: UploadFileProgress) => {
        this.dss.emit(DYN_STORE.UPLOAD_FILE_PROGRESS, response);
      }
    );

    this.on(MainMenuToRenderer.CONSOLE_PAGE, (event: IpcRendererEvent) => {
      that.zone.run(() => {
        that.store.dispatch(closeAndNavigate(getConsoleRoute()));
      });
    });

    this.on(MainMenuToRenderer.ADD_PROJECT, (event: IpcRendererEvent) => {
      that.zone.run(() => {
        that.store.dispatch(closeAndNavigate(getAddProjectRoute()));
      });
    });

    this.on(MainMenuToRenderer.ADD_SONG, (event: IpcRendererEvent) => {
      this.store.pipe(select(selectCurrentProjectId), take(1)).subscribe((projectId: string | null) => {
        if (projectId != null) {
          that.zone.run(() => {
            that.store.dispatch(closeAndNavigate(getAddSongRoute(projectId)));
          });
        }
      });
    });

    this.on(MainMenuToRenderer.ADD_MIX, (event: IpcRendererEvent) => {
      combineLatest([this.store.pipe(select(selectCurrentSongId)), this.store.pipe(select(selectCurrentMixEntityId))])
        .pipe(take(1))
        .subscribe(([songId, mixId]: [string | null, string | null]) => {
          if (songId && mixId) {
            that.zone.run(() => {
              that.store.dispatch(closeAndNavigate(getAddMixRoute(songId, mixId)));
            });
          }
        });
    });

    this.on(MainMenuToRenderer.ADD_TRACK, (event: IpcRendererEvent) => {
      that.zone.run(() => {
        that.store.dispatch(addAudioTrackEntityToSongAndMixAction());
      });
    });

    this.on(MainMenuToRenderer.LOGOUT, (event: IpcRendererEvent) => {
      that.zone.run(() => {
        that.store.dispatch(authLogout());
      });
    });

    this.on(MainMenuToRenderer.EDIT_ACTIVE_TRACK, (event: IpcRendererEvent) => {
      console.log('EDIT ACTIVE FROM MENU');
    });
  }

  private initIpc() {
    if ((<any>window)['ipcRenderer'] || window['require']) {
      if ((<any>window)['ipcRenderer']) {
        this._ipc = (<any>window)['ipcRenderer'];
      } else if (window['require']) {
        this._ipc = window['require']('electron').ipcRenderer;
      }
    } else {
      console.warn("Electron's IPC was not loaded");
    }
  }

  private on(channel: string, listener: (event: Electron.IpcRendererEvent, response: any) => void): void {
    if (!this._ipc) {
      return;
    }
    this._ipc.on(channel, listener);
  }

  private send(channel: string, ...args: any[]): void {
    if (!this._ipc) {
      return;
    }
    this._ipc.send(channel, ...args);
  }

  private invoke$<T>(
    channel: string,
    ...args: (
      | string
      | SptSystemInformationRequest
      | Partial<DeviceStoragePayload>
      | GetDeviceStorage
      | ElectronDialogText
      | undefined
    )[]
  ): Observable<T> {
    // console.log(channel);
    if (this._ipc) {
      return new Observable((observer: Observer<any>) => {
        if (this._ipc) {
          this._ipc.invoke(channel, ...args).then(
            (r: T) => {
              observer.next(r);
              observer.complete();
            },
            (error: any) => {
              observer.error(error);
            }
          );
        }
      });
    }

    return new Observable((observer: Observer<any>) => {
      observer.next(null);
      observer.complete();
    });
  }
}
