import {createFeatureSelector, createSelector} from '@ngrx/store';
import {MixEntity, SongEntity} from '@spout/any-shared/models';
import {
  CHAT_FEATURE_KEY,
  chatAdapter,
  ChatEntity,
  ChatState,
  ChatTypeConstant,
  StudioAppState
} from '@spout/web-global/models';
import {selectCurrentMixEntity} from '../entities/mix-storage.selectors';
import {selectCurrentSongEntity} from '../entities/song-storage.selectors';

const {selectIds, selectEntities, selectAll, selectTotal} = chatAdapter.getSelectors();

export const getSelectChatState = createFeatureSelector<ChatState>(CHAT_FEATURE_KEY);

// select the array of chat ids
export const selectChatIds = createSelector(
  getSelectChatState,
  selectIds // shorthand for usersState => selectUserIds(usersState)
);

// select the dictionary of chat entities
export const selectChatEntities = createSelector(getSelectChatState, selectEntities);

// select the array of chats
export const selectAllChats = createSelector(getSelectChatState, selectAll);

// select the total chat count
export const selectChatTotal = createSelector(getSelectChatState, selectTotal);

export const selectCurrentChatId = createSelector(getSelectChatState, (state: ChatState) => state.selectedId);

export const selectAllMessages = createSelector(selectAllChats, (chats: ChatEntity[]) =>
  chats.filter((chat: ChatEntity) => chat.type === ChatTypeConstant.MESSAGE)
);

export const getAllMessagesByConversationId = createSelector(
  selectAllMessages,
  (messages: ChatEntity[], props: {conversationId: string}) => {
    return messages.filter((chat: ChatEntity) => chat.conversationId === props.conversationId);
  }
);

export const getMessageCountByConversationId = createSelector(
  selectAllMessages,
  (messages: ChatEntity[], props: {conversationId: string}) => {
    return messages.filter((chat: ChatEntity) => chat.conversationId === props.conversationId).length;
  }
);

export const getAllChatsByConversationId = createSelector(
  selectAllChats,
  (messages: ChatEntity[], props: {conversationId: string}) => {
    return messages.filter((chat: ChatEntity) => chat.conversationId === props.conversationId);
  }
);

// export const selectedTrackId = createSelector(
//   getSelectChatState,
//   (state: ChatState) => state.selectedTrackId
// );

export const getConversationsByTrackId = createSelector(
  selectAllChats,
  (chats: ChatEntity[], props: {trackId: string}): ChatEntity[] => {
    return chats.filter(
      (chat: ChatEntity) => chat.trackId === props.trackId && chat.type === ChatTypeConstant.CONVERSATION
    );
  }
);

export const getAllChatsByTrackId = createSelector(selectAllChats, (chats: ChatEntity[], props: {trackId: string}) => {
  return chats.filter((chat: ChatEntity) => chat.trackId === props.trackId);
});

export const getConversationMessageById = createSelector(
  selectAllChats,
  (chats: ChatEntity[], props: {id: string}): string => {
    const found: ChatEntity | undefined = chats.find((chat: ChatEntity) => chat.id === props.id);
    if (found !== undefined && found !== null && found.message) {
      return found.message;
    }
    return '';
  }
);

export const selectShowedArchivedChats = createSelector(getSelectChatState, (state: ChatState) => {
  return state.showArchived;
});

export const selectAllChatsByCurrentSongAndMix = createSelector(
  selectCurrentSongEntity,
  selectCurrentMixEntity,
  selectAllChats,
  (song: SongEntity | null, mix: MixEntity | null, chats: ChatEntity[]) => {
    if (song && mix && chats && chats.length) {
      return chats
        .filter((chat: ChatEntity) => chat.songId === song.id && chat.mixId === mix.id)
        .sort((a: ChatEntity, b: ChatEntity) => a.seconds - b.seconds);
    }

    return [];
  }
);

export const selectAllChatsByCurrentSong = createSelector(
  selectCurrentSongEntity,
  selectAllChats,
  (song: SongEntity | null, chats: ChatEntity[]) => {
    if (song && chats && chats.length) {
      return chats
        .filter((chat: ChatEntity) => chat.songId === song.id)
        .sort((a: ChatEntity, b: ChatEntity) => a.seconds - b.seconds);
    }

    return [];
  }
);
