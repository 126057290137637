import {Action, createReducer, on} from '@ngrx/store';
import {
  closeAuxRoute,
  openAuxRoute,
  routerCurrentRoute,
  routerLoadError,
  setRouteInitialLoaded
} from '@spout/web-global/actions';
import {RouterError, routerInitialState, SpoutRouterState} from '@spout/web-global/models';

const reducer = createReducer(
  routerInitialState,
  on(setRouteInitialLoaded, (state: SpoutRouterState) => {
    return {
      ...state,
      isInitialLoad: false
    };
  }),
  on(routerCurrentRoute, (state: SpoutRouterState, action) => {
    return {
      ...state,
      url: action.payload.state.url,
      params: action.payload.state.params,
      queryParams: action.payload.state.queryParams,
      currentRouteConfig: action.payload.config
    };
  }),

  on(closeAuxRoute, (state: SpoutRouterState) => {
    return {
      ...state,
      auxRouteIsOpen: false
    };
  }),

  on(openAuxRoute, (state: SpoutRouterState) => {
    return {
      ...state,
      auxRouteIsOpen: true
    };
  }),

  on(routerLoadError, (state: SpoutRouterState, action: RouterError) => {
    // state = Object.assign({}, state, { error: action });

    return {
      ...state,
      error: action.error
    };
  })
);

export function routerReducer(state: SpoutRouterState | undefined, action: Action): SpoutRouterState {
  return reducer(state, action);
}
