import {FloatLabelType, MatFormFieldAppearance} from '@angular/material/form-field/form-field';

export interface IFormAppearance {
  FLOAT_LABEL: FloatLabelType;
  APPEARANCE: MatFormFieldAppearance;
}

export const SPT_FORM_OPTIONS: IFormAppearance = {
  FLOAT_LABEL: 'always',
  APPEARANCE: 'fill'
};
