export function radiansToValue(
  radians: number,
  valueEnd: number,
  valueStart: number,
  angleEnd: number,
  angleStart: number
) {
  valueEnd = valueEnd || 100;
  valueStart = valueStart || 0;
  angleEnd = angleEnd || 360;
  angleStart = angleStart || 0;
  return (((180 / Math.PI) * radians - angleStart) * (valueEnd - valueStart)) / (angleEnd - angleStart) + valueStart;
}
