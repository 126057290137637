<div class="page-layout--wide">
  <div class="page-layout-content">
    <h1>Terms and Conditions</h1>
    <p>
      Spout Software LLC (&quot;Spout&quot; or &quot;Spout Software&quot; or &quot;We&quot;) operates www.spoutstudio.io
      (&quot;spoutstudio.io,&quot; &quot;Spout Software Website,&quot; or &quot;Website&quot;), which is an
      internet-based service that captures and distributes live performances to mobile and home entertainment platforms.
      In doing so, Spout.com allows Qualifying Artists(defined below) to create unique profiles online in order to
      further market themselves and sales of their recorded live performances and back catalogs. Spout.com also allows
      other Visitors to browse the profiles of Qualifying Artists, interact with Qualifying Artists, and purchase music
      of Qualifying Artists.
    </p>
    <p>
      This Terms of Use Agreement, as may be modified from time to time (hereinafter referred to as
      &quot;Agreement&quot; or &quot;Terms of Use&quot;) constitutes legally binding terms and applies to your use of
      the services offered on Spout.com (whether as a Qualifying Artist or Visitor).
    </p>
    <h1>1. Your Acceptance</h1>
    <ol type="A">
      <li>
        By using and/or visiting this Website (collectively, including all content and functionality available through
        the SpoutStudios.com domain name, the &quot;Spout Software Website&quot;, or &quot;Website&quot;), you signify
        your agreement to (1) these Terms of Use, (2) Spout Software&#39;s privacy notice, found at
        <a href="https://spoutstudio.io/policies/terms-and-conditions"
          >https://spoutstudio.io/policies/terms-and-conditions</a
        >
        and incorporated here by reference, and also incorporated here by reference. If you do not agree to any of these
        Terms of Use, the Spout Software privacy notice, or the Community Guidelines, you must discontinue use of the
        Spout Software Website immediately.
      </li>
      <li>
        Although we may attempt to notify you when major changes are made to these Terms of Use, you should periodically
        review the most up-to-date version http://www.spoutstudio.io/ ). Spout Software may, in its sole discretion,
        modify or revise these Terms of Use and policies at any time, and you agree to be bound by such modifications or
        revisions. Nothing in this Agreement shall be deemed to confer any third-party rights or benefits.
      </li>
      <li>
        This Agreement and any posted revision to this Agreement, shall remain in full force and effect while you use
        the Spout Software Website or are a Qualifying Artist or Visitor.
      </li>
    </ol>
    <h1>2. Spout Software Website</h1>
    <ol type="A">
      <li>
        These Terms of Use apply to all users of the Spout Software Website, including Qualifying Artists and Visitors.
        The Spout Software Website includes all aspects of Spout Software, including but not limited to all
        productEntities, software and services offered via the website such as the Spout Software &quot;Embeddable
        Player,&quot; the Spout Software &quot;Uploader&quot; and other applications.
      </li>
      <li>
        The Spout Software Website may contain links to third party websites that are not owned or controlled by Spout
        Software. Spout Software has no control over, and assumes no responsibility for, the content, privacy policies,
        or practices of any third party websites. In addition, Spout Software will not and cannot censor or edit the
        content of any third-party site. By using the Website, you expressly relieve Spout Software from any and all
        liability arising from your use of any third-party website.
      </li>
      <li>
        Accordingly, by using and/or visiting this Website, you agree to read and understand the terms and conditions
        and privacy policy of each other website that you visit.
      </li>
      <li>Spout Software reserves the right to discontinue any aspect of the Spout Software Website at any time</li>
    </ol>
    <h1>3. Spout Software Accounts</h1>
    <ol type="A">
      <li>
        In order to access some features of the Website, you will have to create a Spout Software Account(&quot;Spout
        Account&quot; or &quot;Account&quot;). You may never use another&#39;s Account without permission. When creating
        your Account, you must provide accurate and complete information. You are solely responsible for the activity
        that occurs on your Account, and you must keep your Account password secure. You must notify Spout Software
        immediately of any breach of security or unauthorized use of your Account.
      </li>
      <li>
        You may terminate your Account at any time, for any reason, by following the instructions for termination.
      </li>
      <li>
        Spout Software may terminate your Account at any time, for any or no reason, with or without prior notice or
        explanation, and without liability. Furthermore, Spout Software reserves the right, in its sole discretion, to
        reject, refuse to post or remove any posting or content posted by you, or to deny, restrict, suspend, or
        terminate your access to all or any part of the Spout Software Website at any time, for any or no reason, with
        or without prior notice or explanation, and without liability. In addition, Spout Software reserves the right,
        in its sole discretion, to reassign or rename your profile URL. Spout Software reserves the right to remove your
        profile and/or deny, restrict, suspend, or terminate you access to all or any part of the Website if Spout
        Software determines, in its sole discretion, that you have violated this Agreement or pose a threat to Spout
        Software, its employees, business partners, users and/or the public. Even after your Account is terminated, this
        Agreement will remain in full force and effect. Spout Software reserves the right to decide whether Content or a
        User Submission is appropriate and complies with these Terms of Use for violations other than copyright
        infringement, such as, but not limited to, pornography, obscene or defamatory material, or excessive length.
        Spout Software may remove such User Submissions and/or terminate a User&#39;s access for uploading such material
        in violation of these Terms of Use at any time, without prior notice and at its sole discretion. Spout Software
        retains the absolute discretion to remove Content or a User Submission regardless of whether such Content or
        User Submission legally constitutes pornography, obscenity, or defamation.
      </li>
      <li>
        Although Spout Software will not be liable for your losses caused by any unauthorized use of your Account, you
        may be liable for the losses of Spout Software or others due to such unauthorized use.
      </li>
      <li>
        You acknowledge that Spout Software reserves the right to charge for any portion of the Website and to change
        its fees (if any) from time to time in its discretion. If Spout Software terminates your Account because you
        have breached this Agreement, you shall not be entitled to the refund of any unused portion of fees or payments
        (if any).
      </li>
    </ol>
    <h1>4. General Use of the Website&mdash;Permissions and Restrictions</h1>
    <p class="c2">
      Spout Software hereby grants you permission to access and use the Website as set forth in these Terms of Use,
      provided that:
    </p>
    <ol type="A">
      <li>
        You agree not to distribute in any medium any part of the without Spout Software&#39;s prior written
        authorization.
      </li>
      <li>
        You agree not to alter or modify any part of the Website, including but not limited to Spout Software&#39;s
        Embeddable Player or any of its related technologies.
      </li>
      <li>
        You agree not to access User Submissions (defined below) or Spout Software Content through any technology or
        means other than the video playback pages of the Website itself, the Spout Software Embeddable Player, or other
        explicitly authorized means Spout Software may designate.
      </li>
      <li>
        You agree not to use the Website, including the Spout Software Embeddable Player for any commercial use, without
        the prior written authorization of Spout Software. Prohibited commercial uses include any of the following
        actions taken without Spout Software&#39;s express approval:

        <ul>
          <li>
            sale of access to the Website or its related services (such as the Embeddable Player) on another website;
          </li>
          <li>
            use of the Website or its related services (such as the Embeddable Player), for the primary purpose of
            gaining advertising or subscription revenue;
          </li>
          <li>
            the sale of advertising, on the Spout Software website or any third-party website, targeted to the content
            of specific Event Posts or Spout Software content; and/or
          </li>
          <li>
            any use of the Website or its related services (such as the Embeddable player) that Spout Software finds, in
            its sole discretion, to use Spout Software&#39;s resources or Event Posts with the effect of competing with
            or displacing the market for Spout Software, Spout Software content, or its Event Posts.
          </li>
        </ul>
      </li>
      <li>
        Prohibited commercial uses do not include:

        <ul>
          <li>
            uploading an User Submission, original photograph, or original video to Spout Software to promote your
            business or artistic enterprise;
          </li>

          <li>
            using the Embeddable Player to show Spout Software videos on an ad-enabled blog or website, provided the
            primary purpose of using the Embeddable Player is not to gain advertising revenue or compete with Spout
            Software;
          </li>
          <li>any use that Spout Software expressly authorizes in writing.</li>
        </ul>
      </li>
      <li>
        If you use the Spout Software Embeddable Player on your website, you must include a prominent link back to the
        Spout Software website on the pages containing the Embeddable Player and you may not modify, build upon, or
        block any portion of the Embeddable Player in any way.
      </li>
      <li>
        If you use the Spout Software Uploader, you agree that it may automatically download and install updates from
        time to time from Spout Software. These updates are designed to improve, enhance and further develop the
        Uploader and may take the form of bug fixes, enhanced functions, new software modules and completely new
        versions. You agree to receive such updates (and permit Spout Software to deliver these to you) as part of your
        use of the Uploader.
      </li>
      <li>
        You agree not to use or launch any automated system, including without limitation, &quot;robots,&quot;
        &quot;spiders,&quot; or &quot;offline readers,&quot; that accesses the Website in a manner that sends more
        request messages to the Spout Software servers in a given period of time than a human can reasonably produce in
        the same period by using a conventional on-line web browser. Notwithstanding the foregoing, Spout Software
        grants the operators of public search engines permission to use spiders to copy materials from the site for the
        sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the
        materials, but not caches or archives of such materials. Spout Software reserves the right to revoke these
        exceptions either generally or in specific cases.
      </li>
      <li>
        You agree not to collect or harvest any personally identifiable information, including Account names, from the
        Website, nor to use the communication systems provided by the Website (e.g. comments, email) for any commercial
        solicitation purposes. You agree not to solicit, for commercial purposes, any users of the Website. Commercial
        advertisements, affiliate links, and other forms of unauthorized data collection or solicitation may be removed
        from Account profiles without notice or explanation and may result in termination of Account privileges. Spout
        Software reserves the right to take appropriate legal action, including, but not limited to, referral to law
        enforcement, for any illegal or unauthorized use of the Website.
      </li>
      <li>
        In your use of the website, you will otherwise comply with the terms and conditions of these Terms of Use and
        all applicable local, national, and international laws and regulations.
      </li>
    </ol>
    <h1>5. Your Use of Content on the Site</h1>
    <p>
      In addition to the general restrictions above, the following restrictions and conditions apply specifically to
      your use of content on the Spout Software Website.
    </p>
    <ol type="A">
      <li>
        The content on the Spout Software Website, except all User Submissions (as defined below), including without
        limitation, the text, software, scripts, graphics, photos, sounds, music, videos, interactive features and the
        like (&quot;Content&quot;) and the trademarks, service marks and logos contained therein (&quot;Marks&quot;),
        are owned by or licensed to Spout Software, subject to copyright and other intellectual property rights under
        the law. Content on the Website is provided to you AS IS for your information and personal use only and may not
        be downloaded, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise
        exploited for any other purposes whatsoever without the prior written consent of the respective owners. Spout
        Software reserves all rights not expressly granted in and to the Website and the Content.
      </li>
      <li>
        You may access Spout Software Content for your information and personal use solely as intended through the
        provided functionality of the Spout Software Website. You shall not copy or download any User Submission unless
        you see a &ldquo;download&rdquo; or similar link displayed by Spout Software on the Spout Software Website for
        that User Submission.
      </li>
      <li>
        User Comments are made available to you for your information and personal use solely as intended through the
        normal functionality of the Spout Software Website. User Comments are made available &quot;as is&quot;, and may
        not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, downloaded, or
        otherwise exploited in any manner not intended by the normal functionality of the Spout Software Website or
        otherwise as prohibited under this Agreement.
      </li>
      <li>
        You may access Spout Software Content, User Submissions and other content only as permitted under this
        Agreement. Spout Software reserves all rights not expressly granted in and to the Spout Software Content and the
        Spout Software Website.
      </li>
      <li>
        You agree to not engage in the use, copying, or distribution of any of the Content other than expressly
        permitted herein, including any use, copying, or distribution of User Submissions of third parties obtained
        through the Website for any commercial purposes.
      </li>
      <li>
        You agree not to circumvent, disable or otherwise interfere with security-related features of the Spout Software
        Website or features that prevent or restrict use or copying of any Content or enforce limitations on use of the
        Spout Software Website or the Content therein.
      </li>
      <li>
        You understand that when using the Spout Software Website, you will be exposed to User Submissions from a
        variety of sources, and that Spout Software is not responsible for the accuracy, usefulness, safety, or
        intellectual property rights of or relating to such User Submissions. You further understand and acknowledge
        that you may be exposed to User Submissions that are inaccurate, offensive, indecent, or objectionable, and you
        agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against
        Spout Software with respect thereto, and agree to indemnify and hold Spout Software, its Owners/Operators,
        affiliates, and/or licensors, harmless to the fullest extent allowed by law regarding all matters related to
        your use of the site.
      </li>
    </ol>
    <h1>6. Your User Submissions and Conduct</h1>
    <ol type="A">
      <li>
        As a Spout Software Account holder you may submit music, photographs, video content (&quot;User Videos&quot;)
        and textual content (&quot;User Comments&quot;). Qualifying Artists may also provide for the streaming of live
        concerts and purchase of their music through the Website. All the foregoing are collectively referred to as
        &quot;User Submissions.&quot; You understand that whether or not such User Submissions are published, Spout
        Software does not guarantee any confidentiality with respect to any User Submissions.
      </li>
      <li>
        You shall be solely responsible for your own User Submissions and the consequences of posting or publishing
        them. In connection with User Submissions, you affirm, represent, and/or warrant that: you own or have the
        necessary licenses, rights, consents, and permissions to use and authorize Spout Software to use all patent,
        trademark, trade secret, copyright or other proprietary rights in and to any and all User Submissions to enable
        i nclusion and use of the User Submissions in the manner contemplated by the Website and these Terms of Use.
      </li>
      <li>
        Spout Software does not claim any ownership rights in your User Submissions. Rather, you retain all of your
        ownership rights in your User Submissions. However, by submitting User Submissions to Spout Software, you hereby
        grant Spout Software a worldwide, non-exclusive, fully-paid, royalty-free, sublicenseable and transferable
        license to use, reproduce, distribute, prepare derivative works of, display, and perform the User Submissions in
        connection with the Spout Software Website and Spout Software&#39;s (and its successors&#39; and
        affiliates&#39;) business, including without limitation for promoting and redistributing part or all of the
        Spout Software Website (and derivative works thereof) in any media formats and through any media channels. You
        also hereby grant each user of the Spout Software Website a non-exclusive license to access your User
        Submissions through the Website, and to use, reproduce, distribute, display and perform such User Submissions as
        permitted through the functionality of the Website and under these Terms of Use.
      </li>
      <li>
        In connection with User Submissions, you further agree that you will not submit material that is copyrighted,
        protected by trade secret or otherwise subject to third party proprietary rights, including privacy and
        publicity rights, unless you are the owner of such rights or have permission from their rightful owner to post
        the material and to grant Spout Software all of the license rights granted herein.
      </li>
      <li>
        You further agree that you will not, in connection with User Submissions, submit material that is contrary to
        the Spout Software Community Guidelines, found at http://www.software.com, which may be updated from time to
        time, or contrary to applicable local, national, and international laws and regulations.
      </li>
      <li>
        Spout Software does not endorse any User Submission or any opinion, recommendation, or advice expressed therein,
        and Spout Software expressly disclaims any and all liability in connection with User Submissions. Spout Software
        does not permit copyright infringing activities and infringement of intellectual property rights on its Website,
        and Spout Software will remove all Content and User Submissions if properly notified that such Content or User
        Submission infringes on another&#39;s intellectual property rights. Spout Software reserves the right to remove
        Content and User Submissions without prior notice.
      </li>
    </ol>
    <h1>7. Music Sale / Distribution / live concert events</h1>
    <p>
      Spout Live, a component of the Spout Website, captures and distributes live performances of Qualifying Artists via
      state of the art technology.
    </p>
    <p>
      A &quot;Qualifying Artist&quot; is a solo musician or group engaged in (or previously engaged in) the bona fide
      pursuit of success in the music and/or recording industry. Spout Software retains the sole authority to determine
      whether a particular artist or band qualifies and/or continues to qualify as a Qualifying Artist.
    </p>
    <p>
      If, in the sole discretion of Spout Software, you are determined to be a Qualifying Artist, you will have the
      option to record and sell your live performances and any portion of your back catalog through the Spout Software
      Website to all users (other Qualifying Artists and Visitors).
    </p>
    <p>
      As a Qualifying Artist, you will have the ability to post upcoming concert events (which may be recorded and sold
      by You via the Spout Website). Before any event is posted to Your calendar, both You (the Artist) and the venue
      must provide confirmation to Spout Software as to the date and time of the event.
    </p>
    <p>
      <strong>
        Should you choose to record and sell your live performances and/or back catalog through the Spout Software
        Website, the following terms shall apply:</strong
      >
    </p>

    <ol type="A">
      <li>
        Although Spout Software will make all reasonable attempts within its control to ensure the quality and
        availability of all content, you understand and acknowledge that the ultimate quality of the sound recording and
        live streaming is dependent upon technologies and other factors beyond Spout Software&#39;s control such as
        internet connectivity, user bandwidth, user sound card capabilities, quality of cables, venue-owned hardware
        (such as the mixing board), etc. Accordingly, Spout Software is NOT responsible for the recording and streaming
        quality. Spout Software expressly disclaims any and all warranties (express and implied) and/or guarantees
        related to recording and/or streaming quality.
      </li>
      <li>
        Without limiting the applicability of any other provision of this Agreement (including those provisions
        applicable to User Submissions in general as well as the indemnification obligations stated below), you agree,
        affirm, represent, and/or warrant that for Music selected by you for recording and/or distribution via the Spout
        Software Website whether a live concert of a portion of Your back catalog (&quot;Your Music&quot;), the
        following terms and conditions shall apply:

        <ol type="i">
          <li>
            You shall record only music and/or other material for which you own or have the necessary rights (including
            copyrights, performance rights, etc), licenses, consents, and permissions to use in the manner contemplated
            by the Website and these Terms of Use;
          </li>
          <li>
            You shall comply with and assume full responsibility for compliance with all applicable rules and
            regulations in the jurisdiction and venue of Your performance (including, but not limited to, any and all
            union obligations);
          </li>
          <li>
            You shall comply with and assume full responsibility for compliance with all rules and regulations related
            to the use of Explicit lyrics.
          </li>
          <li>
            To the extent the live streaming, recording, and/or distribution of Your Music via the Spout Website
            (whether a live concert or a portion of Your back catalog) is considered a &quot;performance&quot; under
            applicable law, you hereby waive any / all performance royalties in connection with the live streaming via
            the Spout Website. Similarly, to the extent performance royalties may be due to any third parties in
            connection with the live streaming, recording, and/or distribution of Your Music via the Spout Website
            (whether a live concert or a portion of Your back catalog), you shall assume full responsibility for the
            payment of any such royalties.
          </li>
        </ol>
      </li>
      <li>
        By posting a live concert performance on the Spout Website, you hereby agree to the following additional terms
        and conditions:
        <ol type="i">
          <li>
            Spout Software will not own either the Master Recording of the live concert performance (the &quot;Live
            Master&quot;) or the underlying musical compositions embodied in said Masters.
          </li>
          <li>
            Except as may be expressly set out herein, Spout Software shall have the exclusive, worldwide right for two
            years from the date Your live concert performance is recorded via the Spout Website to:

            <ol type="a">
              <li>
                Manufacture, duplicate, distribute, promote, advertise, sell, lease, license or otherwise exploit
                commercially, promotionally or otherwise, the live concert performance (including the Live Masters
                contained thereon), either individually or as part of the live concert performance) in all media now
                known or to be devised;
              </li>
              <li>
                Record, perform and reproduce the Live Masters in videos, and to distribute and exploit such videos and
                to authorize others to do so, for the purpose of promoting sales of the recording and/or use of the
                Spout Website.
              </li>
              <li>
                Use Artist&#39;s name, approved photograph, likeness, trademark, service mark, and/or biography in
                connection with any right granted to Spout Software herein;
              </li>
              <li>
                Manufacture, duplicate, distribute, promote, advertise, sell, lease, license or otherwise exploit
                commercially, promotionally or otherwise, a compilation product (audio only or audio and video)
                including one or more of the Live Masters;
              </li>
              <li>
                License any or all of Spout Software&#39;s rights under this agreement to third parties, as Spout
                Software deems advisable in its reasonable judgment in the furtherance of any right or obligation of
                Spout Software.
              </li>
            </ol>
          </li>
          <li>
            You hereby grant Spout Software, its distributors, and its licensees, an irrevocable license under copyright
            to reproduce the live concert performance and all Masters contained thereon, and to distribute them
            throughout the world.
          </li>
          <li>
            Notwithstanding anything herein to the contrary, you hereby waive payment of mechanical royalties related to
            any musical composition recorded and or posted to the Spout Website which is, in whole or in part, written,
            owned, or controlled by You.
          </li>
          <li>
            With respect to any musical composition which is not, in whole or in part, written, owned, or controlled by
            You, You shall cause the copyright proprietor(s) of such composition to issue to Spout Studio and/or its
            designee(s), mechanical licenses for the United States and Canada at Your sole expense.
          </li>
          <li>
            Any assignment by You regarding the ownership of copyrights in, or the rights to license or administer the
            use of any of the songs recorded and/or distributed via the Spout Website shall be subject to the terms and
            provisions hereof.
          </li>
          <li>
            The sales price on any live concert recording shall be set by Spout Software in its sole discretion. Net
            revenue from sales of any live concert recording shall be split three ways, 1/3 to Spout Software, 1/3 to
            the Qualifying Artist, and 1/3 to the venue at which the live concert was performed.
          </li>
          <li>
            Payments from net revenues shall be in the form of electronic payment only via paypal or direct deposit.
            Payments will be made on a monthly basis (on the last business day of each month) but only if the Qualifying
            Artist account has accrued $20. Accrual may not reflect the most current purchases as credit processing may
            not have fully completed and posted to the account.
          </li>
          <li>
            Spout Software does not keep credit card or other bank account information on file. Such records are kept
            with the credit processing agent which is in no way connect to Spout Software other than as a vendor.
          </li>
        </ol>
      </li>
    </ol>
    <h1>8. Digital Millennium Copyright Act</h1>
    <ol type="A">
      <li>
        If you are a copyright owner or an agent thereof and believe that any User Submission or other content infringes
        upon your copyrights, you may submit a notification pursuant to the Digital Millennium Copyright Act
        (&quot;DMCA&quot;) by providing us (through our Copyright Agent) with the following information in writing (see
        17 U.S.C 512(c)(3) for further detail):
        <ul>
          <li>
            A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
            right that is allegedly infringed;
          </li>
          <li>
            Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at
            a single online site are covered by a single notification, a representative list of such works at that site;
          </li>
          <li>
            Identification of the material that is claimed to be infringing or to be the subject of infringing activity
            and that is to be removed or access to which is to be disabled and information reasonably sufficient to
            permit the service provider to locate the material;
          </li>
          <li>
            Information reasonably sufficient to permit the service provider to contact you, such as an address,
            telephone number, and, if available, an electronic mail;
          </li>
          <li>
            A statement that you have a good faith belief that use of the material in the manner complained of is not
            authorized by the copyright owner, its agent, or the law; and
          </li>
          <li>
            A statement that the information in the notification is accurate, and under penalty of perjury, that you are
            authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
          </li>
        </ul>
        Spout Software&#39;s designated Copyright Agent to receive notifications of claimed infringement is: Spout
        Software Inc., email: <a href="mailto:info@spoutsoftware.com">info@spoutsoftware.com</a> . For clarity, only
        DMCA notices should go to the Copyright Agent; any other feedback, comments, requests for technical support, and
        other communications should be directed to Spout Software customer service through http://www.spoutsoftware.com.
        You acknowledge that if you fail to comply with all of the requirements of this Section 5(D), your DMCA notice
        may not be valid.
      </li>
      <li>
        Counter-Notice. If you believe that your User Submission that was removed (or to which access was disabled) is
        not infringing, or that you have the authorization from the copyright owner, the copyright owner&#39;s agent, or
        pursuant to the law, to post and use the content in your User Submission, you may send a counter-notice
        containing the following information to the Copyright Agent:
        <ul>
          <li>Your physical or electronic signature;</li>
          <li>
            Identification of the content that has been removed or to which access has been disabled and the location at
            which the content appeared before it was removed or disabled;
          </li>
          <li>
            A statement that you have a good faith belief that the content was removed or disabled as a result of
            mistake or a misidentification of the content; and
          </li>
          <li>
            Your name, address, telephone number, and e-mail address, a statement that you consent to the jurisdiction
            of the federal court in San Francisco, California, and a statement that you will accept service of process
            from the person who provided notification of the alleged infringement.
          </li>
        </ul>
        If a counter-notice is received by the Copyright Agent, Spout Software may send a copy of the counter-notice to
        the original complaining party informing that person that it may replace the removed content or cease disabling
        it in 10 business days. Unless the copyright owner files an action seeking a court order against the content
        provider, member or user, the removed content may be replaced, or access to it restored, in 10 to 14 business
        days or more after receipt of the counter-notice, at Spout Software&#39;s sole discretion.
      </li>
    </ol>
    <h1>9. Warranty Disclaimer</h1>
    <p>
      YOU AGREE THAT YOUR USE OF THE SPOUT SOFTWARE WEBSITE SHALL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED
      BY LAW, SPOUT SOFTWARE, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR
      IMPLIED, IN CONNECTION WITH THE WEBSITE AND YOUR USE THEREOF. SPOUT SOFTWARE MAKES NO WARRANTIES OR
      REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THIS SITE&#39;S CONTENT OR THE CONTENT OF ANY SITES LINKED
      TO THIS SITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT,
      (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR
      WEBSITE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
      AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR
      WEBSITE, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR WEBSITE BY
      ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
      AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SPOUT
      SOFTWARE WEBSITE. SPOUT SOFTWARE DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
      SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SPOUT SOFTWARE WEBSITE OR ANY HYPERLINKED WEBSITE OR
      FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND SPOUT SOFTWARE WILL NOT BE A PARTY TO OR IN ANY WAY BE
      RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH
      THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT
      AND EXERCISE CAUTION WHERE APPROPRIATE.
    </p>
    <h1>10. Limitation of Liability</h1>
    <p>
      IN NO EVENT SHALL SPOUT SOFTWARE, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT,
      INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS,
      MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
      FROM YOUR ACCESS TO AND USE OF OUR WEBSITE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
      ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION
      OF TRANSMISSION TO OR FROM OUR WEBSITE, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE
      TRANSMITTED TO OR THROUGH OUR WEBSITE BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR
      ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR
      OTHERWISE MADE AVAILABLE VIA THE SPOUT SOFTWARE WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER
      LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING
      LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
    </p>
    <p>
      YOU SPECIFICALLY ACKNOWLEDGE THAT SPOUT SOFTWARE SHALL NOT BE LIABLE FOR USER SUBMISSIONS OR THE DEFAMATORY,
      OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS
      ENTIRELY WITH YOU.
    </p>
    <p>
      The Website is controlled and offered by Spout Software from its facilities in the United States of America. Spout
      Software makes no representations that the Spout Software Website is appropriate or available for use in other
      locations. Those who access or use the Spout Software Website from other jurisdictions do so at their own volition
      and are responsible for compliance with local law.
    </p>
    <h1>11. Indemnity</h1>
    <p>
      You agree to defend, indemnify and hold harmless Spout Software, its parent corporation, officers, directors,
      employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or
      debt, and expenses (including but not limited to attorney&#39;s fees) arising from: (i) your use of and access to
      the Spout Software Website; (ii) your violation of any term of these Terms of Use; (iii) your violation of any
      third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that
      one of your User Submissions caused damage to a third party. This defense and indemnification obligation will
      survive these Terms of Use and your use of the Spout Software Website.
    </p>
    <h1>12. Ability to Accept Terms of Use</h1>
    <p>
      You affirm that you are either more than 18 years of age, or an emancipated minor, or possess legal parental or
      guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations,
      representations, and warranties set forth in these Terms of Use, and to abide by and comply with these Terms of
      Use. In any case, you affirm that you are over the age of 13, as the Spout Software Website is not intended for
      children under 13.
    </p>
    <h1>13. Assignment</h1>
    <p>
      These Terms of Use, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but
      may be assigned by Spout Software without restriction.
    </p>
    <h1>14. General</h1>
    <p>
      You agree that: (i) the Spout Software Website shall be deemed solely based in Texas; and (ii) the Spout Software
      Website shall be deemed a passive website that does not give rise to personal jurisdiction over Spout Software,
      either specific or general, in jurisdictions other than Texas. These Terms of Use shall be governed by the
      internal substantive laws of the State of Texas, without respect to its conflict of laws principles. Any claim or
      dispute between you and Spout Software that arises in whole or in part from your use of the Spout Software Website
      shall be decided exclusively by a court of competent jurisdiction located in Austin, Travis County, Texas. These
      Terms of Use, together with the Privacy Notice at http://www.Spoutsoftware.comand any other legal notices
      published by Spout Software on the Website, shall constitute the entire agreement between you and Spout Software
      concerning the Spout Software Website. If any provision of these Terms of Use is deemed invalid by a court of
      competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions
      of these Terms of Use, which shall remain in full force and effect. No waiver of any term of this these Terms of
      Use shall be deemed a further or continuing waiver of such term or any other term, and Spout Software&#39;s
      failure to assert any right or provision under these Terms of Use shall not constitute a waiver of such right or
      provision. Spout Software reserves the right to amend these Terms of Use at any time and without notice, and it is
      your responsibility to review these Terms of Use for any changes. Your use of the Spout Software Website following
      any amendment of these Terms of Use will signify your assent to and acceptance of its revised terms. YOU AND SPOUT
      SOFTWARE AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SPOUT SOFTWARE WEBSITE MUST COMMENCE
      WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
    </p>
  </div>
</div>
