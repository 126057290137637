import {Injectable, NgZone} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {TreeConfig} from '@spout/any-shared/models';
import {
  accountSaveFirebase,
  instrumentMusicianAggregate,
  instrumentMusicianAggregateEffect,
  upsertWebsocketRegistry,
  websocketIsDisconnectedAction
} from '@spout/web-global/actions';
import {
  difference,
  dispatchNgrxFirestoreAggregate,
  firestoreGetMusicianInstrumentPath,
  firestoreGetMusicianInstrumentsPath
} from '@spout/web-global/fns';
import {
  AuthAccountStates,
  AuthAccountStatesConnect,
  instrumentMusicianFeatureKey,
  InstrumentMusicianState
} from '@spout/web-global/models';
import {
  GetAuthAccountConnect,
  selectAllInstrumentsMusician,
  selectAuthAccountConnect$
} from '@spout/web-global/selectors';
import {DocumentReference, onSnapshot, QuerySnapshot} from 'firebase/firestore';
import {from, Observable} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';
import {SptFirestoreService} from '../firestore';

@Injectable()
export class InstrumentMusicianEffects {
  saveInstrumentToFirestore = createEffect(
    () =>
      this.actions$.pipe(
        ofType(instrumentMusicianAggregateEffect),
        map(action => {
          this.saveToFirebase(action.instruments).subscribe(
            () => {
              this.zone.run(() => {
                this.store.dispatch(
                  accountSaveFirebase({
                    payload: {
                      hasInstruments: true
                    }
                  })
                );
              });
            },
            () => {
              /* noop */
            }
          );
        })
      ),
    {dispatch: false}
  );

  private _databaseSub: (() => void) | undefined;

  constructor(
    private actions$: Actions,
    private store: Store<AuthAccountStatesConnect & InstrumentMusicianState>,
    private sptFirestore: SptFirestoreService,
    private zone: NgZone
  ) {
    const that = this;
    that.zone.run(() => {
      this.store.dispatch(
        upsertWebsocketRegistry({
          id: instrumentMusicianFeatureKey
        })
      );
    });

    this.store.pipe(selectAuthAccountConnect$).subscribe((s: GetAuthAccountConnect) => {
      if (s.doConnect) {
        this.onConnect.call(this, s.user);
      } else {
        this.onDisconnect.call(this, s.user);
      }
    });
  }

  onConnect(user: AuthAccountStates): void {
    const that = this;
    if (!this._databaseSub) {
      that.zone.run(() => {
        this.store.dispatch(
          websocketIsDisconnectedAction({
            id: instrumentMusicianFeatureKey
          })
        );
      });

      this._databaseSub = onSnapshot(
        this.sptFirestore.collectionRef(firestoreGetMusicianInstrumentsPath(user)),
        (snapshot: QuerySnapshot) => {
          dispatchNgrxFirestoreAggregate<AuthAccountStatesConnect>(
            this.store,
            snapshot,
            instrumentMusicianAggregate,
            this.zone
          );
        },
        (err: Error) => {
          console.error(err);
        },
        () => {
          /* noop */
        }
      );
    }
  }

  onDisconnect(user: AuthAccountStates): void {
    const that = this;
    if (this._databaseSub) {
      this._databaseSub();

      that.zone.run(() => {
        this.store.dispatch(
          websocketIsDisconnectedAction({
            id: instrumentMusicianFeatureKey
          })
        );
      });
    }
  }

  saveToFirebase(toSave: TreeConfig[]): Observable<any> {
    return this.store.pipe(
      select(selectAllInstrumentsMusician),
      take(1),
      mergeMap((selected: TreeConfig[]) =>
        this.store.pipe(
          selectAuthAccountConnect$,
          map((s: GetAuthAccountConnect) => ({
            user: s.user
          })),
          mergeMap(({user}) => {
            const batch = this.sptFirestore.writeBatch();

            // If user has no selected genres
            if (!selected.length) {
              toSave.map((genre: TreeConfig) => {
                const doc: DocumentReference = this.sptFirestore.docRef(
                  firestoreGetMusicianInstrumentPath(user, genre.id)
                );

                batch.set(doc, genre);
              });
            } else {
              // TreeConfig[]
              // Add Genres
              difference(toSave, selected, 'id').map((genre: TreeConfig) => {
                const doc: DocumentReference = this.sptFirestore.docRef(
                  firestoreGetMusicianInstrumentPath(user, genre.id)
                );
                batch.set(doc, genre);
              });

              // TreeConfig[]
              // Delete Genres that are not in selectedGenres
              difference(selected, toSave, 'id').map((genre: TreeConfig) => {
                const doc: DocumentReference = this.sptFirestore.docRef(
                  firestoreGetMusicianInstrumentPath(user, genre.id)
                );
                batch.delete(doc);
              });
            }

            return from(batch.commit());
          })
        )
      )
    );
  }
}
