import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {LatencyEntity} from '@spout/web-global/models';

/**
 * TODO Latency metrics
 * This Entity is stubbed for when we aggregate latency metrics
 * in the future
 */
export const loadLatencies = createAction('[Latency/API] Load Latencies', props<{latencies: LatencyEntity[]}>());

export const addLatency = createAction('[Latency/API] Add Latency', props<{latency: LatencyEntity}>());

export const upsertLatency = createAction('[Latency/API] Upsert Latency', props<{latency: LatencyEntity}>());

export const addLatencies = createAction('[Latency/API] Add Latencies', props<{latencies: LatencyEntity[]}>());

export const upsertLatencies = createAction('[Latency/API] Upsert Latencies', props<{latencies: LatencyEntity[]}>());

export const updateLatency = createAction('[Latency/API] Update Latency', props<{latency: Update<LatencyEntity>}>());

export const updateLatencies = createAction(
  '[Latency/API] Update Latencies',
  props<{latencies: Update<LatencyEntity>[]}>()
);

export const deleteLatency = createAction('[Latency/API] Delete Latency', props<{id: string}>());

export const deleteLatencies = createAction('[Latency/API] Delete Latencies', props<{ids: string[]}>());

export const clearLatencies = createAction('[Latency/API] Clear Latencies');
