import {UrlSegment, UrlTree} from '@angular/router';
import {clone, getIn} from '@uiux/fn';
import {appRoutingConfig, IxRouteConfig} from './router.model';

export function getRouteConfigLinkByName(name: string): string {
  const routeConig: IxRouteConfig = getIn(appRoutingConfig, name, null);
  return routeConig ? `/${routeConig.routerLink}` : '';
}

export function getRouteConfigByName(name: string): IxRouteConfig {
  return clone(getIn(appRoutingConfig, name, null));
}

export function getSegmentRoute(tree: UrlTree, path: string): string | null {
  const formsSegments = getIn(tree, path, null);

  if (formsSegments && formsSegments.length) {
    return formsSegments.reduce((acc: string, i: UrlSegment) => {
      acc += `/${i.path}`;
      return acc;
    }, '');
  }

  return null;
}
