import {Musician} from '../musician';

export interface UserPermissions {
  // Permissions
  createdBy: Musician;
  createdByUID: string;
  members: {[uid: string]: Musician};
  memberUIDs?: string[]; // emails
  owners: {[uid: string]: Musician};
  ownerUIDs: string[];
}
