<spt-slider
  [label]="'Threshold'"
  [showLabel]="true"
  [labelWidth]="labelWidth"
  [valueWidth]="valueWidth"
  [showUnits]="true"
  [min]="-100"
  [max]="0"
  [step]="1"
  [thumbLabel]="true"
  [units]="'dB'"
  [value]="threshold$ | ngrxPush"
  (change)="onThresholdChange($event)"
></spt-slider>

<spt-slider
  [label]="'Knee'"
  [showLabel]="true"
  [labelWidth]="labelWidth"
  [valueWidth]="valueWidth"
  [showUnits]="true"
  [min]="0"
  [max]="40"
  [value]="knee$ | ngrxPush"
  [step]="1"
  [thumbLabel]="true"
  [units]="'dB'"
  (change)="onKneeChange($event)"
></spt-slider>

<spt-slider
  [label]="'Ratio'"
  [showLabel]="true"
  [labelWidth]="labelWidth"
  [valueWidth]="valueWidth"
  [showUnits]="true"
  [min]="1"
  [max]="20"
  [value]="ratio$ | ngrxPush"
  [step]="1"
  [thumbLabel]="true"
  [units]="'dB'"
  (change)="onRatioChange($event)"
></spt-slider>

<spt-slider
  [label]="'Attack'"
  [showLabel]="true"
  [labelWidth]="labelWidth"
  [valueWidth]="valueWidth"
  [showUnits]="true"
  [units]="'s'"
  [min]="0"
  [max]="1"
  [step]="0.001"
  [value]="attack$ | ngrxPush"
  [thumbLabel]="true"
  (change)="onAttachChange($event)"
></spt-slider>

<spt-slider
  [label]="'Release'"
  [showLabel]="true"
  [labelWidth]="labelWidth"
  [valueWidth]="valueWidth"
  [showUnits]="true"
  [units]="'s'"
  [min]="0"
  [max]="1"
  [step]="0.01"
  [value]="release$ | ngrxPush"
  [thumbLabel]="true"
  (change)="onReleaseChange($event)"
></spt-slider>
