import {Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'spt-help-tooltip-header',
  templateUrl: './help-tooltip-header.component.html',
  styleUrls: ['./help-tooltip-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpTooltipHeaderComponent {
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
}
