import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AppHeaderComponent} from './app-header.component';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
  declarations: [AppHeaderComponent],
  imports: [CommonModule, MatDividerModule, MatIconModule, MatButtonModule, MatTooltipModule],
  exports: [AppHeaderComponent]
})
export class AuthAppHeaderModule {}
