import {Dictionary} from '@ngrx/entity/src/models';
import {createFeatureSelector, createSelector, MemoizedSelector, select} from '@ngrx/store';
import {CurrentIds, CurrentMixSongMixes, MixEntity} from '@spout/any-shared/models';
import {
  createPassThroughFeatureSelector,
  createPassThroughSelector,
  getDefaultEntityConfig
} from '@spout/web-global/fns';
import {AccountState, mixesFeatureKey, MixState, StudioAppState} from '@spout/web-global/models';
import {getIn} from '@uiux/fn';
import {isDefinedPipe} from '@uiux/rxjs';
import {pipe} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {selectAccountState} from '../account.selectors';
import {selectCurrentIDsFromStore, selectCurrentIDsFromStore_passThrough} from './device-storage.selectors';

// MIX MIX MIX MIX
// MIX MIX MIX MIX
// MIX MIX MIX MIX
// MIX MIX MIX MIX
export const mixSate = createFeatureSelector<MixState>(mixesFeatureKey);
export const mixSate_passThrough = createPassThroughFeatureSelector<StudioAppState, MixState>(mixesFeatureKey);

export const selectMixEntities = createSelector(mixSate, (state: MixState): Dictionary<MixEntity> => {
  return state.entities;
});

export const selectMixEntities_passThrough = createPassThroughSelector(
  mixSate_passThrough,
  (state: MixState): Dictionary<MixEntity> => {
    return state.entities;
  }
);

export const selectAllMixes = createSelector(selectMixEntities, (entities: Dictionary<MixEntity>): MixEntity[] => {
  if (Object.values(entities).length) {
    return <MixEntity[]>Object.values(entities);
  }

  return [];
});

export const selectAllMixes_passThrough: MemoizedSelector<StudioAppState, MixEntity[]> = createPassThroughSelector(
  selectMixEntities_passThrough,
  (entities: Dictionary<MixEntity>): MixEntity[] => {
    if (Object.values(entities).length) {
      return <MixEntity[]>Object.values(entities);
    }

    return [];
  }
);

export const getMixEntitiesBySongIdPassThrough = (props: {songId: string}) => {
  return createPassThroughSelector(selectAllMixes_passThrough, (mixes: MixEntity[]): MixEntity[] => {
    return mixes.filter((mix: MixEntity) => mix.songId === props.songId);
  });
};

export const getMixEntitiesByProjectId = createSelector(
  selectAllMixes,
  (mixes: MixEntity[], props: {projectId: string}) => {
    return mixes.filter((mix: MixEntity) => mix.projectId === props.projectId);
  }
);

export const selectDefaultMix = createSelector(
  selectAllMixes,
  selectAccountState,
  (entities: MixEntity[], account: AccountState): MixEntity | null => {
    return <MixEntity>getDefaultEntityConfig(<{isDefault: boolean; createdByUID: string}[]>entities, account);
  }
);

export const selectCurrentMixEntity: MemoizedSelector<any, MixEntity | null> = createSelector(
  selectAllMixes,
  selectCurrentIDsFromStore,
  (mixes: MixEntity[], ids: CurrentIds | null): MixEntity | null => {
    // console.log('currentIds', ids);
    if (mixes && mixes.length && ids) {
      const mix = mixes.find((mix: MixEntity) => mix.id === ids.currentMixId);
      return mix ? mix : null;
    }

    return null;
  }
);

export const selectCurrentMixEntity_passThrough: MemoizedSelector<any, MixEntity | null> = createSelector(
  selectAllMixes_passThrough,
  selectCurrentIDsFromStore_passThrough,
  (mixes: MixEntity[], ids: CurrentIds | null): MixEntity | null => {
    // console.log('currentIds', ids);
    if (mixes && mixes.length && ids) {
      const mix = mixes.find((mix: MixEntity) => mix.id === ids.currentMixId);
      return mix ? mix : null;
    }

    return null;
  }
);

export const selectCurrentMixWithSongMixes = (
  state: StudioAppState
): {currentMix: MixEntity | null | undefined; songMixes: MixEntity[]} => {
  const currentMixId: string | null = getIn(state, 'deviceStorage.currentIds.currentMixId', null);
  const currentSongId: string | null = getIn(state, 'deviceStorage.currentIds.currentSongId', null);

  if (state.mix && state.mix.entities && currentMixId && state.mix.entities[currentMixId]) {
    return {
      songMixes: <MixEntity[]>(
        Object.values(state.mix.entities).filter((mix: MixEntity | undefined) => mix && mix.songId === currentSongId)
      ),
      currentMix: state.mix.entities[currentMixId]
    };
  }

  return {
    currentMix: null,
    songMixes: []
  };
};

export const selectCurrentMixWithSongMixes$ = pipe(
  select(selectCurrentMixWithSongMixes),
  isDefinedPipe<CurrentMixSongMixes, CurrentMixSongMixes>(),
  map((d: CurrentMixSongMixes) => JSON.stringify(d)),
  distinctUntilChanged(),
  map((d: string) => JSON.parse(d))
);

export const selectCurrentMixEntityId = createSelector(selectCurrentMixEntity, mixEntity => {
  if (mixEntity) {
    return mixEntity.id;
  }
  return null;
});

export const selectCurrentMixEntityNamePassThrough = createPassThroughSelector(
  selectCurrentMixEntity_passThrough,
  mixEntity => {
    if (mixEntity) {
      return mixEntity.name;
    }
    return null;
  }
);

export const getMixEntityById = createSelector(
  selectMixEntities,
  (entities: Dictionary<MixEntity>, props: {id: string}): MixEntity | undefined => entities[props.id]
);
