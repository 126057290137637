import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {ReactiveComponentModule} from '@ngrx/component';
import {SptAExternalModule, SptAlertMessageModule} from '@spout/web-global/ui';

import {LoginComponent} from './login.component';
import {SigninOathComponent} from './signin-oath/signin-oath.component';
import {SigninEmailComponent} from './signin-email/signin-email.component';
import {SignupEmailComponent} from './signup-email/signup-email.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {StoreModule} from '@ngrx/store';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    LoginComponent,
    SigninOathComponent,
    SigninEmailComponent,
    SignupEmailComponent,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveComponentModule,
    RouterModule,
    SptAlertMessageModule,
    SptAExternalModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatTabsModule,
    MatInputModule,
    StoreModule
  ],
  exports: [LoginComponent]
})
export class UiLoginModule {}
