import {SptPermissionState} from './payment.model';
import {AuthAccountProductStates} from './store-models/account.model';
import {DeviceDetectionState} from './store-models/device.model';
import {ConnectionRegistryState} from './store-models/websocket-registry.models';

export interface MarketingAppState {
  account: AuthAccountProductStates;
  deviceDetection: DeviceDetectionState;
  permission: SptPermissionState;
  webSocketRegistry: ConnectionRegistryState;
}
