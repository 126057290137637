import {MixEntity, ProjectEntity, SongEntity, TrackEntity} from '@spout/any-shared/models';

export const consoleFeatureKey = 'consoleState';

export interface ConsoleVisual {
  // Project
  createProject: boolean;
  createProjectWorkspace: boolean;
  editProject: boolean;
  selectOrCreateProject: boolean;

  // Song
  createSong: boolean;
  editSong: boolean;
  selectOrCreateSong: boolean;

  // Track
  createMasterTrack: boolean;
  editMasterTrack: boolean;
  createTrack: boolean;
  editTrack: boolean;

  // Record
  recordMaster: boolean;
  recorder: boolean;
}

export interface ConsoleAggregate {
  projects: ProjectEntity[];
  currentProjectEntity: ProjectEntity;
  songs: SongEntity[];
  currentSongEntity: SongEntity;
  tracks: TrackEntity[];
  currentTrackEntity: TrackEntity;
  currentMixEntity: MixEntity;
  currentSongHasMaster: boolean;
  currentTrackMaster: TrackEntity;
  isDesktop: boolean;
}

export interface ConsoleState {
  expandAllTracks: boolean;
  expandControls: boolean;
  // Player
  player: boolean;

  // Microphone
  selectMicrophone: boolean;

  // OLD
  visual: ConsoleVisual;
  showControlUI: boolean;
  disableWhenRecording: boolean;
  disableWhenRecordingWhenCurrentTrackIsNotMaster: boolean;
  helpSelectMicrophone: boolean;
}

export const initialConsoleState: ConsoleState = {
  // Track List
  expandAllTracks: false,
  expandControls: false,
  // Player
  player: true,

  // Microphone
  selectMicrophone: false,

  // OLD
  showControlUI: false,
  disableWhenRecording: false,
  disableWhenRecordingWhenCurrentTrackIsNotMaster: false,
  helpSelectMicrophone: false,

  visual: {
    // Project
    createProject: false,
    createProjectWorkspace: false,
    editProject: false,
    selectOrCreateProject: false,

    // Song
    createSong: false,
    editSong: false,
    selectOrCreateSong: false,

    // Track
    createMasterTrack: false,
    editMasterTrack: false,
    createTrack: false,
    editTrack: false,
    recordMaster: false,

    // Record
    recorder: false
  }
};

export interface ImportFileAction {
  action: string;
  name: string;
}
