import {OverlayModule} from '@angular/cdk/overlay';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SptResetBtnComponent} from './spt-reset-btn.component';

@NgModule({
  declarations: [SptResetBtnComponent],
  exports: [SptResetBtnComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule, MatButtonModule, OverlayModule]
})
export class SptResetBtnModule {}
