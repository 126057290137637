import {createAction, props} from '@ngrx/store';
import {CloseAndNavigateTo, RouterAction, RouterError} from '@spout/web-global/models';

export const closeAndNavigate = createAction('[Router] Close Aux and Navigate To', props<CloseAndNavigateTo>());
export const closeAuxRoute = createAction('[Router] Close Aux Route');
export const openAuxRoute = createAction('[Router] Open Aux Route');
export const setRouteInitialLoaded = createAction('[Router] Initial Load');
export const routerLoadError = createAction('[Router] Router Load Error', props<RouterError>());

export const routerCurrentRoute = createAction('[Router] Current Route', props<RouterAction>());
