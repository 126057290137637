import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {transportStop} from '@spout/web-global/actions';
import {DYN_STORE, StudioAppState} from '@spout/web-global/models';
import {hasValue, isNumber} from '@uiux/fn';
import {hasValuePipe} from '@uiux/rxjs';
import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {DynamicStoreService} from '../../services/dynamic-store.service';
import {SptTransportService} from '../spt-transport.service';
import {SptCurrentMixMetricsCalculatorService} from './spt-current-mix-metrics-calculator.service';

@Injectable({
  providedIn: 'root'
})
export class SptScrubberService {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _duration = 0;
  private _skipLength = 10; // seconds

  /**
   * Current time in seconds
   */
  // seconds = 0;

  constructor(
    private dss: DynamicStoreService,
    private store: Store<StudioAppState>,
    private metrics: SptCurrentMixMetricsCalculatorService,
    private sptTransportService: SptTransportService
  ) {
    this.metrics.currentDuration$.pipe(distinctUntilChanged()).subscribe((duration: number) => {
      // console.log('select(selectCurrentMixDuration)', duration);
      this._duration = duration;
      this.playerOutput(this.sptTransportService.seconds);
    });

    this.metrics.currentMixBPM$.pipe(distinctUntilChanged()).subscribe((bpm: number) => {
      if (hasValue(bpm) && isNumber(bpm)) {
        // this.sptTransportService.bpm.rampTo(bpm, 0.5);
      }
    });

    this.dss
      .store<number>(DYN_STORE.FROM_SECONDS_INPUT_FIELD)
      .pipe(takeUntil(this._onDestroy$), hasValuePipe<number, number>(), distinctUntilChanged())
      .subscribe((seconds: number) => {
        seconds = +seconds;
        // console.log('DYN_STORE.FROM_SECONDS_INPUT_FIELD', seconds);
        if (this.sptTransportService.seconds < 0) {
          this.sptTransportService.seconds = 1;
          this.sptTransportService.seconds = 0;
        }

        this.sptTransportService.seconds = seconds;
        // this.seconds = this.sptTransportService.seconds;
        // console.log('spt-player DYN_STORE.SECONDS_INPUT', seconds, this.sptTransportService.seconds, this.sptTransportService.position);
        this.playerOutput(seconds);
      });

    this.sptTransportService.seconds$
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe((seconds: number) => {
        this.playerOutput(seconds);
      });

    this.sptTransportService.onPlayRecordStop$
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe((stop: boolean) => {
        if (stop) {
          this.stopPlayerAndTransport();
        }
      });
  }

  fastRewind() {
    let seconds = this.sptTransportService.seconds - this._skipLength;
    seconds = seconds >= 0 ? seconds : 0;

    this.sptTransportService.seconds = seconds;

    if (this.sptTransportService.isStopped) {
      // const tonePosition = (<BarsBeatsSixteenths>this.sptTransportService.position).split(':');

      // const positionObject: SptPosition = {
      //   bars: tonePosition[ 0 ],
      //   beats: tonePosition[ 1 ],
      //   sixteenths: tonePosition[ 2 ],
      // };

      // this.dss.dispatch<number>(DYN_STORE.SECONDS_OUTPUT, seconds);
      // this.dss.dispatch<SptPosition>(DYN_STORE.POSITION_OUTPUT, positionObject);

      if (this.sptTransportService.seconds > 0) {
        this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, false);
        this.dss.dispatch(DYN_STORE.IS_BEGINNING_OF_SONG, false);
      } else {
        this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, false);
        this.dss.dispatch(DYN_STORE.IS_BEGINNING_OF_SONG, true);
      }
    }
  }

  fastForward() {
    const seconds = this.sptTransportService.seconds + this._skipLength;
    this.sptTransportService.seconds = seconds <= this._duration ? seconds : this._duration;

    if (this.sptTransportService.isStopped) {
      // const tonePosition = (<BarsBeatsSixteenths>this.sptTransportService.position).split(':');

      // const positionObject: SptPosition = {
      //   bars: tonePosition[ 0 ],
      //   beats: tonePosition[ 1 ],
      //   sixteenths: tonePosition[ 2 ],
      // };

      // this.dss.dispatch<number>(DYN_STORE.SECONDS_OUTPUT, seconds);
      // this.dss.dispatch<SptPosition>(DYN_STORE.POSITION_OUTPUT, positionObject);

      if (this.sptTransportService.seconds < this._duration) {
        this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, false);
        this.dss.dispatch(DYN_STORE.IS_BEGINNING_OF_SONG, false);
      } else {
        this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, true);
        this.dss.dispatch(DYN_STORE.IS_BEGINNING_OF_SONG, false);
      }
    }
  }

  fullRewind() {
    // The Sound gets disconnected intermittently if this.sptTransportService is not stopped
    this.sptTransportService.seconds = 0;

    if (this.sptTransportService.isStopped) {
      // const tonePosition = (<BarsBeatsSixteenths>this.sptTransportService.position).split(':');

      // const positionObject: SptPosition = {
      //   bars: tonePosition[ 0 ],
      //   beats: tonePosition[ 1 ],
      //   sixteenths: tonePosition[ 2 ],
      // };

      // this.dss.dispatch<number>(DYN_STORE.SECONDS_OUTPUT, 0);
      // this.dss.dispatch<SptPosition>(DYN_STORE.POSITION_OUTPUT, positionObject);
      this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, false);
      this.dss.dispatch(DYN_STORE.IS_BEGINNING_OF_SONG, true);
    }
  }

  fullForward() {
    // console.log('fullForward');
    // The Sound gets disconnected intermittently if this.sptTransportService is not stopped
    this.sptTransportService.stop();
    this.sptTransportService.seconds = this._duration;
    // this.seconds = this.sptTransportService.seconds;

    // const tonePosition = (<BarsBeatsSixteenths>getTransport().position).split(':');

    // const positionObject: SptPosition = {
    //   bars: tonePosition[ 0 ],
    //   beats: tonePosition[ 1 ],
    //   sixteenths: tonePosition[ 2 ],
    // };

    // this.dss.dispatch<number>(DYN_STORE.SECONDS_OUTPUT, this._duration);
    // this.dss.dispatch<SptPosition>(DYN_STORE.POSITION_OUTPUT, positionObject);
    this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, true);
    this.dss.dispatch(DYN_STORE.IS_BEGINNING_OF_SONG, false);
  }

  stopPlayerAndTransport() {
    // NOTE: Set seconds when stop

    // this.sptTransportService.stop();

    /**
     * NOTE REQUIRED BECAUSE Tone.this.sptTransportService.stop resets to 0
     */
    // this.sptTransportService.seconds = seconds;

    if (this.sptTransportService.seconds <= 0) {
      this.sptTransportService.seconds = 0;

      this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, false);
      this.dss.dispatch(DYN_STORE.IS_BEGINNING_OF_SONG, true);
    } else if (this.sptTransportService.seconds >= this._duration) {
      this.sptTransportService.seconds = this._duration;
      this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, true);
      this.dss.dispatch(DYN_STORE.IS_BEGINNING_OF_SONG, false);
    } else {
      this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, false);
      this.dss.dispatch(DYN_STORE.IS_BEGINNING_OF_SONG, false);
    }

    // this.seconds = this.sptTransportService.seconds;

    // const tonePosition = (<BarsBeatsSixteenths>this.sptTransportService.position).split(':');

    // const positionObject: SptPosition = {
    //   bars: tonePosition[ 0 ],
    //   beats: tonePosition[ 1 ],
    //   sixteenths: tonePosition[ 2 ],
    // };

    // this.dss.dispatch<number>(DYN_STORE.SECONDS_OUTPUT, seconds);
    // this.dss.dispatch<SptPosition>(DYN_STORE.POSITION_OUTPUT, positionObject);
  }

  onDestroy() {
    this._onDestroy$.next(true);
  }

  private playerOutput(seconds: number): void {
    // this.seconds = this.sptTransportService.seconds;

    // const tonePosition = (<BarsBeatsSixteenths>this.sptTransportService.position).split(':');

    // const positionObject: SptPosition = {
    //   bars: tonePosition[ 0 ],
    //   beats: tonePosition[ 1 ],
    //   sixteenths: tonePosition[ 2 ],
    // };

    // this.dss.dispatch<SptPosition>(positionId, positionObject);

    if (!this.sptTransportService.isRecording) {
      if (this._duration > 0 && seconds >= this._duration) {
        if (this.sptTransportService.isStarted) {
          this.sptTransportService.stop();
          this.sptTransportService.seconds = this._duration;

          this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, true);
          this.dss.dispatch(DYN_STORE.IS_BEGINNING_OF_SONG, false);
          this.store.dispatch(transportStop());
        }
      } else {
        this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, false);
      }
    }
  }
}
