import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {ElectronDialogText, exportDirectoryDialogText} from '@spout/any-shared/models';
import {
  getExportDirectoryEffect,
  moveWorkspaceEffect,
  saveDeviceStoreDirectoryPathEffect,
  upsertDeviceStorage
} from '@spout/web-global/actions';
import {AuthAccountStates} from '@spout/web-global/models';
import {selectUidHash$} from '@spout/web-global/selectors';
import {map, switchMap} from 'rxjs/operators';
import {DeviceStorageService} from './services/device-storage.service';

@Injectable()
export class DeviceWorkspaceEffects {
  saveDeviceStoreDirectoryPathEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveDeviceStoreDirectoryPathEffect),
      switchMap(action =>
        this.store.pipe(
          selectUidHash$,
          map(account => {
            const storage = {
              cacheDirectory: action.cacheDirectory
            };

            // TODO ERROR NOTIFICATION
            this.device
              .saveDeviceStorage({
                account,
                storage
              })
              .subscribe(() => {
                /* noop */
              });

            return upsertDeviceStorage({storage});
          })
        )
      )
    );
  });

  moveWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(moveWorkspaceEffect),
      switchMap(action => {
        return this.store.pipe(
          selectUidHash$,
          switchMap((account: string) => {
            return this.device.moveWorkspace(account).pipe(
              map(newWorkspacePath => {
                return saveDeviceStoreDirectoryPathEffect({
                  cacheDirectory: newWorkspacePath
                });
              })
            );
          })
        );
      })
    )
  );

  getExportDirectory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExportDirectoryEffect),
      switchMap(action => {
        return this.store.pipe(
          selectUidHash$,
          switchMap((account: string) => {
            const payload: ElectronDialogText = {
              ...exportDirectoryDialogText,
              account
            };

            return this.device.getNewExportDirectory(payload).pipe(
              map((exportDirectory: string) => {
                return upsertDeviceStorage({
                  storage: {
                    exportDirectory
                  }
                });
              })
            );
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private device: DeviceStorageService,
    private store: Store<AuthAccountStates>
  ) {}
}
