import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AudioFileMetaData, ElectronNativeAudio, FileBackblazeB2LoadPayload} from '@spout/any-shared/models';
import {AuthAccountStates} from '@spout/web-global/models';
import {selectUidHash$} from '@spout/web-global/selectors';
import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {DeviceStorageService} from './device-storage.service';

@Injectable()
export class AudioFileLoadService {
  constructor(private device: DeviceStorageService, private store: Store<AuthAccountStates>) {}

  loadFileFromDisk(audioFileMetaData: AudioFileMetaData): Observable<ElectronNativeAudio> {
    return this.store.pipe(
      selectUidHash$,
      take(1),
      switchMap((account: string) => {
        return this.device.loadAudioFile(<FileBackblazeB2LoadPayload>{
          account,
          audioFileMetaData
        });
      })
    );
  }
}
