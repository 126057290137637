import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {CurrentIds, Musician} from '@spout/any-shared/models';
import {
  createPassThroughFeatureSelector,
  createPassThroughSelector,
  isCreatedByLoggedInMusician,
  isInTrialPeriod,
  validateOnboardingValues
} from '@spout/web-global/fns';
import {
  ACCOUNT_FEATURE_KEY,
  AccountState,
  AuthAccountProductStates,
  AuthAccountStates,
  AuthState,
  OnboardingState,
  PromoCodeDict,
  SptPermissionState,
  SubscriptionItem,
  SubscriptionItemDict,
  SubscriptionStatus
} from '@spout/web-global/models';
import {allValuesHasValue, allValuesTruthy, hasValue} from '@uiux/fn';
import {hasValuePipe} from '@uiux/rxjs';
import {Observable, pipe, UnaryFunction} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

// Lookup the 'Account' feature state managed by NgRx
export const selectAuthAccountState = createFeatureSelector<AuthAccountProductStates>(ACCOUNT_FEATURE_KEY);

export const selectAuthAccountState_passThrough =
  createPassThroughFeatureSelector<AuthAccountProductStates>(ACCOUNT_FEATURE_KEY);

export const selectAccountState = createSelector(selectAuthAccountState, (state): AccountState => state.account);

export const selectAccountState_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state): AccountState => state.account
);
// Should return only one time
// null if not defauld ids in firestore account
export const selectCurrentIdsFromAccountDefaultIds$: UnaryFunction<
  Observable<AuthAccountProductStates>,
  Observable<CurrentIds>
> = pipe(
  select(selectAccountState),
  filter(account => hasValue(account.email)),
  map(({defaultMixId, defaultSongId, defaultTrackId, defaultProjectId}: AccountState) => {
    if (defaultProjectId && defaultSongId && defaultMixId && defaultTrackId) {
      const defaultIDs: CurrentIds = {
        currentProjectId: defaultProjectId,
        currentSongId: defaultSongId,
        currentTrackId: defaultTrackId,
        currentMixId: defaultMixId
      };

      // console.log('CURRENT IDS FROM PIPE', defaultIDs, allValuesHasValue(defaultIDs));

      if (allValuesHasValue(defaultIDs)) {
        return defaultIDs;
      }

      return {
        currentProjectId: null,
        currentSongId: null,
        currentTrackId: null,
        currentMixId: null
      };
    }

    return {
      currentProjectId: null,
      currentSongId: null,
      currentTrackId: null,
      currentMixId: null
    };
  })
);

export const selectDisplayName = createSelector(
  selectAuthAccountState,
  (state: AuthAccountProductStates) => state.account.displayName
);

export const selectEmail = createSelector(selectAuthAccountState, (state: AuthAccountProductStates): string => {
  if (state.account && state.account.email) {
    return state.account.email;
  }

  return '';
});

export const selectPhoneNumber = createSelector(
  selectAuthAccountState,
  (state: AuthAccountProductStates) => state.account.phoneNumber
);

export const selectPhotoUrl = createSelector(
  selectAuthAccountState,
  (state: AuthAccountProductStates) => state.auth.photoURL
);

export const selectUserInitial = createSelector(selectAuthAccountState, (state: AuthAccountProductStates) =>
  state.account.stageName && state.account.stageName ? state.account.stageName.slice(0, 1).toUpperCase() : ''
);

export const selectProviderId = createSelector(
  selectAuthAccountState,
  (state: AuthAccountProductStates) => state.auth.providerId
);

export const selectStageName = createSelector(selectAuthAccountState, (state: AuthAccountProductStates) => {
  if (state.account && state.account.stageName) {
    return state.account.stageName;
  }

  return '';
});

export const getStageName$ = pipe(select(selectStageName), distinctUntilChanged());

export const selectUid = createSelector(selectAuthAccountState, (state: AuthAccountProductStates): string => {
  {
    if (state.account && state.account.uid) {
      return state.account.uid;
    }

    return '';
  }
});

export const selectUid_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state: AuthAccountProductStates): string => {
    {
      if (state.account && state.account.uid) {
        return state.account.uid;
      }

      return '';
    }
  }
);

export const selectAccountIsLoaded = createSelector(
  selectAuthAccountState,
  (state: AuthAccountProductStates) => state.isLoaded
);

export const selectOnboardingState = createSelector(
  selectAuthAccountState,
  (state: AuthAccountProductStates): OnboardingState => {
    return {
      // TODO isLoaded is not updating
      isLoaded: state.isLoaded,
      isLoggedIn: state.auth.isLoggedIn,
      isOnboarded: allValuesTruthy(validateOnboardingValues(state.account)),
      uid: state.auth.uid
    };
  }
);

export const selectOnboarding = createSelector(selectAccountState, state => validateOnboardingValues(state));

export const selectIsOnboarded = createSelector(selectOnboarding, onboarding => {
  return allValuesTruthy(onboarding);
});

export const selectNotOnboarded = createSelector(selectIsOnboarded, (_isOnboarded: boolean) => {
  return !_isOnboarded;
});

export const selectStageNameOnboarded = createSelector(selectOnboarding, onboarding => onboarding.hasStageName);

export const selectGenresOnboarded = createSelector(selectOnboarding, onboarding => onboarding.hasGenres);

export const selectGenresNotOnboarded = createSelector(selectOnboarding, onboarding => !onboarding.hasGenres);

export const selectInstrumentsOnboarded = createSelector(selectOnboarding, onboarding => onboarding.hasInstruments);

export const selectInstrumentsNotOnboarded = createSelector(selectOnboarding, onboarding => !onboarding.hasInstruments);

export const getIsCreatedByLoggedInMusicianFn_passThrough = (config: {createdBy: Musician}) =>
  createPassThroughSelector(selectUid, (uid: string | null): boolean => isCreatedByLoggedInMusician(uid, config));

export const selectMonitorStore = createSelector(
  selectAccountState,
  (accountState: AccountState) => accountState.monitorStore
);

export const selectMonitorStore$: UnaryFunction<Observable<any>, Observable<boolean>> = pipe(
  select(selectMonitorStore),
  distinctUntilChanged<boolean>()
);

// Lookup the 'Auth' feature state managed by NgRx
export const selectAuthState = createSelector(
  selectAuthAccountState,
  (state: AuthAccountProductStates): AuthState => state.auth
);

export const selectSubscriptions = createSelector(
  selectAuthAccountState,
  (state: AuthAccountProductStates): SubscriptionItem[] => Object.values(state.subscriptionItemEntities)
);

export const selectSubscriptionId = createSelector(
  selectAuthAccountState,
  (state: AuthAccountProductStates): string | null => state.subscriptionId
);

export const selectSubscriptionSpecs = createSelector(
  selectAuthAccountState,
  (state: AuthAccountProductStates): SubscriptionItemDict => {
    return {
      subscriptionItemEntities: state.subscriptionItemEntities,
      subscriptionId: state.subscriptionId
    };
  }
);

export const getError = createSelector(selectAuthState, (state: AuthState) => state.loginError);

export const getAllAuth = createSelector(selectAuthState, (state: AuthState) => {
  return state;
});

export const getAlertMessage = createSelector(selectAuthState, (state: AuthState) => {
  return state.alertMessage;
});

export const getIsLoggedIn = createSelector(selectAuthState, (state: AuthState): boolean => {
  return state.isLoggedIn;
});

export const isLoggedInIsOnboarded = createSelector(
  getIsLoggedIn,
  selectIsOnboarded,
  (
    isLoggedIn: boolean,
    isOnboarded: boolean
  ): {
    isLoggedIn: boolean;
    isOnboarded: boolean;
  } => {
    return {
      isLoggedIn,
      isOnboarded
    };
  }
);

export const selectIsLoggedIn$ = pipe(
  select(getIsLoggedIn),
  // tap((isLoggedIn) => {
  //   console.log(isLoggedIn);
  // }),
  distinctUntilChanged<boolean>()
);

export const geIsLoggedInIsTrue = pipe(
  select(getIsLoggedIn),
  distinctUntilChanged<boolean>(),
  map((isLoggedIn: boolean) => isLoggedIn)
);

export const geIsLoggedInIsFalse = pipe(
  select(getIsLoggedIn),
  distinctUntilChanged<boolean>(),
  map((isLoggedIn: boolean) => !isLoggedIn)
);

export const getIsLoggedOut = createSelector(selectAuthState, (state: AuthState) => {
  return !state.isLoggedIn;
});

export const selectIsLoggedOut = pipe(select(getIsLoggedOut), distinctUntilChanged());

export const selectUidHash = createSelector(selectAuthState, state => state.uidHash);

export const selectUidHash$: UnaryFunction<Observable<AuthAccountStates>, Observable<string>> = pipe(
  select(selectUidHash),
  hasValuePipe<string | null, string>(),
  distinctUntilChanged<string>()
);

// export const getIsOnline = createSelector(getUser, (state: AuthState) => state.isOnline);

// export const selectEmail = createSelector(getUser, (state: AuthState) => state.email);

export const hasSubscription = createSelector(selectAuthAccountState, (state: AuthAccountProductStates) =>
  hasValue(state.subscriptionItemEntities)
);

export const selectDawFeatures = createSelector(selectAuthAccountState, (state: SptPermissionState) =>
  state.featurePermissions.filter(d => d.category === 'daw')
);

export const selectCollaborationFeatures = createSelector(selectAuthAccountState, (state: SptPermissionState) =>
  state.featurePermissions.filter(d => d.category === 'collaboration')
);

export const selectHasSubscriptionId_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state: AuthAccountProductStates) =>
    state.subscriptionId !== null && state.subscriptionId !== undefined && state.subscriptionId.length > 0
);

export const selectHasActiveSubscription = createSelector(
  selectAuthAccountState_passThrough,
  (state: AuthAccountProductStates): boolean =>
    state.subscription !== null &&
    state.subscription !== undefined &&
    state.subscription.status === SubscriptionStatus.active
);

export const selectPromo_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state: AuthAccountProductStates) => state.promoCode
);

export const selectPromoStorage_passThrough = createPassThroughSelector(
  selectPromo_passThrough,
  (promo: PromoCodeDict) => {
    return promo && promo.baseStorageGB ? promo.baseStorageGB : 0;
  }
);

export const hasPromoCode = createSelector(
  selectPromo_passThrough,
  (promo: PromoCodeDict): boolean => promo.code !== null && promo.code !== undefined && promo.type !== 'none'
);

export const isQAPromoCode_passThrough = createPassThroughSelector(
  selectPromo_passThrough,
  (promo: PromoCodeDict): boolean => promo.type === 'qa'
);

export const isBetaPromoCode_passThrough = createPassThroughSelector(
  selectPromo_passThrough,
  (promo: PromoCodeDict): boolean => promo.type === 'beta'
);

export const selectPromoRole_passThrough = createPassThroughSelector(
  selectPromo_passThrough,
  (promo: PromoCodeDict) => promo.accountRole
);

export const selectPermissionRole_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state: AuthAccountProductStates) => state.permissionRole
);

export const selectAccountRole_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state: AuthAccountProductStates) => state.accountRole
);

export const selectIsInTrial = createSelector(selectAuthAccountState_passThrough, (state: AuthAccountProductStates) => {
  return isInTrialPeriod(state);
});

export const selectAccountIsActive = createPassThroughSelector(
  hasPromoCode,
  selectIsInTrial,
  selectHasActiveSubscription,
  (hasPromoCode: boolean, isInTrial: boolean, hasActiveSubscription: boolean): boolean => {
    return isInTrial || hasPromoCode || hasActiveSubscription;
  }
);

export const selectAccountIsExpired = createSelector(
  hasPromoCode,
  selectIsInTrial,
  selectHasActiveSubscription,
  (hasPromoCode: boolean, isInTrial: boolean, hasActiveSubscription: boolean): boolean => {
    // console.log(`hasPromoCode ${hasPromoCode}`);
    // console.log(`isInTrial ${isInTrial}`);
    // console.log(`hasActiveSubscription ${hasActiveSubscription}`);
    // console.log(`isExpired`, !(isInTrial || hasPromoCode || hasActiveSubscription));

    if (hasPromoCode) {
      return false;
    } else if (hasActiveSubscription) {
      return false;
    } else if (isInTrial) {
      return false;
    }

    return true;
  }
);
