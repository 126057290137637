import {Dictionary} from '@ngrx/entity/src/models';
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {
  AccountState,
  AuthAccountStates,
  AuthAccountStatesConnect,
  AuthState,
  ConnectionRegistryState,
  ConnectionService,
  SubscriptionItem,
  WEBSOCKET_REGISTRY_FEATURE_KEY,
  websocketRegistryAdapter
} from '@spout/web-global/models';
import {Observable, pipe, UnaryFunction} from 'rxjs';
import {distinctUntilKeyChanged, filter} from 'rxjs/operators';
import {selectAccountState, selectAuthState, selectSubscriptions} from '../account.selectors';

// Lookup the 'ConnectionRegistry' feature state managed by NgRx
const selectConnectionRegistryState = createFeatureSelector<ConnectionRegistryState>(WEBSOCKET_REGISTRY_FEATURE_KEY);

const {selectEntities} = websocketRegistryAdapter.getSelectors();

export const selectAllDisconnected = createSelector(selectConnectionRegistryState, (state: ConnectionRegistryState) => {
  return !state.allConnected;
});

export const selectAllDisconnectedFn = () => {
  return createSelector(selectConnectionRegistryState, (state: ConnectionRegistryState) => {
    return !state.allConnected;
  });
};

export const selectWebSockets = createSelector(selectConnectionRegistryState, selectEntities);

/**
 * @deprecated replace with getWebSocketIdConnected;
 */
export const selectWebSocketIdConnected = createSelector(
  selectWebSockets,
  (entities: Dictionary<ConnectionService>, id: string) => {
    if (entities && id && entities[id] !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return entities[id].connected;
    } else {
      return false;
    }
  }
);

export const getWebSocketIdConnected = (id: string) =>
  createSelector(selectWebSockets, (entities: Dictionary<ConnectionService>) => {
    if (entities && id && entities[id] !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return entities[id].connected;
    } else {
      return false;
    }
  });

export const selectDoConnect = createSelector(
  selectConnectionRegistryState,
  (state: ConnectionRegistryState) => state.doConnect
);

export const selectDoDisconnect = createSelector(
  selectConnectionRegistryState,
  (state: ConnectionRegistryState) => state.doDisconnect
);

export interface GetAuthAccountConnect {
  user: AuthAccountStates;
  doConnect: boolean;
}

export const selectAuthAccountConnect = createSelector(
  selectAccountState,
  selectAuthState,
  selectSubscriptions,
  selectDoConnect,
  (
    account: AccountState,
    auth: AuthState,
    products: SubscriptionItem[],
    doConnect: boolean
  ): AuthAccountStatesConnect => {
    // console.log(account);

    return {
      user: {
        auth,
        account
      },
      doConnect
    };
  }
);

/**
 * Returns SelectWebSocketStatus
 */
export const selectAuthAccountConnect$: UnaryFunction<
  Observable<AuthAccountStatesConnect>,
  Observable<AuthAccountStatesConnect>
> = pipe(
  select(selectAuthAccountConnect),
  filter((d: AuthAccountStatesConnect): boolean => {
    // console.log(d);

    return (
      d &&
      d.user &&
      d.user.account &&
      d.user.account.uid !== null &&
      d.user.account.uid !== undefined &&
      d.user.account.uid.length > 0
    );
  }),
  // filter((d) => hasValueIn(d, 'user.auth.uid')),
  distinctUntilKeyChanged('doConnect')
  // tap((d: GetAuthAccountConnect) => {
  //   console.log(d);
  // }),
);
