import {AudioFileMetaData} from '@spout/any-shared/models';
import {Observable, Observer} from 'rxjs';

export function encodeToArrayBuffer(float32Arrays: Float32Array[], cfg: AudioFileMetaData): Observable<ArrayBuffer> {
  return new Observable((observer: Observer<any>) => {
    const length: number = cfg.length !== undefined ? cfg.length : 0;

    const arrayBuffer = new ArrayBuffer(16 + length * cfg.numberOfChannels * 4);
    const dv = new DataView(arrayBuffer);
    dv.setFloat32(0, cfg.sampleRate !== undefined ? cfg.sampleRate : 44100);
    dv.setFloat32(4, cfg.duration || 0);
    dv.setUint32(8, length);
    dv.setUint32(12, cfg.numberOfChannels);

    for (let c = 0; c < cfg.numberOfChannels; c++) {
      const f64 = float32Arrays[c];
      for (let i = 0; i < f64.length; i++) {
        const j = 16 + c * length * 4 + i * 4;
        dv.setFloat32(j, f64[i]);
      }
    }

    observer.next(arrayBuffer);
  });
}
