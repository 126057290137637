import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {Musician} from '@spout/any-shared/models';
import {pipe} from 'rxjs';
import {filter} from 'rxjs/operators';

export type NotificationsState = EntityState<NotificationModel>;

export const notificationsEntityAdapter: EntityAdapter<NotificationModel> = createEntityAdapter<NotificationModel>();

export const notificationsInitialState: NotificationsState = notificationsEntityAdapter.getInitialState({
  // additional entity state properties
});

export enum NotificationDialogView {
  UNSHARE_PROJECT_SONG = 'UNSHARE_PROJECT_SONG'
}

export enum NotificationLevel {
  Critical = '[Notification] Critical',
  High = '[Notification] High',
  Medium = '[Notification] Medium',
  Low = '[Notification] Low'
}

export enum NotificationTypes {
  UserInviteToProject = '[Notification Type] Invite User to project',
  NewChatMessages = '[Notification Type] New Chat Messages',
  UpdateUI = '[Notification Type] Update UI',
  UpdateElectron = '[Notification Type] Update Electron',
  General = '[Notification Type] General',
  UploadError = '[Notification Type] Upload Error',
  ResetBeta = 'resetBeta'
}

export enum NotificationActionTypes {
  UpdateUi = '[Notification Action Type] Update UI',
  UpdateElectron = '[Notification Action Type] Update Electron',
  UploadError = '[Notification Action Type] Upload Error'
}

export enum StaticNotificationIds {
  UpdateUI = 'update-ui',
  UpdateElectron = 'update-electron',
  UploadError = 'server-error'
}

export interface ApiNotifiation {
  type: string;
  data?: any;
}

export interface NotificationModel {
  type: NotificationTypes;
  header: string;
  message: string;
  level: NotificationLevel;
  id: StaticNotificationIds | string;
  cta?: string | null;
  action?: NotificationActionTypes | null;

  canDismiss?: boolean;
}

export interface GenericNotification {
  type: NotificationTypes;
  header: string;
  message: string;
  level: NotificationLevel;
  // id: StaticNotificationIds | string;
  // cta?: string;
  // action?: NotificationActionTypes;

  canDismiss?: boolean;
}

/**
 * Pass in class of notfication
 * @param NotificationClass
 */
export const ofNotificationType = (NotificationClass: NotificationModel) => {
  return pipe(filter((x: NotificationModel) => NotificationClass.type === x.type));
};

export interface MusicianNotification extends NotificationModel {
  sourceMusician: Musician;
  targetMusician: Musician;
}
