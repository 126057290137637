import {Directionality} from '@angular/cdk/bidi';
import {
  CdkOverlayOrigin,
  HorizontalConnectionPos,
  Overlay,
  OverlayRef,
  VerticalConnectionPos
} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Injector,
  Input,
  NgZone,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {HelpModel} from '@spout/web-global/models';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import componentRefDict from '../content/content-dictionary';
import {ComponentDictItem, CONTAINER_DATA, ContentModel} from '../content/content.model';

const HELP_SVG = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/></svg>`;

export type TooltipDesign = 'small' | 'none';

@Component({
  selector: 'spt-help-tooltip',
  templateUrl: './help-tooltip.component.html',
  styleUrls: ['./help-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'design-help-tooltip',
    '[class.design-help-tooltip-small]': 'design === "small"'
  }
})
export class HelpTooltipComponent implements OnInit {
  private backdropClickSub: Subscription = Subscription.EMPTY;
  private _onDestroy$: Subject<boolean> = new Subject();

  @Input() helpRef: HelpModel | null = null;
  @ViewChild(CdkOverlayOrigin) _overlayOrigin: CdkOverlayOrigin | undefined;
  @Input() design: TooltipDesign = 'none';

  tooltipControl = new FormControl('');

  originX: HorizontalConnectionPos = 'center';
  originY: VerticalConnectionPos = 'top';
  overlayX: HorizontalConnectionPos = 'center';
  overlayY: VerticalConnectionPos = 'bottom';
  isFlexible = true;
  canPush = true;
  offsetX = 0;
  offsetY = 0;

  overlayRef: OverlayRef | null = null;

  disableOverlay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public dir: Directionality,
    public ngZone: NgZone,
    private cd: ChangeDetectorRef,
    private _injector: Injector,
    private _iconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    this._iconRegistry.addSvgIconLiteral('help', this._domSanitizer.bypassSecurityTrustHtml(HELP_SVG));
  }

  ngOnInit(): void {
    if (this.helpRef && componentRefDict.has(this.helpRef)) {
      this.tooltipControl.setValue(`Help ${(<ComponentDictItem>componentRefDict.get(this.helpRef)).title}`);
      this.cd.detectChanges();
    }
  }

  openWithConfig(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    if (this._overlayOrigin && this.helpRef) {
      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(this._overlayOrigin.elementRef)
        .withFlexibleDimensions(this.isFlexible)
        .withPush(this.canPush)
        .withViewportMargin(10)
        .withGrowAfterOpen(false)
        .withPositions([
          {
            originX: this.originX,
            originY: this.originY,
            overlayX: this.overlayX,
            overlayY: this.overlayY,
            offsetX: this.offsetX,
            offsetY: this.offsetY
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          },
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          }
        ]);

      this.overlayRef = this.overlay.create({
        positionStrategy,
        hasBackdrop: true,
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        direction: this.dir.value,
        width: 400,
        maxHeight: 400
      });

      this.disableOverlay$.next(true);

      this.backdropClickSub.unsubscribe();
      this.backdropClickSub = this.overlayRef.backdropClick().subscribe(() => {
        this.close(new MouseEvent('click'));
      });

      this.ngZone.run(() => {
        if (this.helpRef && this.overlayRef && componentRefDict.has(this.helpRef)) {
          const componentPortal = new ComponentPortal(
            (<ComponentDictItem>componentRefDict.get(this.helpRef)).component.component,
            null,
            this.createInjector({showBackHeader: false})
          );

          const componentRef: ComponentRef<any> = this.overlayRef.attach(componentPortal);
          this.overlayRef.addPanelClass('help-tooltip-overlay');

          componentRef.instance.onModalClose().subscribe(() => {
            this.close(new MouseEvent('click'));
          });
        }
      });
    }
  }

  close(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this._onDestroy$.next(true);
      this.disableOverlay$.next(false);
    }
  }

  createInjector(dataToPass: ContentModel): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  ngOnDestroy(): void {
    this.backdropClickSub.unsubscribe();
  }
}
