import {Injectable, NgZone} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {instrumentAggregate, upsertWebsocketRegistry, websocketIsDisconnectedAction} from '@spout/web-global/actions';
import {dispatchNgrxFirestoreAggregate, firestoreInstrumentsPath} from '@spout/web-global/fns';
import {AuthAccountStates, AuthAccountStatesConnect, INSTRUMENT_FEATURE_KEY} from '@spout/web-global/models';
import {GetAuthAccountConnect, selectAuthAccountConnect$} from '@spout/web-global/selectors';
import {onSnapshot, QuerySnapshot} from 'firebase/firestore';
import {SptFirestoreService} from '../firestore';
import {OnWebsocketMonitor} from '../websocket-monitor/websocket-monitor';

@Injectable()
export class InstrumentEffects implements OnWebsocketMonitor {
  private _instrumentDatabase: (() => void) | undefined;

  constructor(
    private actions$: Actions,
    private store: Store<AuthAccountStatesConnect>,
    private sptFirestore: SptFirestoreService,
    private zone: NgZone
  ) {
    const that = this;
    that.zone.run(() => {
      this.store.dispatch(
        upsertWebsocketRegistry({
          id: INSTRUMENT_FEATURE_KEY
        })
      );
    });

    this.store.pipe(selectAuthAccountConnect$).subscribe((s: GetAuthAccountConnect) => {
      if (s.doConnect) {
        this.onConnect.call(this, s.user);
      } else {
        this.onDisconnect.call(this, s.user);
      }
    });
  }

  onConnect(user: AuthAccountStates): void {
    const that = this;
    if (!this._instrumentDatabase) {
      that.zone.run(() => {
        that.store.dispatch(
          websocketIsDisconnectedAction({
            id: INSTRUMENT_FEATURE_KEY
          })
        );
      });

      this._instrumentDatabase = onSnapshot(
        this.sptFirestore.collectionRef(firestoreInstrumentsPath()),
        (snapshot: QuerySnapshot) => {
          dispatchNgrxFirestoreAggregate<AuthAccountStatesConnect>(
            this.store,
            snapshot,
            instrumentAggregate,
            this.zone
          );
        },
        (err: Error) => {
          console.error(err);
        },
        () => {
          /* noop */
        }
      );
    }
  }

  onDisconnect(user: AuthAccountStates): void {
    const that = this;
    if (this._instrumentDatabase) {
      this._instrumentDatabase();

      that.zone.run(() => {
        this.store.dispatch(
          websocketIsDisconnectedAction({
            id: INSTRUMENT_FEATURE_KEY
          })
        );
      });
    }
  }
}
