import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpCompressorContentComponent} from './help-compressor-content/help-compressor-content.component';
import {HelpCompressorComponent} from './help-compressor.component';

@NgModule({
  declarations: [HelpCompressorComponent, HelpCompressorContentComponent],
  exports: [HelpCompressorComponent, HelpCompressorContentComponent],
  imports: [CommonModule, HelpContentModule, HelpNavBackModule, MatIconModule, MatTooltipModule, MatButtonModule]
})
export class HelpCompressorModule {}
