<div>
  <div>Create a song as a place to record tracks.</div>

  <div class="push-top-20">
    Select <span class="help-bold-accent">Share Song</span> to share your song with other musicians in your project.
  </div>

  <div class="push-top-20">
    Selecting <span class="help-bold">Save</span> will save your song in your project "<span class="help-bold-accent">{{
      (selectedProject$ | async)?.name
    }}</span
    >".
  </div>
</div>
