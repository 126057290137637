import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {DeviceStorageState, intialDeviceStorageState} from '@spout/any-shared/models';
import {
  recordInputCompressorAttack,
  recordInputCompressorKnee,
  recordInputCompressorRatio,
  recordInputCompressorRelease,
  recordInputCompressorThreshold,
  microphoneGain,
  saveLatency,
  toggleControlsView,
  upsertDeviceStorage,
  exportMergeCompressorThreshold,
  exportMergeCompressorAttack,
  exportMergeCompressorKnee,
  exportMergeCompressorRelease,
  exportMergeCompressorRatio
} from '@spout/web-global/actions';
import {AuthAccountStates} from '@spout/web-global/models';
import {selectDeviceStorageState, selectUidHash$} from '@spout/web-global/selectors';
import {allValuesHasValue, hasValue, hasValueIn} from '@uiux/fn';
import {isDefinedPipe} from '@uiux/rxjs';
import {of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {SptAudioService} from '../audio/spt-audio.service';
import {SptTransportService} from '../audio/spt-transport.service';
import {DeviceEntityService} from './services/device-entity.service';
import {DeviceStorageService} from './services/device-storage.service';

@Injectable()
export class DeviceStorageEffects {
  // ROOT_EFFECTS_INIT
  // ROOT_EFFECTS_INIT
  // ROOT_EFFECTS_INIT
  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(action =>
        this.store.pipe(
          selectUidHash$,
          switchMap((account: string) =>
            this.device
              .loadDeviceStorageOnInit({
                account,
                storage: intialDeviceStorageState
              })
              .pipe(
                isDefinedPipe<DeviceStorageState, DeviceStorageState>(),
                switchMap((storage: DeviceStorageState) => {
                  // console.log('FROM DEVICE ', storage, hasValue(storage.mediaDeviceInfo));

                  if (hasValueIn(storage, 'latency.detected')) {
                    this.sptTransportService.latencyFromStore = storage.latency;
                  }

                  // Save Device Info in not already in storage
                  if (!hasValue(storage.mediaDeviceInfo)) {
                    return this.deviceEntityService.saveDefaultAudioDevice(storage).pipe(
                      switchMap((storageFromAudioDevice: Partial<DeviceStorageState> | null) => {
                        // console.log(
                        //   'FROM DEFAULT MEDIA DEVICE INFO ',
                        //   storageFromAudioDevice,
                        //   allValuesHasValue(storageFromAudioDevice.currentIds)
                        // );

                        if (storageFromAudioDevice && !allValuesHasValue(storageFromAudioDevice.currentIds)) {
                          // Use Account Default ID's as current ids
                          return this.deviceEntityService
                            .saveCurrentIdsFromAccountDefaultIds(storageFromAudioDevice)
                            .pipe(
                              map(storageFromCurrentIds => {
                                // console.log('FROM CURRENT IDS FROM ACCOUNT DEFAULTS ', storageFromCurrentIds);

                                // Save Audio Device and Current IDs to storage
                                this.device
                                  .saveDeviceStorage({
                                    account,
                                    storage: storageFromCurrentIds
                                  })
                                  .subscribe(() => {
                                    /* noop */
                                  });

                                return upsertDeviceStorage({
                                  storage: storageFromCurrentIds
                                });
                              })
                            );
                        } else {
                          // console.log('FROM SAVE IDS TO STORAGE ', storageFromAudioDevice);

                          if (storageFromAudioDevice) {
                            // Save Current IDs to storage
                            this.device
                              .saveDeviceStorage({
                                account,
                                storage: storageFromAudioDevice
                              })
                              .subscribe(() => {
                                /* noop */
                              });

                            return of(
                              upsertDeviceStorage({
                                storage: storageFromAudioDevice
                              })
                            );
                          }

                          return of(
                            upsertDeviceStorage({
                              storage
                            })
                          );
                        }
                      })
                    );
                  } else if (!allValuesHasValue(storage.currentIds)) {
                    // console.log('FROM HAS MEDIA DEVICE INFO, NO CURRENT IDS ', storage);

                    // Save current ids if not already in storage
                    // Use Account Default ID's as current ids
                    return this.deviceEntityService.saveCurrentIdsFromAccountDefaultIds(storage).pipe(
                      map(storageFromCurrentIds => {
                        // Save Current IDs to storage
                        // console.log('Save Current IDs to storage');
                        // console.log('account', account);
                        // console.log('storageFromCurrentIds', storageFromCurrentIds);
                        this.device
                          .saveDeviceStorage({
                            account,
                            storage: storageFromCurrentIds
                          })
                          .subscribe(() => {
                            /* noop */
                          });

                        return upsertDeviceStorage({
                          storage: storageFromCurrentIds
                        });
                      })
                    );
                  }

                  return of(upsertDeviceStorage({storage}));
                })
              )
          )
        )
      )
    );
  });

  // SAVE
  saveToDeviceStorage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(upsertDeviceStorage),
        switchMap(action => {
          return this.store.pipe(
            selectUidHash$,
            switchMap((account: string) => {
              return this.device.saveDeviceStorage({
                account,
                storage: action.storage
              });
            })
          );
        })
      );
    },
    {dispatch: false}
  );

  // SAVE
  toggleControlsViewStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(toggleControlsView),
      switchMap(action => {
        return this.store.pipe(
          selectUidHash$,
          take(1),
          switchMap((account: string) => {
            return this.store.pipe(
              select(selectDeviceStorageState),
              take(1),
              switchMap((state: DeviceStorageState) => {
                return this.device
                  .saveDeviceStorage({
                    account,
                    storage: {
                      controlsViewOpen: !state.controlsViewOpen
                    }
                  })
                  .pipe(
                    take(1),
                    map((updatedState: DeviceStorageState) => {
                      return upsertDeviceStorage({
                        storage: updatedState
                      });
                    })
                  );
              })
            );
          })
        );
      })
    );
  });

  /**
   * MICROPHONE GAIN
   */
  microphoneGain$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(microphoneGain),
      map(action => {
        // this.sptPlayer.volume(action.volume);

        return upsertDeviceStorage({
          storage: {
            microphoneGain: action.microphoneGain
          }
        });
      })
    );
  });

  recordInputCompressThreshold$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(recordInputCompressorThreshold),
      map(action => {
        return upsertDeviceStorage({
          storage: {
            recordInputCompressorThreshold: action.compressorThreshold
          }
        });
      })
    );
  });

  recordInputCompressorKnee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(recordInputCompressorKnee),
      map(action => {
        return upsertDeviceStorage({
          storage: {
            recordInputCompressorKnee: action.compressorKnee
          }
        });
      })
    );
  });

  recordInputCompressorRatio$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(recordInputCompressorRatio),
      map(action => {
        return upsertDeviceStorage({
          storage: {
            recordInputCompressorRatio: action.compressorRatio
          }
        });
      })
    );
  });

  recordInputCompressorAttack$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(recordInputCompressorAttack),
      map(action => {
        return upsertDeviceStorage({
          storage: {
            recordInputCompressorAttack: action.compressorAttack
          }
        });
      })
    );
  });

  recordInputCompressorRelease$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(recordInputCompressorRelease),
      map(action => {
        return upsertDeviceStorage({
          storage: {
            recordInputCompressorRelease: action.compressorRelease
          }
        });
      })
    );
  });

  mergeCompressThreshold$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(exportMergeCompressorThreshold),
      map(action => {
        return upsertDeviceStorage({
          storage: {
            exportMergeCompressorThreshold: action.compressorThreshold
          }
        });
      })
    );
  });

  exportMergeCompressorKnee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(exportMergeCompressorKnee),
      map(action => {
        return upsertDeviceStorage({
          storage: {
            exportMergeCompressorKnee: action.compressorKnee
          }
        });
      })
    );
  });

  exportMergeCompressorRatio$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(exportMergeCompressorRatio),
      map(action => {
        return upsertDeviceStorage({
          storage: {
            exportMergeCompressorRatio: action.compressorRatio
          }
        });
      })
    );
  });

  exportMergeCompressorAttack$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(exportMergeCompressorAttack),
      map(action => {
        return upsertDeviceStorage({
          storage: {
            exportMergeCompressorAttack: action.compressorAttack
          }
        });
      })
    );
  });

  exportMergeCompressorRelease$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(exportMergeCompressorRelease),
      map(action => {
        return upsertDeviceStorage({
          storage: {
            exportMergeCompressorRelease: action.compressorRelease
          }
        });
      })
    );
  });

  /**
   * Save Latency Effect
   */
  saveLatency$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(saveLatency),
        switchMap(action => {
          return this.store.pipe(
            selectUidHash$,
            switchMap((account: string) => {
              return this.device.saveDeviceStorage({
                account,
                storage: {
                  latency: action.latency
                }
              });
            })
          );
        })
      );
    },
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private device: DeviceStorageService,
    private store: Store<AuthAccountStates>,
    private sptAudioService: SptAudioService,
    private deviceEntityService: DeviceEntityService,
    private sptTransportService: SptTransportService
  ) {}
}
