import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {BehaviorSubject, Subject} from 'rxjs';
import {EmailPassword} from '../login.model';

@Component({
  selector: 'ui-signin-email',
  templateUrl: './signin-email.component.html',
  styleUrls: ['./signin-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninEmailComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  signInForm: FormGroup;
  showPassword$: BehaviorSubject<string>;

  @Output() forgotPassword: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() signInEmail: EventEmitter<EmailPassword> = new EventEmitter<EmailPassword>();
  @Output() dirtyAndValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {
    this.showPassword$ = new BehaviorSubject<string>('password');
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    this.signInForm.valueChanges.subscribe(() => {
      this.dirtyAndValid.next(this.signInForm.dirty && this.signInForm.valid);
      // this.cd.markForCheck();
    });
  }

  submitEmailPassword() {
    if (this.signInForm.dirty && this.signInForm.valid) {
      this.signInEmail.next(this.signInForm.value);
    }
  }

  showPassword(event: MatCheckboxChange) {
    if (this.showPassword$.value === 'password') {
      this.showPassword$.next('text');
    } else {
      this.showPassword$.next('password');
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
