import {createAction, props} from '@ngrx/store';
import {SptSystemInformation} from '@spout/any-shared/models';
import {DeviceDetectionState} from '@spout/web-global/models';
import * as UAParser from 'ua-parser-js';

export const detectDevices = createAction('[Device] Detect Devices');

export const deviceIsSmall = createAction('[Device] Is Small', props<{isSmall: boolean}>());

export const deviceIsPortrait = createAction('[Device] Is Portrait');

export const deviceIsLandscape = createAction('[Device] Is Landscape');

export const loadDevice = createAction('[Device] Load Device', props<{payload: DeviceDetectionState}>());

export const deviceLoaded = createAction('[Device] Device Loaded', props<{payload: Partial<DeviceDetectionState>}>());

export const loadElectronVersion = createAction('[Device] Load Electron Version', props<{electronVersion: string}>());

export const deviceLoadDesktopInformation = createAction(
  '[Device] Desktop Information',
  props<{
    electron: string;
    useragent: UAParser.IResult;
    desktopInformation: SptSystemInformation;
  }>()
);
