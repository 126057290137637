import {AudioFileMetaDataEntity} from '@spout/any-shared/models';

export function audioMetadataFilesHaveAudio(audioMetadataEntities: AudioFileMetaDataEntity[]) {
  return audioMetadataEntities.reduce((hasAudio: boolean, e: AudioFileMetaDataEntity) => {
    if (!hasAudio) {
      return e.duration !== undefined && e.duration > 0;
    }

    return hasAudio;
  }, false);
}
