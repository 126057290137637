import {createAction, props} from '@ngrx/store';
import {
  AccountState,
  AuthAccountStates,
  AuthState,
  CurrentSubscription,
  FeaturePermission,
  Product,
  PromoCodeDict,
  SubscriptionItem,
  TrialModel
} from '@spout/web-global/models';

export const accountLoaded = createAction(
  '[Account] Account Loaded',
  props<{
    payload: {
      auth: AuthState;
      account: AccountState;
      isLoaded?: boolean;
    };
  }>()
);

export const accountRole = createAction('[Account] Account Role', props<{role: string}>());

export const accountUpdated = createAction('[Account] Account Updated', props<{account: AccountState}>());

export const accountLoadError = createAction('[Account] Account Load Error', props<{payload: any}>());

export const accountSaveFirebase = createAction(
  '[Account] Account Save Firebase',
  props<{payload: Partial<AccountState>}>()
);

export const accountSaveBetaTestCodeFirebase = createAction(
  '[Account] Account Save Beta Test Code Firebase',
  props<{payload: Partial<AccountState>}>()
);

export const createNewAccountFromAuth = createAction('[Account] Create New Account', props<{auth: AuthState}>());

export const addSubscriptionIdToAccount = createAction(
  '[Account] Add Subscription ID To Account',
  props<{subscriptionId: string}>()
);

export const addSubscriptionToAccount = createAction(
  '[Account] Add Subscription Account',
  props<{subscription: CurrentSubscription}>()
);

export const addSubscriptionItemToAccount = createAction(
  '[Account] Add Subscription Item To Account',
  props<{subscription: SubscriptionItem}>()
);

export const addTrialToAccount = createAction('[Account] Add Trial Account', props<TrialModel>());

export const addSubscriptionsToAccount = createAction(
  '[Account] Add Subscriptions To Account',
  props<{subscriptions: SubscriptionItem[]}>()
);
export const clearSubscriptions = createAction('[Account] Clear Subscriptions');

export const loadPermissionsData = createAction(
  '[ Account ] Load permissions',
  props<{featurePermissions: FeaturePermission[]}>()
);

export const loadPromoCode = createAction('[ Account ] Load Promo Code', props<{promoCode: PromoCodeDict}>());
export const deletePromoCode = createAction('[ Account ] Delete Promo Code');

export const updateTotalCloudStorageUsed = createAction(
  '[ Account ] Total Cloud Storage Used',
  props<{totalCloudStorageUsed: number}>()
);
