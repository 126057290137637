import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {ReactiveComponentModule} from '@ngrx/component';
import {SubscriptionEndModule} from '../subscription-end/subscription-end.module';
import {MySubscriptionComponent} from './my-subscription.component';
import {CancelSubscriptionSuccessComponent} from './modals/cancel-subscription-success/cancel-subscription-success.component';
import {CancelSubscriptionErrorComponent} from './modals/cancel-subscription-error/cancel-subscription-error.component';

@NgModule({
  declarations: [MySubscriptionComponent, CancelSubscriptionSuccessComponent, CancelSubscriptionErrorComponent],
  exports: [MySubscriptionComponent, CancelSubscriptionSuccessComponent, CancelSubscriptionErrorComponent],
  imports: [
    CommonModule,
    ReactiveComponentModule,
    FormsModule,
    ReactiveFormsModule,

    SubscriptionEndModule,

    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule
  ]
})
export class SptMySubscriptionModule {}
