import {NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {MixEntity, ProjectEntity} from '@spout/any-shared/models';
import {deleteMixs, upsertMixsFromFirestore, upsertModifiedMixsFromFirestore} from '@spout/web-global/actions';
import {DocumentChange, onSnapshot, QuerySnapshot, DocumentData} from 'firebase/firestore';
import {SptFirestoreService} from '../../firestore';
import {aggregateDocChanges, firestoreMixesPath} from '@spout/web-global/fns';
import {AggregateFirebaseSnapshotChangesEntities, AuthAccountStates} from '@spout/web-global/models';
import {Subscription} from 'rxjs';
import {QueryModel} from './query.model';

export class QueryMixesService implements QueryModel<MixEntity> {
  // MIX
  private mixSub: Subscription = Subscription.EMPTY;
  private otherMixSub: (() => void) | undefined;
  private myMixSub: (() => void) | undefined;

  constructor(
    private sptFirebase: SptFirestoreService,
    private store: Store,
    private config: ProjectEntity,
    private zone: NgZone
  ) {}

  onConnect(user: AuthAccountStates) {
    const that = this;
    this.mixSub.unsubscribe();
    this.otherMixSub = onSnapshot(
      this.sptFirebase.collectionRef(firestoreMixesPath(this.config)),
      // .where('fileUploaded', '==', true)
      (snapshot: QuerySnapshot) => {
        if (user.auth.uid) {
          that.process.apply(that, [snapshot.docChanges(), user.auth.uid]);
        }
      },
      () => {
        /* noop */
      },
      () => {
        /* noop */
      }
    );
  }

  onDisconnect(user?: AuthAccountStates) {
    // MIXES
    this.mixSub.unsubscribe();

    if (this.myMixSub) {
      this.myMixSub();
    }

    if (this.otherMixSub) {
      this.otherMixSub();
    }
  }

  process(snapshot: DocumentChange<DocumentData>[], createdByUID: string) {
    const that = this;
    const aggregate: AggregateFirebaseSnapshotChangesEntities<DocumentData> = aggregateDocChanges<DocumentData>(
      snapshot,
      createdByUID
    );
    // console.log('processMixes', aggregate);

    that.zone.run(() => {
      if (aggregate.added.length) {
        // aggregate.added.forEach((added) => {
        //   console.log('MIX ADDED', added.id);
        // });

        that.store.dispatch(
          upsertMixsFromFirestore({
            mixConfigs: <MixEntity[]>aggregate.added
          })
        );
      }

      if (aggregate.modified.length) {
        // aggregate.modified.forEach((modified) => {
        //   console.log('MIX MODIFIED', modified.id);
        // });

        that.store.dispatch(
          upsertModifiedMixsFromFirestore({
            mixConfigs: <MixEntity[]>aggregate.modified
          })
        );
      }

      if (aggregate.removed.length) {
        // aggregate.removed.forEach((removed) => {
        //   console.log('MIX REMOVED', removed);
        // });

        that.store.dispatch(
          deleteMixs({
            ids: aggregate.removed
          })
        );
      }
    });
  }
}
