import {getTrackMixOfTrackAndMix, mixIsOfSong, projectChildrenAreRelated} from '@spout/any-shared/fns';
import {
  CombinedTrackIdTrackMix,
  CurrentEntities,
  MixEntity,
  ProjectEntity,
  SongEntity,
  TrackEntity,
  TrackMix,
  TrackPlayList
} from '@spout/any-shared/models';
import {trackIsCreatedBy} from '@spout/web-global/fns';
import {StudioAppState} from '@spout/web-global/models';
import {getIn, hasValue, hasValueIn} from '@uiux/fn';

export const selectCurrentEntities = (state: StudioAppState): CurrentEntities | null => {
  if (state.deviceStorage && hasValue(state.deviceStorage.currentIds)) {
    if (
      hasValueIn(state, 'project.entities') &&
      hasValueIn(state, 'song.entities') &&
      hasValueIn(state, 'mix.entities') &&
      hasValueIn(state, 'track.entities')
    ) {
      const project: ProjectEntity | undefined =
        state.project.entities[state?.deviceStorage?.currentIds?.currentProjectId || ''];
      const song: SongEntity | undefined = state.song.entities[state?.deviceStorage?.currentIds?.currentSongId || ''];
      const mix: MixEntity | undefined = state.mix.entities[state?.deviceStorage?.currentIds?.currentMixId || ''];
      const track: TrackEntity | undefined =
        state.track.entities[state?.deviceStorage?.currentIds?.currentTrackId || ''];

      // If each are defined
      if (projectChildrenAreRelated(project, song, mix, track)) {
        return {
          project,
          song,
          mix,
          track
        };
      }
    }
  }

  return null;
};

export const selectCurrentPlayList = (props: {uid: string | null}) => {
  return (state: StudioAppState): TrackPlayList | null => {
    if (state.deviceStorage && hasValue(state.deviceStorage.currentIds)) {
      if (
        hasValueIn(state, 'song.entities') &&
        hasValueIn(state, 'mix.entities') &&
        hasValueIn(state, 'track.entities') &&
        hasValueIn(state, 'trackMixes.entities')
      ) {
        const currentSongId = getIn(state, 'deviceStorage.currentIds.currentSongId', '');
        const currentMixId = getIn(state, 'deviceStorage.currentIds.currentMixId', '');

        const song: SongEntity | undefined = state.song.entities[currentSongId];
        const mix: MixEntity | undefined = state.mix.entities[currentMixId];
        if (!song || !mix) {
          return null;
        }

        const trackMixes: TrackMix[] = <TrackMix[]>Object.values(state.trackMixes.entities);
        const tracks: TrackEntity[] = <TrackEntity[]>Object.values(state.track.entities).filter(
          (track: TrackEntity | undefined) => {
            if (track !== null && track !== undefined) {
              const isCreatedByLoggedInMusician = trackIsCreatedBy(track, props.uid);

              if (track.songId === song.id) {
                return isCreatedByLoggedInMusician || track.shared;
              }
            }

            return false;
          }
        );

        if (hasValue(tracks) && mixIsOfSong(song, mix)) {
          if (hasValue(trackMixes)) {
            const trackAndTrackMixes: CombinedTrackIdTrackMix[] = tracks
              .map((track: TrackEntity) => {
                return <CombinedTrackIdTrackMix>{
                  trackId: track.id,
                  trackMix: getTrackMixOfTrackAndMix(mix, track, trackMixes)
                };
              })
              .filter((c: CombinedTrackIdTrackMix) => c.trackMix !== null);

            if (hasValue(trackAndTrackMixes)) {
              const master = trackAndTrackMixes
                .filter((trackAndTrackMix: CombinedTrackIdTrackMix) => {
                  return trackAndTrackMix.trackMix.isReference;
                })
                .reduce((acc: CombinedTrackIdTrackMix | null, i: CombinedTrackIdTrackMix) => {
                  return i;
                }, null);

              // console.log(master);

              // if (!master) {
              //   return null;
              // }

              const trackList = trackAndTrackMixes
                .filter((trackAndTrackMix: CombinedTrackIdTrackMix) => {
                  return !trackAndTrackMix.trackMix.isReference;
                })
                .sort((a, b) => {
                  if (a && a.trackMix && b && b.trackMix) {
                    if (a.trackMix.orderNum > b.trackMix.orderNum) {
                      return 1;
                    }
                    if (a.trackMix.orderNum < b.trackMix.orderNum) {
                      return -1;
                    }
                  }

                  return 0;
                })
                .map((trackAndTrackMix: CombinedTrackIdTrackMix) => trackAndTrackMix.trackId);

              return <TrackPlayList>{
                masterTrackId: master ? master.trackId : null,
                trackIds: trackList
              };
            }
          }
        }
      }
    }

    return null;
  };
};
