import {Action, createReducer, on} from '@ngrx/store';
import {TrackEntity, TrackState} from '@spout/any-shared/models';
import {
  addTrack,
  addTracks,
  authLoggedOut,
  clearTracks,
  createProjectAction,
  createSongAction,
  createTrack,
  createTrackAction,
  deleteTrack,
  deleteTracks,
  syncTrackProgress,
  updateTrack,
  updateTracks,
  upsertTrack,
  upsertTracks
} from '@spout/web-global/actions';
import {getGlobalDefaultPaletteSelector} from '@spout/web-global/fns';
import {initialTrackState, trackAdapter} from '@spout/web-global/models';

function setDefaultColor(track: TrackEntity): TrackEntity {
  if (!track.color) {
    track.color = getGlobalDefaultPaletteSelector();
  }
  return track;
}

function setDefaultColorMany(tracks: TrackEntity[]): TrackEntity[] {
  return tracks.map((track: TrackEntity) => setDefaultColor(track));
}

const reducer = createReducer(
  initialTrackState,
  on(addTrack, (state, action) => {
    return trackAdapter.addOne(setDefaultColor(action.track), state);
  }),
  on(createProjectAction, (state, action) => {
    return trackAdapter.addOne(setDefaultColor(action.track), state);
  }),
  on(createSongAction, (state, action) => {
    return trackAdapter.addOne(setDefaultColor(action.track), state);
  }),
  on(createTrackAction, (state, action) => {
    return trackAdapter.addOne(setDefaultColor(action.track), state);
  }),
  on(upsertTrack, (state, action) => {
    return trackAdapter.upsertOne(setDefaultColor(action.track), state);
  }),
  on(addTracks, (state, action) => {
    return trackAdapter.addMany(setDefaultColorMany(action.tracks), state);
  }),
  on(upsertTracks, (state, action) => {
    return trackAdapter.upsertMany(setDefaultColorMany(action.tracks), state);
  }),
  on(updateTrack, (state, action) => trackAdapter.updateOne(action.track, state)),
  on(updateTracks, (state, action) => trackAdapter.updateMany(action.tracks, state)),
  on(deleteTrack, (state, action) => trackAdapter.removeOne(action.id, state)),
  on(deleteTracks, (state, action) => trackAdapter.removeMany(action.ids, state)),
  on(clearTracks, state => trackAdapter.removeAll(state)),

  on(createTrack, (state, action) => {
    return trackAdapter.addOne(action.track, state);
  }),
  on(authLoggedOut, (state, action) => {
    return trackAdapter.removeAll(state);
  }),
  on(syncTrackProgress, (state, action) => {
    return state;
  })
);

export function tracksReducer(state: TrackState | undefined, action: Action) {
  return reducer(state, action);
}
