// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {AccountState} from '@spout/web-global/models';

export function getDefaultEntityConfig(
  entities: {isDefault: boolean; createdByUID: string}[],
  account: AccountState
): {isDefault: boolean; createdByUID: string} | null {
  return entities.reduce(
    (
      defaultEntity: {isDefault: boolean; createdByUID: string} | null,
      entity: {isDefault: boolean; createdByUID: string} | null
    ) => {
      if (!defaultEntity && entity && entity.isDefault && entity.createdByUID === account.email) {
        return entity;
      }
      return defaultEntity;
    },
    null
  );
}
