import {Role} from './role';

export interface Musician {
  role: Role;
  roleTitle?: string;
  roleType?: string;

  // Must match stageName in AccountState
  stageName: string;

  // Must match email in AccountState
  email: string;

  uid: string;
}
