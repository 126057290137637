import {allValuesMatch, IAllValuesMatchConfig, isDefined} from '@uiux/fn';

export function allValuesMatchNoTimeStamps(src: any, tar: any, config?: IAllValuesMatchConfig): boolean {
  if (isDefined(src) && isDefined(tar)) {
    const includeArrays: boolean = config && config.includeArrays ? config.includeArrays : false;
    let excludeKeys: string[] = config && config.excludeKeys ? config.excludeKeys : [];

    excludeKeys = excludeKeys.concat(['createdAt', 'updatedAt']);

    const _config = {
      includeArrays,
      excludeKeys
    };

    return allValuesMatch(src, tar, _config);
  } else {
    return false;
  }
}
