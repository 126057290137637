import {createAction, props} from '@ngrx/store';
import {FileType} from '@spout/any-shared/models';

export const transportStartRecordingEffect = createAction('[TransportControl] Start Recording Effect');
export const transportStartRecording = createAction('[TransportControl] Start Recording');

export const transportStopRecording = createAction(
  '[TransportControl] Stop Recording Effect',
  props<{fileExportType: FileType}>()
);

export const transportStartPlayingEffect = createAction('[TransportControl] Start Playing Effect');
export const transportStartPlaying = createAction('[TransportControl] Start Playing');

export const masterStopEffect = createAction('[TransportControl] Stop Effect');
export const transportStop = createAction('[TransportControl] Stop');

// SKIP_PREVIOUS
export const masterFullRewindEffect = createAction('[TransportControl] Full Rewind Effect');

// SKIP_NEXT
export const masterFullForwardEffect = createAction('[TransportControl] Full Forward Effect');

export const masterFastRewindEffect = createAction('[TransportControl] Fast Rewind Effect');
export const masterFastForwardEffect = createAction('[TransportControl] Fast Forward Effect');

export const masterIsSavingRecordedFile = createAction(
  '[TransportControl] is processing',
  props<{isSavingRecordedFileTrackId: string | null}>()
);

export const masterVolume = createAction('[TransportControl] Master Volume', props<{volume: number}>());

export const playMixOnRecord = createAction(
  '[TransportControl] Play Mix On Record',
  props<{playMixOnRecord: boolean}>()
);

export const playMixOnRecordEffect = createAction(
  '[TransportControl] Play Mix On Record Effect',
  props<{playMixOnRecord: boolean}>()
);

export const playMetronomeOnRecordEffect = createAction(
  '[TransportControl] Play Metronome On Record Effect',
  props<{playMetronomeOnRecord: boolean}>()
);

export const playMetronomeOnRecord = createAction(
  '[TransportControl] Play Metronome On Record',
  props<{playMetronomeOnRecord: boolean}>()
);

export const playMetronomeEffect = createAction('[TransportControl] Play Metronome');
export const stopMetronomeEffect = createAction('[TransportControl] Stop Metronome');

// export const applyMixSelectedToPlay = createAction(
//   '[TransportControl] Apply Mix Selected To Play',
//   props<{ applyMixSelectedToPlay: boolean }>()
// );
