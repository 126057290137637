import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {TrackMix} from '@spout/any-shared/models';

export const loadTrackMixs = createAction('[TrackMix/API] Load TrackMixs', props<{trackMixs: TrackMix[]}>());

export const addTrackMix = createAction('[TrackMix/API] Add TrackMix', props<{trackMix: TrackMix}>());

export const upsertTrackMix = createAction('[TrackMix/API] Upsert TrackMix', props<{trackMix: TrackMix}>());

export const addTrackMixs = createAction('[TrackMix/API] Add TrackMixs', props<{trackMixs: TrackMix[]}>());

export const upsertTrackMixs = createAction('[TrackMix/API] Upsert TrackMixs', props<{trackMixs: TrackMix[]}>());

export const updateTrackMix = createAction('[TrackMix/API] Update TrackMix', props<{trackMix: Update<TrackMix>}>());

export const updateTrackMixs = createAction(
  '[TrackMix/API] Update TrackMixs',
  props<{trackMixs: Update<TrackMix>[]}>()
);

export const deleteTrackMix = createAction('[TrackMix/API] Delete TrackMix', props<{id: string}>());

export const deleteTrackMixs = createAction('[TrackMix/API] Delete TrackMixs', props<{ids: string[]}>());

export const clearTrackMixs = createAction('[TrackMix/API] Clear TrackMixs');
