/**
 * Generated by script tools/uglify-aw.js
 *
 */

export interface AudioWorkletFileMap {
  LATENCY_WORKLET_PROCESSOR: string;
  CONNECTOR_WORKLET_PROCESSOR: string;
  MERGER_WORKLET_PROCESSOR: string;
  SHARED_BUFFER_PLAYER_WORKLET_PROCESSOR: string;
  SHARED_BUFFER_RECORD_WORKLET_PROCESSOR: string;
}

export const AUDIO_WORKLET_MAP: AudioWorkletFileMap = {
  LATENCY_WORKLET_PROCESSOR: 'assets/js/audio-worklets/lib/latency/latency-worklet-processor.js',
  CONNECTOR_WORKLET_PROCESSOR: 'assets/js/audio-worklets/lib/connector-worklet-processor.js',
  MERGER_WORKLET_PROCESSOR: 'assets/js/audio-worklets/lib/merger-worklet-processor.js',
  SHARED_BUFFER_PLAYER_WORKLET_PROCESSOR: 'assets/js/audio-worklets/lib/shared-buffer-player-worklet-processor.js',
  SHARED_BUFFER_RECORD_WORKLET_PROCESSOR: 'assets/js/audio-worklets/lib/shared-buffer-record-worklet-processor.js'
};
