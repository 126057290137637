export interface MixMetrics {
  // same as Current MixEntity ID
  duration: number;
  // length: number;
  // maxY: number;
  // minY: number;
  minSampleRate: number;
  maxSampleRate: number;
  contextSampleRate: number;
  hasAudio: boolean;

  // duration if no audio is recorded
  // shows time in tracks for initial recording
  defaultDuration: number;
  playerIds: string[];
}

/**
 * Copied from
 * libs/web-shared/models/src/lib/store-models/audio-buffer-data.model.ts:21
 */
export interface WaveformTrackMetrics {
  length: number;
  trackDuration: number;
  channels: number;
  hasData: boolean;
  sample_rate: number;
  scale: number;
  seconds_per_pixel: number;
  pixels_per_second: number;
  bits: number;
}

export enum MIX_METRICS {
  BASE_TRACK_DURATION = 60
}

/**
 * Copied from
 * libs/web-shared/models/src/lib/store-models/audio-buffer-data.model.ts:21
 */
export interface WaveformSongMetrics {
  length: number;
  songDuration: number;
  channels: number;
  sample_rate: number;
  scale: number;
  seconds_per_pixel: number;
  pixels_per_second: number;
  bits: number;
}

export interface TrackReporting {
  trackId: string;

  // player worklet ids
  playerIds: string[];
  // Max duration of a track-audio
  trackDuration: number;
  // max length of track-audio data
  maxSampleRate: number;
  minSampleRate: number;
}
