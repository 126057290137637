import {validateOnboardingValues} from '@spout/web-global/fns';
import {AuthAccountProductStates, OnboardingState} from '@spout/web-global/models';
import {allValuesTruthy} from '@uiux/fn';

export function accountIsLoaded(state: AuthAccountProductStates): boolean {
  return (
    state.isLoaded !== undefined &&
    state.isLoaded &&
    allValuesTruthy(validateOnboardingValues(state.account)) &&
    state.auth.isLoggedIn &&
    state.auth.uid !== null
  );
}

export function accountIsLoadedForOnboarding(s: OnboardingState): boolean {
  return s.isLoaded !== undefined && s.isLoaded && !s.isOnboarded && s.isLoggedIn && s.uid !== null;
}
