import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {WarnDeleteSelectedTrack} from '@spout/web-global/data-access';
import {getTrackColorCSS} from '@spout/web-global/fns';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'spt-warn-is-master',
  templateUrl: './warn-is-master.component.html',
  styleUrls: ['./warn-is-master.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarnIsMasterComponent {
  selectedColor$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  @Input()
  set data(data: WarnDeleteSelectedTrack) {
    if (data && data.track !== null) {
      this.selectedColor$.next(getTrackColorCSS(data.track));
    }
  }

  @Output()
  ok: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
}
