import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveComponentModule} from '@ngrx/component';
import {LoadComponentModule} from '../../component-loader';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {HelpGainModule} from './content/help-gain/help-gain.module';
import {HelpNavHeaderModule} from './content/help-nav-header/help-nav-header.module';
import {HelpSelectMicrophoneModule} from './content/help-select-microphone/help-select-microphone.module';
import {HelpTocModule} from './content/help-toc.module';
import {HelpRightDrawerComponent} from './help-right-drawer.component';
import {SptComponentRefService} from './spt-component-ref.service';

@NgModule({
  declarations: [HelpRightDrawerComponent],
  exports: [HelpRightDrawerComponent],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    ReactiveComponentModule,
    LoadComponentModule,

    HelpTocModule,
    HelpNavHeaderModule,
    HelpSelectMicrophoneModule,
    HelpGainModule,

    MatButtonModule,
    MatIconModule,
    MatDividerModule
  ],
  providers: [SptComponentRefService]
})
export class HelpRightDrawerModule {}
