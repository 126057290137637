import {ThemePalette} from '@angular/material/core';
import {createFeatureSelector, createSelector, MemoizedSelector, select} from '@ngrx/store';
import {transformStorageToGoogle, transformStorageToMediaTrackConstraints} from '@spout/any-shared/fns';
import {
  appVersions,
  concatElectronBuildVersion,
  concatUiBuildVersion,
  CurrentIds,
  DeviceStorageState,
  ui
} from '@spout/any-shared/models';
import {createPassThroughFeatureSelector, createPassThroughSelector} from '@spout/web-global/fns';
import {deviceStorageFeatureKey, StudioAppState} from '@spout/web-global/models';
import {hasValue} from '@uiux/fn';
import {pipe} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {getFirebaseProjectId} from '../environment/environment.selectors';

export const selectDeviceStorageState = createFeatureSelector<DeviceStorageState>(deviceStorageFeatureKey);

export const selectDeviceStorageState_passThrough =
  createPassThroughFeatureSelector<DeviceStorageState>(deviceStorageFeatureKey);

export const getCacheDirectory = createSelector(
  selectDeviceStorageState,
  (state: DeviceStorageState) => state.cacheDirectory
);

export const selectExportDirectory = createSelector(
  selectDeviceStorageState,
  (state: DeviceStorageState) => state.exportDirectory
);

export const selectViewControlsOpen = createSelector(
  selectDeviceStorageState,
  (state: DeviceStorageState) => state.controlsViewOpen
);

export const selectMicrophoneGain = createSelector(selectDeviceStorageState, state => {
  return state.microphoneGain;
});

export const selectMemoizedRecordInputGain = () => {
  return createSelector(selectDeviceStorageState, state => {
    return state.microphoneGain;
  });
};

export const selectMemoizedRecordInputCompressorThreshold = () => {
  return createSelector(selectDeviceStorageState, state => {
    return state.recordInputCompressorThreshold;
  });
};

export const selectMemoizedRecordInputCompressorKnee = () => {
  return createSelector(selectDeviceStorageState, state => {
    return state.recordInputCompressorKnee;
  });
};

export const selectMemoizedRecordInputCompressorRatio = () => {
  return createSelector(selectDeviceStorageState, state => {
    return state.recordInputCompressorRatio;
  });
};

export const selectMemoizedRecordInputCompressorAttack = () => {
  return createSelector(selectDeviceStorageState, state => {
    return state.recordInputCompressorAttack;
  });
};

export const selectMemoizedRecordInputCompressorRelease = () => {
  return createSelector(selectDeviceStorageState, state => {
    return state.recordInputCompressorRelease;
  });
};

export const selectMemoizedExportMergeCompressorThreshold = () => {
  return createSelector(selectDeviceStorageState, state => {
    return state.exportMergeCompressorThreshold;
  });
};

export const selectMemoizedExportMergeCompressorKnee = () => {
  return createSelector(selectDeviceStorageState, state => {
    return state.exportMergeCompressorKnee;
  });
};

export const selectMemoizedExportMergeCompressorRatio = () => {
  return createSelector(selectDeviceStorageState, state => {
    return state.exportMergeCompressorRatio;
  });
};

export const selectMemoizedExportMergeCompressorAttack = () => {
  return createSelector(selectDeviceStorageState, state => {
    return state.exportMergeCompressorAttack;
  });
};

export const selectMemoizedExportMergeCompressorRelease = () => {
  return createSelector(selectDeviceStorageState, state => {
    return state.exportMergeCompressorRelease;
  });
};

// Version of compiled electron for macString or pcString ( which ever is installed )
export const getCompiledAppVersion = createSelector(selectDeviceStorageState, state => state.version);

export const getPlatformName = createSelector(selectDeviceStorageState, (d: DeviceStorageState) => {
  let platformName = '';

  if (d.platform?.isIntelMac) {
    platformName = 'Mac Intel';
  } else if (d.platform?.isM1Mac) {
    platformName = 'Mac M1';
  } else {
    platformName = 'PC';
  }

  return platformName;
});

export const getAppVersionString = createSelector(selectDeviceStorageState, (d: DeviceStorageState) =>
  concatElectronBuildVersion(d.build)
);

export const appUiAndElectronVersionString = createSelector(
  selectDeviceStorageState,
  getPlatformName,
  getAppVersionString,
  getFirebaseProjectId,
  (d: DeviceStorageState, platformName: string, appVersionString: string, project: string) => {
    return `Release: ${appVersions.releaseType},
            Electron: ${appVersionString},
            UI: ${concatUiBuildVersion(ui)},
            Platform: ${platformName},
            SERVER: ${project}`;
  }
);

// CURRENT IDs CURRENT IDs CURRENT IDs CURRENT IDs
// CURRENT IDs CURRENT IDs CURRENT IDs CURRENT IDs
// CURRENT IDs CURRENT IDs CURRENT IDs CURRENT IDs
// CURRENT IDs CURRENT IDs CURRENT IDs CURRENT IDs

export const selectCurrentIDsFromStore = createSelector(selectDeviceStorageState, (state: DeviceStorageState) => {
  if (state.currentIds) {
    return state.currentIds;
  }

  return <CurrentIds>{
    currentProjectId: null,
    currentSongId: null,
    currentMixId: null,
    currentTrackId: null
  };
});

export const selectCurrentIDsFromStore_passThrough: MemoizedSelector<StudioAppState, CurrentIds> =
  createPassThroughSelector(selectDeviceStorageState_passThrough, (state: DeviceStorageState) => {
    if (state.currentIds) {
      return state.currentIds;
    }

    return <CurrentIds>{
      currentProjectId: null,
      currentSongId: null,
      currentMixId: null,
      currentTrackId: null
    };
  });

export const selectMediaDeviceInfo = createSelector(
  selectDeviceStorageState,
  (state: DeviceStorageState) => state.mediaDeviceInfo
);

export const selectMediaDeviceId = createSelector(
  selectDeviceStorageState,
  (state: DeviceStorageState): MediaDeviceInfo => state.mediaDeviceInfo
);

export const selectGoogMediaTrackConstraints = () =>
  createSelector(selectDeviceStorageState, (state: DeviceStorageState) => {
    return transformStorageToGoogle(state);
  });

export const selectMediaTrackConstraints = () =>
  createSelector(selectDeviceStorageState, (state: DeviceStorageState) => {
    return transformStorageToMediaTrackConstraints(state);
  });

export const selectMediaDeviceID$ = pipe(
  select(selectMediaDeviceId),
  filter((device: MediaDeviceInfo) => hasValue(device)),
  distinctUntilChanged<MediaDeviceInfo>()
);

export const selectLatency = createSelector(selectDeviceStorageState, (state: DeviceStorageState): number | null => {
  if (state.latency.detected) {
    return state.latency.detected + state.latency.adjusted;
  }

  return null;
});

export const selectLatencyIsSet = createSelector(selectLatency, (latency: number | null) => {
  return hasValue(latency);
});

export const selectLatencyIsSetColor = createSelector(selectLatencyIsSet, (hasLatencySet: boolean): ThemePalette => {
  if (hasLatencySet) {
    return 'primary';
  }

  return 'warn';
});
