import {createAction, props} from '@ngrx/store';
import {
  AudioFileMetaData,
  AudioFileMetaDataEntity,
  DeviceStorageState,
  MixEntity,
  ProjectEntity,
  SavedLatency,
  SongEntity,
  TrackEntity
} from '@spout/any-shared/models';

// export const upsertDeviceStorage = createAction(
//   '[DeviceStorage] Load DeviceStorage',
//   props<{ storage: Partial<DeviceStorageState> }>()
// );

export const upsertDeviceStorage = createAction(
  '[DeviceStorage] Save To DeviceStorage',
  props<{storage: Partial<DeviceStorageState>}>()
);

export const toggleControlsView = createAction('[DeviceStorage] Toggle Controls View');

export const setDeviceStoreCurrentIdsFromTrackAndMixEntity = createAction(
  '[DeviceStorage] Set Current Ids From Track and Mix',
  props<{
    track: Partial<TrackEntity>;
    mix: Partial<MixEntity>;
  }>()
);

export const saveDeviceStoreDirectoryPathEffect = createAction(
  '[Project/DeviceStorage] Save Project Directory Path',
  props<{cacheDirectory: string}>()
);

export const getExportDirectoryPathEffect = createAction(
  '[Project/DeviceStorage] Export Directory Path',
  props<{cacheDirectory: string}>()
);

export const saveDeviceStoreOrderedTrackMixIds = createAction(
  '[Mix/DeviceStorage] Save Mix Order',
  props<{orderedIds: string[]}>()
);

export const moveWorkspaceEffect = createAction('[File/DeviceStorage] Move Workspace');
export const getExportDirectoryEffect = createAction('[File/DeviceStorage] Get Export Directory');

export const microphoneGain = createAction('[Microphone/DeviceStorage] Gain', props<{microphoneGain: number}>());

export const deleteAudioFilesFromDevice = createAction(
  '[Microphone/DeviceStorage] Delete Audio Files From Device',
  props<{ids: string[]}>()
);

export const saveLatency = createAction('[Latency/DeviceStorage] Save', props<{latency: SavedLatency}>());

// RECORD INPUI COMPRESSOR
export const recordInputCompressorThreshold = createAction(
  '[Compressor/DeviceStorage] Record Input Threshold',
  props<{compressorThreshold: number}>()
);
export const recordInputCompressorKnee = createAction(
  '[Compressor/DeviceStorage] Record Input Knee',
  props<{compressorKnee: number}>()
);
export const recordInputCompressorRatio = createAction(
  '[Compressor/DeviceStorage] Record Input Ratio',
  props<{compressorRatio: number}>()
);
export const recordInputCompressorAttack = createAction(
  '[Compressor/DeviceStorage] Record Input Attack',
  props<{compressorAttack: number}>()
);
export const recordInputCompressorRelease = createAction(
  '[Compressor/DeviceStorage] Record Input Release',
  props<{compressorRelease: number}>()
);

// EXPORT MERGE COMPRESSOR
export const exportMergeCompressorThreshold = createAction(
  '[Compressor/DeviceStorage] Export Merge Threshold',
  props<{compressorThreshold: number}>()
);
export const exportMergeCompressorKnee = createAction(
  '[Compressor/DeviceStorage] Export Merge Knee',
  props<{compressorKnee: number}>()
);
export const exportMergeCompressorRatio = createAction(
  '[Compressor/DeviceStorage] Export Merge Ratio',
  props<{compressorRatio: number}>()
);
export const exportMergeCompressorAttack = createAction(
  '[Compressor/DeviceStorage] Export Merge Attack',
  props<{compressorAttack: number}>()
);
export const exportMergeCompressorRelease = createAction(
  '[Compressor/DeviceStorage] Export Merge Release',
  props<{compressorRelease: number}>()
);
