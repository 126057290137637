import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpGettingStartedReviewingContentComponent} from './help-getting-started-reviewing-content/help-getting-started-reviewing-content.component';
import {HelpGettingStartedReviewingComponent} from './help-getting-started-reviewing.component';

@NgModule({
  declarations: [HelpGettingStartedReviewingComponent, HelpGettingStartedReviewingContentComponent],
  exports: [HelpGettingStartedReviewingComponent, HelpGettingStartedReviewingContentComponent],
  imports: [CommonModule, HelpContentModule, HelpNavBackModule, MatIconModule, MatTooltipModule, MatButtonModule]
})
export class HelpGettingStartedReviewingModule {}
