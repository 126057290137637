import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'spt-help-workspace',
  templateUrl: './help-workspace.component.html',
  styleUrls: ['./help-workspace.component.scss']
})
export class HelpWorkspaceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
