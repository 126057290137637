import {Action, createReducer, on} from '@ngrx/store';
import {
  masterIsSavingRecordedFile,
  playMetronomeOnRecord,
  playMixOnRecord,
  transportStartPlaying,
  transportStartRecording,
  transportStop
} from '@spout/web-global/actions';
import {transportInitialState, TransportState} from '@spout/web-global/models';

const reducer = createReducer(
  transportInitialState,
  on(transportStartPlaying, (state, action) => {
    return Object.assign({}, state, {
      isPlayingMix: true
    });
  }),
  on(transportStartRecording, (state, action) => {
    return Object.assign({}, state, {
      isRecording: true
    });
  }),
  // on(transportStopRecording, (state, action) => {
  //   return Object.assign({}, state, {
  //     isPlayingMix: false,
  //     isRecording: false,
  //   });
  // }),
  on(transportStop, (state, action) => {
    return Object.assign({}, state, {
      isPlayingMix: false,
      isRecording: false
    });
  }),
  on(masterIsSavingRecordedFile, (state, action) => {
    return Object.assign({}, state, <TransportState>{
      isSavingRecordedFileTrackId: action.isSavingRecordedFileTrackId
    });
  }),
  on(playMixOnRecord, (state, action) => {
    return Object.assign({}, state, <TransportState>{
      playMixOnRecord: action.playMixOnRecord
    });
  }),
  on(playMetronomeOnRecord, (state, action) => {
    return Object.assign({}, state, <TransportState>{
      playMetronomeOnRecord: action.playMetronomeOnRecord
    });
  })
  // on(playMetronome, (state, action) => {
  //   return Object.assign({}, state, <MasterControlState>{
  //     isPlayingMetronome: action.playMetronome,
  //   });
  // }),
  // on(applyMixSelectedToPlay, (state, action) => {
  //   return Object.assign({}, state, <MasterControlState>{
  //     applyMixSelectedToPlay: action.applyMixSelectedToPlay,
  //   });
  // })
);

export function sptTransportReducer(state: TransportState | undefined, action: Action) {
  return reducer(state, action);
}
