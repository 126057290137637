import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ComponentDictItem} from './content/content.model';
import {HelpModel, StudioAppState} from '@spout/web-global/models';
import {getHelpView} from '@spout/web-global/selectors';
import {SptComponentRef} from '../../component-loader';
import {ReplaySubject} from 'rxjs';
import componentRefDict from './content/content-dictionary';

@Injectable({
  providedIn: 'root'
})
export class SptComponentRefService {
  componentRef$: ReplaySubject<SptComponentRef> = new ReplaySubject<SptComponentRef>(1);

  constructor(private store: Store<StudioAppState>) {
    this.store.pipe(select(getHelpView)).subscribe((ref: HelpModel) => {
      this._loadComponent(ref);
    });
  }

  private _loadComponent(ref: HelpModel) {
    if (componentRefDict.has(ref)) {
      this.componentRef$.next((<ComponentDictItem>componentRefDict.get(ref)).component);
    }
  }
}
