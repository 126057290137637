import {FieldValue} from 'firebase/firestore';
import {ColorConfig} from '../color-palettes';
import {TreeConfig} from './tree.model';
import {UserPermissions} from './user-permissions.model';

export interface TrackConfig {
  // channels?: number;
  color: ColorConfig;
  // is selected to be in final version of song

  projectId: string;
  songId: string;

  // Use for AudioFileEntity, AudioStorageData
  // fileId: string;
  shared: boolean;
  archived: boolean;

  recordTime?: FieldValue;
  uploadTime?: FieldValue;
}

export type TrackEntity = TrackConfig & TreeConfig & UserPermissions;

export interface TrackPlayList {
  masterTrackId: string;
  trackIds: string[];
}
