import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthErrorComponent} from './auth-error.component';
import {MatCardModule} from '@angular/material/card';
import {ReactiveComponentModule} from '@ngrx/component';

@NgModule({
  declarations: [AuthErrorComponent],
  imports: [CommonModule, MatCardModule, ReactiveComponentModule],
  exports: [AuthErrorComponent]
})
export class AuthErrorModule {}
