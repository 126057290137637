<div>
  <div>A Project is required save songs and record tracks.</div>

  <div class="push-top-20">
    Select <span class="help-bold-accent">Share Project</span> to share your project with other musicians in your
    project. You can change your project's share status any time.
  </div>

  <div class="push-top-20">Selecting <span class="help-bold">Save</span> will save your project in your account.</div>
</div>
