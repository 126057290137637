import {Action, createReducer, on} from '@ngrx/store';
import {
  addDialogQueue,
  addDialogQueues,
  clearDialogQueues,
  closeDialog,
  deleteDialogQueue,
  deleteDialogQueues,
  loadDialogQueues,
  openDialog,
  updateDialogQueue,
  updateDialogQueues,
  upsertDialogQueue,
  upsertDialogQueues
} from '@spout/web-global/actions';
import {
  DialogQueue,
  dialogQueueEntityAdapter,
  DialogQueueState,
  initialDialogQueueState
} from '@spout/web-global/models';

const reducer = createReducer(
  initialDialogQueueState,
  on(addDialogQueue, (state, action) => dialogQueueEntityAdapter.addOne(action.dialogQueue, state)),
  on(upsertDialogQueue, (state, action) => dialogQueueEntityAdapter.upsertOne(action.dialogQueue, state)),
  on(addDialogQueues, (state, action) => dialogQueueEntityAdapter.addMany(action.dialogQueues, state)),
  on(upsertDialogQueues, (state, action) => dialogQueueEntityAdapter.upsertMany(action.dialogQueues, state)),
  on(updateDialogQueue, (state, action) => dialogQueueEntityAdapter.updateOne(action.dialogQueue, state)),
  on(updateDialogQueues, (state, action) => dialogQueueEntityAdapter.updateMany(action.dialogQueues, state)),
  on(deleteDialogQueue, (state, action) => dialogQueueEntityAdapter.removeOne(action.id, state)),
  on(deleteDialogQueues, (state, action) => dialogQueueEntityAdapter.removeMany(action.ids, state)),
  on(loadDialogQueues, (state, action) => {
    return {
      ...dialogQueueEntityAdapter.setAll(action.dialogQueues, state),
      isLoaded: true
    };
  }),
  on(clearDialogQueues, state => dialogQueueEntityAdapter.removeAll(state)),
  on(openDialog, (state, action) => {
    const _state = {
      ...state,
      entities: {
        ...state.entities
      }
    };

    if (_state.entities[action.id]) {
      _state.entities[action.id] = {
        ...(<DialogQueue>_state.entities[action.id]),
        isOpen: true
      };
    }

    return _state;
  }),
  on(closeDialog, (state, action) => {
    const _state = {
      ...state,
      entities: {
        ...state.entities
      }
    };

    if (_state.entities[action.id]) {
      _state.entities[action.id] = {
        ...(<DialogQueue>_state.entities[action.id]),
        isOpen: false,
        destroy: action.destroy !== undefined && action.destroy && (<DialogQueue>_state.entities[action.id]).canDestroy
      };
    }

    return _state;
  })
);

export function dialogQueueReducer(state: DialogQueueState | undefined, action: Action) {
  return reducer(state, action);
}
