import {isPlainObject} from '@uiux/fn';

export function upsertCollectionBy<T>(collection: T[], insert: any, key: string): T[] {
  const found = collection.findIndex((item: any) => item[key] === insert[key]);

  if (found > -1) {
    if (isPlainObject(found) && isPlainObject(insert)) {
      collection.splice(found, 1, Object.assign(found, insert));
    } else {
      collection.splice(found, 1, insert);
    }
  } else {
    collection.push(insert);
  }

  return collection;
}
