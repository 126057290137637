/**
 * One Time Login ID
 * Saved to firestore
 * Copy to apps/firebase/functions/src/models.ts:5
 */
export interface OTLIDFirestore {
  otlid: string;
  jwtToken: string | null;
  uid: string | null;
}

const ONE_TIME_AUTH_CODES = 'otAuthCodes';

export function firestoreOtlidCollection(): string {
  return `${ONE_TIME_AUTH_CODES}`;
}

export function firestoreOtlidById(otlid: string): string {
  return `${ONE_TIME_AUTH_CODES}/${otlid}`;
}
