import {InjectionToken} from '@angular/core';
import {HelpModel} from '@spout/web-global/models';
import {SptComponentRef} from './content-dictionary';

export interface ContentModel {
  showBackHeader: boolean;
}

export interface ComponentDictItem {
  title: string;
  id: HelpModel;
  component: SptComponentRef;
}

export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');
