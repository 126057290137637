<div class="page-layout--wide">
  <div class="page-layout-content">
    <h1 class="MsoNormal">Privacy Statement</h1>

    <div class="WordSection1">
      <p class="MsoNormal">
        Spout Software: Music Creation in the Cloud! is committed to protecting your privacy and developing technology
        that gives you the most powerful and safe online experience. This Statement of Privacy applies to the Spout
        Software: Music Creation in the Cloud! Web site and governs data collection and usage. By using the Spout
        Software: Music Creation in the Cloud! website, you consent to the data practices described in this statement.
      </p>

      <p class="MsoNormal">Collection of your Personal Information</p>

      <p class="MsoNormal">
        Spout Software: Music Creation in the Cloud! collects personally identifiable information, such as your e-mail
        address, name, home or work address or telephone number. Spout Software: Music Creation in the Cloud! also
        collects anonymous demographic information, which is not unique to you, such as your ZIP code, age, gender,
        preferences, interests and favorites.
      </p>

      <p class="MsoNormal">
        There is also information about your computer hardware and software that is automatically collected by Spout
        Software: Music Creation in the
        <span class="GramE">Cloud!.</span> This information can include: your IP address, browser type, domain names,
        access times and referring Web site addresses. This information is used by Spout Software: Music Creation in the
        Cloud! for the operation of the service, to maintain quality of the service, and to provide general statistics
        regarding use of the Spout Software: Music Creation in the Cloud! Web site.
      </p>

      <p class="MsoNormal">
        Please keep in mind that if you directly disclose personally identifiable information or personally sensitive
        data through Spout Software: Music Creation in the Cloud! public message boards, this information may be
        collected and used by others. Note: Spout Software: Music Creation in the Cloud! does not read any of your
        private online communications.
      </p>

      <p class="MsoNormal">
        Spout Software: Music Creation in the Cloud! encourages you to review the privacy statements of Web sites you
        choose to link to from Spout Software: Music Creation in the Cloud! so that you can understand how those Web
        sites collect, use and share your information. Spout Software: Music Creation in the Cloud! is not responsible
        for the privacy statements or other content on Web sites outside of the Spout Software: Music Creation in the
        Cloud! and Spout Software: Music Creation in the Cloud! family of Web sites.
      </p>

      <p class="MsoNormal">Use of your Personal Information</p>

      <p class="MsoNormal">
        Spout Software: Music Creation in the Cloud! collects and uses your personal information to operate the Spout
        Software: Music Creation in the Cloud! Web site and deliver the services you have requested. Spout Software:
        Music Creation in the Cloud! also uses your personally identifiable information to inform you of other
        productEntities or services available from Spout Software: Music Creation in the Cloud! and its affiliates.
        Spout Software: Music Creation in the Cloud! may also contact you via surveys to conduct research about your
        opinion of current services or of potential new services that may be offered.
      </p>

      <p class="MsoNormal">
        Spout Software: Music Creation in the Cloud! does not sell, rent or lease its customer lists to third parties.
        Spout Software: Music Creation in the Cloud! may, from time to time, contact you on behalf of external business
        partners about a particular offering that may be of interest to you. In those cases, your unique personally
        identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. In
        addition, Spout Software: Music Creation in the Cloud! may share data with trusted partners to help us perform
        statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All
        such third parties are prohibited from using your personal information except to provide these services to Spout
        Software: Music Creation in the
        <span class="GramE">Cloud!,</span> and they are required to maintain the confidentiality of your information.
      </p>

      <p class="MsoNormal">
        Spout Software: Music Creation in the Cloud! does not use or disclose sensitive personal information, such as
        race, religion, or political affiliations, without your explicit consent.
      </p>

      <p class="MsoNormal">
        Spout Software: Music Creation in the Cloud! keeps track of the Web sites and pages our customers visit within
        Spout Software: Music Creation in the <span class="GramE">Cloud!,</span> in order to determine what Spout
        Software: Music Creation in the Cloud! services are the most popular. This data is used to deliver customized
        content and advertising within Spout Software: Music Creation in the Cloud! to customers whose behavior
        indicates that they are interested in a particular subject area.
      </p>

      <p class="MsoNormal">
        Spout Software: Music Creation in the Cloud! Web sites will disclose your personal information, without notice,
        only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to
        the edicts of the law or comply with legal process served on Spout Software: Music Creation in the Cloud! or the
        site; (b) protect and defend the rights or property of Spout Software: Music Creation in the Cloud!; and, (c)
        act under exigent circumstances to protect the personal safety of users of Spout Software: Music Creation in the
        Cloud!, or the public.
      </p>

      <p class="MsoNormal">Use of Cookies</p>

      <p class="MsoNormal">
        The Spout Software: Music Creation in the Cloud! Web site use &quot;cookies&quot; to help you personalize your
        online experience. A cookie is a text file that is placed on your hard disk by a Web page server. Cookies cannot
        be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only
        be read by a web server in the domain that issued the cookie to you.
      </p>

      <p class="MsoNormal">
        One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a
        cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize
        Spout Software: Music Creation in the Cloud!
        <span class="GramE">pages, or</span> register with Spout Software: Music Creation in the Cloud! site or
        services, a cookie helps Spout Software: Music Creation in the Cloud! to recall your specific information on
        subsequent visits. This simplifies the process of recording your personal information, such as billing
        addresses, shipping addresses, and so on. When you return to the same Spout Software: Music Creation in the
        Cloud! Web site, the information you previously provided can be retrieved, so you can easily use the Spout
        Software: Music Creation in the Cloud! features that you customized.
      </p>

      <p class="MsoNormal">
        You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can
        usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may
        not be able to fully experience the interactive features of the Spout Software: Music Creation in the Cloud!
        services or Web sites you visit.
      </p>

      <p class="MsoNormal">Security of your Personal Information</p>

      <p class="MsoNormal">
        Spout Software: Music Creation in the Cloud! secures your personal information from unauthorized access, use or
        disclosure. Spout Software: Music Creation in the Cloud! secures the personally identifiable information you
        provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or
        disclosure. When personal information (such as a credit card number) is transmitted to other Web sites, it is
        protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.
      </p>

      <p class="MsoNormal">Changes to this Statement</p>

      <p class="MsoNormal">
        Spout Software: Music Creation in the Cloud! will occasionally update this Statement of Privacy to reflect
        company and customer feedback. Spout Software: Music Creation in the Cloud! encourages you to periodically
        review this Statement to be informed of how Spout Software: Music Creation in the Cloud! is protecting your
        information.
      </p>

      <p class="MsoNormal">Contact Information</p>

      <p class="MsoNormal">
        Spout Software: Music Creation in the Cloud! welcomes your comments regarding this Statement of Privacy. If you
        believe that Spout Software: Music Creation in the Cloud! has not adhered to this Statement, please contact
        Spout Software: Music Creation in the Cloud! at host@change.me. We will use commercially reasonable efforts to
        promptly determine and remedy the problem.
      </p>

      <p class="MsoNormal">Terms of Use</p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        The Spout Software: Music Creation in the Cloud! Web Site is comprised of various Web pages operated by Spout
        Software: Music Creation in the
        <span class="GramE">Cloud!.</span>
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        The Spout Software: Music Creation in the Cloud! Web Site is offered to you conditioned on your acceptance
        without modification of the terms, conditions, and notices contained herein. Your use of the Spout Software:
        Music Creation in the Cloud! Web Site constitutes your agreement to all such terms, conditions, and notices.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        MODIFICATION OF THESE TERMS OF USE
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        Spout Software: Music Creation in the Cloud! reserves the right to change the terms, conditions, and notices
        under which the Spout Software: Music Creation in the Cloud! Web Site is offered, including but not limited to
        the charges associated with the use of the Spout Software: Music Creation in the Cloud! Web Site.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        LINKS TO THIRD PARTY SITES
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        The Spout Software: Music Creation in the Cloud! Web Site may contain links to other Web Sites (&quot;Linked
        Sites&quot;). The Linked Sites are not under the control of Spout Software: Music Creation in the Cloud! and
        Spout Software: Music Creation in the Cloud! is not responsible for the contents of any Linked Site, including
        without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Spout
        Software: Music Creation in the Cloud! is not responsible for webcasting or any other form of transmission
        received from any Linked Site. Spout Software: Music Creation in the Cloud! is providing these links to you only
        as a convenience, and the inclusion of any link does not imply endorsement by Spout Software: Music Creation in
        the Cloud! of the site or any association with its operators.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        NO UNLAWFUL OR PROHIBITED USE
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        As a condition of your use of the Spout Software: Music Creation in the Cloud! Web Site, you warrant to Spout
        Software: Music Creation in the Cloud! that you will not use the Spout Software: Music Creation in the Cloud!
        Web Site for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You may not use
        the Spout Software: Music Creation in the Cloud! Web Site in any manner which could damage, disable, overburden,
        or impair the Spout Software: Music Creation in the Cloud! Web Site or interfere with any other party's use and
        enjoyment of the Spout Software: Music Creation in the Cloud! Web Site. You may not obtain or attempt to obtain
        any materials or information through any means not intentionally made available or provided for through the
        Spout Software: Music Creation in the Cloud! Web Sites.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        USE OF COMMUNICATION SERVICES
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        The Spout Software: Music Creation in the Cloud! Web Site may contain bulletin board services, chat areas, news
        groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities
        designed to enable you to communicate with the public at large or with a group (collectively,
        &quot;Communication Services&quot;), you agree to use the Communication Services only to post, send and receive
        messages and material that are proper and related to the particular Communication Service. By way of example,
        and not as a limitation, you agree that when using a Communication Service, you will not:
      </p>

      <ul type="disc">
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and
          publicity) of others.
        </li>
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene,
          indecent or unlawful topic, name, material or information.
        </li>
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Upload files that contain software or other material protected by intellectual property laws (or by rights of
          privacy of publicity) unless you own or control the rights thereto or have received all necessary consents.
        </li>
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Upload files that contain viruses, corrupted files, or any other similar software or programs that may damage
          the operation of another's computer.
        </li>
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication
          Service specifically allows such messages.
        </li>
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Conduct or forward surveys, contests, pyramid schemes or chain letters.
        </li>
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Download any file posted by another user of a Communication Service that you know, or reasonably should know,
          cannot be legally distributed in such manner.
        </li>
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels
          of the origin or source of software or other material contained in a file that is uploaded.
        </li>
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Restrict or inhibit any other user from using and enjoying the Communication Services.
        </li>
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Violate any code of conduct or other guidelines which may be applicable for any particular Communication
          Service.
        </li>
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Harvest or otherwise collect information about others, including e-mail addresses, without their consent.
        </li>
        <li
          class="MsoNormal"
          style="
            mso-margin-top-alt: auto;
            mso-margin-bottom-alt: auto;
            line-height: normal;
            mso-list: l0 level1 lfo1;
            tab-stops: list 0.5in;
          "
        >
          Violate any applicable laws or regulations.
        </li>
      </ul>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        Spout Software: Music Creation in the Cloud! has no obligation to monitor the Communication Services. However,
        Spout Software: Music Creation in the Cloud! reserves the right to review materials posted to a Communication
        Service and to remove any materials in its sole discretion. Spout Software: Music Creation in the Cloud!
        reserves the right to terminate your access to any or all of the Communication Services at any time without
        notice for any reason whatsoever.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        Spout Software: Music Creation in the Cloud! reserves the right at all times to disclose any information as
        necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse
        to post or to remove any information or materials, in whole or in part, in Spout Software: Music Creation in the
        <span class="SpellE">Cloud!'s</span> sole discretion.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        Always use caution when giving out any personally identifying information about yourself or your children in any
        Communication Service. Spout Software: Music Creation in the Cloud! does not control or endorse the content,
        messages or information found in any Communication Service and, therefore, Spout Software: Music Creation in the
        Cloud! specifically disclaims any liability with regard to the Communication Services and any actions resulting
        from your participation in any Communication Service. Managers and hosts are not authorized Spout Software:
        Music Creation in the Cloud! spokespersons, and their views do not necessarily reflect those of Spout Software:
        Music Creation in the <span class="GramE">Cloud!.</span>
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or
        dissemination. You are responsible for adhering to such limitations if you download the materials.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        MATERIALS PROVIDED TO Spout Software: Music Creation in the Cloud! OR POSTED AT ANY Spout Software: Music
        Creation in the Cloud! WEB SITE
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        Spout Software: Music Creation in the Cloud! does not claim ownership of the materials you provide to Spout
        Software: Music Creation in the Cloud! (including feedback and suggestions) or post, upload, input or submit to
        any Spout Software: Music Creation in the Cloud! Web Site or its associated services (collectively
        &quot;Submissions&quot;). However, by posting, uploading, inputting, providing or submitting your Submission you
        are granting Spout Software: Music Creation in the Cloud!, its affiliated companies and necessary sublicensees
        permission to use your Submission in connection with the operation of their Internet businesses including,
        without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce,
        edit, translate and reformat your Submission; and to publish your name in connection with your Submission.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        No compensation will be paid with respect to the use of your Submission, as provided herein. Spout Software:
        Music Creation in the Cloud! is under no obligation to post or use any Submission you may provide and may remove
        any Submission at any time in Spout Software: Music Creation in the <span class="SpellE">Cloud!'s</span> sole
        discretion.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own
        or otherwise control all of the rights to your Submission as described in this section including, without
        limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        LIABILITY DISCLAIMER
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE Spout Software: Music
        Creation in the Cloud! WEB SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED
        TO THE INFORMATION HEREIN. Spout Software: Music Creation in the Cloud! AND/OR ITS SUPPLIERS MAY MAKE
        IMPROVEMENTS AND/OR CHANGES IN THE Spout Software: Music Creation in the Cloud! WEB SITE AT ANY TIME. ADVICE
        RECEIVED VIA THE Spout Software: Music Creation in the Cloud! WEB SITE SHOULD NOT BE RELIED UPON FOR PERSONAL,
        MEDICAL, LEGAL OR FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE
        TAILORED TO YOUR SITUATION.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        Spout Software: Music Creation in the Cloud! AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY,
        RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
        GRAPHICS CONTAINED ON THE Spout Software: Music Creation in the Cloud! WEB SITE FOR ANY PURPOSE. TO THE MAXIMUM
        EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE
        PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OR CONDITION OF ANY KIND. Spout Software: Music Creation in the
        Cloud! AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
        SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL Spout Software: Music Creation in the
        Cloud! AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
        DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS,
        ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE Spout Software: Music Creation in the
        Cloud! WEB SITE, WITH THE DELAY OR INABILITY TO USE THE Spout Software: Music Creation in the Cloud! WEB SITE OR
        RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
        SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE Spout Software: Music Creation in the Cloud! WEB SITE, OR
        OTHERWISE ARISING OUT OF THE USE OF THE Spout Software: Music Creation in the Cloud! WEB SITE, WHETHER BASED ON
        CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF Spout Software: Music Creation in the Cloud!
        OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT
        ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY
        NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE Spout Software: Music Creation in the Cloud!
        WEB SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE Spout
        Software: Music Creation in the Cloud! WEB SITE.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        SERVICE <span class="GramE">CONTACT :</span> host@change.me
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        TERMINATION/ACCESS RESTRICTION
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        Spout Software: Music Creation in the Cloud! reserves the right, in its sole discretion, to terminate your
        access to the Spout Software: Music Creation in the Cloud! Web Site and the related services or any portion
        thereof at any time, without notice. GENERAL To the maximum extent permitted by law, this agreement is governed
        by the laws of the State of Washington, U.S.A. and you hereby consent to the exclusive jurisdiction and venue of
        courts in San Mateo County, California, U.S.A. in all disputes arising out of or relating to the use of the
        Spout Software: Music Creation in the Cloud! Web Site. Use of the Spout Software: Music Creation in the Cloud!
        Web Site is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and
        conditions, including without limitation this paragraph. You agree that no joint venture, partnership,
        employment, or agency relationship exists between you and Spout Software: Music Creation in the Cloud! as a
        result of this agreement or use of the Spout Software: Music Creation in the Cloud! Web Site. Spout Software:
        Music Creation in the
        <span class="SpellE">Cloud!'s</span> performance of this agreement is subject to existing laws and legal
        process, and nothing contained in this agreement is in derogation of Spout Software: Music Creation in the
        <span class="SpellE">Cloud!'s</span> right to comply with governmental, court and law enforcement requests or
        requirements relating to your use of the Spout Software: Music Creation in the Cloud! Web Site or information
        provided to or gathered by Spout Software: Music Creation in the Cloud! with respect to such use. If any part of
        this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not
        limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or
        unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches
        the intent of the original provision and the remainder of the agreement shall continue in effect. Unless
        otherwise specified herein, this agreement constitutes the entire agreement between the user and Spout Software:
        Music Creation in the Cloud! with respect to the Spout Software: Music Creation in the Cloud! Web Site and it
        supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written,
        between the user and Spout Software: Music Creation in the Cloud! with respect to the Spout Software: Music
        Creation in the Cloud! Web Site. A printed version of this agreement and of any notice given in electronic form
        shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the
        same extent an d subject to the same conditions as other business documents and records originally generated and
        maintained in printed form. It is the express wish to the parties that this agreement and all related documents
        be drawn up in English.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        COPYRIGHT AND TRADEMARK NOTICES:
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        All contents of the Spout Software: Music Creation in the Cloud! Web Site are: Copyright 2013 by Spout Software
        and/or its suppliers. All rights reserved.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        TRADEMARKS
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        The names of actual companies and productEntities mentioned herein may be the trademarks of their respective
        owners.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        The example companies, organizations, productEntities, people and events depicted herein are fictitious. No
        association with any real company, organization, product, person, or event is intended or should be inferred.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        Any rights not expressly granted herein are reserved.
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        NOTICES AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT
      </p>

      <p class="MsoNormal" style="mso-margin-top-alt: auto; mso-margin-bottom-alt: auto; line-height: normal">
        Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of claimed copyright infringement
        under United States copyright law should be sent to Service Provider's Designated Agent. ALL INQUIRIES NOT
        RELEVANT TO THE FOLLOWING PROCEDURE WILL RECEIVE NO RESPONSE. See Notice and Procedure for Making Claims of
        Copyright Infringement.
      </p>
    </div>
  </div>
</div>
