import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WINDOW_PROVIDERS} from '@spout/web-global/utils';
import {SptAExternalComponent} from './spt-a-external.component';

@NgModule({
  declarations: [SptAExternalComponent],
  exports: [SptAExternalComponent],
  imports: [CommonModule],
  providers: [WINDOW_PROVIDERS]
})
export class SptAExternalModule {}
