import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {GlobalTableConfig, GlobalTableState, GlobalTableStateService} from './global-table-state.service';

export interface TableRowDict {
  [rowId: string]: boolean;
}

export interface TableState {
  rowsExpanded: TableRowDict;
  config: GlobalTableConfig;
}

@Injectable()
export class TableStateStore extends ComponentStore<TableState> {
  readonly selectIsAllExpanded$ = this.globalTableStateService.select((state: GlobalTableState) => {
    if (state[state['config'].id]) {
      return state[state['config'].id].allExpanded;
    }

    return state['config'].allExpanded;
  });

  readonly selectNotAllExpanded$ = this.globalTableStateService.select((state: GlobalTableState) => {
    if (state[state['config'].id]) {
      return !state[state['config'].id].allExpanded;
    }

    return !state['config'].allExpanded;
  });

  constructor(private globalTableStateService: GlobalTableStateService) {
    super({
      rowsExpanded: {},
      config: {
        id: '',
        allExpanded: false
      }
    });
  }

  upsertTableId(config: GlobalTableConfig) {
    this.updater((state: TableState) => {
      return {
        ...state,
        config
      };
    });

    this.globalTableStateService.upsertTableId(config);
  }

  init(ids: string[], allExpanded: boolean = true) {
    this.setState(state => {
      const rowsExpanded: TableRowDict = ids.reduce((a: TableRowDict, i: string) => {
        a[i] = allExpanded;

        return a;
      }, {});

      return {
        ...state,
        allExpanded,
        rowsExpanded: {
          ...state.rowsExpanded,
          ...rowsExpanded
        }
      };
    });
  }

  /**
   * TODO change to toggleRow
   * @param id
   */
  toggleRow(id: string) {
    this.setState((state: TableState) => {
      state.rowsExpanded[id] = !state.rowsExpanded[id];
      return state;
    });
  }

  expandAll() {
    this.select((state: TableState) => {
      if (!(state['config'].id.length > 0)) {
        throw Error('Global Table ID not defined');
      }

      this.globalTableStateService.expandAll(state.config.id);

      this.updater((state: TableState) => {
        Object.keys(state.rowsExpanded).forEach((key: string) => {
          state.rowsExpanded[key] = true;
        });
        return state;
      });
    });
  }

  collapseAll() {
    this.select((state: TableState) => {
      if (!state['config'].id) {
        throw Error('Global Table ID not defined');
      }

      this.globalTableStateService.collapseAll(state['config'].id);

      this.updater((state: TableState) => {
        Object.keys(state.rowsExpanded).forEach((key: string) => {
          state.rowsExpanded[key] = false;
        });
        return state;
      });
    });
  }

  /**
   * TODO change to getRowExpanded
   * @param id
   */
  getExpanded(id: string): Observable<boolean> {
    // console.log(id);

    return this.select((state: TableState) => {
      return state.rowsExpanded[id];
    }).pipe(
      map((row: boolean) => {
        if (row !== undefined && row !== null) {
          return row;
        }

        return false;
      })
      // tap(( isExpanded: boolean ) => {
      //   console.log(id, isExpanded);
      // }),
    );
  }

  /**
   * TODO change to getRowCollapsed
   * @param id
   */
  getCollapsed(id: string): Observable<boolean> {
    return this.select((state: TableState) => {
      return state.rowsExpanded[id];
    }).pipe(
      map((row: boolean) => {
        if (row !== undefined && row !== null) {
          return !row;
        }

        return true;
      })
    );
  }

  toggleAll() {
    const that = this;

    this.select((state: TableState) => {
      if (!state['config'].id) {
        throw Error('Global Table ID not defined');
      }

      this.selectIsAllExpanded$.pipe(take(1)).subscribe((allExpanded: boolean) => {
        if (allExpanded) {
          that.globalTableStateService.collapseAll(state['config'].id);
        } else {
          that.globalTableStateService.expandAll(state['config'].id);
        }

        this.setState((_state: TableState) => {
          const state = {
            ..._state
          };

          if (allExpanded) {
            Object.keys(state.rowsExpanded).forEach((key: string) => {
              state.rowsExpanded[key] = false;
            });
          } else {
            Object.keys(state.rowsExpanded).forEach((key: string) => {
              state.rowsExpanded[key] = true;
            });
          }

          return state;
        });
      });
    });
  }
}
