/**
 * @license
 * Copyright UIUX Engineering All Rights Reserved.
 */

import {hasValue, isPlainObject} from '@uiux/fn';

/**
 * Only merge a value from a source object to the target object if it has value
 * @param target
 * @param source
 */
export function mergePropsIfSourceHasValue(target: any, source: any): any {
  if (hasValue(source) && !isPlainObject(source)) {
    return target;
  }

  if (!hasValue(target)) {
    target = {};
  }

  const _target = JSON.parse(JSON.stringify(target));

  const sourceKeys: string[] = Object.keys(source);

  // Walk over keys of source object
  for (let i = 0; i < sourceKeys.length; i++) {
    const key = sourceKeys[i];

    // Only merge source keys that are NOT null or undefined
    if (hasValue(source[key])) {
      // if target and source key is an object, recurse
      if (isPlainObject(_target[key]) && isPlainObject(source[key])) {
        // Don't pass encapsulated context of container function
        _target[key] = mergePropsIfSourceHasValue.apply(null, [_target[key], source[key]]);
      } else {
        // Should be assigning scalar values at this point
        _target[key] = source[key];
      }
    }
  }

  return _target;
}
