import {HttpClient} from '@angular/common/http';
import {NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {Musician, ProjectEntity, TimeStamp} from '@spout/any-shared/models';
import {upsertWebsocketRegistry} from '@spout/web-global/actions';
import {firestoreProjectByIdPath} from '@spout/web-global/fns';
import {
  AuthAccountStates,
  AuthAccountStatesConnect,
  ProjectType,
  SptFirebaseConnectionService
} from '@spout/web-global/models';
import {selectAuthAccountConnect$} from '@spout/web-global/selectors';
import {clone} from '@uiux/fn';

import {DocumentData, DocumentSnapshot, onSnapshot} from 'firebase/firestore';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {SptAudioService} from '../audio/spt-audio.service';
import {Exists, removeTimestampCTorFromDocumentSnapshot, SptFirestoreService} from '../firestore';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {QueryAudioFileMetaDataService} from './query-services/query-audio-file-meta-data.service';
import {QueryChatsService} from './query-services/query-chats.service';
import {QueryMixesService} from './query-services/query-mixes.service';
import {QuerySongsService} from './query-services/query-songs.service';
import {QueryTrackMixesService} from './query-services/query-track-mixes.service';
import {QueryTracksService} from './query-services/query-tracks.service';

export class ProjectNode implements SptFirebaseConnectionService<ProjectEntity> {
  config$: BehaviorSubject<ProjectEntity | null> = new BehaviorSubject<ProjectEntity | null>(null);
  memberUIDs$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  members$: BehaviorSubject<{
    [emailAsKey: string]: Musician;
  }> = new BehaviorSubject({});
  memberList$: BehaviorSubject<Musician[]> = new BehaviorSubject<Musician[]>([]);
  owners$: BehaviorSubject<Musician[]> = new BehaviorSubject<Musician[]>([]);
  // workspace$: BehaviorSubject<ProjectWorkspace> = new BehaviorSubject(null);

  type = ProjectType.Project;
  createdBy: Musician;
  id = '';
  name = '';
  uuid = '';
  description = '';
  updatedAt: TimeStamp = {nanoseconds: 0, seconds: 0};
  updatedAtSeconds = 0;
  private _onDestroy$: Subject<boolean> = new Subject();

  // database reference to song
  querySongService: QuerySongsService;
  queryMixService: QueryMixesService;
  queryTracksService: QueryTracksService;
  queryAudioFileMetaDataService: QueryAudioFileMetaDataService;
  queryChatsService: QueryChatsService;
  queryTrackMixesService: QueryTrackMixesService;

  private valueChangesSub: (() => void) | undefined;

  constructor(
    public config: ProjectEntity,
    private sptFirestore: SptFirestoreService,
    private sptMediaRecorderService: SptAudioService,
    private httpClient: HttpClient,
    private dss: DynamicStoreService,
    private store: Store<AuthAccountStatesConnect>,
    private zone: NgZone
  ) {
    const that = this;
    // console.log('init ', this.config.name);

    this.querySongService = new QuerySongsService(this.sptFirestore, this.store, this.config, this.zone);
    this.queryMixService = new QueryMixesService(this.sptFirestore, this.store, this.config, this.zone);
    this.queryTracksService = new QueryTracksService(this.sptFirestore, this.store, this.config, this.zone);
    this.queryAudioFileMetaDataService = new QueryAudioFileMetaDataService(
      this.sptFirestore,
      this.store,
      this.config,
      this.zone
    );
    this.queryChatsService = new QueryChatsService(this.sptFirestore, this.store, this.config, this.zone);
    this.queryTrackMixesService = new QueryTrackMixesService(this.sptFirestore, this.store, this.config, this.zone);

    // populated property observables
    this.sendUpdate(config);

    this.createdBy = this.config.createdBy;

    // PROJECT
    // PROJECT
    // PROJECT

    that.zone.run(() => {
      this.store.dispatch(
        upsertWebsocketRegistry({
          id: this.config.id
        })
      );
    });

    this.store.pipe(selectAuthAccountConnect$).subscribe(s => {
      if (s.doConnect) {
        this.onConnect.call(this, s.user);
      } else {
        this.onDisconnect.call(this, s.user);
      }
    });
  }

  onConnect(user: AuthAccountStates): void {
    const that = this;

    this.sptFirestore.setWebSocketConnected(this.config.id);

    if (!this.valueChangesSub) {
      this.valueChangesSub = onSnapshot(
        this.sptFirestore.docRef(firestoreProjectByIdPath(this.config)),
        (_doc: DocumentSnapshot<DocumentData>) => {
          if (_doc.exists()) {
            this.sendUpdate(removeTimestampCTorFromDocumentSnapshot(_doc));
          }
        }
      );

      this.querySongService.onConnect(user);
      this.queryMixService.onConnect(user);
      this.queryTracksService.onConnect(user);
      this.queryAudioFileMetaDataService.onConnect(user);
      this.queryChatsService.onConnect(user);
      this.queryTrackMixesService.onConnect(user);
    }
  }

  onDisconnect(user?: AuthAccountStates): void {
    // console.log('onDisconnect');

    this._onDestroy$.next(true);

    // PROJECT
    if (this.valueChangesSub) {
      this.valueChangesSub();
    }

    this.querySongService.onDisconnect();
    this.querySongService.onDisconnect();
    this.queryTracksService.onDisconnect();
    this.queryAudioFileMetaDataService.onDisconnect();
    this.queryChatsService.onDisconnect();
    this.queryTrackMixesService.onDisconnect();

    this.sptFirestore.setWebSocketDisconnected(this.config.id);
  }

  updateConfig(projectConfig: ProjectEntity) {
    this.config = clone(projectConfig);
    this.sendUpdate(projectConfig);
  }

  update(projectConfig: ProjectEntity): Observable<ProjectEntity> {
    this.config = Object.assign(this.config, projectConfig);
    return this.sptFirestore.update<ProjectEntity>(firestoreProjectByIdPath(this.config), projectConfig).pipe(
      map((r: Exists<ProjectEntity>) => {
        return r.data;
      })
    );
  }

  /**
   * Populate property observables
   * @param projectConfig
   */
  private sendUpdate(projectConfig: ProjectEntity): void {
    // console.log('project node sendUpdate', projectConfig);
    if (projectConfig.name) {
      this.name = projectConfig.name;
    }
    this.id = projectConfig.id;
    if (projectConfig.description) {
      this.description = projectConfig.description;
    }
    if (projectConfig.memberUIDs) {
      this.memberUIDs$.next(projectConfig.memberUIDs);
    }
    this.memberList$.next(Object.values(projectConfig.members));
    this.members$.next(projectConfig.members);
    this.owners$.next(Object.values(projectConfig.owners));

    if (projectConfig.updatedAt && projectConfig.updatedAt.seconds) {
      this.updatedAt = projectConfig.updatedAt;
      this.updatedAtSeconds = projectConfig.updatedAt.seconds;
      this.config$.next(projectConfig);
    }
  }
}
