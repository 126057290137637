/**
 * 'foo bar' => 'Foo bar'
 * https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
 * @param str
 */
export function initialCase(str: string): string {
  if (str && str.length) {
    return str.replace(/./, x => x.toUpperCase());
  } else {
    return '';
  }
}
