import {
  AudioFileMetaDataEntity,
  MixEntity,
  SptSystemInformation,
  TrackEntity,
  TrackMix
} from '@spout/any-shared/models';
import {ParsedRecordExport} from '@spout/web-global/models';

export interface FileWithId {
  file: File;
  id: string;
  name: string;
}

export interface ImportFileMeta {
  file: FileWithId;
  meta: {
    trackEntity: TrackEntity;
    audioFileMetaDataEntity: AudioFileMetaDataEntity;
    mixes: MixEntity[];
    trackMixes: TrackMix[];
  };
  // desktopInformation: SptSystemInformation;
  parsedExport: ParsedRecordExport | null;
}

export interface ImportFileMetaDictionary {
  [trackId: string]: ImportFileMeta;
}

export interface CreateParsedExportsInput {
  importFileMetaDictionary: ImportFileMetaDictionary;
  mergedMix: MixEntity;
}

export const IMPORT_PROGRESS = {
  CREATE_TRACK_DONE: 10,
  MAX_UPLOAD_PROGRESS_DONE: 70,
  PROCESSING_TRACK: 80,
  SAVING_TO_FILE_SYSTEM_DONE: 90, // PROCESSING
  UPDATE_META_FILE_DONE: 100
};
