/**
 * Role Titles
 */
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {Musician, Role, PROJECT_ROLE} from '@spout/any-shared/models';
import {AccountAlgolia, AccountState} from './store-models/account.model';

export const RoleNames: {[key: string]: string} = {
  [PROJECT_ROLE.Author]: 'Author',
  [PROJECT_ROLE.Owner]: 'Owner',
  [PROJECT_ROLE.Collaborator]: 'Collaborator',
  [PROJECT_ROLE.ReadOnly]: 'Reviewer',
  [PROJECT_ROLE.Anonymous]: 'Anonymous',
  [PROJECT_ROLE.Reviewer]: 'Reviewer'
};

export function getRoleTitle(type: string): string {
  return RoleNames[type];
}

/**
 * Role Definitions
 * Roles are related to a project
 */
export const RoleDefs: {[key: string]: string} = {
  [PROJECT_ROLE.Author]: 'The creator and recorder of a track-audio.',
  [PROJECT_ROLE.Owner]: 'Has collaborator privileges and can add and remove users.',
  [PROJECT_ROLE.Collaborator]: 'Add, record, play, chat on tracks.',
  [PROJECT_ROLE.ReadOnly]: 'Play tracks and read chat only.',
  [PROJECT_ROLE.Anonymous]: 'You can only view public accounts and files.',
  [PROJECT_ROLE.Reviewer]: 'You can create mixes, annotate, and chat in a project.'
};

export function getRoleDef(type: string): string {
  return RoleDefs[type];
}

export interface ProjectTypeName {
  name: string;
  type: PROJECT_ROLE;
}

export const projectRoleTypes: ProjectTypeName[] = [
  {
    name: getRoleTitle(PROJECT_ROLE.Owner),
    type: PROJECT_ROLE.Owner
  },
  {
    name: getRoleTitle(PROJECT_ROLE.Collaborator),
    type: PROJECT_ROLE.Collaborator
  },
  {
    name: getRoleTitle(PROJECT_ROLE.Reviewer),
    type: PROJECT_ROLE.Reviewer
  }
];

/**
 * Returns role type
 * @param role
 */
export function getProjectRoleTitle(role: Role): string {
  return projectRoleTypes.reduce((roleTitle: string, key: ProjectTypeName) => {
    if (!roleTitle.length && role[key.type]) {
      roleTitle = RoleNames[key.type];
    }
    return roleTitle;
  }, '');
}

export function getProjectRoleType(role: Role): string {
  return projectRoleTypes.reduce((roleType: string, key: ProjectTypeName) => {
    if (!roleType.length && role[key.type]) {
      roleType = key.type;
    }
    return roleType;
  }, '');
}

export function createAuthorRole(): Role {
  return {
    [PROJECT_ROLE.Author]: true,
    [PROJECT_ROLE.Owner]: true,
    [PROJECT_ROLE.Collaborator]: false,
    [PROJECT_ROLE.ReadOnly]: false,
    [PROJECT_ROLE.Anonymous]: false,
    [PROJECT_ROLE.Reviewer]: false
  };
}

export function createOwnerRole(): Role {
  return {
    [PROJECT_ROLE.Author]: false,
    [PROJECT_ROLE.Owner]: true,
    [PROJECT_ROLE.Collaborator]: false,
    [PROJECT_ROLE.ReadOnly]: false,
    [PROJECT_ROLE.Anonymous]: false,
    [PROJECT_ROLE.Reviewer]: false
  };
}

export function createCollaboratorRole(): Role {
  return {
    [PROJECT_ROLE.Author]: false,
    [PROJECT_ROLE.Owner]: false,
    [PROJECT_ROLE.Collaborator]: true,
    [PROJECT_ROLE.ReadOnly]: false,
    [PROJECT_ROLE.Anonymous]: false,
    [PROJECT_ROLE.Reviewer]: false
  };
}

export function createViewerRole(): Role {
  return {
    [PROJECT_ROLE.Author]: false,
    [PROJECT_ROLE.Owner]: false,
    [PROJECT_ROLE.Collaborator]: false,
    [PROJECT_ROLE.ReadOnly]: true,
    [PROJECT_ROLE.Anonymous]: false,
    [PROJECT_ROLE.Reviewer]: false
  };
}

export function createAnonymousRole(): Role {
  return {
    [PROJECT_ROLE.Author]: false,
    [PROJECT_ROLE.Owner]: false,
    [PROJECT_ROLE.Collaborator]: false,
    [PROJECT_ROLE.ReadOnly]: false,
    [PROJECT_ROLE.Anonymous]: true,
    [PROJECT_ROLE.Reviewer]: false
  };
}

export function createReviewerRole(): Role {
  return {
    [PROJECT_ROLE.Author]: false,
    [PROJECT_ROLE.Owner]: false,
    [PROJECT_ROLE.Collaborator]: false,
    [PROJECT_ROLE.ReadOnly]: false,
    [PROJECT_ROLE.Anonymous]: false,
    [PROJECT_ROLE.Reviewer]: true
  };
}

export function createRoleByType(type: string): Role {
  switch (type) {
    case PROJECT_ROLE.Author:
      return createAuthorRole();
    case PROJECT_ROLE.Owner:
      return createOwnerRole();
    case PROJECT_ROLE.Collaborator:
      return createCollaboratorRole();
    case PROJECT_ROLE.ReadOnly:
      return createViewerRole();
    case PROJECT_ROLE.Anonymous:
      return createAnonymousRole();
    case PROJECT_ROLE.Reviewer:
      return createReviewerRole();
  }
  return createAnonymousRole();
}

export function createAuthorMusician(account: AccountState | AccountAlgolia): Musician {
  return {
    role: createAuthorRole(),
    stageName: account.stageName ? account.stageName : '',
    email: account.email ? account.email : '',
    uid: account.uid ? account.uid : ''
  };
}

export function createOwnerMusician(account: AccountState | AccountAlgolia): Musician {
  return {
    role: createOwnerRole(),
    stageName: account.stageName ? account.stageName : '',
    email: account.email ? account.email : '',
    uid: account.uid ? account.uid : ''
  };
}

export function createCollaboratorMusician(account: AccountState | AccountAlgolia): Musician {
  return {
    role: createCollaboratorRole(),
    stageName: account.stageName ? account.stageName : '',
    email: account.email ? account.email : '',
    uid: account.uid ? account.uid : ''
  };
}

export function createViewerMusician(account: AccountState | AccountAlgolia): Musician {
  return {
    role: createViewerRole(),
    stageName: account.stageName ? account.stageName : '',
    email: account.email ? account.email : '',
    uid: account.uid ? account.uid : ''
  };
}

export function createAnonymousMusician(account: AccountState | AccountAlgolia): Musician {
  return {
    role: createAnonymousRole(),
    stageName: account.stageName ? account.stageName : '',
    email: account.email ? account.email : '',
    uid: account.uid ? account.uid : ''
  };
}

export function createReviewerMusician(account: AccountState | AccountAlgolia): Musician {
  return {
    role: createReviewerRole(),
    stageName: account.stageName ? account.stageName : '',
    email: account.email ? account.email : '',
    uid: account.uid ? account.uid : ''
  };
}

export function createMusician(account: AccountState | AccountAlgolia, roleType: string) {
  let musician: Musician;
  switch (roleType) {
    case PROJECT_ROLE.Author: {
      musician = createAuthorMusician(account);
      break;
    }

    case PROJECT_ROLE.Owner: {
      musician = createOwnerMusician(account);
      break;
    }

    case PROJECT_ROLE.Collaborator: {
      musician = createCollaboratorMusician(account);
      break;
    }

    case PROJECT_ROLE.Anonymous: {
      musician = createAnonymousMusician(account);
      break;
    }

    case PROJECT_ROLE.Reviewer: {
      musician = createReviewerMusician(account);
      break;
    }

    // Note: ReadOnly is default Musician
    default: {
      musician = createViewerMusician(account);
      break;
    }
  }

  return musician;
}

export function applyInitialAuthor<T, R>(config: T | R, musician: Musician): R {
  return <R>{
    ...config,

    createdBy: musician,
    createdByUID: musician.uid,
    members: {
      [musician.uid]: musician
    },
    memberUIDs: [musician.uid],
    owners: {
      [musician.uid]: musician
    },
    ownerUIDs: [musician.uid]
  };
}
