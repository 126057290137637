import {PriceInterval, SubscriptionItem} from '@spout/web-global/models';

declare type HasSubscription = 'hasSubscription' | 'noSubscription';

declare type MyPlanType = 'plan' | 'included' | 'storage' | 'total';

export interface CurrentSubscriptionTitleDescription {
  title: string;
  description: string;
}

export interface CurrentSubscriptionItem {
  productDescription: CurrentSubscriptionTitleDescription;
  price: string;
  type: MyPlanType;
}

// TODO add interval like in component
export interface MySubscriptionSpecs {
  id: string | null;
  plan: SubscriptionItem | null;
  storage: SubscriptionItem | null;
  baseStorageGB: number;
  additionalStorageQuantity: number;
  storageIncluded: boolean;
  total: number;
  interval: PriceInterval;
}

export interface MySubscriptionState {
  hasSubscription: boolean;
  currentSubscriptions: MySubscriptionSpecs;
}
