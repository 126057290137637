import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'spt-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup;
  showEmailSentMessage = false;
  emailControl: AbstractControl;

  @Output() email: EventEmitter<string> = new EventEmitter<string>();
  @Output() dirtyAndValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.emailControl = this.forgotPasswordForm.controls['email'];

    this.forgotPasswordForm.valueChanges.subscribe(() => {
      this.dirtyAndValid.next(this.forgotPasswordForm.dirty && this.forgotPasswordForm.valid);
      // this.cd.markForCheck();
    });
  }

  sendEmail() {
    if (this.forgotPasswordForm.valid) {
      this.email.emit(this.forgotPasswordForm.controls['email'].value);
    }
  }
}
