import {
  audioMetaDataState,
  selectAllAudioMetaData,
  selectAllMixes,
  selectAllProjects,
  selectAllSongs,
  selectAllTracks_passThrough,
  selectCurrentAudioFileMetaDataForRecord,
  selectCurrentIDsFromStore,
  selectCurrentMixEntity,
  selectCurrentMixEntityNamePassThrough,
  selectCurrentProjectEntity,
  selectCurrentSongEntity,
  selectCurrentSongId,
  selectCurrentSongNamePassThrough,
  selectCurrentTrackEntityAndAudioMetaDataEntity,
  selectCurrentTrackEntity_passThrough,
  selectCurrentTrackName,
  selectCurrentTracks,
  selectDeviceStorageState,
  selectedColorIconTextCSS
} from '@spout/web-global/selectors';

export function releaseStoreToSelect() {
  selectAllProjects.release();
  selectAllSongs.release();
  selectAllMixes.release();
  selectAllTracks_passThrough.release();
  selectAllAudioMetaData.release();

  selectCurrentProjectEntity.release();
  selectCurrentSongEntity.release();
  selectCurrentMixEntity.release();
  selectCurrentTrackEntity_passThrough.release();
  selectCurrentAudioFileMetaDataForRecord.release();
  selectCurrentTrackEntityAndAudioMetaDataEntity.release();

  selectCurrentIDsFromStore.release();
  selectCurrentMixEntityNamePassThrough.release();
  selectCurrentTracks.release();
  selectCurrentSongNamePassThrough.release();
  selectCurrentTrackName.release();
  selectCurrentSongId.release();

  selectDeviceStorageState.release();
  audioMetaDataState.release();

  selectedColorIconTextCSS.release();
}
