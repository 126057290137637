import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'spt-warn-message',
  templateUrl: './warn-message.component.html',
  styleUrls: ['./warn-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarnMessageComponent {
  @Output() dismiss: EventEmitter<boolean> = new EventEmitter();
}
