import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ServiceWorkerStatus, StudioAppState, studioFeatureKey, StudioVersionState} from '@spout/web-global/models';

export const selectStudioState = createFeatureSelector<StudioVersionState>(studioFeatureKey);

export const selectInstalledStudioVersion = createSelector(selectStudioState, (state: StudioVersionState) => {
  return state.installed;
});

export const selectServiceWorkerStatus = createSelector(
  selectStudioState,
  (state: StudioVersionState): ServiceWorkerStatus => state.serviceWorker
);
