import {Action, createReducer, on} from '@ngrx/store';
import {loadEnvironment} from '@spout/web-global/actions';
import {IEnvironmentState, initialEnvironmentState} from '@spout/web-global/models';

const reducer = createReducer(
  initialEnvironmentState,
  on(loadEnvironment, (state: IEnvironmentState, action: {payload: IEnvironmentState}) => {
    return {
      ...state,
      ...action.payload
    };
  })
);

export function environmentReducer(state: IEnvironmentState | undefined, action: Action) {
  return reducer(state, action);
}
