import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {accountSaveFirebase, deletePromoCode, loadPromoCode} from '@spout/web-global/actions';
import {removeTimestampCTorFromDocumentSnapshot, SptFirestoreService} from '@spout/web-global/data-access';
import {firestorePromoCodeDict} from '@spout/web-global/fns';
import {getRoleName, PromoCodeDict, SPT_FORM_OPTIONS} from '@spout/web-global/models';
import {selectDeviceIsSmallScreen, selectPromo_passThrough} from '@spout/web-global/selectors';
import {DocumentSnapshot, getDoc} from 'firebase/firestore';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'spt-promo-code-form',
  templateUrl: './promo-code-form.component.html',
  styleUrls: ['./promo-code-form.component.scss']
})
export class PromoCodeFormComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _firebaseSub: Subscription = Subscription.EMPTY;
  private _savedCode: string | null = null;

  @Input() setAutofocus = false;
  @Input() showSkip = true;

  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();

  label = 'Beta Program Code';
  promoCodeForm: FormControl;
  formOptions = SPT_FORM_OPTIONS;
  hasPromoCode$: BehaviorSubject<boolean>;
  promoDict$: BehaviorSubject<PromoCodeDict | null>;
  deviceIsSmallScreen$ = this.store.pipe(select(selectDeviceIsSmallScreen));
  deviceIsWideScreen$ = this.deviceIsSmallScreen$.pipe(map(isSmalleScreen => !isSmalleScreen));

  constructor(private store: Store, private cd: ChangeDetectorRef, private sptFirebase: SptFirestoreService) {
    this.hasPromoCode$ = new BehaviorSubject<boolean>(false);
    this.promoDict$ = new BehaviorSubject<PromoCodeDict | null>(null);

    this.promoCodeForm = new FormControl('');
  }

  ngOnInit(): void {
    this.store
      .pipe(select(selectPromo_passThrough), takeUntil(this._onDestroy$))
      .subscribe((promoCode: PromoCodeDict) => {
        if (promoCode && promoCode.code) {
          this.promoDict$.next(promoCode);
          this.promoCodeForm.setValue(promoCode.code, {emitEvent: false});
          this.hasPromoCode$.next(true);
          this._savedCode = promoCode.code;
        } else {
          this.hasPromoCode$.next(false);
        }

        this.cd.detectChanges();
      });
  }

  getRoleName(role: string | null | undefined) {
    return role ? getRoleName(role) : '';
  }

  onDelete() {
    this.store.dispatch(
      accountSaveFirebase({
        payload: {
          promoCode: null
        }
      })
    );

    this.store.dispatch(deletePromoCode());
    this.promoCodeForm.reset('', {emitEvent: false});
    this.hasPromoCode$.next(false);
    this._savedCode = null;
    this.promoDict$.next(null);
    this.cd.detectChanges();
  }

  async onSubmit() {
    if (this.promoCodeForm.valid) {
      const promoCodeSnapshot: DocumentSnapshot = await getDoc(
        this.sptFirebase.docRef(firestorePromoCodeDict(this.promoCodeForm.value))
      );

      const promoCodeDict: PromoCodeDict = removeTimestampCTorFromDocumentSnapshot<PromoCodeDict>(promoCodeSnapshot);

      if (promoCodeSnapshot.exists() && promoCodeDict.active) {
        this._savedCode = this.promoCodeForm.value;

        // this.saveInProgress$.next(true);

        this.store.dispatch(
          accountSaveFirebase({
            payload: {
              promoCode: this.promoCodeForm.value
            }
          })
        );

        this.store.dispatch(
          loadPromoCode({
            promoCode: promoCodeDict
          })
        );

        this.promoCodeForm.reset(this.promoCodeForm.value);
        // this.saved.emit(true);
        this.hasPromoCode$.next(true);
        this.cd.detectChanges();
      } else {
        this.promoCodeForm.setErrors({
          invalidCode: true
        });

        this.cd.detectChanges();
      }
    }
  }

  onNext() {
    this.saved.emit(true);
    this.cd.detectChanges();
  }

  reset(): void {
    if (this.promoCodeForm) {
      this.promoCodeForm.reset(this._savedCode ? this._savedCode : '');

      this.store.dispatch(
        accountSaveFirebase({
          payload: {
            promoCode: this._savedCode ? this._savedCode : null
          }
        })
      );

      this.saved.emit(true);
    }
  }

  ngOnDestroy(): void {
    this._firebaseSub.unsubscribe();
    this._onDestroy$.next(true);
  }
}
