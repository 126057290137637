import {InjectionToken} from '@angular/core';
import {EndpointModels} from '../endpoints/endpoint.models';

export const FIREBASE_CONFIG = 'FIREBASE_CONFIG';
export const LOGOUT_REDIRECT = 'LOGOUT_REDIRECT';
export const ENDPOINTS = 'ENDPOINTS';
export const ENVIRONMENT = new InjectionToken('ENVIRONMENT');

export const EMULATOR_PORTS = {
  FIRESTORE: 8080,
  AUTH: 9099,
  STORAGE: 9199,
  FUNCTIONS: 5001
};

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL?: string; // used for firebase real time database, not firestore
  projectId: string;
  storageBucket: string;
  appId: string;
  // messagingSenderId: string;
  measurementId: string;
}

export interface AlgoliaSearchKeys {
  appId: string;
  apiKey: string;
}

export type AppName = 'studio-app' | 'studio-app-marketing-site' | 'spout-software-site';

export interface IEnvironmentState {
  production: boolean;
  appName: AppName;
  facebookAppId: string;
  firebaseProjectID: string;
  firebase: FirebaseConfig;
  algolia: AlgoliaSearchKeys;

  // NOT USED UNTIL RESOLVE AUTH FOR A DIFFERENT FIREBASE ACCOUNT
  // firebaseMonitor: FirebaseConfig | null;
  mobileTest: boolean | null;
  serviceWorker: boolean;
  endpoints: EndpointModels | null;
  emulator: boolean;
  authSite: string;
}

export interface FirebaseOptions {
  logoutRedirect?: string;
}

export const initialEnvironmentState: IEnvironmentState = {
  production: false,
  appName: 'studio-app',
  firebaseProjectID: '',
  firebase: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    appId: '',
    // messagingSenderId: '',
    measurementId: ''
  },
  algolia: {
    appId: '',
    apiKey: ''
  },
  // firebaseMonitor: null,
  facebookAppId: '',
  mobileTest: null,
  serviceWorker: false,
  endpoints: {
    SERVER: '',
    UPLOAD_BACKBLAZE: '',
    DELETE_BACKBLAZE: '',
    DOWNLOAD_BACKBLAZE: ''
  },
  emulator: true,
  authSite: ''
};

export function faceBookInit(appId: string) {
  (<any>window).fbAsyncInit = function () {
    (<any>window).FB.init({
      appId: appId,
      cookie: true,
      xfbml: true,
      version: 'v7.0'
    });

    (<any>window).FB.AppEvents.logPageView();
  };

  (function (d, s, id) {
    let js: any = d.getElementsByTagName(s)[0];
    const fjs: any = js;
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}
