import {Component, EventEmitter, Input, Output} from '@angular/core';
import {getTrackColorCSS} from '@spout/web-global/fns';
import {ReplaySubject} from 'rxjs';
import {WarnDeleteSelectedTrack} from '../track-item-dialog.model';

@Component({
  selector: 'spt-warn-clear-selected',
  templateUrl: './warn-clear-selected.component.html',
  styleUrls: ['./warn-clear-selected.component.scss']
})
export class WarnClearSelectedComponent {
  selectedColor$: ReplaySubject<string> = new ReplaySubject<any>(1);

  @Input()
  set data(data: WarnDeleteSelectedTrack) {
    if (data && data.track) {
      this.selectedColor$.next(getTrackColorCSS(data.track));
    }
  }

  @Output()
  ok: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
}
