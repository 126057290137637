import {Action, createReducer, on} from '@ngrx/store';
import {
  addLatency,
  addLatencies,
  clearLatencies,
  deleteLatency,
  deleteLatencies,
  loadLatencies,
  updateLatency,
  updateLatencies,
  upsertLatency,
  upsertLatencies
} from '@spout/web-global/actions';
import {initialLatencyState, latencyAdapter, LatencyState} from '@spout/web-global/models';

const reducer = createReducer(
  initialLatencyState,
  on(addLatency, (state, action) => latencyAdapter.addOne(action.latency, state)),
  on(upsertLatency, (state, action) => latencyAdapter.upsertOne(action.latency, state)),
  on(addLatencies, (state, action) => latencyAdapter.addMany(action.latencies, state)),
  on(upsertLatencies, (state, action) => latencyAdapter.upsertMany(action.latencies, state)),
  on(updateLatency, (state, action) => latencyAdapter.updateOne(action.latency, state)),
  on(updateLatencies, (state, action) => latencyAdapter.updateMany(action.latencies, state)),
  on(deleteLatency, (state, action) => latencyAdapter.removeOne(action.id, state)),
  on(deleteLatencies, (state, action) => latencyAdapter.removeMany(action.ids, state)),
  on(loadLatencies, (state, action) => latencyAdapter.setAll(action.latencies, state)),
  on(clearLatencies, state => latencyAdapter.removeAll(state))
);

export function latencyReducer(state: LatencyState | undefined, action: Action) {
  return reducer(state, action);
}
