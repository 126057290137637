import {DeferredPromise} from '@spout/web-global/fns';
import {DYN_STORE} from '@spout/web-global/models';
import {Observable} from 'rxjs';

import {distinctUntilKeyChanged, map, mergeMap, take, tap} from 'rxjs/operators';
import {DynamicStoreService} from '../services/dynamic-store.service';
import SharedBufferWorkletNode, {OutputData} from './shared-buffer-worklet-node';
import {SptMergerWorklet} from './spt-merger-worklet';
import {OutputNodes, SptTransportService} from './spt-transport.service';

export class SptRecorderWorklet {
  private numOutputChannels = 2;
  private recorderWorkletNode: SharedBufferWorkletNode | null;
  private stopPromise: DeferredPromise<any> | null;
  private startPromise: DeferredPromise<any> | undefined;
  private microphone: AudioNode | undefined;
  private mergeCompressorNode: DynamicsCompressorNode | undefined;
  private inputCompressorNode: DynamicsCompressorNode | undefined;
  // private inputGainParam: AudioParam | undefined;
  private inputGain: GainNode | undefined;
  private gainSilentOutput: GainNode | undefined;
  private analyzer: AnalyserNode | undefined;
  output: OutputData = {
    // gainInput$: new ReplaySubject<number>(1),
  };

  id: number = new Date().valueOf();

  onInitialized: (() => void) | undefined;

  // oscillatorTest: OscillatorNode;

  constructor(
    public audioContext: AudioContext,
    private transport: SptTransportService,
    private workerFactory: () => Worker,
    private dss: DynamicStoreService,
    private options: any
  ) {
    const that = this;
    this.numOutputChannels = 2; // stereo output, even if input is mono`

    this.recorderWorkletNode = new SharedBufferWorkletNode(
      this.transport,
      audioContext,
      this.workerFactory,
      this.output,
      this.options
    );

    // this.inputGainParam = (<any>this.recorderWorkletNode.parameters).get('gain');

    // this.oscillatorTest.start();

    this.recorderWorkletNode.port.onmessage = function (event: MessageEvent) {
      const data = event.data;

      if (data.message === 'RECORDING_STARTED' && that.startPromise) {
        that.startPromise.resolve(data.timestampStartRecording);
      }

      if (data.message === 'RECORD_OUTPUT_VOLUME_WITH_GAIN') {
        that.dss.emit(DYN_STORE.RECORD_OUTPUT_VOLUME_WITH_GAIN, {
          left: data.left,
          right: data.right
        });
        return;
      }
    }.bind(this);

    // this._callback = function() {};
    this.stopPromise = null;
  }

  addRecordStream(stream: MediaStream) {
    if (!this.mergeCompressorNode) {
      this.mergeCompressorNode = this.audioContext.createDynamicsCompressor();
    }

    if (!this.microphone) {
      this.microphone = this.audioContext.createMediaStreamSource(stream);
    }

    if (!this.inputGain) {
      this.inputGain = this.audioContext.createGain();
    }

    // microphone -> inputGain
    this.microphone.connect(this.inputGain);

    if (!this.inputCompressorNode) {
      this.inputCompressorNode = this.audioContext.createDynamicsCompressor();
    }

    // inputGain -> compressor
    this.inputGain.connect(this.inputCompressorNode);

    if (!this.analyzer) {
      this.analyzer = this.audioContext.createAnalyser();
    }

    // analyzer -> recorderWorklet
    if (this.recorderWorkletNode) {
      this.analyzer.connect(this.recorderWorkletNode);
    }

    // compressorInputNode -> analyzer
    this.inputCompressorNode.connect(this.analyzer);

    if (!this.gainSilentOutput) {
      this.gainSilentOutput = this.audioContext.createGain();
      this.gainSilentOutput.gain.value = 0;
    }

    // recorderWorklet -> gainSilentOutput
    if (this.recorderWorkletNode) {
      this.recorderWorkletNode.connect(this.gainSilentOutput);
    }

    // TODO TEST
    // this.oscillatorTest = this.audioContext.createOscillator();
    // this.oscillatorTest.type = 'square';
    // this.oscillatorTest.frequency.setValueAtTime(440, this.audioContext.currentTime); // value in hertz
    // END TEST

    // gainSilentOutput -> this.audioContext.destination
    this.gainSilentOutput.connect(this.audioContext.destination);

    this.dss.setSingletonValue(DYN_STORE.RECORD_INPUT_ANALYZER, this.analyzer);

    // TODO TEST
    // this.oscillatorTest.connect(this.workletNode).connect(audioContext.destination);
    // this.oscillatorTest.start();
  }

  removeRecordStream() {
    if (this.microphone) {
      this.microphone.disconnect();
    }

    if (this.inputGain) {
      this.inputGain.disconnect();
    }

    if (this.analyzer) {
      this.analyzer.disconnect();
    }

    if (this.recorderWorkletNode) {
      this.recorderWorkletNode.disconnect();
    }

    if (this.gainSilentOutput) {
      this.gainSilentOutput.disconnect();
    }

    if (this.mergeCompressorNode) {
      this.mergeCompressorNode.disconnect();
    }

    if (this.inputCompressorNode) {
      this.inputCompressorNode.disconnect();
    }
  }

  addExportMixToMaster(o: OutputNodes) {
    const that = this;
    this.transport.mergerWorklet$.pipe(take(1)).subscribe((merger: SptMergerWorklet) => {
      // https://developer.mozilla.org/en-US/docs/Web/API/DynamicsCompressorNode
      // https://mdn.github.io/webaudio-examples/compressor-example/
      if (that.mergeCompressorNode && that.recorderWorkletNode && that.gainSilentOutput) {
        /**
         * Use Merge Compressor Node
         */
        // merger.connect(that.mergeCompressorNode);
        // that.mergeCompressorNode.connect(that.recorderWorkletNode);

        /**
         * Don't use Merge Compressor Node
         */
        merger.connect(that.recorderWorkletNode);

        that.recorderWorkletNode.connect(that.gainSilentOutput);
        that.gainSilentOutput.connect(that.audioContext.destination);
      }
    });
  }

  setGain() {
    return mergeMap((g: number) => {
      return this.transport.audioContext$.pipe(
        distinctUntilKeyChanged<any>('id'),
        tap((audioContext: AudioContext) => {
          // if (this.inputGainParam) {
          //   this.inputGainParam.setValueAtTime(g, audioContext.currentTime);
          // }
          if (!this.inputGain) {
            this.inputGain = audioContext.createGain();
          }

          this.inputGain.gain.setValueAtTime(g, audioContext.currentTime);
        }),
        map(() => {
          return g;
        })
      );
    });
  }

  setInputCompressorThreshold() {
    return mergeMap((v: number) => {
      return this._getInputCompressorNode().pipe(
        map(({audioContext, compressorInputNode}) => {
          compressorInputNode.threshold.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setInputCompressorKnee() {
    return mergeMap((v: number) => {
      return this._getInputCompressorNode().pipe(
        map(({audioContext, compressorInputNode}) => {
          compressorInputNode.knee.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setInputCompressorRatio() {
    return mergeMap((v: number) => {
      return this._getInputCompressorNode().pipe(
        map(({audioContext, compressorInputNode}) => {
          compressorInputNode.ratio.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setInputCompressorAttack() {
    return mergeMap((v: number) => {
      return this._getInputCompressorNode().pipe(
        map(({audioContext, compressorInputNode}) => {
          compressorInputNode.attack.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setInputCompressorRelease() {
    return mergeMap((v: number) => {
      return this._getInputCompressorNode().pipe(
        map(({audioContext, compressorInputNode}) => {
          compressorInputNode.release.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  private _getInputCompressorNode(): Observable<{
    compressorInputNode: DynamicsCompressorNode;
    audioContext: AudioContext;
  }> {
    return this.transport.audioContext$.pipe(
      distinctUntilKeyChanged<any>('id'),
      map((audioContext: AudioContext) => {
        // if (this.inputGainParam) {
        //   this.inputGainParam.setValueAtTime(g, audioContext.currentTime);
        // }
        if (!this.inputCompressorNode) {
          this.inputCompressorNode = audioContext.createDynamicsCompressor();
        }

        return {compressorInputNode: this.inputCompressorNode, audioContext};
      })
    );
  }

  setMergeCompressorThreshold() {
    return mergeMap((v: number) => {
      return this._getMergeCompressorNode().pipe(
        map(({audioContext, compressorMergeNode}) => {
          compressorMergeNode.threshold.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setMergeCompressorKnee() {
    return mergeMap((v: number) => {
      return this._getMergeCompressorNode().pipe(
        map(({audioContext, compressorMergeNode}) => {
          compressorMergeNode.knee.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setMergeCompressorRatio() {
    return mergeMap((v: number) => {
      return this._getMergeCompressorNode().pipe(
        map(({audioContext, compressorMergeNode}) => {
          compressorMergeNode.ratio.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setMergeCompressorAttack() {
    return mergeMap((v: number) => {
      return this._getMergeCompressorNode().pipe(
        map(({audioContext, compressorMergeNode}) => {
          compressorMergeNode.attack.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setMergeCompressorRelease() {
    return mergeMap((v: number) => {
      return this._getMergeCompressorNode().pipe(
        map(({audioContext, compressorMergeNode}) => {
          compressorMergeNode.release.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  private _getMergeCompressorNode(): Observable<{
    compressorMergeNode: DynamicsCompressorNode;
    audioContext: AudioContext;
  }> {
    return this.transport.audioContext$.pipe(
      distinctUntilKeyChanged<any>('id'),
      map((audioContext: AudioContext) => {
        // if (this.inputGainParam) {
        //   this.inputGainParam.setValueAtTime(g, audioContext.currentTime);
        // }
        if (!this.mergeCompressorNode) {
          this.mergeCompressorNode = audioContext.createDynamicsCompressor();
        }

        return {compressorMergeNode: this.mergeCompressorNode, audioContext};
      })
    );
  }

  // record(recordStartLatencyMS: number = 0) {
  //   // TODO TEST
  //   // this.oscillatorTest.start();
  //
  //   this.startPromise = Deferred();
  //   this.workletNode.port.postMessage({ message: 'START_RECORDING', recordStartLatencyMS });
  //   return this.startPromise.promise;
  // }

  stop(): Promise<any> {
    // TODO TEST
    // this.oscillatorTest.stop();

    if (this.recorderWorkletNode) {
      return this.recorderWorkletNode.stop();
    }

    return new Promise<any>(resolve => {
      resolve(true);
    });
  }

  dispose() {
    // this._callback = function() {};
    this.recorderWorkletNode = null;
  }
}
