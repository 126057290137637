<div class="track-item-dialog">
  <button class="close-icon-button" mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <ng-container *ngIf="data.type === dialogType.WARN_CLEAR_SELECTED_TRACK">
    <spt-warn-clear-selected (ok)="ok()" (cancel)="cancel()" [data]="data?.data"></spt-warn-clear-selected>
  </ng-container>

  <ng-container *ngIf="data.type === dialogType.WARN_DELETE_SELECTED_TRACK">
    <spt-warn-delete-selected (ok)="ok()" [data]="data?.data"></spt-warn-delete-selected>
  </ng-container>

  <ng-container *ngIf="data.type === dialogType.WARN_IS_MASTER_TRACK">
    <spt-warn-is-master (ok)="ok()" [data]="data?.data"></spt-warn-is-master>
  </ng-container>

  <ng-container *ngIf="data.type === dialogType.WARN_TRACK_RECORDED">
    <spt-warn-track-recorded (ok)="ok()" [data]="data?.data" (cancel)="cancel()"></spt-warn-track-recorded>
  </ng-container>
</div>
