import {createUint8ArrayExtensionFromBlob$} from '@spout/any-shared/fns';
import {AudioFileMetaData, FileType, FileUint8ArrayType, SptSystemInformation} from '@spout/any-shared/models';
import {ParsedRecordExport, TrackEntityAndAudioFileMetaDataEntity, WaveformValues} from '@spout/web-global/models';
import {Observable, Observer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {getWaveformValuesFromToneBuffer} from './waveform-data';

/**
 *
 * @param blob
 * @param file - TrackEntityAndAudioFileMetaDataEntity
 * @param type - FileType
 * @param desktopInformation
 */
export function parseExportedRecordedWavAudio(
  blob: Blob | File,
  file: TrackEntityAndAudioFileMetaDataEntity,
  type: FileType,
  // desktopInformation: SptSystemInformation,
  audioContext: AudioContext
): Observable<ParsedRecordExport> {
  // console.log(blob);

  return getAudioBufferFromBlob(blob, audioContext).pipe(
    switchMap((audioBuffer: AudioBuffer) => {
      // console.log(audioBuffer);
      return createUint8ArrayExtensionFromBlob$(blob).pipe(
        switchMap((uint8ArrayType: FileUint8ArrayType) =>
          parseRawAudioToEntityData(audioBuffer, uint8ArrayType, file, type, blob)
        )
      );
    })
  );
}

export function getAudioBufferFromBlob(blob: Blob, audioContext: AudioContext): Observable<AudioBuffer> {
  return new Observable((observer: Observer<any>) => {
    (<any>blob).arrayBuffer().then((arrayBuffer: ArrayBuffer) => {
      audioContext.decodeAudioData(arrayBuffer, function (buffer: AudioBuffer) {
        observer.next(buffer);
      });
    });
  });
}

export function parseLoadedUint8ArrayFile(
  uint8ArrayType: FileUint8ArrayType,
  trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity,
  // desktopInformation: SptSystemInformation,
  audioContext: AudioContext,
  blob?: Blob
): Observable<ParsedRecordExport> {
  return new Observable((observer: Observer<any>) => {
    audioContext.decodeAudioData(uint8ArrayType.uint8Array.buffer, function (audioBuffer: AudioBuffer) {
      parseRawAudioToEntityData(
        audioBuffer,
        uint8ArrayType,
        trackEntityAndAudioFileMetaDataEntity,
        uint8ArrayType.type,
        // desktopInformation,
        blob
      ).subscribe((r: ParsedRecordExport) => {
        observer.next(r);
      });
    });
  });
}

/**
 *
 * @param audioBuffer
 * @param uint8ArrayType
 * @param file TrackEntityAndAudioFileMetaDataEntity
 * @param type - not using type from audioMetaDataEntity in case the type is updated, this comes from the raw audio source
 * @param desktopInformation
 * @param blob - Blob
 */
export function parseRawAudioToEntityData(
  audioBuffer: AudioBuffer,
  uint8ArrayType: FileUint8ArrayType,
  file: TrackEntityAndAudioFileMetaDataEntity,
  type: FileType, // Wav
  // desktopInformation: SptSystemInformation,
  blob?: Blob
): Observable<ParsedRecordExport> {
  return new Observable((observer: Observer<any>) => {
    getWaveformValuesFromToneBuffer(audioBuffer).subscribe((w: WaveformValues) => {
      // - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA
      // - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA
      // - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA
      // - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA - AUDIO META DATA

      observer.next(<ParsedRecordExport>{
        audioBuffer,
        uint8ArrayType,
        waveformValues: w,
        recordOffsetMs: 0,
        trackEntityAndAudioFileMetaDataEntity: {
          ...file,
          audioFileMetaDataEntity: <AudioFileMetaData>{
            ...file.audioFileMetaDataEntity,
            type,
            sampleRate: audioBuffer.sampleRate,
            numberOfChannels: audioBuffer.numberOfChannels,
            duration: audioBuffer.duration,
            length: audioBuffer.length,
            bits: w.bits,
            scale: w.scale,
            // desktopInformation,
            fileSize: blob ? blob.size : 0,
            savedTimestamp: Date.now().valueOf()
          }
        }
      });
    });
  });
}
