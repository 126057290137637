import {NgZone} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {createUint8ArrayExtensionFromBlob$} from '@spout/any-shared/fns';
import {
  FileUint8ArrayType,
  GoogleMediaTrackConstraints,
  RenderMixData,
  SaveExportedMixAsFile
} from '@spout/any-shared/models';
import {masterFullRewindEffect} from '@spout/web-global/actions';
import {parseExportedRecordedWavAudio} from '@spout/web-global/fns';
import {
  ParsedRecordExport,
  RecordType,
  StudioAppState,
  TrackEntityAndAudioFileMetaDataEntity
} from '@spout/web-global/models';
import {
  selectGoogMediaTrackConstraints,
  selectMediaDeviceInfo,
  selectMemoizedExportMergeCompressorAttack,
  selectMemoizedExportMergeCompressorKnee,
  selectMemoizedExportMergeCompressorRatio,
  selectMemoizedExportMergeCompressorRelease,
  selectMemoizedExportMergeCompressorThreshold,
  selectMemoizedRecordInputCompressorAttack,
  selectMemoizedRecordInputCompressorKnee,
  selectMemoizedRecordInputCompressorRatio,
  selectMemoizedRecordInputCompressorRelease,
  selectMemoizedRecordInputCompressorThreshold,
  selectMicrophoneGain
} from '@spout/web-global/selectors';
import {hasValue} from '@uiux/fn';
import {combineLatest, from, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {AudioFileSaveService} from '../+device-storage/services/audio-file-save.service';
import {DeviceStorageService} from '../+device-storage/services/device-storage.service';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {SptRecorderWorklet} from './spt-recorder.worklet';
import {OutputNodes, SptTransportService} from './spt-transport.service';

/**
 * For Audio WebWorker to start, must provide
 *  - MediaDeviceInfor
 *  - AudioFileEntity
 */
export class SptRecorderController {
  private _onDestroy$: Subject<boolean> = new Subject();
  private workerSub: Subscription = Subscription.EMPTY;
  private depsSub: Subscription = Subscription.EMPTY;

  private sharedBufferRecorder$: ReplaySubject<SptRecorderWorklet>;
  private workletAdded = false;

  /**
   * Used for meta data information
   *
   * // Provided by libs/mixer-browser-desktop/theme/src/libspt-player/spt-record/spt-record.component.ts
   */
  audioFileEntity$: ReplaySubject<TrackEntityAndAudioFileMetaDataEntity>;

  /**
   * Used for computer device recording params
   */
  // mediaDeviceInfo$: ReplaySubject<MediaDeviceInfo> = new ReplaySubject<MediaDeviceInfo>(1);

  constructor(
    public workerFactoryId: string,
    public workerFactory: () => Worker,
    private store: Store<StudioAppState>,
    private dss: DynamicStoreService,
    private transport: SptTransportService,
    private fileSaveService: AudioFileSaveService,
    private deviceStorageService: DeviceStorageService,
    private zone: NgZone
  ) {
    this.audioFileEntity$ = new ReplaySubject<TrackEntityAndAudioFileMetaDataEntity>(1);
    this.sharedBufferRecorder$ = new ReplaySubject<SptRecorderWorklet>(1);

    navigator.permissions.query(<any>{name: 'microphone'}).then(function (result) {
      if (result.state === 'granted') {
        console.log('MICROPHONE PERMISSION GRANTED');
      } else if (result.state === 'prompt') {
        console.log('MICROPHONE PERMISSION PROMPT');
      } else if (result.state === 'denied') {
        console.log('MICROPHONE PERMISSION DENIED');
      }
      result.onchange = function () {
        /* noop */
      };
    });

    this.transport.audioContextDistinct$
      .pipe(this.createRecorderWorklet(), distinctUntilKeyChanged('id'))
      .subscribe((sharedBufferWorker: SptRecorderWorklet) => {
        this.sharedBufferRecorder$.next(sharedBufferWorker);
        this.watchInputs(sharedBufferWorker);
      });

    // TODO Record Microphone or Export song
    combineLatest([this.sharedBufferRecorder$, this.getRecordingInputStream(), this.transport.outputNodes$]).subscribe(
      ([recorder, inputStream, o]: [SptRecorderWorklet, MediaStream, OutputNodes]) => {
        recorder.removeRecordStream();

        if (o.recordType === RecordType.TRACK) {
          recorder.addRecordStream(inputStream);
        }

        if (o.recordType === RecordType.MIX_MASTER) {
          recorder.addExportMixToMaster(o);
        }
      }
    );
  }

  // `  private addMicrophoneInputStream() {
  //     this.sharedBufferRecorder$
  //       .pipe(take(1))
  //       .subscribe((recorder: SptRecorderWorklet) => {
  //       this.getRecordingInputStream()
  //         .pipe(take(1))
  //         .subscribe((inputStream) => {
  //         // console.log(inputStream);
  //         recorder.addRecordStream(inputStream);
  //       });
  //     });
  //   }`

  private watchInputs(sharedBufferWorker: SptRecorderWorklet) {
    this.store
      .pipe(select(selectMicrophoneGain), distinctUntilChanged(), sharedBufferWorker.setGain())
      .subscribe((gain: number) => {});

    this.store
      .pipe(
        select(selectMemoizedRecordInputCompressorThreshold()),
        distinctUntilChanged(),
        sharedBufferWorker.setInputCompressorThreshold()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedRecordInputCompressorKnee()),
        distinctUntilChanged(),
        sharedBufferWorker.setInputCompressorKnee()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedRecordInputCompressorRatio()),
        distinctUntilChanged(),
        sharedBufferWorker.setInputCompressorRatio()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedRecordInputCompressorAttack()),
        distinctUntilChanged(),
        sharedBufferWorker.setInputCompressorAttack()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedRecordInputCompressorRelease()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorRelease()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedExportMergeCompressorThreshold()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorThreshold()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedExportMergeCompressorKnee()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorKnee()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedExportMergeCompressorRatio()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorRatio()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedExportMergeCompressorAttack()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorAttack()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedExportMergeCompressorRelease()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorRelease()
      )
      .subscribe((v: number) => {});
  }

  private getRecordingInputStream(): Observable<MediaStream> {
    // console.log('init');

    return combineLatest([
      this.store.pipe(select(selectMediaDeviceInfo)),
      this.store.pipe(select(selectGoogMediaTrackConstraints()))
      // this.dss.store(this.dss.DYN_STORE.REFRESH_AUDIO_CONTEXT),
    ]).pipe(
      filter(
        ([device, googConstraints]: [MediaDeviceInfo, GoogleMediaTrackConstraints]) =>
          hasValue(device) && hasValue(googConstraints)
      ),
      mergeMap(([device, googConstraints]: [MediaDeviceInfo, GoogleMediaTrackConstraints]) => {
        // console.log(JSON.stringify(googConstraints, null, 2));

        const constraints: any = <MediaStreamConstraints>{
          // eslint-disable-next-line max-len
          // https://source.chromium.org/chromium/chromium/src/+/master:third_party/blink/renderer/modules/mediastream/media_constraints_impl.cc?q=chromeMediaSourceId&ss=chromium%2Fchromium%2Fsrc

          audio: <MediaTrackConstraints>{
            mandatory: {
              // chromeMediaSource: 'desktop',
              // chromeMediaSourceId: device.deviceId,

              ...googConstraints

              // CAUSES MONO RECORDING
              // googHighpassFilter: false,
            }
          },
          video: false
        };

        // console.log('Supported Constraints', navigator.mediaDevices.getSupportedConstraints());

        // console.log(JSON.stringify(constraints, null, 4));

        // const supported = navigator.mediaDevices.getSupportedConstraints();

        return from(navigator.mediaDevices.getUserMedia(constraints)).pipe(
          mergeMap((inputStream: MediaStream) => {
            const audioTracks: MediaStreamTrack[] = inputStream.getAudioTracks();

            // const trackConstraints = transformGoogleToTrackConstraints(constraints.audio.mandatory);

            // console.log(trackConstraints);

            const constraints$: Observable<any>[] = [];

            for (const audioTrack of audioTracks) {
              // https://www.w3.org/TR/mst-content-hint/#dfn-apply-a-default
              // audioTrack.contentHint = 'music';

              // TODO check if this is correct
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              audioTrack.contentHint = 'music';

              constraints$.push(
                from(
                  audioTrack.applyConstraints({
                    // channelCount: 2,
                    ...constraints.audio.mandatory
                    // channelCount: 2,
                    // sampleSize: 128, // only goes to 16 for some reason
                    // sampleRate: 48000,
                  })
                )
              );
            }

            return combineLatest(constraints$).pipe(
              map(() => {
                // console.log('Audio Track Settings:');
                audioTracks.forEach(audioTrack => {
                  // console.log('Settings: ', JSON.stringify(audioTrack.getSettings(), null, 4));
                  // console.log('Constraints: ', JSON.stringify(audioTrack.getConstraints(), null, 4));
                  // console.log('Capabilities: ', JSON.stringify(audioTrack.getCapabilities(), null, 4));
                });

                return inputStream;
              })
            );
          })
        );
      })
    );

    // this.dss.dispatch(this.dss.DYN_STORE.REFRESH_AUDIO_CONTEXT, new Date().valueOf());
  }

  private createRecorderWorklet() {
    return map((audioContext: AudioContext) => {
      const options = {
        processorOptions: {
          sampleRate: audioContext.sampleRate
        },
        outputChannelCount: [2]
      };

      return new SptRecorderWorklet(audioContext, this.transport, this.workerFactory, this.dss, options);
    });
  }

  stopRecordingAndExport(exportFileType: string): void {
    // console.log('stopRecordingAndExport');
    const that = this;

    this.transport.recordType$.pipe(take(1)).subscribe((recordType: RecordType) => {
      // console.log(recordType);

      this.sharedBufferRecorder$.pipe(take(1)).subscribe((recorder: SptRecorderWorklet) => {
        // console.log('stop recording', this.trackId);
        recorder.stop().then((e: {type: string; blob: Blob}) => {
          if (recordType === RecordType.TRACK) {
            that.processRecordedData.apply(that, [recorder, e]);
          }

          if (recordType === RecordType.MIX_MASTER) {
            that.processExportedData.apply(that, [recorder, e]);
          }
        });
      });
    });
  }

  processRecordedData(recorder: SptRecorderWorklet, e: {type: string; blob: Blob}) {
    const that = this;
    this.audioFileEntity$
      .pipe(take(1))
      .subscribe((trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity) => {
        // that.store.dispatch(masterIsSavingRecordedFile({ isSavingRecordedFileTrackId: trackAudioBufferId.trackEntity.id }));

        that.deviceStorageService
          .getSystemInformation()
          .pipe(
            take(1),
            switchMap(desktopInformation => {
              return parseExportedRecordedWavAudio(
                e.blob,
                trackEntityAndAudioFileMetaDataEntity,
                e.type,
                // desktopInformation,
                recorder.audioContext
              ).pipe(
                map((data: ParsedRecordExport) => {
                  // console.log(data);
                  data.recordOffsetMs = this.transport.recordOffsetMs;
                  return data;
                })
              );
            })
          )
          .subscribe((data: ParsedRecordExport) => {
            this.zone.run(() => {
              that.store.dispatch(masterFullRewindEffect());
              that.dss.emit(this.dss.DYN_STORE.SAVE_RECORDED_AUDIO, data);

              // Note: AudioFileMetaData is updated in track component to show upload progress
            });
          });
      });
  }

  processExportedData(recorder: SptRecorderWorklet, e: {type: string; blob: Blob}) {
    createUint8ArrayExtensionFromBlob$(e.blob)
      .pipe(
        switchMap((uint8ArrayType: FileUint8ArrayType) => {
          return this.dss.store<RenderMixData>(this.dss.DYN_STORE.RENDER_DATA).pipe(
            take<RenderMixData>(1),
            map((value: RenderMixData) => {
              return {
                ...value,
                uint8ArrayType
              };
            })
          );
        })
      )
      .subscribe((payload: SaveExportedMixAsFile) => {
        console.log(payload);

        this.fileSaveService.saveExportedAudioFile(payload).subscribe(() => {
          this.transport.setTypeTrack();
        });
      });
  }

  // clear(): void {
  //   // this.worker.postMessage({ command: AudioWorkerCommand.clear });
  // }

  destroy(): void {
    this._onDestroy$.next(true);
    this.workerSub.unsubscribe();
    this.depsSub.unsubscribe();

    // TODO
    // this.worker.postMessage({ command: AudioWorkerCommand.destroy });

    if (this.workerFactoryId !== 'recorder') {
      // TODO
      // this.worker.terminate();
    }
  }
}
