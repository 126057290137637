import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveComponentModule} from '@ngrx/component';
import {AlertMessageContainerComponent} from './alert-message-container.component';
import {SuccessMessageComponent} from './success-message/success-message.component';
import {WarnMessageComponent} from './warn-message/warn-message.component';

@NgModule({
  declarations: [AlertMessageContainerComponent, SuccessMessageComponent, WarnMessageComponent],
  exports: [AlertMessageContainerComponent, SuccessMessageComponent, WarnMessageComponent],
  imports: [CommonModule, ReactiveComponentModule, MatButtonModule, MatIconModule]
})
export class SptAlertMessageModule {}
