import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpLatencyVsOffsetContentComponent} from './help-latency-vs-offset-content/help-latency-vs-offset-content.component';
import {HelpLatencyVsOffsetComponent} from './help-latency-vs-offset.component';

@NgModule({
  declarations: [HelpLatencyVsOffsetComponent, HelpLatencyVsOffsetContentComponent],
  exports: [HelpLatencyVsOffsetComponent, HelpLatencyVsOffsetContentComponent],
  imports: [CommonModule, HelpContentModule, HelpNavBackModule, MatIconModule, MatTooltipModule, MatButtonModule]
})
export class HelpLatencyVsOffsetModule {}
