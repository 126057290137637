// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// export const firebaseProdConfig = {
//   apiKey: 'AIzaSyA7QyIdQRf1z3zpCr1l6kmdiAawLxuZbMI',
//   authDomain: 'spout-studio-17f13.firebaseapp.com',
//   databaseURL: 'https://spout-studio-17f13.firebaseio.com',
//   projectId: 'spout-studio-17f13',
//   storageBucket: 'spout-studio-17f13.appspot.com',
//   messagingSenderId: '789046118611',
//   appId: '1:789046118611:web:5dce6042d86f70d470ac26',
//   measurementId: 'G-PXH62FSS1P',
// };

import {AlgoliaSearchKeys, FirebaseConfig} from '../environment/models/environment.models';

export const firebaseProdConfig: FirebaseConfig = {
  apiKey: 'AIzaSyC7VBAUaFgaQKmEwnaMMxhdBOTamFei5nw',
  authDomain: 'spout-prod.firebaseapp.com',
  databaseURL: 'https://spout-prod.firebaseio.com',
  projectId: 'spout-prod',
  storageBucket: 'spout-prod.appspot.com',
  // messagingSenderId: '601310708173',
  appId: '1:601310708173:web:b4978e1c03d06edb560484',
  measurementId: 'G-JVGM6CSZ8T'
};

export const firebaseDevConfig: FirebaseConfig = {
  apiKey: 'AIzaSyBsAn7U9swWk0zcQ4ee28J2U0ByxzEZ8ik',
  authDomain: 'spout-dev-d0c97.firebaseapp.com',
  projectId: 'spout-dev-d0c97',
  storageBucket: 'spout-dev-d0c97.appspot.com',
  // messagingSenderId: "898469819067",
  appId: '1:898469819067:web:ba3650e1a44a23f77e6f9e',
  measurementId: 'G-S0SWDFPEBQ'
};

// export const firebaseMonitorConfig: FirebaseConfig = {
//   apiKey: 'AIzaSyAW4mgSkwJLlzh2KiYAORFRH1xI_xpZFno',
//   authDomain: 'spout-monitor.firebaseapp.com',
//   databaseURL: 'https://spout-monitor.firebaseio.com',
//   projectId: 'spout-monitor',
//   storageBucket: 'spout-monitor.appspot.com',
//   // messagingSenderId: '489934786875',
//   appId: '1:489934786875:web:e51e466c96b35dea15271e',
//   measurementId: 'G-R3SSKCK902'
// };

/**
 * https://www.algolia.com/apps/WJA44FN552/api-keys/all
 */
export const algoliaConfig: AlgoliaSearchKeys = {
  appId: 'WJA44FN552',
  apiKey: '5bdaee7a8320f74a142eb14916ad1f30'
};

/**
 * https://www.algolia.com/apps/WJA44FN552/api-keys/all
 */
export const algoliaConfigDev: AlgoliaSearchKeys = {
  appId: 'F515PJQQMF',
  apiKey: 'af40d00fecc011013e8a9b32fd39d373'
};
