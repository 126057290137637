import {Update} from '@ngrx/entity';

export interface AggregateFirebaseSnapshotChangesUpdate<T> {
  added: T[];
  modified: Update<{id: string}>[];

  // list of ids
  removed: string[];
  all: T[];
}

export interface AggregateFirebaseSnapshotChangesEntities<T> {
  added: T[];
  // modified: Update<{ id: string }>[];
  modified: T[];
  removed: string[];
  entities: {[key: string]: T};

  // root node if dataset is a tree structure
  root: T | null;

  // If root node exists, will only include children
  all: T[];
}

export interface AggregateFirebaseSnapshotChanges<T> {
  added: T[];
  // modified: Update<{ id: string }>[];
  modified: T[];
  removed: string[];
  // If root node exists, will only include children
  all: T[];
}
