import {formatCurrency} from '@angular/common';
import {SubscriptionItemDict, PriceInterval, SubscriptionItem} from '@spout/web-global/models';
import {CurrentSubscriptionItem, MySubscriptionSpecs, MySubscriptionState} from './my-subscription.models';

export function findSubscriptionPlan(s: SubscriptionItem[]): SubscriptionItem | null {
  return s.reduce((p: SubscriptionItem | null, product: SubscriptionItem) => {
    if (product.price.product.metadata.addon !== 'storage') {
      return product;
    }

    return p;
  }, null);
}

export function findStoragePlan(s: SubscriptionItem[]): SubscriptionItem | null {
  return s.reduce((p: SubscriptionItem | null, product: SubscriptionItem) => {
    if (product.price.product.metadata.addon === 'storage') {
      return product;
    }

    return p;
  }, null);
}

export function getBaseStorageGB(s: SubscriptionItem): number {
  return parseInt(s.price.product.metadata.baseStorageGB, 10);
}

export function getCurrentSubscription(s: SubscriptionItemDict): MySubscriptionSpecs {
  let total = 0;
  const plan = findSubscriptionPlan(Object.values(s.subscriptionItemEntities));
  const storage = findStoragePlan(Object.values(s.subscriptionItemEntities));
  let baseStorageGB = 0;
  let storageIncluded = false;
  let additionalStorageQuantity = 0;
  let interval: PriceInterval = 'month';

  if (plan) {
    baseStorageGB = getBaseStorageGB(plan);
    storageIncluded = baseStorageGB > 0;
    total = total + plan.price.unit_amount;
    interval = <PriceInterval>plan.plan.interval;
  }

  if (storage) {
    additionalStorageQuantity = storage.quantity;
    total = total + storage.price.unit_amount * storage.quantity;
  }

  return {
    id: s.subscriptionId,
    plan,
    storage,
    baseStorageGB,
    additionalStorageQuantity,
    storageIncluded,
    total,
    interval
  };
}

export function getCurrentSubscriptionDataSource(state: MySubscriptionState): CurrentSubscriptionItem[] {
  const dataSource: CurrentSubscriptionItem[] = [];
  let total = 0;

  if (state.currentSubscriptions.plan) {
    dataSource.push({
      productDescription: {
        title: state.currentSubscriptions.plan.price.product.name,
        description: state.currentSubscriptions.plan.price.product.description
      },
      price: formatCurrency(state.currentSubscriptions.plan.price.unit_amount / 100, 'en-US', '$'),
      type: 'plan'
    });

    total = total + state.currentSubscriptions.plan.price.unit_amount;

    dataSource.push({
      productDescription: {
        title: 'Base Storage',
        description: `This plan comes with ${state.currentSubscriptions.plan.price.product.metadata.baseStorageGB} GB storage.`
      },
      price: 'included',
      type: 'included'
    });
  }

  if (state.currentSubscriptions.storage) {
    dataSource.push({
      productDescription: {
        title: state.currentSubscriptions.storage.price.product.name,
        description: state.currentSubscriptions.storage.price.product.description
      },
      price: formatCurrency(state.currentSubscriptions.storage.price.unit_amount / 100, 'en-US', '$'),
      type: 'plan'
    });

    total = total + state.currentSubscriptions.storage.price.unit_amount * state.currentSubscriptions.storage.quantity;
  }

  if (state.currentSubscriptions.plan) {
    dataSource.push({
      productDescription: {
        title: 'Total',
        description: ''
      },
      price: `${formatCurrency(total / 100, 'en-US', '$')} / ${state.currentSubscriptions.plan.plan.interval}`,
      type: 'total'
    });
  }

  return [...dataSource];
}
