import {Observable, Observer} from 'rxjs';

/**
 * Usage:
 *
 * this.store.pipe(
 *   select(selectSomeData),
 *   passThroughIfChanged()
 * .subscribe((result: any) => { ...  });
 *
 */
export const deepDistinctUntilChanged = <T>(): ((source: Observable<T>) => Observable<T>) => {
  let previousValueSerialized: string | undefined;

  return (source: Observable<T>) => {
    return new Observable((observer: Observer<T>) => {
      return source.subscribe({
        next(x: T) {
          try {
            // stringify for comparison
            //
            const nextValue = JSON.stringify(x);

            if (nextValue !== previousValueSerialized) {
              previousValueSerialized = nextValue;

              // Only pass data if it has changed.
              observer.next(x);
            }
          } catch (e) {
            console.error('Data is not a JSON parsable object.', e);
            observer.error('Data is not a JSON parsable object.');
          }
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        }
      });
    });
  };
};
