import {isDevMode} from '@angular/core';
import {
  createSelectorFactory,
  isNgrxMockEnvironment,
  MemoizedSelector,
  MemoizedSelectorWithProps,
  Selector,
  SelectorWithProps
} from '@ngrx/store';

/**
 * Memoized selector using JSON.stringify to perform deep comparison of previous and current values
 *
 * Created based on https://github.com/ngrx/platform/blob/a9e7fb177c4750ac5c8a39ea3898454669cde847/modules/store/src/selector.ts#L668
 */
export function createSerializedMemoizedSelector<State, S1, Result>(
  s1: Selector<State, S1>,
  projector: (s1: S1) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, Result>(
  s1: SelectorWithProps<State, Props, S1>,
  projector: (s1: S1, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;
export function createSerializedMemoizedSelector<State, S1, Result>(
  selectors: [Selector<State, S1>],
  projector: (s1: S1) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, Result>(
  selectors: [SelectorWithProps<State, Props, S1>],
  projector: (s1: S1, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;

export function createSerializedMemoizedSelector<State, S1, S2, Result>(
  s1: Selector<State, S1>,
  s2: Selector<State, S2>,
  projector: (s1: S1, s2: S2) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, Result>(
  s1: SelectorWithProps<State, Props, S1>,
  s2: SelectorWithProps<State, Props, S2>,
  projector: (s1: S1, s2: S2, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;

export function createSerializedMemoizedSelector<State, S1, S2, Result>(
  selectors: [Selector<State, S1>, Selector<State, S2>],
  projector: (s1: S1, s2: S2) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, Result>(
  selectors: [SelectorWithProps<State, Props, S1>, SelectorWithProps<State, Props, S2>],
  projector: (s1: S1, s2: S2, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;

export function createSerializedMemoizedSelector<State, S1, S2, S3, Result>(
  s1: Selector<State, S1>,
  s2: Selector<State, S2>,
  s3: Selector<State, S3>,
  projector: (s1: S1, s2: S2, s3: S3) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, Result>(
  s1: SelectorWithProps<State, Props, S1>,
  s2: SelectorWithProps<State, Props, S2>,
  s3: SelectorWithProps<State, Props, S3>,
  projector: (s1: S1, s2: S2, s3: S3, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;
export function createSerializedMemoizedSelector<State, S1, S2, S3, Result>(
  selectors: [Selector<State, S1>, Selector<State, S2>, Selector<State, S3>],
  projector: (s1: S1, s2: S2, s3: S3) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, Result>(
  selectors: [
    SelectorWithProps<State, Props, S1>,
    SelectorWithProps<State, Props, S2>,
    SelectorWithProps<State, Props, S3>
  ],
  projector: (s1: S1, s2: S2, s3: S3, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;

export function createSerializedMemoizedSelector<State, S1, S2, S3, S4, Result>(
  s1: Selector<State, S1>,
  s2: Selector<State, S2>,
  s3: Selector<State, S3>,
  s4: Selector<State, S4>,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, S4, Result>(
  s1: SelectorWithProps<State, Props, S1>,
  s2: SelectorWithProps<State, Props, S2>,
  s3: SelectorWithProps<State, Props, S3>,
  s4: SelectorWithProps<State, Props, S4>,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;
export function createSerializedMemoizedSelector<State, S1, S2, S3, S4, Result>(
  selectors: [Selector<State, S1>, Selector<State, S2>, Selector<State, S3>, Selector<State, S4>],
  projector: (s1: S1, s2: S2, s3: S3, s4: S4) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, S4, Result>(
  selectors: [
    SelectorWithProps<State, Props, S1>,
    SelectorWithProps<State, Props, S2>,
    SelectorWithProps<State, Props, S3>,
    SelectorWithProps<State, Props, S4>
  ],
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;

export function createSerializedMemoizedSelector<State, S1, S2, S3, S4, S5, Result>(
  s1: Selector<State, S1>,
  s2: Selector<State, S2>,
  s3: Selector<State, S3>,
  s4: Selector<State, S4>,
  s5: Selector<State, S5>,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, S4, S5, Result>(
  s1: SelectorWithProps<State, Props, S1>,
  s2: SelectorWithProps<State, Props, S2>,
  s3: SelectorWithProps<State, Props, S3>,
  s4: SelectorWithProps<State, Props, S4>,
  s5: SelectorWithProps<State, Props, S5>,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;
export function createSerializedMemoizedSelector<State, S1, S2, S3, S4, S5, Result>(
  selectors: [Selector<State, S1>, Selector<State, S2>, Selector<State, S3>, Selector<State, S4>, Selector<State, S5>],
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, S4, S5, Result>(
  selectors: [
    SelectorWithProps<State, Props, S1>,
    SelectorWithProps<State, Props, S2>,
    SelectorWithProps<State, Props, S3>,
    SelectorWithProps<State, Props, S4>,
    SelectorWithProps<State, Props, S5>
  ],
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;

export function createSerializedMemoizedSelector<State, S1, S2, S3, S4, S5, S6, Result>(
  s1: Selector<State, S1>,
  s2: Selector<State, S2>,
  s3: Selector<State, S3>,
  s4: Selector<State, S4>,
  s5: Selector<State, S5>,
  s6: Selector<State, S6>,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, S4, S5, S6, Result>(
  s1: SelectorWithProps<State, Props, S1>,
  s2: SelectorWithProps<State, Props, S2>,
  s3: SelectorWithProps<State, Props, S3>,
  s4: SelectorWithProps<State, Props, S4>,
  s5: SelectorWithProps<State, Props, S5>,
  s6: SelectorWithProps<State, Props, S6>,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;
export function createSerializedMemoizedSelector<State, S1, S2, S3, S4, S5, S6, Result>(
  selectors: [
    Selector<State, S1>,
    Selector<State, S2>,
    Selector<State, S3>,
    Selector<State, S4>,
    Selector<State, S5>,
    Selector<State, S6>
  ],
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, S4, S5, S6, Result>(
  selectors: [
    SelectorWithProps<State, Props, S1>,
    SelectorWithProps<State, Props, S2>,
    SelectorWithProps<State, Props, S3>,
    SelectorWithProps<State, Props, S4>,
    SelectorWithProps<State, Props, S5>,
    SelectorWithProps<State, Props, S6>
  ],
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;

export function createSerializedMemoizedSelector<State, S1, S2, S3, S4, S5, S6, S7, Result>(
  s1: Selector<State, S1>,
  s2: Selector<State, S2>,
  s3: Selector<State, S3>,
  s4: Selector<State, S4>,
  s5: Selector<State, S5>,
  s6: Selector<State, S6>,
  s7: Selector<State, S7>,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, S4, S5, S6, S7, Result>(
  s1: SelectorWithProps<State, Props, S1>,
  s2: SelectorWithProps<State, Props, S2>,
  s3: SelectorWithProps<State, Props, S3>,
  s4: SelectorWithProps<State, Props, S4>,
  s5: SelectorWithProps<State, Props, S5>,
  s6: SelectorWithProps<State, Props, S6>,
  s7: SelectorWithProps<State, Props, S7>,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;
export function createSerializedMemoizedSelector<State, S1, S2, S3, S4, S5, S6, S7, Result>(
  selectors: [
    Selector<State, S1>,
    Selector<State, S2>,
    Selector<State, S3>,
    Selector<State, S4>,
    Selector<State, S5>,
    Selector<State, S6>,
    Selector<State, S7>
  ],
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, S4, S5, S6, S7, Result>(
  selectors: [
    SelectorWithProps<State, Props, S1>,
    SelectorWithProps<State, Props, S2>,
    SelectorWithProps<State, Props, S3>,
    SelectorWithProps<State, Props, S4>,
    SelectorWithProps<State, Props, S5>,
    SelectorWithProps<State, Props, S6>,
    SelectorWithProps<State, Props, S7>
  ],
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;

export function createSerializedMemoizedSelector<State, S1, S2, S3, S4, S5, S6, S7, S8, Result>(
  s1: Selector<State, S1>,
  s2: Selector<State, S2>,
  s3: Selector<State, S3>,
  s4: Selector<State, S4>,
  s5: Selector<State, S5>,
  s6: Selector<State, S6>,
  s7: Selector<State, S7>,
  s8: Selector<State, S8>,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, S4, S5, S6, S7, S8, Result>(
  s1: SelectorWithProps<State, Props, S1>,
  s2: SelectorWithProps<State, Props, S2>,
  s3: SelectorWithProps<State, Props, S3>,
  s4: SelectorWithProps<State, Props, S4>,
  s5: SelectorWithProps<State, Props, S5>,
  s6: SelectorWithProps<State, Props, S6>,
  s7: SelectorWithProps<State, Props, S7>,
  s8: SelectorWithProps<State, Props, S8>,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;
export function createSerializedMemoizedSelector<State, S1, S2, S3, S4, S5, S6, S7, S8, Result>(
  selectors: [
    Selector<State, S1>,
    Selector<State, S2>,
    Selector<State, S3>,
    Selector<State, S4>,
    Selector<State, S5>,
    Selector<State, S6>,
    Selector<State, S7>,
    Selector<State, S8>
  ],
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result
): MemoizedSelector<State, Result>;
/**
 * @deprecated Selectors with props are deprecated, for more info see {@link https://github.com/ngrx/platform/issues/2980 Github Issue}
 */
export function createSerializedMemoizedSelector<State, Props, S1, S2, S3, S4, S5, S6, S7, S8, Result>(
  selectors: [
    SelectorWithProps<State, Props, S1>,
    SelectorWithProps<State, Props, S2>,
    SelectorWithProps<State, Props, S3>,
    SelectorWithProps<State, Props, S4>,
    SelectorWithProps<State, Props, S5>,
    SelectorWithProps<State, Props, S6>,
    SelectorWithProps<State, Props, S7>,
    SelectorWithProps<State, Props, S8>
  ],
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8, props: Props) => Result
): MemoizedSelectorWithProps<State, Props, Result>;
export function createSerializedMemoizedSelector(...input: any[]): MemoizedSelector<any, any> {
  return createSelectorFactory(projectionFunction => {
    // Value from JSON.stringify
    let previousValueSerialized: string | undefined;

    // Since JSON.parse is an expensive operation, minimize parsing by storing raw value;
    let previousValue: any | undefined;

    function memoized() {
      try {
        if (
          previousValueSerialized &&
          previousValueSerialized === JSON.stringify(projectionFunction.apply(null, [...arguments]))
        ) {
          return previousValue;
        } else {
          previousValue = projectionFunction.apply(null, [...arguments]);
          previousValueSerialized = JSON.stringify(previousValue);

          return previousValue;
        }
      } catch (e) {
        console.error('Data not serializable', e);
        return projectionFunction.apply(null, [...arguments]);
      }
    }

    return {
      memoized,
      reset: () => {},
      setResult: () => {},
      clearResult: () => {}
    };
  })(...input);
}

/**
 * Memoized feature selector using JSON.stringify to perform deep comparison of previous and current values
 *
 * Created based on https://github.com/ngrx/platform/blob/a9e7fb177c4750ac5c8a39ea3898454669cde847/modules/store/src/selector.ts#L668
 *
 * @param featureName
 */
export function createSerializedMemoizedFeatureSelector<T>(featureName: string): MemoizedSelector<object, T>;
export function createSerializedMemoizedFeatureSelector<T, V>(featureName: keyof T): MemoizedSelector<T, V>;
export function createSerializedMemoizedFeatureSelector(featureName: any): MemoizedSelector<any, any> {
  // NO MEMOIZATION HERE
  return createSerializedMemoizedSelector(
    (state: any) => {
      const featureState = state[featureName];
      if (!isNgrxMockEnvironment() && isDevMode() && !(featureName in state)) {
        console.warn(
          `@ngrx/store: The feature name "${featureName}" does ` +
            'not exist in the state, therefore createFeatureSelector ' +
            'cannot access it.  Be sure it is imported in a loaded module ' +
            `using StoreModule.forRoot('${featureName}', ...) or ` +
            `StoreModule.forFeature('${featureName}', ...).  If the default ` +
            'state is intended to be undefined, as is the case with router ' +
            'state, this development-only warning message can be ignored.'
        );
      }
      return featureState;
    },
    (featureState: any) => featureState
  );
}
