// - DEVICE STORE DATA - DEVICE STORE DATA - DEVICE STORE DATA - DEVICE STORE DATA
// - DEVICE STORE DATA - DEVICE STORE DATA - DEVICE STORE DATA - DEVICE STORE DATA
// - DEVICE STORE DATA - DEVICE STORE DATA - DEVICE STORE DATA - DEVICE STORE DATA
// - DEVICE STORE DATA - DEVICE STORE DATA - DEVICE STORE DATA - DEVICE STORE DATA
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {AudioFileMetaDataEntity, FileUint8ArrayType} from '@spout/any-shared/models';
import {TrackEntityAndAudioFileMetaDataEntity, WaveformValues} from './audio-buffer-data.model';

export interface ParsedDeviceStoreData {
  uint8ArrayType: FileUint8ArrayType;
}

// Parsing data
export interface ParsedRecordExport {
  trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity;
  audioBuffer: AudioBuffer;
  uint8ArrayType: FileUint8ArrayType;
  waveformValues: WaveformValues;
  recordOffsetMs: number;
}

export interface SaveUint8ArrayTypeToDevice {
  audioFileMetaDataEntity: AudioFileMetaDataEntity;
  uint8ArrayType: FileUint8ArrayType;
}
