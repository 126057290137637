export const LOGIN_KEY = 'login';

export const LOGIN_VALUE = {
  SHOW_UI: 'showUi',
  IS_WAITING_FOR_TOKEN_TO_SAVE: 'isWaitingForTokenToSave',
  IS_DONE: 'isDone'
};

export interface Login {
  otlid: string;
  login: boolean;
}

export interface EmailPassword {
  email: string;
  password: string;
}
