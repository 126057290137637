//tslint:disable
export const SVG_SPRITE: any = `<svg xmlns:xlink="http://www.w3.org/1999/xlink"><defs>
    <polygon id="path-1" points="0.86 0.348916667 2.441 0.348916667 2.441 7.94 0.86 7.94"/>
    <polygon id="path-3" points="0.06 0.0809556876 15.2604764 0.0809556876 15.2604764 71.729 0.06 71.729"/>
    <polygon id="path-5" points="0.06 0.091 4.139 0.091 4.139 49.753 0.06 49.753"/>
  <svg id="background" filename="halftone-soundwave-modern-music-design-element-isolated-on-white.svg" viewBox="0 0 597 251" width="597px" height="251px">
  
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="halftone-soundwave-modern-music-design-element-isolated-on-white" transform="translate(-0.000000, 0.000000)">
      <path d="M341.448,52.167 C341.448,51.27 342.175,50.542 343.072,50.542 C343.97,50.542 344.697,51.27 344.697,52.167 C344.697,53.064 343.97,53.792 343.072,53.792 C342.175,53.792 341.448,53.064 341.448,52.167" id="Fill-1" class="icon-color"/>
      <path d="M343.354,57.437 C344.162,57.593 344.69,58.374 344.535,59.182 C344.379,59.989 343.598,60.518 342.79,60.362 C341.983,60.207 341.454,59.426 341.61,58.618 C341.766,57.81 342.547,57.282 343.354,57.437" id="Fill-2" class="icon-color"/>
      <path d="M343.636,64.333 C344.354,64.644 344.684,65.479 344.372,66.196 C344.061,66.914 343.227,67.244 342.509,66.933 C341.791,66.621 341.461,65.787 341.772,65.069 C342.084,64.351 342.918,64.021 343.636,64.333" id="Fill-3" class="icon-color"/>
      <path d="M343.918,71.228 C344.546,71.695 344.677,72.583 344.21,73.211 C343.743,73.84 342.855,73.97 342.227,73.503 C341.599,73.036 341.468,72.148 341.935,71.52 C342.402,70.892 343.29,70.761 343.918,71.228" id="Fill-4" class="icon-color"/>
      <path d="M344.2,78.124 C344.738,78.746 344.67,79.688 344.047,80.226 C343.425,80.765 342.483,80.696 341.945,80.073 C341.406,79.451 341.475,78.509 342.097,77.971 C342.72,77.433 343.662,77.501 344.2,78.124" id="Fill-5" class="icon-color"/>
      <path d="M344.482,85.019 C344.931,85.798 344.663,86.792 343.885,87.241 C343.106,87.69 342.112,87.422 341.663,86.644 C341.214,85.865 341.482,84.871 342.26,84.422 C343.038,83.973 344.033,84.241 344.482,85.019" id="Fill-6" class="icon-color"/>
      <path d="M344.764,91.914 C345.123,92.849 344.656,93.897 343.722,94.256 C342.788,94.615 341.74,94.148 341.381,93.214 C341.022,92.28 341.488,91.232 342.422,90.873 C343.357,90.514 344.405,90.98 344.764,91.914" id="Fill-7" class="icon-color"/>
      <path d="M345.046,98.81 C345.315,99.9 344.65,101.001 343.56,101.271 C342.47,101.54 341.368,100.875 341.099,99.785 C340.83,98.695 341.495,97.593 342.585,97.324 C343.675,97.055 344.776,97.72 345.046,98.81" id="Fill-8" class="icon-color"/>
      <path d="M345.328,105.705 C345.507,106.951 344.643,108.106 343.397,108.286 C342.152,108.465 340.997,107.601 340.817,106.355 C340.638,105.11 341.502,103.955 342.747,103.775 C343.993,103.596 345.148,104.46 345.328,105.705" id="Fill-9" class="icon-color"/>
      <path d="M345.609,112.601 C345.699,114.002 344.636,115.211 343.235,115.3 C341.834,115.39 340.625,114.327 340.535,112.926 C340.445,111.525 341.509,110.316 342.91,110.226 C344.311,110.136 345.52,111.2 345.609,112.601" id="Fill-10" class="icon-color"/>
      <path d="M340.254,119.496 C340.254,117.939 341.516,116.677 343.072,116.677 C344.629,116.677 345.891,117.939 345.891,119.496 C345.891,121.053 344.629,122.315 343.072,122.315 C341.516,122.315 340.254,121.053 340.254,119.496" id="Fill-11" class="icon-color"/>
      <path d="M345.447,126.229 C345.447,127.541 344.384,128.604 343.072,128.604 C341.761,128.604 340.698,127.541 340.698,126.229 C340.698,124.918 341.761,123.855 343.072,123.855 C344.384,123.855 345.447,124.918 345.447,126.229" id="Fill-12" class="icon-color"/>
      <path d="M345.003,132.962 C345.003,134.028 344.138,134.892 343.072,134.892 C342.006,134.892 341.142,134.028 341.142,132.962 C341.142,131.896 342.006,131.032 343.072,131.032 C344.138,131.032 345.003,131.896 345.003,132.962" id="Fill-13" class="icon-color"/>
      <path d="M344.558,139.695 C344.558,140.515 343.893,141.181 343.072,141.181 C342.252,141.181 341.587,140.515 341.587,139.695 C341.587,138.874 342.252,138.209 343.072,138.209 C343.893,138.209 344.558,138.874 344.558,139.695" id="Fill-14" class="icon-color"/>
      <path d="M344.114,146.428 C344.114,147.003 343.647,147.469 343.072,147.469 C342.497,147.469 342.031,147.003 342.031,146.428 C342.031,145.853 342.497,145.386 343.072,145.386 C343.647,145.386 344.114,145.853 344.114,146.428" id="Fill-15" class="icon-color"/>
      <path d="M343.669,153.161 C343.669,153.491 343.402,153.758 343.072,153.758 C342.743,153.758 342.475,153.491 342.475,153.161 C342.475,152.831 342.743,152.564 343.072,152.564 C343.402,152.564 343.669,152.831 343.669,153.161" id="Fill-16" class="icon-color"/>
      <path d="M343.225,159.894 C343.225,159.978 343.157,160.046 343.072,160.046 C342.988,160.046 342.92,159.978 342.92,159.894 C342.92,159.809 342.988,159.741 343.072,159.741 C343.157,159.741 343.225,159.809 343.225,159.894" id="Fill-17" class="icon-color"/>
      <path d="M342.781,166.627 C342.781,166.466 342.911,166.335 343.072,166.335 C343.234,166.335 343.364,166.466 343.364,166.627 C343.364,166.788 343.234,166.918 343.072,166.918 C342.911,166.918 342.781,166.788 342.781,166.627" id="Fill-18" class="icon-color"/>
      <path d="M342.336,173.36 C342.336,172.953 342.666,172.623 343.072,172.623 C343.479,172.623 343.809,172.953 343.809,173.36 C343.809,173.766 343.479,174.096 343.072,174.096 C342.666,174.096 342.336,173.766 342.336,173.36" id="Fill-19" class="icon-color"/>
      <path d="M341.892,180.092 C341.892,179.441 342.421,178.912 343.072,178.912 C343.724,178.912 344.253,179.441 344.253,180.092 C344.253,180.744 343.724,181.273 343.072,181.273 C342.421,181.273 341.892,180.744 341.892,180.092" id="Fill-20" class="icon-color"/>
      <path d="M341.448,186.825 C341.448,185.928 342.175,185.2 343.072,185.2 C343.97,185.2 344.697,185.928 344.697,186.825 C344.697,187.723 343.97,188.45 343.072,188.45 C342.175,188.45 341.448,187.723 341.448,186.825" id="Fill-21" class="icon-color"/>
      <path d="M375.431,195.524 C375.431,196.322 374.785,196.968 373.987,196.968 C373.189,196.968 372.543,196.322 372.543,195.524 C372.543,194.726 373.189,194.08 373.987,194.08 C374.785,194.08 375.431,194.726 375.431,195.524" id="Fill-22" class="icon-color"/>
      <path d="M373.736,190.84 C373.019,190.701 372.549,190.007 372.687,189.289 C372.826,188.572 373.52,188.102 374.237,188.24 C374.955,188.379 375.425,189.073 375.287,189.791 C375.148,190.508 374.454,190.978 373.736,190.84" id="Fill-23" class="icon-color"/>
      <path d="M373.486,184.711 C372.848,184.435 372.555,183.693 372.832,183.055 C373.108,182.417 373.85,182.124 374.488,182.401 C375.126,182.678 375.419,183.419 375.142,184.057 C374.866,184.695 374.124,184.988 373.486,184.711" id="Fill-24" class="icon-color"/>
      <path d="M373.235,178.583 C372.677,178.168 372.561,177.379 372.976,176.82 C373.391,176.262 374.18,176.146 374.739,176.561 C375.297,176.976 375.413,177.765 374.998,178.324 C374.583,178.882 373.794,178.998 373.235,178.583" id="Fill-25" class="icon-color"/>
      <path d="M372.985,172.455 C372.506,171.901 372.567,171.064 373.12,170.586 C373.674,170.107 374.511,170.168 374.989,170.722 C375.468,171.275 375.407,172.112 374.853,172.59 C374.3,173.069 373.464,173.008 372.985,172.455" id="Fill-26" class="icon-color"/>
      <path d="M372.734,166.326 C372.336,165.634 372.573,164.75 373.265,164.351 C373.957,163.952 374.841,164.19 375.24,164.882 C375.638,165.574 375.401,166.458 374.709,166.857 C374.017,167.256 373.133,167.018 372.734,166.326" id="Fill-27" class="icon-color"/>
      <path d="M372.484,160.198 C372.165,159.367 372.579,158.436 373.409,158.117 C374.24,157.798 375.171,158.212 375.49,159.042 C375.809,159.872 375.395,160.804 374.565,161.123 C373.734,161.442 372.803,161.028 372.484,160.198" id="Fill-28" class="icon-color"/>
      <path d="M371.982,147.941 C371.823,146.834 372.591,145.807 373.698,145.648 C374.805,145.488 375.832,146.256 375.991,147.363 C376.151,148.47 375.383,149.497 374.276,149.656 C373.169,149.816 372.142,149.048 371.982,147.941" id="Fill-29" class="icon-color"/>
      <path d="M371.732,141.812 C371.652,140.567 372.597,139.493 373.843,139.413 C375.088,139.333 376.162,140.278 376.242,141.523 C376.322,142.769 375.377,143.843 374.132,143.923 C372.886,144.002 371.812,143.058 371.732,141.812" id="Fill-30" class="icon-color"/>
      <path d="M376.492,135.684 C376.492,137.068 375.371,138.189 373.987,138.189 C372.603,138.189 371.482,137.068 371.482,135.684 C371.482,134.3 372.603,133.178 373.987,133.178 C375.371,133.178 376.492,134.3 376.492,135.684" id="Fill-31" class="icon-color"/>
      <path d="M371.876,128.94 C371.876,127.774 372.821,126.83 373.987,126.83 C375.153,126.83 376.097,127.774 376.097,128.94 C376.097,130.105 375.153,131.05 373.987,131.05 C372.821,131.05 371.876,130.105 371.876,128.94" id="Fill-32" class="icon-color"/>
      <path d="M372.271,122.196 C372.271,121.248 373.04,120.48 373.987,120.48 C374.934,120.48 375.703,121.248 375.703,122.196 C375.703,123.143 374.934,123.911 373.987,123.911 C373.04,123.911 372.271,123.143 372.271,122.196" id="Fill-33" class="icon-color"/>
      <path d="M372.667,115.452 C372.667,114.723 373.258,114.131 373.987,114.131 C374.716,114.131 375.308,114.723 375.308,115.452 C375.308,116.181 374.716,116.773 373.987,116.773 C373.258,116.773 372.667,116.181 372.667,115.452" id="Fill-34" class="icon-color"/>
      <path d="M373.061,108.708 C373.061,108.197 373.476,107.782 373.987,107.782 C374.498,107.782 374.913,108.197 374.913,108.708 C374.913,109.219 374.498,109.634 373.987,109.634 C373.476,109.634 373.061,109.219 373.061,108.708" id="Fill-35" class="icon-color"/>
      <path d="M373.456,101.964 C373.456,101.671 373.694,101.433 373.987,101.433 C374.28,101.433 374.518,101.671 374.518,101.964 C374.518,102.257 374.28,102.495 373.987,102.495 C373.694,102.495 373.456,102.257 373.456,101.964" id="Fill-36" class="icon-color"/>
      <path d="M373.851,95.22 C373.851,95.145 373.912,95.084 373.987,95.084 C374.062,95.084 374.123,95.145 374.123,95.22 C374.123,95.295 374.062,95.356 373.987,95.356 C373.912,95.356 373.851,95.295 373.851,95.22" id="Fill-37" class="icon-color"/>
      <path d="M374.246,88.476 C374.246,88.619 374.13,88.735 373.987,88.735 C373.844,88.735 373.728,88.619 373.728,88.476 C373.728,88.333 373.844,88.217 373.987,88.217 C374.13,88.217 374.246,88.333 374.246,88.476" id="Fill-38" class="icon-color"/>
      <path d="M374.641,81.732 C374.641,82.093 374.348,82.386 373.987,82.386 C373.626,82.386 373.333,82.093 373.333,81.732 C373.333,81.371 373.626,81.078 373.987,81.078 C374.348,81.078 374.641,81.371 374.641,81.732" id="Fill-39" class="icon-color"/>
      <path d="M375.036,74.988 C375.036,75.568 374.566,76.037 373.987,76.037 C373.408,76.037 372.938,75.568 372.938,74.988 C372.938,74.409 373.408,73.939 373.987,73.939 C374.566,73.939 375.036,74.409 375.036,74.988" id="Fill-40" class="icon-color"/>
      <path d="M375.431,68.244 C375.431,69.042 374.785,69.688 373.987,69.688 C373.189,69.688 372.543,69.042 372.543,68.244 C372.543,67.447 373.189,66.8 373.987,66.8 C374.785,66.8 375.431,67.447 375.431,68.244" id="Fill-41" class="icon-color"/>
      <path d="M372.244,154.113 C371.981,153.15 372.548,152.157 373.51,151.893 C374.473,151.63 375.466,152.197 375.729,153.159 C375.993,154.122 375.426,155.115 374.464,155.378 C373.501,155.642 372.508,155.075 372.244,154.113" id="Fill-42" class="icon-color"/>
      <path d="M350.985,175.3 C350.985,176.162 350.287,176.861 349.425,176.861 C348.563,176.861 347.864,176.162 347.864,175.3 C347.864,174.439 348.563,173.74 349.425,173.74 C350.287,173.74 350.985,174.439 350.985,175.3" id="Fill-43" class="icon-color"/>
      <path d="M349.154,170.239 C348.378,170.089 347.871,169.339 348.02,168.564 C348.17,167.788 348.92,167.281 349.695,167.43 C350.471,167.58 350.979,168.33 350.829,169.105 C350.68,169.881 349.93,170.388 349.154,170.239" id="Fill-44" class="icon-color"/>
      <path d="M348.883,163.617 C348.194,163.318 347.877,162.517 348.176,161.827 C348.475,161.138 349.277,160.821 349.966,161.12 C350.655,161.419 350.972,162.221 350.673,162.91 C350.374,163.6 349.573,163.916 348.883,163.617" id="Fill-45" class="icon-color"/>
      <path d="M348.612,156.995 C348.009,156.547 347.884,155.694 348.332,155.091 C348.781,154.488 349.633,154.362 350.237,154.811 C350.84,155.259 350.965,156.112 350.517,156.715 C350.068,157.318 349.216,157.444 348.612,156.995" id="Fill-46" class="icon-color"/>
      <path d="M348.342,150.373 C347.825,149.775 347.89,148.871 348.488,148.354 C349.086,147.837 349.99,147.903 350.507,148.501 C351.025,149.099 350.959,150.003 350.361,150.52 C349.763,151.037 348.859,150.971 348.342,150.373" id="Fill-47" class="icon-color"/>
      <path d="M348.071,143.751 C347.64,143.004 347.897,142.049 348.644,141.618 C349.392,141.187 350.347,141.444 350.778,142.191 C351.209,142.939 350.952,143.894 350.205,144.325 C349.457,144.756 348.502,144.499 348.071,143.751" id="Fill-48" class="icon-color"/>
      <path d="M347.8,137.13 C347.456,136.233 347.903,135.226 348.801,134.881 C349.698,134.536 350.704,134.984 351.049,135.881 C351.394,136.778 350.946,137.785 350.049,138.13 C349.152,138.474 348.145,138.027 347.8,137.13" id="Fill-49" class="icon-color"/>
      <path d="M347.53,130.508 C347.271,129.461 347.91,128.403 348.956,128.145 C350.003,127.886 351.061,128.525 351.32,129.571 C351.578,130.618 350.939,131.676 349.893,131.935 C348.846,132.193 347.788,131.554 347.53,130.508" id="Fill-50" class="icon-color"/>
      <path d="M347.259,123.886 C347.086,122.69 347.917,121.581 349.112,121.408 C350.309,121.236 351.418,122.066 351.59,123.262 C351.763,124.458 350.933,125.567 349.737,125.74 C348.54,125.912 347.431,125.082 347.259,123.886" id="Fill-51" class="icon-color"/>
      <path d="M346.988,117.264 C346.902,115.918 347.923,114.758 349.269,114.671 C350.614,114.585 351.775,115.607 351.861,116.952 C351.947,118.298 350.926,119.458 349.581,119.544 C348.235,119.631 347.074,118.61 346.988,117.264" id="Fill-52" class="icon-color"/>
      <path d="M352.132,110.642 C352.132,112.137 350.92,113.349 349.425,113.349 C347.93,113.349 346.717,112.137 346.717,110.642 C346.717,109.147 347.93,107.935 349.425,107.935 C350.92,107.935 352.132,109.147 352.132,110.642" id="Fill-53" class="icon-color"/>
      <path d="M347.571,95.614 C347.571,94.59 348.401,93.761 349.425,93.761 C350.448,93.761 351.278,94.59 351.278,95.614 C351.278,96.638 350.448,97.468 349.425,97.468 C348.401,97.468 347.571,96.638 347.571,95.614" id="Fill-54" class="icon-color"/>
      <path d="M347.998,88.1 C347.998,87.312 348.637,86.673 349.425,86.673 C350.213,86.673 350.852,87.312 350.852,88.1 C350.852,88.888 350.213,89.527 349.425,89.527 C348.637,89.527 347.998,88.888 347.998,88.1" id="Fill-55" class="icon-color"/>
      <path d="M348.425,80.586 C348.425,80.034 348.872,79.586 349.425,79.586 C349.977,79.586 350.425,80.034 350.425,80.586 C350.425,81.138 349.977,81.586 349.425,81.586 C348.872,81.586 348.425,81.138 348.425,80.586" id="Fill-56" class="icon-color"/>
      <path d="M348.851,73.072 C348.851,72.755 349.108,72.499 349.425,72.499 C349.741,72.499 349.998,72.755 349.998,73.072 C349.998,73.388 349.741,73.645 349.425,73.645 C349.108,73.645 348.851,73.388 348.851,73.072" id="Fill-57" class="icon-color"/>
      <path d="M349.278,65.558 C349.278,65.477 349.344,65.411 349.425,65.411 C349.506,65.411 349.571,65.477 349.571,65.558 C349.571,65.639 349.506,65.704 349.425,65.704 C349.344,65.704 349.278,65.639 349.278,65.558" id="Fill-58" class="icon-color"/>
      <path d="M349.705,58.044 C349.705,58.199 349.579,58.324 349.425,58.324 C349.27,58.324 349.145,58.199 349.145,58.044 C349.145,57.889 349.27,57.764 349.425,57.764 C349.579,57.764 349.705,57.889 349.705,58.044" id="Fill-59" class="icon-color"/>
      <path d="M350.132,50.53 C350.132,50.92 349.815,51.237 349.425,51.237 C349.034,51.237 348.718,50.92 348.718,50.53 C348.718,50.139 349.034,49.823 349.425,49.823 C349.815,49.823 350.132,50.139 350.132,50.53" id="Fill-60" class="icon-color"/>
      <path d="M350.558,43.016 C350.558,43.642 350.051,44.149 349.425,44.149 C348.798,44.149 348.291,43.642 348.291,43.016 C348.291,42.389 348.798,41.882 349.425,41.882 C350.051,41.882 350.558,42.389 350.558,43.016" id="Fill-61" class="icon-color"/>
      <path d="M350.985,35.502 C350.985,36.363 350.287,37.062 349.425,37.062 C348.563,37.062 347.864,36.363 347.864,35.502 C347.864,34.64 348.563,33.941 349.425,33.941 C350.287,33.941 350.985,34.64 350.985,35.502" id="Fill-62" class="icon-color"/>
      <path d="M347.144,103.128 C347.144,101.869 348.165,100.848 349.425,100.848 C350.684,100.848 351.705,101.869 351.705,103.128 C351.705,104.388 350.684,105.409 349.425,105.409 C348.165,105.409 347.144,104.388 347.144,103.128" id="Fill-63" class="icon-color"/>
      <path d="M369.51,183.875 C369.51,184.715 368.829,185.396 367.988,185.396 C367.148,185.396 366.468,184.715 366.468,183.875 C366.468,183.035 367.148,182.354 367.988,182.354 C368.829,182.354 369.51,183.035 369.51,183.875" id="Fill-64" class="icon-color"/>
      <path d="M367.695,179.002 C366.949,178.841 366.474,178.104 366.637,177.357 C366.798,176.61 367.535,176.136 368.282,176.298 C369.029,176.46 369.503,177.197 369.341,177.944 C369.179,178.69 368.442,179.165 367.695,179.002" id="Fill-65" class="icon-color"/>
      <path d="M367.402,172.609 C366.749,172.285 366.482,171.493 366.805,170.84 C367.129,170.186 367.922,169.919 368.575,170.243 C369.228,170.567 369.496,171.359 369.172,172.012 C368.848,172.666 368.056,172.933 367.402,172.609" id="Fill-66" class="icon-color"/>
      <path d="M367.109,166.216 C366.549,165.73 366.489,164.882 366.974,164.322 C367.46,163.762 368.308,163.702 368.868,164.187 C369.428,164.673 369.489,165.521 369.003,166.081 C368.517,166.641 367.669,166.701 367.109,166.216" id="Fill-67" class="icon-color"/>
      <path d="M366.816,159.822 C366.349,159.174 366.496,158.271 367.144,157.804 C367.791,157.337 368.695,157.484 369.161,158.132 C369.628,158.78 369.482,159.683 368.834,160.15 C368.186,160.617 367.283,160.47 366.816,159.822" id="Fill-68" class="icon-color"/>
      <path d="M366.522,153.429 C366.149,152.619 366.503,151.66 367.313,151.286 C368.122,150.913 369.081,151.267 369.455,152.077 C369.828,152.886 369.474,153.845 368.665,154.219 C367.855,154.592 366.896,154.238 366.522,153.429" id="Fill-69" class="icon-color"/>
      <path d="M366.229,147.035 C365.949,146.064 366.51,145.049 367.482,144.769 C368.453,144.489 369.468,145.049 369.748,146.021 C370.028,146.993 369.467,148.007 368.496,148.288 C367.524,148.568 366.509,148.007 366.229,147.035" id="Fill-70" class="icon-color"/>
      <path d="M365.936,140.642 C365.749,139.508 366.517,138.438 367.651,138.251 C368.784,138.064 369.855,138.832 370.041,139.966 C370.228,141.099 369.46,142.17 368.327,142.356 C367.193,142.543 366.123,141.775 365.936,140.642" id="Fill-71" class="icon-color"/>
      <path d="M365.643,134.248 C365.549,132.953 366.524,131.827 367.82,131.734 C369.115,131.64 370.241,132.615 370.335,133.91 C370.428,135.206 369.453,136.332 368.158,136.425 C366.862,136.519 365.736,135.544 365.643,134.248" id="Fill-72" class="icon-color"/>
      <path d="M365.812,120.851 C365.812,119.649 366.786,118.675 367.988,118.675 C369.191,118.675 370.165,119.649 370.165,120.851 C370.165,122.054 369.191,123.028 367.988,123.028 C366.786,123.028 365.812,122.054 365.812,120.851" id="Fill-73" class="icon-color"/>
      <path d="M366.274,113.848 C366.274,112.901 367.042,112.134 367.988,112.134 C368.936,112.134 369.703,112.901 369.703,113.848 C369.703,114.795 368.936,115.563 367.988,115.563 C367.042,115.563 366.274,114.795 366.274,113.848" id="Fill-74" class="icon-color"/>
      <path d="M366.736,106.844 C366.736,106.153 367.297,105.592 367.988,105.592 C368.68,105.592 369.241,106.153 369.241,106.844 C369.241,107.536 368.68,108.097 367.988,108.097 C367.297,108.097 366.736,107.536 366.736,106.844" id="Fill-75" class="icon-color"/>
      <path d="M367.198,99.841 C367.198,99.405 367.552,99.051 367.988,99.051 C368.425,99.051 368.779,99.405 368.779,99.841 C368.779,100.277 368.425,100.631 367.988,100.631 C367.552,100.631 367.198,100.277 367.198,99.841" id="Fill-76" class="icon-color"/>
      <path d="M367.661,92.837 C367.661,92.656 367.808,92.51 367.988,92.51 C368.17,92.51 368.316,92.656 368.316,92.837 C368.316,93.019 368.17,93.165 367.988,93.165 C367.808,93.165 367.661,93.019 367.661,92.837" id="Fill-77" class="icon-color"/>
      <path d="M368.123,85.834 C368.123,85.908 368.063,85.968 367.988,85.968 C367.914,85.968 367.854,85.908 367.854,85.834 C367.854,85.76 367.914,85.7 367.988,85.7 C368.063,85.7 368.123,85.76 368.123,85.834" id="Fill-78" class="icon-color"/>
      <path d="M368.585,78.831 C368.585,79.16 368.318,79.427 367.988,79.427 C367.659,79.427 367.392,79.16 367.392,78.831 C367.392,78.501 367.659,78.234 367.988,78.234 C368.318,78.234 368.585,78.501 368.585,78.831" id="Fill-79" class="icon-color"/>
      <path d="M369.048,71.827 C369.048,72.412 368.573,72.886 367.988,72.886 C367.404,72.886 366.93,72.412 366.93,71.827 C366.93,71.242 367.404,70.768 367.988,70.768 C368.573,70.768 369.048,71.242 369.048,71.827" id="Fill-80" class="icon-color"/>
      <path d="M369.51,64.824 C369.51,65.664 368.829,66.345 367.988,66.345 C367.148,66.345 366.468,65.664 366.468,64.824 C366.468,63.984 367.148,63.302 367.988,63.302 C368.829,63.302 369.51,63.984 369.51,64.824" id="Fill-81" class="icon-color"/>
      <path d="M370.628,127.855 C370.628,129.312 369.446,130.494 367.988,130.494 C366.531,130.494 365.35,129.312 365.35,127.855 C365.35,126.397 366.531,125.216 367.988,125.216 C369.446,125.216 370.628,126.397 370.628,127.855" id="Fill-82" class="icon-color"/>
      <path d="M364.583,123.312 C364.583,124.762 363.408,125.937 361.958,125.937 C360.508,125.937 359.333,124.762 359.333,123.312 C359.333,121.862 360.508,120.687 361.958,120.687 C363.408,120.687 364.583,121.862 364.583,123.312" id="Fill-83" class="icon-color"/>
      <path d="M362.044,119.727 C360.74,119.775 359.643,118.756 359.595,117.451 C359.547,116.146 360.567,115.049 361.871,115.001 C363.176,114.954 364.273,115.973 364.32,117.277 C364.368,118.582 363.349,119.679 362.044,119.727" id="Fill-84" class="icon-color"/>
      <path d="M362.14,107.307 C361.125,107.45 360.186,106.744 360.043,105.729 C359.899,104.714 360.606,103.775 361.621,103.632 C362.635,103.488 363.574,104.194 363.718,105.209 C363.861,106.224 363.155,107.163 362.14,107.307" id="Fill-85" class="icon-color"/>
      <path d="M362.202,101.097 C361.332,101.288 360.472,100.738 360.281,99.868 C360.09,98.998 360.64,98.138 361.51,97.947 C362.38,97.755 363.24,98.305 363.431,99.175 C363.622,100.045 363.072,100.905 362.202,101.097" id="Fill-86" class="icon-color"/>
      <path d="M362.265,94.887 C361.54,95.126 360.758,94.732 360.519,94.007 C360.28,93.282 360.674,92.501 361.399,92.262 C362.124,92.023 362.905,92.416 363.144,93.141 C363.383,93.866 362.99,94.648 362.265,94.887" id="Fill-87" class="icon-color"/>
      <path d="M362.327,88.677 C361.747,88.964 361.044,88.726 360.757,88.146 C360.47,87.566 360.708,86.864 361.288,86.577 C361.868,86.29 362.571,86.527 362.857,87.107 C363.145,87.687 362.907,88.39 362.327,88.677" id="Fill-88" class="icon-color"/>
      <path d="M362.432,82.467 C361.997,82.802 361.373,82.72 361.038,82.285 C360.703,81.85 360.785,81.226 361.22,80.892 C361.655,80.557 362.279,80.638 362.613,81.073 C362.948,81.508 362.867,82.132 362.432,82.467" id="Fill-89" class="icon-color"/>
      <path d="M362.564,76.257 C362.274,76.64 361.729,76.715 361.346,76.425 C360.964,76.135 360.889,75.589 361.179,75.207 C361.469,74.824 362.014,74.749 362.396,75.039 C362.779,75.329 362.854,75.874 362.564,76.257" id="Fill-90" class="icon-color"/>
      <path d="M362.737,70.047 C362.592,70.478 362.126,70.709 361.695,70.564 C361.265,70.419 361.034,69.952 361.179,69.522 C361.324,69.092 361.79,68.86 362.22,69.005 C362.651,69.15 362.882,69.617 362.737,70.047" id="Fill-91" class="icon-color"/>
      <path d="M362.824,63.837 C362.824,64.315 362.436,64.703 361.958,64.703 C361.48,64.703 361.092,64.315 361.092,63.837 C361.092,63.359 361.48,62.971 361.958,62.971 C362.436,62.971 362.824,63.359 362.824,63.837" id="Fill-92" class="icon-color"/>
      <path d="M362.078,113.517 C360.918,113.612 359.9,112.75 359.805,111.59 C359.709,110.43 360.572,109.412 361.732,109.317 C362.891,109.221 363.909,110.084 364.005,111.244 C364.1,112.403 363.238,113.421 362.078,113.517" id="Fill-93" class="icon-color"/>
      <path d="M237.707,72.628 C237.707,71.831 238.353,71.184 239.151,71.184 C239.948,71.184 240.595,71.831 240.595,72.628 C240.595,73.426 239.948,74.072 239.151,74.072 C238.353,74.072 237.707,73.426 237.707,72.628" id="Fill-94" class="icon-color"/>
      <path d="M239.401,77.313 C240.119,77.451 240.589,78.145 240.451,78.863 C240.312,79.581 239.618,80.05 238.9,79.912 C238.182,79.774 237.713,79.08 237.851,78.362 C237.99,77.644 238.684,77.174 239.401,77.313" id="Fill-95" class="icon-color"/>
      <path d="M239.652,83.441 C240.29,83.718 240.583,84.459 240.306,85.098 C240.029,85.736 239.288,86.028 238.65,85.752 C238.012,85.475 237.719,84.733 237.995,84.095 C238.272,83.457 239.014,83.164 239.652,83.441" id="Fill-96" class="icon-color"/>
      <path d="M239.902,89.569 C240.461,89.985 240.577,90.774 240.162,91.332 C239.747,91.89 238.958,92.006 238.399,91.591 C237.841,91.176 237.725,90.387 238.14,89.829 C238.555,89.27 239.344,89.154 239.902,89.569" id="Fill-97" class="icon-color"/>
      <path d="M240.153,95.698 C240.632,96.251 240.571,97.088 240.017,97.567 C239.464,98.045 238.627,97.984 238.149,97.431 C237.67,96.878 237.731,96.041 238.284,95.562 C238.838,95.084 239.674,95.145 240.153,95.698" id="Fill-98" class="icon-color"/>
      <path d="M240.404,101.826 C240.802,102.518 240.565,103.402 239.873,103.801 C239.181,104.2 238.297,103.962 237.898,103.271 C237.499,102.579 237.737,101.694 238.429,101.296 C239.121,100.897 240.005,101.135 240.404,101.826" id="Fill-99" class="icon-color"/>
      <path d="M240.654,107.955 C240.973,108.785 240.559,109.717 239.729,110.036 C238.898,110.355 237.967,109.94 237.648,109.11 C237.329,108.28 237.743,107.348 238.573,107.029 C239.403,106.71 240.335,107.125 240.654,107.955" id="Fill-100" class="icon-color"/>
      <path d="M240.905,114.083 C241.144,115.052 240.553,116.031 239.584,116.27 C238.615,116.51 237.636,115.918 237.397,114.95 C237.158,113.981 237.749,113.002 238.718,112.763 C239.686,112.524 240.665,113.115 240.905,114.083" id="Fill-101" class="icon-color"/>
      <path d="M241.155,120.212 C241.315,121.319 240.547,122.345 239.44,122.505 C238.333,122.665 237.306,121.896 237.146,120.789 C236.987,119.682 237.755,118.656 238.862,118.496 C239.969,118.337 240.996,119.105 241.155,120.212" id="Fill-102" class="icon-color"/>
      <path d="M241.406,126.34 C241.486,127.585 240.541,128.66 239.295,128.739 C238.05,128.819 236.976,127.874 236.896,126.629 C236.816,125.384 237.761,124.309 239.006,124.23 C240.252,124.15 241.326,125.095 241.406,126.34" id="Fill-103" class="icon-color"/>
      <path d="M241.261,138.453 C241.261,139.618 240.316,140.563 239.151,140.563 C237.985,140.563 237.04,139.618 237.04,138.453 C237.04,137.287 237.985,136.342 239.151,136.342 C240.316,136.342 241.261,137.287 241.261,138.453" id="Fill-104" class="icon-color"/>
      <path d="M240.866,144.437 C240.866,145.384 240.098,146.152 239.151,146.152 C238.203,146.152 237.435,145.384 237.435,144.437 C237.435,143.489 238.203,142.721 239.151,142.721 C240.098,142.721 240.866,143.489 240.866,144.437" id="Fill-105" class="icon-color"/>
      <path d="M240.471,150.421 C240.471,151.15 239.88,151.741 239.151,151.741 C238.421,151.741 237.83,151.15 237.83,150.421 C237.83,149.691 238.421,149.1 239.151,149.1 C239.88,149.1 240.471,149.691 240.471,150.421" id="Fill-106" class="icon-color"/>
      <path d="M240.076,156.405 C240.076,156.916 239.662,157.33 239.151,157.33 C238.64,157.33 238.225,156.916 238.225,156.405 C238.225,155.894 238.64,155.479 239.151,155.479 C239.662,155.479 240.076,155.894 240.076,156.405" id="Fill-107" class="icon-color"/>
      <path d="M239.681,162.389 C239.681,162.682 239.444,162.919 239.151,162.919 C238.858,162.919 238.62,162.682 238.62,162.389 C238.62,162.096 238.858,161.858 239.151,161.858 C239.444,161.858 239.681,162.096 239.681,162.389" id="Fill-108" class="icon-color"/>
      <path d="M239.286,168.373 C239.286,168.448 239.226,168.508 239.151,168.508 C239.076,168.508 239.015,168.448 239.015,168.373 C239.015,168.298 239.076,168.237 239.151,168.237 C239.226,168.237 239.286,168.298 239.286,168.373" id="Fill-109" class="icon-color"/>
      <path d="M238.892,174.357 C238.892,174.213 239.008,174.097 239.151,174.097 C239.294,174.097 239.41,174.213 239.41,174.357 C239.41,174.5 239.294,174.616 239.151,174.616 C239.008,174.616 238.892,174.5 238.892,174.357" id="Fill-110" class="icon-color"/>
      <path d="M238.497,180.341 C238.497,179.98 238.79,179.687 239.151,179.687 C239.512,179.687 239.805,179.98 239.805,180.341 C239.805,180.702 239.512,180.995 239.151,180.995 C238.79,180.995 238.497,180.702 238.497,180.341" id="Fill-111" class="icon-color"/>
      <path d="M238.102,186.325 C238.102,185.745 238.571,185.276 239.151,185.276 C239.73,185.276 240.2,185.745 240.2,186.325 C240.2,186.904 239.73,187.374 239.151,187.374 C238.571,187.374 238.102,186.904 238.102,186.325" id="Fill-112" class="icon-color"/>
      <path d="M237.707,192.309 C237.707,191.511 238.353,190.865 239.151,190.865 C239.948,190.865 240.595,191.511 240.595,192.309 C240.595,193.107 239.948,193.753 239.151,193.753 C238.353,193.753 237.707,193.107 237.707,192.309" id="Fill-113" class="icon-color"/>
      <path d="M236.645,132.469 C236.645,131.085 237.767,129.963 239.151,129.963 C240.535,129.963 241.656,131.085 241.656,132.469 C241.656,133.852 240.535,134.974 239.151,134.974 C237.767,134.974 236.645,133.852 236.645,132.469" id="Fill-114" class="icon-color"/>
      <path d="M246.67,198.431 C246.67,199.271 245.989,199.952 245.149,199.952 C244.309,199.952 243.628,199.271 243.628,198.431 C243.628,197.59 244.309,196.909 245.149,196.909 C245.989,196.909 246.67,197.59 246.67,198.431" id="Fill-115" class="icon-color"/>
      <path d="M244.856,193.558 C244.109,193.396 243.635,192.66 243.797,191.913 C243.959,191.166 244.696,190.692 245.442,190.854 C246.189,191.016 246.663,191.753 246.501,192.5 C246.339,193.246 245.603,193.72 244.856,193.558" id="Fill-116" class="icon-color"/>
      <path d="M244.563,187.165 C243.909,186.841 243.642,186.049 243.966,185.395 C244.29,184.742 245.082,184.475 245.736,184.799 C246.389,185.122 246.656,185.915 246.332,186.568 C246.008,187.222 245.216,187.489 244.563,187.165" id="Fill-117" class="icon-color"/>
      <path d="M244.269,180.771 C243.709,180.286 243.649,179.438 244.135,178.878 C244.621,178.318 245.469,178.257 246.029,178.743 C246.589,179.229 246.649,180.077 246.163,180.637 C245.677,181.197 244.83,181.257 244.269,180.771" id="Fill-118" class="icon-color"/>
      <path d="M243.976,174.378 C243.51,173.73 243.656,172.827 244.304,172.36 C244.952,171.893 245.855,172.04 246.322,172.688 C246.789,173.336 246.642,174.239 245.994,174.706 C245.346,175.172 244.443,175.026 243.976,174.378" id="Fill-119" class="icon-color"/>
      <path d="M243.683,167.985 C243.31,167.175 243.663,166.216 244.473,165.842 C245.283,165.469 246.242,165.823 246.615,166.632 C246.989,167.442 246.635,168.401 245.825,168.775 C245.016,169.148 244.056,168.794 243.683,167.985" id="Fill-120" class="icon-color"/>
      <path d="M243.39,161.591 C243.11,160.619 243.67,159.605 244.642,159.325 C245.614,159.045 246.628,159.605 246.908,160.577 C247.189,161.549 246.628,162.563 245.656,162.843 C244.684,163.123 243.67,162.563 243.39,161.591" id="Fill-121" class="icon-color"/>
      <path d="M243.096,155.198 C242.91,154.064 243.678,152.994 244.811,152.807 C245.945,152.62 247.015,153.388 247.202,154.521 C247.388,155.655 246.621,156.726 245.487,156.912 C244.354,157.099 243.283,156.331 243.096,155.198" id="Fill-122" class="icon-color"/>
      <path d="M242.803,148.804 C242.71,147.509 243.685,146.383 244.98,146.289 C246.276,146.196 247.402,147.171 247.495,148.466 C247.588,149.762 246.614,150.888 245.318,150.981 C244.023,151.074 242.897,150.1 242.803,148.804" id="Fill-123" class="icon-color"/>
      <path d="M242.972,135.407 C242.972,134.205 243.947,133.23 245.149,133.23 C246.351,133.23 247.326,134.205 247.326,135.407 C247.326,136.61 246.351,137.584 245.149,137.584 C243.947,137.584 242.972,136.61 242.972,135.407" id="Fill-124" class="icon-color"/>
      <path d="M243.435,128.404 C243.435,127.457 244.202,126.689 245.149,126.689 C246.096,126.689 246.864,127.457 246.864,128.404 C246.864,129.351 246.096,130.118 245.149,130.118 C244.202,130.118 243.435,129.351 243.435,128.404" id="Fill-125" class="icon-color"/>
      <path d="M243.897,121.4 C243.897,120.709 244.458,120.148 245.149,120.148 C245.841,120.148 246.402,120.709 246.402,121.4 C246.402,122.092 245.841,122.653 245.149,122.653 C244.458,122.653 243.897,122.092 243.897,121.4" id="Fill-126" class="icon-color"/>
      <path d="M244.359,114.397 C244.359,113.96 244.713,113.607 245.149,113.607 C245.585,113.607 245.939,113.96 245.939,114.397 C245.939,114.833 245.585,115.187 245.149,115.187 C244.713,115.187 244.359,114.833 244.359,114.397" id="Fill-127" class="icon-color"/>
      <path d="M244.821,107.393 C244.821,107.212 244.968,107.066 245.149,107.066 C245.33,107.066 245.477,107.212 245.477,107.393 C245.477,107.574 245.33,107.721 245.149,107.721 C244.968,107.721 244.821,107.574 244.821,107.393" id="Fill-128" class="icon-color"/>
      <path d="M245.283,100.39 C245.283,100.464 245.223,100.524 245.149,100.524 C245.075,100.524 245.015,100.464 245.015,100.39 C245.015,100.316 245.075,100.255 245.149,100.255 C245.223,100.255 245.283,100.316 245.283,100.39" id="Fill-129" class="icon-color"/>
      <path d="M245.746,93.386 C245.746,93.716 245.479,93.983 245.149,93.983 C244.82,93.983 244.552,93.716 244.552,93.386 C244.552,93.057 244.82,92.79 245.149,92.79 C245.479,92.79 245.746,93.057 245.746,93.386" id="Fill-130" class="icon-color"/>
      <path d="M246.208,86.383 C246.208,86.968 245.734,87.442 245.149,87.442 C244.564,87.442 244.09,86.968 244.09,86.383 C244.09,85.798 244.564,85.324 245.149,85.324 C245.734,85.324 246.208,85.798 246.208,86.383" id="Fill-131" class="icon-color"/>
      <path d="M246.67,79.379 C246.67,80.22 245.989,80.901 245.149,80.901 C244.309,80.901 243.628,80.22 243.628,79.379 C243.628,78.539 244.309,77.858 245.149,77.858 C245.989,77.858 246.67,78.539 246.67,79.379" id="Fill-132" class="icon-color"/>
      <path d="M247.788,142.411 C247.788,143.868 246.607,145.05 245.149,145.05 C243.692,145.05 242.51,143.868 242.51,142.411 C242.51,140.953 243.692,139.772 245.149,139.772 C246.607,139.772 247.788,140.953 247.788,142.411" id="Fill-133" class="icon-color"/>
      <path d="M253.905,115.676 C253.905,117.126 252.73,118.302 251.28,118.302 C249.83,118.302 248.655,117.126 248.655,115.676 C248.655,114.226 249.83,113.051 251.28,113.051 C252.73,113.051 253.905,114.226 253.905,115.676" id="Fill-134" class="icon-color"/>
      <path d="M251.367,112.392 C250.062,112.439 248.965,111.42 248.918,110.115 C248.87,108.811 249.889,107.714 251.194,107.666 C252.499,107.619 253.595,108.637 253.643,109.942 C253.691,111.247 252.672,112.344 251.367,112.392" id="Fill-135" class="icon-color"/>
      <path d="M251.402,106.481 C250.242,106.577 249.225,105.714 249.129,104.554 C249.033,103.395 249.896,102.377 251.056,102.281 C252.216,102.185 253.234,103.048 253.329,104.208 C253.425,105.368 252.562,106.386 251.402,106.481" id="Fill-136" class="icon-color"/>
      <path d="M251.466,100.571 C250.451,100.715 249.512,100.009 249.368,98.994 C249.225,97.979 249.931,97.04 250.946,96.896 C251.961,96.753 252.9,97.459 253.044,98.474 C253.187,99.489 252.481,100.428 251.466,100.571" id="Fill-137" class="icon-color"/>
      <path d="M251.529,94.661 C250.659,94.853 249.799,94.303 249.608,93.433 C249.417,92.563 249.967,91.702 250.837,91.511 C251.706,91.32 252.567,91.87 252.758,92.74 C252.949,93.61 252.399,94.47 251.529,94.661" id="Fill-138" class="icon-color"/>
      <path d="M251.593,88.751 C250.868,88.99 250.086,88.597 249.847,87.872 C249.608,87.147 250.002,86.365 250.727,86.126 C251.452,85.887 252.233,86.281 252.472,87.006 C252.712,87.731 252.318,88.512 251.593,88.751" id="Fill-139" class="icon-color"/>
      <path d="M251.656,82.841 C251.076,83.128 250.374,82.891 250.087,82.311 C249.8,81.731 250.037,81.028 250.617,80.741 C251.197,80.454 251.9,80.692 252.187,81.272 C252.474,81.852 252.236,82.554 251.656,82.841" id="Fill-140" class="icon-color"/>
      <path d="M251.74,76.931 C251.305,77.266 250.681,77.185 250.346,76.75 C250.011,76.315 250.092,75.691 250.527,75.356 C250.962,75.021 251.586,75.103 251.921,75.538 C252.256,75.973 252.175,76.596 251.74,76.931" id="Fill-141" class="icon-color"/>
      <path d="M251.877,71.021 C251.587,71.404 251.042,71.479 250.659,71.189 C250.276,70.899 250.201,70.354 250.491,69.971 C250.781,69.589 251.326,69.514 251.709,69.804 C252.092,70.094 252.167,70.639 251.877,71.021" id="Fill-142" class="icon-color"/>
      <path d="M252.063,65.112 C251.918,65.542 251.452,65.773 251.021,65.628 C250.591,65.483 250.36,65.017 250.505,64.587 C250.65,64.156 251.116,63.925 251.547,64.07 C251.977,64.215 252.208,64.681 252.063,65.112" id="Fill-143" class="icon-color"/>
      <path d="M252.15,59.202 C252.15,59.68 251.763,60.068 251.285,60.068 C250.806,60.068 250.419,59.68 250.419,59.202 C250.419,58.724 250.806,58.336 251.285,58.336 C251.763,58.336 252.15,58.724 252.15,59.202" id="Fill-144" class="icon-color"/>
      <path d="M248.655,121.906 C248.655,120.456 249.83,119.281 251.28,119.281 C252.73,119.281 253.905,120.456 253.905,121.906 C253.905,123.356 252.73,124.531 251.28,124.531 C249.83,124.531 248.655,123.356 248.655,121.906" id="Fill-145" class="icon-color"/>
      <path d="M251.16,131.701 C252.32,131.605 253.338,132.468 253.433,133.628 C253.529,134.788 252.666,135.806 251.506,135.901 C250.346,135.997 249.329,135.134 249.233,133.974 C249.137,132.814 250,131.797 251.16,131.701" id="Fill-146" class="icon-color"/>
      <path d="M251.098,137.911 C252.113,137.768 253.052,138.474 253.195,139.489 C253.339,140.504 252.632,141.443 251.617,141.586 C250.602,141.73 249.663,141.023 249.52,140.008 C249.376,138.993 250.083,138.054 251.098,137.911" id="Fill-147" class="icon-color"/>
      <path d="M251.035,144.121 C251.905,143.93 252.766,144.48 252.957,145.35 C253.148,146.22 252.598,147.08 251.728,147.271 C250.858,147.462 249.998,146.912 249.807,146.042 C249.615,145.173 250.166,144.312 251.035,144.121" id="Fill-148" class="icon-color"/>
      <path d="M250.973,150.331 C251.698,150.092 252.48,150.486 252.719,151.211 C252.958,151.935 252.564,152.717 251.839,152.956 C251.114,153.195 250.333,152.801 250.094,152.077 C249.854,151.352 250.248,150.57 250.973,150.331" id="Fill-149" class="icon-color"/>
      <path d="M250.911,156.541 C251.491,156.254 252.193,156.492 252.48,157.072 C252.767,157.651 252.53,158.354 251.95,158.641 C251.37,158.928 250.667,158.691 250.38,158.111 C250.093,157.531 250.331,156.828 250.911,156.541" id="Fill-150" class="icon-color"/>
      <path d="M250.806,162.751 C251.241,162.416 251.865,162.497 252.2,162.932 C252.534,163.367 252.453,163.991 252.018,164.326 C251.583,164.661 250.959,164.58 250.624,164.145 C250.29,163.71 250.371,163.086 250.806,162.751" id="Fill-151" class="icon-color"/>
      <path d="M250.674,168.961 C250.964,168.578 251.509,168.503 251.891,168.793 C252.274,169.083 252.349,169.628 252.059,170.011 C251.769,170.393 251.224,170.469 250.841,170.178 C250.459,169.889 250.384,169.343 250.674,168.961" id="Fill-152" class="icon-color"/>
      <path d="M250.501,175.171 C250.646,174.74 251.112,174.509 251.542,174.654 C251.973,174.799 252.204,175.265 252.059,175.696 C251.914,176.126 251.448,176.357 251.017,176.212 C250.587,176.068 250.356,175.601 250.501,175.171" id="Fill-153" class="icon-color"/>
      <path d="M250.414,181.381 C250.414,180.902 250.802,180.515 251.28,180.515 C251.758,180.515 252.146,180.902 252.146,181.381 C252.146,181.859 251.758,182.246 251.28,182.246 C250.802,182.246 250.414,181.859 250.414,181.381" id="Fill-154" class="icon-color"/>
      <path d="M251.193,125.491 C252.498,125.443 253.595,126.462 253.643,127.767 C253.69,129.072 252.671,130.168 251.367,130.216 C250.062,130.264 248.965,129.245 248.917,127.94 C248.869,126.635 249.888,125.539 251.193,125.491" id="Fill-155" class="icon-color"/>
      <path d="M357.75,90.447 C357.75,91.771 356.677,92.844 355.353,92.844 C354.03,92.844 352.957,91.771 352.957,90.447 C352.957,89.124 354.03,88.051 355.353,88.051 C356.677,88.051 357.75,89.124 357.75,90.447" id="Fill-156" class="icon-color"/>
      <path d="M355.419,86.535 C354.206,86.572 353.193,85.618 353.157,84.404 C353.12,83.191 354.074,82.178 355.288,82.142 C356.501,82.105 357.514,83.059 357.55,84.273 C357.587,85.486 356.633,86.499 355.419,86.535" id="Fill-157" class="icon-color"/>
      <path d="M355.431,80.227 C354.328,80.299 353.375,79.464 353.302,78.361 C353.229,77.258 354.065,76.305 355.168,76.232 C356.271,76.16 357.224,76.995 357.296,78.098 C357.369,79.201 356.534,80.154 355.431,80.227" id="Fill-158" class="icon-color"/>
      <path d="M355.472,73.918 C354.479,74.027 353.586,73.311 353.477,72.318 C353.368,71.326 354.084,70.432 355.077,70.323 C356.069,70.214 356.962,70.93 357.072,71.923 C357.181,72.916 356.465,73.809 355.472,73.918" id="Fill-159" class="icon-color"/>
      <path d="M355.513,67.609 C354.63,67.755 353.797,67.157 353.652,66.275 C353.506,65.393 354.103,64.559 354.986,64.414 C355.868,64.268 356.702,64.866 356.847,65.748 C356.993,66.631 356.395,67.464 355.513,67.609" id="Fill-160" class="icon-color"/>
      <path d="M355.554,61.301 C354.782,61.482 354.008,61.004 353.827,60.232 C353.645,59.46 354.123,58.687 354.895,58.505 C355.667,58.323 356.44,58.801 356.622,59.573 C356.804,60.345 356.326,61.119 355.554,61.301" id="Fill-161" class="icon-color"/>
      <path d="M355.61,54.992 C354.948,55.21 354.235,54.851 354.017,54.189 C353.798,53.527 354.158,52.814 354.82,52.595 C355.482,52.377 356.195,52.737 356.413,53.398 C356.631,54.06 356.272,54.774 355.61,54.992" id="Fill-162" class="icon-color"/>
      <path d="M355.699,48.683 C355.148,48.938 354.494,48.697 354.24,48.146 C353.985,47.594 354.225,46.941 354.777,46.686 C355.328,46.431 355.982,46.672 356.237,47.224 C356.491,47.775 356.251,48.429 355.699,48.683" id="Fill-163" class="icon-color"/>
      <path d="M355.788,42.375 C355.347,42.666 354.753,42.544 354.462,42.103 C354.171,41.662 354.293,41.068 354.734,40.777 C355.175,40.486 355.769,40.608 356.06,41.049 C356.351,41.49 356.229,42.083 355.788,42.375" id="Fill-164" class="icon-color"/>
      <path d="M355.877,36.066 C355.546,36.393 355.012,36.39 354.685,36.059 C354.358,35.729 354.361,35.195 354.691,34.868 C355.022,34.54 355.556,34.543 355.883,34.874 C356.211,35.205 356.208,35.738 355.877,36.066" id="Fill-165" class="icon-color"/>
      <path d="M356.012,29.757 C355.792,30.121 355.318,30.237 354.954,30.016 C354.59,29.796 354.474,29.322 354.695,28.958 C354.915,28.594 355.389,28.478 355.753,28.699 C356.117,28.92 356.233,29.393 356.012,29.757" id="Fill-166" class="icon-color"/>
      <path d="M356.078,23.448 C355.968,23.849 355.554,24.084 355.154,23.973 C354.754,23.863 354.519,23.449 354.629,23.049 C354.739,22.649 355.153,22.414 355.553,22.524 C355.953,22.634 356.188,23.048 356.078,23.448" id="Fill-167" class="icon-color"/>
      <path d="M356.144,17.14 C356.144,17.576 355.79,17.93 355.353,17.93 C354.917,17.93 354.563,17.576 354.563,17.14 C354.563,16.703 354.917,16.349 355.353,16.349 C355.79,16.349 356.144,16.703 356.144,17.14" id="Fill-168" class="icon-color"/>
      <path d="M354.789,146.375 C354.789,145.934 355.146,145.577 355.586,145.577 C356.026,145.577 356.383,145.934 356.383,146.375 C356.383,146.815 356.026,147.172 355.586,147.172 C355.146,147.172 354.789,146.815 354.789,146.375" id="Fill-169" class="icon-color"/>
      <path d="M354.542,140.151 C354.542,139.575 355.009,139.107 355.586,139.107 C356.163,139.107 356.63,139.575 356.63,140.151 C356.63,140.728 356.163,141.196 355.586,141.196 C355.009,141.196 354.542,140.728 354.542,140.151" id="Fill-170" class="icon-color"/>
      <path d="M354.295,133.928 C354.295,133.215 354.873,132.637 355.586,132.637 C356.299,132.637 356.877,133.215 356.877,133.928 C356.877,134.641 356.299,135.219 355.586,135.219 C354.873,135.219 354.295,134.641 354.295,133.928" id="Fill-171" class="icon-color"/>
      <path d="M354.048,127.705 C354.048,126.856 354.736,126.167 355.586,126.167 C356.435,126.167 357.124,126.856 357.124,127.705 C357.124,128.555 356.435,129.243 355.586,129.243 C354.736,129.243 354.048,128.555 354.048,127.705" id="Fill-172" class="icon-color"/>
      <path d="M353.554,115.259 C353.554,114.137 354.464,113.227 355.586,113.227 C356.708,113.227 357.618,114.137 357.618,115.259 C357.618,116.381 356.708,117.291 355.586,117.291 C354.464,117.291 353.554,116.381 353.554,115.259" id="Fill-173" class="icon-color"/>
      <path d="M353.307,109.036 C353.307,107.777 354.327,106.757 355.586,106.757 C356.845,106.757 357.865,107.777 357.865,109.036 C357.865,110.295 356.845,111.315 355.586,111.315 C354.327,111.315 353.307,110.295 353.307,109.036" id="Fill-174" class="icon-color"/>
      <path d="M353.06,102.813 C353.06,101.418 354.191,100.287 355.586,100.287 C356.981,100.287 358.112,101.418 358.112,102.813 C358.112,104.208 356.981,105.339 355.586,105.339 C354.191,105.339 353.06,104.208 353.06,102.813" id="Fill-175" class="icon-color"/>
      <path d="M352.813,96.59 C352.813,95.059 354.055,93.817 355.586,93.817 C357.117,93.817 358.359,95.059 358.359,96.59 C358.359,98.121 357.117,99.363 355.586,99.363 C354.055,99.363 352.813,98.121 352.813,96.59" id="Fill-176" class="icon-color"/>
      <path d="M353.801,121.482 C353.801,120.497 354.6,119.697 355.586,119.697 C356.572,119.697 357.371,120.497 357.371,121.482 C357.371,122.468 356.572,123.267 355.586,123.267 C354.6,123.267 353.801,122.468 353.801,121.482" id="Fill-177" class="icon-color"/>
      <path d="M231.192,85.528 C231.192,84.63 231.919,83.903 232.817,83.903 C233.714,83.903 234.442,84.63 234.442,85.528 C234.442,86.425 233.714,87.153 232.817,87.153 C231.919,87.153 231.192,86.425 231.192,85.528" id="Fill-178" class="icon-color"/>
      <path d="M233.099,90.798 C233.906,90.954 234.435,91.735 234.279,92.542 C234.124,93.35 233.342,93.879 232.535,93.723 C231.727,93.567 231.199,92.786 231.354,91.979 C231.51,91.171 232.291,90.642 233.099,90.798" id="Fill-179" class="icon-color"/>
      <path d="M233.381,97.694 C234.098,98.005 234.428,98.839 234.117,99.557 C233.805,100.275 232.971,100.605 232.253,100.293 C231.535,99.982 231.206,99.148 231.517,98.43 C231.828,97.712 232.663,97.382 233.381,97.694" id="Fill-180" class="icon-color"/>
      <path d="M233.662,104.589 C234.291,105.056 234.421,105.944 233.954,106.572 C233.487,107.2 232.599,107.331 231.971,106.864 C231.343,106.397 231.212,105.509 231.679,104.881 C232.146,104.253 233.034,104.122 233.662,104.589" id="Fill-181" class="icon-color"/>
      <path d="M233.944,111.484 C234.483,112.107 234.414,113.049 233.792,113.587 C233.169,114.125 232.228,114.057 231.689,113.434 C231.151,112.812 231.219,111.87 231.842,111.332 C232.465,110.793 233.406,110.862 233.944,111.484" id="Fill-182" class="icon-color"/>
      <path d="M234.226,118.38 C234.675,119.158 234.408,120.153 233.629,120.602 C232.851,121.051 231.856,120.783 231.407,120.005 C230.958,119.226 231.226,118.232 232.004,117.783 C232.783,117.334 233.777,117.601 234.226,118.38" id="Fill-183" class="icon-color"/>
      <path d="M234.508,125.275 C234.867,126.209 234.401,127.258 233.467,127.617 C232.533,127.976 231.484,127.509 231.125,126.575 C230.766,125.641 231.233,124.593 232.167,124.234 C233.101,123.875 234.149,124.341 234.508,125.275" id="Fill-184" class="icon-color"/>
      <path d="M234.79,132.171 C235.059,133.26 234.394,134.362 233.304,134.631 C232.214,134.901 231.113,134.235 230.844,133.146 C230.574,132.056 231.24,130.954 232.329,130.685 C233.419,130.416 234.521,131.081 234.79,132.171" id="Fill-185" class="icon-color"/>
      <path d="M235.072,139.066 C235.251,140.312 234.387,141.467 233.142,141.646 C231.896,141.826 230.741,140.961 230.562,139.716 C230.382,138.47 231.246,137.315 232.492,137.136 C233.737,136.957 234.892,137.821 235.072,139.066" id="Fill-186" class="icon-color"/>
      <path d="M235.354,145.961 C235.443,147.363 234.38,148.571 232.979,148.661 C231.578,148.751 230.37,147.688 230.28,146.286 C230.19,144.885 231.253,143.677 232.654,143.587 C234.055,143.497 235.264,144.56 235.354,145.961" id="Fill-187" class="icon-color"/>
      <path d="M229.998,152.857 C229.998,151.3 231.26,150.038 232.817,150.038 C234.374,150.038 235.636,151.3 235.636,152.857 C235.636,154.414 234.374,155.676 232.817,155.676 C231.26,155.676 229.998,154.414 229.998,152.857" id="Fill-188" class="icon-color"/>
      <path d="M235.191,159.59 C235.191,160.901 234.128,161.964 232.817,161.964 C231.505,161.964 230.442,160.901 230.442,159.59 C230.442,158.278 231.505,157.215 232.817,157.215 C234.128,157.215 235.191,158.278 235.191,159.59" id="Fill-189" class="icon-color"/>
      <path d="M234.303,173.056 C234.303,173.876 233.637,174.541 232.817,174.541 C231.996,174.541 231.331,173.876 231.331,173.056 C231.331,172.235 231.996,171.57 232.817,171.57 C233.637,171.57 234.303,172.235 234.303,173.056" id="Fill-190" class="icon-color"/>
      <path d="M233.858,179.789 C233.858,180.364 233.392,180.83 232.817,180.83 C232.242,180.83 231.775,180.364 231.775,179.789 C231.775,179.213 232.242,178.747 232.817,178.747 C233.392,178.747 233.858,179.213 233.858,179.789" id="Fill-191" class="icon-color"/>
      <path d="M233.414,186.522 C233.414,186.851 233.146,187.119 232.817,187.119 C232.487,187.119 232.22,186.851 232.22,186.522 C232.22,186.192 232.487,185.924 232.817,185.924 C233.146,185.924 233.414,186.192 233.414,186.522" id="Fill-192" class="icon-color"/>
      <path d="M232.969,193.254 C232.969,193.339 232.901,193.407 232.817,193.407 C232.732,193.407 232.664,193.339 232.664,193.254 C232.664,193.17 232.732,193.102 232.817,193.102 C232.901,193.102 232.969,193.17 232.969,193.254" id="Fill-193" class="icon-color"/>
      <path d="M232.525,199.987 C232.525,199.826 232.656,199.696 232.817,199.696 C232.978,199.696 233.108,199.826 233.108,199.987 C233.108,200.148 232.978,200.279 232.817,200.279 C232.656,200.279 232.525,200.148 232.525,199.987" id="Fill-194" class="icon-color"/>
      <path d="M232.081,206.72 C232.081,206.314 232.41,205.984 232.817,205.984 C233.223,205.984 233.553,206.314 233.553,206.72 C233.553,207.127 233.223,207.457 232.817,207.457 C232.41,207.457 232.081,207.127 232.081,206.72" id="Fill-195" class="icon-color"/>
      <path d="M231.636,213.453 C231.636,212.801 232.165,212.273 232.817,212.273 C233.469,212.273 233.997,212.801 233.997,213.453 C233.997,214.105 233.469,214.634 232.817,214.634 C232.165,214.634 231.636,214.105 231.636,213.453" id="Fill-196" class="icon-color"/>
      <path d="M231.192,220.186 C231.192,219.289 231.919,218.561 232.817,218.561 C233.714,218.561 234.442,219.289 234.442,220.186 C234.442,221.084 233.714,221.811 232.817,221.811 C231.919,221.811 231.192,221.084 231.192,220.186" id="Fill-197" class="icon-color"/>
      <path d="M234.747,166.323 C234.747,167.389 233.883,168.253 232.817,168.253 C231.751,168.253 230.887,167.389 230.887,166.323 C230.887,165.257 231.751,164.393 232.817,164.393 C233.883,164.393 234.747,165.257 234.747,166.323" id="Fill-198" class="icon-color"/>
      <path d="M225.004,84.043 C225.004,83.181 225.703,82.483 226.564,82.483 C227.426,82.483 228.125,83.181 228.125,84.043 C228.125,84.905 227.426,85.604 226.564,85.604 C225.703,85.604 225.004,84.905 225.004,84.043" id="Fill-199" class="icon-color"/>
      <path d="M226.835,91.115 C227.611,91.264 228.118,92.014 227.969,92.79 C227.819,93.565 227.07,94.073 226.294,93.923 C225.518,93.774 225.011,93.024 225.16,92.248 C225.31,91.473 226.06,90.965 226.835,91.115" id="Fill-200" class="icon-color"/>
      <path d="M227.106,99.746 C227.795,100.045 228.112,100.846 227.813,101.536 C227.514,102.225 226.713,102.542 226.023,102.243 C225.334,101.944 225.017,101.143 225.316,100.453 C225.615,99.764 226.416,99.447 227.106,99.746" id="Fill-201" class="icon-color"/>
      <path d="M227.377,108.378 C227.98,108.827 228.105,109.679 227.657,110.282 C227.208,110.886 226.356,111.011 225.753,110.563 C225.149,110.114 225.024,109.261 225.472,108.658 C225.921,108.055 226.773,107.929 227.377,108.378" id="Fill-202" class="icon-color"/>
      <path d="M227.647,117.01 C228.165,117.608 228.099,118.512 227.501,119.029 C226.903,119.546 225.999,119.48 225.482,118.882 C224.965,118.284 225.03,117.38 225.628,116.863 C226.226,116.346 227.13,116.412 227.647,117.01" id="Fill-203" class="icon-color"/>
      <path d="M227.918,125.641 C228.349,126.389 228.092,127.344 227.345,127.775 C226.597,128.206 225.642,127.949 225.211,127.202 C224.78,126.454 225.037,125.499 225.784,125.068 C226.532,124.637 227.487,124.894 227.918,125.641" id="Fill-204" class="icon-color"/>
      <path d="M228.189,134.273 C228.534,135.17 228.086,136.177 227.189,136.522 C226.292,136.866 225.285,136.418 224.94,135.521 C224.596,134.625 225.043,133.618 225.94,133.273 C226.837,132.928 227.844,133.376 228.189,134.273" id="Fill-205" class="icon-color"/>
      <path d="M228.46,142.905 C228.718,143.951 228.079,145.009 227.033,145.268 C225.986,145.527 224.928,144.888 224.669,143.841 C224.411,142.795 225.05,141.737 226.096,141.478 C227.143,141.219 228.201,141.858 228.46,142.905" id="Fill-206" class="icon-color"/>
      <path d="M228.73,151.536 C228.903,152.733 228.073,153.842 226.877,154.014 C225.681,154.187 224.571,153.357 224.399,152.161 C224.227,150.965 225.056,149.855 226.253,149.683 C227.449,149.511 228.558,150.34 228.73,151.536" id="Fill-207" class="icon-color"/>
      <path d="M223.857,168.8 C223.857,167.305 225.069,166.093 226.564,166.093 C228.06,166.093 229.272,167.305 229.272,168.8 C229.272,170.295 228.06,171.507 226.564,171.507 C225.069,171.507 223.857,170.295 223.857,168.8" id="Fill-208" class="icon-color"/>
      <path d="M228.845,175.974 C228.845,177.234 227.824,178.255 226.564,178.255 C225.305,178.255 224.284,177.234 224.284,175.974 C224.284,174.715 225.305,173.694 226.564,173.694 C227.824,173.694 228.845,174.715 228.845,175.974" id="Fill-209" class="icon-color"/>
      <path d="M228.418,183.148 C228.418,184.172 227.588,185.002 226.564,185.002 C225.541,185.002 224.711,184.172 224.711,183.148 C224.711,182.125 225.541,181.295 226.564,181.295 C227.588,181.295 228.418,182.125 228.418,183.148" id="Fill-210" class="icon-color"/>
      <path d="M227.992,190.322 C227.992,191.11 227.353,191.749 226.564,191.749 C225.777,191.749 225.138,191.11 225.138,190.322 C225.138,189.534 225.777,188.895 226.564,188.895 C227.353,188.895 227.992,189.534 227.992,190.322" id="Fill-211" class="icon-color"/>
      <path d="M227.565,197.496 C227.565,198.049 227.117,198.497 226.564,198.497 C226.012,198.497 225.564,198.049 225.564,197.496 C225.564,196.944 226.012,196.496 226.564,196.496 C227.117,196.496 227.565,196.944 227.565,197.496" id="Fill-212" class="icon-color"/>
      <path d="M227.138,204.67 C227.138,204.987 226.881,205.244 226.564,205.244 C226.248,205.244 225.991,204.987 225.991,204.67 C225.991,204.354 226.248,204.097 226.564,204.097 C226.881,204.097 227.138,204.354 227.138,204.67" id="Fill-213" class="icon-color"/>
      <path d="M226.711,211.844 C226.711,211.925 226.645,211.991 226.564,211.991 C226.484,211.991 226.418,211.925 226.418,211.844 C226.418,211.764 226.484,211.698 226.564,211.698 C226.645,211.698 226.711,211.764 226.711,211.844" id="Fill-214" class="icon-color"/>
      <path d="M226.284,219.019 C226.284,218.864 226.41,218.738 226.564,218.738 C226.719,218.738 226.845,218.864 226.845,219.019 C226.845,219.173 226.719,219.299 226.564,219.299 C226.41,219.299 226.284,219.173 226.284,219.019" id="Fill-215" class="icon-color"/>
      <path d="M225.858,226.193 C225.858,225.802 226.174,225.486 226.564,225.486 C226.955,225.486 227.271,225.802 227.271,226.193 C227.271,226.583 226.955,226.9 226.564,226.9 C226.174,226.9 225.858,226.583 225.858,226.193" id="Fill-216" class="icon-color"/>
      <path d="M225.431,233.367 C225.431,232.741 225.939,232.233 226.564,232.233 C227.191,232.233 227.698,232.741 227.698,233.367 C227.698,233.993 227.191,234.5 226.564,234.5 C225.939,234.5 225.431,233.993 225.431,233.367" id="Fill-217" class="icon-color"/>
      <path d="M225.004,240.541 C225.004,239.679 225.703,238.98 226.564,238.98 C227.426,238.98 228.125,239.679 228.125,240.541 C228.125,241.403 227.426,242.101 226.564,242.101 C225.703,242.101 225.004,241.403 225.004,240.541" id="Fill-218" class="icon-color"/>
      <path d="M229.001,160.168 C229.087,161.514 228.066,162.674 226.721,162.761 C225.375,162.847 224.214,161.826 224.128,160.48 C224.042,159.135 225.063,157.974 226.408,157.888 C227.754,157.802 228.915,158.823 229.001,160.168" id="Fill-219" class="icon-color"/>
      <path d="M217.939,119.086 C217.939,117.762 219.012,116.689 220.336,116.689 C221.659,116.689 222.732,117.762 222.732,119.086 C222.732,120.409 221.659,121.482 220.336,121.482 C219.012,121.482 217.939,120.409 217.939,119.086" id="Fill-220" class="icon-color"/>
      <path d="M220.27,124.489 C221.483,124.453 222.496,125.407 222.533,126.62 C222.569,127.833 221.615,128.847 220.402,128.883 C219.188,128.919 218.175,127.965 218.139,126.752 C218.102,125.539 219.057,124.526 220.27,124.489" id="Fill-221" class="icon-color"/>
      <path d="M220.27,132.29 C221.373,132.217 222.326,133.052 222.399,134.155 C222.472,135.258 221.637,136.211 220.534,136.284 C219.431,136.357 218.478,135.521 218.405,134.418 C218.332,133.315 219.167,132.362 220.27,132.29" id="Fill-222" class="icon-color"/>
      <path d="M220.236,140.09 C221.228,139.981 222.121,140.697 222.23,141.689 C222.34,142.682 221.624,143.575 220.631,143.685 C219.638,143.794 218.745,143.077 218.636,142.085 C218.527,141.092 219.243,140.199 220.236,140.09" id="Fill-223" class="icon-color"/>
      <path d="M220.144,155.69 C220.916,155.508 221.689,155.987 221.871,156.759 C222.053,157.531 221.574,158.304 220.803,158.486 C220.03,158.668 219.257,158.19 219.075,157.418 C218.893,156.646 219.372,155.872 220.144,155.69" id="Fill-224" class="icon-color"/>
      <path d="M220.058,163.49 C220.72,163.272 221.434,163.632 221.652,164.293 C221.87,164.955 221.511,165.669 220.849,165.887 C220.187,166.105 219.474,165.746 219.255,165.084 C219.037,164.422 219.397,163.709 220.058,163.49" id="Fill-225" class="icon-color"/>
      <path d="M219.973,171.291 C220.524,171.036 221.178,171.277 221.432,171.828 C221.687,172.38 221.447,173.033 220.895,173.288 C220.344,173.542 219.69,173.302 219.435,172.75 C219.181,172.199 219.421,171.545 219.973,171.291" id="Fill-226" class="icon-color"/>
      <path d="M219.887,179.091 C220.329,178.8 220.922,178.922 221.213,179.363 C221.504,179.804 221.382,180.398 220.941,180.689 C220.5,180.98 219.906,180.858 219.615,180.417 C219.324,179.976 219.446,179.382 219.887,179.091" id="Fill-227" class="icon-color"/>
      <path d="M219.802,186.891 C220.133,186.564 220.666,186.567 220.994,186.898 C221.321,187.228 221.318,187.762 220.987,188.09 C220.656,188.417 220.123,188.414 219.796,188.083 C219.468,187.752 219.471,187.219 219.802,186.891" id="Fill-228" class="icon-color"/>
      <path d="M219.677,194.691 C219.898,194.328 220.371,194.212 220.735,194.432 C221.099,194.653 221.215,195.127 220.994,195.49 C220.774,195.854 220.3,195.97 219.936,195.75 C219.572,195.529 219.456,195.055 219.677,194.691" id="Fill-229" class="icon-color"/>
      <path d="M219.611,202.492 C219.721,202.092 220.135,201.857 220.535,201.967 C220.936,202.077 221.171,202.491 221.06,202.891 C220.95,203.291 220.536,203.526 220.136,203.416 C219.736,203.306 219.501,202.892 219.611,202.492" id="Fill-230" class="icon-color"/>
      <path d="M219.545,210.292 C219.545,209.855 219.899,209.502 220.336,209.502 C220.772,209.502 221.126,209.855 221.126,210.292 C221.126,210.729 220.772,211.083 220.336,211.083 C219.899,211.083 219.545,210.729 219.545,210.292" id="Fill-231" class="icon-color"/>
      <path d="M220.201,147.89 C221.083,147.745 221.916,148.342 222.062,149.224 C222.207,150.107 221.61,150.94 220.728,151.085 C219.845,151.231 219.012,150.633 218.867,149.751 C218.721,148.869 219.318,148.036 220.201,147.89" id="Fill-232" class="icon-color"/>
      <path d="M338.14,198.369 C338.14,199.23 337.441,199.929 336.58,199.929 C335.718,199.929 335.019,199.23 335.019,198.369 C335.019,197.507 335.718,196.808 336.58,196.808 C337.441,196.808 338.14,197.507 338.14,198.369" id="Fill-233" class="icon-color"/>
      <path d="M336.309,193.307 C335.533,193.158 335.026,192.408 335.175,191.632 C335.325,190.857 336.075,190.349 336.85,190.498 C337.626,190.648 338.134,191.398 337.984,192.173 C337.835,192.949 337.085,193.457 336.309,193.307" id="Fill-234" class="icon-color"/>
      <path d="M336.038,186.685 C335.349,186.386 335.032,185.585 335.331,184.896 C335.63,184.206 336.432,183.89 337.121,184.189 C337.811,184.488 338.127,185.289 337.828,185.978 C337.529,186.668 336.728,186.984 336.038,186.685" id="Fill-235" class="icon-color"/>
      <path d="M335.767,180.063 C335.164,179.615 335.039,178.762 335.487,178.159 C335.936,177.556 336.789,177.43 337.392,177.879 C337.995,178.327 338.121,179.18 337.672,179.783 C337.223,180.387 336.371,180.512 335.767,180.063" id="Fill-236" class="icon-color"/>
      <path d="M335.497,173.442 C334.98,172.844 335.045,171.94 335.643,171.422 C336.241,170.905 337.145,170.971 337.663,171.569 C338.18,172.167 338.114,173.071 337.516,173.588 C336.918,174.105 336.014,174.04 335.497,173.442" id="Fill-237" class="icon-color"/>
      <path d="M335.226,166.82 C334.795,166.072 335.052,165.117 335.799,164.686 C336.547,164.255 337.502,164.512 337.933,165.259 C338.364,166.007 338.107,166.962 337.36,167.393 C336.612,167.824 335.657,167.567 335.226,166.82" id="Fill-238" class="icon-color"/>
      <path d="M334.955,160.198 C334.611,159.301 335.059,158.294 335.956,157.949 C336.853,157.605 337.859,158.053 338.204,158.95 C338.549,159.847 338.101,160.853 337.204,161.198 C336.307,161.543 335.3,161.095 334.955,160.198" id="Fill-239" class="icon-color"/>
      <path d="M334.685,153.576 C334.426,152.529 335.065,151.471 336.111,151.213 C337.158,150.954 338.216,151.593 338.475,152.64 C338.733,153.686 338.094,154.744 337.048,155.003 C336.001,155.261 334.943,154.623 334.685,153.576" id="Fill-240" class="icon-color"/>
      <path d="M334.414,146.954 C334.242,145.758 335.071,144.649 336.268,144.476 C337.464,144.304 338.573,145.134 338.745,146.33 C338.918,147.526 338.088,148.635 336.892,148.808 C335.696,148.98 334.586,148.15 334.414,146.954" id="Fill-241" class="icon-color"/>
      <path d="M334.143,140.332 C334.057,138.987 335.078,137.826 336.424,137.74 C337.769,137.654 338.93,138.675 339.016,140.02 C339.102,141.366 338.081,142.526 336.736,142.613 C335.39,142.699 334.229,141.678 334.143,140.332" id="Fill-242" class="icon-color"/>
      <path d="M339.287,133.711 C339.287,135.206 338.075,136.418 336.58,136.418 C335.085,136.418 333.873,135.206 333.873,133.711 C333.873,132.215 335.085,131.003 336.58,131.003 C338.075,131.003 339.287,132.215 339.287,133.711" id="Fill-243" class="icon-color"/>
      <path d="M334.726,118.682 C334.726,117.659 335.556,116.829 336.58,116.829 C337.603,116.829 338.433,117.659 338.433,118.682 C338.433,119.706 337.603,120.536 336.58,120.536 C335.556,120.536 334.726,119.706 334.726,118.682" id="Fill-244" class="icon-color"/>
      <path d="M335.153,111.168 C335.153,110.38 335.792,109.741 336.58,109.741 C337.368,109.741 338.007,110.38 338.007,111.168 C338.007,111.956 337.368,112.595 336.58,112.595 C335.792,112.595 335.153,111.956 335.153,111.168" id="Fill-245" class="icon-color"/>
      <path d="M335.579,103.654 C335.579,103.102 336.027,102.654 336.58,102.654 C337.132,102.654 337.58,103.102 337.58,103.654 C337.58,104.206 337.132,104.654 336.58,104.654 C336.027,104.654 335.579,104.206 335.579,103.654" id="Fill-246" class="icon-color"/>
      <path d="M336.006,96.14 C336.006,95.823 336.263,95.567 336.58,95.567 C336.896,95.567 337.153,95.823 337.153,96.14 C337.153,96.457 336.896,96.713 336.58,96.713 C336.263,96.713 336.006,96.457 336.006,96.14" id="Fill-247" class="icon-color"/>
      <path d="M336.433,88.626 C336.433,88.545 336.499,88.479 336.58,88.479 C336.661,88.479 336.726,88.545 336.726,88.626 C336.726,88.707 336.661,88.773 336.58,88.773 C336.499,88.773 336.433,88.707 336.433,88.626" id="Fill-248" class="icon-color"/>
      <path d="M336.86,81.112 C336.86,81.267 336.734,81.392 336.58,81.392 C336.425,81.392 336.299,81.267 336.299,81.112 C336.299,80.957 336.425,80.832 336.58,80.832 C336.734,80.832 336.86,80.957 336.86,81.112" id="Fill-249" class="icon-color"/>
      <path d="M337.287,73.598 C337.287,73.988 336.97,74.305 336.58,74.305 C336.189,74.305 335.873,73.988 335.873,73.598 C335.873,73.207 336.189,72.891 336.58,72.891 C336.97,72.891 337.287,73.207 337.287,73.598" id="Fill-250" class="icon-color"/>
      <path d="M337.713,66.084 C337.713,66.71 337.206,67.217 336.58,67.217 C335.954,67.217 335.446,66.71 335.446,66.084 C335.446,65.458 335.954,64.95 336.58,64.95 C337.206,64.95 337.713,65.458 337.713,66.084" id="Fill-251" class="icon-color"/>
      <path d="M338.14,58.57 C338.14,59.432 337.441,60.13 336.58,60.13 C335.718,60.13 335.019,59.432 335.019,58.57 C335.019,57.708 335.718,57.009 336.58,57.009 C337.441,57.009 338.14,57.708 338.14,58.57" id="Fill-252" class="icon-color"/>
      <path d="M334.299,126.196 C334.299,124.937 335.32,123.916 336.58,123.916 C337.839,123.916 338.86,124.937 338.86,126.196 C338.86,127.456 337.839,128.477 336.58,128.477 C335.32,128.477 334.299,127.456 334.299,126.196" id="Fill-253" class="icon-color"/>
      <path d="M265.557,29.84 C265.557,29.042 266.203,28.396 267.001,28.396 C267.798,28.396 268.445,29.042 268.445,29.84 C268.445,30.637 267.798,31.284 267.001,31.284 C266.203,31.284 265.557,30.637 265.557,29.84" id="Fill-254" class="icon-color"/>
      <path d="M267.251,33.924 C267.969,34.063 268.439,34.757 268.301,35.474 C268.162,36.192 267.468,36.662 266.75,36.524 C266.033,36.385 265.563,35.691 265.701,34.973 C265.839,34.256 266.534,33.786 267.251,33.924" id="Fill-255" class="icon-color"/>
      <path d="M267.502,39.453 C268.14,39.729 268.433,40.471 268.156,41.109 C267.879,41.747 267.138,42.04 266.5,41.763 C265.862,41.486 265.569,40.745 265.845,40.107 C266.122,39.469 266.864,39.176 267.502,39.453" id="Fill-256" class="icon-color"/>
      <path d="M267.752,44.981 C268.311,45.396 268.427,46.186 268.012,46.744 C267.596,47.302 266.807,47.418 266.249,47.003 C265.691,46.588 265.575,45.799 265.99,45.241 C266.405,44.682 267.194,44.566 267.752,44.981" id="Fill-257" class="icon-color"/>
      <path d="M268.003,50.51 C268.482,51.063 268.421,51.9 267.867,52.378 C267.314,52.857 266.477,52.796 265.999,52.243 C265.52,51.689 265.581,50.852 266.134,50.374 C266.688,49.895 267.525,49.956 268.003,50.51" id="Fill-258" class="icon-color"/>
      <path d="M268.254,56.038 C268.652,56.73 268.415,57.614 267.723,58.013 C267.031,58.412 266.147,58.174 265.748,57.482 C265.349,56.79 265.587,55.906 266.279,55.507 C266.971,55.109 267.855,55.346 268.254,56.038" id="Fill-259" class="icon-color"/>
      <path d="M268.504,61.567 C268.823,62.397 268.409,63.328 267.578,63.647 C266.748,63.966 265.817,63.552 265.497,62.722 C265.178,61.892 265.593,60.96 266.423,60.641 C267.253,60.322 268.185,60.736 268.504,61.567" id="Fill-260" class="icon-color"/>
      <path d="M268.754,67.095 C268.994,68.064 268.403,69.043 267.434,69.282 C266.465,69.521 265.486,68.93 265.247,67.961 C265.008,66.993 265.599,66.014 266.568,65.774 C267.536,65.535 268.515,66.126 268.754,67.095" id="Fill-261" class="icon-color"/>
      <path d="M269.005,72.624 C269.165,73.73 268.397,74.757 267.29,74.917 C266.183,75.076 265.156,74.308 264.996,73.201 C264.837,72.094 265.605,71.068 266.712,70.908 C267.819,70.749 268.846,71.517 269.005,72.624" id="Fill-262" class="icon-color"/>
      <path d="M269.256,78.152 C269.335,79.397 268.391,80.472 267.145,80.551 C265.9,80.631 264.826,79.686 264.746,78.441 C264.666,77.196 265.611,76.121 266.856,76.042 C268.102,75.962 269.176,76.907 269.256,78.152" id="Fill-263" class="icon-color"/>
      <path d="M264.495,83.681 C264.495,82.297 265.617,81.175 267.001,81.175 C268.384,81.175 269.506,82.297 269.506,83.681 C269.506,85.064 268.384,86.186 267.001,86.186 C265.617,86.186 264.495,85.064 264.495,83.681" id="Fill-264" class="icon-color"/>
      <path d="M269.111,89.665 C269.111,90.83 268.167,91.775 267.001,91.775 C265.835,91.775 264.89,90.83 264.89,89.665 C264.89,88.499 265.835,87.554 267.001,87.554 C268.167,87.554 269.111,88.499 269.111,89.665" id="Fill-265" class="icon-color"/>
      <path d="M268.716,95.649 C268.716,96.596 267.948,97.364 267.001,97.364 C266.053,97.364 265.285,96.596 265.285,95.649 C265.285,94.701 266.053,93.933 267.001,93.933 C267.948,93.933 268.716,94.701 268.716,95.649" id="Fill-266" class="icon-color"/>
      <path d="M268.321,101.633 C268.321,102.362 267.73,102.953 267.001,102.953 C266.271,102.953 265.68,102.362 265.68,101.633 C265.68,100.903 266.271,100.312 267.001,100.312 C267.73,100.312 268.321,100.903 268.321,101.633" id="Fill-267" class="icon-color"/>
      <path d="M267.926,107.617 C267.926,108.128 267.512,108.542 267.001,108.542 C266.49,108.542 266.075,108.128 266.075,107.617 C266.075,107.105 266.49,106.691 267.001,106.691 C267.512,106.691 267.926,107.105 267.926,107.617" id="Fill-268" class="icon-color"/>
      <path d="M267.531,113.601 C267.531,113.894 267.294,114.131 267.001,114.131 C266.708,114.131 266.47,113.894 266.47,113.601 C266.47,113.308 266.708,113.07 267.001,113.07 C267.294,113.07 267.531,113.308 267.531,113.601" id="Fill-269" class="icon-color"/>
      <path d="M267.136,119.585 C267.136,119.66 267.076,119.72 267.001,119.72 C266.926,119.72 266.865,119.66 266.865,119.585 C266.865,119.51 266.926,119.449 267.001,119.449 C267.076,119.449 267.136,119.51 267.136,119.585" id="Fill-270" class="icon-color"/>
      <path d="M266.742,125.569 C266.742,125.426 266.858,125.309 267.001,125.309 C267.144,125.309 267.26,125.426 267.26,125.569 C267.26,125.712 267.144,125.828 267.001,125.828 C266.858,125.828 266.742,125.712 266.742,125.569" id="Fill-271" class="icon-color"/>
      <path d="M266.347,131.553 C266.347,131.191 266.639,130.898 267.001,130.898 C267.362,130.898 267.655,131.191 267.655,131.553 C267.655,131.914 267.362,132.207 267.001,132.207 C266.639,132.207 266.347,131.914 266.347,131.553" id="Fill-272" class="icon-color"/>
      <path d="M265.952,137.537 C265.952,136.957 266.421,136.487 267.001,136.487 C267.58,136.487 268.05,136.957 268.05,137.537 C268.05,138.116 267.58,138.586 267.001,138.586 C266.421,138.586 265.952,138.116 265.952,137.537" id="Fill-273" class="icon-color"/>
      <path d="M265.557,143.521 C265.557,142.723 266.203,142.077 267.001,142.077 C267.798,142.077 268.445,142.723 268.445,143.521 C268.445,144.318 267.798,144.965 267.001,144.965 C266.203,144.965 265.557,144.318 265.557,143.521" id="Fill-274" class="icon-color"/>
      <path d="M271.478,76.309 C271.478,75.469 272.159,74.788 272.999,74.788 C273.839,74.788 274.52,75.469 274.52,76.309 C274.52,77.149 273.839,77.831 272.999,77.831 C272.159,77.831 271.478,77.149 271.478,76.309" id="Fill-275" class="icon-color"/>
      <path d="M273.292,81.182 C274.039,81.344 274.513,82.08 274.351,82.827 C274.189,83.574 273.453,84.048 272.706,83.886 C271.959,83.724 271.485,82.987 271.647,82.24 C271.809,81.494 272.546,81.02 273.292,81.182" id="Fill-276" class="icon-color"/>
      <path d="M273.586,87.575 C274.239,87.899 274.506,88.691 274.182,89.345 C273.858,89.998 273.066,90.265 272.413,89.941 C271.759,89.617 271.492,88.825 271.816,88.172 C272.14,87.518 272.932,87.251 273.586,87.575" id="Fill-277" class="icon-color"/>
      <path d="M273.879,93.969 C274.439,94.454 274.499,95.302 274.013,95.862 C273.527,96.422 272.68,96.483 272.12,95.997 C271.559,95.511 271.499,94.663 271.985,94.103 C272.471,93.543 273.319,93.483 273.879,93.969" id="Fill-278" class="icon-color"/>
      <path d="M274.172,100.362 C274.639,101.01 274.492,101.913 273.844,102.38 C273.196,102.847 272.293,102.7 271.826,102.052 C271.359,101.404 271.506,100.501 272.154,100.034 C272.802,99.567 273.705,99.714 274.172,100.362" id="Fill-279" class="icon-color"/>
      <path d="M274.465,106.755 C274.839,107.565 274.485,108.524 273.675,108.898 C272.865,109.271 271.906,108.917 271.533,108.108 C271.16,107.298 271.513,106.339 272.323,105.965 C273.133,105.592 274.092,105.946 274.465,106.755" id="Fill-280" class="icon-color"/>
      <path d="M274.758,113.149 C275.038,114.121 274.478,115.135 273.506,115.415 C272.534,115.695 271.52,115.135 271.24,114.163 C270.96,113.191 271.52,112.177 272.492,111.897 C273.464,111.616 274.478,112.177 274.758,113.149" id="Fill-281" class="icon-color"/>
      <path d="M275.345,125.936 C275.438,127.231 274.464,128.357 273.168,128.451 C271.873,128.544 270.747,127.569 270.653,126.274 C270.56,124.978 271.534,123.852 272.83,123.759 C274.126,123.666 275.252,124.64 275.345,125.936" id="Fill-282" class="icon-color"/>
      <path d="M270.36,132.329 C270.36,130.872 271.542,129.69 272.999,129.69 C274.457,129.69 275.638,130.872 275.638,132.329 C275.638,133.787 274.457,134.968 272.999,134.968 C271.542,134.968 270.36,133.787 270.36,132.329" id="Fill-283" class="icon-color"/>
      <path d="M275.176,139.333 C275.176,140.535 274.201,141.509 272.999,141.509 C271.797,141.509 270.822,140.535 270.822,139.333 C270.822,138.13 271.797,137.156 272.999,137.156 C274.201,137.156 275.176,138.13 275.176,139.333" id="Fill-284" class="icon-color"/>
      <path d="M274.714,146.336 C274.714,147.283 273.946,148.051 272.999,148.051 C272.052,148.051 271.284,147.283 271.284,146.336 C271.284,145.389 272.052,144.622 272.999,144.622 C273.946,144.622 274.714,145.389 274.714,146.336" id="Fill-285" class="icon-color"/>
      <path d="M274.251,153.34 C274.251,154.031 273.691,154.592 272.999,154.592 C272.307,154.592 271.747,154.031 271.747,153.34 C271.747,152.648 272.307,152.087 272.999,152.087 C273.691,152.087 274.251,152.648 274.251,153.34" id="Fill-286" class="icon-color"/>
      <path d="M273.789,160.343 C273.789,160.78 273.435,161.133 272.999,161.133 C272.563,161.133 272.209,160.78 272.209,160.343 C272.209,159.907 272.563,159.553 272.999,159.553 C273.435,159.553 273.789,159.907 273.789,160.343" id="Fill-287" class="icon-color"/>
      <path d="M273.327,167.347 C273.327,167.528 273.18,167.674 272.999,167.674 C272.818,167.674 272.671,167.528 272.671,167.347 C272.671,167.166 272.818,167.019 272.999,167.019 C273.18,167.019 273.327,167.166 273.327,167.347" id="Fill-288" class="icon-color"/>
      <path d="M272.865,174.35 C272.865,174.276 272.925,174.216 272.999,174.216 C273.073,174.216 273.134,174.276 273.134,174.35 C273.134,174.424 273.073,174.484 272.999,174.484 C272.925,174.484 272.865,174.424 272.865,174.35" id="Fill-289" class="icon-color"/>
      <path d="M272.402,181.353 C272.402,181.024 272.67,180.757 272.999,180.757 C273.328,180.757 273.596,181.024 273.596,181.353 C273.596,181.683 273.328,181.95 272.999,181.95 C272.67,181.95 272.402,181.683 272.402,181.353" id="Fill-290" class="icon-color"/>
      <path d="M271.94,188.357 C271.94,187.772 272.414,187.298 272.999,187.298 C273.584,187.298 274.058,187.772 274.058,188.357 C274.058,188.942 273.584,189.416 272.999,189.416 C272.414,189.416 271.94,188.942 271.94,188.357" id="Fill-291" class="icon-color"/>
      <path d="M271.478,195.36 C271.478,194.52 272.159,193.839 272.999,193.839 C273.839,193.839 274.52,194.52 274.52,195.36 C274.52,196.201 273.839,196.882 272.999,196.882 C272.159,196.882 271.478,196.201 271.478,195.36" id="Fill-292" class="icon-color"/>
      <path d="M275.052,119.542 C275.238,120.676 274.471,121.746 273.337,121.933 C272.203,122.12 271.133,121.352 270.947,120.218 C270.76,119.085 271.527,118.014 272.661,117.828 C273.795,117.641 274.865,118.409 275.052,119.542" id="Fill-293" class="icon-color"/>
      <path d="M263.609,115.705 C263.609,116.847 262.682,117.774 261.539,117.774 C260.397,117.774 259.47,116.847 259.47,115.705 C259.47,114.562 260.397,113.635 261.539,113.635 C262.682,113.635 263.609,114.562 263.609,115.705" id="Fill-294" class="icon-color"/>
      <path d="M261.608,112.879 C260.579,112.916 259.715,112.113 259.677,111.084 C259.639,110.056 260.443,109.191 261.471,109.153 C262.5,109.116 263.364,109.919 263.402,110.948 C263.44,111.976 262.636,112.841 261.608,112.879" id="Fill-295" class="icon-color"/>
      <path d="M261.676,107.983 C260.762,108.058 259.959,107.378 259.884,106.464 C259.809,105.55 260.488,104.747 261.403,104.672 C262.317,104.596 263.12,105.276 263.195,106.191 C263.271,107.105 262.591,107.908 261.676,107.983" id="Fill-296" class="icon-color"/>
      <path d="M261.683,103.087 C260.883,103.201 260.143,102.644 260.03,101.844 C259.917,101.043 260.473,100.303 261.274,100.19 C262.074,100.077 262.814,100.634 262.927,101.434 C263.04,102.234 262.483,102.974 261.683,103.087" id="Fill-297" class="icon-color"/>
      <path d="M261.732,98.192 C261.047,98.343 260.368,97.909 260.217,97.223 C260.067,96.537 260.5,95.859 261.186,95.708 C261.872,95.558 262.55,95.991 262.701,96.677 C262.852,97.363 262.418,98.041 261.732,98.192" id="Fill-298" class="icon-color"/>
      <path d="M261.781,93.296 C261.21,93.485 260.594,93.174 260.405,92.603 C260.217,92.031 260.527,91.415 261.099,91.227 C261.67,91.038 262.286,91.349 262.475,91.92 C262.663,92.492 262.353,93.108 261.781,93.296" id="Fill-299" class="icon-color"/>
      <path d="M261.831,88.401 C261.373,88.627 260.819,88.44 260.593,87.982 C260.367,87.525 260.554,86.971 261.011,86.745 C261.469,86.519 262.022,86.706 262.249,87.163 C262.475,87.62 262.288,88.174 261.831,88.401" id="Fill-300" class="icon-color"/>
      <path d="M261.913,83.505 C261.57,83.769 261.078,83.705 260.814,83.362 C260.551,83.019 260.615,82.527 260.957,82.264 C261.3,82 261.792,82.064 262.056,82.406 C262.32,82.749 262.256,83.241 261.913,83.505" id="Fill-301" class="icon-color"/>
      <path d="M262.017,78.61 C261.789,78.911 261.359,78.97 261.057,78.742 C260.756,78.513 260.697,78.084 260.925,77.782 C261.154,77.48 261.584,77.421 261.885,77.65 C262.187,77.878 262.246,78.308 262.017,78.61" id="Fill-302" class="icon-color"/>
      <path d="M262.154,73.714 C262.04,74.054 261.672,74.236 261.333,74.122 C260.993,74.007 260.811,73.64 260.925,73.3 C261.039,72.961 261.407,72.779 261.746,72.893 C262.086,73.007 262.268,73.375 262.154,73.714" id="Fill-303" class="icon-color"/>
      <path d="M262.222,68.819 C262.222,69.196 261.916,69.501 261.539,69.501 C261.163,69.501 260.857,69.196 260.857,68.819 C260.857,68.442 261.163,68.136 261.539,68.136 C261.916,68.136 262.222,68.442 262.222,68.819" id="Fill-304" class="icon-color"/>
      <path d="M259.47,115.729 C259.47,114.585 260.397,113.659 261.539,113.659 C262.682,113.659 263.609,114.585 263.609,115.729 C263.609,116.871 262.682,117.798 261.539,117.798 C260.397,117.798 259.47,116.871 259.47,115.729" id="Fill-305" class="icon-color"/>
      <path d="M261.471,118.554 C262.5,118.517 263.364,119.32 263.402,120.349 C263.44,121.377 262.636,122.242 261.608,122.28 C260.579,122.317 259.715,121.514 259.677,120.485 C259.639,119.457 260.443,118.592 261.471,118.554" id="Fill-306" class="icon-color"/>
      <path d="M261.403,123.45 C262.317,123.375 263.12,124.055 263.195,124.969 C263.271,125.884 262.591,126.686 261.676,126.761 C260.762,126.837 259.959,126.156 259.884,125.242 C259.809,124.328 260.488,123.525 261.403,123.45" id="Fill-307" class="icon-color"/>
      <path d="M261.396,128.346 C262.196,128.233 262.936,128.789 263.049,129.59 C263.162,130.39 262.606,131.13 261.806,131.243 C261.005,131.356 260.265,130.799 260.152,129.999 C260.039,129.199 260.596,128.459 261.396,128.346" id="Fill-308" class="icon-color"/>
      <path d="M261.347,133.241 C262.033,133.09 262.711,133.524 262.862,134.21 C263.012,134.896 262.579,135.574 261.893,135.725 C261.207,135.876 260.529,135.442 260.378,134.756 C260.227,134.07 260.661,133.392 261.347,133.241" id="Fill-309" class="icon-color"/>
      <path d="M261.298,138.137 C261.869,137.948 262.485,138.259 262.674,138.83 C262.862,139.402 262.552,140.018 261.98,140.206 C261.409,140.395 260.793,140.084 260.604,139.513 C260.416,138.941 260.726,138.325 261.298,138.137" id="Fill-310" class="icon-color"/>
      <path d="M261.249,143.032 C261.706,142.806 262.26,142.994 262.486,143.451 C262.712,143.908 262.525,144.462 262.068,144.688 C261.611,144.914 261.056,144.727 260.83,144.27 C260.604,143.813 260.791,143.259 261.249,143.032" id="Fill-311" class="icon-color"/>
      <path d="M261.166,147.928 C261.509,147.664 262.001,147.728 262.264,148.071 C262.528,148.414 262.464,148.906 262.121,149.17 C261.779,149.434 261.287,149.369 261.023,149.027 C260.759,148.684 260.823,148.192 261.166,147.928" id="Fill-312" class="icon-color"/>
      <path d="M261.062,152.823 C261.29,152.522 261.72,152.463 262.022,152.691 C262.323,152.92 262.382,153.35 262.154,153.651 C261.925,153.953 261.495,154.012 261.194,153.784 C260.892,153.555 260.833,153.125 261.062,152.823" id="Fill-313" class="icon-color"/>
      <path d="M260.925,157.719 C261.039,157.38 261.407,157.197 261.746,157.311 C262.086,157.426 262.268,157.793 262.154,158.133 C262.04,158.472 261.672,158.654 261.333,158.54 C260.993,158.426 260.811,158.058 260.925,157.719" id="Fill-314" class="icon-color"/>
      <path d="M260.857,162.614 C260.857,162.237 261.163,161.932 261.539,161.932 C261.916,161.932 262.222,162.237 262.222,162.614 C262.222,162.991 261.916,163.297 261.539,163.297 C261.163,163.297 260.857,162.991 260.857,162.614" id="Fill-315" class="icon-color"/>
      <path d="M326.672,136.43 C326.672,137.88 325.496,139.055 324.046,139.055 C322.596,139.055 321.421,137.88 321.421,136.43 C321.421,134.98 322.596,133.805 324.046,133.805 C325.496,133.805 326.672,134.98 326.672,136.43" id="Fill-316" class="icon-color"/>
      <path d="M324.133,132.845 C322.828,132.893 321.732,131.874 321.684,130.569 C321.636,129.264 322.655,128.167 323.96,128.12 C325.265,128.072 326.361,129.091 326.409,130.396 C326.457,131.701 325.438,132.797 324.133,132.845" id="Fill-317" class="icon-color"/>
      <path d="M324.166,126.635 C323.006,126.731 321.989,125.868 321.893,124.708 C321.797,123.548 322.66,122.53 323.82,122.435 C324.98,122.339 325.998,123.202 326.093,124.362 C326.189,125.522 325.326,126.539 324.166,126.635" id="Fill-318" class="icon-color"/>
      <path d="M324.228,120.425 C323.214,120.568 322.275,119.862 322.131,118.847 C321.988,117.832 322.694,116.893 323.709,116.75 C324.724,116.606 325.663,117.313 325.806,118.328 C325.95,119.342 325.243,120.281 324.228,120.425" id="Fill-319" class="icon-color"/>
      <path d="M324.291,114.215 C323.421,114.406 322.561,113.856 322.369,112.986 C322.178,112.116 322.728,111.256 323.598,111.065 C324.468,110.873 325.328,111.424 325.52,112.293 C325.711,113.163 325.161,114.024 324.291,114.215" id="Fill-320" class="icon-color"/>
      <path d="M324.353,108.005 C323.628,108.244 322.847,107.85 322.608,107.125 C322.368,106.4 322.762,105.619 323.487,105.38 C324.212,105.141 324.994,105.535 325.233,106.259 C325.472,106.984 325.078,107.766 324.353,108.005" id="Fill-321" class="icon-color"/>
      <path d="M324.415,101.795 C323.836,102.082 323.133,101.844 322.846,101.264 C322.559,100.684 322.796,99.982 323.376,99.695 C323.956,99.408 324.659,99.645 324.946,100.225 C325.233,100.805 324.995,101.508 324.415,101.795" id="Fill-322" class="icon-color"/>
      <path d="M324.52,95.585 C324.085,95.92 323.461,95.839 323.127,95.404 C322.792,94.969 322.873,94.345 323.308,94.01 C323.743,93.675 324.367,93.756 324.702,94.191 C325.037,94.626 324.955,95.25 324.52,95.585" id="Fill-323" class="icon-color"/>
      <path d="M324.653,89.375 C324.363,89.758 323.817,89.833 323.435,89.543 C323.052,89.253 322.977,88.708 323.267,88.325 C323.557,87.943 324.102,87.867 324.485,88.157 C324.867,88.447 324.943,88.992 324.653,89.375" id="Fill-324" class="icon-color"/>
      <path d="M324.826,83.165 C324.681,83.596 324.214,83.827 323.784,83.682 C323.353,83.537 323.122,83.071 323.267,82.64 C323.412,82.21 323.879,81.979 324.309,82.123 C324.739,82.268 324.971,82.735 324.826,83.165" id="Fill-325" class="icon-color"/>
      <path d="M324.912,76.955 C324.912,77.433 324.525,77.821 324.046,77.821 C323.568,77.821 323.18,77.433 323.18,76.955 C323.18,76.477 323.568,76.089 324.046,76.089 C324.525,76.089 324.912,76.477 324.912,76.955" id="Fill-326" class="icon-color"/>
      <path d="M321.421,136.46 C321.421,135.01 322.596,133.835 324.046,133.835 C325.496,133.835 326.672,135.01 326.672,136.46 C326.672,137.91 325.496,139.085 324.046,139.085 C322.596,139.085 321.421,137.91 321.421,136.46" id="Fill-327" class="icon-color"/>
      <path d="M323.96,140.045 C325.265,139.997 326.361,141.016 326.409,142.321 C326.457,143.626 325.438,144.722 324.133,144.77 C322.828,144.818 321.732,143.799 321.684,142.494 C321.636,141.189 322.655,140.092 323.96,140.045" id="Fill-328" class="icon-color"/>
      <path d="M323.864,152.465 C324.879,152.321 325.818,153.028 325.961,154.043 C326.105,155.058 325.398,155.997 324.384,156.14 C323.369,156.284 322.43,155.577 322.286,154.562 C322.143,153.547 322.849,152.608 323.864,152.465" id="Fill-329" class="icon-color"/>
      <path d="M323.802,158.675 C324.672,158.484 325.532,159.034 325.723,159.904 C325.915,160.773 325.365,161.634 324.495,161.825 C323.625,162.016 322.764,161.466 322.573,160.596 C322.382,159.726 322.932,158.866 323.802,158.675" id="Fill-330" class="icon-color"/>
      <path d="M323.74,164.885 C324.465,164.646 325.246,165.04 325.485,165.764 C325.724,166.489 325.33,167.271 324.605,167.51 C323.881,167.749 323.099,167.355 322.86,166.63 C322.621,165.905 323.015,165.124 323.74,164.885" id="Fill-331" class="icon-color"/>
      <path d="M323.677,171.095 C324.257,170.808 324.96,171.045 325.247,171.625 C325.534,172.205 325.296,172.908 324.716,173.195 C324.137,173.482 323.434,173.244 323.147,172.665 C322.86,172.084 323.097,171.382 323.677,171.095" id="Fill-332" class="icon-color"/>
      <path d="M323.572,177.305 C324.007,176.97 324.631,177.051 324.966,177.486 C325.301,177.921 325.22,178.545 324.785,178.88 C324.35,179.215 323.726,179.133 323.391,178.698 C323.056,178.263 323.137,177.639 323.572,177.305" id="Fill-333" class="icon-color"/>
      <path d="M323.44,183.515 C323.73,183.132 324.275,183.057 324.658,183.347 C325.04,183.637 325.116,184.182 324.826,184.565 C324.536,184.947 323.991,185.022 323.608,184.732 C323.225,184.442 323.15,183.897 323.44,183.515" id="Fill-334" class="icon-color"/>
      <path d="M323.267,189.724 C323.412,189.294 323.879,189.063 324.309,189.208 C324.739,189.353 324.971,189.819 324.826,190.25 C324.681,190.68 324.214,190.911 323.784,190.766 C323.353,190.621 323.122,190.155 323.267,189.724" id="Fill-335" class="icon-color"/>
      <path d="M323.18,195.934 C323.18,195.456 323.568,195.069 324.046,195.069 C324.525,195.069 324.912,195.456 324.912,195.934 C324.912,196.413 324.525,196.8 324.046,196.8 C323.568,196.8 323.18,196.413 323.18,195.934" id="Fill-336" class="icon-color"/>
      <path d="M323.926,146.255 C325.086,146.159 326.104,147.022 326.2,148.182 C326.295,149.342 325.433,150.36 324.273,150.455 C323.113,150.551 322.095,149.688 321.999,148.528 C321.904,147.368 322.767,146.351 323.926,146.255" id="Fill-337" class="icon-color"/>
      <path d="M328.254,176.902 C328.254,175.578 329.327,174.505 330.651,174.505 C331.974,174.505 333.047,175.578 333.047,176.902 C333.047,178.225 331.974,179.298 330.651,179.298 C329.327,179.298 328.254,178.225 328.254,176.902" id="Fill-338" class="icon-color"/>
      <path d="M330.585,180.814 C331.798,180.778 332.811,181.732 332.848,182.945 C332.884,184.158 331.93,185.171 330.717,185.207 C329.504,185.244 328.49,184.29 328.454,183.077 C328.418,181.863 329.372,180.85 330.585,180.814" id="Fill-339" class="icon-color"/>
      <path d="M330.573,187.123 C331.676,187.05 332.629,187.885 332.702,188.988 C332.775,190.091 331.94,191.044 330.837,191.117 C329.734,191.189 328.781,190.354 328.708,189.251 C328.635,188.148 329.47,187.195 330.573,187.123" id="Fill-340" class="icon-color"/>
      <path d="M330.532,193.431 C331.525,193.322 332.418,194.038 332.527,195.031 C332.637,196.024 331.92,196.917 330.927,197.026 C329.935,197.135 329.042,196.419 328.933,195.426 C328.823,194.433 329.54,193.54 330.532,193.431" id="Fill-341" class="icon-color"/>
      <path d="M330.491,199.74 C331.374,199.594 332.207,200.192 332.353,201.074 C332.498,201.957 331.901,202.79 331.018,202.935 C330.136,203.081 329.303,202.483 329.157,201.601 C329.012,200.719 329.609,199.885 330.491,199.74" id="Fill-342" class="icon-color"/>
      <path d="M330.451,206.049 C331.223,205.867 331.996,206.345 332.178,207.117 C332.36,207.889 331.881,208.663 331.109,208.844 C330.337,209.027 329.564,208.548 329.382,207.776 C329.2,207.004 329.678,206.231 330.451,206.049" id="Fill-343" class="icon-color"/>
      <path d="M330.394,212.357 C331.056,212.139 331.769,212.499 331.988,213.16 C332.206,213.822 331.846,214.536 331.185,214.754 C330.523,214.972 329.81,214.613 329.591,213.951 C329.373,213.289 329.732,212.576 330.394,212.357" id="Fill-344" class="icon-color"/>
      <path d="M330.305,218.666 C330.857,218.411 331.51,218.652 331.765,219.204 C332.019,219.755 331.779,220.409 331.227,220.663 C330.676,220.918 330.022,220.677 329.768,220.126 C329.513,219.574 329.754,218.921 330.305,218.666" id="Fill-345" class="icon-color"/>
      <path d="M330.216,224.975 C330.658,224.684 331.251,224.805 331.542,225.247 C331.833,225.688 331.711,226.281 331.27,226.572 C330.829,226.863 330.235,226.742 329.944,226.301 C329.653,225.859 329.775,225.266 330.216,224.975" id="Fill-346" class="icon-color"/>
      <path d="M330.127,231.283 C330.458,230.956 330.992,230.959 331.319,231.29 C331.647,231.621 331.644,232.154 331.313,232.482 C330.982,232.809 330.448,232.806 330.121,232.475 C329.793,232.144 329.796,231.611 330.127,231.283" id="Fill-347" class="icon-color"/>
      <path d="M329.992,237.592 C330.213,237.228 330.686,237.112 331.05,237.333 C331.414,237.553 331.53,238.027 331.31,238.391 C331.089,238.755 330.615,238.871 330.251,238.65 C329.888,238.43 329.772,237.956 329.992,237.592" id="Fill-348" class="icon-color"/>
      <g id="Group-352" transform="translate(329.000000, 243.000000)">
        <mask id="mask-2" fill="white">
          <use xlink:href="#path-1"/>
        </mask>
        <g id="Clip-350"/>
        <path d="M0.926,0.901 C1.037,0.501 1.45,0.266 1.851,0.376 C2.251,0.486 2.486,0.9 2.375,1.3 C2.265,1.7 1.851,1.935 1.451,1.825 C1.051,1.715 0.816,1.301 0.926,0.901" id="Fill-349" class="icon-color" mask="url(#mask-2)"/>
        <path d="M0.86,7.209 C0.86,6.773 1.214,6.419 1.651,6.419 C2.087,6.419 2.441,6.773 2.441,7.209 C2.441,7.646 2.087,8 1.651,8 C1.214,8 0.86,7.646 0.86,7.209" id="Fill-351" class="icon-color" mask="url(#mask-2)"/>
      </g>
      <path d="M331.215,120.975 C331.215,121.415 330.858,121.772 330.418,121.772 C329.978,121.772 329.621,121.415 329.621,120.975 C329.621,120.535 329.978,120.178 330.418,120.178 C330.858,120.178 331.215,120.535 331.215,120.975" id="Fill-353" class="icon-color"/>
      <path d="M331.462,127.198 C331.462,127.775 330.995,128.242 330.418,128.242 C329.842,128.242 329.374,127.775 329.374,127.198 C329.374,126.621 329.842,126.154 330.418,126.154 C330.995,126.154 331.462,126.621 331.462,127.198" id="Fill-354" class="icon-color"/>
      <path d="M331.709,133.421 C331.709,134.134 331.131,134.712 330.418,134.712 C329.705,134.712 329.127,134.134 329.127,133.421 C329.127,132.708 329.705,132.13 330.418,132.13 C331.131,132.13 331.709,132.708 331.709,133.421" id="Fill-355" class="icon-color"/>
      <path d="M331.956,139.644 C331.956,140.493 331.268,141.182 330.418,141.182 C329.569,141.182 328.88,140.493 328.88,139.644 C328.88,138.795 329.569,138.106 330.418,138.106 C331.268,138.106 331.956,138.795 331.956,139.644" id="Fill-356" class="icon-color"/>
      <path d="M332.203,145.867 C332.203,146.853 331.404,147.652 330.418,147.652 C329.432,147.652 328.633,146.853 328.633,145.867 C328.633,144.881 329.432,144.082 330.418,144.082 C331.404,144.082 332.203,144.881 332.203,145.867" id="Fill-357" class="icon-color"/>
      <path d="M332.45,152.09 C332.45,153.212 331.54,154.122 330.418,154.122 C329.296,154.122 328.386,153.212 328.386,152.09 C328.386,150.968 329.296,150.058 330.418,150.058 C331.54,150.058 332.45,150.968 332.45,152.09" id="Fill-358" class="icon-color"/>
      <path d="M332.697,158.313 C332.697,159.572 331.677,160.592 330.418,160.592 C329.16,160.592 328.139,159.572 328.139,158.313 C328.139,157.054 329.16,156.034 330.418,156.034 C331.677,156.034 332.697,157.054 332.697,158.313" id="Fill-359" class="icon-color"/>
      <path d="M332.944,164.536 C332.944,165.931 331.813,167.062 330.418,167.062 C329.023,167.062 327.892,165.931 327.892,164.536 C327.892,163.141 329.023,162.01 330.418,162.01 C331.813,162.01 332.944,163.141 332.944,164.536" id="Fill-360" class="icon-color"/>
      <path d="M333.191,170.759 C333.191,172.291 331.95,173.532 330.418,173.532 C328.887,173.532 327.645,172.291 327.645,170.759 C327.645,169.228 328.887,167.986 330.418,167.986 C331.95,167.986 333.191,169.228 333.191,170.759" id="Fill-361" class="icon-color"/>
      <path d="M186.634,42.913 C186.634,42.016 187.362,41.288 188.259,41.288 C189.157,41.288 189.884,42.016 189.884,42.913 C189.884,43.81 189.157,44.538 188.259,44.538 C187.362,44.538 186.634,43.81 186.634,42.913" id="Fill-362" class="icon-color"/>
      <path d="M188.541,48.184 C189.349,48.339 189.878,49.12 189.722,49.928 C189.566,50.736 188.785,51.264 187.978,51.108 C187.17,50.953 186.641,50.172 186.797,49.364 C186.953,48.556 187.734,48.028 188.541,48.184" id="Fill-363" class="icon-color"/>
      <path d="M188.823,55.079 C189.541,55.391 189.871,56.225 189.559,56.943 C189.248,57.661 188.414,57.99 187.695,57.679 C186.978,57.367 186.648,56.533 186.959,55.815 C187.271,55.097 188.105,54.768 188.823,55.079" id="Fill-364" class="icon-color"/>
      <path d="M189.105,61.974 C189.733,62.442 189.864,63.329 189.397,63.958 C188.93,64.586 188.042,64.716 187.414,64.249 C186.786,63.782 186.655,62.895 187.122,62.266 C187.589,61.638 188.477,61.507 189.105,61.974" id="Fill-365" class="icon-color"/>
      <path d="M189.387,68.87 C189.925,69.493 189.857,70.434 189.234,70.972 C188.612,71.511 187.67,71.443 187.132,70.82 C186.593,70.197 186.662,69.256 187.285,68.717 C187.907,68.179 188.848,68.247 189.387,68.87" id="Fill-366" class="icon-color"/>
      <path d="M189.669,75.765 C190.118,76.544 189.85,77.539 189.072,77.987 C188.293,78.436 187.299,78.169 186.85,77.39 C186.401,76.612 186.669,75.617 187.447,75.168 C188.225,74.72 189.22,74.987 189.669,75.765" id="Fill-367" class="icon-color"/>
      <path d="M189.951,82.661 C190.31,83.595 189.844,84.643 188.909,85.002 C187.975,85.361 186.927,84.895 186.568,83.961 C186.209,83.026 186.675,81.978 187.609,81.619 C188.544,81.26 189.592,81.727 189.951,82.661" id="Fill-368" class="icon-color"/>
      <path d="M190.515,96.451 C190.694,97.697 189.83,98.852 188.584,99.032 C187.339,99.211 186.184,98.347 186.004,97.101 C185.825,95.856 186.689,94.701 187.934,94.521 C189.18,94.342 190.335,95.206 190.515,96.451" id="Fill-369" class="icon-color"/>
      <path d="M190.797,103.347 C190.886,104.748 189.823,105.957 188.422,106.046 C187.021,106.136 185.812,105.073 185.722,103.672 C185.633,102.271 186.696,101.062 188.097,100.972 C189.498,100.883 190.707,101.946 190.797,103.347" id="Fill-370" class="icon-color"/>
      <path d="M185.441,110.242 C185.441,108.685 186.702,107.423 188.259,107.423 C189.816,107.423 191.078,108.685 191.078,110.242 C191.078,111.799 189.816,113.061 188.259,113.061 C186.702,113.061 185.441,111.799 185.441,110.242" id="Fill-371" class="icon-color"/>
      <path d="M190.634,116.975 C190.634,118.287 189.571,119.35 188.259,119.35 C186.948,119.35 185.885,118.287 185.885,116.975 C185.885,115.664 186.948,114.601 188.259,114.601 C189.571,114.601 190.634,115.664 190.634,116.975" id="Fill-372" class="icon-color"/>
      <path d="M190.19,123.708 C190.19,124.774 189.325,125.638 188.259,125.638 C187.193,125.638 186.329,124.774 186.329,123.708 C186.329,122.642 187.193,121.778 188.259,121.778 C189.325,121.778 190.19,122.642 190.19,123.708" id="Fill-373" class="icon-color"/>
      <path d="M189.745,130.441 C189.745,131.262 189.08,131.927 188.259,131.927 C187.439,131.927 186.773,131.262 186.773,130.441 C186.773,129.62 187.439,128.955 188.259,128.955 C189.08,128.955 189.745,129.62 189.745,130.441" id="Fill-374" class="icon-color"/>
      <path d="M189.301,137.174 C189.301,137.749 188.835,138.216 188.259,138.216 C187.684,138.216 187.218,137.749 187.218,137.174 C187.218,136.599 187.684,136.133 188.259,136.133 C188.835,136.133 189.301,136.599 189.301,137.174" id="Fill-375" class="icon-color"/>
      <path d="M188.856,143.907 C188.856,144.237 188.589,144.504 188.259,144.504 C187.93,144.504 187.662,144.237 187.662,143.907 C187.662,143.577 187.93,143.31 188.259,143.31 C188.589,143.31 188.856,143.577 188.856,143.907" id="Fill-376" class="icon-color"/>
      <path d="M188.412,150.64 C188.412,150.724 188.344,150.793 188.259,150.793 C188.175,150.793 188.107,150.724 188.107,150.64 C188.107,150.556 188.175,150.487 188.259,150.487 C188.344,150.487 188.412,150.556 188.412,150.64" id="Fill-377" class="icon-color"/>
      <path d="M187.968,157.373 C187.968,157.212 188.098,157.081 188.259,157.081 C188.421,157.081 188.551,157.212 188.551,157.373 C188.551,157.534 188.421,157.665 188.259,157.665 C188.098,157.665 187.968,157.534 187.968,157.373" id="Fill-378" class="icon-color"/>
      <path d="M187.523,164.106 C187.523,163.699 187.853,163.37 188.259,163.37 C188.666,163.37 188.995,163.699 188.995,164.106 C188.995,164.512 188.666,164.842 188.259,164.842 C187.853,164.842 187.523,164.512 187.523,164.106" id="Fill-379" class="icon-color"/>
      <path d="M187.079,170.839 C187.079,170.187 187.607,169.658 188.259,169.658 C188.911,169.658 189.44,170.187 189.44,170.839 C189.44,171.491 188.911,172.019 188.259,172.019 C187.607,172.019 187.079,171.491 187.079,170.839" id="Fill-380" class="icon-color"/>
      <path d="M186.634,177.572 C186.634,176.674 187.362,175.947 188.259,175.947 C189.157,175.947 189.884,176.674 189.884,177.572 C189.884,178.469 189.157,179.196 188.259,179.196 C187.362,179.196 186.634,178.469 186.634,177.572" id="Fill-381" class="icon-color"/>
      <path d="M190.233,89.556 C190.502,90.646 189.837,91.748 188.747,92.017 C187.657,92.286 186.555,91.621 186.286,90.531 C186.017,89.441 186.682,88.34 187.772,88.07 C188.862,87.801 189.964,88.466 190.233,89.556" id="Fill-382" class="icon-color"/>
      <path d="M183.368,179 C183.368,179.861 182.669,180.56 181.807,180.56 C180.945,180.56 180.247,179.861 180.247,179 C180.247,178.138 180.945,177.439 181.807,177.439 C182.669,177.439 183.368,178.138 183.368,179" id="Fill-383" class="icon-color"/>
      <path d="M181.536,173.938 C180.761,173.788 180.253,173.038 180.403,172.263 C180.552,171.487 181.302,170.98 182.078,171.129 C182.854,171.279 183.361,172.029 183.212,172.804 C183.062,173.58 182.312,174.088 181.536,173.938" id="Fill-384" class="icon-color"/>
      <path d="M181.266,167.316 C180.576,167.017 180.26,166.216 180.559,165.526 C180.858,164.837 181.659,164.52 182.349,164.819 C183.038,165.118 183.355,165.92 183.055,166.609 C182.757,167.299 181.955,167.615 181.266,167.316" id="Fill-385" class="icon-color"/>
      <path d="M180.995,160.694 C180.392,160.246 180.266,159.393 180.715,158.79 C181.163,158.187 182.016,158.061 182.619,158.51 C183.223,158.958 183.348,159.811 182.9,160.414 C182.451,161.017 181.598,161.143 180.995,160.694" id="Fill-386" class="icon-color"/>
      <path d="M180.725,154.073 C180.207,153.474 180.273,152.57 180.871,152.053 C181.469,151.536 182.373,151.602 182.89,152.2 C183.407,152.798 183.342,153.702 182.743,154.219 C182.145,154.736 181.242,154.67 180.725,154.073" id="Fill-387" class="icon-color"/>
      <path d="M180.454,147.451 C180.023,146.703 180.279,145.748 181.027,145.317 C181.775,144.886 182.73,145.143 183.161,145.89 C183.592,146.638 183.335,147.593 182.587,148.024 C181.84,148.455 180.885,148.198 180.454,147.451" id="Fill-388" class="icon-color"/>
      <path d="M180.183,140.829 C179.838,139.932 180.286,138.925 181.183,138.58 C182.08,138.235 183.087,138.683 183.432,139.58 C183.776,140.478 183.329,141.484 182.431,141.829 C181.534,142.174 180.528,141.726 180.183,140.829" id="Fill-389" class="icon-color"/>
      <path d="M179.912,134.207 C179.654,133.16 180.292,132.102 181.339,131.844 C182.386,131.585 183.444,132.224 183.702,133.271 C183.961,134.317 183.322,135.375 182.275,135.634 C181.229,135.892 180.171,135.253 179.912,134.207" id="Fill-390" class="icon-color"/>
      <path d="M179.641,127.585 C179.469,126.389 180.299,125.279 181.495,125.107 C182.691,124.935 183.801,125.765 183.973,126.961 C184.145,128.157 183.315,129.266 182.119,129.439 C180.923,129.611 179.814,128.781 179.641,127.585" id="Fill-391" class="icon-color"/>
      <path d="M179.371,120.963 C179.285,119.618 180.306,118.457 181.651,118.371 C182.997,118.285 184.157,119.305 184.244,120.651 C184.33,121.997 183.309,123.157 181.963,123.244 C180.618,123.33 179.457,122.309 179.371,120.963" id="Fill-392" class="icon-color"/>
      <path d="M184.514,114.341 C184.514,115.836 183.302,117.048 181.807,117.048 C180.312,117.048 179.1,115.836 179.1,114.341 C179.1,112.846 180.312,111.634 181.807,111.634 C183.302,111.634 184.514,112.846 184.514,114.341" id="Fill-393" class="icon-color"/>
      <path d="M179.527,107.737 C179.527,106.478 180.548,105.457 181.807,105.457 C183.067,105.457 184.087,106.478 184.087,107.737 C184.087,108.997 183.067,110.017 181.807,110.017 C180.548,110.017 179.527,108.997 179.527,107.737" id="Fill-394" class="icon-color"/>
      <path d="M179.954,101.133 C179.954,100.109 180.783,99.279 181.807,99.279 C182.831,99.279 183.661,100.109 183.661,101.133 C183.661,102.157 182.831,102.987 181.807,102.987 C180.783,102.987 179.954,102.157 179.954,101.133" id="Fill-395" class="icon-color"/>
      <path d="M180.38,94.529 C180.38,93.741 181.019,93.102 181.807,93.102 C182.595,93.102 183.234,93.741 183.234,94.529 C183.234,95.317 182.595,95.956 181.807,95.956 C181.019,95.956 180.38,95.317 180.38,94.529" id="Fill-396" class="icon-color"/>
      <path d="M180.807,87.925 C180.807,87.372 181.255,86.925 181.807,86.925 C182.36,86.925 182.807,87.372 182.807,87.925 C182.807,88.477 182.36,88.925 181.807,88.925 C181.255,88.925 180.807,88.477 180.807,87.925" id="Fill-397" class="icon-color"/>
      <path d="M181.234,81.321 C181.234,81.004 181.491,80.747 181.807,80.747 C182.124,80.747 182.381,81.004 182.381,81.321 C182.381,81.637 182.124,81.894 181.807,81.894 C181.491,81.894 181.234,81.637 181.234,81.321" id="Fill-398" class="icon-color"/>
      <path d="M181.661,74.716 C181.661,74.635 181.726,74.57 181.807,74.57 C181.888,74.57 181.954,74.635 181.954,74.716 C181.954,74.797 181.888,74.863 181.807,74.863 C181.726,74.863 181.661,74.797 181.661,74.716" id="Fill-399" class="icon-color"/>
      <path d="M182.087,68.112 C182.087,68.267 181.962,68.392 181.807,68.392 C181.653,68.392 181.527,68.267 181.527,68.112 C181.527,67.958 181.653,67.832 181.807,67.832 C181.962,67.832 182.087,67.958 182.087,68.112" id="Fill-400" class="icon-color"/>
      <path d="M182.514,61.508 C182.514,61.899 182.198,62.215 181.807,62.215 C181.417,62.215 181.1,61.899 181.1,61.508 C181.1,61.118 181.417,60.801 181.807,60.801 C182.198,60.801 182.514,61.118 182.514,61.508" id="Fill-401" class="icon-color"/>
      <path d="M182.941,54.904 C182.941,55.53 182.433,56.038 181.807,56.038 C181.181,56.038 180.673,55.53 180.673,54.904 C180.673,54.278 181.181,53.77 181.807,53.77 C182.433,53.77 182.941,54.278 182.941,54.904" id="Fill-402" class="icon-color"/>
      <path d="M183.368,48.3 C183.368,49.162 182.669,49.86 181.807,49.86 C180.945,49.86 180.247,49.162 180.247,48.3 C180.247,47.438 180.945,46.74 181.807,46.74 C182.669,46.74 183.368,47.438 183.368,48.3" id="Fill-403" class="icon-color"/>
      <path d="M173.482,118.183 C173.482,116.86 174.555,115.787 175.878,115.787 C177.202,115.787 178.275,116.86 178.275,118.183 C178.275,119.507 177.202,120.58 175.878,120.58 C174.555,120.58 173.482,119.507 173.482,118.183" id="Fill-404" class="icon-color"/>
      <path d="M175.813,122.095 C177.026,122.059 178.039,123.013 178.075,124.226 C178.112,125.44 177.157,126.453 175.944,126.489 C174.731,126.526 173.718,125.571 173.682,124.358 C173.645,123.145 174.599,122.132 175.813,122.095" id="Fill-405" class="icon-color"/>
      <path d="M175.801,128.404 C176.904,128.331 177.857,129.167 177.93,130.269 C178.002,131.372 177.167,132.325 176.064,132.398 C174.961,132.471 174.008,131.636 173.935,130.533 C173.863,129.43 174.698,128.477 175.801,128.404" id="Fill-406" class="icon-color"/>
      <path d="M175.76,134.713 C176.753,134.604 177.646,135.32 177.755,136.313 C177.864,137.305 177.148,138.198 176.155,138.308 C175.162,138.417 174.269,137.7 174.16,136.708 C174.051,135.715 174.767,134.822 175.76,134.713" id="Fill-407" class="icon-color"/>
      <path d="M175.719,141.021 C176.601,140.876 177.435,141.474 177.58,142.356 C177.726,143.238 177.128,144.071 176.246,144.217 C175.364,144.362 174.53,143.765 174.385,142.883 C174.239,142 174.837,141.167 175.719,141.021" id="Fill-408" class="icon-color"/>
      <path d="M175.678,147.33 C176.45,147.148 177.224,147.627 177.405,148.399 C177.587,149.171 177.109,149.944 176.337,150.126 C175.565,150.308 174.791,149.83 174.609,149.058 C174.428,148.285 174.906,147.512 175.678,147.33" id="Fill-409" class="icon-color"/>
      <path d="M175.622,153.639 C176.284,153.421 176.997,153.78 177.215,154.442 C177.434,155.104 177.074,155.817 176.412,156.035 C175.751,156.254 175.037,155.894 174.819,155.232 C174.6,154.571 174.96,153.857 175.622,153.639" id="Fill-410" class="icon-color"/>
      <path d="M175.533,159.948 C176.084,159.693 176.738,159.933 176.992,160.485 C177.247,161.036 177.006,161.69 176.455,161.945 C175.903,162.199 175.25,161.959 174.995,161.407 C174.741,160.856 174.981,160.202 175.533,159.948" id="Fill-411" class="icon-color"/>
      <path d="M175.444,166.256 C175.885,165.965 176.479,166.087 176.77,166.528 C177.061,166.969 176.939,167.563 176.498,167.854 C176.057,168.145 175.463,168.023 175.172,167.582 C174.881,167.141 175.003,166.547 175.444,166.256" id="Fill-412" class="icon-color"/>
      <path d="M175.355,172.565 C175.686,172.237 176.219,172.24 176.547,172.571 C176.874,172.902 176.871,173.436 176.54,173.763 C176.21,174.091 175.676,174.088 175.349,173.757 C175.021,173.426 175.024,172.892 175.355,172.565" id="Fill-413" class="icon-color"/>
      <path d="M175.22,178.874 C175.44,178.51 175.914,178.394 176.278,178.614 C176.642,178.835 176.758,179.309 176.537,179.672 C176.317,180.036 175.843,180.152 175.479,179.932 C175.115,179.711 174.999,179.237 175.22,178.874" id="Fill-414" class="icon-color"/>
      <path d="M175.154,185.182 C175.264,184.782 175.678,184.547 176.078,184.657 C176.478,184.768 176.713,185.182 176.603,185.582 C176.493,185.982 176.079,186.217 175.679,186.107 C175.279,185.996 175.044,185.582 175.154,185.182" id="Fill-415" class="icon-color"/>
      <path d="M175.088,191.491 C175.088,191.054 175.442,190.701 175.878,190.701 C176.315,190.701 176.669,191.054 176.669,191.491 C176.669,191.928 176.315,192.282 175.878,192.282 C175.442,192.282 175.088,191.928 175.088,191.491" id="Fill-416" class="icon-color"/>
      <path d="M176.443,62.256 C176.443,62.696 176.086,63.053 175.646,63.053 C175.206,63.053 174.849,62.696 174.849,62.256 C174.849,61.816 175.206,61.459 175.646,61.459 C176.086,61.459 176.443,61.816 176.443,62.256" id="Fill-417" class="icon-color"/>
      <path d="M176.69,68.479 C176.69,69.056 176.222,69.523 175.646,69.523 C175.069,69.523 174.602,69.056 174.602,68.479 C174.602,67.903 175.069,67.435 175.646,67.435 C176.222,67.435 176.69,67.903 176.69,68.479" id="Fill-418" class="icon-color"/>
      <path d="M176.937,74.702 C176.937,75.415 176.359,75.993 175.646,75.993 C174.933,75.993 174.355,75.415 174.355,74.702 C174.355,73.989 174.933,73.411 175.646,73.411 C176.359,73.411 176.937,73.989 176.937,74.702" id="Fill-419" class="icon-color"/>
      <path d="M177.184,80.925 C177.184,81.775 176.495,82.464 175.646,82.464 C174.796,82.464 174.108,81.775 174.108,80.925 C174.108,80.076 174.796,79.388 175.646,79.388 C176.495,79.388 177.184,80.076 177.184,80.925" id="Fill-420" class="icon-color"/>
      <path d="M177.431,87.149 C177.431,88.134 176.632,88.934 175.646,88.934 C174.66,88.934 173.861,88.134 173.861,87.149 C173.861,86.163 174.66,85.363 175.646,85.363 C176.632,85.363 177.431,86.163 177.431,87.149" id="Fill-421" class="icon-color"/>
      <path d="M177.678,93.372 C177.678,94.494 176.768,95.404 175.646,95.404 C174.524,95.404 173.614,94.494 173.614,93.372 C173.614,92.249 174.524,91.34 175.646,91.34 C176.768,91.34 177.678,92.249 177.678,93.372" id="Fill-422" class="icon-color"/>
      <path d="M177.925,99.594 C177.925,100.853 176.904,101.874 175.646,101.874 C174.387,101.874 173.367,100.853 173.367,99.594 C173.367,98.336 174.387,97.316 175.646,97.316 C176.904,97.316 177.925,98.336 177.925,99.594" id="Fill-423" class="icon-color"/>
      <path d="M178.172,105.818 C178.172,107.213 177.041,108.344 175.646,108.344 C174.251,108.344 173.12,107.213 173.12,105.818 C173.12,104.423 174.251,103.292 175.646,103.292 C177.041,103.292 178.172,104.423 178.172,105.818" id="Fill-424" class="icon-color"/>
      <path d="M178.419,112.041 C178.419,113.572 177.177,114.814 175.646,114.814 C174.114,114.814 172.873,113.572 172.873,112.041 C172.873,110.509 174.114,109.268 175.646,109.268 C177.177,109.268 178.419,110.509 178.419,112.041" id="Fill-425" class="icon-color"/>
      <path d="M255.285,63.609 C255.285,62.747 255.983,62.048 256.845,62.048 C257.707,62.048 258.405,62.747 258.405,63.609 C258.405,64.471 257.707,65.169 256.845,65.169 C255.983,65.169 255.285,64.471 255.285,63.609" id="Fill-426" class="icon-color"/>
      <path d="M257.116,70.06 C257.891,70.21 258.399,70.96 258.249,71.735 C258.1,72.511 257.35,73.019 256.574,72.869 C255.799,72.72 255.291,71.97 255.441,71.194 C255.59,70.418 256.34,69.911 257.116,70.06" id="Fill-427" class="icon-color"/>
      <path d="M257.386,78.072 C258.076,78.371 258.392,79.172 258.093,79.862 C257.794,80.551 256.993,80.868 256.304,80.569 C255.614,80.27 255.298,79.468 255.597,78.779 C255.896,78.09 256.697,77.773 257.386,78.072" id="Fill-428" class="icon-color"/>
      <path d="M257.657,86.084 C258.26,86.532 258.386,87.385 257.937,87.988 C257.489,88.591 256.636,88.717 256.033,88.268 C255.43,87.82 255.304,86.967 255.753,86.364 C256.201,85.761 257.054,85.635 257.657,86.084" id="Fill-429" class="icon-color"/>
      <path d="M257.928,94.096 C258.445,94.694 258.379,95.598 257.781,96.115 C257.183,96.632 256.279,96.566 255.762,95.968 C255.245,95.37 255.311,94.466 255.909,93.949 C256.507,93.432 257.411,93.497 257.928,94.096" id="Fill-430" class="icon-color"/>
      <path d="M258.199,102.107 C258.629,102.855 258.373,103.81 257.625,104.241 C256.878,104.672 255.922,104.415 255.491,103.668 C255.061,102.92 255.317,101.965 256.065,101.534 C256.812,101.103 257.768,101.36 258.199,102.107" id="Fill-431" class="icon-color"/>
      <path d="M258.469,110.119 C258.814,111.016 258.366,112.023 257.469,112.368 C256.572,112.712 255.565,112.264 255.221,111.368 C254.876,110.47 255.324,109.464 256.221,109.119 C257.118,108.774 258.125,109.222 258.469,110.119" id="Fill-432" class="icon-color"/>
      <path d="M258.74,118.131 C258.999,119.178 258.36,120.235 257.313,120.494 C256.267,120.753 255.209,120.114 254.95,119.067 C254.691,118.021 255.33,116.962 256.377,116.704 C257.423,116.445 258.481,117.084 258.74,118.131" id="Fill-433" class="icon-color"/>
      <path d="M259.011,126.143 C259.183,127.339 258.353,128.448 257.157,128.621 C255.961,128.793 254.852,127.963 254.679,126.767 C254.507,125.571 255.337,124.461 256.533,124.289 C257.729,124.117 258.838,124.947 259.011,126.143" id="Fill-434" class="icon-color"/>
      <path d="M259.281,134.154 C259.368,135.5 258.347,136.661 257.001,136.747 C255.655,136.833 254.495,135.812 254.409,134.467 C254.322,133.121 255.343,131.96 256.689,131.874 C258.034,131.788 259.195,132.809 259.281,134.154" id="Fill-435" class="icon-color"/>
      <path d="M254.138,142.166 C254.138,140.671 255.35,139.459 256.845,139.459 C258.34,139.459 259.552,140.671 259.552,142.166 C259.552,143.661 258.34,144.873 256.845,144.873 C255.35,144.873 254.138,143.661 254.138,142.166" id="Fill-436" class="icon-color"/>
      <path d="M259.125,149.68 C259.125,150.94 258.104,151.961 256.845,151.961 C255.586,151.961 254.564,150.94 254.564,149.68 C254.564,148.421 255.586,147.4 256.845,147.4 C258.104,147.4 259.125,148.421 259.125,149.68" id="Fill-437" class="icon-color"/>
      <path d="M258.272,164.708 C258.272,165.496 257.633,166.135 256.845,166.135 C256.057,166.135 255.418,165.496 255.418,164.708 C255.418,163.92 256.057,163.282 256.845,163.282 C257.633,163.282 258.272,163.92 258.272,164.708" id="Fill-438" class="icon-color"/>
      <path d="M257.845,172.222 C257.845,172.775 257.397,173.222 256.845,173.222 C256.293,173.222 255.845,172.775 255.845,172.222 C255.845,171.67 256.293,171.222 256.845,171.222 C257.397,171.222 257.845,171.67 257.845,172.222" id="Fill-439" class="icon-color"/>
      <path d="M257.418,179.736 C257.418,180.053 257.162,180.31 256.845,180.31 C256.528,180.31 256.272,180.053 256.272,179.736 C256.272,179.42 256.528,179.163 256.845,179.163 C257.162,179.163 257.418,179.42 257.418,179.736" id="Fill-440" class="icon-color"/>
      <path d="M256.992,187.25 C256.992,187.332 256.926,187.397 256.845,187.397 C256.764,187.397 256.698,187.332 256.698,187.25 C256.698,187.17 256.764,187.104 256.845,187.104 C256.926,187.104 256.992,187.17 256.992,187.25" id="Fill-441" class="icon-color"/>
      <path d="M256.565,194.765 C256.565,194.61 256.69,194.484 256.845,194.484 C257,194.484 257.125,194.61 257.125,194.765 C257.125,194.919 257,195.045 256.845,195.045 C256.69,195.045 256.565,194.919 256.565,194.765" id="Fill-442" class="icon-color"/>
      <path d="M256.138,202.279 C256.138,201.888 256.455,201.572 256.845,201.572 C257.235,201.572 257.552,201.888 257.552,202.279 C257.552,202.669 257.235,202.985 256.845,202.985 C256.455,202.985 256.138,202.669 256.138,202.279" id="Fill-443" class="icon-color"/>
      <path d="M255.711,209.793 C255.711,209.167 256.219,208.659 256.845,208.659 C257.471,208.659 257.979,209.167 257.979,209.793 C257.979,210.419 257.471,210.926 256.845,210.926 C256.219,210.926 255.711,210.419 255.711,209.793" id="Fill-444" class="icon-color"/>
      <path d="M255.285,217.307 C255.285,216.445 255.983,215.746 256.845,215.746 C257.707,215.746 258.405,216.445 258.405,217.307 C258.405,218.168 257.707,218.867 256.845,218.867 C255.983,218.867 255.285,218.168 255.285,217.307" id="Fill-445" class="icon-color"/>
      <path d="M258.699,157.194 C258.699,158.218 257.869,159.048 256.845,159.048 C255.821,159.048 254.991,158.218 254.991,157.194 C254.991,156.17 255.821,155.341 256.845,155.341 C257.869,155.341 258.699,156.17 258.699,157.194" id="Fill-446" class="icon-color"/>
      <path d="M212.495,75.161 C212.495,74.321 213.176,73.64 214.016,73.64 C214.856,73.64 215.537,74.321 215.537,75.161 C215.537,76.001 214.856,76.682 214.016,76.682 C213.176,76.682 212.495,76.001 212.495,75.161" id="Fill-447" class="icon-color"/>
      <path d="M214.309,81.866 C215.056,82.028 215.53,82.765 215.368,83.512 C215.206,84.258 214.47,84.732 213.723,84.57 C212.976,84.408 212.502,83.672 212.664,82.925 C212.826,82.178 213.563,81.704 214.309,81.866" id="Fill-448" class="icon-color"/>
      <path d="M214.603,90.093 C215.256,90.417 215.523,91.209 215.199,91.862 C214.875,92.516 214.083,92.783 213.43,92.459 C212.776,92.135 212.509,91.343 212.833,90.689 C213.157,90.036 213.949,89.769 214.603,90.093" id="Fill-449" class="icon-color"/>
      <path d="M214.896,98.319 C215.456,98.805 215.516,99.653 215.03,100.213 C214.544,100.773 213.697,100.834 213.137,100.348 C212.576,99.862 212.516,99.014 213.002,98.454 C213.488,97.894 214.336,97.834 214.896,98.319" id="Fill-450" class="icon-color"/>
      <path d="M215.189,106.546 C215.656,107.194 215.509,108.097 214.861,108.564 C214.214,109.031 213.31,108.884 212.843,108.236 C212.376,107.589 212.523,106.685 213.171,106.218 C213.819,105.752 214.722,105.898 215.189,106.546" id="Fill-451" class="icon-color"/>
      <path d="M215.482,114.773 C215.856,115.583 215.502,116.541 214.692,116.915 C213.883,117.289 212.923,116.935 212.55,116.125 C212.177,115.315 212.53,114.356 213.34,113.983 C214.15,113.609 215.109,113.963 215.482,114.773" id="Fill-452" class="icon-color"/>
      <path d="M215.775,122.999 C216.056,123.971 215.495,124.986 214.523,125.266 C213.552,125.546 212.537,124.985 212.257,124.014 C211.977,123.042 212.538,122.027 213.509,121.747 C214.481,121.467 215.495,122.028 215.775,122.999" id="Fill-453" class="icon-color"/>
      <path d="M216.069,131.226 C216.255,132.36 215.488,133.43 214.354,133.617 C213.221,133.803 212.15,133.036 211.964,131.902 C211.777,130.769 212.545,129.698 213.678,129.512 C214.812,129.325 215.882,130.092 216.069,131.226" id="Fill-454" class="icon-color"/>
      <path d="M216.362,139.453 C216.455,140.748 215.481,141.874 214.185,141.968 C212.89,142.061 211.764,141.086 211.67,139.791 C211.577,138.495 212.551,137.369 213.847,137.276 C215.143,137.183 216.269,138.157 216.362,139.453" id="Fill-455" class="icon-color"/>
      <path d="M211.377,147.679 C211.377,146.222 212.559,145.04 214.016,145.04 C215.474,145.04 216.655,146.222 216.655,147.679 C216.655,149.137 215.474,150.319 214.016,150.319 C212.559,150.319 211.377,149.137 211.377,147.679" id="Fill-456" class="icon-color"/>
      <path d="M216.193,154.683 C216.193,155.885 215.218,156.86 214.016,156.86 C212.814,156.86 211.839,155.885 211.839,154.683 C211.839,153.481 212.814,152.506 214.016,152.506 C215.218,152.506 216.193,153.481 216.193,154.683" id="Fill-457" class="icon-color"/>
      <path d="M215.268,168.69 C215.268,169.381 214.708,169.942 214.016,169.942 C213.325,169.942 212.764,169.381 212.764,168.69 C212.764,167.998 213.325,167.438 214.016,167.438 C214.708,167.438 215.268,167.998 215.268,168.69" id="Fill-458" class="icon-color"/>
      <path d="M214.806,175.693 C214.806,176.13 214.452,176.483 214.016,176.483 C213.58,176.483 213.226,176.13 213.226,175.693 C213.226,175.257 213.58,174.903 214.016,174.903 C214.452,174.903 214.806,175.257 214.806,175.693" id="Fill-459" class="icon-color"/>
      <path d="M214.344,182.697 C214.344,182.878 214.197,183.025 214.016,183.025 C213.835,183.025 213.688,182.878 213.688,182.697 C213.688,182.516 213.835,182.369 214.016,182.369 C214.197,182.369 214.344,182.516 214.344,182.697" id="Fill-460" class="icon-color"/>
      <path d="M213.882,189.7 C213.882,189.626 213.942,189.566 214.016,189.566 C214.09,189.566 214.151,189.626 214.151,189.7 C214.151,189.775 214.09,189.835 214.016,189.835 C213.942,189.835 213.882,189.775 213.882,189.7" id="Fill-461" class="icon-color"/>
      <path d="M213.419,196.704 C213.419,196.374 213.687,196.107 214.016,196.107 C214.346,196.107 214.613,196.374 214.613,196.704 C214.613,197.033 214.346,197.3 214.016,197.3 C213.687,197.3 213.419,197.033 213.419,196.704" id="Fill-462" class="icon-color"/>
      <path d="M212.957,203.707 C212.957,203.122 213.431,202.648 214.016,202.648 C214.601,202.648 215.075,203.122 215.075,203.707 C215.075,204.292 214.601,204.766 214.016,204.766 C213.431,204.766 212.957,204.292 212.957,203.707" id="Fill-463" class="icon-color"/>
      <path d="M212.495,210.711 C212.495,209.87 213.176,209.189 214.016,209.189 C214.856,209.189 215.537,209.87 215.537,210.711 C215.537,211.551 214.856,212.232 214.016,212.232 C213.176,212.232 212.495,211.551 212.495,210.711" id="Fill-464" class="icon-color"/>
      <path d="M215.731,161.686 C215.731,162.633 214.963,163.401 214.016,163.401 C213.069,163.401 212.302,162.633 212.302,161.686 C212.302,160.739 213.069,159.972 214.016,159.972 C214.963,159.972 215.731,160.739 215.731,161.686" id="Fill-465" class="icon-color"/>
      <path d="M210.006,136.861 C210.006,138.311 208.831,139.486 207.381,139.486 C205.931,139.486 204.756,138.311 204.756,136.861 C204.756,135.411 205.931,134.236 207.381,134.236 C208.831,134.236 210.006,135.411 210.006,136.861" id="Fill-466" class="icon-color"/>
      <path d="M207.494,124.206 C206.334,124.302 205.316,123.439 205.221,122.279 C205.125,121.12 205.988,120.102 207.147,120.006 C208.307,119.911 209.325,120.773 209.421,121.933 C209.516,123.093 208.654,124.111 207.494,124.206" id="Fill-467" class="icon-color"/>
      <path d="M207.55,116.567 C206.535,116.71 205.596,116.004 205.453,114.989 C205.309,113.974 206.016,113.035 207.031,112.891 C208.046,112.748 208.985,113.454 209.128,114.469 C209.271,115.484 208.565,116.423 207.55,116.567" id="Fill-468" class="icon-color"/>
      <path d="M207.607,108.927 C206.737,109.118 205.876,108.568 205.685,107.698 C205.494,106.828 206.044,105.968 206.914,105.776 C207.784,105.585 208.644,106.135 208.835,107.005 C209.027,107.875 208.477,108.735 207.607,108.927" id="Fill-469" class="icon-color"/>
      <path d="M207.663,101.287 C206.938,101.526 206.156,101.132 205.917,100.407 C205.678,99.682 206.072,98.901 206.797,98.662 C207.522,98.423 208.303,98.816 208.543,99.541 C208.782,100.266 208.388,101.048 207.663,101.287" id="Fill-470" class="icon-color"/>
      <path d="M207.774,93.647 C207.194,93.934 206.491,93.696 206.204,93.116 C205.917,92.536 206.155,91.834 206.735,91.547 C207.315,91.26 208.017,91.497 208.304,92.077 C208.591,92.657 208.354,93.36 207.774,93.647" id="Fill-471" class="icon-color"/>
      <path d="M207.894,86.007 C207.46,86.342 206.835,86.261 206.501,85.826 C206.166,85.391 206.247,84.767 206.682,84.432 C207.117,84.097 207.741,84.179 208.076,84.613 C208.411,85.048 208.329,85.672 207.894,86.007" id="Fill-472" class="icon-color"/>
      <path d="M208.015,78.367 C207.725,78.75 207.18,78.825 206.797,78.535 C206.415,78.245 206.339,77.7 206.63,77.317 C206.919,76.935 207.465,76.86 207.847,77.15 C208.23,77.439 208.305,77.985 208.015,78.367" id="Fill-473" class="icon-color"/>
      <path d="M208.168,70.728 C208.023,71.158 207.557,71.389 207.127,71.244 C206.696,71.099 206.465,70.633 206.61,70.202 C206.755,69.772 207.221,69.541 207.652,69.686 C208.082,69.831 208.313,70.297 208.168,70.728" id="Fill-474" class="icon-color"/>
      <path d="M208.256,63.088 C208.256,63.566 207.868,63.953 207.39,63.953 C206.912,63.953 206.524,63.566 206.524,63.088 C206.524,62.609 206.912,62.222 207.39,62.222 C207.868,62.222 208.256,62.609 208.256,63.088" id="Fill-475" class="icon-color"/>
      <path d="M207.468,131.846 C206.163,131.894 205.067,130.875 205.019,129.57 C204.971,128.265 205.99,127.169 207.295,127.121 C208.6,127.073 209.697,128.092 209.744,129.397 C209.792,130.702 208.773,131.799 207.468,131.846" id="Fill-476" class="icon-color"/>
      <path d="M200.847,106.222 C199.634,106.259 198.621,105.305 198.584,104.092 C198.548,102.878 199.502,101.865 200.715,101.829 C201.928,101.793 202.941,102.747 202.978,103.96 C203.014,105.173 202.06,106.186 200.847,106.222" id="Fill-477" class="icon-color"/>
      <path d="M200.858,99.855 C199.755,99.928 198.802,99.093 198.729,97.99 C198.656,96.887 199.492,95.934 200.594,95.861 C201.698,95.788 202.651,96.624 202.723,97.727 C202.796,98.83 201.961,99.783 200.858,99.855" id="Fill-478" class="icon-color"/>
      <path d="M200.899,93.488 C199.906,93.598 199.013,92.881 198.904,91.889 C198.794,90.896 199.511,90.003 200.503,89.894 C201.496,89.785 202.389,90.501 202.499,91.493 C202.608,92.486 201.891,93.379 200.899,93.488" id="Fill-479" class="icon-color"/>
      <path d="M200.939,87.121 C200.057,87.267 199.224,86.67 199.078,85.787 C198.933,84.905 199.53,84.072 200.413,83.926 C201.295,83.781 202.128,84.378 202.274,85.26 C202.419,86.143 201.822,86.976 200.939,87.121" id="Fill-480" class="icon-color"/>
      <path d="M200.98,80.754 C200.208,80.936 199.435,80.458 199.253,79.686 C199.071,78.914 199.549,78.14 200.321,77.958 C201.094,77.777 201.867,78.255 202.049,79.027 C202.231,79.799 201.752,80.573 200.98,80.754" id="Fill-481" class="icon-color"/>
      <path d="M201.039,74.387 C200.377,74.606 199.664,74.246 199.445,73.584 C199.227,72.923 199.587,72.209 200.248,71.991 C200.91,71.773 201.624,72.132 201.842,72.794 C202.06,73.456 201.701,74.169 201.039,74.387" id="Fill-482" class="icon-color"/>
      <path d="M201.128,68.02 C200.576,68.275 199.923,68.034 199.668,67.483 C199.413,66.931 199.654,66.278 200.205,66.023 C200.757,65.769 201.41,66.009 201.665,66.561 C201.92,67.112 201.679,67.766 201.128,68.02" id="Fill-483" class="icon-color"/>
      <path d="M201.216,61.653 C200.775,61.944 200.182,61.823 199.891,61.382 C199.6,60.94 199.721,60.347 200.162,60.056 C200.604,59.765 201.197,59.886 201.488,60.327 C201.779,60.769 201.658,61.362 201.216,61.653" id="Fill-484" class="icon-color"/>
      <path d="M201.305,55.286 C200.974,55.614 200.441,55.611 200.113,55.28 C199.786,54.949 199.788,54.415 200.12,54.088 C200.45,53.761 200.984,53.764 201.311,54.094 C201.639,54.425 201.636,54.959 201.305,55.286" id="Fill-485" class="icon-color"/>
      <path d="M201.44,48.919 C201.219,49.283 200.745,49.399 200.382,49.179 C200.018,48.958 199.902,48.484 200.122,48.121 C200.343,47.757 200.817,47.641 201.18,47.861 C201.544,48.082 201.66,48.555 201.44,48.919" id="Fill-486" class="icon-color"/>
      <path d="M201.506,42.552 C201.395,42.953 200.982,43.188 200.581,43.077 C200.181,42.967 199.946,42.553 200.056,42.153 C200.167,41.753 200.581,41.518 200.981,41.628 C201.381,41.738 201.616,42.152 201.506,42.552" id="Fill-487" class="icon-color"/>
      <path d="M201.572,36.185 C201.572,36.622 201.217,36.976 200.781,36.976 C200.344,36.976 199.99,36.622 199.99,36.185 C199.99,35.749 200.344,35.395 200.781,35.395 C201.217,35.395 201.572,35.749 201.572,36.185" id="Fill-488" class="icon-color"/>
      <path d="M200.217,166.12 C200.217,165.68 200.573,165.323 201.014,165.323 C201.454,165.323 201.811,165.68 201.811,166.12 C201.811,166.56 201.454,166.917 201.014,166.917 C200.573,166.917 200.217,166.56 200.217,166.12" id="Fill-489" class="icon-color"/>
      <path d="M199.97,159.897 C199.97,159.32 200.437,158.853 201.014,158.853 C201.59,158.853 202.058,159.32 202.058,159.897 C202.058,160.474 201.59,160.941 201.014,160.941 C200.437,160.941 199.97,160.474 199.97,159.897" id="Fill-490" class="icon-color"/>
      <path d="M199.723,153.674 C199.723,152.961 200.301,152.383 201.014,152.383 C201.727,152.383 202.305,152.961 202.305,153.674 C202.305,154.387 201.727,154.965 201.014,154.965 C200.301,154.965 199.723,154.387 199.723,153.674" id="Fill-491" class="icon-color"/>
      <path d="M199.475,147.451 C199.475,146.601 200.164,145.913 201.014,145.913 C201.863,145.913 202.551,146.601 202.551,147.451 C202.551,148.3 201.863,148.989 201.014,148.989 C200.164,148.989 199.475,148.3 199.475,147.451" id="Fill-492" class="icon-color"/>
      <path d="M199.229,141.228 C199.229,140.242 200.028,139.443 201.014,139.443 C201.999,139.443 202.798,140.242 202.798,141.228 C202.798,142.213 201.999,143.013 201.014,143.013 C200.028,143.013 199.229,142.213 199.229,141.228" id="Fill-493" class="icon-color"/>
      <path d="M198.982,135.005 C198.982,133.883 199.891,132.973 201.014,132.973 C202.136,132.973 203.046,133.883 203.046,135.005 C203.046,136.127 202.136,137.037 201.014,137.037 C199.891,137.037 198.982,136.127 198.982,135.005" id="Fill-494" class="icon-color"/>
      <path d="M198.735,128.782 C198.735,127.523 199.755,126.503 201.014,126.503 C202.272,126.503 203.292,127.523 203.292,128.782 C203.292,130.04 202.272,131.061 201.014,131.061 C199.755,131.061 198.735,130.04 198.735,128.782" id="Fill-495" class="icon-color"/>
      <path d="M198.488,122.559 C198.488,121.164 199.619,120.033 201.014,120.033 C202.409,120.033 203.54,121.164 203.54,122.559 C203.54,123.954 202.409,125.084 201.014,125.084 C199.619,125.084 198.488,123.954 198.488,122.559" id="Fill-496" class="icon-color"/>
      <path d="M198.241,116.336 C198.241,114.804 199.482,113.563 201.014,113.563 C202.545,113.563 203.786,114.804 203.786,116.336 C203.786,117.867 202.545,119.109 201.014,119.109 C199.482,119.109 198.241,117.867 198.241,116.336" id="Fill-497" class="icon-color"/>
      <path d="M203.177,110.193 C203.177,111.517 202.105,112.59 200.781,112.59 C199.457,112.59 198.385,111.517 198.385,110.193 C198.385,108.87 199.457,107.797 200.781,107.797 C202.105,107.797 203.177,108.87 203.177,110.193" id="Fill-498" class="icon-color"/>
      <path d="M40.27,159.692 C40.27,160.589 39.543,161.317 38.645,161.317 C37.748,161.317 37.02,160.589 37.02,159.692 C37.02,158.794 37.748,158.067 38.645,158.067 C39.543,158.067 40.27,158.794 40.27,159.692" id="Fill-499" class="icon-color"/>
      <path d="M38.363,154.421 C37.556,154.266 37.027,153.485 37.183,152.677 C37.339,151.869 38.12,151.341 38.927,151.497 C39.735,151.652 40.264,152.433 40.108,153.241 C39.952,154.048 39.171,154.577 38.363,154.421" id="Fill-500" class="icon-color"/>
      <path d="M38.082,147.526 C37.364,147.215 37.034,146.38 37.345,145.662 C37.657,144.944 38.491,144.615 39.209,144.926 C39.927,145.237 40.257,146.072 39.945,146.79 C39.634,147.508 38.8,147.837 38.082,147.526" id="Fill-501" class="icon-color"/>
      <path d="M37.8,140.631 C37.172,140.163 37.041,139.275 37.508,138.647 C37.975,138.019 38.863,137.888 39.491,138.355 C40.119,138.823 40.25,139.711 39.783,140.339 C39.316,140.967 38.428,141.097 37.8,140.631" id="Fill-502" class="icon-color"/>
      <path d="M37.518,133.735 C36.979,133.112 37.048,132.171 37.67,131.633 C38.293,131.094 39.234,131.162 39.773,131.785 C40.311,132.408 40.243,133.349 39.62,133.888 C38.998,134.426 38.056,134.358 37.518,133.735" id="Fill-503" class="icon-color"/>
      <path d="M37.236,126.84 C36.787,126.061 37.054,125.066 37.833,124.618 C38.611,124.169 39.606,124.436 40.055,125.215 C40.504,125.993 40.236,126.988 39.458,127.437 C38.679,127.885 37.685,127.618 37.236,126.84" id="Fill-504" class="icon-color"/>
      <path d="M36.672,113.049 C36.403,111.959 37.068,110.857 38.158,110.588 C39.248,110.319 40.349,110.984 40.619,112.074 C40.888,113.164 40.223,114.265 39.133,114.535 C38.043,114.804 36.941,114.139 36.672,113.049" id="Fill-505" class="icon-color"/>
      <path d="M36.39,106.153 C36.211,104.908 37.075,103.753 38.32,103.573 C39.566,103.394 40.721,104.258 40.901,105.503 C41.08,106.749 40.216,107.904 38.97,108.084 C37.725,108.263 36.57,107.399 36.39,106.153" id="Fill-506" class="icon-color"/>
      <path d="M36.108,99.258 C36.018,97.857 37.082,96.648 38.483,96.558 C39.884,96.469 41.093,97.532 41.182,98.933 C41.272,100.334 40.209,101.543 38.808,101.633 C37.407,101.722 36.198,100.659 36.108,99.258" id="Fill-507" class="icon-color"/>
      <path d="M41.464,92.362 C41.464,93.919 40.202,95.181 38.645,95.181 C37.089,95.181 35.827,93.919 35.827,92.362 C35.827,90.806 37.089,89.543 38.645,89.543 C40.202,89.543 41.464,90.806 41.464,92.362" id="Fill-508" class="icon-color"/>
      <path d="M36.271,85.63 C36.271,84.318 37.334,83.255 38.645,83.255 C39.957,83.255 41.02,84.318 41.02,85.63 C41.02,86.941 39.957,88.004 38.645,88.004 C37.334,88.004 36.271,86.941 36.271,85.63" id="Fill-509" class="icon-color"/>
      <path d="M36.715,78.897 C36.715,77.831 37.579,76.966 38.645,76.966 C39.711,76.966 40.576,77.831 40.576,78.897 C40.576,79.963 39.711,80.827 38.645,80.827 C37.579,80.827 36.715,79.963 36.715,78.897" id="Fill-510" class="icon-color"/>
      <path d="M37.16,72.164 C37.16,71.343 37.825,70.678 38.645,70.678 C39.466,70.678 40.131,71.343 40.131,72.164 C40.131,72.984 39.466,73.65 38.645,73.65 C37.825,73.65 37.16,72.984 37.16,72.164" id="Fill-511" class="icon-color"/>
      <path d="M37.604,65.431 C37.604,64.856 38.07,64.389 38.645,64.389 C39.221,64.389 39.687,64.856 39.687,65.431 C39.687,66.006 39.221,66.472 38.645,66.472 C38.07,66.472 37.604,66.006 37.604,65.431" id="Fill-512" class="icon-color"/>
      <path d="M38.048,58.698 C38.048,58.368 38.316,58.101 38.645,58.101 C38.975,58.101 39.242,58.368 39.242,58.698 C39.242,59.028 38.975,59.295 38.645,59.295 C38.316,59.295 38.048,59.028 38.048,58.698" id="Fill-513" class="icon-color"/>
      <path d="M38.493,51.965 C38.493,51.881 38.561,51.812 38.645,51.812 C38.73,51.812 38.798,51.881 38.798,51.965 C38.798,52.049 38.73,52.118 38.645,52.118 C38.561,52.118 38.493,52.049 38.493,51.965" id="Fill-514" class="icon-color"/>
      <path d="M38.937,45.232 C38.937,45.393 38.806,45.524 38.645,45.524 C38.484,45.524 38.354,45.393 38.354,45.232 C38.354,45.071 38.484,44.94 38.645,44.94 C38.806,44.94 38.937,45.071 38.937,45.232" id="Fill-515" class="icon-color"/>
      <path d="M39.382,38.499 C39.382,38.906 39.052,39.235 38.645,39.235 C38.239,39.235 37.909,38.906 37.909,38.499 C37.909,38.093 38.239,37.763 38.645,37.763 C39.052,37.763 39.382,38.093 39.382,38.499" id="Fill-516" class="icon-color"/>
      <path d="M39.826,31.766 C39.826,32.418 39.297,32.947 38.645,32.947 C37.993,32.947 37.465,32.418 37.465,31.766 C37.465,31.114 37.993,30.586 38.645,30.586 C39.297,30.586 39.826,31.114 39.826,31.766" id="Fill-517" class="icon-color"/>
      <path d="M40.27,25.033 C40.27,25.931 39.543,26.658 38.645,26.658 C37.748,26.658 37.02,25.931 37.02,25.033 C37.02,24.136 37.748,23.408 38.645,23.408 C39.543,23.408 40.27,24.136 40.27,25.033" id="Fill-518" class="icon-color"/>
      <path d="M36.954,119.944 C36.595,119.01 37.061,117.962 37.995,117.603 C38.93,117.244 39.978,117.71 40.337,118.644 C40.696,119.578 40.229,120.627 39.295,120.986 C38.361,121.345 37.313,120.878 36.954,119.944" id="Fill-519" class="icon-color"/>
      <path d="M5.987,77.9 C5.987,77.102 6.633,76.456 7.431,76.456 C8.228,76.456 8.875,77.102 8.875,77.9 C8.875,78.697 8.228,79.344 7.431,79.344 C6.633,79.344 5.987,78.697 5.987,77.9" id="Fill-520" class="icon-color"/>
      <path d="M7.681,82.584 C8.399,82.722 8.869,83.417 8.731,84.134 C8.592,84.852 7.898,85.322 7.18,85.184 C6.462,85.045 5.993,84.351 6.131,83.633 C6.27,82.915 6.963,82.446 7.681,82.584" id="Fill-521" class="icon-color"/>
      <path d="M7.932,88.712 C8.57,88.989 8.863,89.731 8.586,90.369 C8.309,91.007 7.568,91.3 6.93,91.023 C6.292,90.746 5.999,90.005 6.275,89.367 C6.552,88.729 7.294,88.436 7.932,88.712" id="Fill-522" class="icon-color"/>
      <path d="M8.182,94.841 C8.741,95.256 8.857,96.045 8.442,96.604 C8.027,97.162 7.237,97.278 6.679,96.863 C6.121,96.448 6.005,95.659 6.42,95.1 C6.835,94.542 7.624,94.426 8.182,94.841" id="Fill-523" class="icon-color"/>
      <path d="M8.433,100.969 C8.912,101.523 8.851,102.36 8.297,102.838 C7.744,103.317 6.907,103.256 6.429,102.702 C5.95,102.149 6.011,101.312 6.564,100.834 C7.118,100.355 7.954,100.416 8.433,100.969" id="Fill-524" class="icon-color"/>
      <path d="M8.684,107.098 C9.082,107.79 8.845,108.674 8.153,109.073 C7.461,109.471 6.577,109.234 6.178,108.542 C5.779,107.85 6.017,106.966 6.709,106.567 C7.401,106.168 8.285,106.406 8.684,107.098" id="Fill-525" class="icon-color"/>
      <path d="M8.934,113.226 C9.253,114.057 8.839,114.988 8.008,115.307 C7.178,115.626 6.247,115.212 5.928,114.382 C5.608,113.551 6.023,112.62 6.853,112.301 C7.683,111.982 8.615,112.396 8.934,113.226" id="Fill-526" class="icon-color"/>
      <path d="M9.185,119.355 C9.424,120.323 8.833,121.303 7.864,121.542 C6.895,121.781 5.916,121.19 5.677,120.221 C5.438,119.253 6.029,118.274 6.998,118.034 C7.966,117.795 8.945,118.386 9.185,119.355" id="Fill-527" class="icon-color"/>
      <path d="M9.435,125.483 C9.595,126.59 8.827,127.617 7.72,127.776 C6.613,127.936 5.586,127.168 5.427,126.061 C5.267,124.954 6.035,123.927 7.142,123.768 C8.249,123.608 9.276,124.376 9.435,125.483" id="Fill-528" class="icon-color"/>
      <path d="M4.925,137.74 C4.925,136.357 6.047,135.235 7.431,135.235 C8.815,135.235 9.936,136.357 9.936,137.74 C9.936,139.124 8.815,140.246 7.431,140.246 C6.047,140.246 4.925,139.124 4.925,137.74" id="Fill-529" class="icon-color"/>
      <path d="M9.541,143.724 C9.541,144.89 8.596,145.835 7.431,145.835 C6.265,145.835 5.32,144.89 5.32,143.724 C5.32,142.558 6.265,141.614 7.431,141.614 C8.596,141.614 9.541,142.558 9.541,143.724" id="Fill-530" class="icon-color"/>
      <path d="M9.146,149.708 C9.146,150.656 8.378,151.424 7.431,151.424 C6.483,151.424 5.715,150.656 5.715,149.708 C5.715,148.761 6.483,147.993 7.431,147.993 C8.378,147.993 9.146,148.761 9.146,149.708" id="Fill-531" class="icon-color"/>
      <path d="M8.751,155.692 C8.751,156.422 8.16,157.013 7.431,157.013 C6.702,157.013 6.11,156.422 6.11,155.692 C6.11,154.963 6.702,154.372 7.431,154.372 C8.16,154.372 8.751,154.963 8.751,155.692" id="Fill-532" class="icon-color"/>
      <path d="M8.356,161.676 C8.356,162.187 7.942,162.602 7.431,162.602 C6.92,162.602 6.505,162.187 6.505,161.676 C6.505,161.165 6.92,160.751 7.431,160.751 C7.942,160.751 8.356,161.165 8.356,161.676" id="Fill-533" class="icon-color"/>
      <path d="M7.961,167.66 C7.961,167.953 7.724,168.191 7.431,168.191 C7.138,168.191 6.9,167.953 6.9,167.66 C6.9,167.367 7.138,167.13 7.431,167.13 C7.724,167.13 7.961,167.367 7.961,167.66" id="Fill-534" class="icon-color"/>
      <path d="M7.566,173.644 C7.566,173.719 7.506,173.78 7.431,173.78 C7.356,173.78 7.295,173.719 7.295,173.644 C7.295,173.569 7.356,173.508 7.431,173.508 C7.506,173.508 7.566,173.569 7.566,173.644" id="Fill-535" class="icon-color"/>
      <path d="M7.172,179.628 C7.172,179.485 7.288,179.369 7.431,179.369 C7.574,179.369 7.69,179.485 7.69,179.628 C7.69,179.771 7.574,179.888 7.431,179.888 C7.288,179.888 7.172,179.771 7.172,179.628" id="Fill-536" class="icon-color"/>
      <path d="M6.777,185.612 C6.777,185.251 7.069,184.958 7.431,184.958 C7.792,184.958 8.085,185.251 8.085,185.612 C8.085,185.974 7.792,186.267 7.431,186.267 C7.069,186.267 6.777,185.974 6.777,185.612" id="Fill-537" class="icon-color"/>
      <path d="M6.382,191.596 C6.382,191.017 6.851,190.547 7.431,190.547 C8.01,190.547 8.48,191.017 8.48,191.596 C8.48,192.176 8.01,192.646 7.431,192.646 C6.851,192.646 6.382,192.176 6.382,191.596" id="Fill-538" class="icon-color"/>
      <path d="M5.987,197.58 C5.987,196.783 6.633,196.136 7.431,196.136 C8.228,196.136 8.875,196.783 8.875,197.58 C8.875,198.378 8.228,199.025 7.431,199.025 C6.633,199.025 5.987,198.378 5.987,197.58" id="Fill-539" class="icon-color"/>
      <path d="M9.686,131.612 C9.765,132.857 8.821,133.931 7.575,134.011 C6.33,134.091 5.256,133.146 5.176,131.9 C5.096,130.655 6.041,129.581 7.286,129.501 C8.532,129.421 9.606,130.366 9.686,131.612" id="Fill-540" class="icon-color"/>
      <path d="M33.828,177.59 C33.828,178.452 33.13,179.15 32.268,179.15 C31.406,179.15 30.708,178.452 30.708,177.59 C30.708,176.728 31.406,176.03 32.268,176.03 C33.13,176.03 33.828,176.728 33.828,177.59" id="Fill-541" class="icon-color"/>
      <path d="M31.997,172.329 C31.222,172.179 30.714,171.429 30.864,170.653 C31.013,169.878 31.763,169.37 32.539,169.52 C33.314,169.669 33.822,170.419 33.672,171.195 C33.523,171.971 32.773,172.478 31.997,172.329" id="Fill-542" class="icon-color"/>
      <path d="M31.726,165.507 C31.037,165.208 30.72,164.407 31.02,163.717 C31.319,163.028 32.12,162.711 32.809,163.01 C33.499,163.309 33.815,164.11 33.516,164.8 C33.217,165.489 32.416,165.806 31.726,165.507" id="Fill-543" class="icon-color"/>
      <path d="M31.456,158.685 C30.853,158.236 30.727,157.384 31.176,156.78 C31.624,156.177 32.477,156.052 33.08,156.5 C33.683,156.949 33.809,157.802 33.36,158.405 C32.912,159.008 32.059,159.133 31.456,158.685" id="Fill-544" class="icon-color"/>
      <path d="M31.185,151.863 C30.668,151.265 30.734,150.361 31.332,149.844 C31.93,149.327 32.834,149.392 33.351,149.991 C33.868,150.589 33.802,151.493 33.204,152.01 C32.606,152.527 31.702,152.461 31.185,151.863" id="Fill-545" class="icon-color"/>
      <path d="M30.914,145.041 C30.483,144.294 30.74,143.338 31.488,142.907 C32.235,142.477 33.191,142.733 33.622,143.481 C34.052,144.228 33.796,145.184 33.048,145.615 C32.301,146.046 31.345,145.789 30.914,145.041" id="Fill-546" class="icon-color"/>
      <path d="M30.644,138.219 C30.299,137.322 30.747,136.316 31.644,135.971 C32.541,135.626 33.548,136.074 33.892,136.971 C34.237,137.868 33.789,138.875 32.892,139.22 C31.995,139.564 30.988,139.117 30.644,138.219" id="Fill-547" class="icon-color"/>
      <path d="M30.373,131.398 C30.114,130.351 30.753,129.293 31.8,129.034 C32.846,128.776 33.904,129.415 34.163,130.461 C34.421,131.508 33.783,132.566 32.736,132.825 C31.689,133.083 30.632,132.444 30.373,131.398" id="Fill-548" class="icon-color"/>
      <path d="M30.102,124.576 C29.93,123.38 30.76,122.27 31.956,122.098 C33.152,121.926 34.261,122.756 34.434,123.952 C34.606,125.148 33.776,126.257 32.58,126.429 C31.384,126.602 30.275,125.772 30.102,124.576" id="Fill-549" class="icon-color"/>
      <path d="M29.831,117.754 C29.745,116.408 30.766,115.248 32.112,115.162 C33.457,115.075 34.618,116.096 34.704,117.442 C34.791,118.788 33.77,119.948 32.424,120.034 C31.078,120.121 29.918,119.099 29.831,117.754" id="Fill-550" class="icon-color"/>
      <path d="M34.975,110.932 C34.975,112.427 33.763,113.639 32.268,113.639 C30.773,113.639 29.561,112.427 29.561,110.932 C29.561,109.437 30.773,108.225 32.268,108.225 C33.763,108.225 34.975,109.437 34.975,110.932" id="Fill-551" class="icon-color"/>
      <path d="M30.414,97.104 C30.414,96.08 31.244,95.25 32.268,95.25 C33.292,95.25 34.122,96.08 34.122,97.104 C34.122,98.127 33.292,98.957 32.268,98.957 C31.244,98.957 30.414,98.127 30.414,97.104" id="Fill-552" class="icon-color"/>
      <path d="M30.841,90.19 C30.841,89.402 31.48,88.763 32.268,88.763 C33.056,88.763 33.695,89.402 33.695,90.19 C33.695,90.978 33.056,91.617 32.268,91.617 C31.48,91.617 30.841,90.978 30.841,90.19" id="Fill-553" class="icon-color"/>
      <path d="M31.268,83.276 C31.268,82.723 31.716,82.276 32.268,82.276 C32.82,82.276 33.268,82.723 33.268,83.276 C33.268,83.828 32.82,84.276 32.268,84.276 C31.716,84.276 31.268,83.828 31.268,83.276" id="Fill-554" class="icon-color"/>
      <path d="M31.695,76.361 C31.695,76.045 31.951,75.788 32.268,75.788 C32.585,75.788 32.841,76.045 32.841,76.361 C32.841,76.678 32.585,76.935 32.268,76.935 C31.951,76.935 31.695,76.678 31.695,76.361" id="Fill-555" class="icon-color"/>
      <path d="M32.121,69.447 C32.121,69.367 32.187,69.301 32.268,69.301 C32.349,69.301 32.414,69.367 32.414,69.447 C32.414,69.528 32.349,69.594 32.268,69.594 C32.187,69.594 32.121,69.528 32.121,69.447" id="Fill-556" class="icon-color"/>
      <path d="M32.548,62.533 C32.548,62.688 32.423,62.813 32.268,62.813 C32.113,62.813 31.988,62.688 31.988,62.533 C31.988,62.379 32.113,62.253 32.268,62.253 C32.423,62.253 32.548,62.379 32.548,62.533" id="Fill-557" class="icon-color"/>
      <path d="M32.975,55.619 C32.975,56.01 32.658,56.326 32.268,56.326 C31.878,56.326 31.561,56.01 31.561,55.619 C31.561,55.229 31.878,54.912 32.268,54.912 C32.658,54.912 32.975,55.229 32.975,55.619" id="Fill-558" class="icon-color"/>
      <path d="M33.402,48.705 C33.402,49.331 32.894,49.839 32.268,49.839 C31.642,49.839 31.134,49.331 31.134,48.705 C31.134,48.079 31.642,47.571 32.268,47.571 C32.894,47.571 33.402,48.079 33.402,48.705" id="Fill-559" class="icon-color"/>
      <path d="M33.828,41.791 C33.828,42.653 33.13,43.351 32.268,43.351 C31.406,43.351 30.708,42.653 30.708,41.791 C30.708,40.929 31.406,40.231 32.268,40.231 C33.13,40.231 33.828,40.929 33.828,41.791" id="Fill-560" class="icon-color"/>
      <path d="M29.988,104.018 C29.988,102.759 31.009,101.738 32.268,101.738 C33.527,101.738 34.548,102.759 34.548,104.018 C34.548,105.277 33.527,106.298 32.268,106.298 C31.009,106.298 29.988,105.277 29.988,104.018" id="Fill-561" class="icon-color"/>
      <path d="M11.908,49.566 C11.908,48.726 12.589,48.045 13.429,48.045 C14.269,48.045 14.95,48.726 14.95,49.566 C14.95,50.406 14.269,51.087 13.429,51.087 C12.589,51.087 11.908,50.406 11.908,49.566" id="Fill-562" class="icon-color"/>
      <path d="M13.723,54.438 C14.469,54.6 14.943,55.337 14.781,56.083 C14.619,56.83 13.883,57.304 13.136,57.142 C12.389,56.98 11.915,56.244 12.077,55.497 C12.239,54.75 12.975,54.276 13.723,54.438" id="Fill-563" class="icon-color"/>
      <path d="M14.016,60.831 C14.669,61.156 14.936,61.948 14.612,62.601 C14.288,63.255 13.496,63.522 12.843,63.198 C12.189,62.874 11.922,62.082 12.246,61.428 C12.57,60.775 13.362,60.508 14.016,60.831" id="Fill-564" class="icon-color"/>
      <path d="M14.309,67.225 C14.869,67.711 14.929,68.559 14.443,69.119 C13.957,69.679 13.11,69.739 12.549,69.253 C11.99,68.767 11.929,67.919 12.415,67.359 C12.901,66.799 13.749,66.739 14.309,67.225" id="Fill-565" class="icon-color"/>
      <path d="M14.602,73.618 C15.069,74.266 14.922,75.17 14.274,75.636 C13.626,76.103 12.723,75.956 12.256,75.309 C11.79,74.661 11.936,73.757 12.584,73.291 C13.232,72.824 14.135,72.971 14.602,73.618" id="Fill-566" class="icon-color"/>
      <path d="M14.895,80.012 C15.269,80.822 14.915,81.781 14.105,82.154 C13.296,82.528 12.336,82.174 11.963,81.364 C11.59,80.554 11.943,79.595 12.753,79.222 C13.563,78.848 14.522,79.202 14.895,80.012" id="Fill-567" class="icon-color"/>
      <path d="M15.482,92.799 C15.668,93.932 14.901,95.003 13.767,95.189 C12.634,95.376 11.563,94.608 11.377,93.475 C11.19,92.341 11.958,91.271 13.091,91.084 C14.225,90.898 15.295,91.665 15.482,92.799" id="Fill-568" class="icon-color"/>
      <path d="M15.775,99.192 C15.868,100.488 14.894,101.614 13.598,101.707 C12.303,101.801 11.177,100.826 11.083,99.53 C10.99,98.235 11.965,97.109 13.26,97.016 C14.556,96.922 15.682,97.897 15.775,99.192" id="Fill-569" class="icon-color"/>
      <path d="M10.79,105.586 C10.79,104.128 11.972,102.947 13.429,102.947 C14.887,102.947 16.068,104.128 16.068,105.586 C16.068,107.043 14.887,108.225 13.429,108.225 C11.972,108.225 10.79,107.043 10.79,105.586" id="Fill-570" class="icon-color"/>
      <path d="M15.606,112.589 C15.606,113.791 14.631,114.766 13.429,114.766 C12.227,114.766 11.252,113.791 11.252,112.589 C11.252,111.387 12.227,110.413 13.429,110.413 C14.631,110.413 15.606,111.387 15.606,112.589" id="Fill-571" class="icon-color"/>
      <path d="M15.144,119.593 C15.144,120.54 14.376,121.307 13.429,121.307 C12.482,121.307 11.715,120.54 11.715,119.593 C11.715,118.646 12.482,117.878 13.429,117.878 C14.376,117.878 15.144,118.646 15.144,119.593" id="Fill-572" class="icon-color"/>
      <path d="M14.681,126.596 C14.681,127.288 14.121,127.848 13.429,127.848 C12.738,127.848 12.177,127.288 12.177,126.596 C12.177,125.905 12.738,125.344 13.429,125.344 C14.121,125.344 14.681,125.905 14.681,126.596" id="Fill-573" class="icon-color"/>
      <path d="M14.219,133.6 C14.219,134.036 13.866,134.39 13.429,134.39 C12.993,134.39 12.639,134.036 12.639,133.6 C12.639,133.163 12.993,132.81 13.429,132.81 C13.866,132.81 14.219,133.163 14.219,133.6" id="Fill-574" class="icon-color"/>
      <path d="M13.757,140.603 C13.757,140.784 13.61,140.931 13.429,140.931 C13.248,140.931 13.101,140.784 13.101,140.603 C13.101,140.422 13.248,140.275 13.429,140.275 C13.61,140.275 13.757,140.422 13.757,140.603" id="Fill-575" class="icon-color"/>
      <path d="M13.295,147.607 C13.295,147.532 13.355,147.472 13.429,147.472 C13.503,147.472 13.564,147.532 13.564,147.607 C13.564,147.681 13.503,147.741 13.429,147.741 C13.355,147.741 13.295,147.681 13.295,147.607" id="Fill-576" class="icon-color"/>
      <path d="M12.832,154.61 C12.832,154.28 13.1,154.013 13.429,154.013 C13.759,154.013 14.026,154.28 14.026,154.61 C14.026,154.939 13.759,155.207 13.429,155.207 C13.1,155.207 12.832,154.939 12.832,154.61" id="Fill-577" class="icon-color"/>
      <path d="M12.37,161.614 C12.37,161.029 12.844,160.554 13.429,160.554 C14.014,160.554 14.488,161.029 14.488,161.614 C14.488,162.198 14.014,162.672 13.429,162.672 C12.844,162.672 12.37,162.198 12.37,161.614" id="Fill-578" class="icon-color"/>
      <path d="M11.908,168.617 C11.908,167.777 12.589,167.096 13.429,167.096 C14.269,167.096 14.95,167.777 14.95,168.617 C14.95,169.457 14.269,170.138 13.429,170.138 C12.589,170.138 11.908,169.457 11.908,168.617" id="Fill-579" class="icon-color"/>
      <g id="Group-583" transform="translate(0.000000, 85.000000)">
        <mask id="mask-4" fill="white">
          <use xlink:href="#path-3"/>
        </mask>
        <g id="Clip-581"/>
        <path d="M15.188,1.405 C15.469,2.377 14.908,3.392 13.936,3.672 C12.965,3.952 11.95,3.391 11.67,2.419 C11.39,1.448 11.95,0.433 12.922,0.153 C13.894,-0.127 14.909,0.434 15.188,1.405" id="Fill-580" class="icon-color" mask="url(#mask-4)"/>
        <path d="M4.139,69.66 C4.139,70.803 3.213,71.729 2.07,71.729 C0.927,71.729 0,70.803 0,69.66 C0,68.517 0.927,67.59 2.07,67.59 C3.213,67.59 4.139,68.517 4.139,69.66" id="Fill-582" class="icon-color" mask="url(#mask-4)"/>
      </g>
      <path d="M2.138,151.834 C1.109,151.872 0.245,151.068 0.207,150.04 C0.169,149.011 0.973,148.146 2.001,148.109 C3.03,148.071 3.894,148.874 3.932,149.903 C3.97,150.931 3.166,151.796 2.138,151.834" id="Fill-584" class="icon-color"/>
      <path d="M2.206,146.938 C1.292,147.014 0.489,146.334 0.414,145.419 C0.338,144.505 1.019,143.702 1.933,143.627 C2.847,143.552 3.65,144.232 3.725,145.146 C3.801,146.06 3.12,146.863 2.206,146.938" id="Fill-585" class="icon-color"/>
      <path d="M2.213,142.043 C1.413,142.156 0.673,141.599 0.56,140.799 C0.447,139.998 1.003,139.258 1.804,139.145 C2.604,139.032 3.344,139.589 3.457,140.389 C3.57,141.189 3.013,141.929 2.213,142.043" id="Fill-586" class="icon-color"/>
      <path d="M2.262,137.147 C1.576,137.298 0.898,136.864 0.747,136.178 C0.597,135.493 1.03,134.814 1.716,134.663 C2.402,134.513 3.08,134.946 3.231,135.632 C3.382,136.318 2.948,136.996 2.262,137.147" id="Fill-587" class="icon-color"/>
      <path d="M2.311,132.251 C1.74,132.44 1.124,132.129 0.935,131.558 C0.747,130.987 1.057,130.37 1.629,130.182 C2.2,129.993 2.816,130.304 3.005,130.875 C3.193,131.447 2.883,132.063 2.311,132.251" id="Fill-588" class="icon-color"/>
      <path d="M2.361,127.356 C1.903,127.582 1.349,127.395 1.123,126.938 C0.897,126.48 1.084,125.926 1.541,125.7 C1.999,125.474 2.553,125.661 2.779,126.119 C3.005,126.576 2.818,127.13 2.361,127.356" id="Fill-589" class="icon-color"/>
      <path d="M2.443,122.46 C2.1,122.724 1.608,122.66 1.345,122.317 C1.081,121.974 1.145,121.483 1.487,121.219 C1.83,120.955 2.322,121.019 2.586,121.362 C2.85,121.705 2.786,122.196 2.443,122.46" id="Fill-590" class="icon-color"/>
      <path d="M2.547,117.565 C2.319,117.866 1.889,117.926 1.587,117.697 C1.286,117.468 1.227,117.039 1.455,116.737 C1.684,116.435 2.114,116.376 2.415,116.605 C2.717,116.833 2.776,117.263 2.547,117.565" id="Fill-591" class="icon-color"/>
      <path d="M2.684,112.669 C2.57,113.009 2.202,113.191 1.863,113.077 C1.523,112.962 1.341,112.595 1.455,112.256 C1.569,111.916 1.937,111.734 2.276,111.848 C2.616,111.962 2.798,112.33 2.684,112.669" id="Fill-592" class="icon-color"/>
      <g id="Group-596" transform="translate(0.000000, 107.000000)">
        <mask id="mask-6" fill="white">
          <use xlink:href="#path-5"/>
        </mask>
        <g id="Clip-594"/>
        <path d="M2.752,0.774 C2.752,1.151 2.447,1.456 2.07,1.456 C1.693,1.456 1.387,1.151 1.387,0.774 C1.387,0.397 1.693,0.091 2.07,0.091 C2.447,0.091 2.752,0.397 2.752,0.774" id="Fill-593" class="icon-color" mask="url(#mask-6)"/>
        <path d="M0,47.684 C0,46.541 0.927,45.614 2.07,45.614 C3.213,45.614 4.139,46.541 4.139,47.684 C4.139,48.827 3.213,49.753 2.07,49.753 C0.927,49.753 0,48.827 0,47.684" id="Fill-595" class="icon-color" mask="url(#mask-6)"/>
      </g>
      <path d="M2.001,158.33 C3.03,158.292 3.894,159.095 3.932,160.124 C3.969,161.153 3.166,162.017 2.137,162.055 C1.109,162.092 0.244,161.289 0.207,160.26 C0.169,159.232 0.972,158.367 2.001,158.33" id="Fill-597" class="icon-color"/>
      <path d="M1.932,164.045 C2.847,163.97 3.649,164.65 3.724,165.564 C3.8,166.478 3.12,167.281 2.205,167.356 C1.291,167.432 0.489,166.752 0.413,165.837 C0.338,164.923 1.018,164.12 1.932,164.045" id="Fill-598" class="icon-color"/>
      <path d="M1.89,175.476 C2.576,175.325 3.254,175.759 3.405,176.445 C3.556,177.131 3.122,177.809 2.436,177.959 C1.751,178.11 1.072,177.677 0.921,176.991 C0.771,176.305 1.204,175.627 1.89,175.476" id="Fill-599" class="icon-color"/>
      <path d="M1.844,181.192 C2.416,181.003 3.032,181.314 3.221,181.885 C3.409,182.457 3.098,183.073 2.527,183.261 C1.956,183.45 1.34,183.139 1.151,182.568 C0.962,181.996 1.273,181.38 1.844,181.192" id="Fill-600" class="icon-color"/>
      <path d="M1.77,186.907 C2.227,186.681 2.781,186.868 3.007,187.325 C3.234,187.782 3.046,188.336 2.589,188.563 C2.132,188.789 1.578,188.602 1.352,188.144 C1.126,187.687 1.313,187.133 1.77,186.907" id="Fill-601" class="icon-color"/>
      <path d="M1.673,192.622 C2.016,192.359 2.508,192.423 2.772,192.766 C3.036,193.108 2.972,193.6 2.629,193.864 C2.286,194.128 1.794,194.064 1.53,193.721 C1.266,193.378 1.33,192.886 1.673,192.622" id="Fill-602" class="icon-color"/>
      <path d="M1.577,198.338 C1.805,198.036 2.235,197.977 2.537,198.206 C2.838,198.435 2.897,198.864 2.669,199.166 C2.44,199.467 2.01,199.526 1.709,199.298 C1.407,199.069 1.348,198.64 1.577,198.338" id="Fill-603" class="icon-color"/>
      <path d="M1.452,204.053 C1.566,203.714 1.934,203.532 2.273,203.646 C2.612,203.76 2.795,204.128 2.681,204.467 C2.566,204.807 2.199,204.989 1.859,204.875 C1.52,204.76 1.338,204.393 1.452,204.053" id="Fill-604" class="icon-color"/>
      <path d="M1.383,209.769 C1.383,209.392 1.689,209.086 2.066,209.086 C2.443,209.086 2.748,209.392 2.748,209.769 C2.748,210.146 2.443,210.452 2.066,210.452 C1.689,210.452 1.383,210.146 1.383,209.769" id="Fill-605" class="icon-color"/>
      <path d="M1.936,169.761 C2.736,169.648 3.476,170.204 3.589,171.004 C3.702,171.805 3.146,172.545 2.346,172.658 C1.545,172.771 0.805,172.214 0.692,171.414 C0.579,170.614 1.136,169.874 1.936,169.761" id="Fill-606" class="icon-color"/>
      <path d="M22.285,129.107 C22.285,130.557 21.11,131.732 19.66,131.732 C18.21,131.732 17.035,130.557 17.035,129.107 C17.035,127.657 18.21,126.482 19.66,126.482 C21.11,126.482 22.285,127.657 22.285,129.107" id="Fill-607" class="icon-color"/>
      <path d="M19.746,125.522 C18.442,125.57 17.345,124.551 17.297,123.246 C17.249,121.941 18.268,120.845 19.573,120.797 C20.878,120.749 21.975,121.768 22.022,123.073 C22.07,124.378 21.051,125.474 19.746,125.522" id="Fill-608" class="icon-color"/>
      <path d="M19.842,113.102 C18.827,113.246 17.888,112.539 17.745,111.524 C17.601,110.51 18.308,109.57 19.323,109.427 C20.337,109.284 21.276,109.99 21.42,111.005 C21.563,112.02 20.857,112.959 19.842,113.102" id="Fill-609" class="icon-color"/>
      <path d="M19.904,106.892 C19.034,107.084 18.174,106.533 17.983,105.664 C17.792,104.794 18.342,103.933 19.212,103.742 C20.082,103.551 20.942,104.101 21.133,104.971 C21.324,105.841 20.774,106.701 19.904,106.892" id="Fill-610" class="icon-color"/>
      <path d="M19.967,100.682 C19.242,100.921 18.46,100.528 18.221,99.803 C17.982,99.078 18.376,98.296 19.101,98.057 C19.826,97.818 20.607,98.212 20.846,98.937 C21.085,99.662 20.691,100.443 19.967,100.682" id="Fill-611" class="icon-color"/>
      <path d="M20.029,94.472 C19.449,94.759 18.746,94.522 18.459,93.942 C18.172,93.362 18.41,92.659 18.99,92.372 C19.57,92.085 20.273,92.323 20.559,92.903 C20.846,93.482 20.609,94.185 20.029,94.472" id="Fill-612" class="icon-color"/>
      <path d="M20.134,88.262 C19.699,88.597 19.075,88.516 18.74,88.081 C18.405,87.646 18.487,87.022 18.922,86.687 C19.357,86.352 19.98,86.434 20.315,86.869 C20.65,87.304 20.569,87.927 20.134,88.262" id="Fill-613" class="icon-color"/>
      <path d="M20.266,82.052 C19.976,82.435 19.431,82.51 19.048,82.22 C18.666,81.93 18.591,81.385 18.881,81.002 C19.171,80.62 19.716,80.545 20.098,80.835 C20.481,81.124 20.556,81.67 20.266,82.052" id="Fill-614" class="icon-color"/>
      <path d="M20.439,75.843 C20.294,76.273 19.828,76.504 19.397,76.359 C18.967,76.214 18.735,75.748 18.881,75.318 C19.026,74.887 19.492,74.656 19.922,74.801 C20.353,74.946 20.584,75.412 20.439,75.843" id="Fill-615" class="icon-color"/>
      <path d="M20.526,69.633 C20.526,70.111 20.138,70.499 19.66,70.499 C19.182,70.499 18.794,70.111 18.794,69.633 C18.794,69.154 19.182,68.767 19.66,68.767 C20.138,68.767 20.526,69.154 20.526,69.633" id="Fill-616" class="icon-color"/>
      <path d="M19.78,119.312 C18.62,119.408 17.602,118.545 17.506,117.385 C17.411,116.225 18.274,115.208 19.433,115.112 C20.593,115.016 21.611,115.879 21.707,117.039 C21.802,118.199 20.94,119.217 19.78,119.312" id="Fill-617" class="icon-color"/>
      <path d="M17.035,129.137 C17.035,127.687 18.21,126.512 19.66,126.512 C21.11,126.512 22.285,127.687 22.285,129.137 C22.285,130.587 21.11,131.763 19.66,131.763 C18.21,131.763 17.035,130.587 17.035,129.137" id="Fill-618" class="icon-color"/>
      <path d="M19.573,132.942 C20.878,132.894 21.974,133.913 22.022,135.218 C22.07,136.523 21.051,137.62 19.746,137.668 C18.441,137.715 17.345,136.696 17.297,135.391 C17.249,134.086 18.268,132.99 19.573,132.942" id="Fill-619" class="icon-color"/>
      <path d="M19.542,139.372 C20.702,139.276 21.719,140.139 21.815,141.299 C21.911,142.459 21.048,143.477 19.888,143.572 C18.728,143.668 17.71,142.805 17.615,141.646 C17.519,140.485 18.382,139.468 19.542,139.372" id="Fill-620" class="icon-color"/>
      <path d="M19.48,145.802 C20.495,145.659 21.434,146.365 21.578,147.38 C21.721,148.395 21.015,149.334 20,149.477 C18.985,149.621 18.046,148.914 17.903,147.9 C17.759,146.885 18.466,145.946 19.48,145.802" id="Fill-621" class="icon-color"/>
      <path d="M19.419,152.232 C20.289,152.041 21.149,152.591 21.341,153.461 C21.532,154.331 20.982,155.191 20.112,155.382 C19.242,155.573 18.382,155.023 18.19,154.153 C17.999,153.284 18.549,152.423 19.419,152.232" id="Fill-622" class="icon-color"/>
      <path d="M19.358,158.662 C20.083,158.423 20.864,158.817 21.103,159.542 C21.342,160.267 20.948,161.048 20.223,161.287 C19.499,161.526 18.717,161.132 18.478,160.407 C18.239,159.683 18.633,158.901 19.358,158.662" id="Fill-623" class="icon-color"/>
      <path d="M19.296,165.092 C19.876,164.805 20.579,165.043 20.866,165.623 C21.153,166.202 20.915,166.905 20.335,167.192 C19.755,167.479 19.053,167.241 18.766,166.662 C18.479,166.082 18.716,165.379 19.296,165.092" id="Fill-624" class="icon-color"/>
      <path d="M19.178,171.522 C19.613,171.187 20.237,171.268 20.572,171.703 C20.906,172.138 20.825,172.762 20.39,173.097 C19.955,173.432 19.331,173.351 18.996,172.916 C18.662,172.481 18.743,171.857 19.178,171.522" id="Fill-625" class="icon-color"/>
      <path d="M19.048,177.952 C19.338,177.569 19.883,177.494 20.266,177.784 C20.649,178.074 20.724,178.619 20.434,179.002 C20.144,179.384 19.598,179.459 19.216,179.17 C18.833,178.879 18.758,178.334 19.048,177.952" id="Fill-626" class="icon-color"/>
      <path d="M18.876,184.382 C19.021,183.951 19.488,183.72 19.918,183.865 C20.349,184.01 20.58,184.476 20.435,184.907 C20.29,185.337 19.824,185.568 19.393,185.423 C18.963,185.278 18.731,184.812 18.876,184.382" id="Fill-627" class="icon-color"/>
      <path d="M18.789,190.812 C18.789,190.333 19.177,189.946 19.655,189.946 C20.133,189.946 20.521,190.333 20.521,190.812 C20.521,191.29 20.133,191.678 19.655,191.678 C19.177,191.678 18.789,191.29 18.789,190.812" id="Fill-628" class="icon-color"/>
      <path d="M139.802,154.189 C139.802,154.987 139.156,155.633 138.358,155.633 C137.561,155.633 136.914,154.987 136.914,154.189 C136.914,153.391 137.561,152.745 138.358,152.745 C139.156,152.745 139.802,153.391 139.802,154.189" id="Fill-629" class="icon-color"/>
      <path d="M138.108,149.505 C137.39,149.366 136.92,148.672 137.058,147.954 C137.197,147.236 137.891,146.767 138.609,146.905 C139.327,147.043 139.796,147.737 139.658,148.455 C139.52,149.173 138.826,149.643 138.108,149.505" id="Fill-630" class="icon-color"/>
      <path d="M137.857,143.376 C137.219,143.099 136.926,142.358 137.203,141.72 C137.48,141.082 138.221,140.789 138.859,141.066 C139.497,141.342 139.79,142.084 139.514,142.722 C139.237,143.36 138.495,143.653 137.857,143.376" id="Fill-631" class="icon-color"/>
      <path d="M137.607,137.248 C137.048,136.832 136.932,136.043 137.347,135.485 C137.762,134.927 138.551,134.811 139.11,135.226 C139.668,135.641 139.784,136.43 139.369,136.988 C138.954,137.547 138.165,137.663 137.607,137.248" id="Fill-632" class="icon-color"/>
      <path d="M137.356,131.119 C136.878,130.566 136.938,129.729 137.492,129.251 C138.045,128.772 138.882,128.833 139.36,129.386 C139.839,129.94 139.778,130.776 139.225,131.255 C138.671,131.734 137.835,131.673 137.356,131.119" id="Fill-633" class="icon-color"/>
      <path d="M137.105,124.991 C136.707,124.299 136.944,123.415 137.636,123.016 C138.328,122.617 139.212,122.855 139.611,123.547 C140.01,124.238 139.772,125.123 139.08,125.522 C138.389,125.92 137.504,125.683 137.105,124.991" id="Fill-634" class="icon-color"/>
      <path d="M136.855,118.862 C136.536,118.032 136.95,117.1 137.781,116.781 C138.611,116.462 139.542,116.877 139.861,117.707 C140.18,118.537 139.766,119.469 138.936,119.788 C138.106,120.107 137.174,119.693 136.855,118.862" id="Fill-635" class="icon-color"/>
      <path d="M136.604,112.734 C136.365,111.765 136.956,110.786 137.925,110.547 C138.894,110.308 139.873,110.899 140.112,111.867 C140.351,112.836 139.76,113.815 138.791,114.054 C137.823,114.294 136.844,113.703 136.604,112.734" id="Fill-636" class="icon-color"/>
      <path d="M136.354,106.606 C136.194,105.498 136.962,104.472 138.069,104.312 C139.176,104.153 140.203,104.921 140.363,106.028 C140.522,107.135 139.754,108.162 138.647,108.321 C137.54,108.481 136.513,107.712 136.354,106.606" id="Fill-637" class="icon-color"/>
      <path d="M136.103,100.477 C136.023,99.232 136.969,98.157 138.214,98.078 C139.459,97.998 140.533,98.943 140.613,100.188 C140.693,101.434 139.748,102.508 138.503,102.587 C137.257,102.667 136.183,101.722 136.103,100.477" id="Fill-638" class="icon-color"/>
      <path d="M140.864,94.349 C140.864,95.732 139.742,96.854 138.358,96.854 C136.975,96.854 135.853,95.732 135.853,94.349 C135.853,92.965 136.975,91.843 138.358,91.843 C139.742,91.843 140.864,92.965 140.864,94.349" id="Fill-639" class="icon-color"/>
      <path d="M136.643,82.38 C136.643,81.433 137.411,80.665 138.358,80.665 C139.306,80.665 140.074,81.433 140.074,82.38 C140.074,83.328 139.306,84.096 138.358,84.096 C137.411,84.096 136.643,83.328 136.643,82.38" id="Fill-640" class="icon-color"/>
      <path d="M137.038,76.397 C137.038,75.667 137.629,75.076 138.358,75.076 C139.088,75.076 139.679,75.667 139.679,76.397 C139.679,77.126 139.088,77.717 138.358,77.717 C137.629,77.717 137.038,77.126 137.038,76.397" id="Fill-641" class="icon-color"/>
      <path d="M137.433,70.412 C137.433,69.901 137.847,69.487 138.358,69.487 C138.869,69.487 139.284,69.901 139.284,70.412 C139.284,70.924 138.869,71.338 138.358,71.338 C137.847,71.338 137.433,70.924 137.433,70.412" id="Fill-642" class="icon-color"/>
      <path d="M137.828,64.428 C137.828,64.135 138.065,63.898 138.358,63.898 C138.651,63.898 138.889,64.135 138.889,64.428 C138.889,64.722 138.651,64.959 138.358,64.959 C138.065,64.959 137.828,64.722 137.828,64.428" id="Fill-643" class="icon-color"/>
      <path d="M138.223,58.444 C138.223,58.369 138.283,58.309 138.358,58.309 C138.433,58.309 138.494,58.369 138.494,58.444 C138.494,58.52 138.433,58.58 138.358,58.58 C138.283,58.58 138.223,58.52 138.223,58.444" id="Fill-644" class="icon-color"/>
      <path d="M138.617,52.46 C138.617,52.604 138.501,52.72 138.358,52.72 C138.215,52.72 138.099,52.604 138.099,52.46 C138.099,52.317 138.215,52.201 138.358,52.201 C138.501,52.201 138.617,52.317 138.617,52.46" id="Fill-645" class="icon-color"/>
      <path d="M139.012,46.476 C139.012,46.838 138.719,47.131 138.358,47.131 C137.997,47.131 137.704,46.838 137.704,46.476 C137.704,46.115 137.997,45.822 138.358,45.822 C138.719,45.822 139.012,46.115 139.012,46.476" id="Fill-646" class="icon-color"/>
      <path d="M139.407,40.492 C139.407,41.072 138.938,41.542 138.358,41.542 C137.779,41.542 137.309,41.072 137.309,40.492 C137.309,39.913 137.779,39.443 138.358,39.443 C138.938,39.443 139.407,39.913 139.407,40.492" id="Fill-647" class="icon-color"/>
      <path d="M139.802,34.508 C139.802,35.306 139.156,35.953 138.358,35.953 C137.561,35.953 136.914,35.306 136.914,34.508 C136.914,33.711 137.561,33.064 138.358,33.064 C139.156,33.064 139.802,33.711 139.802,34.508" id="Fill-648" class="icon-color"/>
      <path d="M136.248,88.365 C136.248,87.199 137.193,86.254 138.358,86.254 C139.524,86.254 140.469,87.199 140.469,88.365 C140.469,89.53 139.524,90.475 138.358,90.475 C137.193,90.475 136.248,89.53 136.248,88.365" id="Fill-649" class="icon-color"/>
      <path d="M130.839,57.714 C130.839,56.874 131.52,56.193 132.36,56.193 C133.2,56.193 133.881,56.874 133.881,57.714 C133.881,58.555 133.2,59.236 132.36,59.236 C131.52,59.236 130.839,58.555 130.839,57.714" id="Fill-650" class="icon-color"/>
      <path d="M132.653,62.587 C133.4,62.749 133.874,63.485 133.712,64.232 C133.55,64.979 132.813,65.453 132.067,65.291 C131.32,65.129 130.846,64.392 131.008,63.646 C131.17,62.899 131.906,62.425 132.653,62.587" id="Fill-651" class="icon-color"/>
      <path d="M132.946,68.98 C133.6,69.304 133.867,70.096 133.543,70.75 C133.219,71.403 132.427,71.67 131.773,71.347 C131.12,71.023 130.853,70.23 131.177,69.577 C131.501,68.923 132.293,68.656 132.946,68.98" id="Fill-652" class="icon-color"/>
      <path d="M133.24,75.374 C133.8,75.86 133.86,76.707 133.374,77.267 C132.888,77.828 132.04,77.888 131.48,77.402 C130.92,76.916 130.86,76.068 131.346,75.508 C131.832,74.948 132.68,74.888 133.24,75.374" id="Fill-653" class="icon-color"/>
      <path d="M133.533,81.767 C133.999,82.415 133.853,83.318 133.205,83.785 C132.557,84.252 131.654,84.105 131.187,83.457 C130.72,82.809 130.867,81.906 131.515,81.439 C132.163,80.973 133.066,81.119 133.533,81.767" id="Fill-654" class="icon-color"/>
      <path d="M133.826,88.161 C134.199,88.97 133.846,89.929 133.036,90.303 C132.226,90.676 131.267,90.322 130.894,89.513 C130.52,88.703 130.874,87.744 131.684,87.371 C132.493,86.997 133.453,87.351 133.826,88.161" id="Fill-655" class="icon-color"/>
      <path d="M134.119,94.554 C134.399,95.526 133.839,96.54 132.867,96.82 C131.895,97.1 130.88,96.54 130.6,95.568 C130.32,94.596 130.881,93.582 131.853,93.302 C132.824,93.022 133.839,93.582 134.119,94.554" id="Fill-656" class="icon-color"/>
      <path d="M134.413,100.947 C134.599,102.081 133.831,103.151 132.698,103.338 C131.564,103.525 130.494,102.757 130.307,101.624 C130.12,100.49 130.888,99.42 132.022,99.233 C133.155,99.046 134.226,99.814 134.413,100.947" id="Fill-657" class="icon-color"/>
      <path d="M129.721,113.734 C129.721,112.277 130.902,111.095 132.36,111.095 C133.817,111.095 134.999,112.277 134.999,113.734 C134.999,115.192 133.817,116.373 132.36,116.373 C130.902,116.373 129.721,115.192 129.721,113.734" id="Fill-658" class="icon-color"/>
      <path d="M134.537,120.738 C134.537,121.94 133.562,122.915 132.36,122.915 C131.158,122.915 130.183,121.94 130.183,120.738 C130.183,119.536 131.158,118.561 132.36,118.561 C133.562,118.561 134.537,119.536 134.537,120.738" id="Fill-659" class="icon-color"/>
      <path d="M134.074,127.741 C134.074,128.688 133.307,129.456 132.36,129.456 C131.413,129.456 130.645,128.688 130.645,127.741 C130.645,126.794 131.413,126.027 132.36,126.027 C133.307,126.027 134.074,126.794 134.074,127.741" id="Fill-660" class="icon-color"/>
      <path d="M133.612,134.745 C133.612,135.436 133.051,135.997 132.36,135.997 C131.668,135.997 131.108,135.436 131.108,134.745 C131.108,134.053 131.668,133.493 132.36,133.493 C133.051,133.493 133.612,134.053 133.612,134.745" id="Fill-661" class="icon-color"/>
      <path d="M133.15,141.748 C133.15,142.185 132.796,142.538 132.36,142.538 C131.923,142.538 131.57,142.185 131.57,141.748 C131.57,141.312 131.923,140.958 132.36,140.958 C132.796,140.958 133.15,141.312 133.15,141.748" id="Fill-662" class="icon-color"/>
      <path d="M132.688,148.752 C132.688,148.933 132.541,149.08 132.36,149.08 C132.179,149.08 132.032,148.933 132.032,148.752 C132.032,148.571 132.179,148.424 132.36,148.424 C132.541,148.424 132.688,148.571 132.688,148.752" id="Fill-663" class="icon-color"/>
      <path d="M132.225,155.755 C132.225,155.681 132.286,155.621 132.36,155.621 C132.434,155.621 132.494,155.681 132.494,155.755 C132.494,155.829 132.434,155.89 132.36,155.89 C132.286,155.89 132.225,155.829 132.225,155.755" id="Fill-664" class="icon-color"/>
      <path d="M131.763,162.759 C131.763,162.429 132.03,162.162 132.36,162.162 C132.689,162.162 132.957,162.429 132.957,162.759 C132.957,163.088 132.689,163.355 132.36,163.355 C132.03,163.355 131.763,163.088 131.763,162.759" id="Fill-665" class="icon-color"/>
      <path d="M131.301,169.762 C131.301,169.177 131.775,168.703 132.36,168.703 C132.945,168.703 133.419,169.177 133.419,169.762 C133.419,170.347 132.945,170.821 132.36,170.821 C131.775,170.821 131.301,170.347 131.301,169.762" id="Fill-666" class="icon-color"/>
      <path d="M130.839,176.766 C130.839,175.926 131.52,175.244 132.36,175.244 C133.2,175.244 133.881,175.926 133.881,176.766 C133.881,177.606 133.2,178.287 132.36,178.287 C131.52,178.287 130.839,177.606 130.839,176.766" id="Fill-667" class="icon-color"/>
      <path d="M134.706,107.341 C134.799,108.637 133.824,109.762 132.529,109.856 C131.233,109.949 130.107,108.974 130.014,107.679 C129.921,106.383 130.895,105.258 132.191,105.164 C133.486,105.071 134.612,106.045 134.706,107.341" id="Fill-668" class="icon-color"/>
      <path d="M126.616,128.538 C125.311,128.586 124.214,127.567 124.166,126.262 C124.119,124.957 125.138,123.86 126.443,123.813 C127.747,123.765 128.844,124.784 128.892,126.089 C128.94,127.393 127.921,128.49 126.616,128.538" id="Fill-669" class="icon-color"/>
      <path d="M126.649,122.328 C125.489,122.424 124.471,121.561 124.376,120.401 C124.28,119.241 125.143,118.223 126.303,118.127 C127.463,118.032 128.48,118.895 128.576,120.054 C128.672,121.214 127.809,122.232 126.649,122.328" id="Fill-670" class="icon-color"/>
      <path d="M126.711,116.118 C125.697,116.261 124.757,115.555 124.614,114.54 C124.47,113.525 125.177,112.586 126.192,112.443 C127.207,112.299 128.146,113.006 128.289,114.02 C128.433,115.035 127.726,115.974 126.711,116.118" id="Fill-671" class="icon-color"/>
      <path d="M126.773,109.908 C125.904,110.099 125.044,109.549 124.852,108.679 C124.661,107.809 125.211,106.949 126.081,106.758 C126.951,106.566 127.811,107.117 128.002,107.986 C128.194,108.856 127.644,109.717 126.773,109.908" id="Fill-672" class="icon-color"/>
      <path d="M126.836,103.698 C126.111,103.937 125.33,103.543 125.09,102.818 C124.851,102.093 125.245,101.312 125.97,101.073 C126.695,100.834 127.477,101.227 127.716,101.952 C127.955,102.677 127.561,103.459 126.836,103.698" id="Fill-673" class="icon-color"/>
      <path d="M126.898,97.488 C126.318,97.775 125.616,97.537 125.329,96.957 C125.042,96.377 125.279,95.675 125.859,95.388 C126.439,95.101 127.142,95.338 127.429,95.918 C127.716,96.498 127.478,97.201 126.898,97.488" id="Fill-674" class="icon-color"/>
      <path d="M127.003,91.278 C126.568,91.613 125.944,91.531 125.61,91.096 C125.275,90.662 125.356,90.038 125.791,89.703 C126.226,89.368 126.85,89.449 127.185,89.884 C127.519,90.319 127.438,90.943 127.003,91.278" id="Fill-675" class="icon-color"/>
      <path d="M127.135,85.068 C126.845,85.451 126.3,85.526 125.918,85.236 C125.535,84.946 125.46,84.401 125.75,84.018 C126.04,83.635 126.585,83.56 126.968,83.85 C127.35,84.14 127.425,84.685 127.135,85.068" id="Fill-676" class="icon-color"/>
      <path d="M127.309,78.858 C127.163,79.289 126.697,79.52 126.267,79.375 C125.836,79.23 125.605,78.764 125.75,78.333 C125.895,77.903 126.361,77.671 126.792,77.816 C127.222,77.961 127.453,78.428 127.309,78.858" id="Fill-677" class="icon-color"/>
      <path d="M127.395,72.648 C127.395,73.126 127.007,73.514 126.529,73.514 C126.051,73.514 125.663,73.126 125.663,72.648 C125.663,72.17 126.051,71.782 126.529,71.782 C127.007,71.782 127.395,72.17 127.395,72.648" id="Fill-678" class="icon-color"/>
      <path d="M129.154,132.123 C129.154,133.573 127.979,134.748 126.529,134.748 C125.079,134.748 123.904,133.573 123.904,132.123 C123.904,130.673 125.079,129.497 126.529,129.497 C127.979,129.497 129.154,130.673 129.154,132.123" id="Fill-679" class="icon-color"/>
      <path d="M26.829,97.338 C26.829,97.778 26.472,98.135 26.032,98.135 C25.592,98.135 25.235,97.778 25.235,97.338 C25.235,96.898 25.592,96.541 26.032,96.541 C26.472,96.541 26.829,96.898 26.829,97.338" id="Fill-680" class="icon-color"/>
      <path d="M27.076,103.936 C27.076,104.512 26.608,104.98 26.032,104.98 C25.455,104.98 24.988,104.512 24.988,103.936 C24.988,103.359 25.455,102.892 26.032,102.892 C26.608,102.892 27.076,103.359 27.076,103.936" id="Fill-681" class="icon-color"/>
      <path d="M27.323,110.534 C27.323,111.247 26.745,111.825 26.032,111.825 C25.319,111.825 24.741,111.247 24.741,110.534 C24.741,109.821 25.319,109.243 26.032,109.243 C26.745,109.243 27.323,109.821 27.323,110.534" id="Fill-682" class="icon-color"/>
      <path d="M27.57,117.132 C27.57,117.981 26.881,118.67 26.032,118.67 C25.182,118.67 24.494,117.981 24.494,117.132 C24.494,116.282 25.182,115.594 26.032,115.594 C26.881,115.594 27.57,116.282 27.57,117.132" id="Fill-683" class="icon-color"/>
      <path d="M27.817,123.73 C27.817,124.716 27.018,125.515 26.032,125.515 C25.046,125.515 24.247,124.716 24.247,123.73 C24.247,122.744 25.046,121.945 26.032,121.945 C27.018,121.945 27.817,122.744 27.817,123.73" id="Fill-684" class="icon-color"/>
      <path d="M28.064,130.328 C28.064,131.45 27.154,132.36 26.032,132.36 C24.91,132.36 24,131.45 24,130.328 C24,129.206 24.91,128.296 26.032,128.296 C27.154,128.296 28.064,129.206 28.064,130.328" id="Fill-685" class="icon-color"/>
      <path d="M28.311,136.926 C28.311,138.185 27.29,139.205 26.032,139.205 C24.773,139.205 23.753,138.185 23.753,136.926 C23.753,135.667 24.773,134.647 26.032,134.647 C27.29,134.647 28.311,135.667 28.311,136.926" id="Fill-686" class="icon-color"/>
      <path d="M28.805,150.122 C28.805,151.653 27.563,152.895 26.032,152.895 C24.5,152.895 23.259,151.653 23.259,150.122 C23.259,148.591 24.5,147.349 26.032,147.349 C27.563,147.349 28.805,148.591 28.805,150.122" id="Fill-687" class="icon-color"/>
      <path d="M28.558,143.524 C28.558,144.919 27.427,146.05 26.032,146.05 C24.637,146.05 23.506,144.919 23.506,143.524 C23.506,142.129 24.637,140.998 26.032,140.998 C27.427,140.998 28.558,142.129 28.558,143.524" id="Fill-688" class="icon-color"/>
      <path d="M146.117,160.316 C146.117,161.213 145.39,161.941 144.492,161.941 C143.595,161.941 142.867,161.213 142.867,160.316 C142.867,159.419 143.595,158.691 144.492,158.691 C145.39,158.691 146.117,159.419 146.117,160.316" id="Fill-689" class="icon-color"/>
      <path d="M144.21,155.045 C143.403,154.89 142.874,154.109 143.03,153.301 C143.186,152.493 143.967,151.965 144.774,152.12 C145.582,152.276 146.11,153.057 145.955,153.865 C145.799,154.673 145.018,155.201 144.21,155.045" id="Fill-690" class="icon-color"/>
      <path d="M143.929,148.15 C143.211,147.839 142.881,147.004 143.192,146.286 C143.504,145.568 144.338,145.239 145.056,145.55 C145.774,145.862 146.104,146.696 145.792,147.414 C145.481,148.132 144.646,148.461 143.929,148.15" id="Fill-691" class="icon-color"/>
      <path d="M143.647,141.255 C143.018,140.788 142.888,139.9 143.355,139.271 C143.822,138.643 144.71,138.513 145.338,138.98 C145.966,139.447 146.097,140.335 145.63,140.963 C145.163,141.591 144.275,141.722 143.647,141.255" id="Fill-692" class="icon-color"/>
      <path d="M143.365,134.359 C142.826,133.736 142.895,132.795 143.517,132.257 C144.14,131.718 145.081,131.786 145.62,132.409 C146.158,133.032 146.09,133.974 145.467,134.512 C144.844,135.05 143.903,134.982 143.365,134.359" id="Fill-693" class="icon-color"/>
      <path d="M143.083,127.464 C142.634,126.685 142.902,125.691 143.68,125.242 C144.458,124.793 145.453,125.06 145.902,125.839 C146.35,126.617 146.083,127.612 145.305,128.061 C144.526,128.509 143.532,128.242 143.083,127.464" id="Fill-694" class="icon-color"/>
      <path d="M142.801,120.568 C142.442,119.634 142.908,118.586 143.842,118.227 C144.777,117.868 145.825,118.334 146.184,119.269 C146.543,120.203 146.076,121.251 145.142,121.61 C144.208,121.969 143.16,121.502 142.801,120.568" id="Fill-695" class="icon-color"/>
      <path d="M142.519,113.673 C142.25,112.583 142.915,111.482 144.005,111.212 C145.095,110.943 146.196,111.608 146.466,112.698 C146.735,113.788 146.07,114.889 144.98,115.159 C143.89,115.428 142.788,114.763 142.519,113.673" id="Fill-696" class="icon-color"/>
      <path d="M142.237,106.778 C142.058,105.532 142.922,104.377 144.167,104.197 C145.413,104.018 146.568,104.882 146.748,106.128 C146.927,107.373 146.063,108.528 144.817,108.708 C143.572,108.887 142.417,108.023 142.237,106.778" id="Fill-697" class="icon-color"/>
      <path d="M147.311,92.987 C147.311,94.544 146.049,95.806 144.492,95.806 C142.935,95.806 141.673,94.544 141.673,92.987 C141.673,91.43 142.935,90.168 144.492,90.168 C146.049,90.168 147.311,91.43 147.311,92.987" id="Fill-698" class="icon-color"/>
      <path d="M142.118,86.254 C142.118,84.942 143.181,83.879 144.492,83.879 C145.804,83.879 146.867,84.942 146.867,86.254 C146.867,87.565 145.804,88.628 144.492,88.628 C143.181,88.628 142.118,87.565 142.118,86.254" id="Fill-699" class="icon-color"/>
      <path d="M142.562,79.521 C142.562,78.455 143.426,77.591 144.492,77.591 C145.558,77.591 146.423,78.455 146.423,79.521 C146.423,80.587 145.558,81.451 144.492,81.451 C143.426,81.451 142.562,80.587 142.562,79.521" id="Fill-700" class="icon-color"/>
      <path d="M143.007,72.788 C143.007,71.967 143.672,71.302 144.492,71.302 C145.313,71.302 145.978,71.967 145.978,72.788 C145.978,73.609 145.313,74.274 144.492,74.274 C143.672,74.274 143.007,73.609 143.007,72.788" id="Fill-701" class="icon-color"/>
      <path d="M143.451,66.055 C143.451,65.48 143.917,65.014 144.492,65.014 C145.068,65.014 145.534,65.48 145.534,66.055 C145.534,66.63 145.068,67.096 144.492,67.096 C143.917,67.096 143.451,66.63 143.451,66.055" id="Fill-702" class="icon-color"/>
      <path d="M143.895,59.322 C143.895,58.992 144.163,58.725 144.492,58.725 C144.822,58.725 145.089,58.992 145.089,59.322 C145.089,59.652 144.822,59.919 144.492,59.919 C144.163,59.919 143.895,59.652 143.895,59.322" id="Fill-703" class="icon-color"/>
      <path d="M144.34,52.589 C144.34,52.505 144.408,52.437 144.492,52.437 C144.577,52.437 144.645,52.505 144.645,52.589 C144.645,52.673 144.577,52.742 144.492,52.742 C144.408,52.742 144.34,52.673 144.34,52.589" id="Fill-704" class="icon-color"/>
      <path d="M144.784,45.856 C144.784,46.017 144.654,46.148 144.492,46.148 C144.331,46.148 144.201,46.017 144.201,45.856 C144.201,45.695 144.331,45.565 144.492,45.565 C144.654,45.565 144.784,45.695 144.784,45.856" id="Fill-705" class="icon-color"/>
      <path d="M145.229,39.123 C145.229,39.53 144.899,39.859 144.492,39.859 C144.086,39.859 143.756,39.53 143.756,39.123 C143.756,38.717 144.086,38.387 144.492,38.387 C144.899,38.387 145.229,38.717 145.229,39.123" id="Fill-706" class="icon-color"/>
      <path d="M145.673,32.391 C145.673,33.042 145.144,33.571 144.492,33.571 C143.84,33.571 143.312,33.042 143.312,32.391 C143.312,31.738 143.84,31.21 144.492,31.21 C145.144,31.21 145.673,31.738 145.673,32.391" id="Fill-707" class="icon-color"/>
      <path d="M146.117,25.658 C146.117,26.555 145.39,27.282 144.492,27.282 C143.595,27.282 142.867,26.555 142.867,25.658 C142.867,24.76 143.595,24.033 144.492,24.033 C145.39,24.033 146.117,24.76 146.117,25.658" id="Fill-708" class="icon-color"/>
      <path d="M141.955,99.882 C141.865,98.481 142.929,97.272 144.33,97.182 C145.731,97.093 146.94,98.156 147.029,99.557 C147.119,100.958 146.056,102.167 144.655,102.257 C143.254,102.346 142.045,101.283 141.955,99.882" id="Fill-709" class="icon-color"/>
      <path d="M149.384,74.992 C149.384,74.13 150.083,73.431 150.945,73.431 C151.806,73.431 152.505,74.13 152.505,74.992 C152.505,75.854 151.806,76.552 150.945,76.552 C150.083,76.552 149.384,75.854 149.384,74.992" id="Fill-710" class="icon-color"/>
      <path d="M151.215,80.053 C151.991,80.203 152.498,80.953 152.349,81.729 C152.199,82.504 151.449,83.012 150.674,82.862 C149.898,82.713 149.391,81.963 149.54,81.187 C149.69,80.411 150.44,79.904 151.215,80.053" id="Fill-711" class="icon-color"/>
      <path d="M151.486,86.675 C152.175,86.974 152.492,87.775 152.193,88.465 C151.894,89.154 151.092,89.471 150.403,89.172 C149.714,88.873 149.397,88.071 149.696,87.382 C149.995,86.693 150.796,86.376 151.486,86.675" id="Fill-712" class="icon-color"/>
      <path d="M151.757,93.297 C152.36,93.746 152.485,94.598 152.037,95.202 C151.588,95.805 150.736,95.93 150.132,95.482 C149.529,95.033 149.404,94.18 149.852,93.577 C150.301,92.974 151.154,92.848 151.757,93.297" id="Fill-713" class="icon-color"/>
      <path d="M152.027,99.919 C152.544,100.517 152.479,101.421 151.881,101.938 C151.283,102.455 150.379,102.389 149.862,101.791 C149.345,101.193 149.41,100.289 150.008,99.772 C150.606,99.255 151.51,99.321 152.027,99.919" id="Fill-714" class="icon-color"/>
      <path d="M152.298,106.541 C152.729,107.288 152.472,108.244 151.725,108.675 C150.977,109.106 150.022,108.849 149.591,108.101 C149.16,107.354 149.417,106.398 150.164,105.968 C150.912,105.537 151.867,105.793 152.298,106.541" id="Fill-715" class="icon-color"/>
      <path d="M152.569,113.163 C152.914,114.06 152.466,115.066 151.569,115.411 C150.672,115.756 149.665,115.308 149.32,114.411 C148.975,113.514 149.423,112.507 150.32,112.163 C151.217,111.818 152.224,112.266 152.569,113.163" id="Fill-716" class="icon-color"/>
      <path d="M152.839,119.785 C153.098,120.831 152.459,121.889 151.413,122.148 C150.366,122.406 149.308,121.767 149.049,120.721 C148.791,119.674 149.43,118.616 150.476,118.358 C151.523,118.099 152.581,118.738 152.839,119.785" id="Fill-717" class="icon-color"/>
      <path d="M153.11,126.406 C153.283,127.602 152.453,128.712 151.257,128.884 C150.061,129.057 148.951,128.227 148.779,127.031 C148.606,125.834 149.436,124.725 150.632,124.553 C151.829,124.38 152.938,125.21 153.11,126.406" id="Fill-718" class="icon-color"/>
      <path d="M148.237,139.65 C148.237,138.155 149.449,136.943 150.945,136.943 C152.44,136.943 153.652,138.155 153.652,139.65 C153.652,141.145 152.44,142.357 150.945,142.357 C149.449,142.357 148.237,141.145 148.237,139.65" id="Fill-719" class="icon-color"/>
      <path d="M153.225,147.164 C153.225,148.424 152.204,149.444 150.945,149.444 C149.685,149.444 148.664,148.424 148.664,147.164 C148.664,145.905 149.685,144.884 150.945,144.884 C152.204,144.884 153.225,145.905 153.225,147.164" id="Fill-720" class="icon-color"/>
      <path d="M152.798,154.678 C152.798,155.702 151.968,156.532 150.945,156.532 C149.921,156.532 149.091,155.702 149.091,154.678 C149.091,153.654 149.921,152.825 150.945,152.825 C151.968,152.825 152.798,153.654 152.798,154.678" id="Fill-721" class="icon-color"/>
      <path d="M152.371,162.192 C152.371,162.98 151.733,163.619 150.945,163.619 C150.156,163.619 149.518,162.98 149.518,162.192 C149.518,161.404 150.156,160.765 150.945,160.765 C151.733,160.765 152.371,161.404 152.371,162.192" id="Fill-722" class="icon-color"/>
      <path d="M151.945,169.706 C151.945,170.259 151.497,170.707 150.945,170.707 C150.392,170.707 149.945,170.259 149.945,169.706 C149.945,169.154 150.392,168.706 150.945,168.706 C151.497,168.706 151.945,169.154 151.945,169.706" id="Fill-723" class="icon-color"/>
      <path d="M151.518,177.22 C151.518,177.537 151.261,177.794 150.945,177.794 C150.628,177.794 150.371,177.537 150.371,177.22 C150.371,176.904 150.628,176.647 150.945,176.647 C151.261,176.647 151.518,176.904 151.518,177.22" id="Fill-724" class="icon-color"/>
      <path d="M151.091,184.735 C151.091,184.815 151.025,184.881 150.945,184.881 C150.863,184.881 150.798,184.815 150.798,184.735 C150.798,184.653 150.863,184.588 150.945,184.588 C151.025,184.588 151.091,184.653 151.091,184.735" id="Fill-725" class="icon-color"/>
      <path d="M150.664,192.248 C150.664,192.094 150.79,191.968 150.945,191.968 C151.099,191.968 151.225,192.094 151.225,192.248 C151.225,192.403 151.099,192.529 150.945,192.529 C150.79,192.529 150.664,192.403 150.664,192.248" id="Fill-726" class="icon-color"/>
      <path d="M150.238,199.763 C150.238,199.372 150.554,199.056 150.945,199.056 C151.335,199.056 151.651,199.372 151.651,199.763 C151.651,200.153 151.335,200.469 150.945,200.469 C150.554,200.469 150.238,200.153 150.238,199.763" id="Fill-727" class="icon-color"/>
      <path d="M149.811,207.277 C149.811,206.651 150.318,206.143 150.945,206.143 C151.571,206.143 152.078,206.651 152.078,207.277 C152.078,207.903 151.571,208.41 150.945,208.41 C150.318,208.41 149.811,207.903 149.811,207.277" id="Fill-728" class="icon-color"/>
      <path d="M149.384,214.791 C149.384,213.929 150.083,213.23 150.945,213.23 C151.806,213.23 152.505,213.929 152.505,214.791 C152.505,215.653 151.806,216.351 150.945,216.351 C150.083,216.351 149.384,215.653 149.384,214.791" id="Fill-729" class="icon-color"/>
      <path d="M153.381,133.028 C153.467,134.374 152.446,135.535 151.1,135.621 C149.755,135.707 148.594,134.686 148.508,133.34 C148.422,131.995 149.443,130.834 150.788,130.748 C152.134,130.662 153.295,131.683 153.381,133.028" id="Fill-730" class="icon-color"/>
      <path d="M159.77,130.647 C159.77,131.97 158.697,133.043 157.373,133.043 C156.05,133.043 154.977,131.97 154.977,130.647 C154.977,129.323 156.05,128.25 157.373,128.25 C158.697,128.25 159.77,129.323 159.77,130.647" id="Fill-731" class="icon-color"/>
      <path d="M157.439,126.735 C156.226,126.771 155.213,125.817 155.176,124.604 C155.14,123.39 156.094,122.377 157.307,122.341 C158.521,122.305 159.534,123.259 159.57,124.472 C159.607,125.685 158.652,126.698 157.439,126.735" id="Fill-732" class="icon-color"/>
      <path d="M157.451,120.426 C156.348,120.499 155.395,119.664 155.322,118.56 C155.249,117.458 156.085,116.504 157.188,116.432 C158.29,116.359 159.243,117.194 159.316,118.297 C159.389,119.4 158.554,120.353 157.451,120.426" id="Fill-733" class="icon-color"/>
      <path d="M157.492,114.117 C156.499,114.226 155.606,113.51 155.497,112.517 C155.388,111.525 156.104,110.632 157.097,110.523 C158.089,110.413 158.982,111.13 159.092,112.122 C159.201,113.115 158.484,114.008 157.492,114.117" id="Fill-734" class="icon-color"/>
      <path d="M157.533,107.808 C156.65,107.954 155.817,107.357 155.671,106.474 C155.526,105.592 156.123,104.759 157.006,104.613 C157.888,104.468 158.721,105.065 158.867,105.947 C159.012,106.83 158.415,107.663 157.533,107.808" id="Fill-735" class="icon-color"/>
      <path d="M157.574,101.5 C156.801,101.682 156.028,101.203 155.846,100.431 C155.665,99.659 156.143,98.886 156.915,98.704 C157.687,98.522 158.46,99 158.642,99.773 C158.824,100.545 158.346,101.318 157.574,101.5" id="Fill-736" class="icon-color"/>
      <path d="M157.63,95.191 C156.968,95.41 156.255,95.05 156.036,94.388 C155.818,93.726 156.178,93.013 156.84,92.795 C157.501,92.576 158.215,92.936 158.433,93.598 C158.651,94.259 158.292,94.973 157.63,95.191" id="Fill-737" class="icon-color"/>
      <path d="M157.719,88.882 C157.167,89.137 156.514,88.897 156.259,88.345 C156.005,87.793 156.245,87.14 156.797,86.885 C157.348,86.631 158.002,86.871 158.256,87.423 C158.511,87.974 158.27,88.628 157.719,88.882" id="Fill-738" class="icon-color"/>
      <path d="M157.808,82.574 C157.367,82.865 156.773,82.743 156.482,82.302 C156.191,81.861 156.313,81.267 156.754,80.976 C157.195,80.685 157.789,80.807 158.08,81.248 C158.371,81.689 158.249,82.283 157.808,82.574" id="Fill-739" class="icon-color"/>
      <path d="M157.897,76.265 C157.566,76.593 157.032,76.59 156.705,76.259 C156.378,75.928 156.38,75.394 156.711,75.067 C157.042,74.739 157.576,74.742 157.903,75.073 C158.231,75.404 158.228,75.938 157.897,76.265" id="Fill-740" class="icon-color"/>
      <path d="M158.032,69.956 C157.811,70.32 157.338,70.436 156.974,70.216 C156.61,69.995 156.494,69.521 156.715,69.158 C156.935,68.794 157.409,68.678 157.773,68.898 C158.137,69.119 158.253,69.592 158.032,69.956" id="Fill-741" class="icon-color"/>
      <path d="M158.098,63.648 C157.988,64.048 157.574,64.283 157.174,64.173 C156.773,64.062 156.538,63.648 156.649,63.248 C156.759,62.848 157.173,62.613 157.573,62.723 C157.973,62.834 158.208,63.247 158.098,63.648" id="Fill-742" class="icon-color"/>
      <path d="M158.164,57.339 C158.164,57.776 157.81,58.129 157.373,58.129 C156.937,58.129 156.583,57.776 156.583,57.339 C156.583,56.902 156.937,56.548 157.373,56.548 C157.81,56.548 158.164,56.902 158.164,57.339" id="Fill-743" class="icon-color"/>
      <path d="M43.478,41.924 C43.478,41.063 44.176,40.364 45.038,40.364 C45.9,40.364 46.598,41.063 46.598,41.924 C46.598,42.786 45.9,43.485 45.038,43.485 C44.176,43.485 43.478,42.786 43.478,41.924" id="Fill-744" class="icon-color"/>
      <path d="M45.309,46.986 C46.085,47.135 46.592,47.885 46.442,48.661 C46.293,49.437 45.543,49.944 44.767,49.795 C43.992,49.645 43.484,48.895 43.634,48.12 C43.783,47.344 44.533,46.836 45.309,46.986" id="Fill-745" class="icon-color"/>
      <path d="M45.579,53.608 C46.269,53.907 46.586,54.708 46.286,55.398 C45.988,56.087 45.186,56.403 44.497,56.105 C43.807,55.805 43.491,55.004 43.79,54.315 C44.089,53.625 44.89,53.309 45.579,53.608" id="Fill-746" class="icon-color"/>
      <path d="M45.85,60.23 C46.454,60.678 46.579,61.531 46.13,62.134 C45.682,62.737 44.829,62.863 44.226,62.414 C43.623,61.966 43.497,61.113 43.946,60.51 C44.394,59.907 45.247,59.781 45.85,60.23" id="Fill-747" class="icon-color"/>
      <path d="M46.121,66.852 C46.638,67.45 46.572,68.354 45.974,68.871 C45.376,69.388 44.472,69.322 43.955,68.724 C43.438,68.126 43.504,67.222 44.102,66.705 C44.7,66.188 45.604,66.253 46.121,66.852" id="Fill-748" class="icon-color"/>
      <path d="M46.392,73.473 C46.823,74.221 46.566,75.176 45.818,75.607 C45.071,76.038 44.115,75.781 43.685,75.034 C43.254,74.286 43.51,73.331 44.258,72.9 C45.005,72.469 45.961,72.726 46.392,73.473" id="Fill-749" class="icon-color"/>
      <path d="M46.662,80.095 C47.007,80.992 46.559,81.999 45.662,82.344 C44.765,82.688 43.758,82.241 43.414,81.344 C43.069,80.446 43.517,79.44 44.414,79.095 C45.311,78.75 46.318,79.198 46.662,80.095" id="Fill-750" class="icon-color"/>
      <path d="M47.204,93.339 C47.376,94.535 46.546,95.644 45.35,95.817 C44.154,95.989 43.045,95.159 42.872,93.963 C42.7,92.767 43.53,91.658 44.726,91.485 C45.922,91.313 47.031,92.143 47.204,93.339" id="Fill-751" class="icon-color"/>
      <path d="M47.475,99.961 C47.561,101.306 46.54,102.467 45.194,102.553 C43.849,102.64 42.688,101.619 42.602,100.273 C42.516,98.927 43.536,97.767 44.882,97.68 C46.228,97.594 47.388,98.615 47.475,99.961" id="Fill-752" class="icon-color"/>
      <path d="M42.331,106.583 C42.331,105.088 43.543,103.875 45.038,103.875 C46.533,103.875 47.745,105.088 47.745,106.583 C47.745,108.078 46.533,109.29 45.038,109.29 C43.543,109.29 42.331,108.078 42.331,106.583" id="Fill-753" class="icon-color"/>
      <path d="M47.318,114.097 C47.318,115.356 46.298,116.377 45.038,116.377 C43.779,116.377 42.758,115.356 42.758,114.097 C42.758,112.837 43.779,111.816 45.038,111.816 C46.298,111.816 47.318,112.837 47.318,114.097" id="Fill-754" class="icon-color"/>
      <path d="M46.892,121.611 C46.892,122.635 46.062,123.465 45.038,123.465 C44.014,123.465 43.184,122.635 43.184,121.611 C43.184,120.587 44.014,119.757 45.038,119.757 C46.062,119.757 46.892,120.587 46.892,121.611" id="Fill-755" class="icon-color"/>
      <path d="M46.465,129.125 C46.465,129.913 45.826,130.552 45.038,130.552 C44.25,130.552 43.611,129.913 43.611,129.125 C43.611,128.337 44.25,127.698 45.038,127.698 C45.826,127.698 46.465,128.337 46.465,129.125" id="Fill-756" class="icon-color"/>
      <path d="M46.038,136.639 C46.038,137.191 45.591,137.639 45.038,137.639 C44.486,137.639 44.038,137.191 44.038,136.639 C44.038,136.087 44.486,135.639 45.038,135.639 C45.591,135.639 46.038,136.087 46.038,136.639" id="Fill-757" class="icon-color"/>
      <path d="M45.611,144.153 C45.611,144.47 45.355,144.726 45.038,144.726 C44.722,144.726 44.465,144.47 44.465,144.153 C44.465,143.836 44.722,143.58 45.038,143.58 C45.355,143.58 45.611,143.836 45.611,144.153" id="Fill-758" class="icon-color"/>
      <path d="M45.185,151.667 C45.185,151.748 45.119,151.814 45.038,151.814 C44.957,151.814 44.892,151.748 44.892,151.667 C44.892,151.586 44.957,151.521 45.038,151.521 C45.119,151.521 45.185,151.586 45.185,151.667" id="Fill-759" class="icon-color"/>
      <path d="M44.758,159.181 C44.758,159.026 44.883,158.901 45.038,158.901 C45.193,158.901 45.318,159.026 45.318,159.181 C45.318,159.336 45.193,159.461 45.038,159.461 C44.883,159.461 44.758,159.336 44.758,159.181" id="Fill-760" class="icon-color"/>
      <path d="M44.331,166.695 C44.331,166.305 44.648,165.988 45.038,165.988 C45.429,165.988 45.745,166.305 45.745,166.695 C45.745,167.086 45.429,167.402 45.038,167.402 C44.648,167.402 44.331,167.086 44.331,166.695" id="Fill-761" class="icon-color"/>
      <path d="M43.904,174.209 C43.904,173.583 44.412,173.076 45.038,173.076 C45.664,173.076 46.172,173.583 46.172,174.209 C46.172,174.835 45.664,175.343 45.038,175.343 C44.412,175.343 43.904,174.835 43.904,174.209" id="Fill-762" class="icon-color"/>
      <path d="M43.478,181.723 C43.478,180.862 44.176,180.163 45.038,180.163 C45.9,180.163 46.598,180.862 46.598,181.723 C46.598,182.585 45.9,183.284 45.038,183.284 C44.176,183.284 43.478,182.585 43.478,181.723" id="Fill-763" class="icon-color"/>
      <path d="M46.933,86.717 C47.192,87.764 46.553,88.822 45.506,89.08 C44.46,89.339 43.402,88.7 43.143,87.653 C42.885,86.607 43.523,85.549 44.57,85.29 C45.617,85.032 46.675,85.67 46.933,86.717" id="Fill-764" class="icon-color"/>
      <path d="M117.252,193.864 C117.252,194.662 116.605,195.308 115.808,195.308 C115.01,195.308 114.364,194.662 114.364,193.864 C114.364,193.067 115.01,192.42 115.808,192.42 C116.605,192.42 117.252,193.067 117.252,193.864" id="Fill-765" class="icon-color"/>
      <path d="M115.557,189.51 C114.839,189.372 114.37,188.677 114.508,187.96 C114.646,187.242 115.341,186.772 116.058,186.91 C116.776,187.049 117.246,187.743 117.108,188.461 C116.969,189.178 116.275,189.648 115.557,189.51" id="Fill-766" class="icon-color"/>
      <path d="M115.307,183.711 C114.669,183.435 114.376,182.693 114.653,182.055 C114.929,181.417 115.671,181.124 116.309,181.401 C116.947,181.678 117.24,182.419 116.963,183.057 C116.686,183.695 115.945,183.988 115.307,183.711" id="Fill-767" class="icon-color"/>
      <path d="M115.056,177.913 C114.498,177.498 114.382,176.709 114.797,176.15 C115.212,175.592 116.001,175.476 116.56,175.891 C117.118,176.306 117.234,177.095 116.819,177.654 C116.404,178.212 115.615,178.328 115.056,177.913" id="Fill-768" class="icon-color"/>
      <path d="M114.806,172.115 C114.327,171.561 114.388,170.724 114.941,170.246 C115.495,169.767 116.331,169.828 116.81,170.381 C117.289,170.935 117.228,171.772 116.674,172.25 C116.121,172.729 115.284,172.668 114.806,172.115" id="Fill-769" class="icon-color"/>
      <path d="M114.555,166.316 C114.156,165.624 114.394,164.74 115.086,164.341 C115.778,163.942 116.662,164.18 117.061,164.872 C117.459,165.564 117.222,166.448 116.53,166.847 C115.838,167.245 114.954,167.008 114.555,166.316" id="Fill-770" class="icon-color"/>
      <path d="M114.305,160.518 C113.986,159.687 114.4,158.756 115.23,158.437 C116.061,158.118 116.992,158.532 117.311,159.362 C117.63,160.193 117.216,161.124 116.386,161.443 C115.555,161.762 114.624,161.348 114.305,160.518" id="Fill-771" class="icon-color"/>
      <path d="M114.054,154.719 C113.815,153.751 114.406,152.771 115.375,152.532 C116.343,152.293 117.322,152.884 117.562,153.853 C117.801,154.821 117.21,155.801 116.241,156.04 C115.273,156.279 114.293,155.688 114.054,154.719" id="Fill-772" class="icon-color"/>
      <path d="M113.553,143.122 C113.473,141.877 114.418,140.803 115.663,140.723 C116.909,140.643 117.983,141.588 118.063,142.833 C118.143,144.079 117.198,145.153 115.952,145.233 C114.707,145.312 113.633,144.368 113.553,143.122" id="Fill-773" class="icon-color"/>
      <path d="M118.313,137.324 C118.313,138.707 117.191,139.829 115.808,139.829 C114.424,139.829 113.302,138.707 113.302,137.324 C113.302,135.94 114.424,134.818 115.808,134.818 C117.191,134.818 118.313,135.94 118.313,137.324" id="Fill-774" class="icon-color"/>
      <path d="M113.698,131.34 C113.698,130.174 114.642,129.229 115.808,129.229 C116.974,129.229 117.918,130.174 117.918,131.34 C117.918,132.505 116.974,133.45 115.808,133.45 C114.642,133.45 113.698,132.505 113.698,131.34" id="Fill-775" class="icon-color"/>
      <path d="M114.092,125.356 C114.092,124.408 114.86,123.64 115.808,123.64 C116.755,123.64 117.523,124.408 117.523,125.356 C117.523,126.303 116.755,127.071 115.808,127.071 C114.86,127.071 114.092,126.303 114.092,125.356" id="Fill-776" class="icon-color"/>
      <path d="M114.487,119.372 C114.487,118.642 115.078,118.051 115.808,118.051 C116.537,118.051 117.128,118.642 117.128,119.372 C117.128,120.101 116.537,120.692 115.808,120.692 C115.078,120.692 114.487,120.101 114.487,119.372" id="Fill-777" class="icon-color"/>
      <path d="M114.882,113.388 C114.882,112.876 115.297,112.462 115.808,112.462 C116.319,112.462 116.733,112.876 116.733,113.388 C116.733,113.899 116.319,114.313 115.808,114.313 C115.297,114.313 114.882,113.899 114.882,113.388" id="Fill-778" class="icon-color"/>
      <path d="M115.277,107.404 C115.277,107.111 115.515,106.873 115.808,106.873 C116.101,106.873 116.339,107.111 116.339,107.404 C116.339,107.697 116.101,107.934 115.808,107.934 C115.515,107.934 115.277,107.697 115.277,107.404" id="Fill-779" class="icon-color"/>
      <path d="M115.672,101.42 C115.672,101.345 115.733,101.284 115.808,101.284 C115.883,101.284 115.944,101.345 115.944,101.42 C115.944,101.495 115.883,101.555 115.808,101.555 C115.733,101.555 115.672,101.495 115.672,101.42" id="Fill-780" class="icon-color"/>
      <path d="M116.067,95.436 C116.067,95.579 115.951,95.695 115.808,95.695 C115.665,95.695 115.549,95.579 115.549,95.436 C115.549,95.292 115.665,95.176 115.808,95.176 C115.951,95.176 116.067,95.292 116.067,95.436" id="Fill-781" class="icon-color"/>
      <path d="M116.462,89.451 C116.462,89.813 116.169,90.106 115.808,90.106 C115.447,90.106 115.154,89.813 115.154,89.451 C115.154,89.09 115.447,88.797 115.808,88.797 C116.169,88.797 116.462,89.09 116.462,89.451" id="Fill-782" class="icon-color"/>
      <path d="M116.857,83.467 C116.857,84.047 116.387,84.517 115.808,84.517 C115.228,84.517 114.759,84.047 114.759,83.467 C114.759,82.888 115.228,82.418 115.808,82.418 C116.387,82.418 116.857,82.888 116.857,83.467" id="Fill-783" class="icon-color"/>
      <path d="M117.252,77.483 C117.252,78.281 116.605,78.928 115.808,78.928 C115.01,78.928 114.364,78.281 114.364,77.483 C114.364,76.686 115.01,76.039 115.808,76.039 C116.605,76.039 117.252,76.686 117.252,77.483" id="Fill-784" class="icon-color"/>
      <path d="M113.803,148.921 C113.644,147.814 114.412,146.787 115.519,146.627 C116.626,146.468 117.653,147.236 117.812,148.343 C117.972,149.45 117.204,150.477 116.097,150.636 C114.99,150.796 113.963,150.028 113.803,148.921" id="Fill-785" class="icon-color"/>
      <path d="M111.331,183.048 C111.331,183.888 110.65,184.569 109.81,184.569 C108.969,184.569 108.288,183.888 108.288,183.048 C108.288,182.208 108.969,181.527 109.81,181.527 C110.65,181.527 111.331,182.208 111.331,183.048" id="Fill-786" class="icon-color"/>
      <path d="M109.516,178.175 C108.77,178.013 108.296,177.277 108.457,176.53 C108.619,175.783 109.356,175.309 110.103,175.471 C110.85,175.633 111.324,176.37 111.162,177.116 C111,177.863 110.263,178.337 109.516,178.175" id="Fill-787" class="icon-color"/>
      <path d="M109.223,171.782 C108.57,171.458 108.303,170.666 108.627,170.012 C108.95,169.359 109.743,169.092 110.396,169.416 C111.049,169.74 111.317,170.532 110.993,171.185 C110.669,171.839 109.877,172.106 109.223,171.782" id="Fill-788" class="icon-color"/>
      <path d="M108.93,165.388 C108.37,164.903 108.31,164.055 108.795,163.495 C109.281,162.935 110.129,162.875 110.689,163.36 C111.249,163.846 111.31,164.694 110.824,165.254 C110.338,165.814 109.49,165.874 108.93,165.388" id="Fill-789" class="icon-color"/>
      <path d="M108.637,158.995 C108.17,158.347 108.317,157.444 108.965,156.977 C109.612,156.51 110.516,156.657 110.982,157.305 C111.449,157.953 111.302,158.856 110.655,159.323 C110.007,159.79 109.103,159.643 108.637,158.995" id="Fill-790" class="icon-color"/>
      <path d="M108.343,152.602 C107.97,151.792 108.324,150.833 109.133,150.459 C109.943,150.086 110.902,150.44 111.276,151.249 C111.649,152.059 111.295,153.018 110.486,153.392 C109.676,153.765 108.717,153.411 108.343,152.602" id="Fill-791" class="icon-color"/>
      <path d="M108.05,146.208 C107.77,145.237 108.331,144.222 109.302,143.942 C110.274,143.662 111.289,144.222 111.569,145.194 C111.849,146.166 111.288,147.18 110.317,147.461 C109.345,147.741 108.33,147.18 108.05,146.208" id="Fill-792" class="icon-color"/>
      <path d="M107.757,139.815 C107.57,138.681 108.338,137.611 109.472,137.424 C110.605,137.237 111.675,138.005 111.862,139.139 C112.049,140.272 111.281,141.342 110.148,141.529 C109.014,141.716 107.944,140.948 107.757,139.815" id="Fill-793" class="icon-color"/>
      <path d="M112.449,127.028 C112.449,128.485 111.267,129.667 109.81,129.667 C108.352,129.667 107.171,128.485 107.171,127.028 C107.171,125.57 108.352,124.389 109.81,124.389 C111.267,124.389 112.449,125.57 112.449,127.028" id="Fill-794" class="icon-color"/>
      <path d="M107.633,120.024 C107.633,118.822 108.607,117.848 109.81,117.848 C111.012,117.848 111.986,118.822 111.986,120.024 C111.986,121.227 111.012,122.201 109.81,122.201 C108.607,122.201 107.633,121.227 107.633,120.024" id="Fill-795" class="icon-color"/>
      <path d="M108.095,113.021 C108.095,112.074 108.863,111.306 109.81,111.306 C110.756,111.306 111.524,112.074 111.524,113.021 C111.524,113.968 110.756,114.735 109.81,114.735 C108.863,114.735 108.095,113.968 108.095,113.021" id="Fill-796" class="icon-color"/>
      <path d="M108.557,106.017 C108.557,105.326 109.118,104.765 109.81,104.765 C110.501,104.765 111.062,105.326 111.062,106.017 C111.062,106.709 110.501,107.27 109.81,107.27 C109.118,107.27 108.557,106.709 108.557,106.017" id="Fill-797" class="icon-color"/>
      <path d="M109.02,99.014 C109.02,98.578 109.373,98.224 109.81,98.224 C110.246,98.224 110.6,98.578 110.6,99.014 C110.6,99.45 110.246,99.804 109.81,99.804 C109.373,99.804 109.02,99.45 109.02,99.014" id="Fill-798" class="icon-color"/>
      <path d="M109.482,92.011 C109.482,91.829 109.629,91.683 109.81,91.683 C109.991,91.683 110.137,91.829 110.137,92.011 C110.137,92.191 109.991,92.338 109.81,92.338 C109.629,92.338 109.482,92.191 109.482,92.011" id="Fill-799" class="icon-color"/>
      <path d="M109.944,85.007 C109.944,85.081 109.884,85.141 109.81,85.141 C109.735,85.141 109.675,85.081 109.675,85.007 C109.675,84.933 109.735,84.873 109.81,84.873 C109.884,84.873 109.944,84.933 109.944,85.007" id="Fill-800" class="icon-color"/>
      <path d="M110.406,78.004 C110.406,78.333 110.139,78.6 109.81,78.6 C109.48,78.6 109.213,78.333 109.213,78.004 C109.213,77.674 109.48,77.407 109.81,77.407 C110.139,77.407 110.406,77.674 110.406,78.004" id="Fill-801" class="icon-color"/>
      <path d="M110.868,71 C110.868,71.585 110.394,72.059 109.81,72.059 C109.225,72.059 108.751,71.585 108.751,71 C108.751,70.415 109.225,69.941 109.81,69.941 C110.394,69.941 110.868,70.415 110.868,71" id="Fill-802" class="icon-color"/>
      <path d="M111.331,63.996 C111.331,64.837 110.65,65.518 109.81,65.518 C108.969,65.518 108.288,64.837 108.288,63.996 C108.288,63.156 108.969,62.475 109.81,62.475 C110.65,62.475 111.331,63.156 111.331,63.996" id="Fill-803" class="icon-color"/>
      <path d="M107.464,133.421 C107.37,132.126 108.345,131 109.641,130.906 C110.936,130.813 112.062,131.788 112.155,133.083 C112.249,134.379 111.274,135.505 109.979,135.598 C108.683,135.691 107.557,134.717 107.464,133.421" id="Fill-804" class="icon-color"/>
      <path d="M119.2,147.624 C119.2,146.481 120.126,145.554 121.269,145.554 C122.412,145.554 123.339,146.481 123.339,147.624 C123.339,148.767 122.412,149.693 121.269,149.693 C120.126,149.693 119.2,148.767 119.2,147.624" id="Fill-805" class="icon-color"/>
      <path d="M121.201,150.45 C122.23,150.412 123.094,151.215 123.132,152.244 C123.17,153.273 122.366,154.137 121.338,154.175 C120.309,154.212 119.444,153.409 119.407,152.381 C119.369,151.352 120.172,150.487 121.201,150.45" id="Fill-806" class="icon-color"/>
      <path d="M121.133,155.345 C122.047,155.27 122.849,155.95 122.925,156.864 C123,157.779 122.32,158.581 121.406,158.656 C120.491,158.732 119.689,158.052 119.614,157.137 C119.538,156.223 120.218,155.42 121.133,155.345" id="Fill-807" class="icon-color"/>
      <path d="M121.126,160.241 C121.926,160.128 122.666,160.685 122.779,161.485 C122.892,162.285 122.335,163.025 121.535,163.138 C120.735,163.251 119.995,162.694 119.882,161.894 C119.769,161.094 120.326,160.354 121.126,160.241" id="Fill-808" class="icon-color"/>
      <path d="M121.076,165.136 C121.762,164.986 122.44,165.419 122.591,166.105 C122.742,166.791 122.308,167.469 121.622,167.62 C120.937,167.771 120.259,167.337 120.108,166.651 C119.957,165.965 120.391,165.287 121.076,165.136" id="Fill-809" class="icon-color"/>
      <path d="M121.027,170.032 C121.599,169.844 122.215,170.154 122.403,170.725 C122.592,171.297 122.281,171.913 121.71,172.101 C121.138,172.29 120.522,171.98 120.334,171.408 C120.145,170.837 120.456,170.22 121.027,170.032" id="Fill-810" class="icon-color"/>
      <path d="M120.978,174.928 C121.435,174.701 121.989,174.888 122.216,175.346 C122.442,175.803 122.255,176.357 121.797,176.583 C121.34,176.809 120.786,176.622 120.56,176.165 C120.334,175.708 120.521,175.154 120.978,174.928" id="Fill-811" class="icon-color"/>
      <path d="M120.896,179.823 C121.238,179.559 121.73,179.623 121.994,179.966 C122.258,180.309 122.194,180.801 121.851,181.065 C121.508,181.329 121.016,181.265 120.752,180.922 C120.489,180.579 120.553,180.087 120.896,179.823" id="Fill-812" class="icon-color"/>
      <path d="M120.791,184.719 C121.02,184.417 121.45,184.358 121.751,184.586 C122.053,184.815 122.112,185.245 121.883,185.546 C121.655,185.848 121.225,185.907 120.924,185.678 C120.622,185.45 120.563,185.02 120.791,184.719" id="Fill-813" class="icon-color"/>
      <path d="M120.655,189.614 C120.769,189.275 121.137,189.092 121.476,189.207 C121.815,189.321 121.998,189.689 121.883,190.028 C121.769,190.367 121.401,190.549 121.062,190.435 C120.723,190.321 120.54,189.953 120.655,189.614" id="Fill-814" class="icon-color"/>
      <path d="M120.587,194.509 C120.587,194.132 120.892,193.827 121.269,193.827 C121.646,193.827 121.952,194.132 121.952,194.509 C121.952,194.886 121.646,195.192 121.269,195.192 C120.892,195.192 120.587,194.886 120.587,194.509" id="Fill-815" class="icon-color"/>
      <path d="M121.338,139.374 C120.309,139.412 119.444,138.609 119.407,137.58 C119.369,136.551 120.172,135.687 121.201,135.649 C122.23,135.611 123.094,136.415 123.132,137.443 C123.17,138.472 122.366,139.336 121.338,139.374" id="Fill-816" class="icon-color"/>
      <path d="M121.406,134.478 C120.491,134.554 119.689,133.874 119.614,132.959 C119.538,132.045 120.218,131.243 121.133,131.167 C122.047,131.092 122.849,131.772 122.925,132.686 C123,133.601 122.32,134.403 121.406,134.478" id="Fill-817" class="icon-color"/>
      <path d="M121.413,129.583 C120.613,129.696 119.872,129.139 119.759,128.339 C119.646,127.539 120.203,126.799 121.003,126.686 C121.803,126.572 122.544,127.129 122.657,127.929 C122.77,128.73 122.213,129.47 121.413,129.583" id="Fill-818" class="icon-color"/>
      <path d="M121.462,124.687 C120.776,124.838 120.098,124.404 119.947,123.719 C119.796,123.033 120.23,122.355 120.916,122.204 C121.602,122.053 122.28,122.487 122.431,123.173 C122.581,123.858 122.148,124.537 121.462,124.687" id="Fill-819" class="icon-color"/>
      <path d="M121.511,119.792 C120.94,119.98 120.324,119.67 120.135,119.098 C119.947,118.527 120.257,117.911 120.828,117.722 C121.4,117.534 122.016,117.844 122.204,118.416 C122.393,118.987 122.082,119.603 121.511,119.792" id="Fill-820" class="icon-color"/>
      <path d="M121.56,114.896 C121.103,115.122 120.549,114.935 120.323,114.478 C120.096,114.021 120.284,113.467 120.741,113.241 C121.198,113.014 121.752,113.202 121.978,113.659 C122.204,114.116 122.017,114.67 121.56,114.896" id="Fill-821" class="icon-color"/>
      <path d="M121.643,110.001 C121.3,110.265 120.808,110.201 120.544,109.858 C120.28,109.515 120.344,109.023 120.687,108.759 C121.03,108.495 121.522,108.559 121.786,108.902 C122.05,109.245 121.986,109.737 121.643,110.001" id="Fill-822" class="icon-color"/>
      <path d="M121.747,105.105 C121.519,105.407 121.089,105.466 120.787,105.237 C120.485,105.009 120.426,104.579 120.655,104.277 C120.883,103.976 121.313,103.916 121.615,104.145 C121.916,104.374 121.976,104.804 121.747,105.105" id="Fill-823" class="icon-color"/>
      <path d="M121.883,100.21 C121.769,100.549 121.401,100.731 121.062,100.617 C120.723,100.503 120.54,100.135 120.655,99.796 C120.769,99.457 121.137,99.274 121.476,99.388 C121.815,99.503 121.998,99.87 121.883,100.21" id="Fill-824" class="icon-color"/>
      <path d="M121.952,95.314 C121.952,95.691 121.646,95.997 121.269,95.997 C120.892,95.997 120.587,95.691 120.587,95.314 C120.587,94.937 120.892,94.632 121.269,94.632 C121.646,94.632 121.952,94.937 121.952,95.314" id="Fill-825" class="icon-color"/>
      <path d="M123.339,142.2 C123.339,143.343 122.412,144.27 121.269,144.27 C120.126,144.27 119.2,143.343 119.2,142.2 C119.2,141.057 120.126,140.131 121.269,140.131 C122.412,140.131 123.339,141.057 123.339,142.2" id="Fill-826" class="icon-color"/>
      <path d="M54.946,109.264 C54.946,107.815 56.122,106.639 57.571,106.639 C59.021,106.639 60.197,107.815 60.197,109.264 C60.197,110.714 59.021,111.89 57.571,111.89 C56.122,111.89 54.946,110.714 54.946,109.264" id="Fill-827" class="icon-color"/>
      <path d="M57.485,112.849 C58.79,112.802 59.886,113.82 59.934,115.125 C59.982,116.43 58.963,117.527 57.658,117.575 C56.353,117.622 55.257,116.603 55.209,115.298 C55.161,113.994 56.18,112.897 57.485,112.849" id="Fill-828" class="icon-color"/>
      <path d="M57.452,119.059 C58.611,118.964 59.629,119.826 59.725,120.986 C59.82,122.146 58.958,123.164 57.798,123.26 C56.638,123.355 55.62,122.492 55.524,121.333 C55.429,120.173 56.292,119.155 57.452,119.059" id="Fill-829" class="icon-color"/>
      <path d="M57.389,125.269 C58.404,125.126 59.343,125.832 59.487,126.847 C59.63,127.862 58.924,128.801 57.909,128.945 C56.894,129.088 55.955,128.382 55.811,127.367 C55.668,126.352 56.374,125.413 57.389,125.269" id="Fill-830" class="icon-color"/>
      <path d="M57.265,137.689 C57.99,137.45 58.771,137.844 59.01,138.569 C59.249,139.294 58.855,140.075 58.131,140.314 C57.406,140.554 56.624,140.16 56.385,139.435 C56.146,138.71 56.54,137.928 57.265,137.689" id="Fill-831" class="icon-color"/>
      <path d="M57.202,143.899 C57.782,143.612 58.485,143.85 58.772,144.43 C59.059,145.01 58.821,145.713 58.241,146 C57.662,146.286 56.959,146.049 56.672,145.469 C56.385,144.889 56.622,144.186 57.202,143.899" id="Fill-832" class="icon-color"/>
      <path d="M57.097,150.109 C57.532,149.775 58.156,149.856 58.491,150.291 C58.826,150.726 58.745,151.349 58.31,151.684 C57.875,152.019 57.251,151.938 56.916,151.503 C56.581,151.068 56.662,150.444 57.097,150.109" id="Fill-833" class="icon-color"/>
      <path d="M56.965,156.319 C57.255,155.937 57.8,155.861 58.183,156.151 C58.566,156.441 58.641,156.987 58.351,157.369 C58.061,157.752 57.515,157.827 57.133,157.537 C56.75,157.247 56.675,156.702 56.965,156.319" id="Fill-834" class="icon-color"/>
      <path d="M56.792,162.529 C56.937,162.099 57.404,161.867 57.834,162.012 C58.264,162.157 58.496,162.623 58.351,163.054 C58.206,163.484 57.739,163.716 57.309,163.571 C56.879,163.426 56.647,162.959 56.792,162.529" id="Fill-835" class="icon-color"/>
      <path d="M56.706,168.739 C56.706,168.261 57.093,167.873 57.571,167.873 C58.05,167.873 58.437,168.261 58.437,168.739 C58.437,169.217 58.05,169.605 57.571,169.605 C57.093,169.605 56.706,169.217 56.706,168.739" id="Fill-836" class="icon-color"/>
      <path d="M57.327,131.479 C58.197,131.288 59.057,131.838 59.248,132.708 C59.44,133.578 58.89,134.438 58.02,134.629 C57.15,134.821 56.289,134.271 56.098,133.401 C55.907,132.531 56.457,131.67 57.327,131.479" id="Fill-837" class="icon-color"/>
      <path d="M53.363,106.048 C53.363,107.372 52.291,108.445 50.967,108.445 C49.643,108.445 48.57,107.372 48.57,106.048 C48.57,104.724 49.643,103.651 50.967,103.651 C52.291,103.651 53.363,104.724 53.363,106.048" id="Fill-838" class="icon-color"/>
      <path d="M51.033,102.136 C49.82,102.172 48.807,101.218 48.77,100.005 C48.734,98.792 49.688,97.779 50.901,97.742 C52.114,97.706 53.127,98.66 53.164,99.873 C53.2,101.086 52.246,102.099 51.033,102.136" id="Fill-839" class="icon-color"/>
      <path d="M51.099,95.827 C49.996,95.9 49.043,95.065 48.97,93.962 C48.897,92.859 49.732,91.906 50.835,91.833 C51.938,91.76 52.891,92.595 52.964,93.698 C53.037,94.801 52.202,95.754 51.099,95.827" id="Fill-840" class="icon-color"/>
      <path d="M51.165,89.518 C50.172,89.628 49.279,88.911 49.17,87.919 C49.06,86.926 49.777,86.033 50.769,85.924 C51.762,85.815 52.655,86.531 52.764,87.523 C52.873,88.516 52.157,89.409 51.165,89.518" id="Fill-841" class="icon-color"/>
      <path d="M51.23,83.21 C50.348,83.355 49.515,82.758 49.369,81.876 C49.224,80.993 49.821,80.16 50.703,80.014 C51.586,79.869 52.419,80.466 52.565,81.349 C52.71,82.231 52.113,83.064 51.23,83.21" id="Fill-842" class="icon-color"/>
      <path d="M51.296,76.901 C50.524,77.083 49.751,76.605 49.569,75.833 C49.387,75.06 49.865,74.287 50.638,74.105 C51.41,73.923 52.183,74.402 52.365,75.174 C52.547,75.946 52.068,76.719 51.296,76.901" id="Fill-843" class="icon-color"/>
      <path d="M51.362,70.592 C50.7,70.811 49.987,70.451 49.769,69.789 C49.55,69.128 49.91,68.414 50.572,68.196 C51.234,67.978 51.947,68.337 52.165,68.999 C52.383,69.661 52.024,70.374 51.362,70.592" id="Fill-844" class="icon-color"/>
      <path d="M51.428,64.284 C50.877,64.538 50.223,64.298 49.968,63.746 C49.714,63.195 49.954,62.541 50.506,62.287 C51.057,62.032 51.711,62.273 51.966,62.824 C52.22,63.376 51.98,64.029 51.428,64.284" id="Fill-845" class="icon-color"/>
      <path d="M51.494,57.975 C51.053,58.266 50.459,58.144 50.168,57.703 C49.877,57.262 49.999,56.668 50.44,56.377 C50.881,56.086 51.475,56.208 51.766,56.649 C52.057,57.09 51.935,57.684 51.494,57.975" id="Fill-846" class="icon-color"/>
      <path d="M51.56,51.666 C51.229,51.994 50.695,51.991 50.368,51.66 C50.04,51.329 50.043,50.796 50.374,50.468 C50.705,50.141 51.239,50.143 51.566,50.474 C51.894,50.805 51.891,51.339 51.56,51.666" id="Fill-847" class="icon-color"/>
      <path d="M51.626,45.358 C51.405,45.721 50.931,45.837 50.568,45.617 C50.204,45.396 50.088,44.923 50.308,44.559 C50.529,44.195 51.003,44.079 51.367,44.299 C51.73,44.52 51.846,44.994 51.626,45.358" id="Fill-848" class="icon-color"/>
      <path d="M51.692,39.049 C51.581,39.449 51.167,39.684 50.767,39.574 C50.367,39.464 50.132,39.05 50.242,38.649 C50.353,38.249 50.766,38.014 51.167,38.125 C51.567,38.235 51.802,38.649 51.692,39.049" id="Fill-849" class="icon-color"/>
      <path d="M51.757,32.74 C51.757,33.177 51.404,33.531 50.967,33.531 C50.53,33.531 50.176,33.177 50.176,32.74 C50.176,32.304 50.53,31.95 50.967,31.95 C51.404,31.95 51.757,32.304 51.757,32.74" id="Fill-850" class="icon-color"/>
      <path d="M50.402,161.975 C50.402,161.535 50.759,161.178 51.2,161.178 C51.64,161.178 51.997,161.535 51.997,161.975 C51.997,162.415 51.64,162.772 51.2,162.772 C50.759,162.772 50.402,162.415 50.402,161.975" id="Fill-851" class="icon-color"/>
      <path d="M50.156,155.752 C50.156,155.175 50.623,154.708 51.2,154.708 C51.776,154.708 52.244,155.175 52.244,155.752 C52.244,156.329 51.776,156.796 51.2,156.796 C50.623,156.796 50.156,156.329 50.156,155.752" id="Fill-852" class="icon-color"/>
      <path d="M49.909,149.529 C49.909,148.816 50.486,148.238 51.2,148.238 C51.912,148.238 52.491,148.816 52.491,149.529 C52.491,150.242 51.912,150.82 51.2,150.82 C50.486,150.82 49.909,150.242 49.909,149.529" id="Fill-853" class="icon-color"/>
      <path d="M49.662,143.306 C49.662,142.457 50.35,141.768 51.2,141.768 C52.049,141.768 52.738,142.457 52.738,143.306 C52.738,144.155 52.049,144.844 51.2,144.844 C50.35,144.844 49.662,144.155 49.662,143.306" id="Fill-854" class="icon-color"/>
      <path d="M49.415,137.083 C49.415,136.097 50.214,135.298 51.2,135.298 C52.185,135.298 52.984,136.097 52.984,137.083 C52.984,138.069 52.185,138.868 51.2,138.868 C50.214,138.868 49.415,138.069 49.415,137.083" id="Fill-855" class="icon-color"/>
      <path d="M49.168,130.86 C49.168,129.738 50.077,128.828 51.2,128.828 C52.322,128.828 53.232,129.738 53.232,130.86 C53.232,131.982 52.322,132.892 51.2,132.892 C50.077,132.892 49.168,131.982 49.168,130.86" id="Fill-856" class="icon-color"/>
      <path d="M48.921,124.637 C48.921,123.378 49.941,122.358 51.2,122.358 C52.458,122.358 53.478,123.378 53.478,124.637 C53.478,125.895 52.458,126.916 51.2,126.916 C49.941,126.916 48.921,125.895 48.921,124.637" id="Fill-857" class="icon-color"/>
      <path d="M48.674,118.414 C48.674,117.019 49.805,115.888 51.2,115.888 C52.595,115.888 53.725,117.019 53.725,118.414 C53.725,119.809 52.595,120.94 51.2,120.94 C49.805,120.94 48.674,119.809 48.674,118.414" id="Fill-858" class="icon-color"/>
      <path d="M48.427,112.191 C48.427,110.659 49.668,109.418 51.2,109.418 C52.731,109.418 53.972,110.659 53.972,112.191 C53.972,113.722 52.731,114.964 51.2,114.964 C49.668,114.964 48.427,113.722 48.427,112.191" id="Fill-859" class="icon-color"/>
      <path d="M162.372,70.253 C162.372,69.412 163.053,68.731 163.893,68.731 C164.733,68.731 165.414,69.412 165.414,70.253 C165.414,71.092 164.733,71.774 163.893,71.774 C163.053,71.774 162.372,71.092 162.372,70.253" id="Fill-860" class="icon-color"/>
      <path d="M164.186,75.125 C164.933,75.287 165.407,76.023 165.245,76.77 C165.083,77.517 164.346,77.991 163.6,77.829 C162.853,77.667 162.379,76.93 162.541,76.184 C162.703,75.437 163.439,74.963 164.186,75.125" id="Fill-861" class="icon-color"/>
      <path d="M164.479,81.518 C165.133,81.842 165.4,82.634 165.076,83.288 C164.752,83.941 163.96,84.208 163.306,83.884 C162.653,83.56 162.386,82.768 162.71,82.115 C163.034,81.461 163.826,81.194 164.479,81.518" id="Fill-862" class="icon-color"/>
      <path d="M164.773,87.911 C165.333,88.397 165.393,89.245 164.907,89.805 C164.421,90.365 163.573,90.426 163.013,89.94 C162.453,89.454 162.393,88.606 162.879,88.046 C163.365,87.486 164.212,87.426 164.773,87.911" id="Fill-863" class="icon-color"/>
      <path d="M165.066,94.305 C165.532,94.953 165.386,95.856 164.738,96.323 C164.09,96.79 163.187,96.643 162.72,95.995 C162.253,95.347 162.4,94.444 163.048,93.977 C163.696,93.511 164.599,93.657 165.066,94.305" id="Fill-864" class="icon-color"/>
      <path d="M165.359,100.698 C165.732,101.508 165.379,102.467 164.569,102.841 C163.759,103.214 162.8,102.86 162.427,102.051 C162.053,101.241 162.407,100.282 163.217,99.908 C164.027,99.535 164.986,99.889 165.359,100.698" id="Fill-865" class="icon-color"/>
      <path d="M165.652,107.092 C165.932,108.064 165.372,109.078 164.4,109.358 C163.428,109.638 162.414,109.078 162.134,108.106 C161.853,107.134 162.414,106.12 163.386,105.84 C164.357,105.559 165.372,106.12 165.652,107.092" id="Fill-866" class="icon-color"/>
      <path d="M165.945,113.485 C166.132,114.619 165.364,115.689 164.231,115.876 C163.097,116.063 162.027,115.295 161.84,114.161 C161.654,113.028 162.421,111.957 163.555,111.771 C164.688,111.584 165.759,112.352 165.945,113.485" id="Fill-867" class="icon-color"/>
      <path d="M166.239,119.879 C166.332,121.174 165.357,122.3 164.062,122.394 C162.766,122.487 161.64,121.512 161.547,120.217 C161.454,118.921 162.428,117.795 163.724,117.702 C165.019,117.609 166.145,118.583 166.239,119.879" id="Fill-868" class="icon-color"/>
      <path d="M166.07,133.276 C166.07,134.478 165.095,135.453 163.893,135.453 C162.691,135.453 161.716,134.478 161.716,133.276 C161.716,132.074 162.691,131.099 163.893,131.099 C165.095,131.099 166.07,132.074 166.07,133.276" id="Fill-869" class="icon-color"/>
      <path d="M165.607,140.279 C165.607,141.226 164.84,141.994 163.893,141.994 C162.946,141.994 162.178,141.226 162.178,140.279 C162.178,139.332 162.946,138.565 163.893,138.565 C164.84,138.565 165.607,139.332 165.607,140.279" id="Fill-870" class="icon-color"/>
      <path d="M165.145,147.283 C165.145,147.974 164.584,148.535 163.893,148.535 C163.201,148.535 162.641,147.974 162.641,147.283 C162.641,146.591 163.201,146.03 163.893,146.03 C164.584,146.03 165.145,146.591 165.145,147.283" id="Fill-871" class="icon-color"/>
      <path d="M164.683,154.286 C164.683,154.722 164.329,155.076 163.893,155.076 C163.457,155.076 163.103,154.722 163.103,154.286 C163.103,153.85 163.457,153.496 163.893,153.496 C164.329,153.496 164.683,153.85 164.683,154.286" id="Fill-872" class="icon-color"/>
      <path d="M164.221,161.29 C164.221,161.471 164.074,161.617 163.893,161.617 C163.712,161.617 163.565,161.471 163.565,161.29 C163.565,161.109 163.712,160.962 163.893,160.962 C164.074,160.962 164.221,161.109 164.221,161.29" id="Fill-873" class="icon-color"/>
      <path d="M163.758,168.293 C163.758,168.219 163.819,168.159 163.893,168.159 C163.967,168.159 164.027,168.219 164.027,168.293 C164.027,168.367 163.967,168.428 163.893,168.428 C163.819,168.428 163.758,168.367 163.758,168.293" id="Fill-874" class="icon-color"/>
      <path d="M163.296,175.297 C163.296,174.967 163.563,174.7 163.893,174.7 C164.222,174.7 164.49,174.967 164.49,175.297 C164.49,175.626 164.222,175.893 163.893,175.893 C163.563,175.893 163.296,175.626 163.296,175.297" id="Fill-875" class="icon-color"/>
      <path d="M162.834,182.3 C162.834,181.715 163.308,181.241 163.893,181.241 C164.478,181.241 164.952,181.715 164.952,182.3 C164.952,182.885 164.478,183.359 163.893,183.359 C163.308,183.359 162.834,182.885 162.834,182.3" id="Fill-876" class="icon-color"/>
      <path d="M162.372,189.303 C162.372,188.463 163.053,187.782 163.893,187.782 C164.733,187.782 165.414,188.463 165.414,189.303 C165.414,190.144 164.733,190.825 163.893,190.825 C163.053,190.825 162.372,190.144 162.372,189.303" id="Fill-877" class="icon-color"/>
      <path d="M161.254,126.272 C161.254,124.815 162.435,123.633 163.893,123.633 C165.35,123.633 166.532,124.815 166.532,126.272 C166.532,127.73 165.35,128.911 163.893,128.911 C162.435,128.911 161.254,127.73 161.254,126.272" id="Fill-878" class="icon-color"/>
      <path d="M167.302,117.006 C167.302,115.556 168.477,114.381 169.927,114.381 C171.377,114.381 172.552,115.556 172.552,117.006 C172.552,118.456 171.377,119.631 169.927,119.631 C168.477,119.631 167.302,118.456 167.302,117.006" id="Fill-879" class="icon-color"/>
      <path d="M169.84,121.92 C171.145,121.873 172.242,122.892 172.29,124.197 C172.337,125.501 171.318,126.598 170.014,126.646 C168.709,126.694 167.612,125.675 167.564,124.37 C167.517,123.065 168.536,121.968 169.84,121.92" id="Fill-880" class="icon-color"/>
      <path d="M169.818,129.461 C170.978,129.365 171.996,130.227 172.091,131.387 C172.187,132.547 171.324,133.565 170.164,133.661 C169.004,133.756 167.987,132.894 167.891,131.734 C167.795,130.574 168.658,129.556 169.818,129.461" id="Fill-881" class="icon-color"/>
      <path d="M169.761,137 C170.776,136.857 171.715,137.563 171.859,138.578 C172.002,139.593 171.296,140.532 170.281,140.675 C169.266,140.819 168.327,140.113 168.183,139.098 C168.04,138.083 168.746,137.144 169.761,137" id="Fill-882" class="icon-color"/>
      <path d="M169.647,152.08 C170.372,151.841 171.154,152.235 171.393,152.96 C171.632,153.685 171.238,154.466 170.513,154.705 C169.788,154.944 169.007,154.55 168.768,153.826 C168.529,153.101 168.923,152.319 169.647,152.08" id="Fill-883" class="icon-color"/>
      <path d="M169.541,159.62 C170.121,159.333 170.824,159.571 171.111,160.15 C171.398,160.73 171.16,161.433 170.581,161.72 C170.001,162.007 169.298,161.769 169.011,161.189 C168.724,160.609 168.961,159.907 169.541,159.62" id="Fill-884" class="icon-color"/>
      <path d="M169.421,167.16 C169.856,166.825 170.48,166.906 170.815,167.341 C171.15,167.776 171.069,168.4 170.634,168.735 C170.199,169.07 169.575,168.988 169.24,168.553 C168.905,168.118 168.986,167.495 169.421,167.16" id="Fill-885" class="icon-color"/>
      <path d="M169.301,174.7 C169.591,174.317 170.136,174.242 170.519,174.532 C170.902,174.822 170.977,175.367 170.687,175.75 C170.397,176.132 169.852,176.207 169.469,175.917 C169.086,175.627 169.011,175.082 169.301,174.7" id="Fill-886" class="icon-color"/>
      <path d="M169.148,182.239 C169.293,181.809 169.759,181.578 170.19,181.723 C170.62,181.868 170.851,182.334 170.706,182.765 C170.561,183.195 170.095,183.426 169.665,183.281 C169.234,183.136 169.003,182.67 169.148,182.239" id="Fill-887" class="icon-color"/>
      <path d="M169.061,189.779 C169.061,189.301 169.449,188.913 169.927,188.913 C170.405,188.913 170.793,189.301 170.793,189.779 C170.793,190.258 170.405,190.645 169.927,190.645 C169.449,190.645 169.061,190.258 169.061,189.779" id="Fill-888" class="icon-color"/>
      <path d="M169.704,144.54 C170.574,144.349 171.435,144.899 171.626,145.769 C171.817,146.639 171.267,147.499 170.397,147.69 C169.527,147.882 168.667,147.332 168.476,146.462 C168.284,145.592 168.834,144.732 169.704,144.54" id="Fill-889" class="icon-color"/>
      <path d="M91.305,198.363 C91.305,199.261 90.578,199.988 89.68,199.988 C88.783,199.988 88.055,199.261 88.055,198.363 C88.055,197.466 88.783,196.739 89.68,196.739 C90.578,196.739 91.305,197.466 91.305,198.363" id="Fill-890" class="icon-color"/>
      <path d="M89.398,193.093 C88.591,192.937 88.062,192.156 88.218,191.349 C88.373,190.541 89.154,190.012 89.962,190.168 C90.77,190.324 91.298,191.105 91.143,191.913 C90.987,192.72 90.206,193.249 89.398,193.093" id="Fill-891" class="icon-color"/>
      <path d="M89.116,186.198 C88.398,185.886 88.069,185.052 88.38,184.334 C88.692,183.616 89.526,183.286 90.244,183.598 C90.962,183.909 91.291,184.743 90.98,185.461 C90.669,186.179 89.834,186.509 89.116,186.198" id="Fill-892" class="icon-color"/>
      <path d="M88.835,179.302 C88.206,178.835 88.076,177.947 88.543,177.319 C89.01,176.691 89.898,176.56 90.526,177.027 C91.154,177.494 91.285,178.382 90.818,179.01 C90.351,179.639 89.463,179.769 88.835,179.302" id="Fill-893" class="icon-color"/>
      <path d="M88.553,172.407 C88.014,171.784 88.082,170.843 88.705,170.304 C89.328,169.766 90.269,169.834 90.808,170.457 C91.346,171.079 91.278,172.021 90.655,172.559 C90.032,173.098 89.091,173.029 88.553,172.407" id="Fill-894" class="icon-color"/>
      <path d="M88.271,165.511 C87.822,164.733 88.089,163.738 88.868,163.289 C89.646,162.841 90.641,163.108 91.09,163.886 C91.538,164.665 91.271,165.66 90.493,166.108 C89.714,166.557 88.719,166.29 88.271,165.511" id="Fill-895" class="icon-color"/>
      <path d="M87.989,158.616 C87.63,157.682 88.096,156.633 89.03,156.275 C89.964,155.916 91.013,156.382 91.372,157.316 C91.731,158.25 91.264,159.298 90.33,159.657 C89.396,160.016 88.348,159.55 87.989,158.616" id="Fill-896" class="icon-color"/>
      <path d="M87.707,151.72 C87.438,150.631 88.103,149.529 89.193,149.26 C90.282,148.991 91.384,149.656 91.654,150.745 C91.923,151.835 91.257,152.937 90.168,153.206 C89.078,153.476 87.976,152.81 87.707,151.72" id="Fill-897" class="icon-color"/>
      <path d="M87.425,144.825 C87.245,143.58 88.11,142.424 89.355,142.245 C90.601,142.065 91.756,142.93 91.935,144.175 C92.115,145.421 91.251,146.576 90.005,146.755 C88.76,146.935 87.604,146.071 87.425,144.825" id="Fill-898" class="icon-color"/>
      <path d="M87.143,137.93 C87.053,136.528 88.117,135.32 89.518,135.23 C90.919,135.14 92.128,136.204 92.217,137.605 C92.307,139.006 91.244,140.215 89.843,140.304 C88.442,140.394 87.233,139.331 87.143,137.93" id="Fill-899" class="icon-color"/>
      <path d="M92.499,131.034 C92.499,132.591 91.237,133.853 89.68,133.853 C88.123,133.853 86.861,132.591 86.861,131.034 C86.861,129.477 88.123,128.215 89.68,128.215 C91.237,128.215 92.499,129.477 92.499,131.034" id="Fill-900" class="icon-color"/>
      <path d="M87.306,124.301 C87.306,122.99 88.369,121.927 89.68,121.927 C90.992,121.927 92.055,122.99 92.055,124.301 C92.055,125.613 90.992,126.676 89.68,126.676 C88.369,126.676 87.306,125.613 87.306,124.301" id="Fill-901" class="icon-color"/>
      <path d="M88.194,110.835 C88.194,110.015 88.86,109.35 89.68,109.35 C90.501,109.35 91.166,110.015 91.166,110.835 C91.166,111.656 90.501,112.321 89.68,112.321 C88.86,112.321 88.194,111.656 88.194,110.835" id="Fill-902" class="icon-color"/>
      <path d="M88.639,104.103 C88.639,103.527 89.105,103.061 89.68,103.061 C90.255,103.061 90.722,103.527 90.722,104.103 C90.722,104.678 90.255,105.144 89.68,105.144 C89.105,105.144 88.639,104.678 88.639,104.103" id="Fill-903" class="icon-color"/>
      <path d="M89.083,97.37 C89.083,97.04 89.35,96.772 89.68,96.772 C90.01,96.772 90.277,97.04 90.277,97.37 C90.277,97.699 90.01,97.967 89.68,97.967 C89.35,97.967 89.083,97.699 89.083,97.37" id="Fill-904" class="icon-color"/>
      <path d="M89.527,90.637 C89.527,90.552 89.596,90.484 89.68,90.484 C89.764,90.484 89.833,90.552 89.833,90.637 C89.833,90.721 89.764,90.789 89.68,90.789 C89.596,90.789 89.527,90.721 89.527,90.637" id="Fill-905" class="icon-color"/>
      <path d="M89.972,83.904 C89.972,84.065 89.841,84.196 89.68,84.196 C89.519,84.196 89.388,84.065 89.388,83.904 C89.388,83.743 89.519,83.612 89.68,83.612 C89.841,83.612 89.972,83.743 89.972,83.904" id="Fill-906" class="icon-color"/>
      <path d="M90.416,77.171 C90.416,77.577 90.087,77.907 89.68,77.907 C89.274,77.907 88.944,77.577 88.944,77.171 C88.944,76.764 89.274,76.435 89.68,76.435 C90.087,76.435 90.416,76.764 90.416,77.171" id="Fill-907" class="icon-color"/>
      <path d="M90.861,70.438 C90.861,71.09 90.332,71.618 89.68,71.618 C89.028,71.618 88.5,71.09 88.5,70.438 C88.5,69.786 89.028,69.257 89.68,69.257 C90.332,69.257 90.861,69.786 90.861,70.438" id="Fill-908" class="icon-color"/>
      <path d="M91.305,63.705 C91.305,64.602 90.578,65.33 89.68,65.33 C88.783,65.33 88.055,64.602 88.055,63.705 C88.055,62.807 88.783,62.08 89.68,62.08 C90.578,62.08 91.305,62.807 91.305,63.705" id="Fill-909" class="icon-color"/>
      <path d="M87.75,117.568 C87.75,116.502 88.614,115.638 89.68,115.638 C90.746,115.638 91.61,116.502 91.61,117.568 C91.61,118.634 90.746,119.499 89.68,119.499 C88.614,119.499 87.75,118.634 87.75,117.568" id="Fill-910" class="icon-color"/>
      <path d="M94.721,62.352 C94.721,61.49 95.42,60.791 96.282,60.791 C97.144,60.791 97.842,61.49 97.842,62.352 C97.842,63.214 97.144,63.912 96.282,63.912 C95.42,63.912 94.721,63.214 94.721,62.352" id="Fill-911" class="icon-color"/>
      <path d="M96.552,67.413 C97.328,67.563 97.836,68.313 97.686,69.088 C97.537,69.864 96.787,70.372 96.011,70.222 C95.236,70.073 94.728,69.323 94.877,68.547 C95.027,67.771 95.777,67.264 96.552,67.413" id="Fill-912" class="icon-color"/>
      <path d="M96.823,74.035 C97.513,74.334 97.829,75.135 97.53,75.825 C97.231,76.514 96.43,76.831 95.74,76.532 C95.051,76.233 94.735,75.431 95.034,74.742 C95.333,74.053 96.134,73.736 96.823,74.035" id="Fill-913" class="icon-color"/>
      <path d="M97.094,80.657 C97.697,81.106 97.823,81.958 97.374,82.561 C96.926,83.165 96.073,83.29 95.47,82.842 C94.867,82.393 94.741,81.54 95.189,80.937 C95.638,80.334 96.491,80.208 97.094,80.657" id="Fill-914" class="icon-color"/>
      <path d="M97.365,87.279 C97.882,87.877 97.816,88.781 97.218,89.298 C96.62,89.815 95.716,89.749 95.199,89.151 C94.682,88.553 94.748,87.649 95.346,87.132 C95.944,86.615 96.848,86.681 97.365,87.279" id="Fill-915" class="icon-color"/>
      <path d="M97.636,93.901 C98.066,94.648 97.81,95.604 97.062,96.035 C96.315,96.465 95.359,96.209 94.928,95.461 C94.497,94.714 94.754,93.758 95.502,93.327 C96.249,92.896 97.204,93.153 97.636,93.901" id="Fill-916" class="icon-color"/>
      <path d="M97.906,100.523 C98.251,101.42 97.803,102.426 96.906,102.771 C96.009,103.116 95.002,102.668 94.658,101.771 C94.313,100.874 94.761,99.867 95.658,99.522 C96.555,99.178 97.561,99.626 97.906,100.523" id="Fill-917" class="icon-color"/>
      <path d="M98.177,107.144 C98.435,108.191 97.796,109.249 96.75,109.508 C95.703,109.766 94.645,109.127 94.387,108.081 C94.128,107.034 94.767,105.976 95.814,105.718 C96.86,105.459 97.919,106.098 98.177,107.144" id="Fill-918" class="icon-color"/>
      <path d="M98.448,113.766 C98.62,114.962 97.79,116.072 96.594,116.244 C95.398,116.416 94.289,115.587 94.116,114.391 C93.944,113.194 94.774,112.085 95.97,111.913 C97.166,111.74 98.275,112.57 98.448,113.766" id="Fill-919" class="icon-color"/>
      <path d="M98.718,120.388 C98.804,121.734 97.784,122.895 96.438,122.981 C95.092,123.067 93.932,122.046 93.845,120.7 C93.759,119.355 94.78,118.194 96.126,118.108 C97.471,118.022 98.632,119.042 98.718,120.388" id="Fill-920" class="icon-color"/>
      <path d="M98.562,133.614 C98.562,134.874 97.541,135.895 96.282,135.895 C95.022,135.895 94.001,134.874 94.001,133.614 C94.001,132.355 95.022,131.334 96.282,131.334 C97.541,131.334 98.562,132.355 98.562,133.614" id="Fill-921" class="icon-color"/>
      <path d="M98.136,140.218 C98.136,141.242 97.306,142.072 96.282,142.072 C95.258,142.072 94.428,141.242 94.428,140.218 C94.428,139.194 95.258,138.365 96.282,138.365 C97.306,138.365 98.136,139.194 98.136,140.218" id="Fill-922" class="icon-color"/>
      <path d="M97.709,146.822 C97.709,147.61 97.07,148.249 96.282,148.249 C95.494,148.249 94.855,147.61 94.855,146.822 C94.855,146.034 95.494,145.396 96.282,145.396 C97.07,145.396 97.709,146.034 97.709,146.822" id="Fill-923" class="icon-color"/>
      <path d="M97.282,153.427 C97.282,153.979 96.834,154.427 96.282,154.427 C95.73,154.427 95.282,153.979 95.282,153.427 C95.282,152.874 95.73,152.426 96.282,152.426 C96.834,152.426 97.282,152.874 97.282,153.427" id="Fill-924" class="icon-color"/>
      <path d="M96.855,160.031 C96.855,160.347 96.599,160.604 96.282,160.604 C95.965,160.604 95.708,160.347 95.708,160.031 C95.708,159.714 95.965,159.457 96.282,159.457 C96.599,159.457 96.855,159.714 96.855,160.031" id="Fill-925" class="icon-color"/>
      <path d="M96.428,166.635 C96.428,166.716 96.363,166.781 96.282,166.781 C96.201,166.781 96.135,166.716 96.135,166.635 C96.135,166.554 96.201,166.488 96.282,166.488 C96.363,166.488 96.428,166.554 96.428,166.635" id="Fill-926" class="icon-color"/>
      <path d="M96.002,173.239 C96.002,173.084 96.127,172.959 96.282,172.959 C96.437,172.959 96.562,173.084 96.562,173.239 C96.562,173.394 96.437,173.519 96.282,173.519 C96.127,173.519 96.002,173.394 96.002,173.239" id="Fill-927" class="icon-color"/>
      <path d="M95.575,179.843 C95.575,179.453 95.892,179.136 96.282,179.136 C96.672,179.136 96.989,179.453 96.989,179.843 C96.989,180.234 96.672,180.55 96.282,180.55 C95.892,180.55 95.575,180.234 95.575,179.843" id="Fill-928" class="icon-color"/>
      <path d="M95.148,186.447 C95.148,185.821 95.656,185.314 96.282,185.314 C96.908,185.314 97.416,185.821 97.416,186.447 C97.416,187.073 96.908,187.581 96.282,187.581 C95.656,187.581 95.148,187.073 95.148,186.447" id="Fill-929" class="icon-color"/>
      <path d="M94.721,193.051 C94.721,192.189 95.42,191.491 96.282,191.491 C97.144,191.491 97.842,192.189 97.842,193.051 C97.842,193.913 97.144,194.612 96.282,194.612 C95.42,194.612 94.721,193.913 94.721,193.051" id="Fill-930" class="icon-color"/>
      <path d="M93.575,127.01 C93.575,125.515 94.787,124.303 96.282,124.303 C97.777,124.303 98.989,125.515 98.989,127.01 C98.989,128.505 97.777,129.717 96.282,129.717 C94.787,129.717 93.575,128.505 93.575,127.01" id="Fill-931" class="icon-color"/>
      <path d="M105.23,95.156 C105.23,96.563 104.09,97.703 102.683,97.703 C101.276,97.703 100.136,96.563 100.136,95.156 C100.136,93.749 101.276,92.609 102.683,92.609 C104.09,92.609 105.23,93.749 105.23,95.156" id="Fill-932" class="icon-color"/>
      <path d="M102.753,90.998 C101.464,91.037 100.387,90.023 100.348,88.733 C100.31,87.444 101.324,86.367 102.613,86.328 C103.903,86.29 104.979,87.304 105.018,88.593 C105.057,89.883 104.043,90.959 102.753,90.998" id="Fill-933" class="icon-color"/>
      <path d="M102.766,84.293 C101.593,84.37 100.58,83.482 100.503,82.31 C100.426,81.138 101.313,80.125 102.486,80.047 C103.658,79.97 104.671,80.858 104.748,82.03 C104.826,83.202 103.938,84.215 102.766,84.293" id="Fill-934" class="icon-color"/>
      <path d="M102.809,77.587 C101.754,77.704 100.805,76.942 100.689,75.887 C100.573,74.832 101.334,73.883 102.389,73.767 C103.444,73.651 104.393,74.412 104.509,75.467 C104.626,76.522 103.864,77.471 102.809,77.587" id="Fill-935" class="icon-color"/>
      <path d="M102.853,70.882 C101.915,71.037 101.029,70.402 100.874,69.464 C100.72,68.526 101.354,67.641 102.293,67.486 C103.23,67.331 104.116,67.966 104.271,68.904 C104.425,69.842 103.79,70.728 102.853,70.882" id="Fill-936" class="icon-color"/>
      <path d="M102.896,64.177 C102.075,64.37 101.253,63.862 101.06,63.041 C100.867,62.221 101.375,61.399 102.196,61.205 C103.017,61.012 103.839,61.52 104.032,62.341 C104.225,63.162 103.717,63.984 102.896,64.177" id="Fill-937" class="icon-color"/>
      <path d="M102.956,57.472 C102.253,57.704 101.494,57.322 101.262,56.618 C101.03,55.915 101.412,55.157 102.116,54.925 C102.819,54.693 103.577,55.075 103.809,55.778 C104.041,56.481 103.659,57.24 102.956,57.472" id="Fill-938" class="icon-color"/>
      <path d="M103.05,50.767 C102.464,51.037 101.77,50.781 101.499,50.195 C101.229,49.609 101.484,48.915 102.07,48.644 C102.657,48.373 103.351,48.629 103.622,49.215 C103.893,49.801 103.637,50.496 103.05,50.767" id="Fill-939" class="icon-color"/>
      <path d="M103.145,44.061 C102.676,44.371 102.045,44.241 101.736,43.772 C101.427,43.303 101.556,42.673 102.025,42.363 C102.494,42.054 103.125,42.183 103.434,42.652 C103.743,43.121 103.614,43.752 103.145,44.061" id="Fill-940" class="icon-color"/>
      <path d="M103.24,37.356 C102.888,37.704 102.321,37.701 101.973,37.349 C101.625,36.998 101.628,36.43 101.979,36.082 C102.331,35.734 102.898,35.737 103.246,36.089 C103.594,36.441 103.591,37.008 103.24,37.356" id="Fill-941" class="icon-color"/>
      <path d="M103.383,30.651 C103.149,31.037 102.645,31.161 102.258,30.926 C101.872,30.692 101.749,30.188 101.983,29.802 C102.217,29.415 102.721,29.292 103.108,29.526 C103.494,29.761 103.618,30.264 103.383,30.651" id="Fill-942" class="icon-color"/>
      <path d="M103.453,23.946 C103.336,24.371 102.896,24.621 102.471,24.503 C102.046,24.386 101.796,23.946 101.913,23.521 C102.03,23.096 102.47,22.846 102.895,22.963 C103.321,23.08 103.571,23.52 103.453,23.946" id="Fill-943" class="icon-color"/>
      <path d="M103.523,17.24 C103.523,17.704 103.147,18.08 102.683,18.08 C102.219,18.08 101.843,17.704 101.843,17.24 C101.843,16.776 102.219,16.4 102.683,16.4 C103.147,16.4 103.523,16.776 103.523,17.24" id="Fill-944" class="icon-color"/>
      <path d="M102.083,154.599 C102.083,154.131 102.462,153.752 102.93,153.752 C103.398,153.752 103.777,154.131 103.777,154.599 C103.777,155.066 103.398,155.446 102.93,155.446 C102.462,155.446 102.083,155.066 102.083,154.599" id="Fill-945" class="icon-color"/>
      <path d="M101.821,147.984 C101.821,147.372 102.317,146.875 102.93,146.875 C103.543,146.875 104.04,147.372 104.04,147.984 C104.04,148.597 103.543,149.094 102.93,149.094 C102.317,149.094 101.821,148.597 101.821,147.984" id="Fill-946" class="icon-color"/>
      <path d="M101.558,141.37 C101.558,140.612 102.173,139.998 102.93,139.998 C103.688,139.998 104.302,140.612 104.302,141.37 C104.302,142.128 103.688,142.742 102.93,142.742 C102.173,142.742 101.558,142.128 101.558,141.37" id="Fill-947" class="icon-color"/>
      <path d="M101.296,134.756 C101.296,133.853 102.028,133.121 102.93,133.121 C103.833,133.121 104.565,133.853 104.565,134.756 C104.565,135.659 103.833,136.391 102.93,136.391 C102.028,136.391 101.296,135.659 101.296,134.756" id="Fill-948" class="icon-color"/>
      <path d="M101.033,128.142 C101.033,127.094 101.882,126.245 102.93,126.245 C103.978,126.245 104.827,127.094 104.827,128.142 C104.827,129.189 103.978,130.039 102.93,130.039 C101.882,130.039 101.033,129.189 101.033,128.142" id="Fill-949" class="icon-color"/>
      <path d="M100.771,121.528 C100.771,120.335 101.738,119.368 102.93,119.368 C104.123,119.368 105.09,120.335 105.09,121.528 C105.09,122.72 104.123,123.687 102.93,123.687 C101.738,123.687 100.771,122.72 100.771,121.528" id="Fill-950" class="icon-color"/>
      <path d="M100.508,114.913 C100.508,113.576 101.593,112.491 102.93,112.491 C104.268,112.491 105.353,113.576 105.353,114.913 C105.353,116.251 104.268,117.335 102.93,117.335 C101.593,117.335 100.508,116.251 100.508,114.913" id="Fill-951" class="icon-color"/>
      <path d="M99.983,101.685 C99.983,100.057 101.303,98.738 102.93,98.738 C104.558,98.738 105.878,100.057 105.878,101.685 C105.878,103.313 104.558,104.632 102.93,104.632 C101.303,104.632 99.983,103.313 99.983,101.685" id="Fill-952" class="icon-color"/>
      <path d="M100.246,108.299 C100.246,106.816 101.448,105.614 102.93,105.614 C104.413,105.614 105.615,106.816 105.615,108.299 C105.615,109.782 104.413,110.984 102.93,110.984 C101.448,110.984 100.246,109.782 100.246,108.299" id="Fill-953" class="icon-color"/>
      <path d="M81.727,56.556 C81.727,55.694 82.426,54.995 83.288,54.995 C84.149,54.995 84.848,55.694 84.848,56.556 C84.848,57.418 84.149,58.116 83.288,58.116 C82.426,58.116 81.727,57.418 81.727,56.556" id="Fill-954" class="icon-color"/>
      <path d="M83.558,63.007 C84.334,63.157 84.841,63.906 84.692,64.682 C84.542,65.458 83.792,65.965 83.017,65.816 C82.241,65.666 81.733,64.916 81.883,64.141 C82.033,63.365 82.782,62.858 83.558,63.007" id="Fill-955" class="icon-color"/>
      <path d="M83.829,71.019 C84.518,71.318 84.835,72.119 84.536,72.809 C84.237,73.498 83.436,73.815 82.746,73.516 C82.057,73.217 81.74,72.415 82.039,71.726 C82.338,71.036 83.139,70.72 83.829,71.019" id="Fill-956" class="icon-color"/>
      <path d="M84.1,79.031 C84.703,79.479 84.828,80.332 84.38,80.935 C83.931,81.538 83.079,81.664 82.475,81.215 C81.872,80.767 81.747,79.914 82.195,79.311 C82.644,78.708 83.496,78.582 84.1,79.031" id="Fill-957" class="icon-color"/>
      <path d="M84.37,87.042 C84.887,87.64 84.822,88.544 84.224,89.062 C83.626,89.579 82.722,89.513 82.205,88.915 C81.688,88.317 81.753,87.413 82.351,86.896 C82.949,86.379 83.853,86.444 84.37,87.042" id="Fill-958" class="icon-color"/>
      <path d="M84.641,95.054 C85.072,95.802 84.815,96.757 84.068,97.188 C83.32,97.619 82.365,97.362 81.934,96.615 C81.503,95.867 81.76,94.912 82.507,94.481 C83.255,94.05 84.21,94.307 84.641,95.054" id="Fill-959" class="icon-color"/>
      <path d="M84.912,103.066 C85.256,103.963 84.809,104.97 83.912,105.314 C83.015,105.659 82.008,105.211 81.663,104.314 C81.318,103.417 81.766,102.411 82.663,102.066 C83.56,101.721 84.567,102.169 84.912,103.066" id="Fill-960" class="icon-color"/>
      <path d="M85.182,111.078 C85.441,112.124 84.802,113.182 83.756,113.441 C82.709,113.699 81.651,113.061 81.392,112.014 C81.134,110.967 81.773,109.91 82.819,109.651 C83.866,109.392 84.924,110.031 85.182,111.078" id="Fill-961" class="icon-color"/>
      <path d="M85.453,119.089 C85.626,120.286 84.796,121.395 83.6,121.567 C82.404,121.74 81.294,120.91 81.122,119.714 C80.949,118.518 81.779,117.408 82.975,117.236 C84.171,117.064 85.281,117.893 85.453,119.089" id="Fill-962" class="icon-color"/>
      <path d="M85.724,127.101 C85.81,128.447 84.789,129.607 83.443,129.694 C82.098,129.78 80.937,128.759 80.851,127.413 C80.765,126.068 81.786,124.907 83.131,124.821 C84.477,124.735 85.638,125.756 85.724,127.101" id="Fill-963" class="icon-color"/>
      <path d="M80.58,135.113 C80.58,133.618 81.792,132.406 83.288,132.406 C84.783,132.406 85.995,133.618 85.995,135.113 C85.995,136.608 84.783,137.82 83.288,137.82 C81.792,137.82 80.58,136.608 80.58,135.113" id="Fill-964" class="icon-color"/>
      <path d="M85.568,142.627 C85.568,143.886 84.547,144.907 83.288,144.907 C82.028,144.907 81.007,143.886 81.007,142.627 C81.007,141.368 82.028,140.347 83.288,140.347 C84.547,140.347 85.568,141.368 85.568,142.627" id="Fill-965" class="icon-color"/>
      <path d="M85.141,150.141 C85.141,151.165 84.311,151.995 83.288,151.995 C82.264,151.995 81.434,151.165 81.434,150.141 C81.434,149.117 82.264,148.288 83.288,148.288 C84.311,148.288 85.141,149.117 85.141,150.141" id="Fill-966" class="icon-color"/>
      <path d="M84.714,157.655 C84.714,158.443 84.076,159.082 83.288,159.082 C82.499,159.082 81.861,158.443 81.861,157.655 C81.861,156.867 82.499,156.228 83.288,156.228 C84.076,156.228 84.714,156.867 84.714,157.655" id="Fill-967" class="icon-color"/>
      <path d="M84.288,165.169 C84.288,165.722 83.84,166.169 83.288,166.169 C82.735,166.169 82.287,165.722 82.287,165.169 C82.287,164.617 82.735,164.169 83.288,164.169 C83.84,164.169 84.288,164.617 84.288,165.169" id="Fill-968" class="icon-color"/>
      <path d="M83.861,172.683 C83.861,173 83.604,173.257 83.288,173.257 C82.971,173.257 82.714,173 82.714,172.683 C82.714,172.367 82.971,172.11 83.288,172.11 C83.604,172.11 83.861,172.367 83.861,172.683" id="Fill-969" class="icon-color"/>
      <path d="M83.434,180.197 C83.434,180.278 83.368,180.344 83.288,180.344 C83.206,180.344 83.141,180.278 83.141,180.197 C83.141,180.116 83.206,180.051 83.288,180.051 C83.368,180.051 83.434,180.116 83.434,180.197" id="Fill-970" class="icon-color"/>
      <path d="M83.007,187.711 C83.007,187.557 83.133,187.431 83.288,187.431 C83.442,187.431 83.567,187.557 83.567,187.711 C83.567,187.866 83.442,187.991 83.288,187.991 C83.133,187.991 83.007,187.866 83.007,187.711" id="Fill-971" class="icon-color"/>
      <path d="M82.581,195.226 C82.581,194.835 82.897,194.519 83.288,194.519 C83.678,194.519 83.994,194.835 83.994,195.226 C83.994,195.616 83.678,195.932 83.288,195.932 C82.897,195.932 82.581,195.616 82.581,195.226" id="Fill-972" class="icon-color"/>
      <path d="M82.154,202.74 C82.154,202.113 82.661,201.606 83.288,201.606 C83.914,201.606 84.421,202.113 84.421,202.74 C84.421,203.366 83.914,203.873 83.288,203.873 C82.661,203.873 82.154,203.366 82.154,202.74" id="Fill-973" class="icon-color"/>
      <path d="M81.727,210.254 C81.727,209.392 82.426,208.693 83.288,208.693 C84.149,208.693 84.848,209.392 84.848,210.254 C84.848,211.115 84.149,211.814 83.288,211.814 C82.426,211.814 81.727,211.115 81.727,210.254" id="Fill-974" class="icon-color"/>
      <path d="M65.445,182.937 C65.445,183.777 64.764,184.458 63.923,184.458 C63.083,184.458 62.402,183.777 62.402,182.937 C62.402,182.097 63.083,181.415 63.923,181.415 C64.764,181.415 65.445,182.097 65.445,182.937" id="Fill-975" class="icon-color"/>
      <path d="M63.63,176.231 C62.883,176.069 62.409,175.333 62.571,174.586 C62.733,173.839 63.47,173.365 64.217,173.527 C64.964,173.689 65.437,174.426 65.276,175.172 C65.114,175.919 64.377,176.393 63.63,176.231" id="Fill-976" class="icon-color"/>
      <path d="M63.337,168.004 C62.684,167.681 62.416,166.888 62.74,166.235 C63.064,165.582 63.856,165.314 64.51,165.638 C65.163,165.962 65.43,166.754 65.107,167.408 C64.783,168.061 63.99,168.328 63.337,168.004" id="Fill-977" class="icon-color"/>
      <path d="M63.044,159.778 C62.484,159.292 62.423,158.444 62.909,157.884 C63.395,157.324 64.243,157.264 64.803,157.75 C65.363,158.235 65.423,159.083 64.937,159.643 C64.452,160.204 63.604,160.264 63.044,159.778" id="Fill-978" class="icon-color"/>
      <path d="M62.751,151.551 C62.284,150.903 62.431,150 63.078,149.533 C63.726,149.066 64.63,149.213 65.096,149.861 C65.563,150.509 65.416,151.412 64.768,151.879 C64.121,152.346 63.217,152.199 62.751,151.551" id="Fill-979" class="icon-color"/>
      <path d="M62.457,143.325 C62.084,142.515 62.438,141.556 63.247,141.182 C64.057,140.809 65.016,141.163 65.39,141.972 C65.763,142.782 65.409,143.741 64.6,144.115 C63.79,144.488 62.831,144.134 62.457,143.325" id="Fill-980" class="icon-color"/>
      <path d="M62.164,135.098 C61.884,134.126 62.445,133.112 63.416,132.832 C64.388,132.551 65.403,133.112 65.683,134.084 C65.963,135.055 65.402,136.07 64.43,136.35 C63.459,136.63 62.444,136.07 62.164,135.098" id="Fill-981" class="icon-color"/>
      <path d="M61.578,118.645 C61.484,117.349 62.459,116.223 63.754,116.13 C65.05,116.037 66.176,117.011 66.269,118.307 C66.363,119.602 65.388,120.728 64.093,120.821 C62.797,120.915 61.671,119.94 61.578,118.645" id="Fill-982" class="icon-color"/>
      <path d="M66.562,110.418 C66.562,111.875 65.381,113.057 63.923,113.057 C62.466,113.057 61.284,111.875 61.284,110.418 C61.284,108.96 62.466,107.779 63.923,107.779 C65.381,107.779 66.562,108.96 66.562,110.418" id="Fill-983" class="icon-color"/>
      <path d="M61.747,103.415 C61.747,102.212 62.721,101.238 63.923,101.238 C65.126,101.238 66.1,102.212 66.1,103.415 C66.1,104.617 65.126,105.591 63.923,105.591 C62.721,105.591 61.747,104.617 61.747,103.415" id="Fill-984" class="icon-color"/>
      <path d="M62.209,96.411 C62.209,95.464 62.977,94.696 63.923,94.696 C64.87,94.696 65.638,95.464 65.638,96.411 C65.638,97.358 64.87,98.126 63.923,98.126 C62.977,98.126 62.209,97.358 62.209,96.411" id="Fill-985" class="icon-color"/>
      <path d="M62.671,89.407 C62.671,88.716 63.232,88.155 63.923,88.155 C64.615,88.155 65.176,88.716 65.176,89.407 C65.176,90.099 64.615,90.66 63.923,90.66 C63.232,90.66 62.671,90.099 62.671,89.407" id="Fill-986" class="icon-color"/>
      <path d="M63.133,82.404 C63.133,81.968 63.487,81.614 63.923,81.614 C64.36,81.614 64.713,81.968 64.713,82.404 C64.713,82.84 64.36,83.194 63.923,83.194 C63.487,83.194 63.133,82.84 63.133,82.404" id="Fill-987" class="icon-color"/>
      <path d="M63.596,75.401 C63.596,75.22 63.742,75.073 63.923,75.073 C64.104,75.073 64.251,75.22 64.251,75.401 C64.251,75.582 64.104,75.729 63.923,75.729 C63.742,75.729 63.596,75.582 63.596,75.401" id="Fill-988" class="icon-color"/>
      <path d="M64.058,68.397 C64.058,68.471 63.998,68.531 63.923,68.531 C63.849,68.531 63.789,68.471 63.789,68.397 C63.789,68.323 63.849,68.263 63.923,68.263 C63.998,68.263 64.058,68.323 64.058,68.397" id="Fill-989" class="icon-color"/>
      <path d="M64.52,61.394 C64.52,61.723 64.253,61.99 63.923,61.99 C63.594,61.99 63.327,61.723 63.327,61.394 C63.327,61.064 63.594,60.797 63.923,60.797 C64.253,60.797 64.52,61.064 64.52,61.394" id="Fill-990" class="icon-color"/>
      <path d="M64.982,54.39 C64.982,54.975 64.508,55.449 63.923,55.449 C63.339,55.449 62.865,54.975 62.865,54.39 C62.865,53.805 63.339,53.331 63.923,53.331 C64.508,53.331 64.982,53.805 64.982,54.39" id="Fill-991" class="icon-color"/>
      <path d="M65.445,47.387 C65.445,48.227 64.764,48.908 63.923,48.908 C63.083,48.908 62.402,48.227 62.402,47.387 C62.402,46.547 63.083,45.866 63.923,45.866 C64.764,45.866 65.445,46.547 65.445,47.387" id="Fill-992" class="icon-color"/>
      <path d="M61.871,126.871 C61.684,125.738 62.452,124.667 63.585,124.481 C64.719,124.294 65.789,125.062 65.976,126.195 C66.163,127.329 65.395,128.399 64.261,128.586 C63.128,128.773 62.057,128.005 61.871,126.871" id="Fill-993" class="icon-color"/>
      <path d="M67.934,114.882 C67.934,113.432 69.109,112.257 70.559,112.257 C72.009,112.257 73.184,113.432 73.184,114.882 C73.184,116.332 72.009,117.507 70.559,117.507 C69.109,117.507 67.934,116.332 67.934,114.882" id="Fill-994" class="icon-color"/>
      <path d="M70.471,119.897 C71.776,119.849 72.873,120.868 72.921,122.173 C72.968,123.478 71.949,124.574 70.644,124.622 C69.339,124.67 68.243,123.651 68.195,122.346 C68.147,121.041 69.166,119.944 70.471,119.897" id="Fill-995" class="icon-color"/>
      <path d="M70.446,127.537 C71.606,127.441 72.624,128.303 72.719,129.463 C72.815,130.623 71.952,131.641 70.792,131.737 C69.632,131.832 68.615,130.969 68.519,129.81 C68.423,128.65 69.286,127.632 70.446,127.537" id="Fill-996" class="icon-color"/>
      <path d="M70.389,135.176 C71.404,135.033 72.343,135.739 72.487,136.754 C72.63,137.769 71.924,138.708 70.909,138.851 C69.894,138.995 68.955,138.288 68.812,137.273 C68.668,136.259 69.375,135.32 70.389,135.176" id="Fill-997" class="icon-color"/>
      <path d="M70.333,142.816 C71.203,142.625 72.063,143.175 72.254,144.045 C72.446,144.915 71.896,145.775 71.026,145.966 C70.156,146.158 69.295,145.607 69.104,144.738 C68.913,143.868 69.463,143.007 70.333,142.816" id="Fill-998" class="icon-color"/>
      <path d="M70.276,150.456 C71.001,150.217 71.783,150.611 72.022,151.336 C72.261,152.061 71.867,152.842 71.142,153.081 C70.418,153.32 69.636,152.926 69.397,152.202 C69.158,151.476 69.552,150.695 70.276,150.456" id="Fill-999" class="icon-color"/>
      <path d="M70.166,158.096 C70.746,157.809 71.448,158.046 71.735,158.626 C72.022,159.206 71.785,159.909 71.205,160.196 C70.625,160.483 69.922,160.245 69.635,159.665 C69.348,159.086 69.586,158.383 70.166,158.096" id="Fill-1000" class="icon-color"/>
      <path d="M70.045,165.736 C70.48,165.401 71.104,165.482 71.439,165.917 C71.774,166.352 71.692,166.976 71.257,167.311 C70.822,167.646 70.199,167.564 69.864,167.129 C69.529,166.694 69.61,166.071 70.045,165.736" id="Fill-1001" class="icon-color"/>
      <path d="M69.925,173.375 C70.215,172.993 70.76,172.918 71.142,173.208 C71.525,173.498 71.6,174.043 71.31,174.426 C71.02,174.808 70.475,174.883 70.092,174.593 C69.71,174.303 69.635,173.758 69.925,173.375" id="Fill-1002" class="icon-color"/>
      <path d="M69.771,181.015 C69.916,180.585 70.382,180.354 70.813,180.499 C71.243,180.644 71.475,181.11 71.33,181.54 C71.185,181.971 70.718,182.202 70.288,182.057 C69.857,181.912 69.626,181.446 69.771,181.015" id="Fill-1003" class="icon-color"/>
      <path d="M69.684,188.655 C69.684,188.177 70.071,187.789 70.55,187.789 C71.028,187.789 71.415,188.177 71.415,188.655 C71.415,189.133 71.028,189.521 70.55,189.521 C70.071,189.521 69.684,189.133 69.684,188.655" id="Fill-1004" class="icon-color"/>
      <path d="M77.093,135.802 C78.306,135.765 79.319,136.719 79.355,137.933 C79.392,139.146 78.438,140.159 77.224,140.195 C76.011,140.232 74.998,139.278 74.962,138.064 C74.925,136.851 75.879,135.838 77.093,135.802" id="Fill-1005" class="icon-color"/>
      <path d="M77.081,142.169 C78.184,142.096 79.137,142.931 79.21,144.034 C79.283,145.137 78.448,146.09 77.345,146.163 C76.242,146.236 75.289,145.4 75.216,144.297 C75.143,143.194 75.979,142.241 77.081,142.169" id="Fill-1006" class="icon-color"/>
      <path d="M77.041,148.536 C78.033,148.426 78.927,149.143 79.036,150.135 C79.145,151.128 78.429,152.021 77.436,152.131 C76.443,152.24 75.55,151.523 75.441,150.531 C75.332,149.538 76.048,148.645 77.041,148.536" id="Fill-1007" class="icon-color"/>
      <path d="M77,154.903 C77.883,154.757 78.716,155.355 78.861,156.237 C79.007,157.119 78.409,157.952 77.527,158.098 C76.645,158.244 75.811,157.646 75.666,156.764 C75.52,155.881 76.118,155.048 77,154.903" id="Fill-1008" class="icon-color"/>
      <path d="M76.96,161.27 C77.732,161.088 78.505,161.566 78.687,162.338 C78.869,163.11 78.39,163.884 77.618,164.066 C76.846,164.248 76.073,163.769 75.891,162.997 C75.709,162.225 76.187,161.452 76.96,161.27" id="Fill-1009" class="icon-color"/>
      <path d="M76.901,167.637 C77.563,167.418 78.276,167.778 78.494,168.44 C78.713,169.102 78.353,169.815 77.691,170.033 C77.029,170.252 76.316,169.892 76.098,169.23 C75.879,168.569 76.239,167.855 76.901,167.637" id="Fill-1010" class="icon-color"/>
      <path d="M76.812,174.004 C77.364,173.749 78.017,173.99 78.272,174.541 C78.526,175.093 78.286,175.746 77.734,176.001 C77.183,176.256 76.529,176.015 76.275,175.463 C76.02,174.912 76.26,174.258 76.812,174.004" id="Fill-1011" class="icon-color"/>
      <path d="M76.723,180.371 C77.164,180.08 77.758,180.202 78.049,180.643 C78.34,181.084 78.218,181.677 77.777,181.969 C77.336,182.26 76.742,182.138 76.451,181.697 C76.16,181.256 76.282,180.662 76.723,180.371" id="Fill-1012" class="icon-color"/>
      <path d="M76.634,186.738 C76.965,186.41 77.499,186.413 77.826,186.744 C78.154,187.075 78.151,187.609 77.82,187.936 C77.489,188.263 76.956,188.261 76.628,187.93 C76.301,187.599 76.304,187.065 76.634,186.738" id="Fill-1013" class="icon-color"/>
      <path d="M76.5,193.105 C76.72,192.741 77.194,192.625 77.558,192.845 C77.922,193.066 78.038,193.54 77.817,193.904 C77.597,194.267 77.123,194.383 76.759,194.163 C76.395,193.942 76.279,193.469 76.5,193.105" id="Fill-1014" class="icon-color"/>
      <path d="M76.434,199.472 C76.544,199.072 76.958,198.837 77.358,198.947 C77.758,199.057 77.993,199.471 77.883,199.871 C77.773,200.271 77.359,200.506 76.959,200.396 C76.559,200.286 76.324,199.872 76.434,199.472" id="Fill-1015" class="icon-color"/>
      <path d="M76.368,205.839 C76.368,205.402 76.722,205.049 77.159,205.049 C77.595,205.049 77.949,205.402 77.949,205.839 C77.949,206.275 77.595,206.629 77.159,206.629 C76.722,206.629 76.368,206.275 76.368,205.839" id="Fill-1016" class="icon-color"/>
      <path d="M77.723,75.904 C77.723,76.344 77.366,76.701 76.926,76.701 C76.486,76.701 76.129,76.344 76.129,75.904 C76.129,75.464 76.486,75.107 76.926,75.107 C77.366,75.107 77.723,75.464 77.723,75.904" id="Fill-1017" class="icon-color"/>
      <path d="M77.97,82.127 C77.97,82.704 77.503,83.171 76.926,83.171 C76.349,83.171 75.882,82.704 75.882,82.127 C75.882,81.551 76.349,81.083 76.926,81.083 C77.503,81.083 77.97,81.551 77.97,82.127" id="Fill-1018" class="icon-color"/>
      <path d="M78.217,88.35 C78.217,89.063 77.639,89.641 76.926,89.641 C76.213,89.641 75.635,89.063 75.635,88.35 C75.635,87.637 76.213,87.059 76.926,87.059 C77.639,87.059 78.217,87.637 78.217,88.35" id="Fill-1019" class="icon-color"/>
      <path d="M78.464,94.573 C78.464,95.423 77.776,96.111 76.926,96.111 C76.077,96.111 75.388,95.423 75.388,94.573 C75.388,93.724 76.077,93.035 76.926,93.035 C77.776,93.035 78.464,93.724 78.464,94.573" id="Fill-1020" class="icon-color"/>
      <path d="M78.711,100.796 C78.711,101.782 77.912,102.581 76.926,102.581 C75.94,102.581 75.141,101.782 75.141,100.796 C75.141,99.811 75.94,99.011 76.926,99.011 C77.912,99.011 78.711,99.811 78.711,100.796" id="Fill-1021" class="icon-color"/>
      <path d="M78.958,107.02 C78.958,108.142 78.048,109.051 76.926,109.051 C75.804,109.051 74.894,108.142 74.894,107.02 C74.894,105.897 75.804,104.988 76.926,104.988 C78.048,104.988 78.958,105.897 78.958,107.02" id="Fill-1022" class="icon-color"/>
      <path d="M79.205,113.243 C79.205,114.501 78.185,115.521 76.926,115.521 C75.667,115.521 74.647,114.501 74.647,113.243 C74.647,111.984 75.667,110.964 76.926,110.964 C78.185,110.964 79.205,111.984 79.205,113.243" id="Fill-1023" class="icon-color"/>
      <path d="M79.452,119.466 C79.452,120.861 78.321,121.991 76.926,121.991 C75.531,121.991 74.4,120.861 74.4,119.466 C74.4,118.07 75.531,116.94 76.926,116.94 C78.321,116.94 79.452,118.07 79.452,119.466" id="Fill-1024" class="icon-color"/>
      <path d="M79.699,125.689 C79.699,127.22 78.457,128.461 76.926,128.461 C75.395,128.461 74.153,127.22 74.153,125.689 C74.153,124.157 75.395,122.916 76.926,122.916 C78.457,122.916 79.699,124.157 79.699,125.689" id="Fill-1025" class="icon-color"/>
      <path d="M74.762,131.831 C74.762,130.508 75.835,129.435 77.159,129.435 C78.482,129.435 79.555,130.508 79.555,131.831 C79.555,133.155 78.482,134.228 77.159,134.228 C75.835,134.228 74.762,133.155 74.762,131.831" id="Fill-1026" class="icon-color"/>
      <path d="M409.56,196.121 C409.56,197.018 408.833,197.746 407.935,197.746 C407.038,197.746 406.31,197.018 406.31,196.121 C406.31,195.223 407.038,194.496 407.935,194.496 C408.833,194.496 409.56,195.223 409.56,196.121" id="Fill-1027" class="icon-color"/>
      <path d="M407.654,190.85 C406.846,190.694 406.317,189.913 406.473,189.106 C406.629,188.298 407.41,187.77 408.217,187.925 C409.025,188.081 409.554,188.862 409.398,189.67 C409.242,190.477 408.461,191.006 407.654,190.85" id="Fill-1028" class="icon-color"/>
      <path d="M407.372,183.955 C406.654,183.643 406.324,182.809 406.636,182.091 C406.947,181.373 407.781,181.043 408.499,181.355 C409.217,181.666 409.547,182.501 409.235,183.219 C408.924,183.937 408.09,184.266 407.372,183.955" id="Fill-1029" class="icon-color"/>
      <path d="M407.09,177.059 C406.462,176.592 406.331,175.704 406.798,175.076 C407.265,174.448 408.153,174.317 408.781,174.784 C409.409,175.252 409.54,176.139 409.073,176.768 C408.606,177.396 407.718,177.526 407.09,177.059" id="Fill-1030" class="icon-color"/>
      <path d="M406.808,170.164 C406.27,169.541 406.338,168.6 406.96,168.061 C407.583,167.523 408.525,167.591 409.063,168.214 C409.602,168.837 409.533,169.778 408.91,170.317 C408.288,170.855 407.346,170.787 406.808,170.164" id="Fill-1031" class="icon-color"/>
      <path d="M406.526,163.269 C406.077,162.49 406.345,161.495 407.123,161.047 C407.901,160.598 408.896,160.865 409.345,161.644 C409.794,162.422 409.526,163.417 408.748,163.866 C407.969,164.314 406.975,164.047 406.526,163.269" id="Fill-1032" class="icon-color"/>
      <path d="M406.244,156.373 C405.885,155.439 406.351,154.391 407.285,154.032 C408.22,153.673 409.268,154.139 409.627,155.073 C409.986,156.007 409.52,157.056 408.585,157.415 C407.651,157.773 406.603,157.307 406.244,156.373" id="Fill-1033" class="icon-color"/>
      <path d="M405.962,149.478 C405.693,148.388 406.358,147.286 407.448,147.017 C408.538,146.748 409.639,147.413 409.909,148.503 C410.178,149.593 409.513,150.694 408.423,150.963 C407.333,151.233 406.232,150.568 405.962,149.478" id="Fill-1034" class="icon-color"/>
      <path d="M405.68,142.582 C405.501,141.337 406.365,140.181 407.611,140.002 C408.856,139.823 410.011,140.687 410.191,141.932 C410.37,143.178 409.506,144.333 408.26,144.512 C407.015,144.692 405.86,143.828 405.68,142.582" id="Fill-1035" class="icon-color"/>
      <path d="M405.398,135.687 C405.309,134.286 406.372,133.077 407.773,132.987 C409.174,132.898 410.383,133.961 410.473,135.362 C410.562,136.763 409.499,137.972 408.098,138.061 C406.697,138.151 405.488,137.088 405.398,135.687" id="Fill-1036" class="icon-color"/>
      <path d="M410.754,128.791 C410.754,130.348 409.492,131.61 407.935,131.61 C406.379,131.61 405.116,130.348 405.116,128.791 C405.116,127.235 406.379,125.972 407.935,125.972 C409.492,125.972 410.754,127.235 410.754,128.791" id="Fill-1037" class="icon-color"/>
      <path d="M405.561,122.058 C405.561,120.747 406.624,119.684 407.935,119.684 C409.247,119.684 410.31,120.747 410.31,122.058 C410.31,123.37 409.247,124.433 407.935,124.433 C406.624,124.433 405.561,123.37 405.561,122.058" id="Fill-1038" class="icon-color"/>
      <path d="M406.005,115.326 C406.005,114.26 406.87,113.395 407.935,113.395 C409.002,113.395 409.866,114.26 409.866,115.326 C409.866,116.391 409.002,117.256 407.935,117.256 C406.87,117.256 406.005,116.391 406.005,115.326" id="Fill-1039" class="icon-color"/>
      <path d="M406.894,101.86 C406.894,101.285 407.36,100.818 407.935,100.818 C408.511,100.818 408.977,101.285 408.977,101.86 C408.977,102.435 408.511,102.901 407.935,102.901 C407.36,102.901 406.894,102.435 406.894,101.86" id="Fill-1040" class="icon-color"/>
      <path d="M407.339,95.127 C407.339,94.797 407.606,94.53 407.935,94.53 C408.265,94.53 408.533,94.797 408.533,95.127 C408.533,95.457 408.265,95.724 407.935,95.724 C407.606,95.724 407.339,95.457 407.339,95.127" id="Fill-1041" class="icon-color"/>
      <path d="M407.783,88.394 C407.783,88.31 407.851,88.241 407.935,88.241 C408.02,88.241 408.088,88.31 408.088,88.394 C408.088,88.478 408.02,88.547 407.935,88.547 C407.851,88.547 407.783,88.478 407.783,88.394" id="Fill-1042" class="icon-color"/>
      <path d="M408.227,81.661 C408.227,81.822 408.097,81.953 407.935,81.953 C407.774,81.953 407.644,81.822 407.644,81.661 C407.644,81.5 407.774,81.369 407.935,81.369 C408.097,81.369 408.227,81.5 408.227,81.661" id="Fill-1043" class="icon-color"/>
      <path d="M408.672,74.928 C408.672,75.335 408.342,75.664 407.935,75.664 C407.529,75.664 407.199,75.335 407.199,74.928 C407.199,74.521 407.529,74.192 407.935,74.192 C408.342,74.192 408.672,74.521 408.672,74.928" id="Fill-1044" class="icon-color"/>
      <path d="M409.116,68.195 C409.116,68.847 408.587,69.376 407.935,69.376 C407.284,69.376 406.755,68.847 406.755,68.195 C406.755,67.543 407.284,67.015 407.935,67.015 C408.587,67.015 409.116,67.543 409.116,68.195" id="Fill-1045" class="icon-color"/>
      <path d="M409.56,61.462 C409.56,62.359 408.833,63.087 407.935,63.087 C407.038,63.087 406.31,62.359 406.31,61.462 C406.31,60.565 407.038,59.837 407.935,59.837 C408.833,59.837 409.56,60.565 409.56,61.462" id="Fill-1046" class="icon-color"/>
      <path d="M406.45,108.593 C406.45,107.772 407.115,107.107 407.935,107.107 C408.756,107.107 409.421,107.772 409.421,108.593 C409.421,109.413 408.756,110.079 407.935,110.079 C407.115,110.079 406.45,109.413 406.45,108.593" id="Fill-1047" class="icon-color"/>
      <path d="M412.827,66.763 C412.827,65.901 413.526,65.202 414.388,65.202 C415.25,65.202 415.948,65.901 415.948,66.763 C415.948,67.624 415.25,68.323 414.388,68.323 C413.526,68.323 412.827,67.624 412.827,66.763" id="Fill-1048" class="icon-color"/>
      <path d="M414.658,71.824 C415.434,71.974 415.942,72.723 415.792,73.499 C415.643,74.275 414.893,74.782 414.117,74.633 C413.341,74.483 412.834,73.733 412.983,72.958 C413.133,72.182 413.883,71.675 414.658,71.824" id="Fill-1049" class="icon-color"/>
      <path d="M414.929,78.446 C415.619,78.745 415.935,79.546 415.636,80.236 C415.337,80.925 414.536,81.242 413.846,80.943 C413.157,80.644 412.84,79.842 413.139,79.153 C413.438,78.463 414.24,78.147 414.929,78.446" id="Fill-1050" class="icon-color"/>
      <path d="M415.2,85.068 C415.803,85.516 415.928,86.369 415.48,86.972 C415.031,87.576 414.179,87.701 413.576,87.252 C412.972,86.804 412.847,85.951 413.295,85.348 C413.744,84.745 414.596,84.619 415.2,85.068" id="Fill-1051" class="icon-color"/>
      <path d="M415.47,91.69 C415.988,92.288 415.922,93.192 415.324,93.709 C414.726,94.226 413.822,94.16 413.305,93.562 C412.788,92.964 412.853,92.06 413.452,91.543 C414.049,91.026 414.953,91.092 415.47,91.69" id="Fill-1052" class="icon-color"/>
      <path d="M415.741,98.311 C416.172,99.059 415.915,100.014 415.168,100.445 C414.42,100.876 413.465,100.619 413.034,99.872 C412.603,99.124 412.86,98.169 413.607,97.738 C414.355,97.307 415.31,97.564 415.741,98.311" id="Fill-1053" class="icon-color"/>
      <path d="M416.012,104.933 C416.357,105.83 415.909,106.837 415.012,107.182 C414.115,107.527 413.108,107.079 412.763,106.182 C412.419,105.285 412.866,104.278 413.763,103.933 C414.661,103.589 415.667,104.036 416.012,104.933" id="Fill-1054" class="icon-color"/>
      <path d="M416.283,111.555 C416.541,112.602 415.902,113.66 414.856,113.918 C413.809,114.177 412.751,113.538 412.493,112.492 C412.234,111.445 412.873,110.387 413.919,110.128 C414.966,109.87 416.024,110.509 416.283,111.555" id="Fill-1055" class="icon-color"/>
      <path d="M416.553,118.177 C416.726,119.373 415.896,120.483 414.7,120.655 C413.504,120.827 412.394,119.998 412.222,118.801 C412.05,117.605 412.879,116.496 414.076,116.323 C415.272,116.151 416.381,116.981 416.553,118.177" id="Fill-1056" class="icon-color"/>
      <path d="M416.824,124.799 C416.91,126.145 415.889,127.305 414.544,127.391 C413.198,127.478 412.038,126.457 411.951,125.111 C411.865,123.765 412.886,122.605 414.232,122.518 C415.577,122.432 416.738,123.453 416.824,124.799" id="Fill-1057" class="icon-color"/>
      <path d="M411.681,131.421 C411.681,129.926 412.893,128.714 414.388,128.714 C415.883,128.714 417.095,129.926 417.095,131.421 C417.095,132.916 415.883,134.128 414.388,134.128 C412.893,134.128 411.681,132.916 411.681,131.421" id="Fill-1058" class="icon-color"/>
      <path d="M416.668,138.025 C416.668,139.284 415.647,140.305 414.388,140.305 C413.128,140.305 412.107,139.284 412.107,138.025 C412.107,136.765 413.128,135.745 414.388,135.745 C415.647,135.745 416.668,136.765 416.668,138.025" id="Fill-1059" class="icon-color"/>
      <path d="M416.241,144.629 C416.241,145.653 415.411,146.483 414.388,146.483 C413.364,146.483 412.534,145.653 412.534,144.629 C412.534,143.605 413.364,142.775 414.388,142.775 C415.411,142.775 416.241,143.605 416.241,144.629" id="Fill-1060" class="icon-color"/>
      <path d="M415.815,151.233 C415.815,152.021 415.176,152.66 414.388,152.66 C413.6,152.66 412.961,152.021 412.961,151.233 C412.961,150.445 413.6,149.806 414.388,149.806 C415.176,149.806 415.815,150.445 415.815,151.233" id="Fill-1061" class="icon-color"/>
      <path d="M415.388,157.837 C415.388,158.39 414.94,158.838 414.388,158.838 C413.835,158.838 413.388,158.39 413.388,157.837 C413.388,157.285 413.835,156.837 414.388,156.837 C414.94,156.837 415.388,157.285 415.388,157.837" id="Fill-1062" class="icon-color"/>
      <path d="M414.961,164.442 C414.961,164.758 414.704,165.015 414.388,165.015 C414.071,165.015 413.814,164.758 413.814,164.442 C413.814,164.125 414.071,163.868 414.388,163.868 C414.704,163.868 414.961,164.125 414.961,164.442" id="Fill-1063" class="icon-color"/>
      <path d="M414.534,171.046 C414.534,171.127 414.469,171.192 414.388,171.192 C414.307,171.192 414.241,171.127 414.241,171.046 C414.241,170.965 414.307,170.899 414.388,170.899 C414.469,170.899 414.534,170.965 414.534,171.046" id="Fill-1064" class="icon-color"/>
      <path d="M414.107,177.65 C414.107,177.495 414.233,177.37 414.388,177.37 C414.542,177.37 414.668,177.495 414.668,177.65 C414.668,177.804 414.542,177.93 414.388,177.93 C414.233,177.93 414.107,177.804 414.107,177.65" id="Fill-1065" class="icon-color"/>
      <path d="M413.681,184.254 C413.681,183.863 413.997,183.547 414.388,183.547 C414.778,183.547 415.095,183.863 415.095,184.254 C415.095,184.644 414.778,184.961 414.388,184.961 C413.997,184.961 413.681,184.644 413.681,184.254" id="Fill-1066" class="icon-color"/>
      <path d="M413.254,190.858 C413.254,190.232 413.762,189.724 414.388,189.724 C415.014,189.724 415.521,190.232 415.521,190.858 C415.521,191.484 415.014,191.992 414.388,191.992 C413.762,191.992 413.254,191.484 413.254,190.858" id="Fill-1067" class="icon-color"/>
      <path d="M412.827,197.462 C412.827,196.6 413.526,195.902 414.388,195.902 C415.25,195.902 415.948,196.6 415.948,197.462 C415.948,198.324 415.25,199.023 414.388,199.023 C413.526,199.023 412.827,198.324 412.827,197.462" id="Fill-1068" class="icon-color"/>
      <path d="M422.713,114.496 C422.713,115.819 421.64,116.893 420.317,116.893 C418.993,116.893 417.92,115.819 417.92,114.496 C417.92,113.172 418.993,112.099 420.317,112.099 C421.64,112.099 422.713,113.172 422.713,114.496" id="Fill-1069" class="icon-color"/>
      <path d="M420.383,110.584 C419.169,110.62 418.156,109.666 418.12,108.453 C418.083,107.24 419.038,106.227 420.251,106.19 C421.464,106.154 422.477,107.108 422.513,108.321 C422.55,109.534 421.596,110.547 420.383,110.584" id="Fill-1070" class="icon-color"/>
      <path d="M420.394,104.275 C419.291,104.348 418.338,103.513 418.265,102.41 C418.192,101.307 419.028,100.354 420.131,100.281 C421.234,100.208 422.187,101.043 422.259,102.146 C422.332,103.249 421.497,104.202 420.394,104.275" id="Fill-1071" class="icon-color"/>
      <path d="M420.435,97.966 C419.442,98.076 418.549,97.359 418.44,96.367 C418.331,95.374 419.047,94.481 420.04,94.372 C421.033,94.263 421.926,94.979 422.035,95.971 C422.144,96.964 421.428,97.857 420.435,97.966" id="Fill-1072" class="icon-color"/>
      <path d="M420.476,91.658 C419.594,91.803 418.76,91.206 418.615,90.324 C418.469,89.441 419.067,88.608 419.949,88.462 C420.831,88.317 421.665,88.914 421.81,89.797 C421.956,90.679 421.358,91.512 420.476,91.658" id="Fill-1073" class="icon-color"/>
      <path d="M420.517,85.349 C419.745,85.531 418.971,85.053 418.789,84.28 C418.608,83.508 419.086,82.735 419.858,82.553 C420.63,82.371 421.404,82.85 421.585,83.622 C421.767,84.394 421.289,85.167 420.517,85.349" id="Fill-1074" class="icon-color"/>
      <path d="M420.573,79.04 C419.911,79.259 419.198,78.899 418.98,78.237 C418.761,77.576 419.121,76.862 419.783,76.644 C420.444,76.425 421.158,76.785 421.376,77.447 C421.594,78.109 421.235,78.822 420.573,79.04" id="Fill-1075" class="icon-color"/>
      <path d="M420.662,72.732 C420.111,72.986 419.457,72.746 419.203,72.194 C418.948,71.643 419.188,70.989 419.74,70.734 C420.292,70.48 420.945,70.721 421.2,71.272 C421.454,71.823 421.214,72.477 420.662,72.732" id="Fill-1076" class="icon-color"/>
      <path d="M420.751,66.423 C420.31,66.714 419.716,66.592 419.425,66.151 C419.134,65.71 419.256,65.116 419.697,64.825 C420.138,64.534 420.732,64.656 421.023,65.097 C421.314,65.538 421.192,66.132 420.751,66.423" id="Fill-1077" class="icon-color"/>
      <path d="M420.84,60.114 C420.509,60.442 419.975,60.439 419.648,60.108 C419.321,59.777 419.324,59.243 419.654,58.916 C419.985,58.589 420.519,58.592 420.846,58.922 C421.174,59.253 421.171,59.787 420.84,60.114" id="Fill-1078" class="icon-color"/>
      <path d="M420.975,53.806 C420.755,54.169 420.281,54.285 419.917,54.065 C419.553,53.844 419.437,53.371 419.658,53.007 C419.878,52.643 420.352,52.527 420.716,52.747 C421.08,52.968 421.196,53.442 420.975,53.806" id="Fill-1079" class="icon-color"/>
      <path d="M421.041,47.497 C420.931,47.897 420.517,48.132 420.117,48.022 C419.717,47.911 419.482,47.498 419.592,47.098 C419.702,46.697 420.116,46.462 420.516,46.573 C420.916,46.683 421.151,47.097 421.041,47.497" id="Fill-1080" class="icon-color"/>
      <path d="M421.107,41.188 C421.107,41.625 420.753,41.979 420.317,41.979 C419.88,41.979 419.526,41.625 419.526,41.188 C419.526,40.752 419.88,40.398 420.317,40.398 C420.753,40.398 421.107,40.752 421.107,41.188" id="Fill-1081" class="icon-color"/>
      <path d="M419.752,170.423 C419.752,169.983 420.109,169.626 420.549,169.626 C420.989,169.626 421.346,169.983 421.346,170.423 C421.346,170.863 420.989,171.22 420.549,171.22 C420.109,171.22 419.752,170.863 419.752,170.423" id="Fill-1082" class="icon-color"/>
      <path d="M419.505,164.2 C419.505,163.623 419.973,163.156 420.549,163.156 C421.126,163.156 421.593,163.623 421.593,164.2 C421.593,164.776 421.126,165.244 420.549,165.244 C419.973,165.244 419.505,164.776 419.505,164.2" id="Fill-1083" class="icon-color"/>
      <path d="M419.258,157.977 C419.258,157.264 419.836,156.686 420.549,156.686 C421.262,156.686 421.84,157.264 421.84,157.977 C421.84,158.69 421.262,159.268 420.549,159.268 C419.836,159.268 419.258,158.69 419.258,157.977" id="Fill-1084" class="icon-color"/>
      <path d="M419.011,151.754 C419.011,150.904 419.7,150.216 420.549,150.216 C421.398,150.216 422.087,150.904 422.087,151.754 C422.087,152.603 421.398,153.292 420.549,153.292 C419.7,153.292 419.011,152.603 419.011,151.754" id="Fill-1085" class="icon-color"/>
      <path d="M418.764,145.531 C418.764,144.545 419.563,143.746 420.549,143.746 C421.535,143.746 422.334,144.545 422.334,145.531 C422.334,146.517 421.535,147.316 420.549,147.316 C419.563,147.316 418.764,146.517 418.764,145.531" id="Fill-1086" class="icon-color"/>
      <path d="M418.517,139.308 C418.517,138.186 419.427,137.276 420.549,137.276 C421.671,137.276 422.581,138.186 422.581,139.308 C422.581,140.43 421.671,141.34 420.549,141.34 C419.427,141.34 418.517,140.43 418.517,139.308" id="Fill-1087" class="icon-color"/>
      <path d="M418.27,133.085 C418.27,131.826 419.29,130.806 420.549,130.806 C421.808,130.806 422.828,131.826 422.828,133.085 C422.828,134.343 421.808,135.364 420.549,135.364 C419.29,135.364 418.27,134.343 418.27,133.085" id="Fill-1088" class="icon-color"/>
      <path d="M418.023,126.862 C418.023,125.467 419.154,124.336 420.549,124.336 C421.944,124.336 423.075,125.467 423.075,126.862 C423.075,128.257 421.944,129.387 420.549,129.387 C419.154,129.387 418.023,128.257 418.023,126.862" id="Fill-1089" class="icon-color"/>
      <path d="M417.776,120.639 C417.776,119.107 419.018,117.866 420.549,117.866 C422.081,117.866 423.322,119.107 423.322,120.639 C423.322,122.17 422.081,123.411 420.549,123.411 C419.018,123.411 417.776,122.17 417.776,120.639" id="Fill-1090" class="icon-color"/>
      <path d="M403.103,221.767 C403.103,222.628 402.404,223.327 401.543,223.327 C400.681,223.327 399.982,222.628 399.982,221.767 C399.982,220.905 400.681,220.206 401.543,220.206 C402.404,220.206 403.103,220.905 403.103,221.767" id="Fill-1091" class="icon-color"/>
      <path d="M401.272,215.315 C400.496,215.166 399.989,214.416 400.138,213.64 C400.288,212.864 401.038,212.357 401.814,212.506 C402.589,212.656 403.097,213.406 402.947,214.182 C402.798,214.957 402.048,215.465 401.272,215.315" id="Fill-1092" class="icon-color"/>
      <path d="M401.001,207.303 C400.312,207.004 399.995,206.203 400.294,205.514 C400.593,204.824 401.395,204.508 402.084,204.807 C402.774,205.106 403.09,205.907 402.791,206.596 C402.492,207.286 401.691,207.602 401.001,207.303" id="Fill-1093" class="icon-color"/>
      <path d="M400.731,199.292 C400.127,198.843 400.002,197.991 400.45,197.387 C400.899,196.784 401.752,196.659 402.355,197.107 C402.958,197.556 403.084,198.408 402.635,199.012 C402.186,199.615 401.334,199.74 400.731,199.292" id="Fill-1094" class="icon-color"/>
      <path d="M400.46,191.28 C399.943,190.682 400.008,189.778 400.607,189.261 C401.204,188.744 402.108,188.809 402.626,189.407 C403.143,190.005 403.077,190.909 402.479,191.426 C401.881,191.944 400.977,191.878 400.46,191.28" id="Fill-1095" class="icon-color"/>
      <path d="M400.189,183.268 C399.758,182.521 400.015,181.565 400.763,181.134 C401.51,180.703 402.465,180.96 402.896,181.708 C403.327,182.455 403.07,183.411 402.323,183.842 C401.575,184.272 400.62,184.016 400.189,183.268" id="Fill-1096" class="icon-color"/>
      <path d="M399.918,175.256 C399.574,174.359 400.021,173.353 400.919,173.008 C401.816,172.663 402.822,173.111 403.167,174.008 C403.512,174.905 403.064,175.912 402.167,176.256 C401.27,176.601 400.263,176.153 399.918,175.256" id="Fill-1097" class="icon-color"/>
      <path d="M399.648,167.245 C399.389,166.198 400.028,165.14 401.075,164.881 C402.121,164.623 403.179,165.262 403.438,166.308 C403.696,167.355 403.057,168.413 402.011,168.672 C400.964,168.93 399.906,168.291 399.648,167.245" id="Fill-1098" class="icon-color"/>
      <path d="M399.377,159.233 C399.205,158.037 400.035,156.927 401.231,156.755 C402.427,156.583 403.536,157.413 403.708,158.609 C403.881,159.805 403.051,160.914 401.855,161.086 C400.659,161.259 399.549,160.429 399.377,159.233" id="Fill-1099" class="icon-color"/>
      <path d="M399.106,151.221 C399.02,149.875 400.041,148.715 401.387,148.629 C402.732,148.543 403.893,149.563 403.979,150.909 C404.065,152.255 403.044,153.415 401.699,153.501 C400.353,153.588 399.193,152.567 399.106,151.221" id="Fill-1100" class="icon-color"/>
      <path d="M404.25,143.209 C404.25,144.704 403.038,145.916 401.543,145.916 C400.048,145.916 398.836,144.704 398.836,143.209 C398.836,141.714 400.048,140.502 401.543,140.502 C403.038,140.502 404.25,141.714 404.25,143.209" id="Fill-1101" class="icon-color"/>
      <path d="M399.262,135.695 C399.262,134.436 400.283,133.415 401.543,133.415 C402.802,133.415 403.823,134.436 403.823,135.695 C403.823,136.955 402.802,137.976 401.543,137.976 C400.283,137.976 399.262,136.955 399.262,135.695" id="Fill-1102" class="icon-color"/>
      <path d="M399.689,128.181 C399.689,127.157 400.519,126.328 401.543,126.328 C402.566,126.328 403.396,127.157 403.396,128.181 C403.396,129.205 402.566,130.035 401.543,130.035 C400.519,130.035 399.689,129.205 399.689,128.181" id="Fill-1103" class="icon-color"/>
      <path d="M400.116,120.667 C400.116,119.879 400.755,119.24 401.543,119.24 C402.331,119.24 402.97,119.879 402.97,120.667 C402.97,121.455 402.331,122.094 401.543,122.094 C400.755,122.094 400.116,121.455 400.116,120.667" id="Fill-1104" class="icon-color"/>
      <path d="M400.969,105.639 C400.969,105.322 401.226,105.066 401.543,105.066 C401.859,105.066 402.116,105.322 402.116,105.639 C402.116,105.956 401.859,106.212 401.543,106.212 C401.226,106.212 400.969,105.956 400.969,105.639" id="Fill-1105" class="icon-color"/>
      <path d="M401.396,98.125 C401.396,98.044 401.462,97.978 401.543,97.978 C401.624,97.978 401.689,98.044 401.689,98.125 C401.689,98.206 401.624,98.271 401.543,98.271 C401.462,98.271 401.396,98.206 401.396,98.125" id="Fill-1106" class="icon-color"/>
      <path d="M401.823,90.611 C401.823,90.765 401.697,90.891 401.543,90.891 C401.388,90.891 401.263,90.765 401.263,90.611 C401.263,90.456 401.388,90.331 401.543,90.331 C401.697,90.331 401.823,90.456 401.823,90.611" id="Fill-1107" class="icon-color"/>
      <path d="M402.25,83.097 C402.25,83.487 401.933,83.804 401.543,83.804 C401.152,83.804 400.836,83.487 400.836,83.097 C400.836,82.706 401.152,82.39 401.543,82.39 C401.933,82.39 402.25,82.706 402.25,83.097" id="Fill-1108" class="icon-color"/>
      <path d="M402.676,75.583 C402.676,76.209 402.169,76.716 401.543,76.716 C400.917,76.716 400.409,76.209 400.409,75.583 C400.409,74.957 400.917,74.449 401.543,74.449 C402.169,74.449 402.676,74.957 402.676,75.583" id="Fill-1109" class="icon-color"/>
      <path d="M403.103,68.069 C403.103,68.93 402.404,69.629 401.543,69.629 C400.681,69.629 399.982,68.93 399.982,68.069 C399.982,67.207 400.681,66.508 401.543,66.508 C402.404,66.508 403.103,67.207 403.103,68.069" id="Fill-1110" class="icon-color"/>
      <path d="M400.543,113.153 C400.543,112.601 400.99,112.153 401.543,112.153 C402.095,112.153 402.543,112.601 402.543,113.153 C402.543,113.706 402.095,114.153 401.543,114.153 C400.99,114.153 400.543,113.706 400.543,113.153" id="Fill-1111" class="icon-color"/>
      <path d="M382.205,176.956 C382.205,177.796 381.524,178.477 380.684,178.477 C379.844,178.477 379.163,177.796 379.163,176.956 C379.163,176.116 379.844,175.435 380.684,175.435 C381.524,175.435 382.205,176.116 382.205,176.956" id="Fill-1112" class="icon-color"/>
      <path d="M380.39,170.25 C379.644,170.088 379.169,169.352 379.331,168.605 C379.493,167.858 380.23,167.384 380.977,167.546 C381.724,167.708 382.198,168.445 382.036,169.191 C381.874,169.938 381.137,170.412 380.39,170.25" id="Fill-1113" class="icon-color"/>
      <path d="M380.097,162.024 C379.444,161.7 379.176,160.908 379.5,160.254 C379.824,159.601 380.617,159.334 381.27,159.657 C381.923,159.981 382.191,160.774 381.867,161.427 C381.543,162.081 380.751,162.348 380.097,162.024" id="Fill-1114" class="icon-color"/>
      <path d="M379.804,153.797 C379.244,153.311 379.184,152.463 379.669,151.903 C380.155,151.343 381.003,151.283 381.563,151.769 C382.123,152.255 382.184,153.103 381.698,153.663 C381.212,154.223 380.364,154.283 379.804,153.797" id="Fill-1115" class="icon-color"/>
      <path d="M379.511,145.571 C379.044,144.923 379.191,144.019 379.838,143.553 C380.486,143.086 381.39,143.232 381.856,143.88 C382.323,144.528 382.176,145.431 381.529,145.898 C380.881,146.365 379.977,146.218 379.511,145.571" id="Fill-1116" class="icon-color"/>
      <path d="M379.217,137.344 C378.844,136.534 379.198,135.575 380.007,135.202 C380.817,134.828 381.776,135.182 382.15,135.992 C382.523,136.801 382.169,137.76 381.36,138.134 C380.55,138.507 379.591,138.154 379.217,137.344" id="Fill-1117" class="icon-color"/>
      <path d="M378.924,129.117 C378.644,128.146 379.205,127.131 380.176,126.851 C381.148,126.571 382.163,127.131 382.443,128.103 C382.723,129.075 382.162,130.089 381.191,130.37 C380.219,130.65 379.204,130.089 378.924,129.117" id="Fill-1118" class="icon-color"/>
      <path d="M378.631,120.891 C378.444,119.757 379.212,118.687 380.345,118.5 C381.479,118.313 382.549,119.081 382.736,120.215 C382.923,121.348 382.155,122.418 381.022,122.605 C379.888,122.792 378.818,122.024 378.631,120.891" id="Fill-1119" class="icon-color"/>
      <path d="M378.338,112.664 C378.245,111.368 379.219,110.242 380.514,110.149 C381.81,110.056 382.936,111.03 383.029,112.326 C383.123,113.621 382.148,114.747 380.853,114.841 C379.557,114.934 378.431,113.959 378.338,112.664" id="Fill-1120" class="icon-color"/>
      <path d="M383.323,104.437 C383.323,105.895 382.141,107.076 380.684,107.076 C379.226,107.076 378.045,105.895 378.045,104.437 C378.045,102.98 379.226,101.798 380.684,101.798 C382.141,101.798 383.323,102.98 383.323,104.437" id="Fill-1121" class="icon-color"/>
      <path d="M378.969,90.43 C378.969,89.483 379.737,88.716 380.684,88.716 C381.63,88.716 382.398,89.483 382.398,90.43 C382.398,91.377 381.63,92.145 380.684,92.145 C379.737,92.145 378.969,91.377 378.969,90.43" id="Fill-1122" class="icon-color"/>
      <path d="M379.431,83.427 C379.431,82.735 379.992,82.174 380.684,82.174 C381.375,82.174 381.936,82.735 381.936,83.427 C381.936,84.118 381.375,84.679 380.684,84.679 C379.992,84.679 379.431,84.118 379.431,83.427" id="Fill-1123" class="icon-color"/>
      <path d="M379.893,76.423 C379.893,75.987 380.247,75.633 380.684,75.633 C381.12,75.633 381.474,75.987 381.474,76.423 C381.474,76.86 381.12,77.213 380.684,77.213 C380.247,77.213 379.893,76.86 379.893,76.423" id="Fill-1124" class="icon-color"/>
      <path d="M380.356,69.42 C380.356,69.239 380.503,69.092 380.684,69.092 C380.865,69.092 381.011,69.239 381.011,69.42 C381.011,69.601 380.865,69.748 380.684,69.748 C380.503,69.748 380.356,69.601 380.356,69.42" id="Fill-1125" class="icon-color"/>
      <path d="M380.818,62.416 C380.818,62.491 380.758,62.551 380.684,62.551 C380.609,62.551 380.549,62.491 380.549,62.416 C380.549,62.342 380.609,62.282 380.684,62.282 C380.758,62.282 380.818,62.342 380.818,62.416" id="Fill-1126" class="icon-color"/>
      <path d="M381.28,55.413 C381.28,55.742 381.013,56.01 380.684,56.01 C380.354,56.01 380.087,55.742 380.087,55.413 C380.087,55.083 380.354,54.816 380.684,54.816 C381.013,54.816 381.28,55.083 381.28,55.413" id="Fill-1127" class="icon-color"/>
      <path d="M381.742,48.41 C381.742,48.994 381.268,49.468 380.684,49.468 C380.099,49.468 379.625,48.994 379.625,48.41 C379.625,47.825 380.099,47.35 380.684,47.35 C381.268,47.35 381.742,47.825 381.742,48.41" id="Fill-1128" class="icon-color"/>
      <path d="M382.205,41.406 C382.205,42.246 381.524,42.927 380.684,42.927 C379.844,42.927 379.163,42.246 379.163,41.406 C379.163,40.566 379.844,39.885 380.684,39.885 C381.524,39.885 382.205,40.566 382.205,41.406" id="Fill-1129" class="icon-color"/>
      <path d="M378.507,97.434 C378.507,96.232 379.481,95.257 380.684,95.257 C381.886,95.257 382.861,96.232 382.861,97.434 C382.861,98.636 381.886,99.611 380.684,99.611 C379.481,99.611 378.507,98.636 378.507,97.434" id="Fill-1130" class="icon-color"/>
      <path d="M384.694,114.882 C384.694,113.432 385.869,112.257 387.319,112.257 C388.769,112.257 389.944,113.432 389.944,114.882 C389.944,116.332 388.769,117.507 387.319,117.507 C385.869,117.507 384.694,116.332 384.694,114.882" id="Fill-1131" class="icon-color"/>
      <path d="M387.206,127.537 C388.366,127.441 389.384,128.303 389.479,129.463 C389.575,130.623 388.712,131.641 387.552,131.737 C386.393,131.832 385.375,130.969 385.279,129.81 C385.183,128.65 386.046,127.632 387.206,127.537" id="Fill-1132" class="icon-color"/>
      <path d="M387.15,135.176 C388.164,135.033 389.103,135.739 389.247,136.754 C389.39,137.769 388.684,138.708 387.669,138.851 C386.654,138.995 385.715,138.288 385.572,137.273 C385.428,136.259 386.135,135.32 387.15,135.176" id="Fill-1133" class="icon-color"/>
      <path d="M387.093,142.816 C387.963,142.625 388.823,143.175 389.015,144.045 C389.206,144.915 388.656,145.775 387.786,145.966 C386.916,146.158 386.056,145.607 385.864,144.738 C385.673,143.868 386.223,143.007 387.093,142.816" id="Fill-1134" class="icon-color"/>
      <path d="M387.037,150.456 C387.762,150.217 388.543,150.611 388.782,151.336 C389.021,152.061 388.628,152.842 387.903,153.081 C387.178,153.32 386.396,152.926 386.157,152.202 C385.918,151.476 386.312,150.695 387.037,150.456" id="Fill-1135" class="icon-color"/>
      <path d="M386.926,158.096 C387.506,157.809 388.209,158.046 388.495,158.626 C388.782,159.206 388.545,159.909 387.965,160.196 C387.385,160.483 386.682,160.245 386.395,159.665 C386.108,159.086 386.346,158.383 386.926,158.096" id="Fill-1136" class="icon-color"/>
      <path d="M386.805,165.736 C387.24,165.401 387.864,165.482 388.199,165.917 C388.534,166.352 388.452,166.976 388.018,167.311 C387.583,167.646 386.959,167.564 386.624,167.129 C386.289,166.694 386.37,166.071 386.805,165.736" id="Fill-1137" class="icon-color"/>
      <path d="M386.685,173.375 C386.975,172.993 387.52,172.918 387.902,173.208 C388.285,173.498 388.36,174.043 388.07,174.426 C387.78,174.808 387.235,174.883 386.852,174.593 C386.47,174.303 386.395,173.758 386.685,173.375" id="Fill-1138" class="icon-color"/>
      <path d="M386.531,181.015 C386.676,180.585 387.143,180.354 387.573,180.499 C388.004,180.644 388.235,181.11 388.09,181.54 C387.945,181.971 387.478,182.202 387.048,182.057 C386.617,181.912 386.386,181.446 386.531,181.015" id="Fill-1139" class="icon-color"/>
      <path d="M386.444,188.655 C386.444,188.177 386.831,187.789 387.31,187.789 C387.788,187.789 388.176,188.177 388.176,188.655 C388.176,189.133 387.788,189.521 387.31,189.521 C386.831,189.521 386.444,189.133 386.444,188.655" id="Fill-1140" class="icon-color"/>
      <path d="M387.231,119.897 C388.536,119.849 389.633,120.868 389.681,122.173 C389.728,123.478 388.71,124.574 387.405,124.622 C386.1,124.67 385.003,123.651 384.955,122.346 C384.908,121.041 385.927,119.944 387.231,119.897" id="Fill-1141" class="icon-color"/>
      <path d="M391.522,128.841 C391.522,127.517 392.595,126.444 393.919,126.444 C395.242,126.444 396.315,127.517 396.315,128.841 C396.315,130.164 395.242,131.237 393.919,131.237 C392.595,131.237 391.522,130.164 391.522,128.841" id="Fill-1142" class="icon-color"/>
      <path d="M393.842,139.178 C394.945,139.105 395.898,139.941 395.97,141.044 C396.043,142.147 395.208,143.1 394.105,143.172 C393.002,143.245 392.049,142.41 391.976,141.307 C391.904,140.204 392.739,139.251 393.842,139.178" id="Fill-1143" class="icon-color"/>
      <path d="M393.801,145.545 C394.794,145.436 395.687,146.152 395.796,147.145 C395.905,148.138 395.189,149.031 394.196,149.14 C393.204,149.249 392.31,148.533 392.201,147.54 C392.092,146.548 392.808,145.654 393.801,145.545" id="Fill-1144" class="icon-color"/>
      <path d="M393.76,151.912 C394.643,151.767 395.476,152.364 395.621,153.247 C395.767,154.129 395.17,154.962 394.287,155.108 C393.405,155.253 392.572,154.656 392.426,153.773 C392.281,152.891 392.878,152.058 393.76,151.912" id="Fill-1145" class="icon-color"/>
      <path d="M393.72,158.279 C394.492,158.097 395.265,158.576 395.447,159.348 C395.629,160.12 395.15,160.893 394.378,161.075 C393.606,161.257 392.833,160.779 392.651,160.007 C392.469,159.235 392.947,158.461 393.72,158.279" id="Fill-1146" class="icon-color"/>
      <path d="M393.661,164.646 C394.323,164.428 395.036,164.788 395.254,165.449 C395.473,166.111 395.113,166.825 394.452,167.043 C393.79,167.261 393.076,166.902 392.858,166.24 C392.64,165.578 392.999,164.865 393.661,164.646" id="Fill-1147" class="icon-color"/>
      <path d="M393.572,171.013 C394.124,170.759 394.777,170.999 395.032,171.551 C395.286,172.102 395.046,172.756 394.494,173.01 C393.943,173.265 393.289,173.025 393.035,172.473 C392.78,171.922 393.021,171.268 393.572,171.013" id="Fill-1148" class="icon-color"/>
      <path d="M393.483,177.38 C393.925,177.089 394.518,177.211 394.809,177.652 C395.1,178.094 394.978,178.687 394.537,178.978 C394.096,179.269 393.503,179.147 393.212,178.706 C392.92,178.265 393.042,177.671 393.483,177.38" id="Fill-1149" class="icon-color"/>
      <path d="M393.395,183.747 C393.725,183.42 394.259,183.423 394.586,183.754 C394.914,184.085 394.911,184.618 394.58,184.946 C394.249,185.273 393.716,185.27 393.388,184.939 C393.061,184.609 393.064,184.075 393.395,183.747" id="Fill-1150" class="icon-color"/>
      <path d="M393.26,190.114 C393.481,189.751 393.954,189.635 394.318,189.855 C394.682,190.076 394.798,190.549 394.577,190.913 C394.357,191.277 393.883,191.393 393.519,191.173 C393.156,190.952 393.039,190.478 393.26,190.114" id="Fill-1151" class="icon-color"/>
      <path d="M393.194,196.482 C393.304,196.081 393.718,195.846 394.118,195.957 C394.519,196.067 394.754,196.481 394.643,196.881 C394.533,197.281 394.119,197.516 393.719,197.406 C393.319,197.295 393.084,196.881 393.194,196.482" id="Fill-1152" class="icon-color"/>
      <path d="M393.128,202.849 C393.128,202.412 393.482,202.058 393.919,202.058 C394.355,202.058 394.709,202.412 394.709,202.849 C394.709,203.285 394.355,203.639 393.919,203.639 C393.482,203.639 393.128,203.285 393.128,202.849" id="Fill-1153" class="icon-color"/>
      <path d="M394.483,72.914 C394.483,73.354 394.126,73.711 393.686,73.711 C393.246,73.711 392.889,73.354 392.889,72.914 C392.889,72.474 393.246,72.117 393.686,72.117 C394.126,72.117 394.483,72.474 394.483,72.914" id="Fill-1154" class="icon-color"/>
      <path d="M394.73,79.137 C394.73,79.714 394.263,80.181 393.686,80.181 C393.11,80.181 392.642,79.714 392.642,79.137 C392.642,78.56 393.11,78.093 393.686,78.093 C394.263,78.093 394.73,78.56 394.73,79.137" id="Fill-1155" class="icon-color"/>
      <path d="M394.977,85.36 C394.977,86.073 394.399,86.651 393.686,86.651 C392.973,86.651 392.395,86.073 392.395,85.36 C392.395,84.647 392.973,84.069 393.686,84.069 C394.399,84.069 394.977,84.647 394.977,85.36" id="Fill-1156" class="icon-color"/>
      <path d="M395.224,91.583 C395.224,92.432 394.536,93.121 393.686,93.121 C392.837,93.121 392.148,92.432 392.148,91.583 C392.148,90.734 392.837,90.045 393.686,90.045 C394.536,90.045 395.224,90.734 395.224,91.583" id="Fill-1157" class="icon-color"/>
      <path d="M395.471,97.806 C395.471,98.792 394.672,99.591 393.686,99.591 C392.7,99.591 391.901,98.792 391.901,97.806 C391.901,96.82 392.7,96.021 393.686,96.021 C394.672,96.021 395.471,96.82 395.471,97.806" id="Fill-1158" class="icon-color"/>
      <path d="M395.718,104.029 C395.718,105.151 394.809,106.061 393.686,106.061 C392.564,106.061 391.654,105.151 391.654,104.029 C391.654,102.907 392.564,101.997 393.686,101.997 C394.809,101.997 395.718,102.907 395.718,104.029" id="Fill-1159" class="icon-color"/>
      <path d="M395.965,110.252 C395.965,111.511 394.945,112.531 393.686,112.531 C392.428,112.531 391.407,111.511 391.407,110.252 C391.407,108.994 392.428,107.973 393.686,107.973 C394.945,107.973 395.965,108.994 395.965,110.252" id="Fill-1160" class="icon-color"/>
      <path d="M396.212,116.475 C396.212,117.87 395.081,119.001 393.686,119.001 C392.291,119.001 391.16,117.87 391.16,116.475 C391.16,115.08 392.291,113.949 393.686,113.949 C395.081,113.949 396.212,115.08 396.212,116.475" id="Fill-1161" class="icon-color"/>
      <path d="M396.459,122.698 C396.459,124.23 395.218,125.471 393.686,125.471 C392.155,125.471 390.913,124.23 390.913,122.698 C390.913,121.167 392.155,119.925 393.686,119.925 C395.218,119.925 396.459,121.167 396.459,122.698" id="Fill-1162" class="icon-color"/>
      <path d="M393.853,132.811 C395.066,132.775 396.079,133.729 396.116,134.942 C396.152,136.155 395.198,137.168 393.984,137.205 C392.771,137.241 391.758,136.287 391.722,135.074 C391.685,133.861 392.64,132.848 393.853,132.811" id="Fill-1163" class="icon-color"/>
      <path d="M555.925,99.901 C555.925,99.003 556.652,98.276 557.55,98.276 C558.447,98.276 559.174,99.003 559.174,99.901 C559.174,100.798 558.447,101.526 557.55,101.526 C556.652,101.526 555.925,100.798 555.925,99.901" id="Fill-1164" class="icon-color"/>
      <path d="M557.831,105.171 C558.639,105.327 559.168,106.108 559.012,106.915 C558.856,107.723 558.075,108.252 557.268,108.096 C556.46,107.94 555.931,107.16 556.087,106.352 C556.243,105.544 557.024,105.016 557.831,105.171" id="Fill-1165" class="icon-color"/>
      <path d="M558.113,112.067 C558.831,112.378 559.161,113.212 558.849,113.93 C558.538,114.648 557.704,114.978 556.986,114.666 C556.268,114.355 555.938,113.521 556.25,112.803 C556.561,112.085 557.395,111.755 558.113,112.067" id="Fill-1166" class="icon-color"/>
      <path d="M558.395,118.962 C559.023,119.429 559.154,120.317 558.687,120.945 C558.22,121.573 557.332,121.704 556.704,121.237 C556.076,120.77 555.945,119.882 556.412,119.254 C556.879,118.626 557.767,118.495 558.395,118.962" id="Fill-1167" class="icon-color"/>
      <path d="M558.677,125.858 C559.216,126.48 559.147,127.422 558.524,127.96 C557.902,128.498 556.96,128.43 556.422,127.807 C555.884,127.185 555.952,126.243 556.574,125.705 C557.197,125.166 558.139,125.235 558.677,125.858" id="Fill-1168" class="icon-color"/>
      <path d="M559.241,139.648 C559.6,140.582 559.134,141.631 558.199,141.99 C557.265,142.349 556.217,141.883 555.858,140.948 C555.499,140.014 555.965,138.966 556.899,138.607 C557.834,138.248 558.882,138.714 559.241,139.648" id="Fill-1169" class="icon-color"/>
      <path d="M559.523,146.544 C559.792,147.634 559.127,148.735 558.037,149.004 C556.947,149.274 555.845,148.609 555.576,147.519 C555.307,146.429 555.972,145.327 557.062,145.058 C558.152,144.789 559.254,145.454 559.523,146.544" id="Fill-1170" class="icon-color"/>
      <path d="M559.805,153.439 C559.984,154.685 559.12,155.84 557.875,156.019 C556.629,156.199 555.474,155.335 555.294,154.089 C555.115,152.844 555.979,151.688 557.224,151.509 C558.47,151.329 559.625,152.194 559.805,153.439" id="Fill-1171" class="icon-color"/>
      <path d="M560.087,160.335 C560.176,161.736 559.113,162.944 557.712,163.034 C556.311,163.124 555.102,162.061 555.012,160.66 C554.923,159.258 555.986,158.05 557.387,157.96 C558.788,157.87 559.997,158.933 560.087,160.335" id="Fill-1172" class="icon-color"/>
      <path d="M554.731,167.23 C554.731,165.673 555.993,164.411 557.55,164.411 C559.106,164.411 560.368,165.673 560.368,167.23 C560.368,168.787 559.106,170.049 557.55,170.049 C555.993,170.049 554.731,168.787 554.731,167.23" id="Fill-1173" class="icon-color"/>
      <path d="M559.924,173.963 C559.924,175.274 558.861,176.338 557.55,176.338 C556.238,176.338 555.175,175.274 555.175,173.963 C555.175,172.652 556.238,171.588 557.55,171.588 C558.861,171.588 559.924,172.652 559.924,173.963" id="Fill-1174" class="icon-color"/>
      <path d="M559.48,180.696 C559.48,181.762 558.616,182.626 557.55,182.626 C556.484,182.626 555.619,181.762 555.619,180.696 C555.619,179.63 556.484,178.766 557.55,178.766 C558.616,178.766 559.48,179.63 559.48,180.696" id="Fill-1175" class="icon-color"/>
      <path d="M559.035,187.429 C559.035,188.249 558.37,188.915 557.55,188.915 C556.729,188.915 556.064,188.249 556.064,187.429 C556.064,186.608 556.729,185.943 557.55,185.943 C558.37,185.943 559.035,186.608 559.035,187.429" id="Fill-1176" class="icon-color"/>
      <path d="M558.591,194.162 C558.591,194.737 558.125,195.203 557.55,195.203 C556.974,195.203 556.508,194.737 556.508,194.162 C556.508,193.586 556.974,193.12 557.55,193.12 C558.125,193.12 558.591,193.586 558.591,194.162" id="Fill-1177" class="icon-color"/>
      <path d="M558.146,200.895 C558.146,201.224 557.879,201.492 557.55,201.492 C557.22,201.492 556.953,201.224 556.953,200.895 C556.953,200.565 557.22,200.298 557.55,200.298 C557.879,200.298 558.146,200.565 558.146,200.895" id="Fill-1178" class="icon-color"/>
      <path d="M557.702,207.627 C557.702,207.712 557.634,207.78 557.55,207.78 C557.465,207.78 557.397,207.712 557.397,207.627 C557.397,207.543 557.465,207.475 557.55,207.475 C557.634,207.475 557.702,207.543 557.702,207.627" id="Fill-1179" class="icon-color"/>
      <path d="M557.258,214.36 C557.258,214.199 557.388,214.069 557.55,214.069 C557.711,214.069 557.841,214.199 557.841,214.36 C557.841,214.522 557.711,214.652 557.55,214.652 C557.388,214.652 557.258,214.522 557.258,214.36" id="Fill-1180" class="icon-color"/>
      <path d="M556.813,221.093 C556.813,220.687 557.143,220.357 557.55,220.357 C557.956,220.357 558.286,220.687 558.286,221.093 C558.286,221.5 557.956,221.829 557.55,221.829 C557.143,221.829 556.813,221.5 556.813,221.093" id="Fill-1181" class="icon-color"/>
      <path d="M556.369,227.826 C556.369,227.174 556.897,226.646 557.55,226.646 C558.201,226.646 558.73,227.174 558.73,227.826 C558.73,228.478 558.201,229.007 557.55,229.007 C556.897,229.007 556.369,228.478 556.369,227.826" id="Fill-1182" class="icon-color"/>
      <path d="M555.925,234.559 C555.925,233.662 556.652,232.934 557.55,232.934 C558.447,232.934 559.174,233.662 559.174,234.559 C559.174,235.457 558.447,236.184 557.55,236.184 C556.652,236.184 555.925,235.457 555.925,234.559" id="Fill-1183" class="icon-color"/>
      <path d="M558.959,132.753 C559.408,133.531 559.141,134.526 558.362,134.975 C557.584,135.424 556.589,135.156 556.14,134.378 C555.691,133.599 555.959,132.605 556.737,132.156 C557.515,131.707 558.51,131.974 558.959,132.753" id="Fill-1184" class="icon-color"/>
      <path d="M590.208,169.806 C590.208,170.604 589.562,171.25 588.764,171.25 C587.966,171.25 587.32,170.604 587.32,169.806 C587.32,169.008 587.966,168.362 588.764,168.362 C589.562,168.362 590.208,169.008 590.208,169.806" id="Fill-1185" class="icon-color"/>
      <path d="M588.514,165.122 C587.796,164.984 587.326,164.289 587.464,163.571 C587.603,162.854 588.297,162.384 589.015,162.522 C589.732,162.661 590.202,163.355 590.064,164.073 C589.925,164.79 589.231,165.26 588.514,165.122" id="Fill-1186" class="icon-color"/>
      <path d="M588.263,158.993 C587.625,158.717 587.332,157.975 587.609,157.337 C587.885,156.699 588.627,156.406 589.265,156.683 C589.903,156.959 590.196,157.701 589.919,158.339 C589.643,158.977 588.901,159.27 588.263,158.993" id="Fill-1187" class="icon-color"/>
      <path d="M588.013,152.865 C587.454,152.45 587.338,151.661 587.753,151.102 C588.168,150.544 588.957,150.428 589.516,150.843 C590.074,151.258 590.19,152.047 589.775,152.606 C589.36,153.164 588.571,153.28 588.013,152.865" id="Fill-1188" class="icon-color"/>
      <path d="M587.762,146.737 C587.283,146.183 587.344,145.346 587.898,144.868 C588.451,144.389 589.288,144.45 589.766,145.003 C590.245,145.557 590.184,146.394 589.631,146.872 C589.077,147.351 588.24,147.29 587.762,146.737" id="Fill-1189" class="icon-color"/>
      <path d="M587.511,140.608 C587.112,139.916 587.35,139.032 588.042,138.633 C588.734,138.234 589.618,138.472 590.017,139.164 C590.416,139.856 590.178,140.74 589.486,141.139 C588.794,141.537 587.91,141.3 587.511,140.608" id="Fill-1190" class="icon-color"/>
      <path d="M587.261,134.48 C586.942,133.649 587.356,132.718 588.186,132.399 C589.017,132.08 589.948,132.494 590.267,133.324 C590.586,134.154 590.172,135.086 589.342,135.405 C588.511,135.724 587.58,135.31 587.261,134.48" id="Fill-1191" class="icon-color"/>
      <path d="M587.01,128.351 C586.771,127.383 587.362,126.403 588.331,126.164 C589.299,125.925 590.279,126.516 590.518,127.485 C590.757,128.453 590.166,129.432 589.197,129.672 C588.229,129.911 587.25,129.32 587.01,128.351" id="Fill-1192" class="icon-color"/>
      <path d="M586.76,122.223 C586.6,121.116 587.368,120.089 588.475,119.929 C589.582,119.77 590.609,120.538 590.768,121.645 C590.928,122.752 590.16,123.779 589.053,123.938 C587.946,124.098 586.919,123.33 586.76,122.223" id="Fill-1193" class="icon-color"/>
      <path d="M586.509,116.094 C586.429,114.849 587.374,113.775 588.62,113.695 C589.865,113.615 590.939,114.56 591.019,115.805 C591.099,117.051 590.154,118.125 588.909,118.205 C587.663,118.284 586.589,117.34 586.509,116.094" id="Fill-1194" class="icon-color"/>
      <path d="M586.654,103.982 C586.654,102.816 587.599,101.871 588.764,101.871 C589.93,101.871 590.875,102.816 590.875,103.982 C590.875,105.147 589.93,106.092 588.764,106.092 C587.599,106.092 586.654,105.147 586.654,103.982" id="Fill-1195" class="icon-color"/>
      <path d="M587.048,97.998 C587.048,97.05 587.816,96.282 588.764,96.282 C589.711,96.282 590.479,97.05 590.479,97.998 C590.479,98.945 589.711,99.713 588.764,99.713 C587.816,99.713 587.048,98.945 587.048,97.998" id="Fill-1196" class="icon-color"/>
      <path d="M587.443,92.014 C587.443,91.284 588.035,90.693 588.764,90.693 C589.493,90.693 590.085,91.284 590.085,92.014 C590.085,92.743 589.493,93.334 588.764,93.334 C588.035,93.334 587.443,92.743 587.443,92.014" id="Fill-1197" class="icon-color"/>
      <path d="M587.839,86.03 C587.839,85.519 588.253,85.104 588.764,85.104 C589.275,85.104 589.69,85.519 589.69,86.03 C589.69,86.541 589.275,86.955 588.764,86.955 C588.253,86.955 587.839,86.541 587.839,86.03" id="Fill-1198" class="icon-color"/>
      <path d="M588.233,80.046 C588.233,79.752 588.471,79.515 588.764,79.515 C589.057,79.515 589.295,79.752 589.295,80.046 C589.295,80.339 589.057,80.576 588.764,80.576 C588.471,80.576 588.233,80.339 588.233,80.046" id="Fill-1199" class="icon-color"/>
      <path d="M588.628,74.062 C588.628,73.987 588.689,73.926 588.764,73.926 C588.839,73.926 588.9,73.987 588.9,74.062 C588.9,74.137 588.839,74.197 588.764,74.197 C588.689,74.197 588.628,74.137 588.628,74.062" id="Fill-1200" class="icon-color"/>
      <path d="M589.023,68.078 C589.023,68.221 588.907,68.337 588.764,68.337 C588.621,68.337 588.505,68.221 588.505,68.078 C588.505,67.934 588.621,67.818 588.764,67.818 C588.907,67.818 589.023,67.934 589.023,68.078" id="Fill-1201" class="icon-color"/>
      <path d="M589.418,62.094 C589.418,62.455 589.125,62.748 588.764,62.748 C588.403,62.748 588.11,62.455 588.11,62.094 C588.11,61.732 588.403,61.439 588.764,61.439 C589.125,61.439 589.418,61.732 589.418,62.094" id="Fill-1202" class="icon-color"/>
      <path d="M589.813,56.11 C589.813,56.689 589.344,57.159 588.764,57.159 C588.185,57.159 587.715,56.689 587.715,56.11 C587.715,55.53 588.185,55.06 588.764,55.06 C589.344,55.06 589.813,55.53 589.813,56.11" id="Fill-1203" class="icon-color"/>
      <path d="M590.208,50.126 C590.208,50.923 589.562,51.57 588.764,51.57 C587.966,51.57 587.32,50.923 587.32,50.126 C587.32,49.328 587.966,48.681 588.764,48.681 C589.562,48.681 590.208,49.328 590.208,50.126" id="Fill-1204" class="icon-color"/>
      <path d="M591.269,109.966 C591.269,111.349 590.148,112.471 588.764,112.471 C587.38,112.471 586.259,111.349 586.259,109.966 C586.259,108.582 587.38,107.46 588.764,107.46 C590.148,107.46 591.269,108.582 591.269,109.966" id="Fill-1205" class="icon-color"/>
      <path d="M562.441,67.2 C562.441,66.338 563.14,65.64 564.002,65.64 C564.863,65.64 565.562,66.338 565.562,67.2 C565.562,68.062 564.863,68.761 564.002,68.761 C563.14,68.761 562.441,68.062 562.441,67.2" id="Fill-1206" class="icon-color"/>
      <path d="M564.272,72.462 C565.048,72.611 565.555,73.361 565.406,74.137 C565.257,74.912 564.506,75.42 563.731,75.27 C562.955,75.121 562.448,74.371 562.597,73.595 C562.747,72.82 563.497,72.312 564.272,72.462" id="Fill-1207" class="icon-color"/>
      <path d="M564.543,79.283 C565.233,79.583 565.549,80.384 565.25,81.073 C564.951,81.763 564.15,82.079 563.46,81.78 C562.771,81.481 562.454,80.68 562.753,79.99 C563.052,79.301 563.854,78.984 564.543,79.283" id="Fill-1208" class="icon-color"/>
      <path d="M564.814,86.105 C565.417,86.554 565.542,87.406 565.094,88.01 C564.645,88.613 563.793,88.738 563.19,88.29 C562.586,87.841 562.461,86.989 562.909,86.385 C563.358,85.782 564.211,85.657 564.814,86.105" id="Fill-1209" class="icon-color"/>
      <path d="M565.084,92.927 C565.602,93.525 565.536,94.429 564.938,94.946 C564.34,95.463 563.436,95.398 562.919,94.8 C562.402,94.202 562.467,93.298 563.065,92.78 C563.664,92.263 564.568,92.329 565.084,92.927" id="Fill-1210" class="icon-color"/>
      <path d="M565.355,99.749 C565.786,100.496 565.529,101.452 564.782,101.883 C564.034,102.314 563.079,102.057 562.648,101.309 C562.217,100.562 562.474,99.606 563.221,99.176 C563.969,98.745 564.924,99.001 565.355,99.749" id="Fill-1211" class="icon-color"/>
      <path d="M565.626,106.571 C565.971,107.468 565.523,108.475 564.626,108.819 C563.729,109.164 562.722,108.716 562.377,107.819 C562.033,106.922 562.48,105.915 563.378,105.571 C564.274,105.226 565.281,105.674 565.626,106.571" id="Fill-1212" class="icon-color"/>
      <path d="M565.897,113.393 C566.155,114.439 565.516,115.497 564.47,115.756 C563.423,116.014 562.365,115.376 562.107,114.329 C561.848,113.282 562.487,112.224 563.534,111.966 C564.58,111.707 565.638,112.346 565.897,113.393" id="Fill-1213" class="icon-color"/>
      <path d="M566.167,120.215 C566.34,121.411 565.51,122.52 564.314,122.692 C563.118,122.865 562.008,122.035 561.836,120.839 C561.664,119.643 562.493,118.533 563.69,118.361 C564.886,118.189 565.995,119.018 566.167,120.215" id="Fill-1214" class="icon-color"/>
      <path d="M566.438,127.036 C566.524,128.382 565.503,129.543 564.158,129.629 C562.812,129.715 561.651,128.694 561.565,127.348 C561.479,126.003 562.5,124.842 563.846,124.756 C565.191,124.67 566.352,125.691 566.438,127.036" id="Fill-1215" class="icon-color"/>
      <path d="M561.295,133.858 C561.295,132.363 562.506,131.151 564.002,131.151 C565.497,131.151 566.709,132.363 566.709,133.858 C566.709,135.353 565.497,136.565 564.002,136.565 C562.506,136.565 561.295,135.353 561.295,133.858" id="Fill-1216" class="icon-color"/>
      <path d="M565.855,147.686 C565.855,148.71 565.025,149.54 564.002,149.54 C562.978,149.54 562.148,148.71 562.148,147.686 C562.148,146.663 562.978,145.833 564.002,145.833 C565.025,145.833 565.855,146.663 565.855,147.686" id="Fill-1217" class="icon-color"/>
      <path d="M565.429,154.601 C565.429,155.388 564.79,156.027 564.002,156.027 C563.214,156.027 562.575,155.388 562.575,154.601 C562.575,153.812 563.214,153.174 564.002,153.174 C564.79,153.174 565.429,153.812 565.429,154.601" id="Fill-1218" class="icon-color"/>
      <path d="M565.002,161.515 C565.002,162.067 564.554,162.515 564.002,162.515 C563.449,162.515 563.001,162.067 563.001,161.515 C563.001,160.962 563.449,160.515 564.002,160.515 C564.554,160.515 565.002,160.962 565.002,161.515" id="Fill-1219" class="icon-color"/>
      <path d="M564.575,168.429 C564.575,168.745 564.318,169.002 564.002,169.002 C563.685,169.002 563.428,168.745 563.428,168.429 C563.428,168.112 563.685,167.855 564.002,167.855 C564.318,167.855 564.575,168.112 564.575,168.429" id="Fill-1220" class="icon-color"/>
      <path d="M564.148,175.343 C564.148,175.424 564.083,175.489 564.002,175.489 C563.921,175.489 563.855,175.424 563.855,175.343 C563.855,175.262 563.921,175.196 564.002,175.196 C564.083,175.196 564.148,175.262 564.148,175.343" id="Fill-1221" class="icon-color"/>
      <path d="M563.721,182.257 C563.721,182.102 563.847,181.977 564.002,181.977 C564.156,181.977 564.282,182.102 564.282,182.257 C564.282,182.412 564.156,182.537 564.002,182.537 C563.847,182.537 563.721,182.412 563.721,182.257" id="Fill-1222" class="icon-color"/>
      <path d="M563.295,189.171 C563.295,188.781 563.611,188.464 564.002,188.464 C564.392,188.464 564.709,188.781 564.709,189.171 C564.709,189.562 564.392,189.878 564.002,189.878 C563.611,189.878 563.295,189.562 563.295,189.171" id="Fill-1223" class="icon-color"/>
      <path d="M562.868,196.085 C562.868,195.459 563.375,194.952 564.002,194.952 C564.628,194.952 565.135,195.459 565.135,196.085 C565.135,196.711 564.628,197.219 564.002,197.219 C563.375,197.219 562.868,196.711 562.868,196.085" id="Fill-1224" class="icon-color"/>
      <path d="M562.441,202.999 C562.441,202.138 563.14,201.439 564.002,201.439 C564.863,201.439 565.562,202.138 565.562,202.999 C565.562,203.861 564.863,204.56 564.002,204.56 C563.14,204.56 562.441,203.861 562.441,202.999" id="Fill-1225" class="icon-color"/>
      <path d="M566.282,140.772 C566.282,142.032 565.261,143.053 564.002,143.053 C562.742,143.053 561.721,142.032 561.721,140.772 C561.721,139.513 562.742,138.492 564.002,138.492 C565.261,138.492 566.282,139.513 566.282,140.772" id="Fill-1226" class="icon-color"/>
      <path d="M584.287,183.188 C584.287,184.028 583.606,184.709 582.766,184.709 C581.926,184.709 581.245,184.028 581.245,183.188 C581.245,182.348 581.926,181.667 582.766,181.667 C583.606,181.667 584.287,182.348 584.287,183.188" id="Fill-1227" class="icon-color"/>
      <path d="M582.472,178.316 C581.726,178.154 581.252,177.417 581.414,176.67 C581.576,175.924 582.312,175.45 583.059,175.612 C583.806,175.773 584.28,176.51 584.118,177.257 C583.956,178.004 583.219,178.478 582.472,178.316" id="Fill-1228" class="icon-color"/>
      <path d="M582.179,171.922 C581.526,171.599 581.259,170.806 581.583,170.153 C581.906,169.5 582.699,169.232 583.352,169.556 C584.006,169.88 584.273,170.672 583.949,171.326 C583.625,171.979 582.833,172.246 582.179,171.922" id="Fill-1229" class="icon-color"/>
      <path d="M581.886,165.529 C581.326,165.043 581.266,164.195 581.752,163.635 C582.237,163.075 583.085,163.015 583.645,163.501 C584.205,163.987 584.266,164.834 583.78,165.395 C583.294,165.955 582.446,166.015 581.886,165.529" id="Fill-1230" class="icon-color"/>
      <path d="M581.593,159.135 C581.126,158.488 581.273,157.584 581.921,157.118 C582.568,156.651 583.472,156.798 583.939,157.445 C584.405,158.093 584.259,158.997 583.611,159.463 C582.963,159.93 582.06,159.783 581.593,159.135" id="Fill-1231" class="icon-color"/>
      <path d="M581.3,152.742 C580.926,151.932 581.28,150.973 582.09,150.6 C582.899,150.226 583.858,150.58 584.232,151.39 C584.605,152.2 584.252,153.159 583.442,153.532 C582.632,153.905 581.673,153.552 581.3,152.742" id="Fill-1232" class="icon-color"/>
      <path d="M581.006,146.349 C580.726,145.377 581.287,144.362 582.259,144.082 C583.23,143.802 584.245,144.363 584.525,145.335 C584.805,146.306 584.245,147.321 583.273,147.601 C582.301,147.881 581.286,147.32 581.006,146.349" id="Fill-1233" class="icon-color"/>
      <path d="M580.713,139.955 C580.526,138.822 581.294,137.751 582.428,137.565 C583.561,137.378 584.632,138.146 584.818,139.279 C585.005,140.413 584.237,141.483 583.104,141.67 C581.97,141.856 580.9,141.089 580.713,139.955" id="Fill-1234" class="icon-color"/>
      <path d="M580.42,133.562 C580.326,132.266 581.301,131.14 582.597,131.047 C583.892,130.954 585.018,131.928 585.112,133.224 C585.205,134.519 584.23,135.645 582.935,135.739 C581.639,135.832 580.513,134.857 580.42,133.562" id="Fill-1235" class="icon-color"/>
      <path d="M580.589,120.165 C580.589,118.962 581.563,117.988 582.766,117.988 C583.968,117.988 584.943,118.962 584.943,120.165 C584.943,121.367 583.968,122.342 582.766,122.342 C581.563,122.342 580.589,121.367 580.589,120.165" id="Fill-1236" class="icon-color"/>
      <path d="M581.051,113.161 C581.051,112.214 581.819,111.447 582.766,111.447 C583.713,111.447 584.48,112.214 584.48,113.161 C584.48,114.108 583.713,114.876 582.766,114.876 C581.819,114.876 581.051,114.108 581.051,113.161" id="Fill-1237" class="icon-color"/>
      <path d="M581.513,106.158 C581.513,105.466 582.074,104.906 582.766,104.906 C583.457,104.906 584.018,105.466 584.018,106.158 C584.018,106.849 583.457,107.41 582.766,107.41 C582.074,107.41 581.513,106.849 581.513,106.158" id="Fill-1238" class="icon-color"/>
      <path d="M581.976,99.154 C581.976,98.718 582.329,98.364 582.766,98.364 C583.202,98.364 583.556,98.718 583.556,99.154 C583.556,99.591 583.202,99.944 582.766,99.944 C582.329,99.944 581.976,99.591 581.976,99.154" id="Fill-1239" class="icon-color"/>
      <path d="M582.438,92.151 C582.438,91.97 582.585,91.823 582.766,91.823 C582.947,91.823 583.094,91.97 583.094,92.151 C583.094,92.332 582.947,92.479 582.766,92.479 C582.585,92.479 582.438,92.332 582.438,92.151" id="Fill-1240" class="icon-color"/>
      <path d="M582.9,85.147 C582.9,85.222 582.84,85.282 582.766,85.282 C582.691,85.282 582.631,85.222 582.631,85.147 C582.631,85.073 582.691,85.013 582.766,85.013 C582.84,85.013 582.9,85.073 582.9,85.147" id="Fill-1241" class="icon-color"/>
      <path d="M583.362,78.144 C583.362,78.473 583.095,78.741 582.766,78.741 C582.436,78.741 582.169,78.473 582.169,78.144 C582.169,77.814 582.436,77.547 582.766,77.547 C583.095,77.547 583.362,77.814 583.362,78.144" id="Fill-1242" class="icon-color"/>
      <path d="M583.825,71.141 C583.825,71.725 583.35,72.199 582.766,72.199 C582.181,72.199 581.707,71.725 581.707,71.141 C581.707,70.556 582.181,70.082 582.766,70.082 C583.35,70.082 583.825,70.556 583.825,71.141" id="Fill-1243" class="icon-color"/>
      <path d="M584.287,64.137 C584.287,64.977 583.606,65.658 582.766,65.658 C581.926,65.658 581.245,64.977 581.245,64.137 C581.245,63.297 581.926,62.616 582.766,62.616 C583.606,62.616 584.287,63.297 584.287,64.137" id="Fill-1244" class="icon-color"/>
      <path d="M585.405,127.168 C585.405,128.626 584.223,129.807 582.766,129.807 C581.308,129.807 580.127,128.626 580.127,127.168 C580.127,125.711 581.308,124.529 582.766,124.529 C584.223,124.529 585.405,125.711 585.405,127.168" id="Fill-1245" class="icon-color"/>
      <path d="M592.056,90.729 C592.056,89.586 592.982,88.659 594.125,88.659 C595.268,88.659 596.195,89.586 596.195,90.729 C596.195,91.871 595.268,92.798 594.125,92.798 C592.982,92.798 592.056,91.871 592.056,90.729" id="Fill-1246" class="icon-color"/>
      <path d="M594.057,93.555 C595.086,93.517 595.95,94.32 595.988,95.349 C596.026,96.377 595.222,97.242 594.194,97.28 C593.165,97.317 592.3,96.514 592.263,95.485 C592.225,94.457 593.028,93.592 594.057,93.555" id="Fill-1247" class="icon-color"/>
      <path d="M593.989,98.45 C594.903,98.375 595.706,99.055 595.781,99.969 C595.856,100.884 595.176,101.686 594.262,101.761 C593.347,101.837 592.545,101.157 592.47,100.242 C592.394,99.328 593.074,98.525 593.989,98.45" id="Fill-1248" class="icon-color"/>
      <path d="M593.982,103.346 C594.782,103.233 595.522,103.79 595.635,104.59 C595.748,105.39 595.191,106.13 594.391,106.243 C593.591,106.356 592.851,105.799 592.738,104.999 C592.625,104.199 593.182,103.459 593.982,103.346" id="Fill-1249" class="icon-color"/>
      <path d="M593.933,108.241 C594.618,108.091 595.297,108.524 595.447,109.21 C595.598,109.896 595.164,110.574 594.479,110.725 C593.793,110.876 593.115,110.442 592.964,109.756 C592.813,109.07 593.247,108.392 593.933,108.241" id="Fill-1250" class="icon-color"/>
      <path d="M593.883,113.137 C594.455,112.948 595.071,113.259 595.26,113.83 C595.448,114.402 595.138,115.018 594.566,115.206 C593.995,115.395 593.378,115.084 593.19,114.513 C593.002,113.942 593.312,113.325 593.883,113.137" id="Fill-1251" class="icon-color"/>
      <path d="M593.834,118.032 C594.292,117.806 594.846,117.994 595.072,118.451 C595.298,118.908 595.111,119.462 594.654,119.688 C594.196,119.914 593.642,119.727 593.416,119.27 C593.19,118.813 593.377,118.259 593.834,118.032" id="Fill-1252" class="icon-color"/>
      <path d="M593.752,122.928 C594.094,122.664 594.586,122.728 594.85,123.071 C595.114,123.414 595.05,123.906 594.707,124.17 C594.364,124.434 593.873,124.37 593.609,124.027 C593.345,123.684 593.409,123.192 593.752,122.928" id="Fill-1253" class="icon-color"/>
      <path d="M593.647,127.823 C593.876,127.522 594.306,127.463 594.607,127.691 C594.909,127.92 594.968,128.35 594.74,128.651 C594.511,128.953 594.081,129.012 593.78,128.783 C593.478,128.555 593.419,128.125 593.647,127.823" id="Fill-1254" class="icon-color"/>
      <path d="M593.511,132.719 C593.625,132.38 593.993,132.197 594.332,132.312 C594.672,132.426 594.854,132.793 594.74,133.133 C594.625,133.472 594.258,133.655 593.919,133.54 C593.579,133.426 593.397,133.058 593.511,132.719" id="Fill-1255" class="icon-color"/>
      <path d="M593.443,137.614 C593.443,137.237 593.748,136.932 594.125,136.932 C594.502,136.932 594.808,137.237 594.808,137.614 C594.808,137.991 594.502,138.297 594.125,138.297 C593.748,138.297 593.443,137.991 593.443,137.614" id="Fill-1256" class="icon-color"/>
      <path d="M594.194,87.059 C593.165,87.096 592.301,86.293 592.263,85.264 C592.225,84.236 593.029,83.371 594.057,83.333 C595.086,83.296 595.951,84.099 595.988,85.128 C596.026,86.156 595.223,87.021 594.194,87.059" id="Fill-1257" class="icon-color"/>
      <path d="M594.263,81.343 C593.348,81.419 592.546,80.738 592.47,79.824 C592.395,78.91 593.075,78.107 593.99,78.032 C594.904,77.957 595.706,78.637 595.782,79.551 C595.857,80.465 595.177,81.268 594.263,81.343" id="Fill-1258" class="icon-color"/>
      <path d="M594.259,75.628 C593.459,75.741 592.719,75.184 592.606,74.384 C592.492,73.584 593.049,72.843 593.849,72.73 C594.649,72.617 595.39,73.174 595.503,73.974 C595.616,74.774 595.059,75.515 594.259,75.628" id="Fill-1259" class="icon-color"/>
      <path d="M594.305,69.912 C593.619,70.063 592.941,69.629 592.79,68.944 C592.639,68.258 593.073,67.579 593.759,67.429 C594.444,67.278 595.123,67.712 595.273,68.397 C595.424,69.083 594.99,69.761 594.305,69.912" id="Fill-1260" class="icon-color"/>
      <path d="M594.35,64.197 C593.779,64.385 593.163,64.075 592.974,63.503 C592.786,62.932 593.096,62.316 593.668,62.127 C594.239,61.939 594.855,62.249 595.044,62.821 C595.232,63.392 594.922,64.008 594.35,64.197" id="Fill-1261" class="icon-color"/>
      <path d="M594.425,58.481 C593.968,58.707 593.414,58.52 593.187,58.063 C592.961,57.606 593.148,57.052 593.606,56.826 C594.063,56.599 594.617,56.787 594.843,57.244 C595.069,57.701 594.882,58.255 594.425,58.481" id="Fill-1262" class="icon-color"/>
      <path d="M594.521,52.766 C594.179,53.03 593.687,52.966 593.423,52.623 C593.159,52.28 593.223,51.788 593.566,51.524 C593.909,51.26 594.401,51.324 594.665,51.667 C594.928,52.01 594.864,52.502 594.521,52.766" id="Fill-1263" class="icon-color"/>
      <path d="M594.618,47.05 C594.39,47.352 593.96,47.411 593.658,47.183 C593.357,46.954 593.297,46.524 593.526,46.223 C593.755,45.921 594.184,45.862 594.486,46.09 C594.788,46.319 594.847,46.749 594.618,47.05" id="Fill-1264" class="icon-color"/>
      <path d="M594.743,41.335 C594.629,41.674 594.261,41.857 593.922,41.742 C593.582,41.628 593.4,41.26 593.514,40.921 C593.628,40.582 593.996,40.399 594.336,40.513 C594.675,40.628 594.857,40.996 594.743,41.335" id="Fill-1265" class="icon-color"/>
      <path d="M594.812,35.619 C594.812,35.996 594.506,36.302 594.129,36.302 C593.752,36.302 593.446,35.996 593.446,35.619 C593.446,35.242 593.752,34.937 594.129,34.937 C594.506,34.937 594.812,35.242 594.812,35.619" id="Fill-1266" class="icon-color"/>
      <path d="M596.195,90.705 C596.195,91.848 595.268,92.774 594.125,92.774 C592.982,92.774 592.056,91.848 592.056,90.705 C592.056,89.562 592.982,88.635 594.125,88.635 C595.268,88.635 596.195,89.562 596.195,90.705" id="Fill-1267" class="icon-color"/>
      <path d="M573.91,124.505 C573.91,123.055 575.085,121.879 576.535,121.879 C577.985,121.879 579.16,123.055 579.16,124.505 C579.16,125.955 577.985,127.13 576.535,127.13 C575.085,127.13 573.91,125.955 573.91,124.505" id="Fill-1268" class="icon-color"/>
      <path d="M576.448,128.09 C577.753,128.042 578.85,129.061 578.898,130.366 C578.946,131.67 577.927,132.767 576.622,132.815 C575.317,132.863 574.22,131.844 574.172,130.539 C574.125,129.234 575.143,128.137 576.448,128.09" id="Fill-1269" class="icon-color"/>
      <path d="M576.415,134.3 C577.575,134.204 578.593,135.067 578.688,136.227 C578.784,137.387 577.921,138.404 576.761,138.5 C575.602,138.596 574.584,137.733 574.488,136.573 C574.392,135.413 575.255,134.395 576.415,134.3" id="Fill-1270" class="icon-color"/>
      <path d="M576.353,140.51 C577.368,140.366 578.307,141.073 578.45,142.088 C578.594,143.102 577.887,144.041 576.872,144.185 C575.857,144.328 574.918,143.622 574.775,142.607 C574.631,141.592 575.338,140.653 576.353,140.51" id="Fill-1271" class="icon-color"/>
      <path d="M576.29,146.72 C577.16,146.528 578.021,147.079 578.212,147.948 C578.403,148.818 577.853,149.678 576.983,149.87 C576.113,150.061 575.253,149.511 575.062,148.641 C574.87,147.771 575.42,146.911 576.29,146.72" id="Fill-1272" class="icon-color"/>
      <path d="M576.228,152.93 C576.953,152.691 577.735,153.084 577.974,153.809 C578.213,154.534 577.819,155.316 577.094,155.555 C576.369,155.794 575.588,155.4 575.349,154.675 C575.109,153.95 575.503,153.169 576.228,152.93" id="Fill-1273" class="icon-color"/>
      <path d="M576.166,159.14 C576.746,158.853 577.449,159.09 577.736,159.67 C578.022,160.25 577.785,160.953 577.205,161.24 C576.625,161.527 575.922,161.289 575.635,160.709 C575.349,160.129 575.586,159.427 576.166,159.14" id="Fill-1274" class="icon-color"/>
      <path d="M576.061,165.35 C576.496,165.015 577.12,165.096 577.455,165.531 C577.789,165.966 577.708,166.59 577.273,166.925 C576.838,167.259 576.214,167.178 575.88,166.743 C575.545,166.308 575.626,165.684 576.061,165.35" id="Fill-1275" class="icon-color"/>
      <path d="M575.929,171.559 C576.219,171.177 576.764,171.102 577.147,171.392 C577.529,171.682 577.604,172.227 577.314,172.61 C577.024,172.992 576.479,173.067 576.096,172.777 C575.714,172.487 575.639,171.942 575.929,171.559" id="Fill-1276" class="icon-color"/>
      <path d="M575.756,177.769 C575.901,177.339 576.367,177.107 576.798,177.252 C577.228,177.397 577.459,177.864 577.314,178.294 C577.169,178.725 576.703,178.956 576.272,178.811 C575.842,178.666 575.611,178.2 575.756,177.769" id="Fill-1277" class="icon-color"/>
      <path d="M575.669,183.979 C575.669,183.501 576.057,183.113 576.535,183.113 C577.013,183.113 577.401,183.501 577.401,183.979 C577.401,184.457 577.013,184.845 576.535,184.845 C576.057,184.845 575.669,184.457 575.669,183.979" id="Fill-1278" class="icon-color"/>
      <path d="M576.622,120.67 C575.317,120.718 574.221,119.699 574.173,118.394 C574.125,117.089 575.144,115.992 576.449,115.944 C577.754,115.897 578.85,116.916 578.898,118.22 C578.946,119.525 577.927,120.622 576.622,120.67" id="Fill-1279" class="icon-color"/>
      <path d="M576.653,114.24 C575.493,114.335 574.475,113.473 574.38,112.313 C574.284,111.153 575.147,110.135 576.307,110.039 C577.467,109.944 578.484,110.807 578.58,111.966 C578.676,113.126 577.813,114.144 576.653,114.24" id="Fill-1280" class="icon-color"/>
      <path d="M576.714,107.81 C575.7,107.953 574.761,107.247 574.617,106.232 C574.474,105.217 575.18,104.278 576.195,104.135 C577.21,103.991 578.149,104.697 578.292,105.712 C578.436,106.727 577.729,107.666 576.714,107.81" id="Fill-1281" class="icon-color"/>
      <path d="M576.776,101.38 C575.906,101.571 575.046,101.021 574.854,100.151 C574.663,99.281 575.213,98.421 576.083,98.23 C576.953,98.038 577.813,98.589 578.005,99.458 C578.196,100.328 577.646,101.188 576.776,101.38" id="Fill-1282" class="icon-color"/>
      <path d="M576.837,94.95 C576.112,95.189 575.331,94.795 575.092,94.07 C574.853,93.345 575.247,92.564 575.971,92.325 C576.696,92.085 577.478,92.479 577.717,93.204 C577.956,93.929 577.562,94.711 576.837,94.95" id="Fill-1283" class="icon-color"/>
      <path d="M576.899,88.52 C576.319,88.807 575.616,88.569 575.329,87.989 C575.042,87.409 575.28,86.707 575.86,86.42 C576.44,86.133 577.142,86.37 577.429,86.95 C577.716,87.53 577.479,88.233 576.899,88.52" id="Fill-1284" class="icon-color"/>
      <path d="M577.017,82.09 C576.582,82.425 575.958,82.343 575.623,81.908 C575.289,81.474 575.37,80.85 575.805,80.515 C576.24,80.18 576.864,80.261 577.199,80.696 C577.533,81.131 577.452,81.755 577.017,82.09" id="Fill-1285" class="icon-color"/>
      <path d="M577.147,75.66 C576.857,76.042 576.311,76.118 575.929,75.828 C575.546,75.538 575.471,74.992 575.761,74.61 C576.051,74.227 576.596,74.152 576.979,74.442 C577.362,74.732 577.436,75.277 577.147,75.66" id="Fill-1286" class="icon-color"/>
      <path d="M577.318,69.23 C577.173,69.661 576.707,69.892 576.277,69.747 C575.846,69.602 575.615,69.136 575.76,68.705 C575.905,68.275 576.371,68.043 576.802,68.188 C577.232,68.333 577.464,68.8 577.318,69.23" id="Fill-1287" class="icon-color"/>
      <path d="M577.406,62.8 C577.406,63.278 577.018,63.666 576.54,63.666 C576.061,63.666 575.674,63.278 575.674,62.8 C575.674,62.322 576.061,61.934 576.54,61.934 C577.018,61.934 577.406,62.322 577.406,62.8" id="Fill-1288" class="icon-color"/>
      <path d="M579.16,124.474 C579.16,125.924 577.985,127.1 576.535,127.1 C575.085,127.1 573.91,125.924 573.91,124.474 C573.91,123.025 575.085,121.849 576.535,121.849 C577.985,121.849 579.16,123.025 579.16,124.474" id="Fill-1289" class="icon-color"/>
      <path d="M456.393,85.219 C456.393,84.421 457.039,83.775 457.837,83.775 C458.634,83.775 459.281,84.421 459.281,85.219 C459.281,86.016 458.634,86.663 457.837,86.663 C457.039,86.663 456.393,86.016 456.393,85.219" id="Fill-1290" class="icon-color"/>
      <path d="M458.087,89.903 C458.805,90.041 459.275,90.735 459.136,91.453 C458.998,92.171 458.304,92.641 457.586,92.503 C456.868,92.364 456.398,91.67 456.537,90.952 C456.675,90.234 457.369,89.765 458.087,89.903" id="Fill-1291" class="icon-color"/>
      <path d="M458.338,96.031 C458.976,96.308 459.269,97.05 458.992,97.688 C458.715,98.326 457.974,98.619 457.336,98.342 C456.697,98.065 456.405,97.324 456.681,96.686 C456.958,96.048 457.7,95.755 458.338,96.031" id="Fill-1292" class="icon-color"/>
      <path d="M458.588,102.16 C459.147,102.575 459.263,103.364 458.848,103.922 C458.432,104.481 457.643,104.597 457.085,104.182 C456.527,103.767 456.411,102.978 456.826,102.419 C457.241,101.861 458.03,101.745 458.588,102.16" id="Fill-1293" class="icon-color"/>
      <path d="M458.839,108.288 C459.318,108.842 459.257,109.679 458.703,110.157 C458.15,110.636 457.313,110.575 456.835,110.021 C456.356,109.468 456.417,108.631 456.97,108.153 C457.524,107.674 458.36,107.735 458.839,108.288" id="Fill-1294" class="icon-color"/>
      <path d="M459.089,114.417 C459.488,115.109 459.251,115.993 458.559,116.392 C457.867,116.79 456.983,116.553 456.584,115.861 C456.185,115.169 456.423,114.285 457.115,113.886 C457.806,113.487 458.691,113.725 459.089,114.417" id="Fill-1295" class="icon-color"/>
      <path d="M459.59,126.674 C459.83,127.642 459.239,128.621 458.27,128.861 C457.301,129.1 456.322,128.509 456.083,127.54 C455.844,126.571 456.435,125.592 457.404,125.353 C458.372,125.114 459.351,125.705 459.59,126.674" id="Fill-1296" class="icon-color"/>
      <path d="M459.841,132.802 C460.001,133.909 459.233,134.936 458.126,135.095 C457.019,135.255 455.992,134.487 455.832,133.38 C455.673,132.273 456.441,131.246 457.548,131.087 C458.655,130.927 459.681,131.695 459.841,132.802" id="Fill-1297" class="icon-color"/>
      <path d="M460.092,138.931 C460.171,140.176 459.227,141.25 457.981,141.33 C456.736,141.41 455.662,140.465 455.582,139.219 C455.502,137.974 456.447,136.9 457.692,136.82 C458.938,136.74 460.012,137.685 460.092,138.931" id="Fill-1298" class="icon-color"/>
      <path d="M455.331,145.059 C455.331,143.675 456.453,142.553 457.837,142.553 C459.22,142.553 460.342,143.675 460.342,145.059 C460.342,146.443 459.22,147.564 457.837,147.564 C456.453,147.564 455.331,146.443 455.331,145.059" id="Fill-1299" class="icon-color"/>
      <path d="M459.947,151.043 C459.947,152.209 459.002,153.153 457.837,153.153 C456.671,153.153 455.726,152.209 455.726,151.043 C455.726,149.877 456.671,148.933 457.837,148.933 C459.002,148.933 459.947,149.877 459.947,151.043" id="Fill-1300" class="icon-color"/>
      <path d="M459.552,157.027 C459.552,157.974 458.784,158.743 457.837,158.743 C456.889,158.743 456.121,157.974 456.121,157.027 C456.121,156.079 456.889,155.312 457.837,155.312 C458.784,155.312 459.552,156.079 459.552,157.027" id="Fill-1301" class="icon-color"/>
      <path d="M459.157,163.011 C459.157,163.741 458.566,164.332 457.837,164.332 C457.107,164.332 456.516,163.741 456.516,163.011 C456.516,162.282 457.107,161.691 457.837,161.691 C458.566,161.691 459.157,162.282 459.157,163.011" id="Fill-1302" class="icon-color"/>
      <path d="M458.762,168.995 C458.762,169.506 458.348,169.921 457.837,169.921 C457.326,169.921 456.911,169.506 456.911,168.995 C456.911,168.484 457.326,168.069 457.837,168.069 C458.348,168.069 458.762,168.484 458.762,168.995" id="Fill-1303" class="icon-color"/>
      <path d="M458.367,174.979 C458.367,175.272 458.13,175.51 457.837,175.51 C457.544,175.51 457.306,175.272 457.306,174.979 C457.306,174.686 457.544,174.449 457.837,174.449 C458.13,174.449 458.367,174.686 458.367,174.979" id="Fill-1304" class="icon-color"/>
      <path d="M457.972,180.963 C457.972,181.038 457.912,181.099 457.837,181.099 C457.762,181.099 457.701,181.038 457.701,180.963 C457.701,180.888 457.762,180.827 457.837,180.827 C457.912,180.827 457.972,180.888 457.972,180.963" id="Fill-1305" class="icon-color"/>
      <path d="M457.577,186.947 C457.577,186.804 457.694,186.688 457.837,186.688 C457.98,186.688 458.096,186.804 458.096,186.947 C458.096,187.09 457.98,187.207 457.837,187.207 C457.694,187.207 457.577,187.09 457.577,186.947" id="Fill-1306" class="icon-color"/>
      <path d="M457.183,192.931 C457.183,192.57 457.475,192.277 457.837,192.277 C458.198,192.277 458.491,192.57 458.491,192.931 C458.491,193.293 458.198,193.585 457.837,193.585 C457.475,193.585 457.183,193.293 457.183,192.931" id="Fill-1307" class="icon-color"/>
      <path d="M456.788,198.915 C456.788,198.336 457.257,197.866 457.837,197.866 C458.416,197.866 458.886,198.336 458.886,198.915 C458.886,199.495 458.416,199.964 457.837,199.964 C457.257,199.964 456.788,199.495 456.788,198.915" id="Fill-1308" class="icon-color"/>
      <path d="M456.393,204.899 C456.393,204.102 457.039,203.455 457.837,203.455 C458.634,203.455 459.281,204.102 459.281,204.899 C459.281,205.697 458.634,206.343 457.837,206.343 C457.039,206.343 456.393,205.697 456.393,204.899" id="Fill-1309" class="icon-color"/>
      <path d="M459.34,120.545 C459.659,121.375 459.245,122.307 458.414,122.626 C457.584,122.945 456.653,122.531 456.333,121.701 C456.014,120.87 456.429,119.939 457.259,119.62 C458.089,119.301 459.021,119.715 459.34,120.545" id="Fill-1310" class="icon-color"/>
      <path d="M465.356,181.693 C465.356,182.533 464.675,183.214 463.835,183.214 C462.995,183.214 462.314,182.533 462.314,181.693 C462.314,180.853 462.995,180.172 463.835,180.172 C464.675,180.172 465.356,180.853 465.356,181.693" id="Fill-1311" class="icon-color"/>
      <path d="M463.542,176.821 C462.795,176.659 462.321,175.922 462.483,175.176 C462.645,174.429 463.382,173.954 464.128,174.116 C464.875,174.278 465.349,175.015 465.187,175.762 C465.025,176.509 464.289,176.983 463.542,176.821" id="Fill-1312" class="icon-color"/>
      <path d="M463.249,170.427 C462.595,170.103 462.328,169.311 462.652,168.658 C462.976,168.004 463.768,167.737 464.421,168.061 C465.075,168.385 465.342,169.177 465.018,169.831 C464.694,170.484 463.902,170.751 463.249,170.427" id="Fill-1313" class="icon-color"/>
      <path d="M462.955,164.034 C462.395,163.548 462.335,162.7 462.821,162.14 C463.307,161.58 464.155,161.52 464.715,162.006 C465.275,162.491 465.335,163.339 464.849,163.9 C464.363,164.46 463.516,164.52 462.955,164.034" id="Fill-1314" class="icon-color"/>
      <path d="M462.662,157.64 C462.195,156.993 462.342,156.089 462.99,155.622 C463.638,155.156 464.541,155.303 465.008,155.95 C465.475,156.598 465.328,157.502 464.68,157.968 C464.032,158.435 463.129,158.288 462.662,157.64" id="Fill-1315" class="icon-color"/>
      <path d="M462.369,151.247 C461.996,150.437 462.349,149.478 463.159,149.105 C463.969,148.731 464.928,149.085 465.301,149.895 C465.675,150.704 465.321,151.664 464.511,152.037 C463.701,152.411 462.742,152.057 462.369,151.247" id="Fill-1316" class="icon-color"/>
      <path d="M462.076,144.853 C461.796,143.882 462.356,142.867 463.328,142.587 C464.3,142.307 465.314,142.868 465.594,143.839 C465.874,144.811 465.314,145.826 464.342,146.106 C463.37,146.386 462.356,145.825 462.076,144.853" id="Fill-1317" class="icon-color"/>
      <path d="M461.489,132.067 C461.396,130.771 462.37,129.645 463.666,129.552 C464.962,129.458 466.088,130.433 466.181,131.729 C466.274,133.024 465.3,134.15 464.004,134.243 C462.708,134.337 461.583,133.362 461.489,132.067" id="Fill-1318" class="icon-color"/>
      <path d="M466.474,125.673 C466.474,127.131 465.293,128.312 463.835,128.312 C462.378,128.312 461.196,127.131 461.196,125.673 C461.196,124.216 462.378,123.034 463.835,123.034 C465.293,123.034 466.474,124.216 466.474,125.673" id="Fill-1319" class="icon-color"/>
      <path d="M461.658,118.67 C461.658,117.468 462.633,116.493 463.835,116.493 C465.037,116.493 466.012,117.468 466.012,118.67 C466.012,119.872 465.037,120.846 463.835,120.846 C462.633,120.846 461.658,119.872 461.658,118.67" id="Fill-1320" class="icon-color"/>
      <path d="M462.12,111.666 C462.12,110.719 462.888,109.952 463.835,109.952 C464.782,109.952 465.549,110.719 465.549,111.666 C465.549,112.613 464.782,113.381 463.835,113.381 C462.888,113.381 462.12,112.613 462.12,111.666" id="Fill-1321" class="icon-color"/>
      <path d="M462.583,104.663 C462.583,103.971 463.143,103.411 463.835,103.411 C464.527,103.411 465.087,103.971 465.087,104.663 C465.087,105.354 464.527,105.915 463.835,105.915 C463.143,105.915 462.583,105.354 462.583,104.663" id="Fill-1322" class="icon-color"/>
      <path d="M463.045,97.659 C463.045,97.223 463.399,96.869 463.835,96.869 C464.271,96.869 464.625,97.223 464.625,97.659 C464.625,98.096 464.271,98.449 463.835,98.449 C463.399,98.449 463.045,98.096 463.045,97.659" id="Fill-1323" class="icon-color"/>
      <path d="M463.507,90.656 C463.507,90.475 463.654,90.328 463.835,90.328 C464.016,90.328 464.163,90.475 464.163,90.656 C464.163,90.837 464.016,90.984 463.835,90.984 C463.654,90.984 463.507,90.837 463.507,90.656" id="Fill-1324" class="icon-color"/>
      <path d="M463.97,83.652 C463.97,83.727 463.909,83.787 463.835,83.787 C463.761,83.787 463.701,83.727 463.701,83.652 C463.701,83.578 463.761,83.518 463.835,83.518 C463.909,83.518 463.97,83.578 463.97,83.652" id="Fill-1325" class="icon-color"/>
      <path d="M464.432,76.649 C464.432,76.979 464.164,77.246 463.835,77.246 C463.506,77.246 463.238,76.979 463.238,76.649 C463.238,76.319 463.506,76.052 463.835,76.052 C464.164,76.052 464.432,76.319 464.432,76.649" id="Fill-1326" class="icon-color"/>
      <path d="M464.894,69.646 C464.894,70.23 464.42,70.704 463.835,70.704 C463.25,70.704 462.776,70.23 462.776,69.646 C462.776,69.061 463.25,68.587 463.835,68.587 C464.42,68.587 464.894,69.061 464.894,69.646" id="Fill-1327" class="icon-color"/>
      <path d="M465.356,62.642 C465.356,63.482 464.675,64.163 463.835,64.163 C462.995,64.163 462.314,63.482 462.314,62.642 C462.314,61.802 462.995,61.121 463.835,61.121 C464.675,61.121 465.356,61.802 465.356,62.642" id="Fill-1328" class="icon-color"/>
      <path d="M461.783,138.46 C461.596,137.327 462.363,136.256 463.497,136.069 C464.631,135.883 465.701,136.65 465.888,137.784 C466.074,138.918 465.307,139.988 464.173,140.175 C463.039,140.361 461.969,139.594 461.783,138.46" id="Fill-1329" class="icon-color"/>
      <path d="M467.04,107.285 C467.04,105.835 468.216,104.66 469.666,104.66 C471.116,104.66 472.291,105.835 472.291,107.285 C472.291,108.735 471.116,109.91 469.666,109.91 C468.216,109.91 467.04,108.735 467.04,107.285" id="Fill-1330" class="icon-color"/>
      <path d="M469.579,110.87 C470.884,110.822 471.981,111.841 472.029,113.146 C472.076,114.451 471.057,115.547 469.752,115.595 C468.447,115.643 467.351,114.624 467.303,113.319 C467.255,112.014 468.274,110.917 469.579,110.87" id="Fill-1331" class="icon-color"/>
      <path d="M469.546,117.08 C470.706,116.984 471.723,117.847 471.819,119.007 C471.915,120.166 471.052,121.184 469.892,121.28 C468.732,121.376 467.714,120.513 467.619,119.353 C467.523,118.193 468.386,117.175 469.546,117.08" id="Fill-1332" class="icon-color"/>
      <path d="M469.421,129.5 C470.291,129.308 471.151,129.859 471.343,130.728 C471.534,131.598 470.984,132.459 470.114,132.65 C469.244,132.841 468.384,132.291 468.193,131.421 C468.001,130.551 468.551,129.691 469.421,129.5" id="Fill-1333" class="icon-color"/>
      <path d="M469.359,135.71 C470.084,135.471 470.865,135.864 471.105,136.589 C471.344,137.314 470.95,138.096 470.225,138.335 C469.5,138.574 468.718,138.18 468.479,137.455 C468.24,136.73 468.634,135.949 469.359,135.71" id="Fill-1334" class="icon-color"/>
      <path d="M469.297,141.92 C469.877,141.633 470.579,141.87 470.866,142.45 C471.153,143.03 470.916,143.733 470.336,144.02 C469.756,144.307 469.053,144.069 468.766,143.489 C468.479,142.909 468.717,142.207 469.297,141.92" id="Fill-1335" class="icon-color"/>
      <path d="M469.192,148.13 C469.627,147.795 470.251,147.876 470.585,148.311 C470.92,148.746 470.839,149.37 470.404,149.705 C469.969,150.04 469.345,149.958 469.01,149.523 C468.676,149.088 468.757,148.464 469.192,148.13" id="Fill-1336" class="icon-color"/>
      <path d="M469.06,154.34 C469.35,153.957 469.895,153.882 470.277,154.172 C470.66,154.462 470.735,155.007 470.445,155.39 C470.155,155.772 469.61,155.847 469.227,155.557 C468.845,155.267 468.77,154.722 469.06,154.34" id="Fill-1337" class="icon-color"/>
      <path d="M468.886,160.549 C469.031,160.119 469.498,159.888 469.928,160.032 C470.359,160.178 470.59,160.644 470.445,161.075 C470.3,161.505 469.834,161.736 469.403,161.591 C468.973,161.446 468.741,160.98 468.886,160.549" id="Fill-1338" class="icon-color"/>
      <path d="M468.8,166.759 C468.8,166.281 469.188,165.894 469.666,165.894 C470.144,165.894 470.532,166.281 470.532,166.759 C470.532,167.238 470.144,167.625 469.666,167.625 C469.188,167.625 468.8,167.238 468.8,166.759" id="Fill-1339" class="icon-color"/>
      <path d="M469.484,123.29 C470.499,123.146 471.437,123.853 471.581,124.868 C471.724,125.883 471.018,126.822 470.003,126.965 C468.988,127.108 468.049,126.402 467.906,125.387 C467.762,124.372 468.469,123.433 469.484,123.29" id="Fill-1340" class="icon-color"/>
      <path d="M569.366,165.17 C569.366,164.73 569.723,164.373 570.163,164.373 C570.603,164.373 570.96,164.73 570.96,165.17 C570.96,165.61 570.603,165.967 570.163,165.967 C569.723,165.967 569.366,165.61 569.366,165.17" id="Fill-1341" class="icon-color"/>
      <path d="M569.119,158.572 C569.119,157.996 569.586,157.528 570.163,157.528 C570.74,157.528 571.207,157.996 571.207,158.572 C571.207,159.149 570.74,159.616 570.163,159.616 C569.586,159.616 569.119,159.149 569.119,158.572" id="Fill-1342" class="icon-color"/>
      <path d="M568.872,151.974 C568.872,151.261 569.45,150.683 570.163,150.683 C570.876,150.683 571.454,151.261 571.454,151.974 C571.454,152.687 570.876,153.265 570.163,153.265 C569.45,153.265 568.872,152.687 568.872,151.974" id="Fill-1343" class="icon-color"/>
      <path d="M568.625,145.376 C568.625,144.527 569.314,143.838 570.163,143.838 C571.013,143.838 571.701,144.527 571.701,145.376 C571.701,146.226 571.013,146.914 570.163,146.914 C569.314,146.914 568.625,146.226 568.625,145.376" id="Fill-1344" class="icon-color"/>
      <path d="M568.378,138.778 C568.378,137.793 569.177,136.993 570.163,136.993 C571.149,136.993 571.948,137.793 571.948,138.778 C571.948,139.764 571.149,140.563 570.163,140.563 C569.177,140.563 568.378,139.764 568.378,138.778" id="Fill-1345" class="icon-color"/>
      <path d="M568.131,132.18 C568.131,131.058 569.041,130.148 570.163,130.148 C571.285,130.148 572.195,131.058 572.195,132.18 C572.195,133.302 571.285,134.212 570.163,134.212 C569.041,134.212 568.131,133.302 568.131,132.18" id="Fill-1346" class="icon-color"/>
      <path d="M567.884,125.582 C567.884,124.324 568.904,123.303 570.163,123.303 C571.422,123.303 572.442,124.324 572.442,125.582 C572.442,126.841 571.422,127.861 570.163,127.861 C568.904,127.861 567.884,126.841 567.884,125.582" id="Fill-1347" class="icon-color"/>
      <path d="M567.637,118.984 C567.637,117.589 568.768,116.458 570.163,116.458 C571.558,116.458 572.689,117.589 572.689,118.984 C572.689,120.379 571.558,121.51 570.163,121.51 C568.768,121.51 567.637,120.379 567.637,118.984" id="Fill-1348" class="icon-color"/>
      <path d="M567.39,112.386 C567.39,110.855 568.632,109.613 570.163,109.613 C571.695,109.613 572.936,110.855 572.936,112.386 C572.936,113.918 571.695,115.159 570.163,115.159 C568.632,115.159 567.39,113.918 567.39,112.386" id="Fill-1349" class="icon-color"/>
      <path d="M450.078,87.315 C450.078,86.418 450.805,85.69 451.703,85.69 C452.6,85.69 453.327,86.418 453.327,87.315 C453.327,88.213 452.6,88.94 451.703,88.94 C450.805,88.94 450.078,88.213 450.078,87.315" id="Fill-1350" class="icon-color"/>
      <path d="M451.984,92.586 C452.792,92.741 453.321,93.522 453.165,94.33 C453.009,95.138 452.228,95.666 451.421,95.51 C450.613,95.355 450.085,94.574 450.24,93.766 C450.396,92.958 451.177,92.43 451.984,92.586" id="Fill-1351" class="icon-color"/>
      <path d="M452.266,99.481 C452.984,99.792 453.314,100.627 453.003,101.345 C452.691,102.063 451.857,102.392 451.139,102.081 C450.421,101.77 450.091,100.935 450.403,100.217 C450.714,99.499 451.548,99.17 452.266,99.481" id="Fill-1352" class="icon-color"/>
      <path d="M452.83,113.272 C453.369,113.895 453.3,114.836 452.677,115.374 C452.055,115.913 451.113,115.845 450.575,115.222 C450.036,114.599 450.105,113.658 450.728,113.119 C451.35,112.581 452.292,112.649 452.83,113.272" id="Fill-1353" class="icon-color"/>
      <path d="M453.112,120.167 C453.561,120.946 453.293,121.941 452.515,122.389 C451.737,122.838 450.742,122.571 450.293,121.792 C449.844,121.014 450.112,120.019 450.89,119.57 C451.668,119.122 452.663,119.389 453.112,120.167" id="Fill-1354" class="icon-color"/>
      <path d="M453.394,127.063 C453.753,127.997 453.287,129.045 452.352,129.404 C451.418,129.763 450.37,129.297 450.011,128.363 C449.652,127.429 450.119,126.38 451.053,126.021 C451.987,125.662 453.035,126.129 453.394,127.063" id="Fill-1355" class="icon-color"/>
      <path d="M453.676,133.958 C453.945,135.048 453.28,136.15 452.19,136.419 C451.1,136.688 449.999,136.023 449.729,134.933 C449.46,133.843 450.125,132.741 451.215,132.472 C452.305,132.203 453.407,132.868 453.676,133.958" id="Fill-1356" class="icon-color"/>
      <path d="M453.958,140.853 C454.137,142.099 453.273,143.254 452.028,143.434 C450.782,143.613 449.627,142.749 449.447,141.504 C449.268,140.258 450.132,139.103 451.378,138.923 C452.623,138.744 453.778,139.608 453.958,140.853" id="Fill-1357" class="icon-color"/>
      <path d="M454.24,147.749 C454.329,149.15 453.266,150.359 451.865,150.448 C450.464,150.538 449.255,149.475 449.165,148.074 C449.076,146.673 450.139,145.464 451.54,145.374 C452.941,145.285 454.15,146.348 454.24,147.749" id="Fill-1358" class="icon-color"/>
      <path d="M448.884,154.644 C448.884,153.088 450.146,151.825 451.703,151.825 C453.259,151.825 454.522,153.088 454.522,154.644 C454.522,156.201 453.259,157.463 451.703,157.463 C450.146,157.463 448.884,156.201 448.884,154.644" id="Fill-1359" class="icon-color"/>
      <path d="M454.077,161.377 C454.077,162.689 453.014,163.752 451.703,163.752 C450.391,163.752 449.328,162.689 449.328,161.377 C449.328,160.066 450.391,159.003 451.703,159.003 C453.014,159.003 454.077,160.066 454.077,161.377" id="Fill-1360" class="icon-color"/>
      <path d="M453.633,168.11 C453.633,169.176 452.769,170.04 451.703,170.04 C450.637,170.04 449.772,169.176 449.772,168.11 C449.772,167.044 450.637,166.18 451.703,166.18 C452.769,166.18 453.633,167.044 453.633,168.11" id="Fill-1361" class="icon-color"/>
      <path d="M453.188,174.843 C453.188,175.664 452.523,176.329 451.703,176.329 C450.882,176.329 450.217,175.664 450.217,174.843 C450.217,174.023 450.882,173.357 451.703,173.357 C452.523,173.357 453.188,174.023 453.188,174.843" id="Fill-1362" class="icon-color"/>
      <path d="M452.744,181.576 C452.744,182.151 452.278,182.618 451.703,182.618 C451.127,182.618 450.661,182.151 450.661,181.576 C450.661,181.001 451.127,180.535 451.703,180.535 C452.278,180.535 452.744,181.001 452.744,181.576" id="Fill-1363" class="icon-color"/>
      <path d="M452.3,188.309 C452.3,188.639 452.032,188.906 451.703,188.906 C451.373,188.906 451.106,188.639 451.106,188.309 C451.106,187.979 451.373,187.712 451.703,187.712 C452.032,187.712 452.3,187.979 452.3,188.309" id="Fill-1364" class="icon-color"/>
      <path d="M451.855,195.042 C451.855,195.126 451.787,195.194 451.703,195.194 C451.618,195.194 451.55,195.126 451.55,195.042 C451.55,194.958 451.618,194.889 451.703,194.889 C451.787,194.889 451.855,194.958 451.855,195.042" id="Fill-1365" class="icon-color"/>
      <path d="M451.411,201.775 C451.411,201.614 451.541,201.483 451.703,201.483 C451.864,201.483 451.994,201.614 451.994,201.775 C451.994,201.936 451.864,202.066 451.703,202.066 C451.541,202.066 451.411,201.936 451.411,201.775" id="Fill-1366" class="icon-color"/>
      <path d="M450.966,208.508 C450.966,208.101 451.296,207.772 451.703,207.772 C452.109,207.772 452.439,208.101 452.439,208.508 C452.439,208.914 452.109,209.244 451.703,209.244 C451.296,209.244 450.966,208.914 450.966,208.508" id="Fill-1367" class="icon-color"/>
      <path d="M450.522,215.241 C450.522,214.589 451.051,214.06 451.703,214.06 C452.355,214.06 452.883,214.589 452.883,215.241 C452.883,215.893 452.355,216.421 451.703,216.421 C451.051,216.421 450.522,215.893 450.522,215.241" id="Fill-1368" class="icon-color"/>
      <path d="M450.078,221.974 C450.078,221.076 450.805,220.349 451.703,220.349 C452.6,220.349 453.327,221.076 453.327,221.974 C453.327,222.871 452.6,223.599 451.703,223.599 C450.805,223.599 450.078,222.871 450.078,221.974" id="Fill-1369" class="icon-color"/>
      <path d="M452.548,106.376 C453.176,106.844 453.307,107.732 452.84,108.36 C452.373,108.988 451.485,109.118 450.857,108.651 C450.229,108.184 450.098,107.296 450.565,106.668 C451.032,106.04 451.92,105.909 452.548,106.376" id="Fill-1370" class="icon-color"/>
      <path d="M446.811,164.416 C446.811,165.278 446.112,165.976 445.25,165.976 C444.389,165.976 443.69,165.278 443.69,164.416 C443.69,163.554 444.389,162.855 445.25,162.855 C446.112,162.855 446.811,163.554 446.811,164.416" id="Fill-1371" class="icon-color"/>
      <path d="M444.98,159.354 C444.204,159.205 443.697,158.455 443.846,157.679 C443.995,156.903 444.746,156.396 445.521,156.545 C446.297,156.695 446.804,157.445 446.655,158.221 C446.505,158.996 445.755,159.504 444.98,159.354" id="Fill-1372" class="icon-color"/>
      <path d="M444.709,152.732 C444.02,152.433 443.703,151.632 444.002,150.943 C444.301,150.253 445.102,149.937 445.792,150.236 C446.481,150.535 446.798,151.336 446.499,152.025 C446.2,152.715 445.398,153.032 444.709,152.732" id="Fill-1373" class="icon-color"/>
      <path d="M444.438,146.111 C443.835,145.662 443.71,144.809 444.158,144.206 C444.607,143.603 445.459,143.477 446.062,143.926 C446.666,144.375 446.791,145.227 446.343,145.83 C445.894,146.434 445.042,146.559 444.438,146.111" id="Fill-1374" class="icon-color"/>
      <path d="M444.168,139.489 C443.65,138.891 443.716,137.987 444.314,137.47 C444.912,136.952 445.816,137.018 446.333,137.616 C446.85,138.214 446.785,139.118 446.187,139.635 C445.589,140.152 444.684,140.087 444.168,139.489" id="Fill-1375" class="icon-color"/>
      <path d="M443.897,132.867 C443.466,132.119 443.723,131.164 444.47,130.733 C445.218,130.302 446.173,130.559 446.604,131.306 C447.035,132.054 446.778,133.009 446.031,133.44 C445.283,133.871 444.328,133.614 443.897,132.867" id="Fill-1376" class="icon-color"/>
      <path d="M443.355,119.623 C443.097,118.577 443.736,117.519 444.782,117.26 C445.829,117.001 446.887,117.64 447.146,118.687 C447.404,119.734 446.765,120.791 445.719,121.05 C444.672,121.308 443.614,120.67 443.355,119.623" id="Fill-1377" class="icon-color"/>
      <path d="M443.085,113.001 C442.912,111.805 443.742,110.696 444.938,110.523 C446.134,110.351 447.244,111.181 447.416,112.377 C447.589,113.573 446.758,114.682 445.563,114.855 C444.366,115.027 443.257,114.197 443.085,113.001" id="Fill-1378" class="icon-color"/>
      <path d="M442.814,106.379 C442.728,105.034 443.749,103.873 445.094,103.787 C446.44,103.701 447.601,104.722 447.687,106.067 C447.773,107.413 446.752,108.574 445.406,108.66 C444.061,108.746 442.9,107.725 442.814,106.379" id="Fill-1379" class="icon-color"/>
      <path d="M447.957,99.757 C447.957,101.253 446.746,102.465 445.25,102.465 C443.755,102.465 442.543,101.253 442.543,99.757 C442.543,98.262 443.755,97.05 445.25,97.05 C446.746,97.05 447.957,98.262 447.957,99.757" id="Fill-1380" class="icon-color"/>
      <path d="M442.97,92.243 C442.97,90.984 443.991,89.963 445.25,89.963 C446.51,89.963 447.531,90.984 447.531,92.243 C447.531,93.503 446.51,94.524 445.25,94.524 C443.991,94.524 442.97,93.503 442.97,92.243" id="Fill-1381" class="icon-color"/>
      <path d="M443.397,84.729 C443.397,83.706 444.227,82.876 445.25,82.876 C446.274,82.876 447.104,83.706 447.104,84.729 C447.104,85.753 446.274,86.583 445.25,86.583 C444.227,86.583 443.397,85.753 443.397,84.729" id="Fill-1382" class="icon-color"/>
      <path d="M443.824,77.215 C443.824,76.427 444.462,75.788 445.25,75.788 C446.038,75.788 446.677,76.427 446.677,77.215 C446.677,78.003 446.038,78.642 445.25,78.642 C444.462,78.642 443.824,78.003 443.824,77.215" id="Fill-1383" class="icon-color"/>
      <path d="M444.25,69.701 C444.25,69.149 444.698,68.701 445.25,68.701 C445.803,68.701 446.25,69.149 446.25,69.701 C446.25,70.254 445.803,70.701 445.25,70.701 C444.698,70.701 444.25,70.254 444.25,69.701" id="Fill-1384" class="icon-color"/>
      <path d="M444.677,62.187 C444.677,61.87 444.934,61.614 445.25,61.614 C445.567,61.614 445.824,61.87 445.824,62.187 C445.824,62.504 445.567,62.761 445.25,62.761 C444.934,62.761 444.677,62.504 444.677,62.187" id="Fill-1385" class="icon-color"/>
      <path d="M445.104,54.673 C445.104,54.592 445.169,54.527 445.25,54.527 C445.331,54.527 445.397,54.592 445.397,54.673 C445.397,54.754 445.331,54.82 445.25,54.82 C445.169,54.82 445.104,54.754 445.104,54.673" id="Fill-1386" class="icon-color"/>
      <path d="M445.53,47.159 C445.53,47.314 445.405,47.439 445.25,47.439 C445.096,47.439 444.97,47.314 444.97,47.159 C444.97,47.004 445.096,46.879 445.25,46.879 C445.405,46.879 445.53,47.004 445.53,47.159" id="Fill-1387" class="icon-color"/>
      <path d="M445.957,39.645 C445.957,40.035 445.641,40.352 445.25,40.352 C444.86,40.352 444.543,40.035 444.543,39.645 C444.543,39.255 444.86,38.938 445.25,38.938 C445.641,38.938 445.957,39.255 445.957,39.645" id="Fill-1388" class="icon-color"/>
      <path d="M446.384,32.131 C446.384,32.757 445.877,33.265 445.25,33.265 C444.624,33.265 444.117,32.757 444.117,32.131 C444.117,31.505 444.624,30.997 445.25,30.997 C445.877,30.997 446.384,31.505 446.384,32.131" id="Fill-1389" class="icon-color"/>
      <path d="M446.811,24.617 C446.811,25.479 446.112,26.177 445.25,26.177 C444.389,26.177 443.69,25.479 443.69,24.617 C443.69,23.755 444.389,23.056 445.25,23.056 C446.112,23.056 446.811,23.755 446.811,24.617" id="Fill-1390" class="icon-color"/>
      <path d="M443.626,126.245 C443.281,125.348 443.729,124.341 444.626,123.996 C445.523,123.652 446.53,124.1 446.875,124.997 C447.219,125.894 446.772,126.9 445.875,127.245 C444.977,127.59 443.971,127.142 443.626,126.245" id="Fill-1391" class="icon-color"/>
      <path d="M436.425,108.761 C436.425,107.437 437.498,106.364 438.822,106.364 C440.145,106.364 441.218,107.437 441.218,108.761 C441.218,110.084 440.145,111.157 438.822,111.157 C437.498,111.157 436.425,110.084 436.425,108.761" id="Fill-1392" class="icon-color"/>
      <path d="M438.756,112.673 C439.969,112.637 440.982,113.591 441.018,114.804 C441.055,116.017 440.101,117.03 438.887,117.067 C437.674,117.103 436.661,116.149 436.625,114.936 C436.589,113.722 437.542,112.709 438.756,112.673" id="Fill-1393" class="icon-color"/>
      <path d="M438.744,118.982 C439.847,118.909 440.8,119.744 440.873,120.847 C440.946,121.95 440.111,122.903 439.008,122.976 C437.904,123.049 436.952,122.214 436.879,121.11 C436.806,120.008 437.641,119.054 438.744,118.982" id="Fill-1394" class="icon-color"/>
      <path d="M438.703,125.29 C439.696,125.181 440.589,125.898 440.698,126.89 C440.807,127.883 440.091,128.776 439.098,128.885 C438.106,128.994 437.212,128.278 437.103,127.286 C436.994,126.293 437.71,125.4 438.703,125.29" id="Fill-1395" class="icon-color"/>
      <path d="M438.662,131.599 C439.545,131.454 440.378,132.051 440.523,132.933 C440.669,133.816 440.072,134.649 439.189,134.794 C438.307,134.94 437.473,134.343 437.328,133.46 C437.183,132.578 437.78,131.745 438.662,131.599" id="Fill-1396" class="icon-color"/>
      <path d="M438.621,137.908 C439.393,137.726 440.167,138.204 440.349,138.976 C440.53,139.748 440.052,140.522 439.28,140.704 C438.508,140.885 437.735,140.407 437.553,139.635 C437.371,138.863 437.849,138.09 438.621,137.908" id="Fill-1397" class="icon-color"/>
      <path d="M438.476,150.525 C439.027,150.27 439.681,150.511 439.936,151.063 C440.19,151.614 439.95,152.267 439.398,152.522 C438.847,152.777 438.193,152.536 437.938,151.985 C437.684,151.433 437.924,150.78 438.476,150.525" id="Fill-1398" class="icon-color"/>
      <path d="M438.387,156.834 C438.828,156.543 439.422,156.665 439.713,157.106 C440.004,157.547 439.882,158.14 439.441,158.431 C439,158.723 438.406,158.601 438.115,158.159 C437.824,157.718 437.946,157.125 438.387,156.834" id="Fill-1399" class="icon-color"/>
      <path d="M438.298,163.143 C438.629,162.815 439.163,162.818 439.49,163.149 C439.817,163.48 439.815,164.013 439.484,164.341 C439.153,164.668 438.619,164.666 438.292,164.334 C437.964,164.004 437.967,163.47 438.298,163.143" id="Fill-1400" class="icon-color"/>
      <path d="M438.163,169.451 C438.383,169.087 438.857,168.971 439.221,169.192 C439.585,169.413 439.701,169.886 439.48,170.25 C439.26,170.614 438.786,170.73 438.422,170.509 C438.058,170.289 437.942,169.815 438.163,169.451" id="Fill-1401" class="icon-color"/>
      <path d="M438.097,175.76 C438.207,175.36 438.621,175.125 439.021,175.235 C439.421,175.345 439.656,175.759 439.546,176.159 C439.436,176.559 439.022,176.795 438.622,176.684 C438.222,176.574 437.987,176.16 438.097,175.76" id="Fill-1402" class="icon-color"/>
      <path d="M438.031,182.069 C438.031,181.632 438.385,181.278 438.822,181.278 C439.258,181.278 439.612,181.632 439.612,182.069 C439.612,182.505 439.258,182.859 438.822,182.859 C438.385,182.859 438.031,182.505 438.031,182.069" id="Fill-1403" class="icon-color"/>
      <path d="M438.565,144.216 C439.227,143.998 439.94,144.358 440.159,145.019 C440.377,145.681 440.017,146.395 439.355,146.613 C438.694,146.831 437.98,146.472 437.762,145.81 C437.543,145.148 437.903,144.435 438.565,144.216" id="Fill-1404" class="icon-color"/>
      <path d="M549.596,68.225 C549.596,67.363 550.295,66.665 551.157,66.665 C552.019,66.665 552.717,67.363 552.717,68.225 C552.717,69.087 552.019,69.786 551.157,69.786 C550.295,69.786 549.596,69.087 549.596,68.225" id="Fill-1405" class="icon-color"/>
      <path d="M551.427,73.287 C552.203,73.436 552.711,74.186 552.561,74.962 C552.412,75.737 551.662,76.245 550.886,76.095 C550.11,75.946 549.603,75.196 549.753,74.42 C549.902,73.645 550.652,73.137 551.427,73.287" id="Fill-1406" class="icon-color"/>
      <path d="M551.698,79.909 C552.388,80.208 552.704,81.009 552.405,81.698 C552.106,82.388 551.305,82.704 550.615,82.405 C549.926,82.106 549.609,81.305 549.908,80.615 C550.208,79.926 551.009,79.61 551.698,79.909" id="Fill-1407" class="icon-color"/>
      <path d="M551.969,86.53 C552.572,86.979 552.698,87.831 552.249,88.435 C551.801,89.038 550.948,89.164 550.345,88.715 C549.742,88.266 549.616,87.414 550.065,86.811 C550.513,86.207 551.366,86.082 551.969,86.53" id="Fill-1408" class="icon-color"/>
      <path d="M552.24,93.152 C552.757,93.75 552.691,94.654 552.093,95.171 C551.495,95.688 550.591,95.623 550.074,95.025 C549.557,94.427 549.622,93.523 550.221,93.006 C550.819,92.489 551.723,92.554 552.24,93.152" id="Fill-1409" class="icon-color"/>
      <path d="M552.51,99.774 C552.941,100.522 552.685,101.477 551.937,101.908 C551.19,102.339 550.234,102.082 549.803,101.335 C549.372,100.587 549.629,99.632 550.377,99.201 C551.124,98.77 552.079,99.027 552.51,99.774" id="Fill-1410" class="icon-color"/>
      <path d="M552.781,106.396 C553.126,107.293 552.678,108.3 551.781,108.645 C550.884,108.989 549.877,108.541 549.533,107.644 C549.188,106.747 549.636,105.741 550.533,105.396 C551.43,105.051 552.436,105.499 552.781,106.396" id="Fill-1411" class="icon-color"/>
      <path d="M553.323,119.64 C553.495,120.836 552.665,121.945 551.469,122.118 C550.273,122.29 549.164,121.46 548.991,120.264 C548.819,119.068 549.649,117.958 550.845,117.786 C552.041,117.614 553.15,118.444 553.323,119.64" id="Fill-1412" class="icon-color"/>
      <path d="M553.593,126.262 C553.68,127.607 552.658,128.768 551.313,128.854 C549.967,128.94 548.807,127.919 548.72,126.574 C548.634,125.228 549.655,124.067 551.001,123.981 C552.346,123.895 553.507,124.916 553.593,126.262" id="Fill-1413" class="icon-color"/>
      <path d="M548.45,132.883 C548.45,131.388 549.662,130.176 551.157,130.176 C552.652,130.176 553.864,131.388 553.864,132.883 C553.864,134.379 552.652,135.591 551.157,135.591 C549.662,135.591 548.45,134.379 548.45,132.883" id="Fill-1414" class="icon-color"/>
      <path d="M553.437,140.398 C553.437,141.657 552.416,142.678 551.157,142.678 C549.897,142.678 548.876,141.657 548.876,140.398 C548.876,139.138 549.897,138.117 551.157,138.117 C552.416,138.117 553.437,139.138 553.437,140.398" id="Fill-1415" class="icon-color"/>
      <path d="M553.01,147.912 C553.01,148.935 552.181,149.765 551.157,149.765 C550.133,149.765 549.303,148.935 549.303,147.912 C549.303,146.888 550.133,146.058 551.157,146.058 C552.181,146.058 553.01,146.888 553.01,147.912" id="Fill-1416" class="icon-color"/>
      <path d="M552.584,155.426 C552.584,156.214 551.945,156.853 551.157,156.853 C550.369,156.853 549.73,156.214 549.73,155.426 C549.73,154.638 550.369,153.999 551.157,153.999 C551.945,153.999 552.584,154.638 552.584,155.426" id="Fill-1417" class="icon-color"/>
      <path d="M552.157,162.94 C552.157,163.492 551.709,163.94 551.157,163.94 C550.604,163.94 550.157,163.492 550.157,162.94 C550.157,162.387 550.604,161.94 551.157,161.94 C551.709,161.94 552.157,162.387 552.157,162.94" id="Fill-1418" class="icon-color"/>
      <path d="M551.73,170.454 C551.73,170.771 551.474,171.027 551.157,171.027 C550.84,171.027 550.583,170.771 550.583,170.454 C550.583,170.137 550.84,169.88 551.157,169.88 C551.474,169.88 551.73,170.137 551.73,170.454" id="Fill-1419" class="icon-color"/>
      <path d="M551.303,177.968 C551.303,178.049 551.238,178.114 551.157,178.114 C551.076,178.114 551.01,178.049 551.01,177.968 C551.01,177.887 551.076,177.821 551.157,177.821 C551.238,177.821 551.303,177.887 551.303,177.968" id="Fill-1420" class="icon-color"/>
      <path d="M550.877,185.482 C550.877,185.327 551.002,185.202 551.157,185.202 C551.312,185.202 551.437,185.327 551.437,185.482 C551.437,185.637 551.312,185.762 551.157,185.762 C551.002,185.762 550.877,185.637 550.877,185.482" id="Fill-1421" class="icon-color"/>
      <path d="M550.45,192.996 C550.45,192.606 550.766,192.289 551.157,192.289 C551.547,192.289 551.864,192.606 551.864,192.996 C551.864,193.387 551.547,193.703 551.157,193.703 C550.766,193.703 550.45,193.387 550.45,192.996" id="Fill-1422" class="icon-color"/>
      <path d="M550.023,200.51 C550.023,199.884 550.531,199.376 551.157,199.376 C551.783,199.376 552.29,199.884 552.29,200.51 C552.29,201.136 551.783,201.644 551.157,201.644 C550.531,201.644 550.023,201.136 550.023,200.51" id="Fill-1423" class="icon-color"/>
      <path d="M549.596,208.024 C549.596,207.162 550.295,206.464 551.157,206.464 C552.019,206.464 552.717,207.162 552.717,208.024 C552.717,208.886 552.019,209.584 551.157,209.584 C550.295,209.584 549.596,208.886 549.596,208.024" id="Fill-1424" class="icon-color"/>
      <path d="M553.052,113.018 C553.31,114.064 552.672,115.122 551.625,115.381 C550.578,115.64 549.52,115.001 549.262,113.954 C549.003,112.908 549.642,111.85 550.689,111.591 C551.735,111.332 552.793,111.971 553.052,113.018" id="Fill-1425" class="icon-color"/>
      <path d="M478.943,52.272 C478.943,51.474 479.589,50.827 480.387,50.827 C481.185,50.827 481.831,51.474 481.831,52.272 C481.831,53.069 481.185,53.716 480.387,53.716 C479.589,53.716 478.943,53.069 478.943,52.272" id="Fill-1426" class="icon-color"/>
      <path d="M480.638,56.626 C481.355,56.764 481.825,57.458 481.687,58.176 C481.548,58.894 480.854,59.364 480.137,59.225 C479.419,59.087 478.949,58.393 479.087,57.675 C479.226,56.957 479.92,56.488 480.638,56.626" id="Fill-1427" class="icon-color"/>
      <path d="M480.888,62.424 C481.526,62.701 481.819,63.443 481.542,64.081 C481.266,64.719 480.524,65.012 479.886,64.735 C479.248,64.458 478.955,63.717 479.232,63.079 C479.509,62.441 480.25,62.148 480.888,62.424" id="Fill-1428" class="icon-color"/>
      <path d="M481.139,68.223 C481.697,68.638 481.813,69.427 481.398,69.985 C480.983,70.544 480.194,70.66 479.635,70.245 C479.077,69.83 478.961,69.04 479.376,68.482 C479.791,67.924 480.58,67.808 481.139,68.223" id="Fill-1429" class="icon-color"/>
      <path d="M481.389,74.021 C481.868,74.575 481.807,75.411 481.253,75.89 C480.7,76.369 479.863,76.308 479.385,75.754 C478.906,75.201 478.967,74.364 479.521,73.886 C480.074,73.407 480.911,73.468 481.389,74.021" id="Fill-1430" class="icon-color"/>
      <path d="M481.64,79.82 C482.038,80.512 481.801,81.396 481.109,81.795 C480.417,82.193 479.533,81.956 479.134,81.264 C478.736,80.572 478.973,79.688 479.665,79.289 C480.357,78.891 481.241,79.128 481.64,79.82" id="Fill-1431" class="icon-color"/>
      <path d="M481.89,85.618 C482.209,86.449 481.795,87.38 480.965,87.699 C480.134,88.018 479.203,87.604 478.884,86.774 C478.565,85.943 478.979,85.012 479.809,84.693 C480.64,84.374 481.571,84.788 481.89,85.618" id="Fill-1432" class="icon-color"/>
      <path d="M482.141,91.417 C482.38,92.385 481.789,93.365 480.82,93.604 C479.852,93.843 478.873,93.252 478.633,92.283 C478.394,91.315 478.985,90.335 479.954,90.096 C480.922,89.857 481.901,90.448 482.141,91.417" id="Fill-1433" class="icon-color"/>
      <path d="M482.391,97.215 C482.551,98.322 481.783,99.349 480.676,99.508 C479.569,99.668 478.542,98.9 478.383,97.793 C478.223,96.686 478.991,95.659 480.098,95.5 C481.205,95.34 482.232,96.108 482.391,97.215" id="Fill-1434" class="icon-color"/>
      <path d="M482.642,103.014 C482.722,104.259 481.777,105.333 480.531,105.413 C479.286,105.493 478.212,104.548 478.132,103.303 C478.052,102.057 478.997,100.983 480.243,100.903 C481.488,100.823 482.562,101.768 482.642,103.014" id="Fill-1435" class="icon-color"/>
      <path d="M477.882,108.812 C477.882,107.429 479.003,106.307 480.387,106.307 C481.771,106.307 482.892,107.429 482.892,108.812 C482.892,110.196 481.771,111.318 480.387,111.318 C479.003,111.318 477.882,110.196 477.882,108.812" id="Fill-1436" class="icon-color"/>
      <path d="M482.102,120.78 C482.102,121.728 481.335,122.496 480.387,122.496 C479.44,122.496 478.671,121.728 478.671,120.78 C478.671,119.833 479.44,119.065 480.387,119.065 C481.335,119.065 482.102,119.833 482.102,120.78" id="Fill-1437" class="icon-color"/>
      <path d="M481.708,126.764 C481.708,127.494 481.116,128.085 480.387,128.085 C479.658,128.085 479.067,127.494 479.067,126.764 C479.067,126.035 479.658,125.444 480.387,125.444 C481.116,125.444 481.708,126.035 481.708,126.764" id="Fill-1438" class="icon-color"/>
      <path d="M481.313,132.748 C481.313,133.26 480.898,133.674 480.387,133.674 C479.876,133.674 479.461,133.26 479.461,132.748 C479.461,132.237 479.876,131.823 480.387,131.823 C480.898,131.823 481.313,132.237 481.313,132.748" id="Fill-1439" class="icon-color"/>
      <path d="M480.918,138.732 C480.918,139.025 480.68,139.263 480.387,139.263 C480.094,139.263 479.856,139.025 479.856,138.732 C479.856,138.439 480.094,138.202 480.387,138.202 C480.68,138.202 480.918,138.439 480.918,138.732" id="Fill-1440" class="icon-color"/>
      <path d="M480.523,144.716 C480.523,144.791 480.462,144.852 480.387,144.852 C480.312,144.852 480.251,144.791 480.251,144.716 C480.251,144.641 480.312,144.581 480.387,144.581 C480.462,144.581 480.523,144.641 480.523,144.716" id="Fill-1441" class="icon-color"/>
      <path d="M480.128,150.7 C480.128,150.557 480.244,150.441 480.387,150.441 C480.53,150.441 480.646,150.557 480.646,150.7 C480.646,150.844 480.53,150.96 480.387,150.96 C480.244,150.96 480.128,150.844 480.128,150.7" id="Fill-1442" class="icon-color"/>
      <path d="M479.733,156.684 C479.733,156.323 480.026,156.03 480.387,156.03 C480.749,156.03 481.041,156.323 481.041,156.684 C481.041,157.046 480.749,157.339 480.387,157.339 C480.026,157.339 479.733,157.046 479.733,156.684" id="Fill-1443" class="icon-color"/>
      <path d="M479.338,162.668 C479.338,162.089 479.807,161.619 480.387,161.619 C480.966,161.619 481.436,162.089 481.436,162.668 C481.436,163.248 480.966,163.718 480.387,163.718 C479.807,163.718 479.338,163.248 479.338,162.668" id="Fill-1444" class="icon-color"/>
      <path d="M478.943,168.652 C478.943,167.855 479.589,167.208 480.387,167.208 C481.185,167.208 481.831,167.855 481.831,168.652 C481.831,169.45 481.185,170.097 480.387,170.097 C479.589,170.097 478.943,169.45 478.943,168.652" id="Fill-1445" class="icon-color"/>
      <path d="M482.497,114.796 C482.497,115.962 481.553,116.907 480.387,116.907 C479.221,116.907 478.277,115.962 478.277,114.796 C478.277,113.631 479.221,112.686 480.387,112.686 C481.553,112.686 482.497,113.631 482.497,114.796" id="Fill-1446" class="icon-color"/>
      <path d="M484.864,56.36 C484.864,55.52 485.545,54.839 486.385,54.839 C487.225,54.839 487.907,55.52 487.907,56.36 C487.907,57.2 487.225,57.881 486.385,57.881 C485.545,57.881 484.864,57.2 484.864,56.36" id="Fill-1447" class="icon-color"/>
      <path d="M486.679,61.232 C487.426,61.394 487.9,62.131 487.737,62.878 C487.576,63.624 486.839,64.098 486.092,63.936 C485.345,63.774 484.871,63.038 485.033,62.291 C485.195,61.544 485.932,61.07 486.679,61.232" id="Fill-1448" class="icon-color"/>
      <path d="M486.972,67.626 C487.625,67.949 487.892,68.742 487.569,69.395 C487.245,70.049 486.452,70.316 485.799,69.992 C485.145,69.668 484.878,68.876 485.202,68.222 C485.526,67.569 486.318,67.302 486.972,67.626" id="Fill-1449" class="icon-color"/>
      <path d="M487.265,74.019 C487.825,74.505 487.885,75.353 487.4,75.913 C486.914,76.473 486.066,76.533 485.506,76.047 C484.946,75.561 484.885,74.714 485.371,74.153 C485.857,73.593 486.705,73.533 487.265,74.019" id="Fill-1450" class="icon-color"/>
      <path d="M487.558,80.412 C488.025,81.06 487.878,81.964 487.23,82.431 C486.583,82.897 485.679,82.75 485.212,82.103 C484.746,81.455 484.892,80.551 485.54,80.085 C486.188,79.618 487.091,79.765 487.558,80.412" id="Fill-1451" class="icon-color"/>
      <path d="M487.852,86.806 C488.225,87.616 487.871,88.575 487.061,88.948 C486.252,89.322 485.293,88.968 484.919,88.158 C484.546,87.348 484.9,86.389 485.709,86.016 C486.519,85.643 487.478,85.996 487.852,86.806" id="Fill-1452" class="icon-color"/>
      <path d="M488.145,93.199 C488.425,94.171 487.864,95.186 486.892,95.466 C485.921,95.746 484.906,95.185 484.626,94.214 C484.346,93.242 484.907,92.227 485.878,91.947 C486.85,91.667 487.865,92.228 488.145,93.199" id="Fill-1453" class="icon-color"/>
      <path d="M488.731,105.986 C488.824,107.282 487.85,108.408 486.554,108.501 C485.259,108.595 484.133,107.62 484.04,106.324 C483.946,105.029 484.921,103.903 486.216,103.809 C487.512,103.716 488.638,104.691 488.731,105.986" id="Fill-1454" class="icon-color"/>
      <path d="M483.746,112.38 C483.746,110.922 484.928,109.741 486.385,109.741 C487.843,109.741 489.024,110.922 489.024,112.38 C489.024,113.837 487.843,115.019 486.385,115.019 C484.928,115.019 483.746,113.837 483.746,112.38" id="Fill-1455" class="icon-color"/>
      <path d="M488.562,119.383 C488.562,120.585 487.588,121.56 486.385,121.56 C485.183,121.56 484.208,120.585 484.208,119.383 C484.208,118.181 485.183,117.206 486.385,117.206 C487.588,117.206 488.562,118.181 488.562,119.383" id="Fill-1456" class="icon-color"/>
      <path d="M488.1,126.387 C488.1,127.334 487.332,128.101 486.385,128.101 C485.439,128.101 484.671,127.334 484.671,126.387 C484.671,125.44 485.439,124.672 486.385,124.672 C487.332,124.672 488.1,125.44 488.1,126.387" id="Fill-1457" class="icon-color"/>
      <path d="M487.638,133.39 C487.638,134.082 487.077,134.642 486.385,134.642 C485.694,134.642 485.133,134.082 485.133,133.39 C485.133,132.699 485.694,132.138 486.385,132.138 C487.077,132.138 487.638,132.699 487.638,133.39" id="Fill-1458" class="icon-color"/>
      <path d="M487.176,140.394 C487.176,140.83 486.822,141.184 486.385,141.184 C485.949,141.184 485.595,140.83 485.595,140.394 C485.595,139.957 485.949,139.604 486.385,139.604 C486.822,139.604 487.176,139.957 487.176,140.394" id="Fill-1459" class="icon-color"/>
      <path d="M486.713,147.397 C486.713,147.578 486.566,147.725 486.385,147.725 C486.204,147.725 486.058,147.578 486.058,147.397 C486.058,147.216 486.204,147.069 486.385,147.069 C486.566,147.069 486.713,147.216 486.713,147.397" id="Fill-1460" class="icon-color"/>
      <path d="M486.251,154.401 C486.251,154.326 486.311,154.266 486.385,154.266 C486.46,154.266 486.52,154.326 486.52,154.401 C486.52,154.475 486.46,154.535 486.385,154.535 C486.311,154.535 486.251,154.475 486.251,154.401" id="Fill-1461" class="icon-color"/>
      <path d="M485.789,161.404 C485.789,161.075 486.056,160.807 486.385,160.807 C486.715,160.807 486.982,161.075 486.982,161.404 C486.982,161.734 486.715,162.001 486.385,162.001 C486.056,162.001 485.789,161.734 485.789,161.404" id="Fill-1462" class="icon-color"/>
      <path d="M485.326,168.407 C485.326,167.823 485.801,167.349 486.385,167.349 C486.97,167.349 487.444,167.823 487.444,168.407 C487.444,168.992 486.97,169.467 486.385,169.467 C485.801,169.467 485.326,168.992 485.326,168.407" id="Fill-1463" class="icon-color"/>
      <path d="M484.864,175.411 C484.864,174.571 485.545,173.89 486.385,173.89 C487.225,173.89 487.907,174.571 487.907,175.411 C487.907,176.251 487.225,176.932 486.385,176.932 C485.545,176.932 484.864,176.251 484.864,175.411" id="Fill-1464" class="icon-color"/>
      <path d="M488.438,99.593 C488.625,100.726 487.857,101.797 486.723,101.983 C485.59,102.17 484.52,101.403 484.333,100.269 C484.146,99.135 484.914,98.065 486.047,97.878 C487.181,97.692 488.251,98.459 488.438,99.593" id="Fill-1465" class="icon-color"/>
      <path d="M476.995,87.298 C476.995,88.441 476.069,89.368 474.926,89.368 C473.783,89.368 472.856,88.441 472.856,87.298 C472.856,86.155 473.783,85.229 474.926,85.229 C476.069,85.229 476.995,86.155 476.995,87.298" id="Fill-1466" class="icon-color"/>
      <path d="M474.994,84.472 C473.965,84.51 473.101,83.707 473.063,82.678 C473.025,81.649 473.829,80.785 474.858,80.747 C475.886,80.71 476.751,81.513 476.788,82.541 C476.826,83.57 476.023,84.435 474.994,84.472" id="Fill-1467" class="icon-color"/>
      <path d="M475.062,79.577 C474.148,79.652 473.345,78.972 473.27,78.058 C473.195,77.143 473.875,76.341 474.789,76.266 C475.703,76.19 476.506,76.87 476.581,77.785 C476.657,78.699 475.977,79.501 475.062,79.577" id="Fill-1468" class="icon-color"/>
      <path d="M475.069,74.681 C474.269,74.794 473.529,74.237 473.416,73.437 C473.303,72.637 473.86,71.897 474.66,71.784 C475.46,71.671 476.2,72.228 476.313,73.028 C476.426,73.828 475.87,74.568 475.069,74.681" id="Fill-1469" class="icon-color"/>
      <path d="M475.118,69.786 C474.433,69.936 473.754,69.503 473.604,68.817 C473.453,68.131 473.887,67.453 474.572,67.302 C475.258,67.151 475.936,67.585 476.087,68.271 C476.238,68.957 475.804,69.635 475.118,69.786" id="Fill-1470" class="icon-color"/>
      <path d="M475.168,64.89 C474.596,65.079 473.98,64.768 473.792,64.197 C473.603,63.625 473.913,63.009 474.485,62.821 C475.056,62.632 475.673,62.943 475.861,63.514 C476.05,64.085 475.739,64.702 475.168,64.89" id="Fill-1471" class="icon-color"/>
      <path d="M475.217,59.994 C474.759,60.221 474.206,60.033 473.979,59.576 C473.753,59.119 473.94,58.565 474.398,58.339 C474.855,58.113 475.409,58.3 475.635,58.757 C475.861,59.214 475.674,59.768 475.217,59.994" id="Fill-1472" class="icon-color"/>
      <path d="M475.299,55.099 C474.956,55.363 474.465,55.299 474.201,54.956 C473.937,54.613 474.001,54.121 474.344,53.857 C474.687,53.593 475.179,53.657 475.443,54 C475.706,54.343 475.642,54.835 475.299,55.099" id="Fill-1473" class="icon-color"/>
      <path d="M475.404,50.203 C475.175,50.505 474.745,50.564 474.444,50.336 C474.142,50.107 474.083,49.677 474.311,49.376 C474.54,49.074 474.97,49.015 475.271,49.244 C475.573,49.472 475.632,49.902 475.404,50.203" id="Fill-1474" class="icon-color"/>
      <path d="M475.54,45.308 C475.426,45.647 475.058,45.83 474.719,45.716 C474.379,45.601 474.197,45.233 474.311,44.894 C474.426,44.555 474.793,44.372 475.133,44.487 C475.472,44.601 475.654,44.969 475.54,45.308" id="Fill-1475" class="icon-color"/>
      <path d="M475.608,40.413 C475.608,40.79 475.303,41.095 474.926,41.095 C474.549,41.095 474.243,40.79 474.243,40.413 C474.243,40.036 474.549,39.73 474.926,39.73 C475.303,39.73 475.608,40.036 475.608,40.413" id="Fill-1476" class="icon-color"/>
      <path d="M472.856,92.722 C472.856,91.579 473.783,90.652 474.926,90.652 C476.069,90.652 476.995,91.579 476.995,92.722 C476.995,93.865 476.069,94.791 474.926,94.791 C473.783,94.791 472.856,93.865 472.856,92.722" id="Fill-1477" class="icon-color"/>
      <path d="M474.858,95.548 C475.886,95.51 476.751,96.313 476.788,97.342 C476.826,98.371 476.023,99.235 474.994,99.273 C473.965,99.311 473.101,98.507 473.063,97.479 C473.025,96.45 473.829,95.586 474.858,95.548" id="Fill-1478" class="icon-color"/>
      <path d="M474.789,100.443 C475.703,100.368 476.506,101.048 476.581,101.962 C476.657,102.877 475.977,103.679 475.062,103.755 C474.148,103.83 473.345,103.15 473.27,102.236 C473.195,101.321 473.875,100.519 474.789,100.443" id="Fill-1479" class="icon-color"/>
      <path d="M474.733,110.235 C475.419,110.084 476.097,110.517 476.248,111.203 C476.398,111.889 475.965,112.567 475.279,112.718 C474.593,112.869 473.915,112.435 473.764,111.749 C473.614,111.064 474.047,110.386 474.733,110.235" id="Fill-1480" class="icon-color"/>
      <path d="M474.684,115.13 C475.255,114.942 475.871,115.252 476.06,115.824 C476.248,116.395 475.938,117.011 475.366,117.2 C474.795,117.388 474.179,117.078 473.99,116.506 C473.802,115.935 474.112,115.319 474.684,115.13" id="Fill-1481" class="icon-color"/>
      <path d="M474.635,120.026 C475.092,119.8 475.646,119.987 475.872,120.444 C476.098,120.901 475.911,121.455 475.454,121.681 C474.997,121.908 474.443,121.72 474.216,121.263 C473.99,120.806 474.178,120.252 474.635,120.026" id="Fill-1482" class="icon-color"/>
      <path d="M474.552,124.921 C474.895,124.657 475.387,124.721 475.651,125.064 C475.915,125.407 475.851,125.899 475.508,126.163 C475.165,126.427 474.673,126.363 474.409,126.02 C474.145,125.677 474.209,125.185 474.552,124.921" id="Fill-1483" class="icon-color"/>
      <path d="M474.448,129.817 C474.676,129.515 475.106,129.456 475.408,129.685 C475.71,129.913 475.769,130.343 475.54,130.645 C475.311,130.946 474.882,131.006 474.58,130.777 C474.279,130.548 474.219,130.118 474.448,129.817" id="Fill-1484" class="icon-color"/>
      <path d="M474.311,134.712 C474.426,134.373 474.793,134.191 475.133,134.305 C475.472,134.419 475.654,134.787 475.54,135.126 C475.426,135.465 475.058,135.648 474.719,135.534 C474.379,135.419 474.197,135.052 474.311,134.712" id="Fill-1485" class="icon-color"/>
      <path d="M474.243,139.608 C474.243,139.231 474.549,138.925 474.926,138.925 C475.303,138.925 475.608,139.231 475.608,139.608 C475.608,139.985 475.303,140.29 474.926,140.29 C474.549,140.29 474.243,139.985 474.243,139.608" id="Fill-1486" class="icon-color"/>
      <path d="M474.782,105.339 C475.582,105.226 476.322,105.783 476.436,106.583 C476.548,107.383 475.992,108.123 475.192,108.236 C474.392,108.349 473.651,107.793 473.538,106.993 C473.425,106.192 473.982,105.452 474.782,105.339" id="Fill-1487" class="icon-color"/>
      <path d="M541.249,121.172 C541.249,122.622 540.073,123.797 538.623,123.797 C537.174,123.797 535.998,122.622 535.998,121.172 C535.998,119.722 537.174,118.547 538.623,118.547 C540.073,118.547 541.249,119.722 541.249,121.172" id="Fill-1488" class="icon-color"/>
      <path d="M538.71,117.587 C537.405,117.635 536.309,116.616 536.261,115.311 C536.213,114.006 537.232,112.91 538.537,112.862 C539.842,112.814 540.938,113.833 540.986,115.138 C541.034,116.443 540.015,117.539 538.71,117.587" id="Fill-1489" class="icon-color"/>
      <path d="M538.743,111.377 C537.583,111.473 536.566,110.61 536.47,109.45 C536.374,108.29 537.237,107.273 538.397,107.177 C539.557,107.081 540.575,107.944 540.67,109.104 C540.766,110.264 539.903,111.282 538.743,111.377" id="Fill-1490" class="icon-color"/>
      <path d="M538.806,105.167 C537.791,105.311 536.852,104.604 536.708,103.589 C536.565,102.575 537.271,101.635 538.286,101.492 C539.301,101.349 540.24,102.055 540.383,103.07 C540.527,104.085 539.821,105.024 538.806,105.167" id="Fill-1491" class="icon-color"/>
      <path d="M538.93,92.747 C538.205,92.986 537.424,92.592 537.185,91.867 C536.946,91.143 537.339,90.361 538.064,90.122 C538.789,89.883 539.571,90.277 539.81,91.002 C540.049,91.727 539.655,92.508 538.93,92.747" id="Fill-1492" class="icon-color"/>
      <path d="M538.993,86.537 C538.413,86.824 537.71,86.587 537.423,86.007 C537.136,85.427 537.373,84.724 537.953,84.437 C538.533,84.15 539.236,84.388 539.523,84.968 C539.81,85.548 539.573,86.25 538.993,86.537" id="Fill-1493" class="icon-color"/>
      <path d="M539.097,80.327 C538.663,80.662 538.039,80.581 537.704,80.146 C537.369,79.711 537.45,79.087 537.885,78.752 C538.32,78.417 538.944,78.499 539.279,78.933 C539.614,79.368 539.532,79.993 539.097,80.327" id="Fill-1494" class="icon-color"/>
      <path d="M539.229,74.117 C538.94,74.5 538.394,74.575 538.012,74.285 C537.629,73.995 537.554,73.45 537.844,73.067 C538.134,72.685 538.679,72.61 539.062,72.9 C539.445,73.19 539.52,73.735 539.229,74.117" id="Fill-1495" class="icon-color"/>
      <path d="M539.403,67.907 C539.258,68.338 538.791,68.569 538.361,68.424 C537.93,68.279 537.699,67.813 537.844,67.383 C537.989,66.952 538.456,66.721 538.886,66.866 C539.316,67.011 539.548,67.477 539.403,67.907" id="Fill-1496" class="icon-color"/>
      <path d="M539.489,61.698 C539.489,62.176 539.102,62.563 538.623,62.563 C538.145,62.563 537.758,62.176 537.758,61.698 C537.758,61.219 538.145,60.832 538.623,60.832 C539.102,60.832 539.489,61.219 539.489,61.698" id="Fill-1497" class="icon-color"/>
      <path d="M538.868,98.957 C537.998,99.148 537.138,98.598 536.946,97.729 C536.755,96.858 537.305,95.998 538.175,95.807 C539.045,95.616 539.906,96.166 540.097,97.036 C540.288,97.906 539.738,98.766 538.868,98.957" id="Fill-1498" class="icon-color"/>
      <path d="M542.831,131.939 C542.831,130.616 543.904,129.543 545.228,129.543 C546.552,129.543 547.624,130.616 547.624,131.939 C547.624,133.263 546.552,134.336 545.228,134.336 C543.904,134.336 542.831,133.263 542.831,131.939" id="Fill-1499" class="icon-color"/>
      <path d="M545.096,142.16 C546.199,142.087 547.152,142.922 547.225,144.025 C547.298,145.128 546.463,146.081 545.36,146.154 C544.257,146.227 543.303,145.392 543.231,144.289 C543.158,143.186 543.993,142.233 545.096,142.16" id="Fill-1500" class="icon-color"/>
      <path d="M545.03,148.469 C546.023,148.36 546.916,149.076 547.025,150.069 C547.134,151.061 546.418,151.954 545.426,152.064 C544.433,152.173 543.54,151.456 543.43,150.464 C543.321,149.471 544.038,148.578 545.03,148.469" id="Fill-1501" class="icon-color"/>
      <path d="M544.964,154.777 C545.847,154.632 546.68,155.229 546.826,156.112 C546.971,156.994 546.374,157.827 545.491,157.973 C544.609,158.118 543.776,157.521 543.63,156.639 C543.485,155.756 544.082,154.923 544.964,154.777" id="Fill-1502" class="icon-color"/>
      <path d="M544.899,161.086 C545.671,160.904 546.444,161.383 546.626,162.155 C546.808,162.927 546.329,163.7 545.557,163.882 C544.785,164.064 544.012,163.585 543.83,162.813 C543.648,162.041 544.126,161.268 544.899,161.086" id="Fill-1503" class="icon-color"/>
      <path d="M544.833,167.395 C545.494,167.176 546.208,167.536 546.426,168.198 C546.644,168.86 546.285,169.573 545.623,169.791 C544.961,170.01 544.248,169.65 544.03,168.988 C543.811,168.327 544.171,167.613 544.833,167.395" id="Fill-1504" class="icon-color"/>
      <path d="M544.767,173.703 C545.318,173.449 545.972,173.689 546.227,174.241 C546.481,174.793 546.24,175.446 545.689,175.701 C545.138,175.955 544.484,175.715 544.229,175.163 C543.975,174.612 544.215,173.958 544.767,173.703" id="Fill-1505" class="icon-color"/>
      <path d="M544.701,180.012 C545.142,179.721 545.736,179.843 546.027,180.284 C546.318,180.725 546.196,181.319 545.755,181.61 C545.314,181.901 544.72,181.779 544.429,181.338 C544.138,180.897 544.26,180.303 544.701,180.012" id="Fill-1506" class="icon-color"/>
      <path d="M544.635,186.321 C544.966,185.993 545.5,185.996 545.827,186.327 C546.154,186.658 546.152,187.192 545.821,187.519 C545.49,187.846 544.956,187.844 544.629,187.513 C544.301,187.182 544.304,186.648 544.635,186.321" id="Fill-1507" class="icon-color"/>
      <path d="M544.569,192.629 C544.79,192.266 545.264,192.15 545.627,192.37 C545.991,192.591 546.107,193.065 545.886,193.428 C545.666,193.792 545.192,193.908 544.829,193.688 C544.465,193.467 544.349,192.993 544.569,192.629" id="Fill-1508" class="icon-color"/>
      <path d="M544.503,198.938 C544.613,198.538 545.027,198.303 545.428,198.413 C545.828,198.524 546.063,198.937 545.953,199.338 C545.842,199.738 545.428,199.973 545.028,199.862 C544.628,199.752 544.393,199.338 544.503,198.938" id="Fill-1509" class="icon-color"/>
      <path d="M544.437,205.247 C544.437,204.81 544.791,204.457 545.228,204.457 C545.664,204.457 546.018,204.81 546.018,205.247 C546.018,205.683 545.664,206.037 545.228,206.037 C544.791,206.037 544.437,205.683 544.437,205.247" id="Fill-1510" class="icon-color"/>
      <path d="M545.792,76.012 C545.792,76.452 545.435,76.809 544.995,76.809 C544.555,76.809 544.198,76.452 544.198,76.012 C544.198,75.572 544.555,75.215 544.995,75.215 C545.435,75.215 545.792,75.572 545.792,76.012" id="Fill-1511" class="icon-color"/>
      <path d="M546.039,82.235 C546.039,82.812 545.572,83.279 544.995,83.279 C544.419,83.279 543.951,82.812 543.951,82.235 C543.951,81.659 544.419,81.191 544.995,81.191 C545.572,81.191 546.039,81.659 546.039,82.235" id="Fill-1512" class="icon-color"/>
      <path d="M546.286,88.458 C546.286,89.171 545.708,89.749 544.995,89.749 C544.282,89.749 543.704,89.171 543.704,88.458 C543.704,87.745 544.282,87.167 544.995,87.167 C545.708,87.167 546.286,87.745 546.286,88.458" id="Fill-1513" class="icon-color"/>
      <path d="M546.533,94.681 C546.533,95.531 545.845,96.219 544.995,96.219 C544.146,96.219 543.457,95.531 543.457,94.681 C543.457,93.832 544.146,93.143 544.995,93.143 C545.845,93.143 546.533,93.832 546.533,94.681" id="Fill-1514" class="icon-color"/>
      <path d="M546.78,100.904 C546.78,101.89 545.981,102.689 544.995,102.689 C544.01,102.689 543.211,101.89 543.211,100.904 C543.211,99.918 544.01,99.119 544.995,99.119 C545.981,99.119 546.78,99.918 546.78,100.904" id="Fill-1515" class="icon-color"/>
      <path d="M547.027,107.127 C547.027,108.25 546.118,109.159 544.995,109.159 C543.873,109.159 542.963,108.25 542.963,107.127 C542.963,106.005 543.873,105.095 544.995,105.095 C546.118,105.095 547.027,106.005 547.027,107.127" id="Fill-1516" class="icon-color"/>
      <path d="M547.274,113.35 C547.274,114.609 546.254,115.629 544.995,115.629 C543.737,115.629 542.716,114.609 542.716,113.35 C542.716,112.092 543.737,111.072 544.995,111.072 C546.254,111.072 547.274,112.092 547.274,113.35" id="Fill-1517" class="icon-color"/>
      <path d="M547.521,119.573 C547.521,120.968 546.39,122.099 544.995,122.099 C543.6,122.099 542.469,120.968 542.469,119.573 C542.469,118.179 543.6,117.048 544.995,117.048 C546.39,117.048 547.521,118.179 547.521,119.573" id="Fill-1518" class="icon-color"/>
      <path d="M547.768,125.797 C547.768,127.328 546.527,128.569 544.995,128.569 C543.464,128.569 542.222,127.328 542.222,125.797 C542.222,124.265 543.464,123.024 544.995,123.024 C546.527,123.024 547.768,124.265 547.768,125.797" id="Fill-1519" class="icon-color"/>
      <path d="M545.162,135.851 C546.375,135.815 547.388,136.769 547.425,137.982 C547.461,139.196 546.507,140.209 545.294,140.245 C544.081,140.281 543.068,139.327 543.031,138.114 C542.995,136.901 543.949,135.888 545.162,135.851" id="Fill-1520" class="icon-color"/>
      <path d="M433.823,169.155 C433.823,169.995 433.142,170.676 432.302,170.676 C431.462,170.676 430.781,169.995 430.781,169.155 C430.781,168.315 431.462,167.634 432.302,167.634 C433.142,167.634 433.823,168.315 433.823,169.155" id="Fill-1521" class="icon-color"/>
      <path d="M432.009,164.283 C431.262,164.121 430.788,163.384 430.95,162.638 C431.112,161.891 431.848,161.417 432.595,161.579 C433.342,161.741 433.816,162.477 433.654,163.224 C433.492,163.971 432.756,164.445 432.009,164.283" id="Fill-1522" class="icon-color"/>
      <path d="M431.716,157.89 C431.062,157.566 430.795,156.773 431.119,156.12 C431.443,155.467 432.235,155.199 432.888,155.523 C433.542,155.847 433.809,156.639 433.485,157.293 C433.161,157.946 432.369,158.213 431.716,157.89" id="Fill-1523" class="icon-color"/>
      <path d="M431.422,151.496 C430.862,151.01 430.802,150.162 431.288,149.602 C431.774,149.042 432.622,148.982 433.182,149.468 C433.742,149.954 433.802,150.801 433.316,151.362 C432.83,151.922 431.982,151.982 431.422,151.496" id="Fill-1524" class="icon-color"/>
      <path d="M431.129,145.103 C430.662,144.455 430.809,143.551 431.457,143.085 C432.105,142.618 433.008,142.765 433.475,143.412 C433.942,144.06 433.795,144.964 433.147,145.43 C432.499,145.897 431.596,145.75 431.129,145.103" id="Fill-1525" class="icon-color"/>
      <path d="M430.836,138.709 C430.462,137.9 430.816,136.94 431.626,136.567 C432.436,136.194 433.395,136.547 433.768,137.357 C434.142,138.167 433.788,139.126 432.978,139.499 C432.168,139.873 431.209,139.519 430.836,138.709" id="Fill-1526" class="icon-color"/>
      <path d="M430.543,132.316 C430.263,131.344 430.823,130.329 431.795,130.049 C432.767,129.769 433.781,130.33 434.061,131.301 C434.341,132.273 433.781,133.288 432.809,133.568 C431.837,133.848 430.823,133.287 430.543,132.316" id="Fill-1527" class="icon-color"/>
      <path d="M429.956,119.529 C429.863,118.233 430.837,117.107 432.133,117.014 C433.429,116.921 434.555,117.895 434.648,119.191 C434.741,120.486 433.767,121.612 432.471,121.706 C431.175,121.799 430.049,120.824 429.956,119.529" id="Fill-1528" class="icon-color"/>
      <path d="M434.941,113.135 C434.941,114.593 433.76,115.774 432.302,115.774 C430.845,115.774 429.663,114.593 429.663,113.135 C429.663,111.678 430.845,110.496 432.302,110.496 C433.76,110.496 434.941,111.678 434.941,113.135" id="Fill-1529" class="icon-color"/>
      <path d="M430.125,106.132 C430.125,104.93 431.1,103.955 432.302,103.955 C433.504,103.955 434.479,104.93 434.479,106.132 C434.479,107.334 433.504,108.309 432.302,108.309 C431.1,108.309 430.125,107.334 430.125,106.132" id="Fill-1530" class="icon-color"/>
      <path d="M430.588,99.128 C430.588,98.181 431.355,97.414 432.302,97.414 C433.249,97.414 434.017,98.181 434.017,99.128 C434.017,100.075 433.249,100.843 432.302,100.843 C431.355,100.843 430.588,100.075 430.588,99.128" id="Fill-1531" class="icon-color"/>
      <path d="M431.05,92.125 C431.05,91.433 431.61,90.873 432.302,90.873 C432.994,90.873 433.554,91.433 433.554,92.125 C433.554,92.816 432.994,93.377 432.302,93.377 C431.61,93.377 431.05,92.816 431.05,92.125" id="Fill-1532" class="icon-color"/>
      <path d="M431.512,85.121 C431.512,84.685 431.866,84.331 432.302,84.331 C432.738,84.331 433.092,84.685 433.092,85.121 C433.092,85.558 432.738,85.911 432.302,85.911 C431.866,85.911 431.512,85.558 431.512,85.121" id="Fill-1533" class="icon-color"/>
      <path d="M431.974,78.118 C431.974,77.937 432.121,77.79 432.302,77.79 C432.483,77.79 432.63,77.937 432.63,78.118 C432.63,78.299 432.483,78.446 432.302,78.446 C432.121,78.446 431.974,78.299 431.974,78.118" id="Fill-1534" class="icon-color"/>
      <path d="M432.436,71.114 C432.436,71.189 432.376,71.249 432.302,71.249 C432.228,71.249 432.167,71.189 432.167,71.114 C432.167,71.04 432.228,70.98 432.302,70.98 C432.376,70.98 432.436,71.04 432.436,71.114" id="Fill-1535" class="icon-color"/>
      <path d="M432.899,64.111 C432.899,64.441 432.632,64.708 432.302,64.708 C431.973,64.708 431.705,64.441 431.705,64.111 C431.705,63.781 431.973,63.514 432.302,63.514 C432.632,63.514 432.899,63.781 432.899,64.111" id="Fill-1536" class="icon-color"/>
      <path d="M433.361,57.108 C433.361,57.692 432.887,58.166 432.302,58.166 C431.717,58.166 431.243,57.692 431.243,57.108 C431.243,56.523 431.717,56.049 432.302,56.049 C432.887,56.049 433.361,56.523 433.361,57.108" id="Fill-1537" class="icon-color"/>
      <path d="M433.823,50.104 C433.823,50.944 433.142,51.625 432.302,51.625 C431.462,51.625 430.781,50.944 430.781,50.104 C430.781,49.264 431.462,48.583 432.302,48.583 C433.142,48.583 433.823,49.264 433.823,50.104" id="Fill-1538" class="icon-color"/>
      <path d="M430.249,125.922 C430.063,124.789 430.83,123.718 431.964,123.531 C433.098,123.345 434.168,124.112 434.355,125.246 C434.541,126.38 433.774,127.45 432.64,127.637 C431.507,127.823 430.436,127.056 430.249,125.922" id="Fill-1539" class="icon-color"/>
      <path d="M429.042,126.14 C429.042,127.59 427.867,128.765 426.417,128.765 C424.968,128.765 423.792,127.59 423.792,126.14 C423.792,124.69 424.968,123.514 426.417,123.514 C427.867,123.514 429.042,124.69 429.042,126.14" id="Fill-1540" class="icon-color"/>
      <path d="M426.504,121.225 C425.199,121.273 424.103,120.254 424.055,118.949 C424.007,117.644 425.026,116.548 426.331,116.5 C427.636,116.452 428.732,117.471 428.78,118.776 C428.828,120.081 427.809,121.177 426.504,121.225" id="Fill-1541" class="icon-color"/>
      <path d="M426.526,113.685 C425.366,113.781 424.349,112.918 424.253,111.758 C424.157,110.598 425.02,109.581 426.18,109.485 C427.34,109.389 428.358,110.252 428.453,111.412 C428.549,112.572 427.686,113.59 426.526,113.685" id="Fill-1542" class="icon-color"/>
      <path d="M426.583,106.145 C425.568,106.289 424.629,105.582 424.486,104.567 C424.342,103.553 425.049,102.613 426.064,102.47 C427.079,102.327 428.018,103.033 428.161,104.048 C428.305,105.063 427.598,106.002 426.583,106.145" id="Fill-1543" class="icon-color"/>
      <path d="M426.697,91.066 C425.972,91.305 425.19,90.911 424.952,90.186 C424.712,89.461 425.106,88.679 425.831,88.44 C426.556,88.201 427.337,88.595 427.577,89.32 C427.816,90.045 427.422,90.826 426.697,91.066" id="Fill-1544" class="icon-color"/>
      <path d="M426.803,83.526 C426.223,83.813 425.52,83.575 425.233,82.995 C424.947,82.415 425.184,81.712 425.764,81.425 C426.344,81.139 427.047,81.376 427.333,81.956 C427.62,82.536 427.383,83.239 426.803,83.526" id="Fill-1545" class="icon-color"/>
      <path d="M426.923,75.986 C426.488,76.321 425.864,76.239 425.529,75.804 C425.195,75.369 425.276,74.745 425.711,74.411 C426.146,74.076 426.77,74.157 427.104,74.592 C427.439,75.027 427.358,75.651 426.923,75.986" id="Fill-1546" class="icon-color"/>
      <path d="M427.043,68.446 C426.753,68.828 426.208,68.904 425.825,68.614 C425.443,68.324 425.368,67.778 425.658,67.396 C425.948,67.013 426.493,66.938 426.875,67.228 C427.258,67.518 427.333,68.063 427.043,68.446" id="Fill-1547" class="icon-color"/>
      <path d="M427.197,60.906 C427.052,61.336 426.585,61.568 426.155,61.423 C425.724,61.278 425.493,60.811 425.638,60.381 C425.783,59.951 426.249,59.719 426.68,59.864 C427.11,60.009 427.342,60.476 427.197,60.906" id="Fill-1548" class="icon-color"/>
      <path d="M427.283,53.366 C427.283,53.844 426.896,54.232 426.417,54.232 C425.939,54.232 425.551,53.844 425.551,53.366 C425.551,52.888 425.939,52.5 426.417,52.5 C426.896,52.5 427.283,52.888 427.283,53.366" id="Fill-1549" class="icon-color"/>
      <path d="M426.64,98.605 C425.77,98.797 424.91,98.246 424.719,97.377 C424.527,96.507 425.077,95.647 425.947,95.455 C426.817,95.264 427.678,95.814 427.869,96.684 C428.06,97.554 427.51,98.414 426.64,98.605" id="Fill-1550" class="icon-color"/>
      <path d="M504.89,38.801 C504.89,37.904 505.617,37.176 506.515,37.176 C507.412,37.176 508.14,37.904 508.14,38.801 C508.14,39.699 507.412,40.426 506.515,40.426 C505.617,40.426 504.89,39.699 504.89,38.801" id="Fill-1551" class="icon-color"/>
      <path d="M506.797,44.072 C507.604,44.228 508.133,45.009 507.977,45.816 C507.821,46.624 507.04,47.152 506.233,46.997 C505.425,46.841 504.897,46.06 505.052,45.252 C505.208,44.445 505.989,43.916 506.797,44.072" id="Fill-1552" class="icon-color"/>
      <path d="M507.078,50.967 C507.796,51.279 508.126,52.113 507.815,52.831 C507.503,53.549 506.669,53.878 505.951,53.567 C505.233,53.256 504.903,52.421 505.215,51.703 C505.526,50.986 506.361,50.656 507.078,50.967" id="Fill-1553" class="icon-color"/>
      <path d="M507.361,57.863 C507.989,58.33 508.119,59.218 507.652,59.846 C507.185,60.474 506.297,60.605 505.669,60.138 C505.041,59.671 504.91,58.783 505.377,58.154 C505.844,57.526 506.732,57.396 507.361,57.863" id="Fill-1554" class="icon-color"/>
      <path d="M507.642,64.758 C508.181,65.381 508.112,66.322 507.49,66.861 C506.867,67.399 505.926,67.331 505.387,66.708 C504.849,66.085 504.917,65.144 505.54,64.605 C506.162,64.067 507.104,64.135 507.642,64.758" id="Fill-1555" class="icon-color"/>
      <path d="M507.924,71.653 C508.373,72.432 508.106,73.427 507.327,73.875 C506.549,74.324 505.554,74.057 505.105,73.279 C504.657,72.5 504.924,71.505 505.702,71.056 C506.481,70.608 507.475,70.875 507.924,71.653" id="Fill-1556" class="icon-color"/>
      <path d="M508.206,78.549 C508.565,79.483 508.099,80.531 507.165,80.89 C506.23,81.249 505.182,80.783 504.823,79.849 C504.464,78.915 504.931,77.867 505.865,77.508 C506.799,77.149 507.847,77.615 508.206,78.549" id="Fill-1557" class="icon-color"/>
      <path d="M508.488,85.444 C508.757,86.534 508.092,87.636 507.002,87.905 C505.912,88.174 504.811,87.509 504.542,86.419 C504.272,85.329 504.937,84.228 506.027,83.958 C507.117,83.689 508.219,84.355 508.488,85.444" id="Fill-1558" class="icon-color"/>
      <path d="M508.77,92.34 C508.949,93.585 508.085,94.74 506.84,94.92 C505.594,95.099 504.439,94.235 504.259,92.99 C504.08,91.744 504.944,90.589 506.19,90.41 C507.435,90.23 508.59,91.094 508.77,92.34" id="Fill-1559" class="icon-color"/>
      <path d="M509.052,99.235 C509.141,100.636 508.078,101.845 506.677,101.935 C505.276,102.025 504.067,100.961 503.978,99.56 C503.888,98.159 504.951,96.95 506.352,96.861 C507.753,96.771 508.962,97.834 509.052,99.235" id="Fill-1560" class="icon-color"/>
      <path d="M503.696,106.131 C503.696,104.574 504.958,103.312 506.515,103.312 C508.072,103.312 509.334,104.574 509.334,106.131 C509.334,107.688 508.072,108.95 506.515,108.95 C504.958,108.95 503.696,107.688 503.696,106.131" id="Fill-1561" class="icon-color"/>
      <path d="M508.445,119.596 C508.445,120.663 507.581,121.527 506.515,121.527 C505.449,121.527 504.585,120.663 504.585,119.596 C504.585,118.53 505.449,117.666 506.515,117.666 C507.581,117.666 508.445,118.53 508.445,119.596" id="Fill-1562" class="icon-color"/>
      <path d="M508,126.329 C508,127.15 507.335,127.815 506.515,127.815 C505.694,127.815 505.029,127.15 505.029,126.329 C505.029,125.509 505.694,124.844 506.515,124.844 C507.335,124.844 508,125.509 508,126.329" id="Fill-1563" class="icon-color"/>
      <path d="M507.556,133.062 C507.556,133.638 507.09,134.104 506.515,134.104 C505.939,134.104 505.473,133.638 505.473,133.062 C505.473,132.487 505.939,132.021 506.515,132.021 C507.09,132.021 507.556,132.487 507.556,133.062" id="Fill-1564" class="icon-color"/>
      <path d="M507.112,139.795 C507.112,140.125 506.844,140.392 506.515,140.392 C506.185,140.392 505.918,140.125 505.918,139.795 C505.918,139.465 506.185,139.198 506.515,139.198 C506.844,139.198 507.112,139.465 507.112,139.795" id="Fill-1565" class="icon-color"/>
      <path d="M506.667,146.528 C506.667,146.612 506.599,146.681 506.515,146.681 C506.43,146.681 506.362,146.612 506.362,146.528 C506.362,146.444 506.43,146.375 506.515,146.375 C506.599,146.375 506.667,146.444 506.667,146.528" id="Fill-1566" class="icon-color"/>
      <path d="M506.223,153.261 C506.223,153.1 506.354,152.969 506.515,152.969 C506.676,152.969 506.806,153.1 506.806,153.261 C506.806,153.422 506.676,153.553 506.515,153.553 C506.354,153.553 506.223,153.422 506.223,153.261" id="Fill-1567" class="icon-color"/>
      <path d="M505.779,159.994 C505.779,159.587 506.108,159.258 506.515,159.258 C506.921,159.258 507.251,159.587 507.251,159.994 C507.251,160.401 506.921,160.73 506.515,160.73 C506.108,160.73 505.779,160.401 505.779,159.994" id="Fill-1568" class="icon-color"/>
      <path d="M505.334,166.727 C505.334,166.075 505.863,165.546 506.515,165.546 C507.167,165.546 507.695,166.075 507.695,166.727 C507.695,167.379 507.167,167.907 506.515,167.907 C505.863,167.907 505.334,167.379 505.334,166.727" id="Fill-1569" class="icon-color"/>
      <path d="M504.89,173.46 C504.89,172.562 505.617,171.835 506.515,171.835 C507.412,171.835 508.14,172.562 508.14,173.46 C508.14,174.357 507.412,175.085 506.515,175.085 C505.617,175.085 504.89,174.357 504.89,173.46" id="Fill-1570" class="icon-color"/>
      <path d="M508.889,112.864 C508.889,114.175 507.826,115.238 506.515,115.238 C505.203,115.238 504.14,114.175 504.14,112.864 C504.14,111.552 505.203,110.489 506.515,110.489 C507.826,110.489 508.889,111.552 508.889,112.864" id="Fill-1571" class="icon-color"/>
      <path d="M498.502,42.693 C498.502,41.831 499.201,41.133 500.063,41.133 C500.924,41.133 501.623,41.831 501.623,42.693 C501.623,43.555 500.924,44.253 500.063,44.253 C499.201,44.253 498.502,43.555 498.502,42.693" id="Fill-1572" class="icon-color"/>
      <path d="M500.333,47.754 C501.109,47.904 501.616,48.654 501.467,49.43 C501.317,50.205 500.568,50.713 499.792,50.563 C499.016,50.414 498.509,49.664 498.658,48.888 C498.808,48.112 499.558,47.605 500.333,47.754" id="Fill-1573" class="icon-color"/>
      <path d="M500.604,54.376 C501.293,54.675 501.61,55.477 501.311,56.166 C501.012,56.855 500.21,57.172 499.521,56.873 C498.832,56.574 498.515,55.773 498.814,55.083 C499.113,54.394 499.914,54.077 500.604,54.376" id="Fill-1574" class="icon-color"/>
      <path d="M500.875,60.998 C501.478,61.447 501.603,62.299 501.155,62.903 C500.706,63.506 499.854,63.631 499.25,63.183 C498.647,62.734 498.522,61.882 498.97,61.278 C499.419,60.675 500.271,60.55 500.875,60.998" id="Fill-1575" class="icon-color"/>
      <path d="M501.145,67.62 C501.663,68.218 501.597,69.122 500.999,69.639 C500.401,70.156 499.497,70.091 498.98,69.492 C498.462,68.895 498.528,67.99 499.126,67.473 C499.724,66.956 500.628,67.022 501.145,67.62" id="Fill-1576" class="icon-color"/>
      <path d="M501.416,74.242 C501.847,74.989 501.59,75.945 500.843,76.376 C500.095,76.806 499.14,76.55 498.709,75.802 C498.278,75.055 498.535,74.099 499.282,73.668 C500.03,73.238 500.985,73.494 501.416,74.242" id="Fill-1577" class="icon-color"/>
      <path d="M501.687,80.864 C502.031,81.761 501.584,82.767 500.687,83.112 C499.79,83.457 498.783,83.009 498.438,82.112 C498.093,81.215 498.541,80.208 499.438,79.864 C500.335,79.519 501.342,79.966 501.687,80.864" id="Fill-1578" class="icon-color"/>
      <path d="M502.228,94.107 C502.401,95.303 501.571,96.413 500.374,96.585 C499.178,96.757 498.069,95.928 497.897,94.732 C497.724,93.535 498.554,92.426 499.75,92.254 C500.947,92.081 502.056,92.911 502.228,94.107" id="Fill-1579" class="icon-color"/>
      <path d="M502.499,100.729 C502.585,102.075 501.564,103.235 500.219,103.322 C498.873,103.408 497.712,102.387 497.626,101.041 C497.54,99.696 498.561,98.535 499.906,98.449 C501.252,98.363 502.413,99.384 502.499,100.729" id="Fill-1580" class="icon-color"/>
      <path d="M497.355,107.351 C497.355,105.856 498.568,104.644 500.063,104.644 C501.558,104.644 502.77,105.856 502.77,107.351 C502.77,108.846 501.558,110.058 500.063,110.058 C498.568,110.058 497.355,108.846 497.355,107.351" id="Fill-1581" class="icon-color"/>
      <path d="M502.343,113.955 C502.343,115.215 501.322,116.236 500.063,116.236 C498.803,116.236 497.782,115.215 497.782,113.955 C497.782,112.696 498.803,111.675 500.063,111.675 C501.322,111.675 502.343,112.696 502.343,113.955" id="Fill-1582" class="icon-color"/>
      <path d="M501.916,120.559 C501.916,121.583 501.086,122.413 500.063,122.413 C499.039,122.413 498.209,121.583 498.209,120.559 C498.209,119.536 499.039,118.706 500.063,118.706 C501.086,118.706 501.916,119.536 501.916,120.559" id="Fill-1583" class="icon-color"/>
      <path d="M501.489,127.164 C501.489,127.952 500.851,128.59 500.063,128.59 C499.275,128.59 498.636,127.952 498.636,127.164 C498.636,126.375 499.275,125.737 500.063,125.737 C500.851,125.737 501.489,126.375 501.489,127.164" id="Fill-1584" class="icon-color"/>
      <path d="M501.063,133.768 C501.063,134.32 500.615,134.768 500.063,134.768 C499.51,134.768 499.062,134.32 499.062,133.768 C499.062,133.215 499.51,132.768 500.063,132.768 C500.615,132.768 501.063,133.215 501.063,133.768" id="Fill-1585" class="icon-color"/>
      <path d="M500.636,140.372 C500.636,140.688 500.379,140.945 500.063,140.945 C499.746,140.945 499.489,140.688 499.489,140.372 C499.489,140.055 499.746,139.799 500.063,139.799 C500.379,139.799 500.636,140.055 500.636,140.372" id="Fill-1586" class="icon-color"/>
      <path d="M500.209,146.976 C500.209,147.057 500.143,147.123 500.063,147.123 C499.982,147.123 499.916,147.057 499.916,146.976 C499.916,146.895 499.982,146.829 500.063,146.829 C500.143,146.829 500.209,146.895 500.209,146.976" id="Fill-1587" class="icon-color"/>
      <path d="M499.782,153.58 C499.782,153.425 499.908,153.3 500.063,153.3 C500.217,153.3 500.343,153.425 500.343,153.58 C500.343,153.735 500.217,153.86 500.063,153.86 C499.908,153.86 499.782,153.735 499.782,153.58" id="Fill-1588" class="icon-color"/>
      <path d="M499.355,160.184 C499.355,159.794 499.672,159.477 500.063,159.477 C500.453,159.477 500.769,159.794 500.769,160.184 C500.769,160.575 500.453,160.891 500.063,160.891 C499.672,160.891 499.355,160.575 499.355,160.184" id="Fill-1589" class="icon-color"/>
      <path d="M498.929,166.788 C498.929,166.162 499.436,165.655 500.063,165.655 C500.689,165.655 501.196,166.162 501.196,166.788 C501.196,167.414 500.689,167.922 500.063,167.922 C499.436,167.922 498.929,167.414 498.929,166.788" id="Fill-1590" class="icon-color"/>
      <path d="M498.502,173.392 C498.502,172.531 499.201,171.832 500.063,171.832 C500.924,171.832 501.623,172.531 501.623,173.392 C501.623,174.254 500.924,174.953 500.063,174.953 C499.201,174.953 498.502,174.254 498.502,173.392" id="Fill-1591" class="icon-color"/>
      <path d="M501.957,87.486 C502.216,88.532 501.577,89.59 500.531,89.849 C499.484,90.107 498.426,89.468 498.167,88.422 C497.909,87.375 498.548,86.317 499.594,86.059 C500.641,85.8 501.699,86.439 501.957,87.486" id="Fill-1592" class="icon-color"/>
      <path d="M490.99,118.183 C490.99,116.86 492.063,115.787 493.386,115.787 C494.71,115.787 495.783,116.86 495.783,118.183 C495.783,119.507 494.71,120.58 493.386,120.58 C492.063,120.58 490.99,119.507 490.99,118.183" id="Fill-1593" class="icon-color"/>
      <path d="M493.309,128.404 C494.412,128.331 495.365,129.167 495.437,130.269 C495.51,131.372 494.675,132.325 493.572,132.398 C492.469,132.471 491.516,131.636 491.443,130.533 C491.37,129.43 492.206,128.477 493.309,128.404" id="Fill-1594" class="icon-color"/>
      <path d="M493.268,134.713 C494.26,134.604 495.153,135.32 495.263,136.313 C495.372,137.305 494.655,138.198 493.663,138.308 C492.67,138.417 491.777,137.7 491.668,136.708 C491.559,135.715 492.275,134.822 493.268,134.713" id="Fill-1595" class="icon-color"/>
      <path d="M493.227,141.021 C494.109,140.876 494.942,141.474 495.088,142.356 C495.233,143.238 494.636,144.071 493.754,144.217 C492.871,144.362 492.038,143.765 491.892,142.883 C491.747,142 492.344,141.167 493.227,141.021" id="Fill-1596" class="icon-color"/>
      <path d="M493.186,147.33 C493.958,147.148 494.731,147.627 494.913,148.399 C495.095,149.171 494.617,149.944 493.845,150.126 C493.072,150.308 492.299,149.83 492.117,149.058 C491.935,148.285 492.414,147.512 493.186,147.33" id="Fill-1597" class="icon-color"/>
      <path d="M493.129,153.639 C493.791,153.421 494.505,153.78 494.723,154.442 C494.941,155.104 494.582,155.817 493.92,156.035 C493.258,156.254 492.545,155.894 492.326,155.232 C492.108,154.571 492.468,153.857 493.129,153.639" id="Fill-1598" class="icon-color"/>
      <path d="M493.04,159.948 C493.592,159.693 494.245,159.933 494.5,160.485 C494.755,161.036 494.514,161.69 493.963,161.945 C493.411,162.199 492.758,161.959 492.503,161.407 C492.248,160.856 492.489,160.202 493.04,159.948" id="Fill-1599" class="icon-color"/>
      <path d="M492.951,166.256 C493.393,165.965 493.986,166.087 494.277,166.528 C494.568,166.969 494.447,167.563 494.005,167.854 C493.564,168.145 492.971,168.023 492.68,167.582 C492.389,167.141 492.51,166.547 492.951,166.256" id="Fill-1600" class="icon-color"/>
      <path d="M492.862,172.565 C493.193,172.237 493.727,172.24 494.055,172.571 C494.382,172.902 494.379,173.436 494.048,173.763 C493.717,174.091 493.184,174.088 492.856,173.757 C492.529,173.426 492.532,172.892 492.862,172.565" id="Fill-1601" class="icon-color"/>
      <path d="M492.727,178.874 C492.948,178.51 493.422,178.394 493.786,178.614 C494.149,178.835 494.265,179.309 494.045,179.672 C493.824,180.036 493.35,180.152 492.987,179.932 C492.623,179.711 492.507,179.237 492.727,178.874" id="Fill-1602" class="icon-color"/>
      <path d="M492.662,185.182 C492.772,184.782 493.186,184.547 493.586,184.657 C493.986,184.768 494.221,185.182 494.111,185.582 C494,185.982 493.587,186.217 493.186,186.107 C492.786,185.996 492.551,185.582 492.662,185.182" id="Fill-1603" class="icon-color"/>
      <path d="M492.596,191.491 C492.596,191.054 492.949,190.701 493.386,190.701 C493.823,190.701 494.176,191.054 494.176,191.491 C494.176,191.928 493.823,192.282 493.386,192.282 C492.949,192.282 492.596,191.928 492.596,191.491" id="Fill-1604" class="icon-color"/>
      <path d="M493.951,62.256 C493.951,62.696 493.594,63.053 493.154,63.053 C492.713,63.053 492.357,62.696 492.357,62.256 C492.357,61.816 492.713,61.459 493.154,61.459 C493.594,61.459 493.951,61.816 493.951,62.256" id="Fill-1605" class="icon-color"/>
      <path d="M494.198,68.479 C494.198,69.056 493.73,69.523 493.154,69.523 C492.577,69.523 492.11,69.056 492.11,68.479 C492.11,67.903 492.577,67.435 493.154,67.435 C493.73,67.435 494.198,67.903 494.198,68.479" id="Fill-1606" class="icon-color"/>
      <path d="M494.444,74.702 C494.444,75.415 493.867,75.993 493.154,75.993 C492.441,75.993 491.863,75.415 491.863,74.702 C491.863,73.989 492.441,73.411 493.154,73.411 C493.867,73.411 494.444,73.989 494.444,74.702" id="Fill-1607" class="icon-color"/>
      <path d="M494.691,80.925 C494.691,81.775 494.003,82.464 493.154,82.464 C492.304,82.464 491.616,81.775 491.616,80.925 C491.616,80.076 492.304,79.388 493.154,79.388 C494.003,79.388 494.691,80.076 494.691,80.925" id="Fill-1608" class="icon-color"/>
      <path d="M494.939,87.149 C494.939,88.134 494.139,88.934 493.154,88.934 C492.168,88.934 491.369,88.134 491.369,87.149 C491.369,86.163 492.168,85.363 493.154,85.363 C494.139,85.363 494.939,86.163 494.939,87.149" id="Fill-1609" class="icon-color"/>
      <path d="M495.185,93.372 C495.185,94.494 494.276,95.404 493.154,95.404 C492.031,95.404 491.122,94.494 491.122,93.372 C491.122,92.249 492.031,91.34 493.154,91.34 C494.276,91.34 495.185,92.249 495.185,93.372" id="Fill-1610" class="icon-color"/>
      <path d="M495.432,99.594 C495.432,100.853 494.412,101.874 493.154,101.874 C491.895,101.874 490.875,100.853 490.875,99.594 C490.875,98.336 491.895,97.316 493.154,97.316 C494.412,97.316 495.432,98.336 495.432,99.594" id="Fill-1611" class="icon-color"/>
      <path d="M495.679,105.818 C495.679,107.213 494.549,108.344 493.154,108.344 C491.758,108.344 490.628,107.213 490.628,105.818 C490.628,104.423 491.758,103.292 493.154,103.292 C494.549,103.292 495.679,104.423 495.679,105.818" id="Fill-1612" class="icon-color"/>
      <path d="M495.926,112.041 C495.926,113.572 494.685,114.814 493.154,114.814 C491.622,114.814 490.381,113.572 490.381,112.041 C490.381,110.509 491.622,109.268 493.154,109.268 C494.685,109.268 495.926,110.509 495.926,112.041" id="Fill-1613" class="icon-color"/>
      <path d="M493.32,122.095 C494.533,122.059 495.546,123.013 495.583,124.226 C495.619,125.44 494.665,126.453 493.452,126.489 C492.239,126.526 491.226,125.571 491.189,124.358 C491.153,123.145 492.107,122.132 493.32,122.095" id="Fill-1614" class="icon-color"/>
      <path d="M511.347,2.316 C511.347,1.454 512.046,0.755 512.907,0.755 C513.769,0.755 514.468,1.454 514.468,2.316 C514.468,3.177 513.769,3.876 512.907,3.876 C512.046,3.876 511.347,3.177 511.347,2.316" id="Fill-1615" class="icon-color"/>
      <path d="M513.178,8.767 C513.954,8.916 514.461,9.666 514.312,10.442 C514.162,11.218 513.412,11.725 512.637,11.576 C511.861,11.426 511.353,10.676 511.503,9.9 C511.653,9.125 512.402,8.617 513.178,8.767" id="Fill-1616" class="icon-color"/>
      <path d="M513.449,16.779 C514.138,17.078 514.455,17.879 514.156,18.568 C513.857,19.258 513.055,19.574 512.366,19.275 C511.677,18.976 511.36,18.175 511.659,17.486 C511.958,16.796 512.76,16.48 513.449,16.779" id="Fill-1617" class="icon-color"/>
      <path d="M513.72,24.79 C514.323,25.239 514.448,26.091 514,26.695 C513.551,27.298 512.699,27.423 512.095,26.975 C511.492,26.526 511.367,25.674 511.815,25.07 C512.264,24.467 513.117,24.342 513.72,24.79" id="Fill-1618" class="icon-color"/>
      <path d="M513.99,32.802 C514.507,33.4 514.442,34.304 513.844,34.821 C513.246,35.338 512.342,35.273 511.824,34.675 C511.308,34.077 511.373,33.173 511.971,32.655 C512.569,32.138 513.473,32.204 513.99,32.802" id="Fill-1619" class="icon-color"/>
      <path d="M514.261,40.814 C514.692,41.561 514.435,42.517 513.688,42.948 C512.94,43.378 511.985,43.122 511.554,42.374 C511.123,41.627 511.38,40.671 512.127,40.24 C512.875,39.81 513.83,40.066 514.261,40.814" id="Fill-1620" class="icon-color"/>
      <path d="M514.532,48.826 C514.876,49.723 514.429,50.729 513.532,51.074 C512.635,51.419 511.628,50.971 511.283,50.074 C510.938,49.177 511.386,48.17 512.283,47.826 C513.18,47.481 514.187,47.928 514.532,48.826" id="Fill-1621" class="icon-color"/>
      <path d="M514.802,56.837 C515.061,57.884 514.422,58.942 513.376,59.2 C512.329,59.459 511.271,58.82 511.012,57.774 C510.754,56.727 511.393,55.669 512.439,55.41 C513.486,55.152 514.544,55.791 514.802,56.837" id="Fill-1622" class="icon-color"/>
      <path d="M515.073,64.849 C515.246,66.045 514.416,67.155 513.22,67.327 C512.023,67.499 510.914,66.669 510.742,65.473 C510.569,64.277 511.399,63.168 512.595,62.996 C513.791,62.823 514.901,63.653 515.073,64.849" id="Fill-1623" class="icon-color"/>
      <path d="M515.344,72.861 C515.43,74.207 514.409,75.367 513.063,75.453 C511.718,75.539 510.557,74.519 510.471,73.173 C510.385,71.827 511.406,70.667 512.751,70.58 C514.097,70.494 515.258,71.515 515.344,72.861" id="Fill-1624" class="icon-color"/>
      <path d="M510.2,80.873 C510.2,79.377 511.412,78.166 512.907,78.166 C514.403,78.166 515.615,79.377 515.615,80.873 C515.615,82.368 514.403,83.58 512.907,83.58 C511.412,83.58 510.2,82.368 510.2,80.873" id="Fill-1625" class="icon-color"/>
      <path d="M515.188,88.387 C515.188,89.646 514.167,90.667 512.907,90.667 C511.648,90.667 510.627,89.646 510.627,88.387 C510.627,87.127 511.648,86.106 512.907,86.106 C514.167,86.106 515.188,87.127 515.188,88.387" id="Fill-1626" class="icon-color"/>
      <path d="M514.761,95.901 C514.761,96.924 513.931,97.754 512.907,97.754 C511.884,97.754 511.054,96.924 511.054,95.901 C511.054,94.877 511.884,94.047 512.907,94.047 C513.931,94.047 514.761,94.877 514.761,95.901" id="Fill-1627" class="icon-color"/>
      <path d="M513.908,110.929 C513.908,111.481 513.46,111.929 512.907,111.929 C512.355,111.929 511.907,111.481 511.907,110.929 C511.907,110.376 512.355,109.929 512.907,109.929 C513.46,109.929 513.908,110.376 513.908,110.929" id="Fill-1628" class="icon-color"/>
      <path d="M513.481,118.443 C513.481,118.76 513.224,119.016 512.907,119.016 C512.591,119.016 512.334,118.76 512.334,118.443 C512.334,118.126 512.591,117.87 512.907,117.87 C513.224,117.87 513.481,118.126 513.481,118.443" id="Fill-1629" class="icon-color"/>
      <path d="M513.054,125.957 C513.054,126.038 512.988,126.104 512.907,126.104 C512.826,126.104 512.761,126.038 512.761,125.957 C512.761,125.876 512.826,125.81 512.907,125.81 C512.988,125.81 513.054,125.876 513.054,125.957" id="Fill-1630" class="icon-color"/>
      <path d="M512.627,133.471 C512.627,133.316 512.753,133.191 512.907,133.191 C513.062,133.191 513.188,133.316 513.188,133.471 C513.188,133.626 513.062,133.751 512.907,133.751 C512.753,133.751 512.627,133.626 512.627,133.471" id="Fill-1631" class="icon-color"/>
      <path d="M512.201,140.985 C512.201,140.595 512.517,140.278 512.907,140.278 C513.298,140.278 513.614,140.595 513.614,140.985 C513.614,141.376 513.298,141.692 512.907,141.692 C512.517,141.692 512.201,141.376 512.201,140.985" id="Fill-1632" class="icon-color"/>
      <path d="M511.774,148.499 C511.774,147.873 512.281,147.366 512.907,147.366 C513.534,147.366 514.041,147.873 514.041,148.499 C514.041,149.125 513.534,149.633 512.907,149.633 C512.281,149.633 511.774,149.125 511.774,148.499" id="Fill-1633" class="icon-color"/>
      <path d="M511.347,156.013 C511.347,155.151 512.046,154.453 512.907,154.453 C513.769,154.453 514.468,155.151 514.468,156.013 C514.468,156.875 513.769,157.574 512.907,157.574 C512.046,157.574 511.347,156.875 511.347,156.013" id="Fill-1634" class="icon-color"/>
      <path d="M514.334,103.415 C514.334,104.203 513.695,104.842 512.907,104.842 C512.119,104.842 511.481,104.203 511.481,103.415 C511.481,102.627 512.119,101.988 512.907,101.988 C513.695,101.988 514.334,102.627 514.334,103.415" id="Fill-1635" class="icon-color"/>
      <path d="M530.75,56.471 C530.75,55.631 531.431,54.95 532.271,54.95 C533.112,54.95 533.793,55.631 533.793,56.471 C533.793,57.311 533.112,57.992 532.271,57.992 C531.431,57.992 530.75,57.311 530.75,56.471" id="Fill-1636" class="icon-color"/>
      <path d="M532.565,63.176 C533.311,63.338 533.786,64.075 533.624,64.822 C533.462,65.568 532.725,66.043 531.978,65.881 C531.232,65.719 530.757,64.982 530.919,64.235 C531.081,63.489 531.818,63.014 532.565,63.176" id="Fill-1637" class="icon-color"/>
      <path d="M533.151,79.63 C533.711,80.116 533.771,80.963 533.285,81.523 C532.8,82.083 531.952,82.144 531.392,81.658 C530.832,81.172 530.771,80.324 531.257,79.764 C531.743,79.204 532.591,79.144 533.151,79.63" id="Fill-1638" class="icon-color"/>
      <path d="M533.444,87.856 C533.911,88.504 533.764,89.407 533.117,89.874 C532.469,90.341 531.565,90.194 531.099,89.546 C530.632,88.899 530.779,87.995 531.426,87.529 C532.074,87.062 532.978,87.209 533.444,87.856" id="Fill-1639" class="icon-color"/>
      <path d="M533.738,96.083 C534.111,96.893 533.757,97.852 532.948,98.225 C532.138,98.599 531.179,98.245 530.805,97.435 C530.432,96.625 530.786,95.666 531.595,95.293 C532.405,94.92 533.364,95.273 533.738,96.083" id="Fill-1640" class="icon-color"/>
      <path d="M534.031,104.31 C534.311,105.281 533.75,106.296 532.779,106.576 C531.807,106.856 530.792,106.295 530.512,105.324 C530.232,104.352 530.793,103.337 531.764,103.057 C532.736,102.777 533.751,103.338 534.031,104.31" id="Fill-1641" class="icon-color"/>
      <path d="M534.324,112.536 C534.511,113.67 533.743,114.74 532.609,114.927 C531.476,115.114 530.406,114.346 530.219,113.212 C530.032,112.079 530.8,111.008 531.933,110.822 C533.067,110.635 534.137,111.403 534.324,112.536" id="Fill-1642" class="icon-color"/>
      <path d="M534.617,120.763 C534.711,122.058 533.736,123.184 532.44,123.278 C531.145,123.371 530.019,122.397 529.926,121.101 C529.832,119.805 530.807,118.679 532.102,118.586 C533.398,118.493 534.524,119.468 534.617,120.763" id="Fill-1643" class="icon-color"/>
      <path d="M529.632,128.99 C529.632,127.532 530.814,126.35 532.271,126.35 C533.729,126.35 534.91,127.532 534.91,128.99 C534.91,130.447 533.729,131.629 532.271,131.629 C530.814,131.629 529.632,130.447 529.632,128.99" id="Fill-1644" class="icon-color"/>
      <path d="M534.448,135.993 C534.448,137.195 533.474,138.17 532.271,138.17 C531.069,138.17 530.095,137.195 530.095,135.993 C530.095,134.791 531.069,133.816 532.271,133.816 C533.474,133.816 534.448,134.791 534.448,135.993" id="Fill-1645" class="icon-color"/>
      <path d="M533.986,142.997 C533.986,143.944 533.218,144.711 532.271,144.711 C531.325,144.711 530.557,143.944 530.557,142.997 C530.557,142.05 531.325,141.282 532.271,141.282 C533.218,141.282 533.986,142.05 533.986,142.997" id="Fill-1646" class="icon-color"/>
      <path d="M533.524,150 C533.524,150.692 532.963,151.252 532.271,151.252 C531.58,151.252 531.019,150.692 531.019,150 C531.019,149.308 531.58,148.748 532.271,148.748 C532.963,148.748 533.524,149.308 533.524,150" id="Fill-1647" class="icon-color"/>
      <path d="M533.062,157.004 C533.062,157.44 532.708,157.794 532.271,157.794 C531.835,157.794 531.481,157.44 531.481,157.004 C531.481,156.567 531.835,156.213 532.271,156.213 C532.708,156.213 533.062,156.567 533.062,157.004" id="Fill-1648" class="icon-color"/>
      <path d="M532.599,164.007 C532.599,164.188 532.453,164.335 532.271,164.335 C532.091,164.335 531.944,164.188 531.944,164.007 C531.944,163.826 532.091,163.679 532.271,163.679 C532.453,163.679 532.599,163.826 532.599,164.007" id="Fill-1649" class="icon-color"/>
      <path d="M532.137,171.01 C532.137,170.936 532.197,170.876 532.271,170.876 C532.346,170.876 532.406,170.936 532.406,171.01 C532.406,171.085 532.346,171.145 532.271,171.145 C532.197,171.145 532.137,171.085 532.137,171.01" id="Fill-1650" class="icon-color"/>
      <path d="M531.675,178.014 C531.675,177.684 531.942,177.417 532.271,177.417 C532.601,177.417 532.868,177.684 532.868,178.014 C532.868,178.344 532.601,178.611 532.271,178.611 C531.942,178.611 531.675,178.344 531.675,178.014" id="Fill-1651" class="icon-color"/>
      <path d="M531.213,185.017 C531.213,184.433 531.687,183.959 532.271,183.959 C532.856,183.959 533.331,184.433 533.331,185.017 C533.331,185.602 532.856,186.076 532.271,186.076 C531.687,186.076 531.213,185.602 531.213,185.017" id="Fill-1652" class="icon-color"/>
      <path d="M530.75,192.021 C530.75,191.181 531.431,190.5 532.271,190.5 C533.112,190.5 533.793,191.181 533.793,192.021 C533.793,192.861 533.112,193.542 532.271,193.542 C531.431,193.542 530.75,192.861 530.75,192.021" id="Fill-1653" class="icon-color"/>
      <path d="M532.858,71.403 C533.511,71.727 533.779,72.519 533.455,73.173 C533.131,73.826 532.338,74.093 531.685,73.769 C531.031,73.445 530.764,72.653 531.088,72 C531.412,71.346 532.204,71.079 532.858,71.403" id="Fill-1654" class="icon-color"/>
      <path d="M528.261,124.526 C528.261,125.976 527.086,127.151 525.636,127.151 C524.186,127.151 523.011,125.976 523.011,124.526 C523.011,123.076 524.186,121.901 525.636,121.901 C527.086,121.901 528.261,123.076 528.261,124.526" id="Fill-1655" class="icon-color"/>
      <path d="M525.724,119.511 C524.419,119.559 523.322,118.54 523.274,117.235 C523.227,115.93 524.246,114.833 525.55,114.786 C526.855,114.738 527.952,115.757 528,117.062 C528.047,118.367 527.029,119.463 525.724,119.511" id="Fill-1656" class="icon-color"/>
      <path d="M525.805,104.231 C524.79,104.375 523.852,103.668 523.708,102.653 C523.565,101.639 524.271,100.699 525.286,100.556 C526.301,100.413 527.24,101.119 527.383,102.134 C527.527,103.149 526.82,104.088 525.805,104.231" id="Fill-1657" class="icon-color"/>
      <path d="M525.862,96.591 C524.992,96.783 524.132,96.233 523.94,95.363 C523.749,94.493 524.299,93.633 525.169,93.441 C526.039,93.25 526.899,93.8 527.091,94.67 C527.282,95.54 526.732,96.4 525.862,96.591" id="Fill-1658" class="icon-color"/>
      <path d="M525.918,88.952 C525.193,89.191 524.412,88.797 524.173,88.072 C523.934,87.347 524.328,86.565 525.052,86.326 C525.777,86.087 526.559,86.481 526.798,87.206 C527.037,87.931 526.643,88.712 525.918,88.952" id="Fill-1659" class="icon-color"/>
      <path d="M526.029,81.312 C525.449,81.599 524.747,81.361 524.459,80.781 C524.173,80.201 524.41,79.498 524.99,79.212 C525.57,78.925 526.273,79.162 526.56,79.742 C526.847,80.322 526.609,81.025 526.029,81.312" id="Fill-1660" class="icon-color"/>
      <path d="M526.15,73.672 C525.715,74.007 525.091,73.925 524.756,73.49 C524.421,73.055 524.502,72.431 524.937,72.097 C525.372,71.762 525.996,71.843 526.331,72.278 C526.666,72.713 526.585,73.337 526.15,73.672" id="Fill-1661" class="icon-color"/>
      <path d="M526.27,66.032 C525.98,66.415 525.435,66.49 525.053,66.2 C524.67,65.91 524.595,65.365 524.885,64.982 C525.175,64.599 525.72,64.524 526.102,64.814 C526.485,65.104 526.56,65.65 526.27,66.032" id="Fill-1662" class="icon-color"/>
      <path d="M526.424,58.392 C526.279,58.823 525.812,59.054 525.382,58.909 C524.951,58.764 524.72,58.297 524.865,57.867 C525.01,57.437 525.477,57.205 525.907,57.35 C526.337,57.495 526.569,57.962 526.424,58.392" id="Fill-1663" class="icon-color"/>
      <path d="M526.511,50.752 C526.511,51.23 526.124,51.618 525.645,51.618 C525.167,51.618 524.78,51.23 524.78,50.752 C524.78,50.274 525.167,49.886 525.645,49.886 C526.124,49.886 526.511,50.274 526.511,50.752" id="Fill-1664" class="icon-color"/>
      <path d="M525.749,111.871 C524.589,111.967 523.571,111.104 523.476,109.944 C523.38,108.784 524.243,107.767 525.403,107.671 C526.563,107.575 527.58,108.438 527.676,109.598 C527.772,110.758 526.909,111.776 525.749,111.871" id="Fill-1665" class="icon-color"/>
      <path d="M521.433,107.577 C521.433,108.9 520.36,109.973 519.036,109.973 C517.713,109.973 516.64,108.9 516.64,107.577 C516.64,106.253 517.713,105.18 519.036,105.18 C520.36,105.18 521.433,106.253 521.433,107.577" id="Fill-1666" class="icon-color"/>
      <path d="M519.102,103.606 C517.889,103.642 516.876,102.688 516.839,101.475 C516.803,100.262 517.757,99.249 518.97,99.212 C520.184,99.176 521.197,100.13 521.233,101.343 C521.269,102.557 520.315,103.57 519.102,103.606" id="Fill-1667" class="icon-color"/>
      <path d="M519.113,97.239 C518.01,97.312 517.057,96.477 516.985,95.374 C516.912,94.271 517.747,93.317 518.85,93.245 C519.953,93.172 520.906,94.007 520.979,95.11 C521.052,96.213 520.216,97.166 519.113,97.239" id="Fill-1668" class="icon-color"/>
      <path d="M519.154,90.872 C518.161,90.981 517.268,90.265 517.159,89.272 C517.05,88.279 517.766,87.386 518.759,87.277 C519.751,87.168 520.645,87.884 520.754,88.877 C520.863,89.87 520.147,90.763 519.154,90.872" id="Fill-1669" class="icon-color"/>
      <path d="M519.235,78.138 C518.463,78.32 517.69,77.841 517.508,77.069 C517.326,76.297 517.805,75.524 518.577,75.342 C519.349,75.16 520.122,75.639 520.304,76.411 C520.486,77.183 520.008,77.956 519.235,78.138" id="Fill-1670" class="icon-color"/>
      <path d="M519.294,71.771 C518.632,71.989 517.919,71.63 517.701,70.968 C517.482,70.306 517.842,69.593 518.504,69.374 C519.165,69.156 519.879,69.516 520.097,70.177 C520.315,70.839 519.956,71.553 519.294,71.771" id="Fill-1671" class="icon-color"/>
      <path d="M519.383,65.404 C518.831,65.659 518.178,65.418 517.923,64.866 C517.669,64.315 517.909,63.661 518.461,63.407 C519.012,63.152 519.666,63.393 519.92,63.944 C520.175,64.496 519.934,65.149 519.383,65.404" id="Fill-1672" class="icon-color"/>
      <path d="M519.472,59.037 C519.031,59.328 518.437,59.206 518.146,58.765 C517.855,58.324 517.977,57.73 518.418,57.439 C518.859,57.148 519.453,57.27 519.743,57.711 C520.034,58.152 519.913,58.746 519.472,59.037" id="Fill-1673" class="icon-color"/>
      <path d="M519.56,52.67 C519.23,52.997 518.696,52.994 518.368,52.664 C518.041,52.333 518.044,51.799 518.375,51.472 C518.706,51.144 519.239,51.147 519.567,51.478 C519.894,51.809 519.891,52.342 519.56,52.67" id="Fill-1674" class="icon-color"/>
      <path d="M519.695,46.303 C519.474,46.667 519.001,46.783 518.637,46.562 C518.273,46.341 518.157,45.868 518.378,45.504 C518.598,45.14 519.072,45.024 519.436,45.244 C519.799,45.465 519.916,45.939 519.695,46.303" id="Fill-1675" class="icon-color"/>
      <path d="M519.761,39.936 C519.651,40.336 519.237,40.571 518.836,40.461 C518.436,40.35 518.201,39.936 518.312,39.536 C518.422,39.136 518.836,38.901 519.236,39.012 C519.636,39.122 519.871,39.536 519.761,39.936" id="Fill-1676" class="icon-color"/>
      <path d="M519.827,33.569 C519.827,34.005 519.473,34.359 519.036,34.359 C518.6,34.359 518.246,34.005 518.246,33.569 C518.246,33.132 518.6,32.778 519.036,32.778 C519.473,32.778 519.827,33.132 519.827,33.569" id="Fill-1677" class="icon-color"/>
      <path d="M518.472,163.503 C518.472,163.063 518.829,162.706 519.269,162.706 C519.709,162.706 520.066,163.063 520.066,163.503 C520.066,163.944 519.709,164.3 519.269,164.3 C518.829,164.3 518.472,163.944 518.472,163.503" id="Fill-1678" class="icon-color"/>
      <path d="M518.225,157.28 C518.225,156.704 518.692,156.236 519.269,156.236 C519.845,156.236 520.313,156.704 520.313,157.28 C520.313,157.857 519.845,158.324 519.269,158.324 C518.692,158.324 518.225,157.857 518.225,157.28" id="Fill-1679" class="icon-color"/>
      <path d="M517.978,151.057 C517.978,150.344 518.556,149.766 519.269,149.766 C519.982,149.766 520.56,150.344 520.56,151.057 C520.56,151.77 519.982,152.348 519.269,152.348 C518.556,152.348 517.978,151.77 517.978,151.057" id="Fill-1680" class="icon-color"/>
      <path d="M517.731,144.834 C517.731,143.985 518.419,143.296 519.269,143.296 C520.118,143.296 520.807,143.985 520.807,144.834 C520.807,145.684 520.118,146.372 519.269,146.372 C518.419,146.372 517.731,145.684 517.731,144.834" id="Fill-1681" class="icon-color"/>
      <path d="M517.484,138.611 C517.484,137.625 518.283,136.826 519.269,136.826 C520.255,136.826 521.054,137.625 521.054,138.611 C521.054,139.597 520.255,140.396 519.269,140.396 C518.283,140.396 517.484,139.597 517.484,138.611" id="Fill-1682" class="icon-color"/>
      <path d="M517.237,132.388 C517.237,131.266 518.147,130.356 519.269,130.356 C520.391,130.356 521.301,131.266 521.301,132.388 C521.301,133.51 520.391,134.42 519.269,134.42 C518.147,134.42 517.237,133.51 517.237,132.388" id="Fill-1683" class="icon-color"/>
      <path d="M516.99,126.165 C516.99,124.907 518.01,123.886 519.269,123.886 C520.527,123.886 521.548,124.907 521.548,126.165 C521.548,127.424 520.527,128.444 519.269,128.444 C518.01,128.444 516.99,127.424 516.99,126.165" id="Fill-1684" class="icon-color"/>
      <path d="M516.743,119.942 C516.743,118.547 517.874,117.416 519.269,117.416 C520.664,117.416 521.795,118.547 521.795,119.942 C521.795,121.337 520.664,122.468 519.269,122.468 C517.874,122.468 516.743,121.337 516.743,119.942" id="Fill-1685" class="icon-color"/>
      <path d="M516.496,113.719 C516.496,112.188 517.737,110.946 519.269,110.946 C520.8,110.946 522.042,112.188 522.042,113.719 C522.042,115.25 520.8,116.492 519.269,116.492 C517.737,116.492 516.496,115.25 516.496,113.719" id="Fill-1686" class="icon-color"/>
      <path d="M519.195,84.505 C518.312,84.65 517.479,84.053 517.334,83.171 C517.188,82.288 517.785,81.455 518.668,81.31 C519.55,81.164 520.383,81.761 520.529,82.644 C520.674,83.526 520.077,84.359 519.195,84.505" id="Fill-1687" class="icon-color"/>
      <path d="M306.506,191.635 C306.506,192.533 305.778,193.26 304.881,193.26 C303.983,193.26 303.256,192.533 303.256,191.635 C303.256,190.738 303.983,190.01 304.881,190.01 C305.778,190.01 306.506,190.738 306.506,191.635" id="Fill-1688" class="icon-color"/>
      <path d="M304.599,186.365 C303.791,186.209 303.263,185.428 303.418,184.62 C303.574,183.813 304.355,183.284 305.163,183.44 C305.97,183.595 306.499,184.376 306.343,185.184 C306.188,185.992 305.406,186.52 304.599,186.365" id="Fill-1689" class="icon-color"/>
      <path d="M304.317,179.469 C303.599,179.158 303.269,178.323 303.581,177.605 C303.892,176.888 304.727,176.558 305.444,176.869 C306.162,177.181 306.492,178.015 306.181,178.733 C305.869,179.451 305.035,179.781 304.317,179.469" id="Fill-1690" class="icon-color"/>
      <path d="M304.035,172.574 C303.407,172.107 303.276,171.219 303.743,170.591 C304.21,169.962 305.098,169.832 305.726,170.299 C306.354,170.766 306.485,171.654 306.018,172.282 C305.551,172.91 304.663,173.041 304.035,172.574" id="Fill-1691" class="icon-color"/>
      <path d="M303.753,165.678 C303.215,165.056 303.283,164.114 303.906,163.576 C304.529,163.037 305.47,163.106 306.008,163.728 C306.547,164.351 306.478,165.293 305.856,165.831 C305.233,166.37 304.292,166.301 303.753,165.678" id="Fill-1692" class="icon-color"/>
      <path d="M303.471,158.783 C303.022,158.004 303.29,157.01 304.068,156.561 C304.847,156.112 305.841,156.38 306.29,157.158 C306.739,157.937 306.471,158.931 305.693,159.38 C304.915,159.829 303.92,159.561 303.471,158.783" id="Fill-1693" class="icon-color"/>
      <path d="M303.189,151.888 C302.83,150.953 303.297,149.905 304.231,149.546 C305.165,149.187 306.213,149.654 306.572,150.588 C306.931,151.522 306.465,152.57 305.531,152.929 C304.597,153.288 303.548,152.822 303.189,151.888" id="Fill-1694" class="icon-color"/>
      <path d="M302.907,144.992 C302.638,143.902 303.303,142.801 304.393,142.531 C305.483,142.262 306.585,142.927 306.854,144.017 C307.123,145.107 306.458,146.209 305.368,146.478 C304.278,146.747 303.177,146.082 302.907,144.992" id="Fill-1695" class="icon-color"/>
      <path d="M302.625,138.097 C302.446,136.851 303.31,135.696 304.556,135.517 C305.801,135.337 306.956,136.201 307.136,137.447 C307.315,138.692 306.451,139.847 305.206,140.027 C303.96,140.206 302.805,139.342 302.625,138.097" id="Fill-1696" class="icon-color"/>
      <path d="M302.344,131.201 C302.254,129.8 303.317,128.592 304.718,128.502 C306.119,128.412 307.328,129.475 307.418,130.876 C307.508,132.278 306.444,133.486 305.043,133.576 C303.642,133.666 302.433,132.603 302.344,131.201" id="Fill-1697" class="icon-color"/>
      <path d="M307.7,124.306 C307.7,125.863 306.438,127.125 304.881,127.125 C303.324,127.125 302.062,125.863 302.062,124.306 C302.062,122.749 303.324,121.487 304.881,121.487 C306.438,121.487 307.7,122.749 307.7,124.306" id="Fill-1698" class="icon-color"/>
      <path d="M302.506,117.573 C302.506,116.262 303.569,115.198 304.881,115.198 C306.192,115.198 307.255,116.262 307.255,117.573 C307.255,118.885 306.192,119.947 304.881,119.947 C303.569,119.947 302.506,118.885 302.506,117.573" id="Fill-1699" class="icon-color"/>
      <path d="M303.395,104.107 C303.395,103.287 304.06,102.621 304.881,102.621 C305.701,102.621 306.366,103.287 306.366,104.107 C306.366,104.928 305.701,105.593 304.881,105.593 C304.06,105.593 303.395,104.928 303.395,104.107" id="Fill-1700" class="icon-color"/>
      <path d="M303.839,97.374 C303.839,96.799 304.306,96.333 304.881,96.333 C305.456,96.333 305.922,96.799 305.922,97.374 C305.922,97.949 305.456,98.416 304.881,98.416 C304.306,98.416 303.839,97.949 303.839,97.374" id="Fill-1701" class="icon-color"/>
      <path d="M304.284,90.641 C304.284,90.312 304.551,90.044 304.881,90.044 C305.21,90.044 305.478,90.312 305.478,90.641 C305.478,90.971 305.21,91.238 304.881,91.238 C304.551,91.238 304.284,90.971 304.284,90.641" id="Fill-1702" class="icon-color"/>
      <path d="M304.728,83.908 C304.728,83.824 304.796,83.756 304.881,83.756 C304.965,83.756 305.033,83.824 305.033,83.908 C305.033,83.993 304.965,84.061 304.881,84.061 C304.796,84.061 304.728,83.993 304.728,83.908" id="Fill-1703" class="icon-color"/>
      <path d="M305.172,77.175 C305.172,77.336 305.042,77.467 304.881,77.467 C304.719,77.467 304.589,77.336 304.589,77.175 C304.589,77.014 304.719,76.884 304.881,76.884 C305.042,76.884 305.172,77.014 305.172,77.175" id="Fill-1704" class="icon-color"/>
      <path d="M305.617,70.443 C305.617,70.849 305.287,71.179 304.881,71.179 C304.474,71.179 304.145,70.849 304.145,70.443 C304.145,70.036 304.474,69.706 304.881,69.706 C305.287,69.706 305.617,70.036 305.617,70.443" id="Fill-1705" class="icon-color"/>
      <path d="M306.061,63.71 C306.061,64.362 305.533,64.89 304.881,64.89 C304.229,64.89 303.7,64.362 303.7,63.71 C303.7,63.058 304.229,62.529 304.881,62.529 C305.533,62.529 306.061,63.058 306.061,63.71" id="Fill-1706" class="icon-color"/>
      <path d="M306.506,56.977 C306.506,57.874 305.778,58.602 304.881,58.602 C303.983,58.602 303.256,57.874 303.256,56.977 C303.256,56.079 303.983,55.352 304.881,55.352 C305.778,55.352 306.506,56.079 306.506,56.977" id="Fill-1707" class="icon-color"/>
      <path d="M302.951,110.84 C302.951,109.774 303.815,108.91 304.881,108.91 C305.947,108.91 306.811,109.774 306.811,110.84 C306.811,111.906 305.947,112.77 304.881,112.77 C303.815,112.77 302.951,111.906 302.951,110.84" id="Fill-1708" class="icon-color"/>
      <path d="M309.772,46.578 C309.772,45.716 310.471,45.017 311.333,45.017 C312.195,45.017 312.893,45.716 312.893,46.578 C312.893,47.439 312.195,48.138 311.333,48.138 C310.471,48.138 309.772,47.439 309.772,46.578" id="Fill-1709" class="icon-color"/>
      <path d="M311.603,51.639 C312.379,51.789 312.887,52.538 312.737,53.314 C312.588,54.09 311.838,54.597 311.062,54.448 C310.286,54.298 309.779,53.548 309.928,52.773 C310.078,51.997 310.828,51.49 311.603,51.639" id="Fill-1710" class="icon-color"/>
      <path d="M311.874,58.261 C312.564,58.56 312.88,59.361 312.581,60.051 C312.282,60.74 311.481,61.057 310.791,60.758 C310.102,60.459 309.785,59.657 310.085,58.968 C310.383,58.278 311.185,57.962 311.874,58.261" id="Fill-1711" class="icon-color"/>
      <path d="M312.145,64.883 C312.748,65.331 312.874,66.184 312.425,66.787 C311.977,67.391 311.124,67.516 310.521,67.067 C309.917,66.619 309.792,65.766 310.241,65.163 C310.689,64.56 311.542,64.434 312.145,64.883" id="Fill-1712" class="icon-color"/>
      <path d="M312.416,71.505 C312.933,72.103 312.867,73.007 312.269,73.524 C311.671,74.041 310.767,73.975 310.25,73.377 C309.733,72.779 309.798,71.875 310.397,71.358 C310.995,70.841 311.899,70.907 312.416,71.505" id="Fill-1713" class="icon-color"/>
      <path d="M312.686,78.126 C313.117,78.874 312.861,79.829 312.113,80.26 C311.366,80.691 310.41,80.435 309.979,79.687 C309.548,78.939 309.805,77.984 310.553,77.553 C311.3,77.122 312.255,77.379 312.686,78.126" id="Fill-1714" class="icon-color"/>
      <path d="M312.957,84.748 C313.302,85.645 312.854,86.652 311.957,86.997 C311.06,87.342 310.053,86.894 309.709,85.997 C309.364,85.1 309.812,84.093 310.709,83.748 C311.606,83.404 312.612,83.851 312.957,84.748" id="Fill-1715" class="icon-color"/>
      <path d="M313.499,97.992 C313.671,99.188 312.841,100.298 311.645,100.47 C310.449,100.642 309.34,99.812 309.167,98.616 C308.995,97.42 309.825,96.311 311.021,96.139 C312.217,95.966 313.326,96.796 313.499,97.992" id="Fill-1716" class="icon-color"/>
      <path d="M313.769,104.614 C313.855,105.96 312.835,107.12 311.489,107.206 C310.143,107.293 308.983,106.272 308.896,104.926 C308.81,103.581 309.831,102.42 311.177,102.334 C312.522,102.248 313.683,103.268 313.769,104.614" id="Fill-1717" class="icon-color"/>
      <path d="M308.626,111.236 C308.626,109.741 309.838,108.529 311.333,108.529 C312.828,108.529 314.04,109.741 314.04,111.236 C314.04,112.731 312.828,113.943 311.333,113.943 C309.838,113.943 308.626,112.731 308.626,111.236" id="Fill-1718" class="icon-color"/>
      <path d="M313.613,117.84 C313.613,119.099 312.592,120.12 311.333,120.12 C310.073,120.12 309.052,119.099 309.052,117.84 C309.052,116.581 310.073,115.56 311.333,115.56 C312.592,115.56 313.613,116.581 313.613,117.84" id="Fill-1719" class="icon-color"/>
      <path d="M313.186,124.444 C313.186,125.468 312.357,126.298 311.333,126.298 C310.309,126.298 309.479,125.468 309.479,124.444 C309.479,123.42 310.309,122.591 311.333,122.591 C312.357,122.591 313.186,123.42 313.186,124.444" id="Fill-1720" class="icon-color"/>
      <path d="M312.76,131.048 C312.76,131.836 312.121,132.475 311.333,132.475 C310.545,132.475 309.906,131.836 309.906,131.048 C309.906,130.26 310.545,129.622 311.333,129.622 C312.121,129.622 312.76,130.26 312.76,131.048" id="Fill-1721" class="icon-color"/>
      <path d="M312.333,137.653 C312.333,138.205 311.885,138.653 311.333,138.653 C310.78,138.653 310.333,138.205 310.333,137.653 C310.333,137.1 310.78,136.652 311.333,136.652 C311.885,136.652 312.333,137.1 312.333,137.653" id="Fill-1722" class="icon-color"/>
      <path d="M311.906,144.257 C311.906,144.573 311.65,144.83 311.333,144.83 C311.016,144.83 310.759,144.573 310.759,144.257 C310.759,143.94 311.016,143.683 311.333,143.683 C311.65,143.683 311.906,143.94 311.906,144.257" id="Fill-1723" class="icon-color"/>
      <path d="M311.479,150.861 C311.479,150.942 311.414,151.007 311.333,151.007 C311.252,151.007 311.186,150.942 311.186,150.861 C311.186,150.78 311.252,150.714 311.333,150.714 C311.414,150.714 311.479,150.78 311.479,150.861" id="Fill-1724" class="icon-color"/>
      <path d="M311.053,157.465 C311.053,157.31 311.178,157.185 311.333,157.185 C311.488,157.185 311.613,157.31 311.613,157.465 C311.613,157.619 311.488,157.745 311.333,157.745 C311.178,157.745 311.053,157.619 311.053,157.465" id="Fill-1725" class="icon-color"/>
      <path d="M310.626,164.069 C310.626,163.678 310.942,163.362 311.333,163.362 C311.723,163.362 312.04,163.678 312.04,164.069 C312.04,164.459 311.723,164.776 311.333,164.776 C310.942,164.776 310.626,164.459 310.626,164.069" id="Fill-1726" class="icon-color"/>
      <path d="M310.199,170.673 C310.199,170.047 310.707,169.539 311.333,169.539 C311.959,169.539 312.466,170.047 312.466,170.673 C312.466,171.299 311.959,171.807 311.333,171.807 C310.707,171.807 310.199,171.299 310.199,170.673" id="Fill-1727" class="icon-color"/>
      <path d="M309.772,177.277 C309.772,176.415 310.471,175.717 311.333,175.717 C312.195,175.717 312.893,176.415 312.893,177.277 C312.893,178.139 312.195,178.838 311.333,178.838 C310.471,178.838 309.772,178.139 309.772,177.277" id="Fill-1728" class="icon-color"/>
      <path d="M313.228,91.37 C313.486,92.417 312.848,93.475 311.801,93.733 C310.754,93.992 309.696,93.353 309.438,92.306 C309.179,91.26 309.818,90.202 310.865,89.943 C311.911,89.685 312.969,90.324 313.228,91.37" id="Fill-1729" class="icon-color"/>
      <path d="M319.658,130.195 C319.658,131.519 318.585,132.592 317.262,132.592 C315.938,132.592 314.865,131.519 314.865,130.195 C314.865,128.872 315.938,127.799 317.262,127.799 C318.585,127.799 319.658,128.872 319.658,130.195" id="Fill-1730" class="icon-color"/>
      <path d="M317.328,126.283 C316.114,126.32 315.101,125.366 315.065,124.152 C315.028,122.939 315.983,121.926 317.196,121.89 C318.409,121.853 319.422,122.807 319.458,124.021 C319.495,125.234 318.541,126.247 317.328,126.283" id="Fill-1731" class="icon-color"/>
      <path d="M317.38,113.666 C316.387,113.775 315.494,113.059 315.385,112.066 C315.276,111.073 315.992,110.18 316.985,110.071 C317.978,109.962 318.871,110.678 318.98,111.671 C319.089,112.664 318.373,113.557 317.38,113.666" id="Fill-1732" class="icon-color"/>
      <path d="M317.421,107.357 C316.539,107.503 315.706,106.905 315.56,106.023 C315.414,105.141 316.012,104.307 316.894,104.162 C317.776,104.016 318.61,104.614 318.755,105.496 C318.901,106.378 318.303,107.212 317.421,107.357" id="Fill-1733" class="icon-color"/>
      <path d="M317.462,101.049 C316.69,101.231 315.917,100.752 315.735,99.98 C315.553,99.208 316.031,98.434 316.803,98.253 C317.575,98.071 318.349,98.549 318.531,99.321 C318.712,100.093 318.234,100.867 317.462,101.049" id="Fill-1734" class="icon-color"/>
      <path d="M317.518,94.74 C316.857,94.958 316.143,94.598 315.925,93.937 C315.706,93.275 316.066,92.562 316.728,92.343 C317.39,92.125 318.103,92.485 318.321,93.146 C318.54,93.808 318.18,94.521 317.518,94.74" id="Fill-1735" class="icon-color"/>
      <path d="M317.607,88.431 C317.056,88.686 316.402,88.445 316.148,87.894 C315.893,87.342 316.133,86.689 316.685,86.434 C317.237,86.179 317.89,86.42 318.145,86.971 C318.399,87.523 318.159,88.176 317.607,88.431" id="Fill-1736" class="icon-color"/>
      <path d="M317.696,82.122 C317.255,82.413 316.661,82.292 316.37,81.851 C316.079,81.409 316.201,80.816 316.642,80.525 C317.083,80.234 317.677,80.355 317.968,80.797 C318.259,81.238 318.137,81.831 317.696,82.122" id="Fill-1737" class="icon-color"/>
      <path d="M317.785,75.814 C317.454,76.141 316.921,76.138 316.593,75.807 C316.266,75.477 316.269,74.943 316.599,74.615 C316.931,74.288 317.464,74.291 317.791,74.622 C318.119,74.953 318.116,75.486 317.785,75.814" id="Fill-1738" class="icon-color"/>
      <path d="M317.92,69.505 C317.7,69.869 317.226,69.985 316.862,69.764 C316.498,69.544 316.382,69.07 316.603,68.706 C316.823,68.342 317.297,68.226 317.661,68.447 C318.025,68.667 318.141,69.141 317.92,69.505" id="Fill-1739" class="icon-color"/>
      <path d="M317.986,63.196 C317.876,63.597 317.462,63.832 317.062,63.721 C316.662,63.611 316.427,63.197 316.537,62.797 C316.647,62.397 317.061,62.162 317.461,62.272 C317.862,62.382 318.096,62.796 317.986,63.196" id="Fill-1740" class="icon-color"/>
      <path d="M318.052,56.888 C318.052,57.324 317.698,57.678 317.262,57.678 C316.825,57.678 316.471,57.324 316.471,56.888 C316.471,56.451 316.825,56.097 317.262,56.097 C317.698,56.097 318.052,56.451 318.052,56.888" id="Fill-1741" class="icon-color"/>
      <path d="M316.697,186.122 C316.697,185.682 317.054,185.325 317.494,185.325 C317.934,185.325 318.291,185.682 318.291,186.122 C318.291,186.562 317.934,186.919 317.494,186.919 C317.054,186.919 316.697,186.562 316.697,186.122" id="Fill-1742" class="icon-color"/>
      <path d="M316.45,179.899 C316.45,179.323 316.918,178.855 317.494,178.855 C318.071,178.855 318.538,179.323 318.538,179.899 C318.538,180.476 318.071,180.943 317.494,180.943 C316.918,180.943 316.45,180.476 316.45,179.899" id="Fill-1743" class="icon-color"/>
      <path d="M316.203,173.676 C316.203,172.963 316.781,172.385 317.494,172.385 C318.207,172.385 318.785,172.963 318.785,173.676 C318.785,174.389 318.207,174.967 317.494,174.967 C316.781,174.967 316.203,174.389 316.203,173.676" id="Fill-1744" class="icon-color"/>
      <path d="M315.956,167.453 C315.956,166.604 316.645,165.915 317.494,165.915 C318.344,165.915 319.032,166.604 319.032,167.453 C319.032,168.303 318.344,168.991 317.494,168.991 C316.645,168.991 315.956,168.303 315.956,167.453" id="Fill-1745" class="icon-color"/>
      <path d="M315.709,161.23 C315.709,160.244 316.508,159.445 317.494,159.445 C318.48,159.445 319.279,160.244 319.279,161.23 C319.279,162.216 318.48,163.015 317.494,163.015 C316.508,163.015 315.709,162.216 315.709,161.23" id="Fill-1746" class="icon-color"/>
      <path d="M315.462,155.007 C315.462,153.885 316.372,152.975 317.494,152.975 C318.616,152.975 319.526,153.885 319.526,155.007 C319.526,156.129 318.616,157.039 317.494,157.039 C316.372,157.039 315.462,156.129 315.462,155.007" id="Fill-1747" class="icon-color"/>
      <path d="M315.215,148.784 C315.215,147.525 316.236,146.505 317.494,146.505 C318.753,146.505 319.773,147.525 319.773,148.784 C319.773,150.043 318.753,151.063 317.494,151.063 C316.236,151.063 315.215,150.043 315.215,148.784" id="Fill-1748" class="icon-color"/>
      <path d="M314.968,142.561 C314.968,141.166 316.099,140.035 317.494,140.035 C318.889,140.035 320.02,141.166 320.02,142.561 C320.02,143.956 318.889,145.087 317.494,145.087 C316.099,145.087 314.968,143.956 314.968,142.561" id="Fill-1749" class="icon-color"/>
      <path d="M314.721,136.338 C314.721,134.807 315.963,133.565 317.494,133.565 C319.026,133.565 320.267,134.807 320.267,136.338 C320.267,137.869 319.026,139.111 317.494,139.111 C315.963,139.111 314.721,137.869 314.721,136.338" id="Fill-1750" class="icon-color"/>
      <path d="M317.339,119.975 C316.236,120.047 315.283,119.212 315.211,118.109 C315.138,117.006 315.973,116.053 317.076,115.98 C318.179,115.908 319.132,116.743 319.205,117.846 C319.277,118.949 318.442,119.902 317.339,119.975" id="Fill-1751" class="icon-color"/>
      <path d="M300.048,208.983 C300.048,209.844 299.35,210.543 298.488,210.543 C297.626,210.543 296.927,209.844 296.927,208.983 C296.927,208.121 297.626,207.422 298.488,207.422 C299.35,207.422 300.048,208.121 300.048,208.983" id="Fill-1752" class="icon-color"/>
      <path d="M298.217,202.531 C297.442,202.382 296.934,201.632 297.083,200.856 C297.233,200.081 297.983,199.573 298.759,199.722 C299.534,199.872 300.042,200.622 299.892,201.398 C299.743,202.173 298.993,202.681 298.217,202.531" id="Fill-1753" class="icon-color"/>
      <path d="M297.947,194.52 C297.257,194.221 296.94,193.419 297.24,192.73 C297.538,192.04 298.34,191.724 299.029,192.023 C299.719,192.322 300.035,193.123 299.736,193.813 C299.437,194.502 298.636,194.819 297.947,194.52" id="Fill-1754" class="icon-color"/>
      <path d="M297.676,186.508 C297.073,186.059 296.947,185.207 297.396,184.603 C297.844,184 298.697,183.875 299.3,184.323 C299.903,184.772 300.029,185.625 299.58,186.228 C299.132,186.831 298.279,186.956 297.676,186.508" id="Fill-1755" class="icon-color"/>
      <path d="M297.405,178.496 C296.888,177.898 296.953,176.994 297.552,176.477 C298.15,175.96 299.054,176.025 299.571,176.623 C300.088,177.222 300.022,178.125 299.424,178.643 C298.826,179.16 297.922,179.094 297.405,178.496" id="Fill-1756" class="icon-color"/>
      <path d="M297.134,170.484 C296.703,169.737 296.96,168.781 297.708,168.351 C298.455,167.92 299.41,168.176 299.841,168.924 C300.272,169.671 300.016,170.627 299.268,171.058 C298.521,171.489 297.565,171.232 297.134,170.484" id="Fill-1757" class="icon-color"/>
      <path d="M296.593,154.461 C296.334,153.414 296.973,152.356 298.02,152.098 C299.066,151.839 300.124,152.478 300.383,153.525 C300.641,154.571 300.003,155.629 298.956,155.888 C297.909,156.146 296.852,155.507 296.593,154.461" id="Fill-1758" class="icon-color"/>
      <path d="M296.322,146.449 C296.15,145.253 296.98,144.144 298.176,143.971 C299.372,143.799 300.481,144.629 300.654,145.825 C300.826,147.021 299.996,148.13 298.8,148.303 C297.604,148.475 296.495,147.645 296.322,146.449" id="Fill-1759" class="icon-color"/>
      <path d="M296.051,138.437 C295.965,137.092 296.986,135.931 298.332,135.845 C299.677,135.759 300.838,136.78 300.924,138.125 C301.01,139.471 299.99,140.631 298.644,140.718 C297.298,140.804 296.138,139.783 296.051,138.437" id="Fill-1760" class="icon-color"/>
      <path d="M301.195,130.426 C301.195,131.921 299.983,133.133 298.488,133.133 C296.993,133.133 295.781,131.921 295.781,130.426 C295.781,128.93 296.993,127.718 298.488,127.718 C299.983,127.718 301.195,128.93 301.195,130.426" id="Fill-1761" class="icon-color"/>
      <path d="M296.208,122.912 C296.208,121.652 297.228,120.631 298.488,120.631 C299.747,120.631 300.768,121.652 300.768,122.912 C300.768,124.171 299.747,125.192 298.488,125.192 C297.228,125.192 296.208,124.171 296.208,122.912" id="Fill-1762" class="icon-color"/>
      <path d="M296.634,115.398 C296.634,114.374 297.464,113.544 298.488,113.544 C299.512,113.544 300.341,114.374 300.341,115.398 C300.341,116.421 299.512,117.251 298.488,117.251 C297.464,117.251 296.634,116.421 296.634,115.398" id="Fill-1763" class="icon-color"/>
      <path d="M297.061,107.883 C297.061,107.095 297.7,106.456 298.488,106.456 C299.276,106.456 299.915,107.095 299.915,107.883 C299.915,108.671 299.276,109.31 298.488,109.31 C297.7,109.31 297.061,108.671 297.061,107.883" id="Fill-1764" class="icon-color"/>
      <path d="M297.488,100.369 C297.488,99.817 297.935,99.369 298.488,99.369 C299.04,99.369 299.488,99.817 299.488,100.369 C299.488,100.922 299.04,101.37 298.488,101.37 C297.935,101.37 297.488,100.922 297.488,100.369" id="Fill-1765" class="icon-color"/>
      <path d="M297.914,92.855 C297.914,92.539 298.171,92.282 298.488,92.282 C298.805,92.282 299.061,92.539 299.061,92.855 C299.061,93.172 298.805,93.428 298.488,93.428 C298.171,93.428 297.914,93.172 297.914,92.855" id="Fill-1766" class="icon-color"/>
      <path d="M298.341,85.341 C298.341,85.26 298.407,85.194 298.488,85.194 C298.569,85.194 298.634,85.26 298.634,85.341 C298.634,85.422 298.569,85.488 298.488,85.488 C298.407,85.488 298.341,85.422 298.341,85.341" id="Fill-1767" class="icon-color"/>
      <path d="M298.768,77.827 C298.768,77.982 298.643,78.107 298.488,78.107 C298.333,78.107 298.208,77.982 298.208,77.827 C298.208,77.672 298.333,77.547 298.488,77.547 C298.643,77.547 298.768,77.672 298.768,77.827" id="Fill-1768" class="icon-color"/>
      <path d="M299.195,70.313 C299.195,70.703 298.878,71.02 298.488,71.02 C298.098,71.02 297.781,70.703 297.781,70.313 C297.781,69.923 298.098,69.606 298.488,69.606 C298.878,69.606 299.195,69.923 299.195,70.313" id="Fill-1769" class="icon-color"/>
      <path d="M299.622,62.799 C299.622,63.425 299.114,63.932 298.488,63.932 C297.862,63.932 297.354,63.425 297.354,62.799 C297.354,62.173 297.862,61.665 298.488,61.665 C299.114,61.665 299.622,62.173 299.622,62.799" id="Fill-1770" class="icon-color"/>
      <path d="M300.048,55.285 C300.048,56.147 299.35,56.845 298.488,56.845 C297.626,56.845 296.927,56.147 296.927,55.285 C296.927,54.423 297.626,53.724 298.488,53.724 C299.35,53.724 300.048,54.423 300.048,55.285" id="Fill-1771" class="icon-color"/>
      <path d="M296.864,162.472 C296.519,161.575 296.967,160.569 297.864,160.224 C298.761,159.879 299.767,160.327 300.112,161.224 C300.457,162.121 300.009,163.128 299.112,163.473 C298.215,163.817 297.208,163.37 296.864,162.472" id="Fill-1772" class="icon-color"/>
      <path d="M280.645,180.47 C280.645,181.31 279.964,181.991 279.124,181.991 C278.284,181.991 277.603,181.31 277.603,180.47 C277.603,179.629 278.284,178.948 279.124,178.948 C279.964,178.948 280.645,179.629 280.645,180.47" id="Fill-1773" class="icon-color"/>
      <path d="M278.831,173.764 C278.084,173.602 277.61,172.866 277.772,172.119 C277.934,171.372 278.67,170.898 279.417,171.06 C280.164,171.222 280.638,171.959 280.476,172.705 C280.314,173.452 279.577,173.926 278.831,173.764" id="Fill-1774" class="icon-color"/>
      <path d="M278.537,165.538 C277.884,165.214 277.617,164.421 277.941,163.768 C278.265,163.114 279.057,162.847 279.71,163.171 C280.364,163.495 280.631,164.287 280.307,164.941 C279.983,165.594 279.191,165.862 278.537,165.538" id="Fill-1775" class="icon-color"/>
      <path d="M278.244,157.311 C277.684,156.825 277.624,155.977 278.11,155.417 C278.596,154.857 279.444,154.797 280.004,155.283 C280.564,155.769 280.624,156.616 280.138,157.176 C279.652,157.737 278.804,157.797 278.244,157.311" id="Fill-1776" class="icon-color"/>
      <path d="M277.951,149.084 C277.484,148.436 277.631,147.533 278.279,147.066 C278.927,146.599 279.83,146.746 280.297,147.394 C280.764,148.042 280.617,148.945 279.969,149.412 C279.321,149.879 278.418,149.732 277.951,149.084" id="Fill-1777" class="icon-color"/>
      <path d="M277.658,140.858 C277.284,140.048 277.638,139.089 278.448,138.715 C279.258,138.342 280.217,138.696 280.59,139.506 C280.963,140.315 280.61,141.274 279.8,141.648 C278.99,142.021 278.031,141.667 277.658,140.858" id="Fill-1778" class="icon-color"/>
      <path d="M277.365,132.631 C277.085,131.659 277.645,130.645 278.617,130.364 C279.589,130.084 280.603,130.645 280.883,131.617 C281.163,132.588 280.603,133.603 279.631,133.883 C278.659,134.163 277.645,133.603 277.365,132.631" id="Fill-1779" class="icon-color"/>
      <path d="M277.071,124.404 C276.885,123.271 277.652,122.2 278.786,122.014 C279.919,121.827 280.99,122.595 281.176,123.728 C281.363,124.862 280.596,125.932 279.462,126.119 C278.328,126.305 277.258,125.538 277.071,124.404" id="Fill-1780" class="icon-color"/>
      <path d="M276.778,116.178 C276.685,114.882 277.659,113.756 278.955,113.663 C280.25,113.569 281.376,114.544 281.47,115.84 C281.563,117.135 280.589,118.261 279.293,118.354 C277.997,118.448 276.871,117.473 276.778,116.178" id="Fill-1781" class="icon-color"/>
      <path d="M281.763,107.951 C281.763,109.408 280.581,110.59 279.124,110.59 C277.666,110.59 276.485,109.408 276.485,107.951 C276.485,106.493 277.666,105.312 279.124,105.312 C280.581,105.312 281.763,106.493 281.763,107.951" id="Fill-1782" class="icon-color"/>
      <path d="M276.947,100.947 C276.947,99.745 277.922,98.771 279.124,98.771 C280.326,98.771 281.301,99.745 281.301,100.947 C281.301,102.15 280.326,103.124 279.124,103.124 C277.922,103.124 276.947,102.15 276.947,100.947" id="Fill-1783" class="icon-color"/>
      <path d="M277.409,93.944 C277.409,92.997 278.177,92.229 279.124,92.229 C280.071,92.229 280.839,92.997 280.839,93.944 C280.839,94.891 280.071,95.658 279.124,95.658 C278.177,95.658 277.409,94.891 277.409,93.944" id="Fill-1784" class="icon-color"/>
      <path d="M277.872,86.94 C277.872,86.249 278.432,85.688 279.124,85.688 C279.815,85.688 280.376,86.249 280.376,86.94 C280.376,87.632 279.815,88.193 279.124,88.193 C278.432,88.193 277.872,87.632 277.872,86.94" id="Fill-1785" class="icon-color"/>
      <path d="M278.334,79.937 C278.334,79.501 278.688,79.147 279.124,79.147 C279.56,79.147 279.914,79.501 279.914,79.937 C279.914,80.373 279.56,80.727 279.124,80.727 C278.688,80.727 278.334,80.373 278.334,79.937" id="Fill-1786" class="icon-color"/>
      <path d="M278.796,72.934 C278.796,72.752 278.943,72.606 279.124,72.606 C279.305,72.606 279.452,72.752 279.452,72.934 C279.452,73.114 279.305,73.261 279.124,73.261 C278.943,73.261 278.796,73.114 278.796,72.934" id="Fill-1787" class="icon-color"/>
      <path d="M279.258,65.93 C279.258,66.004 279.198,66.064 279.124,66.064 C279.05,66.064 278.99,66.004 278.99,65.93 C278.99,65.856 279.05,65.796 279.124,65.796 C279.198,65.796 279.258,65.856 279.258,65.93" id="Fill-1788" class="icon-color"/>
      <path d="M279.721,58.927 C279.721,59.256 279.453,59.523 279.124,59.523 C278.794,59.523 278.527,59.256 278.527,58.927 C278.527,58.597 278.794,58.33 279.124,58.33 C279.453,58.33 279.721,58.597 279.721,58.927" id="Fill-1789" class="icon-color"/>
      <path d="M280.183,51.923 C280.183,52.508 279.709,52.982 279.124,52.982 C278.539,52.982 278.065,52.508 278.065,51.923 C278.065,51.338 278.539,50.864 279.124,50.864 C279.709,50.864 280.183,51.338 280.183,51.923" id="Fill-1790" class="icon-color"/>
      <path d="M280.645,44.92 C280.645,45.76 279.964,46.441 279.124,46.441 C278.284,46.441 277.603,45.76 277.603,44.92 C277.603,44.08 278.284,43.399 279.124,43.399 C279.964,43.399 280.645,44.08 280.645,44.92" id="Fill-1791" class="icon-color"/>
      <path d="M283.134,114.882 C283.134,113.432 284.309,112.257 285.759,112.257 C287.209,112.257 288.384,113.432 288.384,114.882 C288.384,116.332 287.209,117.507 285.759,117.507 C284.309,117.507 283.134,116.332 283.134,114.882" id="Fill-1792" class="icon-color"/>
      <path d="M285.672,119.897 C286.977,119.849 288.073,120.868 288.121,122.173 C288.169,123.478 287.15,124.574 285.845,124.622 C284.54,124.67 283.444,123.651 283.396,122.346 C283.348,121.041 284.367,119.944 285.672,119.897" id="Fill-1793" class="icon-color"/>
      <path d="M285.646,127.537 C286.806,127.441 287.824,128.303 287.92,129.463 C288.015,130.623 287.153,131.641 285.993,131.737 C284.833,131.832 283.815,130.969 283.719,129.81 C283.624,128.65 284.486,127.632 285.646,127.537" id="Fill-1794" class="icon-color"/>
      <path d="M285.59,135.176 C286.605,135.033 287.544,135.739 287.687,136.754 C287.831,137.769 287.124,138.708 286.109,138.851 C285.094,138.995 284.155,138.288 284.012,137.273 C283.868,136.259 284.575,135.32 285.59,135.176" id="Fill-1795" class="icon-color"/>
      <path d="M285.534,142.816 C286.404,142.625 287.264,143.175 287.455,144.045 C287.646,144.915 287.096,145.775 286.226,145.966 C285.356,146.158 284.496,145.607 284.305,144.738 C284.113,143.868 284.664,143.007 285.534,142.816" id="Fill-1796" class="icon-color"/>
      <path d="M285.477,150.456 C286.202,150.217 286.983,150.611 287.223,151.336 C287.462,152.061 287.068,152.842 286.343,153.081 C285.618,153.32 284.837,152.926 284.597,152.202 C284.358,151.476 284.752,150.695 285.477,150.456" id="Fill-1797" class="icon-color"/>
      <path d="M285.366,158.096 C285.946,157.809 286.649,158.046 286.936,158.626 C287.223,159.206 286.985,159.909 286.405,160.196 C285.825,160.483 285.123,160.245 284.836,159.665 C284.549,159.086 284.786,158.383 285.366,158.096" id="Fill-1798" class="icon-color"/>
      <path d="M285.246,165.736 C285.681,165.401 286.305,165.482 286.639,165.917 C286.974,166.352 286.893,166.976 286.458,167.311 C286.023,167.646 285.399,167.564 285.064,167.129 C284.729,166.694 284.811,166.071 285.246,165.736" id="Fill-1799" class="icon-color"/>
      <path d="M285.125,173.375 C285.415,172.993 285.96,172.918 286.343,173.208 C286.725,173.498 286.801,174.043 286.511,174.426 C286.22,174.808 285.675,174.883 285.293,174.593 C284.91,174.303 284.835,173.758 285.125,173.375" id="Fill-1800" class="icon-color"/>
      <path d="M284.972,181.015 C285.117,180.585 285.583,180.354 286.013,180.499 C286.444,180.644 286.675,181.11 286.53,181.54 C286.385,181.971 285.919,182.202 285.488,182.057 C285.058,181.912 284.827,181.446 284.972,181.015" id="Fill-1801" class="icon-color"/>
      <path d="M284.884,188.655 C284.884,188.177 285.272,187.789 285.75,187.789 C286.228,187.789 286.616,188.177 286.616,188.655 C286.616,189.133 286.228,189.521 285.75,189.521 C285.272,189.521 284.884,189.133 284.884,188.655" id="Fill-1802" class="icon-color"/>
      <path d="M289.963,131.831 C289.963,130.508 291.035,129.435 292.359,129.435 C293.683,129.435 294.756,130.508 294.756,131.831 C294.756,133.155 293.683,134.228 292.359,134.228 C291.035,134.228 289.963,133.155 289.963,131.831" id="Fill-1803" class="icon-color"/>
      <path d="M292.293,135.802 C293.506,135.765 294.52,136.719 294.556,137.933 C294.592,139.146 293.638,140.159 292.425,140.195 C291.212,140.232 290.199,139.278 290.162,138.064 C290.126,136.851 291.08,135.838 292.293,135.802" id="Fill-1804" class="icon-color"/>
      <path d="M292.282,142.169 C293.385,142.096 294.338,142.931 294.411,144.034 C294.484,145.137 293.648,146.09 292.546,146.163 C291.442,146.236 290.489,145.4 290.417,144.297 C290.344,143.194 291.179,142.241 292.282,142.169" id="Fill-1805" class="icon-color"/>
      <path d="M292.241,148.536 C293.234,148.426 294.127,149.143 294.236,150.135 C294.345,151.128 293.629,152.021 292.637,152.131 C291.644,152.24 290.751,151.523 290.642,150.531 C290.532,149.538 291.249,148.645 292.241,148.536" id="Fill-1806" class="icon-color"/>
      <path d="M292.201,154.903 C293.083,154.757 293.916,155.355 294.062,156.237 C294.207,157.119 293.61,157.952 292.728,158.098 C291.845,158.244 291.012,157.646 290.866,156.764 C290.721,155.881 291.318,155.048 292.201,154.903" id="Fill-1807" class="icon-color"/>
      <path d="M292.16,161.27 C292.932,161.088 293.705,161.566 293.887,162.338 C294.069,163.11 293.591,163.884 292.819,164.066 C292.047,164.248 291.273,163.769 291.091,162.997 C290.909,162.225 291.388,161.452 292.16,161.27" id="Fill-1808" class="icon-color"/>
      <path d="M292.101,167.637 C292.763,167.418 293.477,167.778 293.695,168.44 C293.913,169.102 293.553,169.815 292.892,170.033 C292.23,170.252 291.517,169.892 291.298,169.23 C291.08,168.569 291.439,167.855 292.101,167.637" id="Fill-1809" class="icon-color"/>
      <path d="M292.013,174.004 C292.564,173.749 293.217,173.99 293.472,174.541 C293.727,175.093 293.486,175.746 292.935,176.001 C292.383,176.256 291.73,176.015 291.475,175.463 C291.22,174.912 291.461,174.258 292.013,174.004" id="Fill-1810" class="icon-color"/>
      <path d="M291.924,180.371 C292.365,180.08 292.958,180.202 293.25,180.643 C293.54,181.084 293.419,181.677 292.978,181.969 C292.536,182.26 291.943,182.138 291.652,181.697 C291.361,181.256 291.483,180.662 291.924,180.371" id="Fill-1811" class="icon-color"/>
      <path d="M291.835,186.738 C292.166,186.41 292.699,186.413 293.027,186.744 C293.354,187.075 293.352,187.609 293.021,187.936 C292.69,188.263 292.156,188.261 291.829,187.93 C291.501,187.599 291.504,187.065 291.835,186.738" id="Fill-1812" class="icon-color"/>
      <path d="M291.7,193.105 C291.921,192.741 292.395,192.625 292.759,192.845 C293.122,193.066 293.238,193.54 293.018,193.904 C292.797,194.267 292.323,194.383 291.96,194.163 C291.596,193.942 291.48,193.469 291.7,193.105" id="Fill-1813" class="icon-color"/>
      <path d="M291.634,199.472 C291.745,199.072 292.159,198.837 292.559,198.947 C292.959,199.057 293.194,199.471 293.084,199.871 C292.973,200.271 292.56,200.506 292.159,200.396 C291.759,200.286 291.524,199.872 291.634,199.472" id="Fill-1814" class="icon-color"/>
      <path d="M291.569,205.839 C291.569,205.402 291.922,205.049 292.359,205.049 C292.796,205.049 293.15,205.402 293.15,205.839 C293.15,206.275 292.796,206.629 292.359,206.629 C291.922,206.629 291.569,206.275 291.569,205.839" id="Fill-1815" class="icon-color"/>
      <path d="M292.924,75.904 C292.924,76.344 292.567,76.701 292.126,76.701 C291.686,76.701 291.329,76.344 291.329,75.904 C291.329,75.464 291.686,75.107 292.126,75.107 C292.567,75.107 292.924,75.464 292.924,75.904" id="Fill-1816" class="icon-color"/>
      <path d="M293.171,82.127 C293.171,82.704 292.703,83.171 292.126,83.171 C291.55,83.171 291.082,82.704 291.082,82.127 C291.082,81.551 291.55,81.083 292.126,81.083 C292.703,81.083 293.171,81.551 293.171,82.127" id="Fill-1817" class="icon-color"/>
      <path d="M293.418,88.35 C293.418,89.063 292.84,89.641 292.126,89.641 C291.414,89.641 290.836,89.063 290.836,88.35 C290.836,87.637 291.414,87.059 292.126,87.059 C292.84,87.059 293.418,87.637 293.418,88.35" id="Fill-1818" class="icon-color"/>
      <path d="M293.664,94.573 C293.664,95.423 292.976,96.111 292.126,96.111 C291.277,96.111 290.589,95.423 290.589,94.573 C290.589,93.724 291.277,93.035 292.126,93.035 C292.976,93.035 293.664,93.724 293.664,94.573" id="Fill-1819" class="icon-color"/>
      <path d="M293.911,100.796 C293.911,101.782 293.112,102.581 292.126,102.581 C291.141,102.581 290.341,101.782 290.341,100.796 C290.341,99.811 291.141,99.011 292.126,99.011 C293.112,99.011 293.911,99.811 293.911,100.796" id="Fill-1820" class="icon-color"/>
      <path d="M294.158,107.02 C294.158,108.142 293.249,109.051 292.126,109.051 C291.004,109.051 290.094,108.142 290.094,107.02 C290.094,105.897 291.004,104.988 292.126,104.988 C293.249,104.988 294.158,105.897 294.158,107.02" id="Fill-1821" class="icon-color"/>
      <path d="M294.405,113.243 C294.405,114.501 293.385,115.521 292.126,115.521 C290.868,115.521 289.848,114.501 289.848,113.243 C289.848,111.984 290.868,110.964 292.126,110.964 C293.385,110.964 294.405,111.984 294.405,113.243" id="Fill-1822" class="icon-color"/>
      <path d="M294.652,119.466 C294.652,120.861 293.522,121.991 292.126,121.991 C290.731,121.991 289.601,120.861 289.601,119.466 C289.601,118.07 290.731,116.94 292.126,116.94 C293.522,116.94 294.652,118.07 294.652,119.466" id="Fill-1823" class="icon-color"/>
      <path d="M294.899,125.689 C294.899,127.22 293.658,128.461 292.126,128.461 C290.595,128.461 289.354,127.22 289.354,125.689 C289.354,124.157 290.595,122.916 292.126,122.916 C293.658,122.916 294.899,124.157 294.899,125.689" id="Fill-1824" class="icon-color"/>
      <path d="M193.092,4.857 C193.092,3.995 193.79,3.297 194.652,3.297 C195.514,3.297 196.213,3.995 196.213,4.857 C196.213,5.719 195.514,6.418 194.652,6.418 C193.79,6.418 193.092,5.719 193.092,4.857" id="Fill-1825" class="icon-color"/>
      <path d="M194.923,11.309 C195.699,11.458 196.206,12.208 196.057,12.984 C195.907,13.759 195.157,14.267 194.381,14.117 C193.606,13.968 193.098,13.218 193.248,12.442 C193.397,11.667 194.147,11.159 194.923,11.309" id="Fill-1826" class="icon-color"/>
      <path d="M195.194,19.32 C195.883,19.619 196.199,20.421 195.9,21.11 C195.602,21.8 194.8,22.116 194.111,21.817 C193.421,21.518 193.105,20.717 193.404,20.027 C193.703,19.338 194.504,19.021 195.194,19.32" id="Fill-1827" class="icon-color"/>
      <path d="M195.464,27.332 C196.068,27.781 196.193,28.633 195.745,29.237 C195.296,29.84 194.443,29.965 193.84,29.517 C193.237,29.068 193.111,28.215 193.56,27.612 C194.008,27.009 194.861,26.883 195.464,27.332" id="Fill-1828" class="icon-color"/>
      <path d="M195.735,35.344 C196.252,35.942 196.186,36.846 195.589,37.363 C194.99,37.88 194.087,37.814 193.569,37.216 C193.052,36.618 193.118,35.714 193.716,35.197 C194.314,34.68 195.218,34.746 195.735,35.344" id="Fill-1829" class="icon-color"/>
      <path d="M196.006,43.356 C196.437,44.103 196.18,45.059 195.432,45.49 C194.685,45.92 193.73,45.664 193.299,44.916 C192.868,44.169 193.124,43.213 193.872,42.782 C194.62,42.351 195.575,42.608 196.006,43.356" id="Fill-1830" class="icon-color"/>
      <path d="M196.277,51.367 C196.621,52.264 196.173,53.271 195.276,53.616 C194.379,53.961 193.373,53.513 193.028,52.616 C192.683,51.719 193.131,50.712 194.028,50.367 C194.925,50.022 195.932,50.47 196.277,51.367" id="Fill-1831" class="icon-color"/>
      <path d="M196.547,59.379 C196.806,60.426 196.167,61.484 195.12,61.742 C194.074,62.001 193.016,61.362 192.757,60.315 C192.499,59.269 193.137,58.211 194.184,57.952 C195.231,57.694 196.289,58.332 196.547,59.379" id="Fill-1832" class="icon-color"/>
      <path d="M197.089,75.403 C197.175,76.748 196.154,77.909 194.808,77.995 C193.463,78.081 192.302,77.06 192.216,75.715 C192.13,74.369 193.15,73.208 194.496,73.122 C195.842,73.036 197.003,74.057 197.089,75.403" id="Fill-1833" class="icon-color"/>
      <path d="M191.945,83.414 C191.945,81.919 193.157,80.707 194.652,80.707 C196.147,80.707 197.359,81.919 197.359,83.414 C197.359,84.909 196.147,86.122 194.652,86.122 C193.157,86.122 191.945,84.909 191.945,83.414" id="Fill-1834" class="icon-color"/>
      <path d="M196.933,90.928 C196.933,92.188 195.912,93.209 194.652,93.209 C193.393,93.209 192.372,92.188 192.372,90.928 C192.372,89.669 193.393,88.648 194.652,88.648 C195.912,88.648 196.933,89.669 196.933,90.928" id="Fill-1835" class="icon-color"/>
      <path d="M196.506,98.442 C196.506,99.466 195.676,100.296 194.652,100.296 C193.628,100.296 192.799,99.466 192.799,98.442 C192.799,97.419 193.628,96.589 194.652,96.589 C195.676,96.589 196.506,97.419 196.506,98.442" id="Fill-1836" class="icon-color"/>
      <path d="M196.079,105.957 C196.079,106.745 195.44,107.384 194.652,107.384 C193.864,107.384 193.225,106.745 193.225,105.957 C193.225,105.168 193.864,104.53 194.652,104.53 C195.44,104.53 196.079,105.168 196.079,105.957" id="Fill-1837" class="icon-color"/>
      <path d="M195.652,113.471 C195.652,114.023 195.204,114.471 194.652,114.471 C194.1,114.471 193.652,114.023 193.652,113.471 C193.652,112.918 194.1,112.47 194.652,112.47 C195.204,112.47 195.652,112.918 195.652,113.471" id="Fill-1838" class="icon-color"/>
      <path d="M195.226,120.985 C195.226,121.301 194.969,121.558 194.652,121.558 C194.335,121.558 194.079,121.301 194.079,120.985 C194.079,120.668 194.335,120.411 194.652,120.411 C194.969,120.411 195.226,120.668 195.226,120.985" id="Fill-1839" class="icon-color"/>
      <path d="M194.799,128.499 C194.799,128.58 194.733,128.645 194.652,128.645 C194.571,128.645 194.506,128.58 194.506,128.499 C194.506,128.418 194.571,128.352 194.652,128.352 C194.733,128.352 194.799,128.418 194.799,128.499" id="Fill-1840" class="icon-color"/>
      <path d="M194.372,136.013 C194.372,135.858 194.497,135.733 194.652,135.733 C194.807,135.733 194.932,135.858 194.932,136.013 C194.932,136.168 194.807,136.293 194.652,136.293 C194.497,136.293 194.372,136.168 194.372,136.013" id="Fill-1841" class="icon-color"/>
      <path d="M193.945,143.527 C193.945,143.137 194.262,142.82 194.652,142.82 C195.043,142.82 195.359,143.137 195.359,143.527 C195.359,143.917 195.043,144.234 194.652,144.234 C194.262,144.234 193.945,143.917 193.945,143.527" id="Fill-1842" class="icon-color"/>
      <path d="M193.518,151.041 C193.518,150.415 194.026,149.907 194.652,149.907 C195.278,149.907 195.786,150.415 195.786,151.041 C195.786,151.667 195.278,152.175 194.652,152.175 C194.026,152.175 193.518,151.667 193.518,151.041" id="Fill-1843" class="icon-color"/>
      <path d="M193.092,158.555 C193.092,157.693 193.79,156.995 194.652,156.995 C195.514,156.995 196.213,157.693 196.213,158.555 C196.213,159.417 195.514,160.116 194.652,160.116 C193.79,160.116 193.092,159.417 193.092,158.555" id="Fill-1844" class="icon-color"/>
      <path d="M196.818,67.391 C196.99,68.587 196.16,69.696 194.964,69.869 C193.768,70.041 192.659,69.211 192.486,68.015 C192.314,66.819 193.144,65.71 194.34,65.537 C195.536,65.365 196.646,66.195 196.818,67.391" id="Fill-1845" class="icon-color"/>
      <path d="M372.244,154.113 C371.981,153.15 372.548,152.157 373.51,151.893 C374.473,151.63 375.466,152.197 375.729,153.159 C375.993,154.122 375.426,155.115 374.464,155.378 C373.501,155.642 372.508,155.075 372.244,154.113" id="Fill-1846" class="icon-color"/>
      <path d="M347.144,103.128 C347.144,101.869 348.165,100.848 349.425,100.848 C350.684,100.848 351.705,101.869 351.705,103.128 C351.705,104.388 350.684,105.409 349.425,105.409 C348.165,105.409 347.144,104.388 347.144,103.128" id="Fill-1847" class="icon-color"/>
      <path d="M370.628,127.855 C370.628,129.312 369.446,130.494 367.988,130.494 C366.531,130.494 365.35,129.312 365.35,127.855 C365.35,126.397 366.531,125.216 367.988,125.216 C369.446,125.216 370.628,126.397 370.628,127.855" id="Fill-1848" class="icon-color"/>
      <path d="M362.078,113.517 C360.918,113.612 359.9,112.75 359.805,111.59 C359.709,110.43 360.572,109.412 361.731,109.317 C362.891,109.221 363.909,110.084 364.005,111.244 C364.1,112.403 363.238,113.421 362.078,113.517" id="Fill-1849" class="icon-color"/>
      <path d="M236.645,132.469 C236.645,131.085 237.767,129.963 239.151,129.963 C240.535,129.963 241.656,131.085 241.656,132.469 C241.656,133.852 240.535,134.974 239.151,134.974 C237.767,134.974 236.645,133.852 236.645,132.469" id="Fill-1850" class="icon-color"/>
      <path d="M247.788,142.411 C247.788,143.868 246.607,145.05 245.149,145.05 C243.692,145.05 242.51,143.868 242.51,142.411 C242.51,140.953 243.692,139.772 245.149,139.772 C246.607,139.772 247.788,140.953 247.788,142.411" id="Fill-1851" class="icon-color"/>
      <path d="M251.193,125.491 C252.498,125.443 253.595,126.462 253.642,127.767 C253.69,129.072 252.671,130.168 251.366,130.216 C250.062,130.264 248.965,129.245 248.917,127.94 C248.869,126.635 249.888,125.539 251.193,125.491" id="Fill-1852" class="icon-color"/>
      <path d="M353.801,121.482 C353.801,120.496 354.6,119.697 355.586,119.697 C356.572,119.697 357.371,120.496 357.371,121.482 C357.371,122.468 356.572,123.267 355.586,123.267 C354.6,123.267 353.801,122.468 353.801,121.482" id="Fill-1853" class="icon-color"/>
      <path d="M234.747,166.323 C234.747,167.389 233.883,168.253 232.817,168.253 C231.751,168.253 230.886,167.389 230.886,166.323 C230.886,165.257 231.751,164.393 232.817,164.393 C233.883,164.393 234.747,165.257 234.747,166.323" id="Fill-1854" class="icon-color"/>
      <path d="M229.001,160.168 C229.087,161.514 228.066,162.674 226.721,162.761 C225.375,162.847 224.214,161.826 224.128,160.48 C224.042,159.135 225.063,157.974 226.408,157.888 C227.754,157.802 228.915,158.823 229.001,160.168" id="Fill-1855" class="icon-color"/>
      <path d="M220.201,147.89 C221.083,147.744 221.916,148.342 222.062,149.224 C222.207,150.106 221.61,150.94 220.728,151.085 C219.845,151.231 219.012,150.633 218.867,149.751 C218.721,148.869 219.318,148.035 220.201,147.89" id="Fill-1856" class="icon-color"/>
      <path d="M334.299,126.196 C334.299,124.937 335.32,123.916 336.58,123.916 C337.839,123.916 338.86,124.937 338.86,126.196 C338.86,127.456 337.839,128.477 336.58,128.477 C335.32,128.477 334.299,127.456 334.299,126.196" id="Fill-1857" class="icon-color"/>
      <path d="M275.052,119.542 C275.238,120.676 274.471,121.746 273.337,121.933 C272.203,122.12 271.133,121.352 270.946,120.218 C270.76,119.085 271.527,118.014 272.661,117.828 C273.795,117.641 274.865,118.409 275.052,119.542" id="Fill-1858" class="icon-color"/>
      <path d="M323.926,146.255 C325.086,146.159 326.104,147.022 326.2,148.182 C326.295,149.342 325.433,150.359 324.273,150.455 C323.113,150.551 322.095,149.688 321.999,148.528 C321.904,147.368 322.767,146.35 323.926,146.255" id="Fill-1859" class="icon-color"/>
      <path d="M190.233,89.556 C190.502,90.646 189.837,91.748 188.747,92.017 C187.657,92.286 186.555,91.621 186.286,90.531 C186.017,89.441 186.682,88.34 187.772,88.07 C188.862,87.801 189.963,88.466 190.233,89.556" id="Fill-1860" class="icon-color"/>
      <path d="M196.818,67.391 C196.99,68.587 196.16,69.696 194.964,69.869 C193.768,70.041 192.659,69.211 192.486,68.015 C192.314,66.819 193.144,65.71 194.34,65.537 C195.536,65.365 196.646,66.195 196.818,67.391" id="Fill-1861" class="icon-color"/>
      <path d="M258.699,157.194 C258.699,158.218 257.869,159.048 256.845,159.048 C255.821,159.048 254.991,158.218 254.991,157.194 C254.991,156.17 255.821,155.341 256.845,155.341 C257.869,155.341 258.699,156.17 258.699,157.194" id="Fill-1862" class="icon-color"/>
      <path d="M215.731,161.686 C215.731,162.633 214.963,163.401 214.016,163.401 C213.069,163.401 212.302,162.633 212.302,161.686 C212.302,160.739 213.069,159.972 214.016,159.972 C214.963,159.972 215.731,160.739 215.731,161.686" id="Fill-1863" class="icon-color"/>
      <path d="M207.468,131.846 C206.163,131.894 205.067,130.875 205.019,129.57 C204.971,128.265 205.99,127.169 207.295,127.121 C208.6,127.073 209.697,128.092 209.744,129.397 C209.792,130.702 208.773,131.798 207.468,131.846" id="Fill-1864" class="icon-color"/>
      <path d="M203.177,110.193 C203.177,111.517 202.105,112.589 200.781,112.589 C199.457,112.589 198.385,111.517 198.385,110.193 C198.385,108.869 199.457,107.797 200.781,107.797 C202.105,107.797 203.177,108.869 203.177,110.193" id="Fill-1865" class="icon-color"/>
      <path d="M36.954,119.944 C36.595,119.01 37.061,117.962 37.995,117.603 C38.93,117.244 39.978,117.71 40.337,118.644 C40.696,119.578 40.229,120.627 39.295,120.986 C38.361,121.345 37.313,120.878 36.954,119.944" id="Fill-1866" class="icon-color"/>
      <path d="M9.686,131.612 C9.765,132.857 8.821,133.931 7.575,134.011 C6.33,134.091 5.256,133.146 5.176,131.9 C5.096,130.655 6.041,129.581 7.286,129.501 C8.532,129.421 9.606,130.366 9.686,131.612" id="Fill-1867" class="icon-color"/>
      <path d="M29.988,104.018 C29.988,102.758 31.009,101.738 32.268,101.738 C33.527,101.738 34.548,102.758 34.548,104.018 C34.548,105.277 33.527,106.298 32.268,106.298 C31.009,106.298 29.988,105.277 29.988,104.018" id="Fill-1868" class="icon-color"/>
      <path d="M15.188,86.405 C15.469,87.377 14.908,88.392 13.936,88.672 C12.965,88.952 11.95,88.391 11.67,87.419 C11.39,86.448 11.95,85.433 12.922,85.153 C13.894,84.873 14.909,85.434 15.188,86.405" id="Fill-1869" class="icon-color"/>
      <path d="M1.936,169.761 C2.736,169.647 3.476,170.204 3.589,171.004 C3.702,171.805 3.146,172.545 2.346,172.658 C1.545,172.771 0.805,172.214 0.692,171.414 C0.579,170.614 1.136,169.874 1.936,169.761" id="Fill-1870" class="icon-color"/>
      <path d="M19.78,119.312 C18.62,119.408 17.602,118.545 17.506,117.385 C17.411,116.225 18.274,115.208 19.433,115.112 C20.593,115.016 21.611,115.879 21.707,117.039 C21.802,118.199 20.94,119.216 19.78,119.312" id="Fill-1871" class="icon-color"/>
      <path d="M136.248,88.365 C136.248,87.199 137.193,86.254 138.358,86.254 C139.524,86.254 140.469,87.199 140.469,88.365 C140.469,89.53 139.524,90.475 138.358,90.475 C137.193,90.475 136.248,89.53 136.248,88.365" id="Fill-1872" class="icon-color"/>
      <path d="M134.706,107.341 C134.799,108.637 133.824,109.762 132.529,109.856 C131.233,109.949 130.107,108.974 130.014,107.679 C129.921,106.383 130.895,105.257 132.191,105.164 C133.486,105.071 134.612,106.045 134.706,107.341" id="Fill-1873" class="icon-color"/>
      <path d="M129.154,132.123 C129.154,133.573 127.979,134.748 126.529,134.748 C125.079,134.748 123.904,133.573 123.904,132.123 C123.904,130.673 125.079,129.497 126.529,129.497 C127.979,129.497 129.154,130.673 129.154,132.123" id="Fill-1874" class="icon-color"/>
      <path d="M28.558,143.524 C28.558,144.919 27.427,146.05 26.032,146.05 C24.637,146.05 23.506,144.919 23.506,143.524 C23.506,142.129 24.637,140.998 26.032,140.998 C27.427,140.998 28.558,142.129 28.558,143.524" id="Fill-1875" class="icon-color"/>
      <path d="M141.955,99.882 C141.865,98.481 142.929,97.272 144.33,97.182 C145.731,97.093 146.94,98.156 147.029,99.557 C147.119,100.958 146.056,102.167 144.655,102.257 C143.254,102.346 142.045,101.283 141.955,99.882" id="Fill-1876" class="icon-color"/>
      <path d="M153.381,133.028 C153.467,134.374 152.446,135.535 151.1,135.621 C149.755,135.707 148.594,134.686 148.508,133.34 C148.422,131.995 149.443,130.834 150.788,130.748 C152.134,130.662 153.295,131.682 153.381,133.028" id="Fill-1877" class="icon-color"/>
      <path d="M46.933,86.717 C47.192,87.764 46.553,88.822 45.506,89.08 C44.46,89.339 43.402,88.7 43.143,87.653 C42.885,86.607 43.523,85.549 44.57,85.29 C45.617,85.032 46.675,85.67 46.933,86.717" id="Fill-1878" class="icon-color"/>
      <path d="M113.803,148.921 C113.644,147.814 114.412,146.787 115.519,146.627 C116.626,146.468 117.653,147.236 117.812,148.343 C117.972,149.45 117.204,150.477 116.097,150.636 C114.99,150.796 113.963,150.028 113.803,148.921" id="Fill-1879" class="icon-color"/>
      <path d="M107.464,133.421 C107.37,132.126 108.345,131 109.641,130.906 C110.936,130.813 112.062,131.788 112.155,133.083 C112.249,134.379 111.274,135.505 109.979,135.598 C108.683,135.691 107.557,134.717 107.464,133.421" id="Fill-1880" class="icon-color"/>
      <path d="M123.339,142.2 C123.339,143.343 122.412,144.27 121.269,144.27 C120.126,144.27 119.2,143.343 119.2,142.2 C119.2,141.057 120.126,140.131 121.269,140.131 C122.412,140.131 123.339,141.057 123.339,142.2" id="Fill-1881" class="icon-color"/>
      <path d="M57.327,131.479 C58.197,131.288 59.057,131.838 59.248,132.708 C59.44,133.578 58.89,134.438 58.02,134.629 C57.15,134.821 56.289,134.271 56.098,133.401 C55.907,132.531 56.457,131.67 57.327,131.479" id="Fill-1882" class="icon-color"/>
      <path d="M48.427,112.191 C48.427,110.659 49.668,109.418 51.2,109.418 C52.731,109.418 53.972,110.659 53.972,112.191 C53.972,113.722 52.731,114.964 51.2,114.964 C49.668,114.964 48.427,113.722 48.427,112.191" id="Fill-1883" class="icon-color"/>
      <path d="M161.254,126.272 C161.254,124.815 162.435,123.633 163.893,123.633 C165.35,123.633 166.532,124.815 166.532,126.272 C166.532,127.73 165.35,128.911 163.893,128.911 C162.435,128.911 161.254,127.73 161.254,126.272" id="Fill-1884" class="icon-color"/>
      <path d="M169.704,144.54 C170.574,144.349 171.435,144.899 171.626,145.769 C171.817,146.639 171.267,147.499 170.397,147.69 C169.527,147.882 168.667,147.331 168.476,146.462 C168.284,145.592 168.834,144.731 169.704,144.54" id="Fill-1885" class="icon-color"/>
      <path d="M87.75,117.568 C87.75,116.502 88.614,115.638 89.68,115.638 C90.746,115.638 91.61,116.502 91.61,117.568 C91.61,118.634 90.746,119.499 89.68,119.499 C88.614,119.499 87.75,118.634 87.75,117.568" id="Fill-1886" class="icon-color"/>
      <path d="M93.575,127.01 C93.575,125.515 94.787,124.303 96.282,124.303 C97.777,124.303 98.989,125.515 98.989,127.01 C98.989,128.505 97.777,129.717 96.282,129.717 C94.787,129.717 93.575,128.505 93.575,127.01" id="Fill-1887" class="icon-color"/>
      <path d="M100.246,108.299 C100.246,106.816 101.448,105.614 102.93,105.614 C104.413,105.614 105.615,106.816 105.615,108.299 C105.615,109.782 104.413,110.984 102.93,110.984 C101.448,110.984 100.246,109.782 100.246,108.299" id="Fill-1888" class="icon-color"/>
      <path d="M61.871,126.871 C61.684,125.738 62.452,124.667 63.585,124.481 C64.719,124.294 65.789,125.062 65.976,126.195 C66.163,127.329 65.395,128.399 64.261,128.586 C63.128,128.772 62.057,128.005 61.871,126.871" id="Fill-1889" class="icon-color"/>
      <path d="M74.762,131.831 C74.762,130.507 75.835,129.435 77.159,129.435 C78.482,129.435 79.555,130.507 79.555,131.831 C79.555,133.155 78.482,134.227 77.159,134.227 C75.835,134.227 74.762,133.155 74.762,131.831" id="Fill-1890" class="icon-color"/>
      <path d="M406.45,108.593 C406.45,107.772 407.115,107.107 407.935,107.107 C408.756,107.107 409.421,107.772 409.421,108.593 C409.421,109.413 408.756,110.078 407.935,110.078 C407.115,110.078 406.45,109.413 406.45,108.593" id="Fill-1891" class="icon-color"/>
      <path d="M417.776,120.639 C417.776,119.107 419.018,117.866 420.549,117.866 C422.08,117.866 423.322,119.107 423.322,120.639 C423.322,122.17 422.08,123.411 420.549,123.411 C419.018,123.411 417.776,122.17 417.776,120.639" id="Fill-1892" class="icon-color"/>
      <path d="M400.543,113.153 C400.543,112.601 400.99,112.153 401.543,112.153 C402.095,112.153 402.543,112.601 402.543,113.153 C402.543,113.705 402.095,114.153 401.543,114.153 C400.99,114.153 400.543,113.705 400.543,113.153" id="Fill-1893" class="icon-color"/>
      <path d="M378.507,97.434 C378.507,96.232 379.481,95.257 380.684,95.257 C381.886,95.257 382.86,96.232 382.86,97.434 C382.86,98.636 381.886,99.611 380.684,99.611 C379.481,99.611 378.507,98.636 378.507,97.434" id="Fill-1894" class="icon-color"/>
      <path d="M387.231,119.896 C388.536,119.849 389.633,120.868 389.681,122.173 C389.728,123.477 388.709,124.574 387.405,124.622 C386.1,124.67 385.003,123.651 384.955,122.346 C384.908,121.041 385.927,119.944 387.231,119.896" id="Fill-1895" class="icon-color"/>
      <path d="M393.853,132.811 C395.066,132.775 396.079,133.729 396.115,134.942 C396.152,136.155 395.198,137.168 393.984,137.205 C392.771,137.241 391.758,136.287 391.722,135.074 C391.685,133.861 392.64,132.848 393.853,132.811" id="Fill-1896" class="icon-color"/>
      <path d="M558.959,132.753 C559.408,133.531 559.14,134.526 558.362,134.975 C557.584,135.423 556.589,135.156 556.14,134.378 C555.691,133.599 555.959,132.605 556.737,132.156 C557.515,131.707 558.51,131.974 558.959,132.753" id="Fill-1897" class="icon-color"/>
      <path d="M591.269,109.966 C591.269,111.349 590.148,112.471 588.764,112.471 C587.38,112.471 586.259,111.349 586.259,109.966 C586.259,108.582 587.38,107.46 588.764,107.46 C590.148,107.46 591.269,108.582 591.269,109.966" id="Fill-1898" class="icon-color"/>
      <path d="M566.282,140.772 C566.282,142.032 565.261,143.053 564.002,143.053 C562.742,143.053 561.721,142.032 561.721,140.772 C561.721,139.513 562.742,138.492 564.002,138.492 C565.261,138.492 566.282,139.513 566.282,140.772" id="Fill-1899" class="icon-color"/>
      <path d="M585.405,127.168 C585.405,128.626 584.223,129.807 582.766,129.807 C581.308,129.807 580.127,128.626 580.127,127.168 C580.127,125.711 581.308,124.529 582.766,124.529 C584.223,124.529 585.405,125.711 585.405,127.168" id="Fill-1900" class="icon-color"/>
      <path d="M596.195,90.705 C596.195,91.848 595.268,92.774 594.125,92.774 C592.982,92.774 592.056,91.848 592.056,90.705 C592.056,89.562 592.982,88.635 594.125,88.635 C595.268,88.635 596.195,89.562 596.195,90.705" id="Fill-1901" class="icon-color"/>
      <path d="M579.16,124.474 C579.16,125.924 577.985,127.1 576.535,127.1 C575.085,127.1 573.91,125.924 573.91,124.474 C573.91,123.025 575.085,121.849 576.535,121.849 C577.985,121.849 579.16,123.025 579.16,124.474" id="Fill-1902" class="icon-color"/>
      <path d="M459.34,120.545 C459.659,121.375 459.245,122.307 458.414,122.626 C457.584,122.945 456.653,122.531 456.333,121.7 C456.014,120.87 456.429,119.939 457.259,119.62 C458.089,119.301 459.021,119.715 459.34,120.545" id="Fill-1903" class="icon-color"/>
      <path d="M461.782,138.46 C461.596,137.326 462.363,136.256 463.497,136.069 C464.631,135.883 465.701,136.65 465.888,137.784 C466.074,138.918 465.307,139.988 464.173,140.175 C463.039,140.361 461.969,139.594 461.782,138.46" id="Fill-1904" class="icon-color"/>
      <path d="M469.484,123.29 C470.498,123.146 471.437,123.853 471.581,124.868 C471.724,125.882 471.018,126.822 470.003,126.965 C468.988,127.108 468.049,126.402 467.906,125.387 C467.762,124.372 468.469,123.433 469.484,123.29" id="Fill-1905" class="icon-color"/>
      <path d="M567.39,112.386 C567.39,110.855 568.632,109.613 570.163,109.613 C571.694,109.613 572.936,110.855 572.936,112.386 C572.936,113.918 571.694,115.159 570.163,115.159 C568.632,115.159 567.39,113.918 567.39,112.386" id="Fill-1906" class="icon-color"/>
      <path d="M452.548,106.376 C453.176,106.843 453.307,107.731 452.84,108.36 C452.373,108.988 451.485,109.118 450.857,108.651 C450.229,108.184 450.098,107.296 450.565,106.668 C451.032,106.04 451.92,105.909 452.548,106.376" id="Fill-1907" class="icon-color"/>
      <path d="M443.626,126.245 C443.281,125.348 443.729,124.341 444.626,123.996 C445.523,123.652 446.53,124.1 446.875,124.997 C447.219,125.894 446.772,126.9 445.875,127.245 C444.977,127.59 443.971,127.142 443.626,126.245" id="Fill-1908" class="icon-color"/>
      <path d="M438.565,144.216 C439.227,143.998 439.94,144.358 440.158,145.019 C440.377,145.681 440.017,146.395 439.355,146.613 C438.694,146.831 437.98,146.472 437.762,145.81 C437.543,145.148 437.903,144.435 438.565,144.216" id="Fill-1909" class="icon-color"/>
      <path d="M553.052,113.018 C553.31,114.064 552.671,115.122 551.625,115.381 C550.578,115.64 549.52,115.001 549.262,113.954 C549.003,112.908 549.642,111.85 550.689,111.591 C551.735,111.332 552.793,111.971 553.052,113.018" id="Fill-1910" class="icon-color"/>
      <path d="M482.497,114.796 C482.497,115.962 481.553,116.907 480.387,116.907 C479.221,116.907 478.277,115.962 478.277,114.796 C478.277,113.631 479.221,112.686 480.387,112.686 C481.553,112.686 482.497,113.631 482.497,114.796" id="Fill-1911" class="icon-color"/>
      <path d="M488.438,99.593 C488.625,100.726 487.857,101.797 486.723,101.983 C485.59,102.17 484.52,101.403 484.333,100.269 C484.146,99.135 484.914,98.065 486.047,97.878 C487.181,97.692 488.251,98.459 488.438,99.593" id="Fill-1912" class="icon-color"/>
      <path d="M474.782,105.339 C475.582,105.226 476.322,105.783 476.436,106.583 C476.548,107.383 475.992,108.123 475.192,108.236 C474.392,108.349 473.651,107.793 473.538,106.993 C473.425,106.192 473.982,105.452 474.782,105.339" id="Fill-1913" class="icon-color"/>
      <path d="M538.868,98.957 C537.998,99.148 537.138,98.598 536.946,97.729 C536.755,96.858 537.305,95.998 538.175,95.807 C539.045,95.616 539.905,96.166 540.097,97.036 C540.288,97.906 539.738,98.766 538.868,98.957" id="Fill-1914" class="icon-color"/>
      <path d="M545.162,135.851 C546.375,135.815 547.388,136.769 547.425,137.982 C547.461,139.195 546.507,140.208 545.294,140.245 C544.08,140.281 543.067,139.327 543.031,138.114 C542.995,136.901 543.949,135.888 545.162,135.851" id="Fill-1915" class="icon-color"/>
      <path d="M430.249,125.922 C430.063,124.789 430.83,123.718 431.964,123.531 C433.098,123.345 434.168,124.112 434.355,125.246 C434.541,126.38 433.774,127.45 432.64,127.637 C431.506,127.823 430.436,127.056 430.249,125.922" id="Fill-1916" class="icon-color"/>
      <path d="M426.64,98.605 C425.77,98.797 424.91,98.246 424.719,97.377 C424.527,96.507 425.077,95.647 425.947,95.455 C426.817,95.264 427.678,95.814 427.869,96.684 C428.06,97.554 427.51,98.414 426.64,98.605" id="Fill-1917" class="icon-color"/>
      <path d="M508.889,112.864 C508.889,114.175 507.826,115.238 506.515,115.238 C505.203,115.238 504.14,114.175 504.14,112.864 C504.14,111.552 505.203,110.489 506.515,110.489 C507.826,110.489 508.889,111.552 508.889,112.864" id="Fill-1918" class="icon-color"/>
      <path d="M501.957,87.486 C502.216,88.532 501.577,89.59 500.531,89.849 C499.484,90.107 498.426,89.468 498.167,88.422 C497.909,87.375 498.548,86.317 499.594,86.059 C500.641,85.8 501.699,86.439 501.957,87.486" id="Fill-1919" class="icon-color"/>
      <path d="M493.32,122.095 C494.533,122.059 495.546,123.013 495.583,124.226 C495.619,125.44 494.665,126.453 493.452,126.489 C492.239,126.525 491.226,125.571 491.189,124.358 C491.153,123.145 492.107,122.132 493.32,122.095" id="Fill-1920" class="icon-color"/>
      <path d="M514.334,103.415 C514.334,104.203 513.695,104.842 512.907,104.842 C512.119,104.842 511.481,104.203 511.481,103.415 C511.481,102.627 512.119,101.988 512.907,101.988 C513.695,101.988 514.334,102.627 514.334,103.415" id="Fill-1921" class="icon-color"/>
      <path d="M532.858,71.403 C533.511,71.727 533.779,72.519 533.455,73.173 C533.131,73.826 532.338,74.093 531.685,73.769 C531.031,73.445 530.764,72.653 531.088,72 C531.412,71.346 532.204,71.079 532.858,71.403" id="Fill-1922" class="icon-color"/>
      <path d="M525.749,111.871 C524.589,111.967 523.571,111.104 523.476,109.944 C523.38,108.784 524.243,107.767 525.403,107.671 C526.562,107.575 527.58,108.438 527.676,109.598 C527.772,110.758 526.909,111.776 525.749,111.871" id="Fill-1923" class="icon-color"/>
      <path d="M519.195,84.505 C518.312,84.65 517.479,84.053 517.334,83.171 C517.188,82.288 517.785,81.455 518.668,81.31 C519.55,81.164 520.383,81.761 520.529,82.644 C520.674,83.526 520.077,84.359 519.195,84.505" id="Fill-1924" class="icon-color"/>
      <path d="M302.95,110.84 C302.95,109.774 303.815,108.91 304.881,108.91 C305.947,108.91 306.811,109.774 306.811,110.84 C306.811,111.906 305.947,112.77 304.881,112.77 C303.815,112.77 302.95,111.906 302.95,110.84" id="Fill-1925" class="icon-color"/>
      <path d="M313.228,91.37 C313.486,92.417 312.847,93.475 311.801,93.733 C310.754,93.992 309.696,93.353 309.438,92.306 C309.179,91.26 309.818,90.202 310.865,89.943 C311.911,89.685 312.969,90.324 313.228,91.37" id="Fill-1926" class="icon-color"/>
      <path d="M317.339,119.975 C316.236,120.047 315.283,119.212 315.21,118.109 C315.138,117.006 315.973,116.053 317.076,115.98 C318.179,115.908 319.132,116.743 319.205,117.846 C319.277,118.949 318.442,119.902 317.339,119.975" id="Fill-1927" class="icon-color"/>
      <path d="M296.864,162.472 C296.519,161.575 296.967,160.569 297.864,160.224 C298.761,159.879 299.767,160.327 300.112,161.224 C300.457,162.121 300.009,163.128 299.112,163.473 C298.215,163.817 297.208,163.37 296.864,162.472" id="Fill-1928" class="icon-color"/>
      <path d="M215.731,161.686 C215.731,162.633 214.963,163.401 214.016,163.401 C213.069,163.401 212.302,162.633 212.302,161.686 C212.302,160.739 213.069,159.972 214.016,159.972 C214.963,159.972 215.731,160.739 215.731,161.686" id="Fill-1929" class="icon-color"/>
      <path d="M277.658,140.858 C277.284,140.048 277.638,139.089 278.448,138.715 C279.258,138.342 280.217,138.696 280.59,139.505 C280.963,140.315 280.61,141.274 279.8,141.648 C278.99,142.021 278.031,141.667 277.658,140.858" id="Fill-1930" class="icon-color"/>
    </g>
  </g>
</svg></defs></svg>`;
