import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {TreeConfig} from '@spout/any-shared/models';
import {AggregateFirebaseSnapshotChangesEntities, InstrumentMusician} from '@spout/web-global/models';

export const loadInstrumentMusicians = createAction(
  '[InstrumentMusician/API] Load InstrumentMusicians',
  props<{instrumentMusicians: InstrumentMusician[]}>()
);

export const addInstrumentMusician = createAction(
  '[InstrumentMusician/API] Add InstrumentMusician',
  props<{instrumentMusician: InstrumentMusician}>()
);

export const upsertInstrumentMusician = createAction(
  '[InstrumentMusician/API] Upsert InstrumentMusician',
  props<{instrumentMusician: InstrumentMusician}>()
);

export const addInstrumentMusicians = createAction(
  '[InstrumentMusician/API] Add InstrumentMusicians',
  props<{instrumentMusicians: InstrumentMusician[]}>()
);

export const upsertInstrumentMusicians = createAction(
  '[InstrumentMusician/API] Upsert InstrumentMusicians',
  props<{instrumentMusicians: InstrumentMusician[]}>()
);

export const updateInstrumentMusician = createAction(
  '[InstrumentMusician/API] Update InstrumentMusician',
  props<{instrumentMusician: Update<InstrumentMusician>}>()
);

export const updateInstrumentMusicians = createAction(
  '[InstrumentMusician/API] Update InstrumentMusicians',
  props<{instrumentMusicians: Update<InstrumentMusician>[]}>()
);

export const deleteInstrumentMusician = createAction(
  '[InstrumentMusician/API] Delete InstrumentMusician',
  props<{id: string}>()
);

export const deleteInstrumentMusicians = createAction(
  '[InstrumentMusician/API] Delete InstrumentMusicians',
  props<{ids: string[]}>()
);

export const clearInstrumentMusicians = createAction('[InstrumentMusician/API] Clear InstrumentMusicians');

export const instrumentMusicianAggregateEffect = createAction(
  '[InstrumentMusician/API] Update Aggregate Effect',
  props<{instruments: TreeConfig[]}>()
);

export const instrumentMusicianAggregate = createAction(
  '[InstrumentMusician/API] Update Aggregate',
  props<{aggregate: AggregateFirebaseSnapshotChangesEntities<TreeConfig>}>()
);
