// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  AudioFileMetaDataEntity,
  MixEntity,
  Musician,
  ProjectEntity,
  SongEntity,
  TrackEntity,
  TrackMix
} from '@spout/any-shared/models';
import {AuthAccountStates, ChatEntity} from '@spout/web-global/models';

export function firestorePromoCodes() {
  return `promoCodes`;
}

export function firestorePromoCodeDict(code: string) {
  return `promoCodes/${code}`;
}

export function firestorePermissions() {
  return `permissions`;
}

export function firestoreProjectsPath() {
  return `projects`;
}

export function firestoreProjectByIdPath(project: Partial<ProjectEntity>): string {
  return `projects/${project.id}`;
}

export function firestoreSongsPath(project: Partial<ProjectEntity>): string {
  return `projects/${project.id}/songs`;
}

export function firestoreSongByIdPath(song: Partial<SongEntity>): string {
  return `projects/${song.projectId}/songs/${song.id}`;
}

export function firestoreMixesPath(project: Partial<ProjectEntity>): string {
  return `projects/${project.id}/mixes`;
}

export function firestoreMixByIdPath(mix: Partial<MixEntity>): string {
  return `projects/${mix.projectId}/mixes/${mix.id}`;
}

export function firestoreTrackMixesPath(project: Partial<ProjectEntity>): string {
  return `projects/${project.id}/trackMixes`;
}

export function firestoreTrackMixByIdPath(trackMix: Partial<TrackMix>): string {
  return `projects/${trackMix.projectId}/trackMixes/${trackMix.id}`;
}

export function firestoreTracksPath(project: Partial<ProjectEntity>): string {
  return `projects/${project.id}/tracks`;
}

export function firestoreTracksPathByTrackEntity(track: Partial<TrackEntity>): string {
  return `projects/${track.projectId}/tracks`;
}

export function firestoreTrackByIdPath(track: Partial<TrackEntity>): string {
  return `projects/${track.projectId}/tracks/${track.id}`;
}

export function firestoreFilesPath(project: Partial<ProjectEntity>) {
  return `projects/${project.id}/files`;
}

export function firestoreFilesByProjectId(file: Partial<AudioFileMetaDataEntity>) {
  return `projects/${file.projectId}/files`;
}
export function firestoreFileByIdPath(file: Partial<AudioFileMetaDataEntity>) {
  return `projects/${file.projectId}/files/${file.id}`;
}
export function firestoreFileByProjectIdAndIdPath(projectId: string, fileId: string) {
  return `projects/${projectId}/files/${fileId}`;
}

export function firestoreChatsPath(project: Partial<ProjectEntity>): string {
  return `projects/${project.id}/chats`;
}

export function firestoreMessageByIdPath(message: ChatEntity): string {
  return `projects/${message.projectId}/chats/${message.id}`;
}

export function getMusicianAccountPath(user: AuthAccountStates): string {
  return `users/${user.auth.uid}`;
}

export function getMusicianAccountPathByUID(uid: string): string {
  return `users/${uid}`;
}

export function getMusicianSubscriptionPath(uid: string): string {
  return `users/${uid}/subscriptions`;
}

export function getMusicianPublicPathByUser(uid: string): string {
  return `userPublicProfile/${uid}`;
}

export function getMusicianAccountDevicesPath(user: AuthAccountStates, deviceSerialNumber: string): string {
  return `users/${user.auth.uid}/devices/${deviceSerialNumber}`;
}

// export function getMusicianProjectsPath(user: AuthAccountStates): string {
//   return `users/${getUserFirestoreKey(user.auth)}/projects`;
// }

export function firestoreGetMusicianNotificationsPath(user: AuthAccountStates): string {
  return `users/${user.auth.uid}/notifications`;
}

export function firestoreGetMusicianGenresPath(user: AuthAccountStates): string {
  return `users/${user.auth.uid}/genres`;
}

export function firestoreGetMusicianGenrePath(user: AuthAccountStates, genreId: string): string {
  return `users/${user.auth.uid}/genres/${genreId}`;
}

export function firestoreGetMusicianInstrumentsPath(user: AuthAccountStates): string {
  return `users/${user.auth.uid}/instruments`;
}

export function firestoreGetMusicianInstrumentPath(user: AuthAccountStates, instrumentId: string): string {
  return `users/${user.auth.uid}/instruments/${instrumentId}`;
}

export function firestoreUserSubscriptionsPath(uid: string): string {
  return `users/${uid}/subscriptions`;
}

export function firestoreCheckoutSessions(uid: string): string {
  return `users/${uid}/checkout_sessions`;
}

export function firestoreGenresPath() {
  return 'genres';
}

export function firestoreInstrumentsPath() {
  return 'instruments';
}

export function firestoreLatencyPath() {
  return 'latency';
}

export function cloudFunctionOtAuthCode(otlid: string) {
  return `otAuthCodes/${otlid}`;
}

export function studioVersion() {
  return `app/studio-version`;
}

export function firestoreTrial() {
  return `app/trial`;
}

// TODO move to better file
// export function isOwner(config: UserPermissions, uid: string): boolean {
//   const _emailAsKey: string = uid;
//   if (config.members[_emailAsKey]) {
//     return config.members[_emailAsKey].role.owner;
//   } else {
//     return false;
//   }
// }

/**
 * TODO Move to better location - https://spoutsoftware.atlassian.net/browse/SA-106
 * @param config
 * @param uid
 */
export function isCreatedBy(config: {createdBy: Musician}, uid: string): boolean {
  if (config && config.createdBy && config.createdBy.uid) {
    return uid === config.createdBy.uid;
  }
  return false;
}

/**
 * TODO Move to better location - https://spoutsoftware.atlassian.net/browse/SA-106
 * @param track
 * @param uid
 */
export function trackIsCreatedBy(track: TrackEntity | null | undefined, uid: string | null): boolean {
  if (track && track.createdBy && track.createdBy.uid) {
    return uid === track.createdBy.uid;
  }

  return false;
}

// Monitor Database
export function getMusicianMonitorActions(user: AuthAccountStates): string {
  return `users/${user.auth.uid}/storeActions`;
}

export function getMusicianMonitorStorePath(user: AuthAccountStates, storeKey: string): string {
  return `users/${user.auth.uid}/storeLatestState/${storeKey}`;
}

export function getMusicianMonitorActionPath(user: AuthAccountStates, id: string): string {
  return `users/${user.auth.uid}/storeActions/${id}`;
}

// Subscriptions
export function getCustomersPath() {
  return 'customers';
}

export function getCustomer(uid: string) {
  return `customers/${uid}`;
}

export function getProductsPath() {
  return 'products';
}

export function getPricesPath(productId: string) {
  return `products/${productId}/prices`;
}
