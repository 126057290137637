import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HelpNavContainerComponent} from './help-nav-container.component';

@NgModule({
  declarations: [HelpNavContainerComponent],
  exports: [HelpNavContainerComponent],
  imports: [CommonModule]
})
export class HelpNavHeaderModule {}
