import {ChangeDetectionStrategy, Component, Inject, InjectionToken} from '@angular/core';
import {Store} from '@ngrx/store';
import {openHelpViewRightDrawer} from '@spout/web-global/actions';
import {HelpModel} from '@spout/web-global/models';
import {ComponentDictItem} from './content.model';

export const COMPONENT_REF_DICT = new InjectionToken('COMPONENT_REF_DICT');

@Component({
  selector: 'spt-help-toc',
  templateUrl: './help-toc.component.html',
  styleUrls: ['./help-toc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpTocComponent {
  static title = 'Help';

  helpTile = HelpTocComponent.title;
  helpModel = HelpModel;

  refs: ComponentDictItem[] = Array.from(this.compRefDict.values());

  constructor(
    private store: Store,
    @Inject(COMPONENT_REF_DICT) private compRefDict: Map<HelpModel, ComponentDictItem>
  ) {}

  loadComponent(helpView: HelpModel) {
    this.store.dispatch(
      openHelpViewRightDrawer({
        helpView
      })
    );
  }
}
