import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {select, Store} from '@ngrx/store';
import {StripePaymentFirestoreService, StripePaymentSelectionService} from '@spout/web-global/data-access';
import {AuthAccountProductStates, SubscriptionItemDict} from '@spout/web-global/models';
import {selectPromoStorage_passThrough, selectSubscriptionSpecs} from '@spout/web-global/selectors';
import {combineLatest, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {getCurrentSubscription, getCurrentSubscriptionDataSource} from './fns';
import {CurrentSubscriptionItem, MySubscriptionSpecs, MySubscriptionState} from './my-subscription.models';

@Injectable({
  providedIn: 'root'
})
export class MySubscriptionService extends ComponentStore<MySubscriptionState> {
  private _onDestroy$: Subject<boolean> = new Subject();

  constructor(
    public payment: StripePaymentSelectionService,
    private paymentWizardFirestoreService: StripePaymentFirestoreService,
    private store: Store<AuthAccountProductStates>
  ) {
    super({
      hasSubscription: false,
      currentSubscriptions: {
        id: null,
        plan: null,
        storage: null,
        baseStorageGB: 0,
        additionalStorageQuantity: 0,
        storageIncluded: false,
        total: 0,
        interval: 'month'
      }
    });

    this.store
      .pipe(select(selectSubscriptionSpecs), takeUntil(this._onDestroy$))
      .subscribe((s: SubscriptionItemDict) => {
        this.addHasSubscription(s.subscriptionId);
        this.addCurrentSpecs(getCurrentSubscription(s));
      });
  }

  readonly addCurrentSpecs = this.updater((state: MySubscriptionState, c: MySubscriptionSpecs) => {
    return {
      ...state,
      currentSubscriptions: {
        ...state.currentSubscriptions,
        ...c
      }
    };
  });

  readonly addHasSubscription = this.updater(
    (state: MySubscriptionState, subscriptionId: string | null | undefined) => {
      return {
        ...state,
        hasSubscription: subscriptionId !== null && subscriptionId !== undefined
      };
    }
  );

  readonly totalStoragePerSubscription$: Observable<number> = this.select((state: MySubscriptionState) => {
    if (state.currentSubscriptions) {
      return state.currentSubscriptions.baseStorageGB + state.currentSubscriptions.additionalStorageQuantity;
    }

    return 0;
  });

  readonly totalStorageForAccount$ = combineLatest([
    this.store.pipe(select(selectPromoStorage_passThrough)),
    this.totalStoragePerSubscription$
  ]).pipe(
    map(([promoStorage, subscriptionStorage]: [number, number]) => {
      return Math.max(promoStorage, subscriptionStorage);
    })
  );

  readonly currentSubscriptions$: Observable<CurrentSubscriptionItem[]> = this.select((state: MySubscriptionState) => {
    return getCurrentSubscriptionDataSource(state);
  });

  readonly hasSubscription$: Observable<boolean> = this.select((state: MySubscriptionState) => {
    return state.hasSubscription;
  });

  readonly getCurrentPlanTitle$: Observable<string> = this.select((state: MySubscriptionState) => {
    const subs: CurrentSubscriptionItem[] = getCurrentSubscriptionDataSource(state);
    if (subs && subs.length) {
      return subs.reduce((title: string, p: CurrentSubscriptionItem) => {
        if (p.type === 'plan' && !title.length) {
          return p.productDescription.title;
        }

        return title;
      }, '');
    }

    return '';
  });

  onDestroy() {
    this._onDestroy$.next(true);
  }
}
