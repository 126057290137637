import {Component, ChangeDetectionStrategy} from '@angular/core';
import {Store} from '@ngrx/store';
import {openHelpViewRightDrawer} from '@spout/web-global/actions';
import {HelpModel} from '@spout/web-global/models';

@Component({
  selector: 'spt-help-content-header',
  templateUrl: './help-content-header.component.html',
  styleUrls: ['./help-content-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpContentHeaderComponent {
  constructor(private store: Store) {}

  back() {
    this.store.dispatch(
      openHelpViewRightDrawer({
        helpView: HelpModel.TOC
      })
    );
  }
}
