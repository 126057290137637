import {Action, createReducer, on} from '@ngrx/store';
import {mergePropsIfSourceHasValue} from '@spout/any-shared/fns';
import {DeviceStorageState, intialDeviceStorageState} from '@spout/any-shared/models';
import {
  authLoggedOut,
  recordInputCompressorAttack,
  recordInputCompressorKnee,
  recordInputCompressorRatio,
  recordInputCompressorRelease,
  recordInputCompressorThreshold,
  microphoneGain,
  saveLatency,
  upsertDeviceStorage,
  exportMergeCompressorAttack,
  exportMergeCompressorKnee,
  exportMergeCompressorRelease,
  exportMergeCompressorThreshold,
  exportMergeCompressorRatio
} from '@spout/web-global/actions';
import {clone} from '@uiux/fn';

const reducer = createReducer<DeviceStorageState, Action>(
  intialDeviceStorageState,
  on(upsertDeviceStorage, (state, action) => {
    return mergePropsIfSourceHasValue({...state}, action.storage);
  }),
  on(saveLatency, (state, action) => {
    return {
      ...state,
      latency: action.latency
    };
  }),
  on(authLoggedOut, (state, action) => {
    return clone(intialDeviceStorageState);
  }),
  on(microphoneGain, (state: DeviceStorageState, action) => {
    return {
      ...state,
      microphoneGain: action.microphoneGain
    };
  }),
  on(recordInputCompressorThreshold, (state: DeviceStorageState, action) => {
    return {
      ...state,
      recordInputCompressorThreshold: action.compressorThreshold
    };
  }),
  on(recordInputCompressorKnee, (state: DeviceStorageState, action) => {
    return {
      ...state,
      recordInputCompressorKnee: action.compressorKnee
    };
  }),
  on(recordInputCompressorRatio, (state: DeviceStorageState, action) => {
    return {
      ...state,
      recordInputCompressorRatio: action.compressorRatio
    };
  }),
  on(recordInputCompressorAttack, (state: DeviceStorageState, action) => {
    return {
      ...state,
      recordInputCompressorAttack: action.compressorAttack
    };
  }),
  on(recordInputCompressorRelease, (state: DeviceStorageState, action) => {
    return {
      ...state,
      recordInputCompressorRelease: action.compressorRelease
    };
  }),
  on(exportMergeCompressorThreshold, (state: DeviceStorageState, action) => {
    return {
      ...state,
      exportMergeCompressorThreshold: action.compressorThreshold
    };
  }),
  on(exportMergeCompressorKnee, (state: DeviceStorageState, action) => {
    return {
      ...state,
      exportMergeCompressorKnee: action.compressorKnee
    };
  }),
  on(exportMergeCompressorRatio, (state: DeviceStorageState, action) => {
    return {
      ...state,
      exportMergeCompressorRatio: action.compressorRatio
    };
  }),
  on(exportMergeCompressorAttack, (state: DeviceStorageState, action) => {
    return {
      ...state,
      exportMergeCompressorAttack: action.compressorAttack
    };
  }),
  on(exportMergeCompressorRelease, (state: DeviceStorageState, action) => {
    return {
      ...state,
      exportMergeCompressorRelease: action.compressorRelease
    };
  })
);

export function deviceStorageReducer(state: DeviceStorageState | undefined, action: Action) {
  return reducer(state, action);
}
