import {Action, createReducer, on} from '@ngrx/store';
import {
  addInstrumentMusician,
  addInstrumentMusicians,
  authLoggedOut,
  clearInstrumentMusicians,
  deleteInstrumentMusician,
  deleteInstrumentMusicians,
  instrumentMusicianAggregate,
  loadInstrumentMusicians,
  updateInstrumentMusician,
  updateInstrumentMusicians,
  upsertInstrumentMusician,
  upsertInstrumentMusicians
} from '@spout/web-global/actions';
import {
  initialInstrumentMusicianState,
  instrumentMusicianEntityAdapter,
  InstrumentMusicianState
} from '@spout/web-global/models';

const reducer = createReducer(
  initialInstrumentMusicianState,
  on(addInstrumentMusician, (state, action) =>
    instrumentMusicianEntityAdapter.addOne(action.instrumentMusician, state)
  ),
  on(upsertInstrumentMusician, (state, action) =>
    instrumentMusicianEntityAdapter.upsertOne(action.instrumentMusician, state)
  ),
  on(addInstrumentMusicians, (state, action) =>
    instrumentMusicianEntityAdapter.addMany(action.instrumentMusicians, state)
  ),
  on(upsertInstrumentMusicians, (state, action) =>
    instrumentMusicianEntityAdapter.upsertMany(action.instrumentMusicians, state)
  ),
  on(updateInstrumentMusician, (state, action) =>
    instrumentMusicianEntityAdapter.updateOne(action.instrumentMusician, state)
  ),
  on(updateInstrumentMusicians, (state, action) =>
    instrumentMusicianEntityAdapter.updateMany(action.instrumentMusicians, state)
  ),
  on(deleteInstrumentMusician, (state, action) => instrumentMusicianEntityAdapter.removeOne(action.id, state)),
  on(deleteInstrumentMusicians, (state, action) => instrumentMusicianEntityAdapter.removeMany(action.ids, state)),
  on(loadInstrumentMusicians, (state, action) =>
    instrumentMusicianEntityAdapter.setAll(action.instrumentMusicians, state)
  ),
  on(clearInstrumentMusicians, state => instrumentMusicianEntityAdapter.removeAll(state)),
  on(instrumentMusicianAggregate, (state, action) => {
    let _state = instrumentMusicianEntityAdapter.upsertMany(action.aggregate.added, state);
    _state = instrumentMusicianEntityAdapter.upsertMany(action.aggregate.modified, _state);
    _state = instrumentMusicianEntityAdapter.removeMany(action.aggregate.removed, _state);

    return _state;
  }),
  on(authLoggedOut, (state, action) => {
    return instrumentMusicianEntityAdapter.removeAll(state);
  })
);

export function instrumentMusicianReducer(state: InstrumentMusicianState | undefined, action: Action) {
  return reducer(state, action);
}
