import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {accountReducer, websocketRegistryReducer} from '@spout/web-global/data-access';
import {authAccountInitialState, initialWebsocketRegistryState} from '@spout/web-global/models';
import {AccountEffects} from './+account/account.effects';
import {StoreModule} from '@ngrx/store';

/**
 * Stripe
 */

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forRoot(
      {
        account: accountReducer,
        webSocketRegistry: websocketRegistryReducer
      },
      {
        initialState: {
          account: authAccountInitialState,
          webSocketRegistry: initialWebsocketRegistryState
        },
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true
        }
      }
    ),
    EffectsModule.forRoot([AccountEffects])
  ],
  providers: []
})
export class WebPlatformLoginDataAccessModule {}
