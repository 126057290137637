export function valueToRadians(
  value: number,
  valueEnd?: number,
  angleEnd?: number,
  angleStart?: number,
  valueStart?: number
) {
  valueEnd = valueEnd || 100;
  valueStart = valueStart || 0;
  angleEnd = angleEnd || 360;
  angleStart = angleStart || 0;
  return (Math.PI / 180) * (((value - valueStart) * (angleEnd - angleStart)) / (valueEnd - valueStart) + angleStart);
}
