export interface SongCommand {
  trackId: string;
  songId: string;
  command: string;
  duration: number;
  length: number;
  maxY: number;
  sampleRate: number;
}

export declare type RecordType = 'track' | 'mixMaster';

export interface IRecordType {
  TRACK: RecordType;
  MIX_MASTER: RecordType;
}

export const RecordType: IRecordType = {
  TRACK: 'track',
  MIX_MASTER: 'mixMaster'
};

export enum DYN_STORE {
  // DEFAULT_IDS = 'DEFAULT_IDS', // interface AccountDefaultIds
  CURRENT_IDS = 'CURRENT_IDS', // interface CurrentIds

  CLEAN_WORKSPACE = 'CLEAN_WORKSPACE',
  CLEAR_TRACK = 'CLEAR_TRACK',
  TRACK_CLEARED = 'TRACK_CLEARED',

  POSITION_OUTPUT = 'POSITION_OUTPUT',
  FROM_POSITION_INPUT_FIELD = 'FROM_POSITION_INPUT_FIELD',
  FROM_SECONDS_INPUT_FIELD = 'FROM_SECONDS_INPUT_FIELD',

  IS_END_OF_SONG = 'IS_END_OF_SONG',
  REFRESH_TRACK_METRICS = 'REFRESH_TRACK_METRICS',
  IS_BEGINNING_OF_SONG = 'IS_BEGINNING_OF_SONG',
  // SET_TIME_TO_START = 'SET_TIME_TO_START',

  REAPPLY_TRACK_MIXES = 'REAPPLY_TRACK_MIXES',

  LOAD_FILE_TO_TRACK = 'LOAD_FILE_TO_TRACK',
  UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS',

  VOLUME = 'VOLUME',
  RECORD_OUTPUT_VOLUME_WITH_GAIN = 'RECORD_OUTPUT_VOLUME_WITH_GAIN',
  RECORD_INPUT_ANALYZER = 'RECORD_INPUT_ANALYZER',
  SAVE_RECORDED_AUDIO = 'SAVE_RECORDED_AUDIO',
  RENDER_DATA = 'RENDER_DATA' // form value interface RenderMixData
}
