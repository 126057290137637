import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProjectEntity} from '@spout/any-shared/models';
import {firestoreProjectByIdPath} from '@spout/web-global/fns';
import {Observable, Observer} from 'rxjs';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {Exists, SptFirestoreService} from '../firestore';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(private store: Store, private dss: DynamicStoreService, private sptFirestore: SptFirestoreService) {}

  createProject(project: Partial<ProjectEntity>): Observable<ProjectEntity> {
    return new Observable((observer: Observer<any>) => {
      // CREATE PROJECT IN FIRESTORE
      this.sptFirestore.setDocIfNotExist<ProjectEntity>(firestoreProjectByIdPath(project), project).subscribe(
        (r: Exists<ProjectEntity>) => {
          // DOES NOT DISPATCH ACTION
          // THIS DATA GOES NO WHERE
          observer.next(r.data);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  updateProject(project: Partial<ProjectEntity>): Observable<ProjectEntity> {
    return new Observable((observer: Observer<any>) => {
      this.sptFirestore.update<ProjectEntity>(firestoreProjectByIdPath(project), project).subscribe(
        (r: Exists<ProjectEntity>) => {
          observer.next(r.data);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }
}
