import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {SvgLoaderService} from '@web-global/utils';

@Component({
  selector: 'auth-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'auth-app-header  mat-elevation-z2'
  }
})
export class AppHeaderComponent {
  constructor(private svgLoader: SvgLoaderService) {
    this.svgLoader.addSvgIconSet();
  }
}
