import {createAction, props} from '@ngrx/store';
import {AuthEmail, AuthState} from '@spout/web-global/models';

export enum AuthActionTypes {
  AuthDoNothingAction = '[Auth] Do Nothing Action',
  AuthLoginGoogle = '[Auth] Login Google',
  AuthLoginFacebook = '[Auth] Login Facebook',
  AuthLoginTwitter = '[Auth] Login Twitter',
  AuthLoginEmail = '[Auth] Login Email',
  AuthCreateUserEmail = '[Auth] Create User Email',
  AuthAlert = '[Auth] Email Account Created',
  LoggedIn = '[Auth] Logged In',
  AuthLoadUser = '[Auth] Load User',
  LoggedOut = '[Auth] Logged Out',
  AuthError = '[Auth] Auth Error',
  AuthIsOnline = '[Auth] Auth Is Online',
  AuthIsOffline = '[Auth] Auth Is Offline'
}

export const authLoginGoogle = createAction(AuthActionTypes.AuthLoginGoogle);

export const authLoginFacebook = createAction(AuthActionTypes.AuthLoginFacebook);

export const authLoginTwitter = createAction(AuthActionTypes.AuthLoginTwitter);

export const authLoginEmail = createAction(AuthActionTypes.AuthLoginEmail, props<{payload: AuthEmail}>());

export const authCreateUserEmail = createAction(AuthActionTypes.AuthCreateUserEmail, props<{payload: AuthEmail}>());

// export class AuthError implements Action {
//   readonly type = AuthActionTypes.AuthError;
//   public payload: any;
//   constructor(private error: any) {
//     this.payload = {
//       code: error.code,
//       message: error.message,
//     };
//   }
// }

export const authError = createAction(AuthActionTypes.AuthError, props<{payload: {code: string; message: string}}>());

export const authLoggedIn = createAction(AuthActionTypes.LoggedIn, props<{payload: AuthState}>());
export const authLoadUser = createAction(AuthActionTypes.AuthLoadUser, props<{payload: AuthState}>());

export const authLoggingIn = createAction('[Auth] Logging In');

export const authLoggedOut = createAction(AuthActionTypes.LoggedOut);

export const authLogout = createAction('[Auth] Log Out Action');

export const authIsOnline = createAction(AuthActionTypes.AuthIsOnline);

export const authIsOffline = createAction(AuthActionTypes.AuthIsOffline);

export const authDoNothingAction = createAction(AuthActionTypes.AuthDoNothingAction);

// export class AuthAlert implements Action {
//   readonly type = AuthActionTypes.AuthAlert;
//   public payload: any;
//   constructor(private error: any) {
//     this.payload = {
//       code: error.code,
//       message: error.message,
//     };
//   }
// }

export const authAlert = createAction(AuthActionTypes.AuthAlert, props<{payload: {code: number; message: string}}>());

// export class AuthDetermineAlertOrError implements Action {
//   readonly type: string;
//   public payload: {
//     code: '';
//     message: '';
//   };
//   constructor(private error: any) {
//     if (error.message === ERROR_MESSAGES.providerId) {
//       this.type = AuthActionTypes.AuthAlert;
//     } else {
//       this.type = AuthActionTypes.AuthError;
//     }
//
//     this.payload = {
//       code: error.code,
//       message: error.message,
//     };
//   }
// }
