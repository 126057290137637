import {createFeatureSelector, createSelector} from '@ngrx/store';
import {InstrumentMusician, instrumentMusicianFeatureKey, InstrumentMusicianState} from '@spout/web-global/models';

export const instrumentMusicianState = createFeatureSelector<InstrumentMusicianState>(instrumentMusicianFeatureKey);

export const selectAllInstrumentsMusician = createSelector(
  instrumentMusicianState,
  (state: InstrumentMusicianState): InstrumentMusician[] => {
    if (state && state.entities) {
      return <InstrumentMusician[]>Object.values(state.entities);
    }
    return [];
  }
);
