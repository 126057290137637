export enum HelpModel {
  TOC = 'toc',
  MICROPHONE = 'mic',
  GAIN = 'gain',
  COMPRESSOR = 'compressor',
  LATENCY_TEST = 'latencyTest',
  GETTING_STARTED_COLLABORATION = 'gettingStartedCollaboration',
  GETTING_STARTED_CREATING = 'gettingStartedCreating',
  GETTING_STARTED_REVIEWING = 'gettingStartedReviewing',
  LATENCY_VS_OFFSET = 'latencyVsOffset'
}
