<div class="trial" *ngIf="showTrial">For 30 Day Trial, just download the app and start using it.</div>

<div class="mat-h5" *ngIf="showDownload">Download Version {{ firebaseStudioVersion$ | ngrxPush }}</div>

<div class="download-links" *ngIf="showDownload">
  <a *ngIf="(downloadPcUrl$ | ngrxPush)?.length" mat-flat-button color="primary" [href]="downloadPcUrl$ | ngrxPush">
    WINDOWS <mat-icon>file_download</mat-icon></a
  >

  <a
    *ngIf="(downloadMacIntelUrl$ | ngrxPush)?.length"
    mat-flat-button
    color="primary"
    [href]="downloadMacIntelUrl$ | ngrxPush"
  >
    MAC INTEL <mat-icon>file_download</mat-icon></a
  >

  <a
    *ngIf="(downloadMacM1Url$ | ngrxPush)?.length"
    mat-flat-button
    color="primary"
    [href]="downloadMacM1Url$ | ngrxPush"
  >
    MAC M1 <mat-icon>file_download</mat-icon></a
  >
</div>

<div *ngIf="!showDownload" class="mat-h3 push-top-20">App Coming Soon</div>
