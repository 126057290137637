import {Component, EventEmitter, Inject, Input} from '@angular/core';
import {HelpModel} from '@spout/web-global/models';
import {Observable, Subject} from 'rxjs';
import {CONTAINER_DATA, ContentModel} from '../content.model';

@Component({
  selector: 'spt-help-getting-started-collaboration',
  templateUrl: './help-getting-started-collaboration.component.html',
  styleUrls: ['./help-getting-started-collaboration.component.scss']
})
export class HelpGettingStartedCollaborationComponent implements ContentModel {
  static ref = HelpModel.GETTING_STARTED_COLLABORATION;
  static title = 'Getting Started With Collaboration';

  showBackHeader = true;

  helpTitle = HelpGettingStartedCollaborationComponent.title;

  private modalClose: Subject<any> = new Subject();

  onModalClose(): Observable<any> {
    return this.modalClose.asObservable();
  }

  constructor(@Inject(CONTAINER_DATA) private data: ContentModel) {
    if (this.data) {
      this.showBackHeader = this.data.showBackHeader;
    }
  }

  closeModal() {
    this.modalClose.next();
    this.modalClose.complete();
  }
}
