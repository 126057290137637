export interface SampleRateOptions {
  44100: number;
  48000: number;
  96000: number;
}

export const SAMPLE_RATE: SampleRateOptions = {
  '44100': 44100,
  '48000': 48000,
  '96000': 96000
};

export type SampleRate = 44100 | 48000 | 96000;

export const sampleRateOptions: number[] = Object.values(SAMPLE_RATE);
