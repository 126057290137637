import {TrackMixAudioSync} from '@spout/any-shared/models';
import {SptWorkletAudioPlayer} from '../spt-worklet-audio-player';
import {ApplyVolumeMix} from '@spout/web-global/models';
import {allValuesMatch} from '@uiux/fn';

function getSyncFromPlayer(p: SptWorkletAudioPlayer): {
  start: number | string;
  offset: number | string;
  stop: number | string;
} {
  return {
    start: p.start,
    stop: p.stop,
    offset: p.offsetMs
  };
}

function getSyncFromSnippet(p: TrackMixAudioSync): {
  start: number | string;
  offset: number | string;
  stop: number | string;
} {
  return {
    start: p.start,
    stop: p.stop,
    offset: p.offsetMs
  };
}

export function syncParamsEqual(p: SptWorkletAudioPlayer, snippit: TrackMixAudioSync): boolean {
  const a = getSyncFromPlayer(p);
  const b = getSyncFromSnippet(snippit);

  return allValuesMatch(a, b);
}

function getVolumeFromPlayer(p: SptWorkletAudioPlayer): {mute: boolean; volume: number; masterVolume: number} {
  return {
    mute: p.mute,
    volume: p.volume,
    masterVolume: p.masterVolume
  };
}

function getVolumeFromApplyVolumeMix(p: ApplyVolumeMix): {mute: boolean; volume: number; masterVolume: number} {
  return {
    mute: p.mute,
    volume: p.volume,
    masterVolume: p.masterVolume
  };
}

export function volumeParamsEqual(p: SptWorkletAudioPlayer, v: ApplyVolumeMix): boolean {
  const a = getVolumeFromPlayer(p);
  const b = getVolumeFromApplyVolumeMix(v);

  return allValuesMatch(a, b);
}
