<button *ngIf="config.FACEBOOK" mat-stroked-button (click)="facebook.next()">
  <span class="btn-social-login">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 0V16H8.52098V9.81953H6.43799V7.2965H8.52098V5.17759C8.52098 3.63657 9.77021 2.38734 11.3112 2.38734H13.4888V4.65613H11.9306C11.441 4.65613 11.044 5.0531 11.044 5.54276V7.29653H13.4497L13.1172 9.81957H11.044V16H16V0H0Z"
        fill="#56657F"
      />
    </svg>
    FACEBOOK
  </span>
</button>
<button *ngIf="config.GOOGLE" mat-stroked-button (click)="google.next()">
  <span class="btn-social-login">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(4, 4)">
        <rect width="16" height="16" fill="none"></rect>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.5157 3.9635L13.1074 1.84175C11.7219 0.691437 9.9421 0 8.00101 0C4.90913 0 2.22695 1.75384 0.895508 4.32088H0.895945L0.896119 4.32102L0.894938 4.3208H0.8945C0.322625 5.42233 0 6.67333 0 7.99993C0 9.37169 0.345005 10.6626 0.953253 11.7908L0.952881 11.7911C2.3041 14.2971 4.95288 15.9999 7.99947 15.9999C9.89529 15.9999 11.6368 15.3406 13.0078 14.2386L13.0077 14.2385C14.4173 13.1053 15.4348 11.504 15.8242 9.67021C15.939 9.13131 15.9992 8.57262 15.9992 7.99987C15.9992 7.48909 15.9513 6.98931 15.8594 6.50537H12.5153H8.17505V9.67021H12.4529C12.0678 10.6979 11.3368 11.5564 10.4014 12.1049L10.4015 12.1051C9.6969 12.5186 8.87586 12.7555 7.99947 12.7555C5.96065 12.7555 4.22153 11.4724 3.54572 9.66975L3.54594 9.66893C3.35078 9.14983 3.24441 8.58718 3.24434 7.99993C3.24434 7.45883 3.33491 6.93883 3.50106 6.45443L3.50053 6.45321L3.5021 6.4545C4.14385 4.58678 5.91573 3.24437 8.00101 3.24437C8.92451 3.24437 9.78651 3.50769 10.5157 3.9635Z"
          fill="#56657F"
        />
      </g>
    </svg>
    Sign in with Google</span
  >
</button>
<button *ngIf="config.APPLE" mat-stroked-button (click)="apple.next()">
  <span class="btn-social-login">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7227 7.38461C13.5547 7.38461 14.5977 6.80483 15.2188 6.03179C15.7812 5.33121 16.1914 4.35283 16.1914 3.37444C16.1914 3.24158 16.1797 3.10871 16.1562 3C15.2305 3.03624 14.1172 3.64018 13.4492 4.44946C12.9219 5.06548 12.4414 6.03179 12.4414 7.02225C12.4414 7.1672 12.4648 7.31214 12.4766 7.36046C12.5352 7.37254 12.6289 7.38461 12.7227 7.38461ZM9.79297 22C10.9297 22 11.4336 21.2149 12.8516 21.2149C14.293 21.2149 14.6094 21.9758 15.875 21.9758C17.1172 21.9758 17.9492 20.7921 18.7344 19.6325C19.6133 18.3039 19.9766 16.9994 20 16.939C19.918 16.9148 17.5391 15.9123 17.5391 13.0979C17.5391 10.658 19.4141 9.5588 19.5195 9.47425C18.2773 7.63827 16.3906 7.58996 15.875 7.58996C14.4805 7.58996 13.3438 8.45963 12.6289 8.45963C11.8555 8.45963 10.8359 7.63827 9.62891 7.63827C7.33203 7.63827 5 9.59504 5 13.2912C5 15.5861 5.86719 18.014 6.93359 19.5842C7.84766 20.9129 8.64453 22 9.79297 22Z"
        fill="white"
      />
    </svg>

    Sign in with Apple</span
  >
</button>
<button *ngIf="config.TWITTER" mat-stroked-button (click)="twitter.next()">
  <span class="btn-social-login">
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 2.039C15.405 2.3 14.771 2.473 14.11 2.557C14.79 2.151 15.309 1.513 15.553 0.744C14.919 1.122 14.219 1.389 13.473 1.538C12.871 0.897 12.013 0.5 11.077 0.5C9.261 0.5 7.799 1.974 7.799 3.781C7.799 4.041 7.821 4.291 7.875 4.529C5.148 4.396 2.735 3.089 1.114 1.098C0.831 1.589 0.665 2.151 0.665 2.756C0.665 3.892 1.25 4.899 2.122 5.482C1.595 5.472 1.078 5.319 0.64 5.078C0.64 5.088 0.64 5.101 0.64 5.114C0.64 6.708 1.777 8.032 3.268 8.337C3.001 8.41 2.71 8.445 2.408 8.445C2.198 8.445 1.986 8.433 1.787 8.389C2.212 9.688 3.418 10.643 4.852 10.674C3.736 11.547 2.319 12.073 0.785 12.073C0.516 12.073 0.258 12.061 0 12.028C1.453 12.965 3.175 13.5 5.032 13.5C11.068 13.5 14.368 8.5 14.368 4.166C14.368 4.021 14.363 3.881 14.356 3.742C15.007 3.28 15.554 2.703 16 2.039Z"
        fill="#56657F"
      />
    </svg>
    TWITTER</span
  >
</button>
