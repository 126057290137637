import {ItemizedCost, PaymentWizardForm, ProductWizardStore} from './payment-wizard.service.model';
import {
  Price,
  PriceInterval,
  PriceOptions,
  PriceOptionsFlat,
  Product,
  SubscriptionItem
} from '@spout/web-global/models';
import {hasValue} from '@uiux/fn';

export function priceOptions(state: ProductWizardStore): PriceOptions[] {
  if (!hasValue(state.productEntities) || !hasValue(state.priceEntities)) {
    return [];
  }

  return Object.values(state.productEntities).map((product: Product) => {
    const prices: Price[] = Object.values(state.priceEntities).filter((p: Price) => p.productId === product.id);

    return <PriceOptions>{
      product,
      prices: [
        ...prices.filter((p: Price) => p.interval === 'month'),
        ...prices.filter((p: Price) => p.interval === 'year')
      ]
    };
  });
}

export function getCurrentPlan(state: ProductWizardStore): SubscriptionItem | null {
  return state.currentSubscriptionPlan;
}

export function getCurrentPlanId(state: ProductWizardStore): PaymentWizardForm {
  const priceId = state.currentSubscriptionPlan?.price.id;
  const storageQuantity = state.currentSubscriptionStorage?.quantity;

  return {
    priceId: priceId ? priceId : '',
    additionalStoragePerGB: storageQuantity !== undefined ? storageQuantity : 0,
    earlyAdopterAgreementSigned: false
  };
}

export function planPriceOptions(state: ProductWizardStore): PriceOptions[] {
  return priceOptions(state).filter((p: PriceOptions) => p.product.stripe_metadata_addon !== 'storage');
}

export function priceOptionsFlat(state: ProductWizardStore): PriceOptionsFlat[] {
  return priceOptions(state).reduce((a: PriceOptionsFlat[], p: PriceOptions) => {
    const flat = p.prices.map((price: Price) => {
      return <PriceOptionsFlat>{
        product: p.product,
        price
      };
    });

    return [...a, ...flat];
  }, []);
}

export function planPriceOptionsFlat(state: ProductWizardStore): PriceOptionsFlat[] {
  return priceOptionsFlat(state).filter((p: PriceOptionsFlat) => p.product.stripe_metadata_addon !== 'storage');
}

export function storagePriceOptionsFlat(state: ProductWizardStore): PriceOptionsFlat[] {
  return priceOptionsFlat(state).filter((p: PriceOptionsFlat) => p.product.stripe_metadata_addon === 'storage');
}

export function totalCost(items: ItemizedCost[]): number {
  return items.reduce((t: number, i: ItemizedCost) => {
    if (!i.isTrial) {
      t = t + i.itemPrice;
    }

    return t;
  }, 0);
}

export function getPlanPricesByInterval(state: ProductWizardStore, interval: PriceInterval) {
  return planPriceOptionsFlat(state).filter((price: PriceOptionsFlat) => price.price.interval === interval);
}

export function getPlanPriceByInterval(state: ProductWizardStore, interval: PriceInterval) {
  return getPlanPricesByInterval(state, interval).reduce((a: PriceOptionsFlat | null, i: PriceOptionsFlat) => {
    if (!a) {
      return i;
    }

    return a;
  }, null);
}

export function getStoragePricesByInterval(state: ProductWizardStore, interval: PriceInterval) {
  return storagePriceOptionsFlat(state).filter((price: PriceOptionsFlat) => price.price.interval === interval);
}

export function getStoragePriceByInterval(state: ProductWizardStore, interval: PriceInterval) {
  return getStoragePricesByInterval(state, interval).reduce((a: PriceOptionsFlat | null, i: PriceOptionsFlat) => {
    if (!a) {
      return i;
    }

    return a;
  }, null);
}

export function isUpdatedStorage(
  selectedStoragePrice: Price | null,
  currentSubscriptionStorage: SubscriptionItem | null,
  additionalStoragePerGB: number
): boolean {
  if (selectedStoragePrice) {
    if (currentSubscriptionStorage) {
      if (currentSubscriptionStorage.price.id !== selectedStoragePrice.id) {
        return true;
      }
      return additionalStoragePerGB !== currentSubscriptionStorage.quantity;
    }
    return true;
  }
  return false;
}

export function isUpdatedPlan(
  currentSubscriptionPlan: SubscriptionItem | null,
  selectedPlanPrice: Price | null
): boolean {
  if (selectedPlanPrice) {
    if (currentSubscriptionPlan) {
      return currentSubscriptionPlan.price.id !== selectedPlanPrice.id;
    }

    return true;
  }
  return false;
}

export function isUpdatedCheckout(
  currentSubscriptionPlan: SubscriptionItem | null,
  selectedPlanPrice: Price | null,
  selectedStoragePrice: Price | null,
  currentSubscriptionStorage: SubscriptionItem | null,
  additionalStoragePerGB: number
): boolean {
  return (
    isUpdatedPlan(currentSubscriptionPlan, selectedPlanPrice) ||
    isUpdatedStorage(selectedStoragePrice, currentSubscriptionStorage, additionalStoragePerGB)
  );
}

export function isUpdatedCheckoutState(state: ProductWizardStore): boolean {
  return isUpdatedCheckout(
    state.currentSubscriptionPlan,
    state.selectedPlanPrice,
    state.selectedStoragePrice,
    state.currentSubscriptionStorage,
    state.additionalStoragePerGB
  );
}
