import {Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, Input} from '@angular/core';
import {authLogout} from '@spout/web-global/actions';
import {AccountState} from '@spout/web-global/models';
import {getIsLoggedIn, selectAccountState} from '@spout/web-global/selectors';
import {OtlidAppService} from './otlid-app.service';
import {Observable} from 'rxjs';

import {select, Store} from '@ngrx/store';

@Component({
  selector: 'auth-app-otlid-login',
  templateUrl: './app-otlid-login.component.html',
  styleUrls: ['./app-otlid-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppOtlidLoginComponent {
  @Input() loginBtnText = 'Login';

  @Output() showProgress: EventEmitter<boolean> = new EventEmitter<boolean>();

  account$: Observable<AccountState>;
  isLoggedIn$: Observable<boolean>;

  constructor(public appLogin: OtlidAppService, private store: Store) {
    this.account$ = this.store.pipe(select(selectAccountState));
    this.isLoggedIn$ = this.store.pipe(select(getIsLoggedIn));
  }

  onLoginBrowser() {
    this.showProgress.emit(true);
    this.appLogin.launchAuthSite().subscribe(() => {
      this.showProgress.emit(false);
    });
  }

  logout() {
    this.store.dispatch(authLogout());
  }
}
