import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {CurrentIds, DeviceStorageState} from '@spout/any-shared/models';
import {AuthAccountProductStates} from '@spout/web-global/models';
import {selectCurrentIdsFromAccountDefaultIds$} from '@spout/web-global/selectors';
import {mergePropsIfSourceHasValue} from '@uiux/fn';
import {hasValuePipe} from '@uiux/rxjs';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {SptAudioService} from '../../audio/spt-audio.service';
import {DeviceStorageService} from './device-storage.service';

@Injectable()
export class DeviceEntityService {
  constructor(
    private device: DeviceStorageService,
    private store: Store<AuthAccountProductStates>,
    private sptAudioService: SptAudioService
  ) {}

  saveCurrentIdsFromAccountDefaultIds(storage: Partial<DeviceStorageState>): Observable<Partial<DeviceStorageState>> {
    const _currentIdStorage = {
      ...storage
    };

    // console.log('SAVE CURRENT IDS FROM ACCOUNT DEFAULT IDS', _currentIdStorage);

    return this.store.pipe(
      selectCurrentIdsFromAccountDefaultIds$,
      hasValuePipe<CurrentIds, CurrentIds>(),
      take(1),
      map((currentIds: CurrentIds) => {
        _currentIdStorage.currentIds = {
          ...currentIds
        };

        return _currentIdStorage;
      })
    );
  }

  saveDefaultAudioDevice(storage: Partial<DeviceStorageState>): Observable<Partial<DeviceStorageState> | null> {
    return this.sptAudioService.getDefaultAudioDevice().pipe(
      map((deviceInfo: MediaDeviceInfo | null) => {
        if (deviceInfo) {
          const storeData: MediaDeviceInfo = mergePropsIfSourceHasValue(
            {...storage},
            {
              mediaDeviceInfo: deviceInfo.toJSON()
            }
          );

          return storeData;
        }

        return null;
      })
    );
  }
}
