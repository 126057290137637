import {BehaviorSubject, from, Subject} from 'rxjs';
import {reduce} from 'rxjs/operators';
import {TreeConfig} from './entities/tree.model';

export interface ITreeNode {
  data: TreeConfig;
  children?: TreeNode[];
}

export interface TreeString {
  data: TreeConfig;
  children: TreeString[];
}

export interface TreeChildrenReduceAccumulator {
  children: TreeConfig[];
  notChildren: TreeConfig[];
}

export interface ToParentNode {
  addToList?: TreeNode;
  list?: TreeNode[];
  selected?: TreeConfig[];
  selectedList?: TreeConfig[];
  childrenRemoved?: TreeConfig[]; // root only
  toRemove?: {
    remove?: TreeConfig;
    // isRoot?: boolean;
    childrenRemoved?: TreeConfig[]; // root only
  };
}

export function findMatchingUuid(id: string): any {
  return (element: TreeNode): boolean => {
    return element.data.id === id;
  };
}

export class TreeNode implements ITreeNode {
  children: TreeNode[] = [];

  // Parent to child classes
  parent$: Subject<any> = new Subject();
  list$: BehaviorSubject<TreeNode[]> = new BehaviorSubject<TreeNode[]>([this]);
  selected$: Subject<TreeConfig[]> = new Subject();
  childrenRemoved$: Subject<TreeConfig[]> = new Subject<TreeConfig[]>();
  search = '';

  id: string | null = null;

  constructor(
    public data: TreeConfig,
    public parentSearch: string | null,
    private toParent$: Subject<ToParentNode> | null, // for this class parent
    private toChild$: Subject<any> | null, // This class is the child
    private isRoot?: boolean
  ) {
    if (data && data.id && data.id.length) {
      this.id = data.id;
    }

    if (this.parentSearch && this.parentSearch.length) {
      this.search = `${this.data.name}, ${parentSearch}`;
    } else {
      this.search = `${this.data.name}`;
    }

    if (this.isRoot) {
      this.list$.next([this]);
    }

    // Communicate to parent of this class
    if (this.toParent$) {
      this.toParent$.next({
        addToList: this
      });
    }

    // Communicate from this Class parent to this node as child
    if (this.toChild$) {
      this.toChild$.subscribe((r: any) => {
        // console.log('TO CHILD: ', r);
      });
    }

    this.parent$.subscribe((r: ToParentNode) => {
      if (r.addToList) {
        if (this.isRoot) {
          const list: TreeNode[] = this.list$.getValue();
          list.push(r.addToList);
          this.list$.next(list);
        } else if (this.toParent$) {
          this.toParent$.next({
            addToList: r.addToList
          });
        }
      }

      if (r.selected) {
        if (this.isRoot) {
          this.selected$.next([this.data, ...r.selected]);
        } else if (this.toParent$) {
          this.toParent$.next({
            selected: [...r.selected, this.data]
          });
        }
      }

      if (r.childrenRemoved && r.childrenRemoved.length) {
        // console.log('childrenRemoved', r.childrenRemoved);
        if (this.isRoot) {
          // parent node, remove all in listToRemove
          this.childrenRemoved$.next(r.childrenRemoved);

          // cache list, parent node will follow in next cycle
          const list: TreeNode[] = this.list$.getValue();

          r.childrenRemoved.forEach((child: TreeConfig) => {
            const indexRemoveChild = list.findIndex(findMatchingUuid(child.id));
            list.splice(indexRemoveChild, 1);
          });

          this.list$.next(list);
        } else if (this.toParent$) {
          this.toParent$.next({
            childrenRemoved: r.childrenRemoved
          });
        }
      }

      if (r.toRemove && r.toRemove.remove) {
        // console.log('remove', r.remove);
        const index: number = this.children.findIndex(findMatchingUuid(r.toRemove.remove.id));
        this.children.splice(index, 1);

        const childrenRemoved: TreeConfig[] = [r.toRemove.remove];

        if (r.toRemove.childrenRemoved) {
          childrenRemoved.push(...r.toRemove.childrenRemoved);
        }

        if (this.isRoot) {
          // parent node, remove all in listToRemove
          this.childrenRemoved$.next(childrenRemoved);

          // cache list, parent node will follow in next cycle
          const list: TreeNode[] = this.list$.getValue();

          childrenRemoved.forEach((child: TreeConfig) => {
            const indexRemoveChild = list.findIndex(findMatchingUuid(child.id));
            list.splice(indexRemoveChild, 1);
          });

          // Add root back to list ( this node )
          const indexOfRoot = list.findIndex(findMatchingUuid(this.data.id));
          if (indexOfRoot < 0) {
            list.push(this);
          }

          this.list$.next(list);
        } else if (this.toParent$) {
          this.toParent$.next({
            childrenRemoved: childrenRemoved
          });
        }
      }
    });
  }

  addChildren(dataList: TreeConfig[]) {
    from(dataList)
      .pipe(
        reduce(
          (acc: TreeChildrenReduceAccumulator, config: TreeConfig) => {
            const isChild = this.children.filter((child: TreeNode) => child.data.id === config.id);

            // is a direct direct descendant
            if (config.parentId === this.data.id) {
              if (!isChild.length) {
                acc.children.push(config);
              }
            } else {
              // not a direct descendant
              acc.notChildren.push(config);
            }

            return acc;
          },
          <TreeChildrenReduceAccumulator>{
            children: [],
            notChildren: []
          }
        )
      )
      .subscribe((result: TreeChildrenReduceAccumulator) => {
        result.children.map((config: TreeConfig) => {
          if (config && config.id && config.id.length) {
            const node = new TreeNode(config, this.search, this.parent$, this.toChild$);
            this.children.push(node);
          }
        });

        this.children.map((node: TreeNode) => {
          node.addChildren(result.notChildren);
        });

        this.children.sort((a: TreeNode, b: TreeNode) => {
          const nameA = a && a.data && a.data.name ? a.data.name.toUpperCase() : ''; // ignore upper and lowercase
          const nameB = b && b.data && b.data.name ? b.data.name.toUpperCase() : ''; // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      });
  }

  select() {
    if (this.toParent$) {
      this.toParent$.next({
        selected: [this.data]
      });
    }
  }

  remove() {
    if (this.toParent$) {
      this.toParent$.next({
        toRemove: {
          remove: this.data,
          childrenRemoved: this.getChildrenConfigList()
        }
      });
    }
  }

  removeAllChildren(): void {
    if (this.isRoot) {
      this.children = [];
      this.list$.next([this]);
      this.selected$.next([this.data]);
      this.childrenRemoved$.next([]);
    }
  }

  toString(): TreeString {
    return {
      data: this.data,
      children: this.children.map((child: TreeNode) => {
        return child.toString();
      })
    };
  }

  getChildrenConfigList(): TreeConfig[] {
    return this.children.reduce((acc: TreeConfig[], child: TreeNode) => {
      acc.push(child.data, ...child.getChildrenConfigList());
      return acc;
    }, []);
  }
}
