import {CommonChartConfig, CommonTooltip} from '../core/chart.models';

export interface BulletChartData {
  max: number;
  progress: number;
  min: number;
  /* units suffix such as Kb, Db, MHz */
  units: string;
}

export type BulletChartConfig = CommonChartConfig;

export interface BulletChartToolTip extends CommonTooltip<BulletChartData> {
  showTooltipDivot: boolean;
}
