import {removeTimestampCTorFromDocumentSnapshot} from '@spout/web-global/data-access';
import {AggregateFirebaseSnapshotChangesEntities} from '@spout/web-global/models';
import {hasValue} from '@uiux/fn';
import {getUpdatedAtSeconds} from './get-updated-at-seconds';
import {DocumentChange} from 'firebase/firestore';

/**
 *
 * @param changes
 * @param createdByUID email of logged in musician
 * @param id
 * @param rootName
 */
export function aggregateDocChanges<T>(
  changes: Array<DocumentChange<T>>,
  createdByUID: string = '',
  id: string = 'id',
  rootName: string = 'root'
): AggregateFirebaseSnapshotChangesEntities<T> {
  return changes.reduce(
    function (acc: AggregateFirebaseSnapshotChangesEntities<T>, change: DocumentChange<T>) {
      const data: any = removeTimestampCTorFromDocumentSnapshot(change.doc);

      (<any>data).updatedAtSeconds = getUpdatedAtSeconds(data);

      if (hasValue(data.name)) {
        if (data.name === rootName) {
          acc.root = data;
          return acc;
        }
      }

      if (change.type === 'added') {
        acc.added.push(data);
      }

      if (change.type === 'modified') {
        // console.log('Modified Project: ', change.doc.data());
        acc.modified.push(data);
      }

      if (change.type === 'removed') {
        // console.log('Removed Track: ', data);
        acc.removed.push((<any>data)[id]);

        // if (deleteLocalIfNotShare(data, createdByUID)) {
        //   console.log('delete because not shared');
        //   acc.removed.push((<any>data)[id]);
        //   return acc;
        // } else if (deleteLocalIfNotSync(data, createdByUID)) {
        //   console.log('delete because not sync');
        //   acc.removed.push((<any>data)[id]);
        //   return acc;
        // } else if (!isOwner(data, createdByUID)) {
        //   acc.removed.push((<any>data)[id]);
        // }
      }

      acc.all.push(data);
      acc.entities[data[id]] = data;

      return acc;
    },
    <AggregateFirebaseSnapshotChangesEntities<T>>{
      added: [],
      modified: [],
      removed: [],
      all: [],
      entities: {},
      root: null
    }
  );
}
