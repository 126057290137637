import {select, Store} from '@ngrx/store';
import {
  AudioFileMetaDataEntity,
  ElectronErrorCodes,
  ElectronNativeAudio,
  FileUint8ArrayType,
  TrackMixAudioSync
} from '@spout/any-shared/models';
import {getDefaultWaveformValues, parseLoadedUint8ArrayFile} from '@spout/web-global/fns';
import {
  ApplyVolumeMix,
  MixMetrics,
  ParsedRecordExport,
  StudioAppState,
  TrackEntityAndAudioFileMetaDataEntity,
  WaveformValues,
  WaveformValuesTrackMixAudioSync
} from '@spout/web-global/models';
import {getAudioMetaDataById} from '@spout/web-global/selectors';
import {hasValue} from '@uiux/fn';
import {isDefinedPipe} from '@uiux/rxjs';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil
} from 'rxjs/operators';
import {AudioFileLoadService} from '../+device-storage/services/audio-file-load.service';
import {AudioFileSaveService} from '../+device-storage/services/audio-file-save.service';
import {DeviceStorageService} from '../+device-storage/services/device-storage.service';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {FirebaseStorageService} from '../services/firebase-storage.service';
import {volumeParamsEqual} from './helpers/audio-player.helpers';
import {getPlayerInstanceKeyByEntities} from './helpers/audio.helpers';
import {SptCurrentMixMetricsCalculatorService} from './services/spt-current-mix-metrics-calculator.service';
import {SptVolumeTranslateService} from './services/spt-volume-translate.service';
import {SharedBufferWorkletOptions} from './shared-buffer-worklet-node';
import {SptMergerWorklet} from './spt-merger-worklet';
import {InputMergeMap, SptTransportService} from './spt-transport.service';

export class SptWorkletAudioPlayer extends AudioWorkletNode {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _isLoaded$: BehaviorSubject<boolean>;
  private _loadSub: Subscription;

  private waveformValues$: ReplaySubject<WaveformValues | null>;
  trackMixAudioSync$: ReplaySubject<TrackMixAudioSync>;
  waveformValuesTrackMixAudioSync$: ReplaySubject<WaveformValuesTrackMixAudioSync>;

  onClear$: Subject<boolean>;
  onLoadAudio$: Subject<boolean>;

  trackId: string;
  audioFileMetaDataEntityId: string;

  // Sync
  start: number;
  offsetMs: number;
  stop: number;
  volume: number;
  masterVolume: number;

  private calculatedGain: number;
  private duration: number;
  private sampleRate: number;

  // Volume
  private _mute: boolean;
  private readonly _audioContextId: number;
  private readonly _gain: GainNode;

  set mute(m) {
    this._mute = m;
    this.port.postMessage({
      message: 'MUTE',
      mute: m
    });
  }

  get mute() {
    return this._mute;
  }

  unmute() {
    this._mute = false;
    this.port.postMessage({
      message: 'MUTE',
      mute: false
    });
  }

  unmute$() {
    this._mute = false;
    this.port.postMessage({
      message: 'MUTE',
      mute: false
    });

    return of(true);
  }

  constructor(
    // TODO Is this needed if injected SptTransportService ?
    audioContext: AudioContext,
    public id: string,
    public a: TrackEntityAndAudioFileMetaDataEntity,
    private dss: DynamicStoreService,
    private store: Store<StudioAppState>,
    private transport: SptTransportService,
    private audioFileLoadService: AudioFileLoadService,
    private audioFileSaveService: AudioFileSaveService,
    private volumeService: SptVolumeTranslateService,
    private metrics: SptCurrentMixMetricsCalculatorService,
    private _storage: FirebaseStorageService,
    public isActiveInPlaylist: boolean,
    private device: DeviceStorageService,
    options: SharedBufferWorkletOptions
  ) {
    super(audioContext, 'shared-buffer-player-worklet-processor', options);

    const that = this;

    this._gain = audioContext.createGain();
    this.connect(this._gain);

    this._audioContextId = (<any>audioContext)['id'];

    // console.log('AudioContext', audioContext);

    this.trackId = this.a.trackEntity.id;
    this.audioFileMetaDataEntityId = this.a.audioFileMetaDataEntity.id;

    this._isLoaded$ = new BehaviorSubject<boolean>(false);
    this._loadSub = Subscription.EMPTY;
    // this.waveformValues$ = new BehaviorSubject<WaveformValues>(getDefaultWaveformValues());
    this.waveformValues$ = new ReplaySubject<WaveformValues | null>(1);
    this.trackMixAudioSync$ = new ReplaySubject<TrackMixAudioSync>(1);
    this.waveformValuesTrackMixAudioSync$ = new ReplaySubject<WaveformValuesTrackMixAudioSync>(1);

    this.onLoadAudio$ = new Subject<boolean>();
    this.onClear$ = new Subject<boolean>();
    this.start = 0;
    this.offsetMs = 0;
    this.stop = 0;

    this.duration = 0;
    this.sampleRate = 0;

    this._mute = false;
    this.volume = 0;
    this.calculatedGain = 1;
    this.masterVolume = 0;

    combineLatest([
      this.transport.audioContext$.pipe(
        map((audioCtx: AudioContext) => audioCtx.sampleRate),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      ),
      this.metrics.mixMetrics$.pipe(
        map((m: MixMetrics) => m.defaultDuration),
        distinctUntilChanged()
      )
    ]).subscribe(([sampleRate, defaultDuration]) => {
      this.waveformValues$.next(getDefaultWaveformValues(sampleRate, defaultDuration, false));
    });

    combineLatest([this.waveformValues$, this.trackMixAudioSync$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([waveformValues, trackMixAudioSync]: [WaveformValues | null, TrackMixAudioSync]) => {
        this.waveformValuesTrackMixAudioSync$.next({
          waveformValues,
          trackMixAudioSync
        });
      });

    this.port.onmessage = function (event) {
      const data = event.data;

      if (data.message === 'PLAYER_READY') {
      }
    };

    // Load recorded audio
    this.dss
      .event<ParsedRecordExport>(this.dss.DYN_STORE.SAVE_RECORDED_AUDIO)
      .pipe(
        filter((data: ParsedRecordExport) => {
          return getPlayerInstanceKeyByEntities(data.trackEntityAndAudioFileMetaDataEntity) === this.id;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((data: ParsedRecordExport) => {
        // console.log('load DYN_STORE.saveRecordedAudio', data);
        this.loadBuffer(data);
        this.waveformValues$.next(data.waveformValues);
      });

    // Get Latest
    this.store
      .pipe(
        select(getAudioMetaDataById, {
          audioFileMetaDataId: this.audioFileMetaDataEntityId
        }),
        isDefinedPipe<AudioFileMetaDataEntity | undefined, AudioFileMetaDataEntity>(),
        filter((_a: AudioFileMetaDataEntity) => _a.fileUploaded),
        distinctUntilKeyChanged<AudioFileMetaDataEntity>('fileUploaded'),
        takeUntil(this._onDestroy$)
      )
      .subscribe((_a: AudioFileMetaDataEntity) => {
        that.loadFromSystem.call(this, _a);
      });

    this.store
      .pipe(
        select(getAudioMetaDataById, {
          audioFileMetaDataId: this.audioFileMetaDataEntityId
        }),
        isDefinedPipe<AudioFileMetaDataEntity | undefined, AudioFileMetaDataEntity>(),
        filter((_a: AudioFileMetaDataEntity) => !(_a && _a.fileUploaded)),
        distinctUntilKeyChanged<AudioFileMetaDataEntity>('fileUploaded'),
        takeUntil(this._onDestroy$)
      )
      .subscribe((_a: AudioFileMetaDataEntity) => {
        // that.disconnect();
        that.clear();
      });

    this.transport.onStopTimeSeconds$.pipe(takeUntil(this._onDestroy$)).subscribe((seconds: number) => {
      this.port.postMessage({
        message: 'SET_CURRENT_TIME_MS'
      });
    });
  }

  connectOutput(dest: AudioDestinationNode) {
    this._gain.connect(dest);
  }

  connectMerger(merger: SptMergerWorklet, inputMap: InputMergeMap) {
    this._gain.connect(merger, 0, inputMap[this.id]);
  }

  disconnectOutput$(): Observable<boolean> {
    // console.log('disconnectOutput destroy');
    this._gain.disconnect();
    return of(true);
  }

  audioContextIdMatches(id: number): boolean {
    return this._audioContextId === id;
  }

  private loadFromSystem(a: AudioFileMetaDataEntity) {
    const that = this;

    this._loadSub.unsubscribe();
    this._loadSub = this.audioFileLoadService
      .loadFileFromDisk(a)
      .pipe(
        switchMap((response: ElectronNativeAudio) => {
          // console.log(response);
          if (
            !(
              response &&
              response.error &&
              response.error.error &&
              response.error.error.code === ElectronErrorCodes.FILE_DOES_NOT_EXIST
            )
          ) {
            return of(response.uint8ArrayType);
          } else if (a.fileUploaded) {
            return this._storage.downloadStorage(a).pipe(
              // tap((d: { progress: number; result: FileUint8ArrayType }) => {
              //   console.log(d);
              // }),
              filter((d: {progress: number; result: FileUint8ArrayType}) => d && hasValue(d.result)),
              map((d: {progress: number; result: FileUint8ArrayType}) => d.result),
              switchMap((result: FileUint8ArrayType) => {
                return this.audioFileSaveService
                  .saveNativeAudioFile({
                    uint8ArrayType: result,
                    audioFileMetaDataEntity: that.a.audioFileMetaDataEntity
                  })
                  .pipe(
                    map(() => {
                      return result;
                    })
                  );
              })
            );
          } else {
            // console.log('FILESYSTEM');
            return of(null);
          }
        }),
        isDefinedPipe<FileUint8ArrayType | null | undefined, FileUint8ArrayType>(),
        mergeMap((response: FileUint8ArrayType) => {
          return that.transport.audioContext$.pipe(
            mergeMap(audioContext => {
              return parseLoadedUint8ArrayFile(response, that.a, audioContext);
            })
          );

          // const extensionBlob = createBlobFromFileUint8ArrayType(response.uint8ArrayType);
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((data: ParsedRecordExport) => {
        that.loadBuffer.call(that, data);
      });
  }

  setSnippet(mixOptions: TrackMixAudioSync): void {
    if (!mixOptions) {
      return;
    }

    this.start = mixOptions.start;
    this.stop = mixOptions.stop;
    this.offsetMs = mixOptions.offsetMs;

    this.port.postMessage({
      message: 'SET_MIX_OPTIONS',
      mixOptions
    });

    this.trackMixAudioSync$.next(mixOptions);
  }

  setVolume(v: ApplyVolumeMix): void {
    const volume = this.volumeService.percentToDecibelWithMaster(v.volume, v.masterVolume);

    // console.log('percentToDecibelWithMaster -->', volume);

    if (!volumeParamsEqual(this, v)) {
      if (volume !== this.volume || this.masterVolume !== v.masterVolume) {
        // console.log('apply volume');
        const calculatedGainWithMaster = this.volumeService.calculatedGainWithMaster(v.volume, v.masterVolume);
        // console.log('calculatedGainWithMaster', calculatedGainWithMaster);

        this.calculatedGain = calculatedGainWithMaster;
        this.volume = v.volume;
        this.masterVolume = v.masterVolume;
        this._gain.gain.value = calculatedGainWithMaster;
      }

      /**
       * NOTE: Mute must come after setting volume to apply
       */
      if (this._mute !== v.mute) {
        this._mute = v.mute;
      }

      if (this._mute) {
        this._gain.gain.value = 0;
      }
    }
  }

  clear() {
    // console.log('CLEAR');
    this.port.postMessage({
      message: 'CLEAR'
    });
    this._isLoaded$.next(false);
    this.onClear$.next(true);
    this.waveformValues$.next(null);

    combineLatest([
      this.transport.audioContext$.pipe(
        map((audioCtx: AudioContext) => audioCtx.sampleRate),
        distinctUntilChanged()
      ),
      this.metrics.mixMetrics$.pipe(
        map((m: MixMetrics) => m.defaultDuration),
        distinctUntilChanged()
      )
    ])
      .pipe(take(1))
      .subscribe(([sampleRate, defaultDuration]) => {
        this.waveformValues$.next(getDefaultWaveformValues(sampleRate, defaultDuration, false));
      });
  }

  destroy() {
    // console.log('disconnect destroy');
    this.disconnect();
    this._onDestroy$.next(true);
    this.port.postMessage({
      message: 'CLEAR'
    });
  }

  private loadBuffer(data: ParsedRecordExport) {
    const that = this;

    this.transport.transportSharedArrayBuffer$
      .pipe(take(1))
      .subscribe((transportSharedArrayBuffer: SharedArrayBuffer) => {
        // Only load if has data
        if (
          !(
            data &&
            data.audioBuffer &&
            data.audioBuffer.duration > 0 &&
            data.audioBuffer.length &&
            that.transport &&
            transportSharedArrayBuffer
          )
        ) {
          return;
        }

        // console.log(data);

        that.waveformValues$.next(data.waveformValues);

        that._isLoaded$.next(true);

        this.sampleRate = data.audioBuffer.sampleRate;
        this.duration = data.audioBuffer.duration;

        const numberOfChannels = data.audioBuffer.numberOfChannels;

        const inputs: Float32Array[][] = [];

        // add inputs
        inputs.push([]);

        for (let channel = 0; channel < numberOfChannels; channel++) {
          inputs[0][channel] = data.audioBuffer.getChannelData(channel);
        }

        if (inputs.length && inputs[0].length) {
          // console.log('LOAD_BUFFER');
          that.port.postMessage({
            message: 'LOAD_BUFFER',
            transportSharedArrayBuffer: transportSharedArrayBuffer,
            inputs,
            sampleRate: data.audioBuffer.sampleRate,
            trackName: data.trackEntityAndAudioFileMetaDataEntity.trackEntity.name
          });
        }

        this.onLoadAudio$.next(true);
      });
  }

  mute$(m: boolean): Observable<boolean> {
    this._mute = m;
    this.port.postMessage({
      message: 'MUTE',
      mute: m
    });

    return of(true);
  }
}
