import {algoliaConfig, firebaseProdConfig} from '../firebase/environment.firebas.config';
import {endpointsStaging} from './endpoints/endpoints.staging';

import {EMULATOR_PORTS, faceBookInit, IEnvironmentState} from './models/environment.models';

export const emulatorEnv: IEnvironmentState = {
  production: true,
  mobileTest: false,
  appName: 'studio-app',
  facebookAppId: '2219988261549213',
  firebaseProjectID: 'spout-prod',
  firebase: firebaseProdConfig,
  // firebaseMonitor: firebaseMonitorConfig,
  serviceWorker: false,

  // proxy server
  endpoints: endpointsStaging,
  algolia: algoliaConfig,
  emulator: true,
  authSite: 'https://dev.auth.spoutstudio.io'
};

/*
K000DWPy6RO5e/R6ZYz9sG3rVh4f8fM
 */

export const emulatorEnvLocal: IEnvironmentState = {
  ...emulatorEnv,
  serviceWorker: false
};

faceBookInit(emulatorEnv.facebookAppId);
