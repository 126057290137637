import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'spt-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrls: ['./privacy-policy-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
