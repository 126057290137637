import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpSelectMicrohoneContentComponent} from './help-select-microhone-content/help-select-microhone-content.component';
import {HelpSelectMicrophoneComponent} from './help-select-microphone.component';

@NgModule({
  declarations: [HelpSelectMicrophoneComponent, HelpSelectMicrohoneContentComponent],
  exports: [HelpSelectMicrophoneComponent, HelpSelectMicrohoneContentComponent],
  imports: [CommonModule, HelpContentModule, HelpNavBackModule, MatIconModule, MatTooltipModule, MatButtonModule]
})
export class HelpSelectMicrophoneModule {}
