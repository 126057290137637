import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSliderModule} from '@angular/material/slider';
import {ReactiveComponentModule} from '@ngrx/component';
import {SptSliderComponent} from './spt-slider.component';

@NgModule({
  declarations: [SptSliderComponent],
  exports: [SptSliderComponent],
  imports: [CommonModule, MatSliderModule, ReactiveComponentModule]
})
export class SptSliderModule {}
