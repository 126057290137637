<!--<h3>Sign in with email</h3>-->

<form [formGroup]="signInForm">
  <mat-form-field class="full-width" appearance="fill" floatLabel="always">
    <mat-label>Email</mat-label>
    <input type="email" matInput formControlName="email" required placeholder="Ex. pat@example.com" />

    <mat-error *ngIf="signInForm.controls['email'].hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field class="full-width password" appearance="fill" floatLabel="always">
    <mat-label>Password</mat-label>
    <input [type]="showPassword$ | ngrxPush" matInput formControlName="password" required />

    <mat-error *ngIf="signInForm.controls['password'].hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-checkbox (change)="showPassword($event)" [checked]="(showPassword$ | ngrxPush) === 'text'">
    Show Password
  </mat-checkbox>

  <button
    mat-flat-button
    e2e="cta-sign-in"
    color="primary"
    [disabled]="signInForm.pristine || (signInForm.dirty && signInForm.invalid)"
    (click)="submitEmailPassword()"
  >
    SIGN IN
  </button>
</form>

<button mat-button e2e="cta-forgot-password" (click)="forgotPassword.next(true)">FORGOT PASSWORD ?</button>
