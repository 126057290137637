<ng-content select="ui-bullet-chart-title, [ui-bullet-chart-title], [uiBulletChartTitle]"></ng-content>
<ng-content
  select="ui-bullet-chart-description, [ui-bullet-chart-description], [uiBulletChartDescription]"
></ng-content>
<div
  class="bullet-chart-tooltip g-chart-tooltip"
  [ngClass]="{'g-chart-tooltip-divot': showTooltipDivot$ | ngrxPush}"
  [style]="tooltipStyle$ | ngrxPush"
>
  <ng-content select="ui-bullet-chart-tooltip, [ui-bullet-chart-tooltip], [uiBulletChartTooltip]"></ng-content>
</div>
<div
  #chartContainer
  class="g-ui-chart-container svg-bullet-chart"
  uiResizeObserver
  (resize)="onResize$.next($event)"
></div>
<div class="bullet-chart-labels">
  <div class="bullet-chart-min-label g-chart-label">{{ min$ | ngrxPush }}</div>
  <div class="bullet-chart-max-label g-chart-label">{{ max$ | ngrxPush }}</div>
</div>
