export interface Role {
  author: boolean;
  owner: boolean;
  collaborator: boolean;
  readOnly: boolean;
  anonymous: boolean;
  reviewer: boolean;
}

export enum PROJECT_ROLE {
  Author = 'author',
  Owner = 'owner',
  Collaborator = 'collaborator',
  ReadOnly = 'readOnly',
  Anonymous = 'anonymous',
  Reviewer = 'reviewer'
}
