import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {StorageMap} from '@ngx-pwa/local-storage';
import {AudioFileMetaDataEntity, ProjectEntity} from '@spout/any-shared/models';
import {authLogout} from '@spout/web-global/actions';
import {
  clearIndexedDBCached,
  firestoreProjectByIdPath,
  getMusicianAccountPathByUID,
  getMusicianPublicPathByUser
} from '@spout/web-global/fns';
import {AuthAccountProductStates, StudioAppState} from '@spout/web-global/models';
import {selectAllAudioMetaData, selectAllProjectsIOwn, selectAuthAccountState} from '@spout/web-global/selectors';
import {BehaviorSubject, forkJoin, Observable, Observer, of, ReplaySubject, Subject} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {AudioFileDeleteService} from '../../+device-storage/services/audio-file-delete.service';
import {DeviceStorageService} from '../../+device-storage/services/device-storage.service';

import {SptFirestoreService} from '../../firestore';
import {FirebaseStorageService} from '../firebase-storage.service';

@Injectable()
export class ResetDevEnvironmentService {
  isCleaning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  notCleaning$ = this.isCleaning$.pipe(map(isCleaning => !isCleaning));
  deleteProgress$: ReplaySubject<string> = new ReplaySubject<string>(1);
  complete$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private device: DeviceStorageService,
    private store: Store<StudioAppState>,
    private fileDeleteService: AudioFileDeleteService,
    private storage: StorageMap,
    private _storage: FirebaseStorageService,
    private sptFirestore: SptFirestoreService
  ) {}

  deleteEntireAccount() {
    this.isCleaning$.next(true);
    this.store
      .pipe(
        select(selectAuthAccountState),
        take<AuthAccountProductStates>(1),
        filter<AuthAccountProductStates>(
          (a: AuthAccountProductStates) => a && a.auth && a.auth.uidHash !== null && a.account.uid !== null
        ),
        this.deleteAudioFilesFromWorkspace$(),
        this.deleteAudioFilesFromCloud$(),
        this.deleteAlgoliaIndex$(),
        this.deleteSripeAccount$(),
        this.deleteProjects$(),
        this.deletePublicAccount$(),
        this.deleteUserAccount$()

        // Need to keep user uid for testing collaboration
        // this.deleteUserAuth$()
      )
      .subscribe(() => {
        this.deleteProgress$.next('Logging out.');

        this.isCleaning$.next(false);

        this.store.dispatch(authLogout());

        localStorage.clear();
        this.storage.clear();
        clearIndexedDBCached().then(() => {
          /* noop */
        });

        this.sptFirestore.clearIndexedDbPersistence().catch(error => {
          console.error('Could not enable persistence:', error.code);
        });
        this.isCleaning$.next(false);
        this.complete$.next(true);
        this.complete$.complete();
      });
  }

  private deleteAudioFilesFromCloud$() {
    return switchMap((a: AuthAccountProductStates) => {
      return this.store.pipe(
        select(selectAllAudioMetaData),
        take(1),
        this.deleteFilesFromBackblaze$(),
        map(() => a)
      );
    });
  }

  private deleteFilesFromBackblaze$() {
    return switchMap((files: AudioFileMetaDataEntity[]) => {
      if (files && files.length) {
        this.deleteProgress$.next('Deleting audio files from Backblaze B2.');
        return this._storage.deleteStorage(files).pipe(
          take(1),
          map(() => files)
        );
      }

      return of(files);
    });
  }

  private deleteAudioFilesFromWorkspace$() {
    return switchMap((a: AuthAccountProductStates) => {
      this.deleteProgress$.next('Deleting audio files from file system.');
      return this.device
        .cleanWorkspace({
          account: a.auth.uidHash ? a.auth.uidHash : ''
        })
        .pipe(
          take(1),
          map(() => a)
        );
    });
  }

  private deleteFilesFromFileSystem$() {
    return switchMap((files: AudioFileMetaDataEntity[]) => {
      this.deleteProgress$.next('Empty workspace.');
      return this.fileDeleteService.deleteFilesFromFileSystem(files).pipe(take(1));
    });
  }

  private deleteSripeAccount$() {
    return switchMap((a: AuthAccountProductStates) => {
      return new Observable((observer: Observer<AuthAccountProductStates>) => {
        if (a.account.stripeId) {
          console.log('Deleting deleteStripeCustomerById', a.account.stripeId);
          this.deleteProgress$.next('Deleting Stripe Account.');
          const deleteStripeCustomerByIdFn = this.sptFirestore.httpsCallable('deleteStripeCustomerById');

          deleteStripeCustomerByIdFn(a.account.stripeId)
            .then(function (result) {
              console.log('Delete success: ' + JSON.stringify(result, null, 2));
              observer.next(a);
              observer.complete();
            })
            .catch(function (err) {
              console.warn(err);
              observer.next(a);
              observer.complete();
            });
        } else {
          observer.next(a);
          observer.complete();
        }
      });
    });
  }

  private deleteAlgoliaIndex$() {
    return switchMap((a: AuthAccountProductStates): Observable<AuthAccountProductStates> => {
      this.deleteProgress$.next('Deleting Algolia Index.');
      return new Observable((observer: Observer<AuthAccountProductStates>) => {
        console.log('Deleting deleteAlgoliaIndex', a.account.uid);
        const deleteAlgoliaIndexFn = this.sptFirestore.httpsCallable('deleteAlgoliaIndex');

        deleteAlgoliaIndexFn(a.account.uid)
          .then(function (result) {
            console.log('Delete success: ' + JSON.stringify(result, null, 2));
            observer.next(a);
            observer.complete();
          })
          .catch(function (err) {
            console.warn(err);
            observer.next(a);
            observer.complete();
          });
      });
    });
  }

  private deleteProjects$() {
    const that = this;
    return switchMap((a: AuthAccountProductStates): Observable<AuthAccountProductStates> => {
      this.deleteProgress$.next('Deleting Projects I Own.');
      return this.store.pipe(
        select(selectAllProjectsIOwn),
        map((projects: ProjectEntity[]) => {
          // Get Project Paths
          return projects.map((project: ProjectEntity) => {
            return firestoreProjectByIdPath(project);
          });
        }),
        switchMap((paths: string[]) => {
          if (paths && paths.length) {
            const toDelete$ = paths.map((path: string) => {
              return that.deleteDevFirestorePath(path);
            });
            return forkJoin(toDelete$);
          } else {
            return of(true);
          }
        }),
        map(() => a)
      );
    });
  }

  private deletePublicAccount$() {
    const that = this;
    return switchMap((a: AuthAccountProductStates): Observable<AuthAccountProductStates> => {
      this.deleteProgress$.next('Deleting Public Account.');
      return that.deleteDevFirestorePath(getMusicianPublicPathByUser(<string>a.account.uid)).pipe(map(() => a));
    });
  }

  private deleteUserAccount$() {
    const that = this;
    return switchMap((a: AuthAccountProductStates) => {
      this.deleteProgress$.next('Deleting User Account.');
      return that.deleteDevFirestorePath(getMusicianAccountPathByUID(<string>a.account.uid)).pipe(map(() => a));
    });
  }

  private deleteDevFirestorePath(path: string) {
    return new Observable((observer: Observer<any>) => {
      console.log('Deleting', path);
      const recursiveDeleteFn = this.sptFirestore.httpsCallable('recursiveDelete');

      recursiveDeleteFn({path: path})
        .then(function (result: any) {
          console.log('Delete success: ' + JSON.stringify(result, null, 2));
          observer.next(true);
          observer.complete();
        })
        .catch(function (err: any) {
          console.warn(err);
          observer.next(true);
          observer.complete();
        });
    });
  }

  private deleteUserAuth$() {
    return switchMap((a: AuthAccountProductStates) => {
      this.deleteProgress$.next('Deleting User Auth.');

      return new Observable((observer: Observer<AuthAccountProductStates>) => {
        const deleteUserAuthFn = this.sptFirestore.httpsCallable('deleteUserAuth');

        deleteUserAuthFn()
          .then(function (result: any) {
            console.log('Delete success: ' + JSON.stringify(result, null, 2));
            observer.next(a);
            observer.complete();
          })
          .catch(function (err: any) {
            console.warn(err);
            observer.next(a);
            observer.complete();
          });
      });
    });
  }
}
