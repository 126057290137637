export interface Endpoints {
  UPLOAD_BACKBLAZE: string;
  DELETE_BACKBLAZE: string;
  DOWNLOAD_BACKBLAZE: string;
}

export const ENDPOINTS: Endpoints = {
  UPLOAD_BACKBLAZE: 'uploadBackblaze',
  DELETE_BACKBLAZE: 'deleteBackblaze',
  DOWNLOAD_BACKBLAZE: 'downloadBackblaze'
};
