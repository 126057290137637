import {Injectable} from '@angular/core';
import {Dictionary} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {AudioFileMetaDataEntity, MixEntity, SongEntity, TrackEntity, TrackMix} from '@spout/any-shared/models';
import {createAndAddAudioTrackEntityToSongAndMix} from '@spout/web-global/fns';
import {AccountState, StudioAppState} from '@spout/web-global/models';
import {
  selectAccountState,
  selectAllMixes,
  selectAllMixes_passThrough,
  selectCurrentSongEntity,
  selectCurrentSongTrackCount,
  selectTrackEntities_passThrough
} from '@spout/web-global/selectors';
import {isDefinedPipe} from '@uiux/rxjs';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';

export interface SelectCurrentAudioTrackAndAudioFileMetaData {
  account: AccountState;
  song: SongEntity;
  currentSongTrackCount: number;
  mixes: MixEntity[];
  // trackMixes: TrackMix[],
}

@Injectable({
  providedIn: 'root'
})
export class CreateTrackService {
  constructor(private store: Store<StudioAppState>) {}

  createTrackNamedUniqueId(id: string, name: string) {
    return this.store.pipe(
      select(selectTrackEntities_passThrough),
      switchMap((trackEntities: Dictionary<TrackEntity>) => {
        if (!trackEntities[id]) {
          return this.createAudioTrackAndAudioFileMetaData(id, name);
        }

        // Get unique name
        let _id = id;
        let _name = name;
        let count = 1;
        while (trackEntities[_id] !== undefined && trackEntities[_id] !== null) {
          _id = `${id}_${count}`;
          _name = `${_name} ${count}`;
          count++;
        }

        return this.createAudioTrackAndAudioFileMetaData(_id, _name);
      })
    );
  }

  /**
   * Do not need TrackMix[] because they are being created for imported tracks
   * @param id
   * @param name
   */
  createAudioTrackAndAudioFileMetaData(
    id?: string,
    name?: string
  ): Observable<{
    trackEntity: TrackEntity;
    audioFileMetaDataEntity: AudioFileMetaDataEntity;
    mixes: MixEntity[];
    trackMixes: TrackMix[];
  }> {
    return this.selectCurrentAudioTrackAndAudioFileMetaData().pipe(
      map(({account, song, currentSongTrackCount, mixes}: SelectCurrentAudioTrackAndAudioFileMetaData) => {
        return createAndAddAudioTrackEntityToSongAndMix(account, song, mixes, currentSongTrackCount, id, name);
      })
    );
  }

  selectCurrentAudioTrackAndAudioFileMetaData(): Observable<SelectCurrentAudioTrackAndAudioFileMetaData> {
    selectAccountState.release();
    selectCurrentSongEntity.release();
    selectCurrentSongTrackCount.release();
    selectAllMixes.release();
    return combineLatest([
      this.store.pipe(
        select(selectAccountState)
        // unfreezePipe()
      ),
      this.store.pipe(
        select(selectCurrentSongEntity),
        isDefinedPipe<SongEntity | null, SongEntity>()
        // unfreezePipe()
      ),
      this.store.pipe(
        select(selectCurrentSongTrackCount)
        // unfreezePipe()
      ),
      this.store.pipe(
        select(selectAllMixes_passThrough)
        // unfreezePipe()
      )
      // this.store.pipe(select(selectAllTrackMixes))
    ]).pipe(
      map(([account, song, currentSongTrackCount, mixes]: [AccountState, SongEntity, number, MixEntity[]]) => {
        // Only mixes part of song
        const _mixes = mixes.filter((mix: MixEntity) => song && mix.songId === song.id);
        // const _trackMixes = trackMixes.filter((trackMix: TrackMix) => trackMix.songId === song.id );

        return {
          account,
          song,
          currentSongTrackCount,
          mixes: _mixes
          // trackMixes: _trackMixes,
        };
      }),
      filter(
        (d: {
          account: AccountState;
          song: SongEntity;
          currentSongTrackCount: number;
          mixes: MixEntity[];
          // trackMixes: TrackMix[],
        }): boolean => {
          return d.account && d.song && d.currentSongTrackCount > 0 && d.mixes.length > 0;
        }
      ),

      take<SelectCurrentAudioTrackAndAudioFileMetaData>(1)
    );
  }
}
