import {
  defaultWaveformSongMetrics,
  defaultWaveformValues,
  WaveformSongMetrics,
  WaveformValues
} from '@spout/web-global/models';

export function calculatePixelsPerSecond(sample_rate: number, scale: number = 512): number {
  return sample_rate / scale;
}

export function calculateSecondsPerPixel(sample_rate: number, scale: number = 512): number {
  return scale / sample_rate;
}

export function calculateAudioLength(durationSeconds: number, pixels_per_second: number): number {
  return Math.floor(durationSeconds * pixels_per_second);
}

interface PixelSecondsLength {
  length: number;
  pixels_per_second: number;
  seconds_per_pixel: number;
}

// waveform-data.js lib/core.js:406
export function calculatePixelsSecondsLength(
  durationSeconds: number,
  sample_rate: number,
  scale: number = 512
): PixelSecondsLength {
  const r = <PixelSecondsLength>{};
  r.pixels_per_second = calculatePixelsPerSecond(sample_rate, scale);
  r.seconds_per_pixel = calculateSecondsPerPixel(sample_rate, scale);
  r.length = calculateAudioLength(durationSeconds, r.pixels_per_second);
  return r;
}

export function getDefaultSongWaveformValues(sample_rate: number, duration: number): WaveformSongMetrics {
  let w: WaveformSongMetrics = JSON.parse(JSON.stringify(defaultWaveformSongMetrics));
  w.sample_rate = sample_rate;

  // used to set duration of empty mix
  w.songDuration = duration;

  w = {
    ...w,
    ...calculatePixelsSecondsLength(duration, sample_rate, w.scale)
  };

  return w;
}

export function getDefaultWaveformValues(
  sample_rate: number,
  durationSeconds: number,
  hasData: boolean
): WaveformValues {
  let w: WaveformValues = JSON.parse(JSON.stringify(defaultWaveformValues));
  w.sample_rate = sample_rate;

  // used to set duration of empty mix
  w.audioDuration = durationSeconds;
  w.hasData = hasData;

  w.channel[0].min_array = new Array(w.length).fill(0);
  w.channel[0].max_array = new Array(w.length).fill(0);
  w.channel[1].min_array = new Array(w.length).fill(0);
  w.channel[1].max_array = new Array(w.length).fill(0);

  w = {
    ...w,
    ...calculatePixelsSecondsLength(durationSeconds, sample_rate, w.scale)
  };

  return w;
}

export function addDurationOffset(durationSeconds: number, offsetMs: number): number {
  return durationSeconds + offsetMs * 0.001;
}
