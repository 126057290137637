import {hasValueIn} from '@uiux/fn';

/**
 * https://www.theiphonewiki.com/wiki/Models#iPhone
 * @param model
 */

// export function _window(): any {
//   // return the global native browser window object
//   return window;
// }

// export function deviceDetection(): Navigator {
//   return (<Window>_window()).navigator;
// }

// export function userAgent(): string {
//   return deviceDetection().userAgent;
// }

// export function devicePlatformID(): string {
//   return hasValueIn(_window(), 'cordova.platformId')
//     ? _window().cordova.platformId
//     : '';
// }

export function isiPhoneX(_model: string): boolean {
  return _model && _model.length ? /iPhone10/gi.test(_model) : false;
}

export function isiPhoneXR(_model: string): boolean {
  return _model && _model.length ? /iPhone11/gi.test(_model) : false;
}

export function isDesktop(platform: string): boolean {
  return platform && platform.length ? /desktop/gi.test(platform) : false;
}

export function isElectron(): boolean {
  const _userAgent = window.navigator.userAgent.toLowerCase();
  return _userAgent.indexOf('electron') > -1;
}

export function isBrowser(platform: string): boolean {
  return platform && platform.length ? /browser/gi.test(platform) : false;
}

export function isCordova(_platformID: string): boolean {
  return _platformID && _platformID.length ? !isDesktop(_platformID) && !isBrowser(_platformID) : false;
}

export function isIphoneNotch(_model: string): boolean {
  return isiPhoneX(_model) || isiPhoneXR(_model);
}

// export function model(): string {
//   return hasValueIn(_window(), 'device.model') ? _window().device.model : '';
// }

// export function navigator(): Navigator {
//   return (<Window>_window()).navigator;
// }

// export function platformID(): string {
//   return hasValueIn(_window(), 'cordova.platformId')
//     ? _window().cordova.platformId
//     : '';
// }

// export function isIPhoneX(): boolean {
//   // Really basic check for the ios platform
//   // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
//   // const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream'];
//   const iOS = /iPhone/.test(navigator.userAgent) && !window['MSStream'];
//
//   // Get the device pixel ratio
//   const ratio = window.devicePixelRatio || 1;
//
//   // Define the users device screen dimensions
//   const screen = {
//     width : window.screen.width * ratio,
//     height : window.screen.height * ratio
//   };
//
//   // iPhone X Detection
//   return (iOS && screen.width === 1125 && screen.height === 2436) ||
//     (iOS && screen.height === 1125 && screen.width === 2436);
// }
