import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {
  authAccountInitialState,
  deviceDetectionInitialState,
  initialWebsocketRegistryState,
  MarketingAppState
} from '@spout/web-global/models';
import {WINDOW_PROVIDERS} from '@spout/web-global/utils';
import {AccountEffects} from './+account/account.effects';
import {accountReducer} from './+account/account.reducer';
import {AccountService} from './+account/account.service';
import {AuthLogoutWatchService} from './+account/auth-logout-watch.service';
import {TrackCloudStorageService} from './+account/track-cloud-storage.service';
import {ConsoleStore} from './+console/console.store';
import {DeviceDetectionEffects} from './+device-detection/device-detection.effects';
import {deviceDetectionReducer} from './+device-detection/device-detection.reducer';
import {DeviceDetectionService} from './+device-detection/device-detection.service';
import {websocketRegistryReducer} from './+websocket-registry/websocket-registry.reducer';
import {PresenceService} from './services/presence.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    StoreModule.forRoot(
      {
        account: accountReducer,
        deviceDetection: deviceDetectionReducer,
        webSocketRegistry: websocketRegistryReducer
      },
      {
        initialState: <MarketingAppState>{
          account: authAccountInitialState,
          deviceDetection: deviceDetectionInitialState,
          webSocketRegistry: initialWebsocketRegistryState
        },
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictActionWithinNgZone: false,
          strictActionTypeUniqueness: false
        }
      }
    ),
    EffectsModule.forRoot([AccountEffects, DeviceDetectionEffects])
  ],
  providers: [
    WINDOW_PROVIDERS,
    AuthLogoutWatchService,
    AccountService,
    ConsoleStore,
    DeviceDetectionService,
    PresenceService,
    TrackCloudStorageService
  ]
})
export class DataAccessStudioSiteStoreModule {}
