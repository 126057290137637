import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {animationDelay, animationDuration} from '../animations/animation.constants';
import {AlertMessage, AlertMessageInput, AlertMessageStoreService} from './alert-message-store.service';

@Component({
  selector: 'spt-alert-message-container',
  templateUrl: './alert-message-container.component.html',
  styleUrls: ['./alert-message-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AlertMessageStoreService],
  animations: [
    trigger('marginTop', [
      state('false', style({'margin-top': 0})),
      state('true', style({'margin-top': '5px'})),
      transition('false => true', [animate(`${animationDuration} ease-out`)]),
      transition('true => false', [animate(`${animationDelay} ${animationDelay} ease-in`)])
    ]),
    trigger('showMessage', [
      transition(':enter', [style({opacity: 0}), animate(`${animationDuration} ease-out`, style({opacity: 1}))]),
      transition(':leave', [animate(`${animationDuration} ease-out`, style({opacity: 0}))])
    ])
  ]
})
export class AlertMessageContainerComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  warnMessages$: Observable<AlertMessage[]>;
  successMessages$: Observable<AlertMessage[]>;
  hasMessages$: Observable<boolean>;

  @HostBinding('@marginTop') hasMessages = false;

  constructor(private elRef: ElementRef, private cd: ChangeDetectorRef, private store: AlertMessageStoreService) {
    this.warnMessages$ = this.store.warnMessages$;
    this.successMessages$ = this.store.successMessages$;
    this.hasMessages$ = this.store.hasMessages$;
  }

  ngOnInit() {
    this.hasMessages$.pipe(takeUntil(this._onDestroy$)).subscribe((hasMessages: boolean) => {
      this.hasMessages = hasMessages;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  addWarnMessage(messageInput: AlertMessageInput): void {
    this.store.addWarnMessage(messageInput);
  }

  addSuccessMessage(messageInput: AlertMessageInput): void {
    this.store.addSuccessMessage(messageInput);
  }

  deleteMessage(key: string) {
    this.store.deleteMessage(key);
  }
}
