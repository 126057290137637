import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {ProjectEntity, ProjectState, TreeConfig} from '@spout/any-shared/models';
import {AccountState} from './account.model';

export const projectAdapter: EntityAdapter<ProjectEntity> = createEntityAdapter<ProjectEntity>();

export enum ProjectType {
  Project = 'project',
  Song = 'song',
  MasterTrack = 'masterTrack',
  Track = 'track'
}

export const initialProjectsState: ProjectState = projectAdapter.getInitialState({
  // additional entity state properties
  selectedId: null,
  defaultProjectId: null
});

export interface CreateProjectPayload {
  account: AccountState;
  project: TreeConfig;
}
