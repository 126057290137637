import {HttpClient} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProjectEntity} from '@spout/any-shared/models';
import {AuthAccountStatesConnect} from '@spout/web-global/models';
import {SptAudioService} from '../audio/spt-audio.service';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {SptFirestoreService} from '../firestore';
import {ProjectNode} from './project.node';

@Injectable({
  providedIn: 'root'
})
export class ProjectFactoryService {
  constructor(
    private store: Store<AuthAccountStatesConnect>,
    private sptFirestore: SptFirestoreService,
    private sptMediaRecorderService: SptAudioService,
    private httpClient: HttpClient,
    private dss: DynamicStoreService,
    private zone: NgZone
  ) {}

  // PROJECT NODE
  createProjectNode(projectConfig: ProjectEntity): ProjectNode {
    return new ProjectNode(
      projectConfig,
      this.sptFirestore,
      this.sptMediaRecorderService,
      this.httpClient,
      this.dss,
      this.store,
      this.zone
    );
  }
}
