import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {AudioFileMetaDataEntity} from '@spout/any-shared/models';
import {
  clearTrackEffect,
  deleteAudioFileMetaDataEntities,
  deleteAudioFilesFromDevice,
  deleteTrackEffect,
  upsertAudioFileMetaDataEntities
} from '@spout/web-global/actions';
import {CompositeAudioBufferDataAndAudioMetaData, StudioAppState} from '@spout/web-global/models';
import {getAudioMetaDataFilesByIDs} from '@spout/web-global/selectors';
import {EMPTY, of} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {SptAudioService} from '../audio/spt-audio.service';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {FirebaseStorageService} from '../services/firebase-storage.service';
import {AudioFileDeleteService} from './services/audio-file-delete.service';
import {AudioFileLoadService} from './services/audio-file-load.service';
import {AudioFileSaveService} from './services/audio-file-save.service';
import {DeviceEntityService} from './services/device-entity.service';
import {DeviceStorageService} from './services/device-storage.service';

@Injectable()
export class DeviceAudioFileMetaDataEffects {
  deleteTrackAudiofilesFromFileSystem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteTrackEffect),
        switchMap(action => {
          const that = this;
          if (action.trackModel?.fileData && action.trackModel.fileData.length) {
            const audioFileMetaData: AudioFileMetaDataEntity[] = action.trackModel.fileData.map(
              (d: CompositeAudioBufferDataAndAudioMetaData) => d.audioFileMetaData
            );

            return that._storage.deleteStorage(audioFileMetaData).pipe(
              switchMap(() => {
                return that.fileDeleteService.deleteFilesFromFileSystem(audioFileMetaData);
              })
            );
          } else {
            return EMPTY;
          }
        })
      ),
    {dispatch: false}
  );

  clearTrackEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearTrackEffect),
        switchMap(action => {
          const that = this;
          if (action.trackModel?.fileData && action.trackModel.fileData.length) {
            const audioFileMetaData: AudioFileMetaDataEntity[] = action.trackModel.fileData.map(
              (d: CompositeAudioBufferDataAndAudioMetaData) => d.audioFileMetaData
            );

            return that._storage.deleteStorage(audioFileMetaData).pipe(
              switchMap(() => {
                return that.fileDeleteService.deleteFilesFromFileSystem(audioFileMetaData);
              })
            );
          } else {
            return EMPTY;
          }
        })
      ),
    {dispatch: false}
  );

  updateAudioMetaDataEntitiesFromFirestore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(upsertAudioFileMetaDataEntities),
        switchMap(action => {
          if (action && action.files && action.files.length) {
            const toDelete = action.files.filter((file: AudioFileMetaDataEntity) => !file.fileUploaded);

            if (toDelete && toDelete.length) {
              // console.log(toDelete);
              return this.fileDeleteService.deleteFilesFromFileSystem(toDelete);
            }

            return of(EMPTY);
          }

          return of(EMPTY);
        })
      ),
    {dispatch: false}
  );

  deleteAudioFilesFromDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAudioFilesFromDevice),
      switchMap(action => {
        return this.store.pipe(
          select(getAudioMetaDataFilesByIDs(action.ids)),
          take<AudioFileMetaDataEntity[]>(1),
          tap((files: AudioFileMetaDataEntity[]) => {
            this.fileDeleteService.deleteFilesFromFileSystem(files).subscribe(() => {
              /* noop */
            });
          }),
          map(() => {
            return deleteAudioFileMetaDataEntities({ids: action.ids});
          })
        );
      })
    )
  );

  constructor(
    private dss: DynamicStoreService,
    private actions$: Actions,
    private store: Store<StudioAppState>,
    private sptAudioService: SptAudioService,
    private device: DeviceStorageService,
    private deviceEntityService: DeviceEntityService,
    private fileLoadService: AudioFileLoadService,
    private fileSaveService: AudioFileSaveService,
    private fileDeleteService: AudioFileDeleteService,
    private _storage: FirebaseStorageService
  ) {
    // this.saveRecordedAudio();
  }
}
