import {ChatEntity, ChatTypeConstant} from '@spout/web-global/models';
import {Observable} from 'rxjs';

export function filterChatTypeConversation(): (source: Observable<ChatEntity>) => Observable<ChatEntity> {
  return (source: Observable<ChatEntity>) =>
    new Observable<ChatEntity>(observer => {
      return source.subscribe({
        next(data: ChatEntity) {
          if (data.type === ChatTypeConstant.CONVERSATION) {
            observer.next(<ChatEntity>data);
          }
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        }
      });
    });
}

export function filterChatTypeMessage(): (source: Observable<ChatEntity>) => Observable<ChatEntity> {
  return (source: Observable<ChatEntity>) =>
    new Observable<ChatEntity>(observer => {
      return source.subscribe({
        next(data: ChatEntity) {
          if (data.type === ChatTypeConstant.MESSAGE) {
            observer.next(<ChatEntity>data);
          }
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        }
      });
    });
}
