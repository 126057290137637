import {AccountState} from '@spout/web-global/models';
import {hasValue} from '@uiux/fn';

export function validateOnboardingValues(a: AccountState) {
  return {
    // hasEmail: hasValue(a.email),
    hasStageName: hasValue(a.stageName),
    hasGenres: a.hasGenres,
    hasInstruments: a.hasInstruments
    // hasDefaultProject: hasValue(a.defaultProjectId),
    // hasDefaultSong: hasValue(a.defaultSongId),
    // hasDefaultTrack: hasValue(a.defaultTrackId),
    // hasDefaultMix: hasValue(a.defaultMixId)
  };
}
