import {Component} from '@angular/core';
import {select, Store} from '@ngrx/store';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {ProjectEntity} from '@spout/any-shared/models';
import {StudioAppState} from '@spout/web-global/models';
import {selectCurrentProjectEntity} from '@spout/web-global/selectors';

@Component({
  selector: 'spt-help-create-song',
  templateUrl: './help-create-song.component.html',
  styleUrls: ['./help-create-song.component.scss']
})
export class HelpCreateSongComponent {
  selectedProject$ = this.store.pipe(select(selectCurrentProjectEntity));

  constructor(private store: Store<StudioAppState>) {}
}
