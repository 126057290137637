import {Injectable, NgZone} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {select, Store} from '@ngrx/store';
import {MixEntity, TrackEntity, TrackMix} from '@spout/any-shared/models';
import {createTrackAction, masterIsSavingRecordedFile} from '@spout/web-global/actions';
import {createScaleLimitToRangeOutput, parseExportedRecordedWavAudio, updateTrackMixAudio} from '@spout/web-global/fns';
import {ParsedRecordExport, StudioAppState} from '@spout/web-global/models';
import {getAudioMetaDataByIdFn, getTrackEntityByIdFn} from '@spout/web-global/selectors';
import {hasValue} from '@uiux/fn';
import {isDefinedPipe} from '@uiux/rxjs';
import {combineLatest, Observable, Observer, of} from 'rxjs';
import {distinctUntilKeyChanged, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {AudioFileMetaDataFirestoreService} from '../../../+audio-file-meta-data/audio-file-meta-data-firestore.service';
import {MixesService} from '../../../+mixes/mixes.service';
import {CreateTrackService, SelectCurrentAudioTrackAndAudioFileMetaData} from '../../../+tracks/create-track.service';
import {SptTransportService} from '../../../audio/spt-transport.service';
import {SptFirestoreService} from '../../../firestore';
import {FirebaseStorageService} from '../../../services/firebase-storage.service';
import {transformParsedRecordExportToB2Payload} from '../../../services/proxy-service/proxy-form-data';
import {
  SpoutProxyServerServiceB2UploadParams,
  UploadStatusSpoutProxyServerServiceB2UploadResponse
} from '../../../services/proxy-service/proxy.models';
import {AudioFileSaveService} from '../audio-file-save.service';
import {DeviceStorageService} from '../device-storage.service';
import {createFilesWithId} from './create-files-with-id';
import {createImportFileMeta$, CreateImportFileMetaSource} from './create-import-file-meta';

import {FileWithId, IMPORT_PROGRESS, ImportFileMeta} from './import-files.models';

export interface ImportingCurrentFile {
  action: string;
  name: string;
}

export interface ImportfilesState {
  isImporting: boolean;
  notImporting: boolean;
  importingFile: ImportingCurrentFile;
  uploadProgress: number;
  queue: FileWithId[];
  currentUpload: FileWithId | null;
}

@Injectable({
  providedIn: 'root'
})
export class ImportFilesService extends ComponentStore<ImportfilesState> {
  readonly isImporting$ = this.select((state: ImportfilesState) => state.isImporting, {debounce: true});
  readonly notImporting$ = this.select((state: ImportfilesState) => state.notImporting, {debounce: true});
  readonly importingFile$ = this.select((state: ImportfilesState) => state.importingFile, {debounce: true});
  readonly uploadProgress$ = this.select((state: ImportfilesState) => state.uploadProgress, {debounce: true});
  readonly currentUploadFile$ = this.select((state: ImportfilesState) => state.currentUpload, {debounce: true});
  readonly uploadProgress = this.updater((state, uploadProgress: number) => ({
    ...state,
    uploadProgress
  }));
  readonly importingFile = this.updater((state, importingFile: ImportingCurrentFile) => ({
    ...state,
    importingFile
  }));
  readonly progressAndAction = this.updater(
    (state: ImportfilesState, payload: {uploadProgress: number; action: string}) => ({
      ...state,
      uploadProgress: payload.uploadProgress,
      importingFile: {
        ...state.importingFile,
        action: payload.action
      }
    })
  );
  readonly isImporting = this.updater((state, isImporting: boolean) => ({
    ...state,
    isImporting,
    notImporting: !isImporting
  }));
  readonly notImporting = this.updater((state, notImporting: boolean) => ({
    ...state,
    notImporting,
    isImporting: !notImporting
  }));
  readonly updateImportState = this.updater((state, newState: ImportfilesState) => ({
    ...state,
    ...newState
  }));
  readonly addToQueue = this.updater((state, files: FileWithId[]) => {
    let currentUpload: FileWithId | undefined;

    if (!state.currentUpload) {
      currentUpload = files.shift();
    }

    if (currentUpload) {
      return {
        ...state,
        queue: [...files],
        currentUpload
      };
    }

    if (files && files.length) {
      return {
        ...state,
        queue: [...files]
      };
    }
    return {
      ...state
    };
  });

  readonly nextUpload = this.updater(state => {
    let currentUpload: FileWithId | undefined;

    if (state.queue.length > 0) {
      currentUpload = state.queue.shift();

      if (currentUpload) {
        return {
          ...state,
          currentUpload: currentUpload
        };
      }

      return {
        ...state
      };
    }

    return {
      ...state,
      isImporting: false,
      notImporting: true,
      importingFile: {
        action: '',
        name: ''
      },
      uploadProgress: 0,
      currentUpload: null
    };
  });

  readonly resetState = this.updater(() => ({
    isImporting: false,
    notImporting: true,
    importingFile: {
      action: '',
      name: ''
    },
    uploadProgress: 0,
    queue: [],
    currentUpload: null
  }));

  private uploadProgressScale: (value: number) => number;

  constructor(
    private store: Store<StudioAppState>,
    private device: DeviceStorageService,
    private fileSaveService: AudioFileSaveService,
    private createTrackService: CreateTrackService,
    private mixesService: MixesService,
    private zone: NgZone,
    private sptFirestore: SptFirestoreService,
    private _storage: FirebaseStorageService,
    private firestoreAudioMetaData: AudioFileMetaDataFirestoreService,
    private transport: SptTransportService
  ) {
    super({
      isImporting: false,
      notImporting: true,
      importingFile: {
        action: '',
        name: ''
      },
      uploadProgress: 0,
      queue: [],
      currentUpload: null
    });

    // const that = this;

    this.uploadProgressScale = createScaleLimitToRangeOutput(
      0,
      100,
      IMPORT_PROGRESS.CREATE_TRACK_DONE,
      IMPORT_PROGRESS.MAX_UPLOAD_PROGRESS_DONE
    );

    this.currentUploadFile$
      .pipe(isDefinedPipe(), distinctUntilKeyChanged<FileWithId>('id'))
      .subscribe(this.processQueue.bind(this));
  }

  importFromFileSystem(files: File[]): void {
    this.addToQueue(createFilesWithId(files));
  }

  /**
   * 1. Start processing file
   * @param fileWithId
   * @private
   */
  private processQueue(fileWithId: FileWithId): void {
    const that = this;
    this.zone.run(() => {
      that.updateImportState(<ImportfilesState>{
        isImporting: true,
        notImporting: false,
        uploadProgress: 0,
        importingFile: {
          action: 'Creating Track:',
          name: fileWithId.name
        }
      });
    });

    // combineLatest([
    //   // Get current entities to build new track and audio meta data entities
    //   this.createTrackService.selectCurrentAudioTrackAndAudioFileMetaData(),
    //   this.device.getSystemInformation()
    // ])
    this.createTrackService
      .selectCurrentAudioTrackAndAudioFileMetaData()
      .pipe(
        map((d: SelectCurrentAudioTrackAndAudioFileMetaData) => {
          // console.log('before -->');
          // console.log(d, fileWithId);
          // console.log('\n\n');
          return <CreateImportFileMetaSource>{
            ...d,
            // desktopInformation,
            fileWithId
          };
        }),

        // Ultimates goes to libs/web-global/fns/src/lib/entity-create/add-track-to-song.ts:29
        createImportFileMeta$,

        switchMap((data: ImportFileMeta) => {
          // console.log('after -->');
          // console.log(data);
          // console.log('\n\n');
          return this.transport.audioContext$.pipe(
            switchMap((audioContext: AudioContext) => {
              // console.log(audioContext);
              // console.log(JSON.parse(JSON.stringify(data, null, 2)));

              // Ultimately calls libs/web-global/fns/src/lib/audio/parse-exported-wav-audio.ts:78
              return parseExportedRecordedWavAudio(
                data.file.file,
                data.meta,
                data.file.file.type,
                // data.desktopInformation,
                audioContext
              ).pipe(
                map((p: ParsedRecordExport): ImportFileMeta => {
                  // console.log(p);

                  data.meta.trackMixes = data.meta.trackMixes.map((trackMix: TrackMix) => {
                    return updateTrackMixAudio(trackMix, p);
                  });

                  data.parsedExport = p;

                  // Merge file metrics with audioFileMetaDataEntity
                  data.meta.audioFileMetaDataEntity = {
                    ...data.meta.audioFileMetaDataEntity,
                    ...p.trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity,
                    fileSize: data.file.file.size
                  };
                  return data;
                }),
                switchMap(that.upsertEntitiesFirestore.bind(that)),
                // UPLOADING
                switchMap((_data: ImportFileMeta) => {
                  return new Observable((observer: Observer<ImportFileMeta>) => {
                    const dataSet = new Set<SpoutProxyServerServiceB2UploadParams>();

                    if (_data.parsedExport) {
                      dataSet.add(transformParsedRecordExportToB2Payload(_data.parsedExport));
                    }

                    const status = that._storage.uploadStorage(dataSet, (<TrackEntity>_data.meta.trackEntity).name);

                    that.zone.run(() => {
                      that.progressAndAction({
                        action: 'Uploading Track',
                        uploadProgress: IMPORT_PROGRESS.CREATE_TRACK_DONE
                      });
                    });

                    status[_data.meta.audioFileMetaDataEntity.id].subscribe(
                      (_status: UploadStatusSpoutProxyServerServiceB2UploadResponse) => {
                        that.zone.run(() => {
                          that.uploadProgress(that.uploadProgressScale(_status.progress));
                        });

                        if (_status.progress === 100 && _status.result !== null && _status.result !== undefined) {
                          _data.meta.audioFileMetaDataEntity = {
                            ..._data.meta.audioFileMetaDataEntity,
                            fileUploaded: _status.result.fileUploaded,
                            storageLocation: 'google'
                          };

                          observer.next(_data);
                        }
                      }
                    );
                  });
                }),

                // SAVING TO FILE SYSTEM
                switchMap((_data: ImportFileMeta) => {
                  that.zone.run(() => {
                    that.progressAndAction({
                      action: 'Processing Track:',
                      uploadProgress: IMPORT_PROGRESS.PROCESSING_TRACK
                    });
                  });

                  if (
                    _data.parsedExport &&
                    _data.parsedExport.trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity
                  ) {
                    return that.fileSaveService
                      .saveNativeAudioFile({
                        audioFileMetaDataEntity:
                          _data.parsedExport.trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity,
                        uint8ArrayType: _data.parsedExport.uint8ArrayType
                      })
                      .pipe(
                        map(() => {
                          return _data;
                        })
                      );
                  }

                  return of(_data);
                }),

                // UPDATE FIRESTORE FILE IS UPLOADED
                switchMap((_data: ImportFileMeta) => {
                  that.zone.run(() => {
                    that.progressAndAction({
                      action: 'Syncing Track:',
                      uploadProgress: IMPORT_PROGRESS.SAVING_TO_FILE_SYSTEM_DONE
                    });
                  });
                  return this.firestoreAudioMetaData.updateAudioFileMetaData(_data.meta.audioFileMetaDataEntity).pipe(
                    tap({
                      next: (v: any) => {
                        // console.log('next', v);
                      },
                      error: (e: any) => {
                        console.log(e);
                      },
                      complete: () => {
                        // console.log('complete');
                      }
                    })
                  );
                })
              );
            })
          );
        })
      )
      .subscribe(
        () => {
          console.log('Finished');
          // that.store.dispatch(masterIsSavingRecordedFile({ isSavingRecordedFileTrackId: null }));
          that.zone.run(() => {
            that.progressAndAction({
              action: 'Finished:',
              uploadProgress: IMPORT_PROGRESS.UPDATE_META_FILE_DONE
            });
          });

          that.nextUpload();
        },
        () => {
          that.zone.run(() => {
            that.store.dispatch(masterIsSavingRecordedFile({isSavingRecordedFileTrackId: null}));
          });
        }
      );
  }

  private upsertEntitiesFirestore(importFileMeta: ImportFileMeta): Observable<ImportFileMeta> {
    const that = this;

    this.zone.run(() => {
      that.store.dispatch(
        createTrackAction({
          mixConfigs: importFileMeta.meta.mixes.map((mix: MixEntity) => {
            return {
              id: mix.id,
              changes: mix
            };
          }),
          track: importFileMeta.meta.trackEntity,
          file: importFileMeta.meta.audioFileMetaDataEntity,
          trackMixs: importFileMeta.meta.trackMixes
        })
      );
    });

    return combineLatest([
      this.store.pipe(
        select(
          getTrackEntityByIdFn({
            trackId: importFileMeta.meta.trackEntity.id
          })
        )
      ),
      this.store.pipe(
        select(
          getAudioMetaDataByIdFn({
            audioFileMetaDataId: importFileMeta.meta.audioFileMetaDataEntity.id
          })
        )
        // unfreezePipe()
      )
    ]).pipe(
      filter(([trackEntity, audioMetaDataEntity]) => {
        return hasValue(trackEntity) && hasValue(audioMetaDataEntity);
      }),
      take(1),
      map(() => {
        return importFileMeta;
      })
    );
  }
}
