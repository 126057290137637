import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Update} from '@ngrx/entity';
import {Action, select, Store} from '@ngrx/store';
import {BatchCreateNewMix, CombinedTrackAndTrackMix, MixEntity, TrackEntity, TrackMix} from '@spout/any-shared/models';
import {
  addMix,
  addMixFromSidenav,
  addMixToSongAction,
  closeAndNavigate,
  copyMixToSongAction,
  createDefaultConfigsToFirestore,
  createMixAction,
  createProjectAction,
  createSongAction,
  createTrackAction,
  loadMixs,
  saveDeviceStoreOrderedTrackMixIds,
  setDeviceStoreCurrentIdsFromTrackAndMixEntity,
  updateMix,
  updateTrackMixs
} from '@spout/web-global/actions';
import {
  copyMixEntity,
  createInitialMixEntityWithSong,
  createNewTrackMixFromTrackMix,
  setMixEntityProps
} from '@spout/web-global/fns';
import {AccountState, getAddMixRoute, StudioAppState} from '@spout/web-global/models';
import {
  selectAccountState,
  selectCurrentMixEntityId,
  selectCurrentSongId,
  selectCurrentTrackEntity_passThrough,
  selectCurrentTrackMixes,
  selectTrackMixSourcesByCurrentMix
} from '@spout/web-global/selectors';
import {differenceBy, hasValue} from '@uiux/fn';
import {EMPTY, Observable, of} from 'rxjs';
import {concatMap, map, mergeMap, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {SptPlayerCacheService} from '../audio/services/spt-player-cache.service';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {releaseStoreToSelect} from '../services/release-store';
import {MixesService} from './mixes.service';

@Injectable()
export class MixEffects {
  loadMixs$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loadMixs),
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        concatMap(() => EMPTY)
      );
    },
    {dispatch: false}
  );
  /*

    addMixToSongAction$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(addMixToSongAction),
          switchMap((action) => {
            return this.store.pipe(
              select(selectCurrentTrackEntity),
              this.playerCache.muteAllPipe(),
              tap((track: TrackEntity) => {
                this.store
                  .pipe(
                    select(selectAccountState),
                    map((account: AccountState) => {
                      return createMixEntityFromMix(account, action.mix, action.mixName, action.mixDescription);
                    })
                  )
                  .subscribe((mix: MixEntity) => {
                    releaseStoreToSelect();

                    // MIX ENTITY
                    this.store.dispatch(
                      addMix({
                        mixConfig: mix,
                      })
                    );

                    // CURRENT IDS
                    this.store.dispatch(
                      setDeviceStoreCurrentIdsFromTrackAndMixEntity({
                        track,
                        mixConfig: mix,
                      })
                    );
                  });
              })
            );
          })
        ),
      { dispatch: false }
    );
  */

  addMixToSongActionV2$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addMixToSongAction),
        switchMap(action => {
          return this.store.pipe(
            select(selectTrackMixSourcesByCurrentMix),
            take(1),
            switchMap((d: CombinedTrackAndTrackMix[]) => {
              return this.store.pipe(
                select(selectAccountState),
                take(1),
                switchMap((account: AccountState) => {
                  releaseStoreToSelect();

                  let mix = createInitialMixEntityWithSong(account, action.song);
                  mix = setMixEntityProps(mix, {
                    name: action.mixName,
                    description: action.mixDescription,
                    isDefault: false
                  });

                  const trackMixes = d.map((_d: CombinedTrackAndTrackMix) => {
                    return createNewTrackMixFromTrackMix(_d.trackMix, mix.id, account);
                  });

                  return this.mixService
                    .batchCreateNewMix(<BatchCreateNewMix>{
                      mix,
                      trackMixes
                    })
                    .pipe(
                      map(() => {
                        return createMixAction({
                          mix: mix,
                          trackMixs: trackMixes
                        });
                      })
                    );
                })
              );
            })
          );
        })
      )
    // { dispatch: false },
  );

  createMixAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMixAction),
      switchMap(action => {
        return this.store.pipe(
          select(selectCurrentTrackEntity_passThrough),
          // this.playerCache.muteAllPipe(),
          take<TrackEntity | undefined>(1),
          switchMap((track: TrackEntity | undefined) => {
            if (track) {
              return of(
                setDeviceStoreCurrentIdsFromTrackAndMixEntity({
                  track,
                  mix: action.mix
                })
              );
            }

            return EMPTY;
          })
        );
      })
    )
  );

  // TODO NOT WORKING https://spoutsoftware.atlassian.net/browse/SS-62
  copyMixToSongAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copyMixToSongAction),
      switchMap(action => {
        return this.store.pipe(
          select(selectCurrentTrackEntity_passThrough),
          switchMap((track: TrackEntity | undefined) => {
            return this.playerCache.muteAllPipe$().pipe(map(() => track));
          }),
          mergeMap((track: TrackEntity | undefined) => {
            return this.store.pipe(
              select(selectAccountState),
              map((account: AccountState) => {
                return copyMixEntity(account, action.mix, action.mixName, action.mixDescription);
              }),
              tap((mix: MixEntity) => {
                releaseStoreToSelect();
                // CURRENT IDS
                if (track) {
                  this.store.dispatch(
                    setDeviceStoreCurrentIdsFromTrackAndMixEntity({
                      track,
                      mix: mix
                    })
                  );
                }
              }),
              map((mix: MixEntity) => {
                return addMix({
                  mix: mix
                });
              })
            );
          })
        );
      })
    )
  );

  createDefaultMixEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createDefaultConfigsToFirestore),
        switchMap(action => {
          if (action.mix) {
            return this.mixService.createMixInFirestore(action.mix);
          }
          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  addMixEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createProjectAction, createSongAction),
        switchMap(action => {
          return this.mixService.createMixInFirestore(action.mix);
        })
      ),
    {dispatch: false}
  );

  updateMixEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateMix),
        switchMap(action => {
          if (action.mix.changes.id) {
            return this.mixService.updateMixToFirestore(action.mix.changes);
          }
          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  updateMixEntitys$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createTrackAction),
        switchMap(action => {
          return this.mixService.batchUpdateFirestoreMixes(
            action.mixConfigs.map((mix: Update<MixEntity>) => {
              return <MixEntity>mix.changes;
            })
          );
        })
      ),
    {dispatch: false}
  );

  saveOrderedTrackMixIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveDeviceStoreOrderedTrackMixIds),
      switchMap(action => {
        return this.store.pipe(
          select(selectCurrentTrackMixes),
          take(1),
          map((trackMixes: TrackMix[]) => {
            // const trackMixDict = trackMixes.reduce((a: { [ trackId: string ]: TrackMix }, trackMix: TrackMix ) => {
            //   a[trackMix.trackId] = trackMix;
            //   return a;
            // }, {})
            //
            //
            // const trackMixDict: { [trackId: string]: TrackMix } = action.orderedIds
            //   .reduce((a: { [trackId: string]: TrackMix }, trackId: string, index: number) => {
            //     a[trackId].orderNum = index;
            //     return a;
            //   },
            //   <{ [trackId: string]: TrackMix }>trackMixDict
            // );
            let _trackMixes: TrackMix[] = [];
            action.orderedIds.map((trackId: string, index: number) => {
              const _trackMix: TrackMix | undefined = trackMixes.find(
                (trackMix: TrackMix) => trackMix.trackId === trackId
              );
              if (_trackMix) {
                _trackMix.orderNum = index;
                _trackMixes.push(_trackMix);
              }
            });

            let _remaining = differenceBy(_trackMixes, trackMixes, 'id');

            if (hasValue(_remaining)) {
              _remaining = _remaining.map((trackMix: TrackMix) => {
                trackMix.orderNum = 1000;
                return trackMix;
              });

              _trackMixes = [..._trackMixes, ..._remaining];
            }

            return updateTrackMixs({
              trackMixs: _trackMixes.map((trackMix: TrackMix) => {
                return {
                  id: trackMix.id,
                  changes: trackMix
                };
              })
            });
          })
        );
      })
    );
  });

  addMixFromSidenav$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMixFromSidenav),
      withLatestFrom(this.store.pipe(select(selectCurrentSongId)), this.store.pipe(select(selectCurrentMixEntityId))),
      switchMap(([_action, songId, mixId]): Observable<Action> => {
        if (songId && mixId) {
          return of(closeAndNavigate(getAddMixRoute(songId, mixId)));
        }
        return EMPTY;
      })
    )
  );

  constructor(
    private dss: DynamicStoreService,
    private actions$: Actions,
    private mixService: MixesService,
    private store: Store<StudioAppState>,
    private playerCache: SptPlayerCacheService
  ) {}
}
