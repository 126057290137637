import {DecodedArrayBuffer} from '@spout/any-shared/models';
import {Observable, Observer} from 'rxjs';
import {fileToArrayBuffer} from './fileToArrayBuffer';
import {decodeArrayBuffer} from './decodeArrayBuffer';

export function decodeFromBlob(blob: Blob): Observable<DecodedArrayBuffer> {
  return new Observable((observer: Observer<DecodedArrayBuffer>) => {
    fileToArrayBuffer(blob).subscribe((src: ArrayBuffer) => {
      observer.next(decodeArrayBuffer(src));
    });
  });
}
