import {NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {AudioFileMetaDataEntity, ProjectEntity} from '@spout/any-shared/models';
import {deleteAudioFilesFromDevice, upsertAudioFileMetaDataEntities} from '@spout/web-global/actions';
import {DocumentChange, DocumentData, onSnapshot, QuerySnapshot} from 'firebase/firestore';
import {SptFirestoreService} from '../../firestore';
import {aggregateDocChanges, firestoreFilesPath} from '@spout/web-global/fns';
import {
  AggregateFirebaseSnapshotChangesEntities,
  AuthAccountStates,
  AuthAccountStatesConnect
} from '@spout/web-global/models';
import {Subscription} from 'rxjs';

import {QueryModel} from './query.model';

export class QueryAudioFileMetaDataService implements QueryModel<AudioFileMetaDataEntity> {
  // FILE
  private filesSub: Subscription = Subscription.EMPTY;
  private allFilesSub: (() => void) | undefined;

  constructor(
    private sptFirebase: SptFirestoreService,
    private store: Store<AuthAccountStatesConnect>,
    private config: ProjectEntity,
    private zone: NgZone
  ) {}

  onConnect(user: AuthAccountStates) {
    const that = this;
    // BEGIN FILES

    this.allFilesSub = onSnapshot(
      this.sptFirebase.collectionRef(firestoreFilesPath(this.config)),
      (snapshot: QuerySnapshot) => {
        if (user && user.auth && user.auth.uid) {
          that.process.apply(that, [snapshot.docChanges(), user.auth.uid]);
        }
      }
    );

    // END FILES
  }

  onDisconnect(user?: AuthAccountStates) {
    // Files

    this.filesSub.unsubscribe();

    if (this.allFilesSub) {
      this.allFilesSub();
    }
  }

  process(snapshot: DocumentChange<DocumentData>[], createdByUID: string) {
    const aggregate: AggregateFirebaseSnapshotChangesEntities<DocumentData> = aggregateDocChanges<DocumentData>(
      snapshot,
      createdByUID
    );
    // console.log('processFiles', aggregate);
    if (aggregate.added.length) {
      // aggregate.added.forEach((added) => {
      //   console.log('FILES ADDED', added.id);
      // });

      this.zone.run(() => {
        this.store.dispatch(
          upsertAudioFileMetaDataEntities({
            files: <AudioFileMetaDataEntity[]>aggregate.added
          })
        );
      });
    }

    if (aggregate.modified.length) {
      // aggregate.modified.forEach((modified) => {
      //   console.log('FILES MODIFIED', modified.id);
      // });

      // const changes: Update<AudioFileMetaDataEntity>[] = aggregate.modified.reduce(
      //   (_changes: Update<AudioFileMetaDataEntity>[], i: AudioFileMetaDataEntity) => {
      //     _changes.push({
      //       id: i.id,
      //       changes: i,
      //     });
      //
      //     return _changes;
      //   },
      //   []
      // );

      this.zone.run(() => {
        this.store.dispatch(
          upsertAudioFileMetaDataEntities({
            files: <AudioFileMetaDataEntity[]>aggregate.modified
          })
        );
      });
    }

    if (aggregate.removed.length) {
      // aggregate.removed.forEach((removed) => {
      //   console.log('FILES REMOVED', removed);
      // });

      this.zone.run(() => {
        this.store.dispatch(
          deleteAudioFilesFromDevice({
            ids: aggregate.removed
          })
        );
      });
    }
  }
}
