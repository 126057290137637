/**
 * this file should match modesl in functions/src/backblazeB2/lib/models.ts
 */
import {FILE_TYPE, FileUint8ArrayType} from '@spout/any-shared/models';

export interface NativeAudio {
  fileType: string;
  projectId: string;
  audioFileMetaDataId: string;
}

export interface ClientUploadNativeAudioBackblazeB2 {
  projectId: string;
  audioFileMetaDataId: string;
  uint8ArrayType: FileUint8ArrayType;
}

export declare type ClientDeleteNativeAudioBackblazeB2 = NativeAudio;

export interface ClientGetNativeAudioBakblazeB2 {
  // account: string;
  // audioFileMetaData: AudioFileMetaData;
  fileType: FILE_TYPE;
  projectId: string;
  audioFileMetaDataId: string;
}
