import {NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProjectEntity, TrackMix} from '@spout/any-shared/models';
import {deleteTrackMixs, upsertTrackMixs} from '@spout/web-global/actions';
import {aggregateDocChanges, firestoreTrackMixesPath} from '@spout/web-global/fns';
import {AggregateFirebaseSnapshotChangesEntities, AuthAccountStates} from '@spout/web-global/models';
import {DocumentChange, onSnapshot, QuerySnapshot} from 'firebase/firestore';
import {SptFirestoreService} from '../../firestore';
import {QueryModel} from './query.model';

export class QueryTrackMixesService implements QueryModel<TrackMix> {
  // TRACK
  private trackMixesSub: (() => void) | undefined;

  constructor(
    private sptFirebase: SptFirestoreService,
    private store: Store,
    private config: ProjectEntity,
    private zone: NgZone
  ) {}

  onConnect(user: AuthAccountStates) {
    const that = this;

    // BEGIN TRACKS
    if (this.trackMixesSub) {
      this.trackMixesSub();
    }

    this.trackMixesSub = onSnapshot(
      this.sptFirebase.collectionRef(firestoreTrackMixesPath(this.config)),
      (snapshot: QuerySnapshot) => {
        // that.processTracks(snapshot, user.auth.uid);
        that.process.apply(that, [snapshot.docChanges() as DocumentChange<TrackMix>[]]);
      },
      () => {
        /* noop */
      },
      () => {
        /* noop */
      }
    );
  }

  onDisconnect(user?: AuthAccountStates) {
    // TRACKS
    if (this.trackMixesSub) {
      this.trackMixesSub();
    }
  }

  process(snapshot: DocumentChange<TrackMix>[]) {
    const that = this;
    const aggregate: AggregateFirebaseSnapshotChangesEntities<TrackMix> = aggregateDocChanges<TrackMix>(
      snapshot
      // createdByUID
    );

    this.zone.run(() => {
      if (aggregate.added.length) {
        // aggregate.added.forEach((added) => {
        //   console.log('TRACKS ADDED', added.id);
        // });

        // aggregate.added = aggregate.added.map((t: TrackMix) => {
        //   Object.keys(t.audioSnippets).forEach((key: string) => {
        //     t.audioSnippets[key] = removeTimeStampCTorFromData(t.audioSnippets[key]);
        //   });
        //
        //   return t;
        // });

        that.store.dispatch(
          upsertTrackMixs({
            trackMixs: <TrackMix[]>aggregate.added
          })
        );
      }

      if (aggregate.modified.length) {
        // aggregate.modified.forEach((modified) => {
        //   console.log('TRACKS MODIFIED', modified.id);
        // });

        // aggregate.modified = aggregate.modified.map((t: TrackMix) => {
        //   Object.keys(t.audioSnippets).forEach((key: string) => {
        //     t = removeTimeStampCTorFromData(t);
        //     t.audioSnippets[key] = removeTimeStampCTorFromData(t.audioSnippets[key]);
        //   });
        //
        //   return t;
        // });

        that.store.dispatch(
          upsertTrackMixs({
            trackMixs: <TrackMix[]>aggregate.modified
          })
        );
      }

      if (aggregate.removed.length) {
        // aggregate.removed.forEach((removed) => {
        //   console.log('TRACKS REMOVED', removed);
        // });

        that.store.dispatch(
          deleteTrackMixs({
            ids: aggregate.removed
          })
        );
      }
    });
  }
}
