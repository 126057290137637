import {EntityType, Mix, MixEntity, SongEntity} from '@spout/any-shared/models';
import {AccountState, applyInitialAuthor, createAuthorMusician} from '@spout/web-global/models';
import {uuid} from '../uuid';

export function createInitialMixEntityWithSong(account: AccountState, song: Partial<SongEntity>): MixEntity {
  const mixId = uuid();

  const config: Mix = {
    id: mixId,
    entityType: EntityType.MIX,
    name: '',
    description: '',
    songId: song.id ? song.id : '',
    projectId: song.projectId ? song.projectId : '',
    isDefault: false,

    bpm: 0,
    hasReferenceTrack: false
  };

  return applyInitialAuthor<Mix, MixEntity>(config, createAuthorMusician(account));
}

export function copyMixEntity(account: AccountState, mix: MixEntity, name: string, description: string) {
  const mixId = uuid();

  const config: Mix = {
    ...mix,
    id: mixId,
    name,
    description,
    isDefault: false
  };

  return applyInitialAuthor<Mix, MixEntity>(config, createAuthorMusician(account));
}

export function setMixEntityProps(mixEntity: MixEntity, props: Partial<MixEntity>): MixEntity {
  return {
    ...mixEntity,
    ...props
  };
}
