import {select, Store} from '@ngrx/store';
import {
  addWebsocketRegistry,
  websocketIsConnectedAction,
  websocketIsDisconnectedAction
} from '@spout/web-global/actions';
import {AccountState, AuthAccountStates, AuthState, StudioAppState} from '@spout/web-global/models';
import {selectAccountState, selectAuthState, selectDoConnect} from '@spout/web-global/selectors';
import {Observable, Observer, Subscription} from 'rxjs';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, map, mergeMap} from 'rxjs/operators';

export type ConnectFunction = (user: AuthAccountStates) => void;

export interface OnWebsocketMonitor {
  onConnect: (user: AuthAccountStates) => void;
  onDisconnect: (user: AuthAccountStates) => void;
}

export class WebSocketMonitor {
  private _registrationKey: string | null = null;

  private _context: any | null = null;

  // The context which _onDisconnectFn references when _onConnectFn is called
  // @deprecated
  // Action to create when getting initial data from Firebase

  private _mergedSub: Subscription = Subscription.EMPTY;

  // Call when connection to Firebase is made
  private _onConnectFn: (user: AuthAccountStates) => void = (user: AuthAccountStates) => {
    /* noop */
  };
  // Call when connection to Firebase is disconnected
  private _onDisconnectFn: (user: AuthAccountStates) => void = (user: AuthAccountStates) => {
    /* noop */
  };

  constructor(private store: Store<StudioAppState>) {
    // console.log('Connection');
  }

  /**
   *
   * @param uniqueId - Unique ID to track-audio WebSocket Connection
   * @param onConnect - Function called when connecting to WebSocket
   * @param onDisconnect - Function called when disconnecting from WebSocket
   * @param context - pass "this" as context or context of service
   */
  register(uniqueId: string, onConnect: ConnectFunction, onDisconnect: ConnectFunction, context?: any) {
    this._registrationKey = uniqueId;
    this._onConnectFn = onConnect;
    this._onDisconnectFn = onDisconnect;
    this._context = context;

    if (!this._registrationKey) {
      throw new Error('A unique ID is required to register a WebSocket connection.');
    }

    if (!this._onConnectFn) {
      throw new Error(
        'An onConnect function is required, implement the ConnectService interface in your connect service.'
      );
    }

    if (!this._onDisconnectFn) {
      throw new Error(
        'An onDisconnect function is required, implement the ConnectService interface in your connect service.'
      );
    }

    if (!this._context) {
      throw new Error('Context required for connection functions, pass "this" as last argument.');
    }

    this._init();
  }

  private _init() {
    // console.log(`connecting ${this._registrationKey}`);

    if (this._registrationKey) {
      this.store.dispatch(
        addWebsocketRegistry({
          id: this._registrationKey
        })
      );
    }

    this._mergedSub.unsubscribe();

    // Make sure changes match libs/shared/data-access-api/src/lib/connection/connection.collection.ts
    const account$: Observable<AccountState> = this.store.pipe(
      select(selectAccountState),
      filter<AccountState>((account: AccountState): boolean => {
        return account !== null && account.email !== null && account.email.length > 0;
      }),
      distinctUntilKeyChanged<AccountState>('email')
    );

    const auth$: Observable<AuthState> = this.store.pipe(
      select(selectAuthState),
      filter((auth: AuthState) => {
        return auth && auth.uid !== null && auth.uid.length > 0;
      }),
      distinctUntilKeyChanged<AuthState>('isLoggedIn'),
      filter((auth: AuthState) => auth.isLoggedIn)
    );

    this._mergedSub = account$
      .pipe(
        mergeMap((account: AccountState) => {
          return auth$.pipe(
            map((auth: AuthState) => {
              return {
                account,
                auth
              };
            }),
            mergeMap((user: AuthAccountStates) =>
              this.store.pipe(
                select(selectDoConnect),
                distinctUntilChanged(),
                map((doConnect: boolean) => {
                  return {
                    user,
                    doConnect
                  };
                })
              )
            )
          );
        })
      )
      .subscribe(({user, doConnect}: {user: AuthAccountStates; doConnect: boolean}) => {
        if (doConnect) {
          this._onConnectFn.call(this._context, user);

          if (this._registrationKey) {
            this.store.dispatch(
              websocketIsConnectedAction({
                id: this._registrationKey
              })
            );
          }
        } else {
          this._onDisconnectFn.call(this._context, user);
        }
      });
  }

  destroy() {
    this._mergedSub.unsubscribe();
  }

  setIsConnected(): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      if (this._registrationKey) {
        this.store.dispatch(
          websocketIsConnectedAction({
            id: this._registrationKey
          })
        );
      }

      observer.next(true);
    });
  }

  setIsDisconnected(): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      if (this._registrationKey) {
        this.store.dispatch(
          websocketIsDisconnectedAction({
            id: this._registrationKey
          })
        );
      }

      observer.next(true);
    });
  }
}
