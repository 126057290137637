import {A11yModule} from '@angular/cdk/a11y';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {CommunityGuidelinesComponent} from './community-guidelines/community-guidelines.component';
import {EulaComponent} from './eula/eula.component';

import {PagePoliciesRoutingModule} from './page-policies-routing.module';
import {PagePoliciesComponent} from './page-policies.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {PrivacyPolicyDialogComponent} from './privacy-policy-dialog/privacy-policy-dialog.component';
import {TermsAndConditionsDialogComponent} from './terms-and-conditions-dialog/terms-and-conditions-dialog.component';

@NgModule({
  declarations: [
    PagePoliciesComponent,
    PrivacyPolicyComponent,
    CommunityGuidelinesComponent,
    TermsAndConditionsComponent,
    EulaComponent,
    PrivacyPolicyDialogComponent,
    TermsAndConditionsDialogComponent
  ],
  imports: [
    CommonModule,
    A11yModule,
    MatDialogModule,
    MatButtonModule,
    PerfectScrollbarModule,
    PagePoliciesRoutingModule
  ],
  exports: [
    PagePoliciesComponent,
    PrivacyPolicyComponent,
    CommunityGuidelinesComponent,
    TermsAndConditionsComponent,
    EulaComponent,
    PrivacyPolicyDialogComponent,
    TermsAndConditionsDialogComponent
  ]
})
export class PagePoliciesModule {}
