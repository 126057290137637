import {MixEntity, Musician, ProjectEntity, Role, SongEntity, TrackEntity} from '@spout/any-shared/models';
import {createPassThroughSelector} from '@spout/web-global/fns';
import {ACCOUNT_ROLE, createViewerRole, FEATURE_ID, SptPermissionState} from '@spout/web-global/models';
import {
  getIsCreatedByLoggedInMusicianFn_passThrough,
  selectAuthAccountState_passThrough,
  selectPermissionRole_passThrough,
  selectUid_passThrough
} from './account.selectors';
import {selectCurrentMixEntity_passThrough} from './entities/mix-storage.selectors';
import {selectCurrentProjectEntity_passThrough} from './entities/project-storage.selectors';
import {selectCurrentSongEntity_passThrough} from './entities/song-storage.selectors';
import {selectCurrentTrackEntity_passThrough} from './entities/track-storage.selectors';

export const selectProjectRole_passThrough = createPassThroughSelector(
  selectCurrentProjectEntity_passThrough,
  selectUid_passThrough,
  (project: ProjectEntity | undefined, uid: string | null): Role => {
    if (project && uid) {
      if (project.members[uid]) {
        return project.members[uid].role;
      }
    }

    return createViewerRole();
  }
);

export const selectedIsProjectOwner_passThrough = createPassThroughSelector(
  selectCurrentProjectEntity_passThrough,
  selectUid_passThrough,
  (project: ProjectEntity | undefined, uid: string | null) => {
    if (project && uid) {
      if (project.members[uid]) {
        return project.members[uid].role.owner;
      } else {
        return false;
      }
    }

    return false;
  }
);

export const selectIsTrackOwner_passThrough = createPassThroughSelector(
  selectCurrentTrackEntity_passThrough,
  selectUid_passThrough,
  (song: TrackEntity | undefined, uid: string | null) => {
    if (song && uid) {
      if (song.members[uid]) {
        return song.members[uid].role.owner;
      } else {
        return false;
      }
    }

    return false;
  }
);

export const isCollaboratorRole_passThrough = createPassThroughSelector(
  selectPermissionRole_passThrough,
  (role: string | null | undefined) => role === ACCOUNT_ROLE.COLLABORATOR
);

export const selectedIsSongOwner_passThrough = createPassThroughSelector(
  selectCurrentSongEntity_passThrough,
  selectUid_passThrough,
  (song: SongEntity | null, uid: string | null) => {
    if (song && uid) {
      if (song.members[uid]) {
        return song.members[uid].role.owner;
      } else {
        return false;
      }
    }

    return false;
  }
);

export const selectIsMixOwner_passThrough = createPassThroughSelector(
  selectCurrentMixEntity_passThrough,
  selectUid_passThrough,
  (song: MixEntity | null, uid: string | null) => {
    if (song && uid) {
      if (song.members[uid]) {
        return song.members[uid].role.owner;
      } else {
        return false;
      }
    }

    return false;
  }
);

/**
 * ADD_ANNOTATIONS
 */
export const selectCanAddAnnotations_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state: SptPermissionState) => state.permissions[FEATURE_ID.ADD_ANNOTATIONS]
);

/**
 * ADD_COLLABORATORS
 */
export const selectCanAddCollaborators_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.ADD_COLLABORATORS]
);

/**
 * CHAT
 */
export const selectCanChat_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.CHAT]
);

/**
 * CLEAR_TRACKS
 */
export const selectCanClearTracks_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state: SptPermissionState) => state.permissions[FEATURE_ID.CLEAR_TRACKS]
);

/**
 * CREATE_PROJECTS
 */
export const selectCanClearProject_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state: SptPermissionState) => state.permissions[FEATURE_ID.CREATE_PROJECTS]
);

/**
 * CREATE_PROJECTS
 */
export const selectCanCreateSongs_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.CREATE_SONGS]
);

/**
 * CREATE_TRACKS
 */
export const selectCanCreateTracks_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.CREATE_TRACKS]
);

/**
 * CREATE_MIXES
 */
export const selectCanCreateMixes_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.CREATE_MIXES]
);

/**
 * DELETE_TRACKS
 */
export const selectCanDeleteTracks_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.DELETE_TRACKS]
);

/**
 * EXPORT_SONG
 */
export const selectCanExportSongs_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.EXPORT_SONG]
);

/**
 * IMPORT_AUDIO_FILES
 */
export const selectCanImportAudioFiles_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state: SptPermissionState) => state.permissions[FEATURE_ID.IMPORT_AUDIO_FILES]
);

/**
 * RECORD
 */
export const selectCanRecord_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.RECORD]
);

/**
 * SHARE_TRACKS
 */
export const selectCanShareTracks_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state: SptPermissionState) => state.permissions[FEATURE_ID.SHARE_TRACKS]
);

/**
 * EDIT PROJECT, SONG, TRACK, MIX
 */
export const selectShowEditProject_passThrough = createPassThroughSelector(
  selectedIsProjectOwner_passThrough,
  selectProjectRole_passThrough,
  (isProjectOwner: boolean, role: Role) => {
    //
    return isProjectOwner && !role.collaborator;
  }
);

export const selectShowEditSong_passThrough = createPassThroughSelector(
  selectedIsSongOwner_passThrough,
  selectProjectRole_passThrough,
  (isSongOwner: boolean, role: Role) => {
    //
    return isSongOwner && !role.collaborator;
  }
);

export const selectShowEditMix_passThrough = createPassThroughSelector(
  selectIsMixOwner_passThrough,

  selectProjectRole_passThrough,
  (isMixOwner: boolean, role: Role) => {
    //
    return isMixOwner && !role.collaborator;
  }
);

export const getShowEditTrackButtonFn_passThrough = (config: {createdBy: Musician}) =>
  createPassThroughSelector(
    getIsCreatedByLoggedInMusicianFn_passThrough(config),
    selectCanCreateTracks_passThrough,
    selectProjectRole_passThrough,
    (isCreatedByLoggedInMusician: boolean, canCreate: boolean, role: Role) => {
      //
      return isCreatedByLoggedInMusician && canCreate && !role.collaborator;
    }
  );

/**
 * TRACK CONTROLS
 */

export const selectShowRecordButton_passThrough = createPassThroughSelector(
  selectCanRecord_passThrough,
  (canRecord: boolean) => {
    //
    return canRecord;
  }
);

export const selectShowTrackVolumeButton_passThrough = createPassThroughSelector(
  selectIsMixOwner_passThrough,
  (isMixOwner: boolean) => {
    //
    return isMixOwner;
  }
);

export const selectShowTrackOffsetButton_passThrough = createPassThroughSelector(
  selectIsMixOwner_passThrough,
  (isMixOwner: boolean) => {
    //
    return isMixOwner;
  }
);

export const selectShowTrackColorButton_passThrough = createPassThroughSelector(
  selectIsMixOwner_passThrough,
  (isMixOwner: boolean) => {
    //
    return isMixOwner;
  }
);

export const selectShowClearTrackButton_passThrough = createPassThroughSelector(
  selectCanClearTracks_passThrough,
  (canClearTrack: boolean) => {
    //
    return canClearTrack;
  }
);

export const selectShowShareTrackButton_passThrough = createPassThroughSelector(
  selectCanShareTracks_passThrough,
  (canShareTracks: boolean) => {
    //
    return canShareTracks;
  }
);

export const getShowSetMasterButtonFn_passThrough = (config: {createdBy: Musician}) =>
  createPassThroughSelector(
    getIsCreatedByLoggedInMusicianFn_passThrough(config),
    selectCanRecord_passThrough,
    (isCreatedByLoggedInMusician: boolean, canRecord: boolean) => {
      //
      return isCreatedByLoggedInMusician && canRecord;
    }
  );

export const getShowShowDeleteTrackButtonFn_passThrough = (config: {createdBy: Musician}) =>
  createPassThroughSelector(
    getIsCreatedByLoggedInMusicianFn_passThrough(config),
    selectCanDeleteTracks_passThrough,
    (isCreatedByLoggedInMusician: boolean, canDelete: boolean) => {
      //
      return isCreatedByLoggedInMusician && canDelete;
    }
  );
