import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {openHelpViewRightDrawer} from '@spout/web-global/actions';
import {HelpModel} from '@spout/web-global/models';

@Component({
  selector: 'spt-help-content',
  templateUrl: './help-content.component.html',
  styleUrls: ['./help-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpContentComponent {
  @Input() showDivider = true;
}
