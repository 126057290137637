import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {TreeConfig} from '@spout/any-shared/models';
import {
  AggregateFirebaseSnapshotChangesEntities,
  InstrumentActionTypes,
  InstrumentState
} from '@spout/web-global/models';

export const instrumentLoaded = createAction(
  InstrumentActionTypes.InstrumentLoaded,
  props<{payload: InstrumentState}>()
);

export const saveInstrumentsToFirebase = createAction(
  InstrumentActionTypes.SaveInstrumentsToFirebase,
  props<{payload: any}>()
);

// export const loggedOut = createAction(InstrumentActionTypes.LoggedOut, props<{ payload: any }>());

// export const instrumentDoNothingAction = createAction(InstrumentActionTypes.InstrumentDoNothingAction);

export const loadInstruments = createAction('[Instrument/API] Load Instruments', props<{instruments: TreeConfig[]}>());

export const addInstrument = createAction('[Instrument/API] Add Instrument', props<{instrument: TreeConfig}>());

export const upsertInstrument = createAction('[Instrument/API] Upsert Instrument', props<{instrument: TreeConfig}>());

export const addInstruments = createAction('[Instrument/API] Add Instruments', props<{instruments: TreeConfig[]}>());

export const upsertInstruments = createAction(
  '[Instrument/API] Upsert Instruments',
  props<{instruments: TreeConfig[]}>()
);

export const updateInstrument = createAction(
  '[Instrument/API] Update Instrument',
  props<{instrument: Update<TreeConfig>}>()
);

export const updateInstruments = createAction(
  '[Instrument/API] Update Instruments',
  props<{instruments: Update<TreeConfig>[]}>()
);

export const deleteInstrument = createAction('[Instrument/API] Delete TreeConfig', props<{id: string}>());

export const deleteInstruments = createAction('[Instrument/API] Delete TreeConfigs', props<{ids: string[]}>());

export const clearInstruments = createAction('[Instrument/API] Clear TreeConfigs');

export const instrumentAggregate = createAction(
  '[Instrument/API] Update Aggregate',
  props<{aggregate: AggregateFirebaseSnapshotChangesEntities<TreeConfig>}>()
);
